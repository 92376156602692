<div class="main-content view-notification-container no-scroll">
  <div class="container-fluid">
    <div class="card">
      <div class="card-content">
        <div class="col-12 header">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <strong style="width: 100%">{{ formTitle | uppercase }}</strong>
            </div>
            <div class="col-6 right-top-section">
              <button *ngIf="
                  defaultButtonText === 'AddNew' &&
                  formTitle !== 'Modify Notification' &&
                  isAddNotificationPermission
                " class="btn btn-outline-dark btn-sm add-new-store" (click)="AddNew()">
                <i class="fa fa-plus anchor-btn" aria-hidden="true"></i> Add
                New Notfication
              </button>
            </div>
          </div>

        </div>
        <div class="col-12">
          <ng-container #componentPlaceHolder></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>