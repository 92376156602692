export enum Permissions {
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
  DASHBOARD_ADD = "DASHBOARD_ADD",
  DASHBOARD_EDIT = "DASHBOARD_EDIT",
  DASHBOARD_DELETE = "DASHBOARD_DELETE",
  PROMO_VIEW = "PROMO_VIEW",
  PROMO_ADD = "PROMO_ADD",
  PROMO_EDIT = "PROMO_EDIT",
  PROMO_DELETE = "PROMO_DELETE",
  DEAL_VIEW = "DEAL_VIEW",
  DEAL_ADD = "DEAL_ADD",
  DEAL_EDIT = "DEAL_EDIT",
  DEAL_DELETE = "DEAL_DELETE",
  ROLE_VIEW = "ROLE_VIEW",
  ROLE_ADD = "ROLE_ADD",
  ROLE_EDIT = "ROLE_EDIT",
  ROLE_DELETE = "ROLE_DELETE",
  AGENT_VIEW = "AGENT_VIEW",
  AGENT_ADD = "AGENT_ADD",
  AGENT_EDIT = "AGENT_EDIT",
  AGENT_DELETE = "AGENT_DELETE",
  ORDER_VIEW = "ORDER_VIEW",
  ORDER_ADD = "ORDER_ADD",
  ORDER_EDIT = "ORDER_EDIT",
  ORDER_DELETE = "ORDER_DELETE",
  RESTAURANT_CONFIG_VIEW = "RESTAURANT_CONFIG_VIEW",
  RESTAURANT_CONFIG_ADD = "RESTAURANT_CONFIG_ADD",
  RESTAURANT_CONFIG_EDIT = "RESTAURANT_CONFIG_EDIT",
  RESTAURANT_CONFIG_DELETE = "RESTAURANT_CONFIG_DELETE",
  CHATBOT_VIEW = "CHATBOT_VIEW",
  PLACE_MANUAL_ORDER = "PLACE_MANUAL_ORDER",
  KEYWORD_VIEW = "KEYWORD_VIEW",
  KEYWORD_ADD = "KEYWORD_ADD",
  KEYWORD_EDIT = "KEYWORD_EDIT",
  KEYWORD_DELETE = "KEYWORD_DELETE",
  NOTIFICATION_VIEW = "NOTIFICATION_VIEW",
  NOTIFICATION_ADD = "NOTIFICATION_ADD",
  NOTIFICATION_EDIT = "NOTIFICATION_EDIT",
  NOTIFICATION_DELETE = "NOTIFICATION_DELETE",
  FAQ_VIEW = "FAQ_VIEW",
  FAQ_ADD = "FAQ_ADD",
  FAQ_EDIT = "FAQ_EDIT",
  FAQ_DELETE = "FAQ_DELETE",
}
