import { Injectable } from "@angular/core";
// import { Http, Headers, Response } from "@angular/http";
import { VoixHttpService } from "../voix-http/voix-http.service";
import { map } from "rxjs/operators";
import { CryptoService } from "../services/crypto.service";
import { of } from "rxjs/internal/observable/of";
import { CookieService } from 'ngx-cookie-service';
import { environment } from "environments/environment";

@Injectable({
  providedIn:'root'
})
export class AuthenticationService {
  userLogin: boolean;
  userDetails: any;
  constructor(
    // private http: Http,
    private VoixHttp: VoixHttpService,
    private crypto: CryptoService,
    private cookieService: CookieService,
  ) {}

  login(email: string, password: string) {
    let param = { email: email, password: password };
    return this.VoixHttp.post("v1/agents/login", param).pipe(
      map((response: Response) => {
        // login successful if there's a jwt token in the response
        let user:any = response;
        if (user.success) {
          if (!('isAdminBuild' in environment)) {
            user.user.permissions = user.user.roles?.[0]?.role_details?.permissions.map(permission => permission?.permission_details?.name);
            localStorage.setItem("agentCurrentUser", JSON.stringify(user));
            localStorage.setItem("userPermissions", JSON.stringify(user.user.permissions));
          } else {
            user.user.permissions = user.user.roles?.[0]?.role_details?.permissions.map(permission => permission?.permission_details?.name);
            const userStringify = JSON.stringify(user);
            localStorage.setItem("agentCurrentUser", userStringify);
            const userData = {...(user?.user || {})};
            delete userData?.permissions;
            delete userData?.roles;
            delete userData?.clients;
            const subDomain = environment?.['ssoSubDomain'];
            this.cookieService.set('agentToken', user.token, null, '/', subDomain, true);
            this.cookieService.set('agentUser', JSON.stringify(userData), null, '/', subDomain, true);
            this.cookieService.set('userPermissions', JSON.stringify(user.user?.permissions || []), null, '/', subDomain, true);
            this.cookieService.set('agentRoles', JSON.stringify(user.user?.roles || []), null, '/', subDomain, true);
            this.cookieService.set('agentClients', JSON.stringify(user.user?.clients || []), null, '/', subDomain, true);
            this.crypto.Encrypting("agentSecret", user.user.role);
          }
        }
        return user;
      })
    );
  }

  userDetail() {
    let userObject = JSON.parse(localStorage.getItem("agentCurrentUser"));
    if (userObject) {
      return of(userObject);
      // return this.VoixHttp.get("v1/agent");
    } else {
      window.location.href = "/welcome";
    }
  }

  openAudioBrowser() {
    window.open(
      "/sip-audio-browser",
      "SIP session window",
      "resizable=no,scrollbars=no,status=yes,height=145, top=200, left=960"
    );
  }

  isUserLogin() {
    if (!('isAdminBuild' in environment)) {
      return localStorage.getItem("agentCurrentUser") ? true : false;
    }
    if (!this.cookieService.get('agentToken')) {
      localStorage.removeItem("agentCurrentUser");
      return false;
    }
    let agentCurrentUser: any = localStorage.getItem("agentCurrentUser");
    if (!agentCurrentUser) {
      agentCurrentUser = {};
      agentCurrentUser.token = this.cookieService.get('agentToken');
      agentCurrentUser.user = JSON.parse(this.cookieService.get('agentUser') || null);
      agentCurrentUser.user.permissions = JSON.parse(this.cookieService.get('userPermissions') || null);
      agentCurrentUser.user.roles = JSON.parse(this.cookieService.get('agentRoles') || null);
      agentCurrentUser.user.client = JSON.parse(this.cookieService.get('agentClients') || null);
      if (agentCurrentUser?.token) {
        localStorage.setItem("agentCurrentUser", JSON.stringify(agentCurrentUser));
        this.crypto.Encrypting("agentSecret", agentCurrentUser.user.role);
      } else {
        agentCurrentUser = null;
      }
    }
    return !!agentCurrentUser;
  }

  logout() {
    if (!('isAdminBuild' in environment)) {
      localStorage.removeItem("agentCurrentUser");
      localStorage.removeItem("currentRestaurant");
      localStorage.removeItem("userPermissions");
      return;
    }
    if (this.isUserLogin()) {
      this.updateAgentStatus("OFFLINE");
    }
    // remove user from local storage to log user out
    localStorage.removeItem("agentCurrentUser");
    localStorage.removeItem("currentRestaurant");
    this.cookieService.deleteAll('/', environment.ssoSubDomain);
  }

  updateAgentStatus(status) {
    return this.VoixHttp.patch("v1/agent/status", { online_status: status });
  }

  updateSettingAgentStatus(status, restaurant_id, agent_id) {
    return this.VoixHttp.put(
      "v1/agent-restaurants/" + restaurant_id + "/?role=admin",
      { online_status: status, agent_id: agent_id }
    );
  }
  getCurrentUser(){
    let user = localStorage.getItem("agentCurrentUser");
    return user ? JSON.parse(user).user:null;
  }

  getLoggedInUser() {
    if (!('isAdminBuild' in environment)) {
      return this.VoixHttp.get("v1/agent");
    }
    return this.VoixHttp.get("v1/agent", {}, false, true);
  }
}
