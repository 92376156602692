import { Injectable } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
import { VoixHttpService } from "../voix-http/voix-http.service";

@Injectable({
  providedIn: "root",
})
export class getRestaurantService {
  restaurantSelected$: Observable<any>;
  private restaurantSelected = new Subject<any>();
  public getrestaurant = {};
  constructor(private voixHttp: VoixHttpService) {
    this.restaurantSelected$ = this.restaurantSelected.asObservable();
  }

  setRestaurant(data) {
    //console.log(data); // I have data! Let's return it so subscribers can use it!
    if (data) {
      localStorage.setItem("currentRestaurant", JSON.stringify(data));
      // we can do stuff with data if we want
      this.restaurantSelected.next(data);
    }
  }

  restaurant() {
    if (localStorage.getItem("currentRestaurant")) {
      this.getrestaurant = JSON.parse(
        localStorage.getItem("currentRestaurant")
      );
      return of(this.getrestaurant);
    } else {
      return of({});
    }
  }
  getRestaurantDetails(restaurantphone: string) {
    var getParam =
      '{user_restaurant_details(phoneNumber: "' +
      restaurantphone +
      '") { restaurant_id, restaurants { address_1, delivery_fee, delivery_radius,external_location_id, client_id, client {name,logo_url}, mode_of_operation,landmark,nick_name, name, configurations(component_name: "dashboard", component_key: "address_swne") { component_value } } }}';

    return this.voixHttp.get("v1/graphql-gateway/", {
      params: { query_object: getParam },
    });
  }
  // Service message commands
  emitChange(change: any) {
    this.restaurantSelected.next(change);
  }
}
