<div class="rows">
  <div class="col-md-6">
    <div class="form-group">
      <label for="inputRole-1">Name*</label>
      <input
        class="form-control"
        placeholder="Enter role name"
        id="inputRole-1"
        [(ngModel)]="role.Name"
        type="text"
        required
        maxlength="50"
        [disabled]="isPermissionPolicy"
      />
      <small *ngIf="!errorMessages.Name.Valid" class="error-message">{{
        errorMessages.Name.Message
      }}</small>
    </div>
    <div class="form-group">
      <label for="inputRoleDescription-1">Description*</label>
      <input
        placeholder="Enter role description"
        class="form-control"
        [(ngModel)]="role.Description"
        id="inputRoleDescription-1"
        type="text"
        [disabled]="isPermissionPolicy"
        required
      />
      <small *ngIf="!errorMessages.Description.Valid" class="error-message">{{
        errorMessages.Description.Message
      }}</small>
    </div>
    <div class="form-group" *ngIf="selectedFormType == 'Modify'">
      <div class="form-check">
        <input
          class="form-check-input"
          [(ngModel)]="role.IsActive"
          (change)="isActiveChange()"
          type="checkbox"
          id="checkOne"
          [disabled]="isPermissionPolicy"
          required
        />
        <label class="form-check-label pl5" for="checkOne">Is Active</label>
      </div>
      <small *ngIf="!errorMessages.IsActive.Valid" class="error-message">{{
        errorMessages.IsActive.Message
      }}</small>
    </div>
  </div>
</div>
<div class="mb-3">
  <table
    class="table table-bordered table-responsive table-striped table-hover"
  >
    <thead>
      <tr>
        <th class="table-heading" scope="col">
          <input
            (click)="SelectAllPermission($event.target.checked)"
            [checked]="allPermissionSelected"
            [(ngModel)]="allPermissionSelected"
            type="checkbox"
            class="selection-heading"
            [disabled]="isPermissionPolicy"
          />
          {{ "Selection" | uppercase }}
        </th>
        <th class="table-heading" scope="col">
          {{ "Permission Name" | uppercase }}
        </th>
        <th class="table-heading" scope="col">
          {{ "Description" | uppercase }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let permission of permissions">
        <td [attr.for]="permission.ID">
          <c-form-check>
            <input
              cFormCheckInput
              [id]="permission.ID"
              (click)="role.IsPermissionsModified = true"
              [checked]="permission.Selected"
              [(ngModel)]="permission.Selected"
              type="checkbox"
              [disabled]="isPermissionPolicy"
            />
          </c-form-check>
        </td>
        <td>{{ permission.Permission }}</td>
        <td>{{ permission.Description }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!errorMessages.Permissions.Valid" class="align-center">
  <small class="error-message">{{ errorMessages.Permissions.Message }}</small>
</div>
<div class="row">
  <div class="col-md-12 action-btns">
    <div class="back-button">
      <button (click)="Back()" class="btn btn-outline-dark btn-sm">
        <i
          class="fa fa-arrow-left anchor-btn btn-action-buttons-icon"
          aria-hidden="true"
        ></i>
        <span>Back</span>
      </button>
    </div>
    <div class="flex">
      <button
        *ngIf="selectedFormType == 'Add'"
        (click)="AddRole()"
        class="btn btn-outline-dark btn-sm btn-action-buttons"
      >
        <i class="fa fa-plus anchor-btn" aria-hidden="true"></i> Add Role
      </button>
      <button
        *ngIf="selectedFormType == 'Add'"
        style="margin-left: 5px"
        (click)="ResetForm()"
        class="btn btn-outline-dark btn-sm btn-action-buttons"
      >
        <i class="fa fa-refresh anchor-btn" aria-hidden="true"></i> Reset
      </button>
      <button
        *ngIf="selectedFormType == 'Modify'"
        (click)="ModifyRole()"
        class="btn btn-outline-dark btn-sm btn-action-buttons"
      >
        <i class="fa fa-pencil anchor-btn" aria-hidden="true"></i> Modify Role
      </button>
    </div>
  </div>
</div>
