<div class="main-content main-messenger no-scroll ">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9 chat-window-group" [ngStyle]="{'min-height': docHeight + 'px'}">
        <div *ngFor="let windowitem of chatWindows; let i = index" [attr.data-index]="i">
          <app-chat-window [indexValue]="i" [chatWindowInfo]="windowitem" (chatWindowClose)="closeConversation($event)" (sendSMS)="sendSMS($event)" (refreshChatConversation)="getConversation($event)"></app-chat-window>
        </div>
      </div>
      <div class="col-md-3 side-border">
        <app-sms-messenger-sidebar (eventEmitChatWindow)="openChatWindow($event)" (assignToAgent)="assignToMe($event)" [openWindows]="chatWindows" [listOfContacts]="listOfContacts"></app-sms-messenger-sidebar>
      </div>
    </div>
  </div>
</div>