import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import * as _ from 'underscore';
@Injectable({
  providedIn: "root",
})
export class menuService {
  //V2
  allData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  secondFormReset: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  removeLeftFormSelectedItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allDataSecond: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  EditItem: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allDataThird: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  halfMenu: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  removecart: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  constructor() {}
  getVariationCollections(name, menu, foodType) {
    var variationObj;
    var collectionList = {};
    variationObj = _.find(menu, function (menuData) {
      return (
        menuData.name.toLowerCase() === name.toLocaleLowerCase() &&
        menuData.MenuCategory.name.toLowerCase() ===
          foodType.toLocaleLowerCase()
      );
    });

    if (variationObj) {
      variationObj.MenuVariationCollections.forEach((element) => {
        collectionList[element.MenuVariation.name] = collectionList[
          element.MenuVariation.name
        ]
          ? collectionList[element.MenuVariation.name]
          : {};
        collectionList[element.MenuVariation.name].allow_multiple =
          element.MenuVariation.allow_multiple;
        collectionList[element.MenuVariation.name].data = collectionList[
          element.MenuVariation.name
        ].data
          ? collectionList[element.MenuVariation.name].data
          : [];
        collectionList[element.MenuVariation.name].data.push(
          element.MenuVariationValueCollection
        );
      });
    }
    return collectionList;
  }

  getFoodTypeMenu(menu, foodType) {
    var menuList = _.filter(menu, function (v) {
      return (
        _.isObject(v.MenuCategory) &&
        v.MenuCategory.name.toUpperCase() == foodType.toUpperCase()
      );
    });
    menuList = _.sortBy(menuList, "name");
    //console.log("menuList", menuList);
    return menuList;
  }
}
