<div class="main-container">
  <div class="inner-container">
    <div class="title">Reset Password</div>
    <button
      mat-icon-button
      mat-dialog-close
      matTooltip="Close"
      class="close-btn"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form method="#" action="#" [formGroup]="resetPasswordForm">
    <mat-form-field class="add-tag-input-section">
      <mat-label>New Password</mat-label>
      <input
        [type]="newPasswordHide ? 'text' : 'password'"
        matInput
        placeholder="Enter Password"
        [formControl]="resetPasswordForm.controls['new_password']"
      />
      <mat-icon matSuffix (click)="newPasswordHide = !newPasswordHide">{{
        newPasswordHide ? "visibility" : "visibility_off"
      }}</mat-icon>
    </mat-form-field>
    <mat-form-field class="add-tag-input-section">
      <mat-label>Confirm Password</mat-label>
      <input
        [type]="confirmPasswordHide ? 'text' : 'password'"
        matInput
        placeholder="Enter Password"
        [formControl]="resetPasswordForm.controls['confirm_password']"
      />
      <mat-icon
        matSuffix
        (click)="confirmPasswordHide = !confirmPasswordHide"
        >{{ confirmPasswordHide ? "visibility" : "visibility_off" }}</mat-icon
      >
      <div class="confirm-password">
        <mat-error *ngIf="resetPasswordForm.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </div>
    </mat-form-field>
    <div mat-dialog-actions fxFlexAlign="center" class="action-buttons">
      <button mat-stroked-button mat-dialog-close class="button__cancel">
        Cancel
      </button>
      <button
        mat-raised-button
        class="btn-action-buttons"
        (click)="submit()"
        [disabled]="!resetPasswordForm.valid"
      >
        Submit
      </button>
    </div>
  </form>
</div>
