import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
@Pipe({name: 'slotsFilter'})
export class SlotsFilterPipe implements PipeTransform {
  transform(obj: any, args: any = {key: 'slots'}): any[] {
    //console.log("slotsFilter", obj, args);
    var dataArr = [];
    if(!_.isEmpty(obj)) {
      obj = typeof obj == 'object' ? obj : JSON.parse(obj);
      obj =  (obj.currentIntent) ? obj.currentIntent : {};
      
      // return the resulting array
      if(args.key === 'slots' && !_.isEmpty(obj)) {
        obj.slots = (obj.slots) ? obj.slots : {};
        obj.slots = typeof obj.slots == 'object' ? obj.slots : JSON.parse(obj.slots);
        Object.keys(obj.slots).forEach(function(key, index) {
          dataArr.push({key: key, value: obj.slots[key]});
        });
      } else {
        return obj[args.key];
      }
    }
    
    return dataArr;
  }
}