import { Injectable, TemplateRef } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { ToastrService } from "ngx-toastr";

declare var $: any;
export interface Toast {
	template: TemplateRef<any>;
	classname?: string;
	delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private snackBarRef: MatSnackBarRef<unknown>;
	toasts: Toast[] = [];

  constructor(private _snackBar: MatSnackBar, private toastr: ToastrService) {}
  
  showToast(toast: Toast) {
		this.toasts.push(toast);
	}

	removeToast(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clearToast() {
		this.toasts.splice(0, this.toasts.length);
	}
  
  show(
    msgType,
    iconText,
    messageText,
    horizontalAlign = "right",
    timer = 2500
  ) {
    const type = ["", "info", "success", "warning", "danger"];
    const color = Math.floor(Math.random() * 4 + 1);
    $.notify(
      {
        icon: "notifications",
        message: messageText,
      },
      {
        type: msgType,
        timer: timer,
        placement: {
          from: "bottom",
          align: horizontalAlign,
        },
      }
    );
  }

  showCashDisable(msgType, iconText, messageText, horizontalAlign = "right", timer = 2500) {
    const type = ["", "info", "success", "warning", "danger"];
    $.notify(
      {
        icon: "notifications",
        message: messageText
      },
      {
        type: msgType,
        timer: timer,
        placement: {
          from: "top",
          align: horizontalAlign
        },
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="message">{2}</span>' +
          '</div>',
        animate: {
          enter: "animated fadeInDown",
          exit: "animated fadeOutUp"
        },
        icon_type: "class",
        allow_dismiss: true,
        newest_on_top: true,
        delay: timer,
        offset: {
          x: 15,
          y: 15
        },
        template_object: {
          icon_type: iconText
        },
        onShow: function () {
          this.css({
            "background-color": "#f44336",
            "color": "#ffffff"
          });
        }
      }
    );
  }
  
  openSnackBarV2(horizontalPosition, verticalPosition, message, panelClass, toastrClass={}) {
    if (panelClass === 'danger-snackbar') {
      this.toastr.error(message, 'Error!');
    } else if (panelClass === 'success-snackbar') {
      this.toastr.success(message, 'Success!', toastrClass);
    } else if (panelClass === 'warning-snackbar') {
      this.toastr.warning(message, 'Warning!');
    } else {
      this.snackBarRef = this._snackBar.open(message, "", {
        duration: 2000,
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        panelClass: [panelClass],
      });
    }
  }

  openSnackBarActionV2(
    horizontalPosition,
    verticalPosition,
    message,
    panelClass,
    toastrClass={}
  ) {
    if (panelClass === 'danger-snackbar') {
      this.toastr.error(message, 'Error!');
    } else if (panelClass === 'success-snackbar') {
      this.toastr.success(message, 'Success!', toastrClass);
    } else if (panelClass === 'warning-snackbar') {
      this.toastr.warning(message, 'Warning!');
    } else {
      this.snackBarRef = this._snackBar.open(message, "Close", {
        horizontalPosition: horizontalPosition,
        verticalPosition: verticalPosition,
        panelClass: [panelClass],
      });
    }
  }

  dismiss() {
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  showV2(
    msgType,
    iconText,
    messageText,
    horizontalAlign = "right",
    timer = 1000
  ) {
    const type = ["", "info", "success", "warning", "danger"];
    const color = Math.floor(Math.random() * 4 + 1);
    $.notify(
      {
        icon: "notifications",
        message: messageText,
      },
      {
        type: msgType,
        timer: timer,
        placement: {
          from: "bottom",
          align: horizontalAlign,
        },
      }
    );
  }
}
