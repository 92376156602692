import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
@Pipe({name: 'removeSpecialsChar'})
export class RemoveSpecialsCharPipe implements PipeTransform {

    transform(obj:any, tl = false) {
      //console.log(obj);
      if(tl) {
        if(!_.isEmpty(obj) && typeof obj == 'object') {
          //console.log("RemoveSpecialsCharPipe", typeof obj);
          obj = obj.map(x => x.trim().toLowerCase());
        }
      } else { 
        obj = obj.trim().replace(/[^\w\s]/gi, '').toLowerCase().split(" ").join("_");
      }
      return obj;
    }
}