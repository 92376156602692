<div class="main-content no-scroll">
  <div class="container-fluid">
    <div class="row">
      
      <div class="col-md-8">
        <div class="card">
          <div class="card-header" data-background-color="voix-blue">
            <h4 class="title">Custom Reponses</h4>
            <!-- <p class="category">Here is a subtitle for this table</p> -->
          </div>
          <div class="card-content table-responsive">
            <mat-table [dataSource]="dataSource">

              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef class="ids"> Id</mat-header-cell>
                <mat-cell *matCellDef="let row" class="ids"> {{row.id}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="text">
                <mat-header-cell *matHeaderCellDef >Custom Text</mat-header-cell>
                <mat-cell *matCellDef="let row"> 
                  <span *ngIf="!row?.edit"><a (click)="edit(row)" class="pointer-cursor"><mat-icon >edit</mat-icon></a> {{row?.text || '- N/A -'}}</span>

                  <mat-form-field *ngIf="row?.edit" class="width-90">
                    <input matInput value="{{row?.text}}" (input)="onChangeProperty(row, $event.target.value, 'text')">
                    <button mat-button *ngIf="row?.edit" matSuffix mat-icon-button aria-label="done" (click)="update(row)" class="updateInstruction">
                      <mat-icon class="apply">done</mat-icon>
                    </button>
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="tags" >
                <mat-header-cell *matHeaderCellDef class="tags">Tag</mat-header-cell>
                <mat-cell *matCellDef="let row" class="tags"> 
                  <span *ngIf="!row?.editTag"><a (click)="editTag(row)" class="pointer-cursor"><mat-icon >edit</mat-icon></a> {{row?.tag?.name || '- N/A -'}}</span>

                  <mat-form-field *ngIf="row?.editTag" class="width-90">
                    <mat-select placeholder="Status" (selectionChange)="onChangeProperty(row, $event.value, 'tag_id')">
                        <mat-option *ngFor="let tag of tags" [value]="tag.id">
                            {{ tag.name }}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                
                  <a (click)="delete(row)" class="pointer-cursor trash"><mat-icon class="delete">delete_forever</mat-icon></a>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table>
            <mat-paginator [length]="customResponseData?.count" [pageSize]="limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePage($event)">
            </mat-paginator>

          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card">
          <div class="card-content table-responsive">
            <form method="#" class="customResponse" action="#" [formGroup]="customResponse" (ngSubmit)="submitForm(customResponse.value)">
                <div class="row">    
                  <div class="col-md-12">
                    <mat-form-field class="width-100">
                      <textarea  matInput placeholder="Custom Reponse" [formControl]="customResponse.controls['text']"></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field class="width-100">
                      <mat-select placeholder="Select Tag" [formControl]="customResponse.controls['tag_id']">
                        <mat-option *ngFor="let tag of tags" [value]="tag.id">
                            {{ tag.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12 text-center">
                    <button type="submit" class="btn btn-primary btn-round" [disabled]="!customResponse.valid">Submit</button>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>