import { Component, Inject, Input, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API_URLS } from 'app/core/constants/api-urls';
import { NotificationService } from 'app/core/notification/notification.service';
import { VoixHttpService } from 'app/core/voix-http/voix-http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'vx-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css'],
})
export class ResetPasswordDialogComponent {
  resetPasswordForm: FormGroup;
  newPasswordHide = false;
  confirmPasswordHide = false;
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    fb: FormBuilder,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxService: NgxUiLoaderService
    ) {
    this.resetPasswordForm = fb.group({
      'new_password' : [null, Validators.required],
      'confirm_password': [null, Validators.required],
    }, {validator: this.checkPasswords });
  }

  public confirmMessage:string;

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    if(group.controls.confirm_password.value) {
      let pass = group.controls.new_password.value;
      let confirmPass = group.controls.confirm_password.value;
  
      return pass === confirmPass ? null : { notSame: true }     
    }
  }


  submit() {
    const data = {
      agent_id: this.data.agentId,
      password: this.resetPasswordForm.get('new_password').value,
      confirmPassword: this.resetPasswordForm.get('confirm_password').value,
    }
    this.ngxService.start();
    this.voixHttp.patch(API_URLS.Reset.Patch, data).subscribe((response) => {
      if (response.success) {
        this.notification.show('success','', response.data.message);
        this.dialogRef.close();
      }
      this.ngxService.stop();
    }, () => this.ngxService.stop())
  }
}