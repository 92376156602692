import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  feedbackDefaultState,
  feedbackState,
} from "app/core/ngxs/state/feedback.state";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import * as moment from "moment";
import { orderDefaultState, orderState } from "app/core/ngxs/state/order.state";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { generalService } from "app/core/services/general.service";

@Component({
  selector: "app-order-dialog",
  templateUrl: "./order-dialog.component.html",
  styleUrls: ["./order-dialog.component.css"],
})
export class OrderDialogComponent {
  @Select(feedbackState.getFeedback)
  feedbackState: Observable<feedbackDefaultState>;
  @Select(orderState.getOrder)
  orderState: Observable<orderDefaultState>;
  posForm: FormGroup;
  responseData: any;
  callLoyaltyEnroll: any;
  loyaltyEnrollStatusBtn: boolean;
  feedbacks: any[] = [];
  phone: string = "";
  cartNumber: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<OrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router,
    private generalService: generalService,
  ) {
    this.responseData = data;
    this.callLoyaltyEnroll = this.responseData?.onLoyaltyEnrollClick;
    this.loyaltyEnrollStatusBtn = this.responseData?.order?.data?.isLoyaltyApplicable && this.generalService.isLoyaltyIvrFlowEnabled && this.responseData?.isLoyaltyEnrollmentIVREnabled;
    this.getPhoneNumber();
    this.cartNumber = this.fb.group({
      phone: [
        this.phone,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
    });
  }

  getPhoneNumber() {
    let CP: any = this.router.url.split("/");
    this.phone = CP[2];
    console.log(CP[3]);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  changeDateFormat(date) {
    console.log(date);
    return moment(new Date(date)).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  removeTimeZonePart(dateString) {
    let finalDate = "";

    if (dateString.split("+").length > 1) {
      let b = dateString.split("+");

      finalDate = b[0];
    } else {
      let b = dateString.split("-");

      if (b.length > 1) {
        b.pop();
        finalDate = b.join("-");
      }
    }

    return finalDate;
  }

  selectfeedback(feedback) {
    this.feedbacks.push(feedback);
    console.log(this.feedbacks);
  }
  submit() {
    this.dialogRef.close();
    window.location.assign("/dashboard");
  }
  loyaltyEnroll() {
    if(this.callLoyaltyEnroll) {
      this.callLoyaltyEnroll();
    }
  }
  placeNewOrder() {
    let CP: any = this.router.url.split("/");
    let urlGenerate = CP[3];
    let restaurantPhoneNumber = CP[4];
    // let restaurantno =
    //   this.restaurant.UserRestaurant.RestaurantPhones[0].phone_number;
    window.location.href =
      "/order-receive/" +
      this.cartNumber.value.phone +
      "/" +
      urlGenerate +
      "/" +
      restaurantPhoneNumber;
  }
}
