import { IRoleListing } from './../model/role-listing.model';
import { Component, OnInit } from '@angular/core';
import { ComponentService } from 'app/core/services/component.service';
import { RolesAndPermissionsService } from '../../core/services/roles-and-permissions.service';
import { IPermission } from '../model/peremission.model';
import Swal from 'sweetalert2';
import { Permissions } from 'app/core/auth/model/permissions.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-role-listing',
  templateUrl: './role-listing.component.html',
  styleUrls: ['./role-listing.component.scss']
})
export class RoleListingComponent implements OnInit {

  roleListing: Array<IRoleListing> = []
  userPermissions = Permissions;
  public currentuser: any;
  constructor(private componentService: ComponentService
    , private rolesAndPermissionService: RolesAndPermissionsService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.LoadRoleListing();
  }
  DeleteRole(role: IRoleListing) {
    let msg = `Permissions for ${role.Name} will also be deleted, Do you want to delete ${role.Name}?`;
    if (role?.userAttached?.length) {
      const attachedUsers = role?.userAttached.map(user => user.user_id).toString();
      msg = `Can't delete role because following users are attached ${attachedUsers}`
    }
    Swal.fire({
      title:
      msg,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: !role?.userAttached?.length,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Delete ${role.Name}`,
    }).then((result) => {
      if (result.value && !role?.userAttached?.length) {
        this.ngxService.start();
        this.rolesAndPermissionService.DeleteRole(role.ID).subscribe({
          next: data => {
            this.LoadRoleListing();
            let successMessage: string = data.data as string;
            this.ngxService.stop();
            Swal.fire(
              successMessage,
              'Role with permissions successfully deleted.',
              "success"
            );
          },
          error: (error) => {
            this.ngxService.stop();
            Swal.fire(
              'Role Not Deleted',
              error?.error || 'May be that role assigned to any agent, please unassign and try again',
              "error"
            );
          }
        });
      }
    });

  }
  ModifyRole(ModifyRole: IRoleListing) {
    this.rolesAndPermissionService.GetRolePermissions(ModifyRole.ID).subscribe(data => {
      let listOfSelectedRolePermissions: Array<IPermission> = [];
      data.forEach(rolePermission => {
        listOfSelectedRolePermissions.push({
          ID: rolePermission.PermissionID,
          Selected: false
        });
      });
      ModifyRole.Permissions = listOfSelectedRolePermissions;
      this.componentService.SetSelectedButtonType("Modify");
      this.componentService.LoadRoleAddModifyComponent({
        ComponentName: "ModifyRole",
        Role: ModifyRole
      });
    });
  }

  LoadRoleListing() {
    this.ngxService.start();
    this.rolesAndPermissionService.GetRoles(false).subscribe(data => {
      this.roleListing = data;
      this.ngxService.stop();
    },
    () => this.ngxService.stop());
  }
}
