import { Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'app/core/notification/notification.service';
import { PermissionsPipe } from 'app/core/pipes/permissions.pipe';
import { ComponentService } from 'app/core/services/component.service';
import { UtilityService } from 'app/core/services/Utility.service';
import { VoixHttpService } from 'app/core/voix-http/voix-http.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { AddStoreComponent } from '../add-store/add-store.component';
import { StoreListingComponent } from '../store-listing/store-listing.component';
import { UploadedStoreListComponent } from '../uploaded-store-list/uploaded-store-list.component';
import { HostListener } from '@angular/core';
import { Permissions } from 'app/core/auth/model/permissions.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { IStoreLoadComponentDetail, IStoreUploadCSVDetails } from '../model/IStore';

@Component({
  selector: 'app-view-stores',
  templateUrl: './view-stores.component.html',
  styleUrls: ['./view-stores.component.scss']
})
export class ViewStoresComponent implements OnDestroy {
  @ViewChild('componentPlaceHolder', { read: ViewContainerRef }) addRoleComponent: ViewContainerRef | undefined;
  @ViewChild('fileImportInput') fileImportInput: any;
  initialComponent: string = "StoreListing";
  defaultButtonText: string = "AddNew";
  formTitle: string;
  isAddAgentPermission = false;
  dialogRef = null;
  clientID: number;
  isSmallScreen = false;
  userPermissions = Permissions;

  constructor(private utilityService: UtilityService,
    private componentService: ComponentService,
    public permissionPipe: PermissionsPipe,
    private ngxCsvParser: NgxCsvParser,
    public dialog: MatDialog,
    private notification: NotificationService,
    private voixHttp: VoixHttpService,
    private ngxService: NgxUiLoaderService,
    private clipboard: Clipboard
    ) { }

  ngOnInit() {
    this.utilityService.SetPageTitle("View Stores");
    this.UpdateSelectedButtonType();
    this.componentService.LoadStoreAddComponentSubscription().subscribe(data => {
      // this.isAddAgentPermission = this.permissionPipe.transform(Permissions.AGENT_ADD);
    this.loadComponent(data);
    });
    this.componentService.SetSelectedButtonType("AddNew");
    this.componentService.getSelectedClientForStore().subscribe(client=>{
      this.clientID = client?.id
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const screenWidth = window.innerWidth;
    if(screenWidth < 1335){
      this.isSmallScreen = true
    }else{
      this.isSmallScreen = false
    }
  }

  UpdateSelectedButtonType() {
    this.componentService.GetSelectedButtonType().subscribe((type)=> this.defaultButtonText = type);
  }
  ngAfterViewInit(): void {
    this.componentService.LoadStoresAddModifyComponent({
      ComponentName: this.initialComponent
    });
  }

  AddNew() {
    this.componentService.SetSelectedButtonType("Back");
    // this.LoadComponent("AddAgent");
    this.componentService.LoadStoresAddModifyComponent({
      ComponentName: "AddStore",
    });
  }

  loadComponent(data: IStoreLoadComponentDetail){
    let componentName: string = data?.ComponentName;
    this.addRoleComponent?.clear();
    switch (componentName) {
      case "StoreListing":
        // const isViewPermission = this.permissionPipe.transform(Permissions.AGENT_VIEW);
          this.formTitle = "List Of Stores";
          this.addRoleComponent?.createComponent(StoreListingComponent, {
          });
        break;
      case "AddStore":
          this.formTitle = "Add New Store";
          let ref = this.addRoleComponent?.createComponent(AddStoreComponent);
          let component: AddStoreComponent = ref?.instance as AddStoreComponent;
          component.selectedFormType = "Add";
          // component.store = data.Store;
        break;
      case "ModifyStore":
          this.formTitle = "Modify Store";
          let refModify = this.addRoleComponent?.createComponent(AddStoreComponent);
          let componentModify: AddStoreComponent = refModify?.instance as AddStoreComponent;
          componentModify.selectedFormType = "Modify";
          componentModify.store = data.Store;
        break;
    }
  }
  submit(stores){
    let url = 'v1/user/restaurant/bulk-create';
    this.ngxService.start();
    this.voixHttp.uploadCsvForStores(url,stores).subscribe((res:any)=>{
      let successMessage: string = res.data as string;
      this.dialogRef.close();
      const allExistingStores = [...(res?.existStores || []), ...(res?.existingStoresPhoneNumber || [])]
      if (!allExistingStores.length) {
        Swal.fire(
          successMessage,
          '('+ res?.storeCreatedCount +') Stores created successfully.',
          "success"
        );
      } else {
        let storeNumber = '';
        let storePhoneNumber = '';
        let htmlText = '<div style="height: 150px; overflow-y: auto;">';
        if (res?.existStores?.length) {
          htmlText += "Store ID : ";
          res?.existStores.forEach((store) => {
            storeNumber += store.StoreID + '<br>';
          });
          htmlText += storeNumber;
        }
        if (res?.existingStoresPhoneNumber?.length) {
          htmlText += "Store Phone Number : ";
          res?.existingStoresPhoneNumber.forEach((store) => {
            storePhoneNumber += store.Phone + '<br>';
          });
          htmlText += storePhoneNumber;
        }

        Swal.fire({
          title: res?.storeCreatedCount +' Store(s) created successfully, but the following '+ allExistingStores.length +' record(s) already exist.',
          width: 500,
          html: htmlText + '</div>',
          heightAuto: false,
          scrollbarPadding: true,
          grow: 'row',
          icon: 'info',
          confirmButtonText: 'OK',
          showCancelButton: true,
          cancelButtonText: 'Copy',
          reverseButtons: true
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.cancel) {
            this.copyToClipBoard(storeNumber ? storeNumber : storePhoneNumber);
            Swal.fire('Copied!', 'Store ID/Store Phone Number has been copied.', 'success');
          }
        });
      } 
      this.ngxService.stop();
      this.loadComponent({ComponentName: "StoreListing"});
    },error=>{
      Swal.fire("Service Interruption Error");
      this.ngxService.stop();
    })
  }

  copyToClipBoard(storeNumber: string) {
    storeNumber = storeNumber.replace(/<br>/g, ', ').replace(/, \s*$/, '');    
    this.clipboard.copy(storeNumber);
  }
    downloadSampleCSVFile() {  
      let csv = 'Store ID,Phone,First Name,Last Name,Address,City,State,Zip Code,Secondary Phones\n';
      [['', '','','', '', '', '', '']].forEach(function(row) {
        csv += row.join(',');
        csv += "\n";
      });
      var hiddenElement = document.createElement('a');  
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';  
      hiddenElement.download = 'Store Sample.csv';
      hiddenElement.click();  
    } 
    openFile(fileImportInput) {
      fileImportInput.click();
    }
    fileChangeListener($event): void {
      const files = $event.srcElement.files;
      this.ngxCsvParser
      .parse(files[0], {
        header: true,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe({
        next: async (result: Array<IStoreUploadCSVDetails>) => {
          this.fileImportInput.nativeElement.value = '';
          
            this.dialogRef = this.dialog.open(UploadedStoreListComponent, {
              width: "1000px",
              height: "800px",
              data: result,
            });
            
            this.dialogRef.componentInstance.agents = '';
            this.dialogRef.componentInstance.onSubmit.subscribe((result) => {
              if(result) {
                this.submit(result.csv.map((item) => {return {...item, clientId: this.clientID}}));
              }
            });
        
        },
        error: (error: NgxCSVParserError) => {
          this.notification.openSnackBarActionV2(
            "end",
            "top",
            error.message,
            "danger-snackbar"
          );
        }
      });
    }

    ngOnDestroy(): void {
      this.componentService.selectedClient = null;
    }
  }
