import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentService } from 'app/core/services/component.service';
import { VoixHttpService } from 'app/core/voix-http/voix-http.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { IStore, IStoreWebhookDetails, IStoreWebhookSuccessResponse } from '../model/IStore';
import * as _ from 'underscore';
import * as moment from 'moment';
import { NotificationService } from 'app/core/notification/notification.service';
import { AuthenticationService } from 'app/core/auth/authentication.service';
import { Permissions } from 'app/core/auth/model/permissions.model';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { Client } from '../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store-listing',
  templateUrl: './store-listing.component.html',
  styleUrls: ['./store-listing.component.scss']
})
export class StoreListingComponent {
  selectedUsersFromGrid: Array<IStore> = [] as Array<IStore>;
  @ViewChild('paginator') paginator: MatPaginator;
  allStoresSelected: boolean = false;
  multipleStoresSelected: boolean = false;
  selectedClient: Client;
  webHookDate: string;
  params = {
    limit: 10,
    page: 1,
    store: ''
  }
  storeData = {
    count: 0
  };
  storeListing: IStore[] = [];
  userPermissions = Permissions;
  constructor(
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog,
    public componentService: ComponentService,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private auth: AuthenticationService,
    public router: Router
  ) {
    this.selectedClient = this.componentService.selectedClient;
    this.onSearch = _.debounce(this.onSearch, 1000);
  }

  onSearch(value) {
    this.params = {
      ...this.params,
      page: 1,
      store: value?.toLowerCase()
    }
    // this.LoadAgentListing();
    this.selectedClientForStores(this.selectedClient);
  }

  handlePage(e:any){
    this.params = {
      ...this.params,
      page: e.pageIndex + 1,
      limit: e.pageSize,
    }
    this.selectedClientForStores(this.selectedClient);
  }

  deleteStore(store?) {
    let msg = '';
    if (store) {
      msg = 'Are you sure you want to delete the store';
    } else if (!this.selectedUsersFromGrid?.length) {
      return;
    } else {
      const count = this.selectedUsersFromGrid?.length;
      msg = `Are you sure you want to delete the ${count} store${count >= 2 ? 's' : ''}?`;
    }
    Swal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      let url = 'v1/user/restaurant/delete'
      if (result.value) {
        this.ngxService.start();
        let selectedIDs = store ? [store.id] : this.selectedUsersFromGrid.map((val:any)=> val.id);
        const options = {
          body: {
            client_code: this.selectedClient?.name,
            restaurant_ids: selectedIDs
          }
        };
        this.voixHttp.deleteStore(url, options).subscribe(() => {
          if(this.storeListing.length === 1 && this.params.page !== 1) {
            this.params.page = this.params.page - 1;
            this.paginator.pageIndex = this.params.page - 1;
          }
          this.selectedClientForStores(this.selectedClient);
          this.selectedUsersFromGrid = [];
          this.allStoresSelected = false;
          this.multipleStoresSelected = false;
          this.ngxService.stop();
          Swal.fire(
            'Store deleted successfully.',
            '',
            "success"
          );
        }, () =>{
          this.ngxService.stop();
          Swal.fire(
            'Store Not Deleted',
            'There was an error while deleting the store.',
            "error"
          );
        })
      }
    });
  }

  // SelectUnSelectAll(event) {
  //   const checkboxCheckedStatus = event.target.checked;
  //   this.storeListing.forEach(x => x.Selected = checkboxCheckedStatus);
  //   this.allStoresSelected = checkboxCheckedStatus;
  //   this.multipleStoresSelected = this.allStoresSelected;
  //   this.selectedUsersFromGrid = this.storeListing.filter(x => x.Selected == true);
  // }

  // OnRowCheckboxClicked(event: any, store: any) {
  //   var checkboxCheckedStatus = event.target.checked;
  //   store.Selected = checkboxCheckedStatus;
  //   var selectedFromGrid = this.storeListing.filter(x => x.Selected == checkboxCheckedStatus).length;
  //   var totalAgents = this.storeListing.length;
  //   this.allStoresSelected = checkboxCheckedStatus ? selectedFromGrid == totalAgents : false;
  //   this.multipleStoresSelected = this.storeListing.filter(x => x.Selected == true).length >= 1;
  //   this.selectedUsersFromGrid = this.storeListing.filter(x => x.Selected == true);
  // }

  formatDate(date: string): string {
    if (date) {
      return new DatePipe('en-US').transform(date, 'dd/MM/yyyy h:mm a', 'UTC');
    }
  }

  webHookStore(store?: IStore) {
    let msg = '';
    if (store) {
      msg = "Are you sure you want to trigger this store's webhook";
    } else if (!this.selectedUsersFromGrid?.length) {
      return;
    } else {
      const count = this.selectedUsersFromGrid?.length;
      msg = `Are you sure you want to webhook the ${count} store${count >= 2 ? 's' : ''}?`;
    }
    Swal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      let url = 'webhook';
      if (result.value) {
        this.ngxService.start();
        let selectedIDs = store ? [store.storeNumber] : this.selectedUsersFromGrid.map((val:any)=> val.storeNumber);
        const options = {
          clientcode: this.selectedClient?.name,
          stores: selectedIDs,
          identity: this.auth.getCurrentUser()?.email
        };
        this.voixHttp.webHookForStores(url, options).subscribe({
          next: (res: IStoreWebhookSuccessResponse) => {
            this.selectedClientForStores(this.selectedClient, { isWebhook: true, store, res });
            this.selectedUsersFromGrid = [];
            this.allStoresSelected = false;
            this.multipleStoresSelected = false;
            this.ngxService.stop();
          },
          error: error => {
            this.ngxService.stop();
            Swal.fire(
              'Something went wrong',
              'There was an error when webhook the store.',
              "error"
            );
          }
        })
      }
    });
  }

  modifyStore(modifyStore: IStore) {
    this.componentService.LoadStoresAddModifyComponent({
      ComponentName: "ModifyStore",
      Store: modifyStore
    });
  }

  clientSelectedForStores(selectedClient: Client) {
    this.params = {
      ...this.params,
      limit: 10,
      page: 1,
    }
    if(this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.selectedClientForStores(selectedClient);
  }

  selectedClientForStores(selectedClient: Client, opt?: IStoreWebhookDetails) {
    let params = { ...this.params };
    this.selectedClient = selectedClient;
    this.componentService.selectedClient = selectedClient;
    this.componentService.setSelectedClientForStore(selectedClient);
    const url = `v1/user/restaurant/lists?clientcode=${selectedClient.name}`
    this.ngxService.start();
    this.voixHttp.getStores(url,params).subscribe({
      next: (res) => {
        if (opt?.isWebhook) {
          const getStore = res?.data?.find(store => store.id == opt?.store?.id);
          if (getStore?.webhookModifiedDate && getStore?.webhookModifiedDate != opt?.store?.webhookModifiedDate) {
            let successMessage: string = opt.res?.message as string;
            Swal.fire(
              'Webhook received.',
              'Webhook request completed successfully.',
              "success"
            );
          } else {
            Swal.fire(
              'Something went wrong',
              'Webhook is not trigged.',
              "error"
            );
          }
        }
        this.storeListing = res.data.map((store: IStore) => {
          store.isWebhookValid = !store.webhookModifiedDate ? false : moment(store.webhookModifiedDate).isSame(moment(), 'day');
          store.createdAt = store.createdAt ? moment(store.createdAt).format('DD/MM/yyyy') : '-';
          return { ...store, Phone: store['RestaurantPhones.phone_number'] }
        });
        this.storeData.count = res.totalItems;
        this.ngxService.stop();
      },
      error: (error) => {
        this.showErrorMessage(error);
      }
    })
  }

  loadNotification(storeNumber) {
    this.router.navigate(
      ['/notification-management'],
      { queryParams: { storeNumber: storeNumber } }
    );
  }

  showErrorMessage(error) {
    this.notification.openSnackBarActionV2(
      "end",
      "top",
      error?.error?.message || error?.message || error?.error || "Something went wrong please try again",
      "danger-snackbar"
    );
    this.ngxService.stop();
  }
}
