export interface RestaurantConfigurationCSV {
restaurant_id: number;
component_name: ComponentNameEnum;
component_key: ComponentKeyEnum;
component_value: string;
}

export interface CSVData {
  restaurant_id: string;
  component_name: string;
  component_key: string;
  component_value: string;
}

export interface Store {
    storeId: string;
    storePhone: string;
  }
  
export interface Client {
    id: number;
    logo_url: string;
    name: string;
  }

export interface Configurations {
    restaurant_id: string;
    component_name: string;
    component_key: string;
    component_value: string;
}

export interface ComponentName {
  key: string;
  value: string;
}

export interface ComponentKey {
  key: string;
  value: string;
}

export interface RestaurantConfigurationPayload {
  configurations: RestaurantConfigurationCSV[];
}

export interface BulkUploadPayload {
  Configurations: RestaurantConfigurationCSV[]; 
}

export interface DeletePayload {
  id: number;
  restaurant_id: string;
  // Add any additional properties as required
}

export interface DialogData {
  data: DialogItem[];
  message: string;
  title?: string;
}

export interface DialogItem {
  restaurant_id: number;
  component_name: string;
  component_key: string;
  component_value?: string;
}

export interface CustomerFilter {
  // Define the properties for the customer filter object
}

export interface GetRestaurantConfigurationsParams {
  restaurant_id: string;
  limit: number;
  page: number;
  // Add other properties for the parameters if necessary
}

export interface RestaurantConfigurationRequest {
  configurations: RestaurantConfigurationCSV[];
}

export enum ComponentNameEnum {
    "API Dashboard" = "api_dashboard",
    "Business Engine" = "be",
    "Dashboard" = "dashboard",
    "Hub" = "hub",
  }
  
  export enum ComponentKeyEnum {
    "Fax Number" = "fax_number",
    "Post Order Actions" = "post_order_actions",
    "Post Order Actions Automated" = "post_order_actions_automated",
    "Delivery Flow Position" = "delivery_flow_position",
    "Pos Password" = "pos_password",
    "Pos Url" = "pos_url",
    "Pos Username" = "pos_username",
    "Timezone" = "timezone",
    "Delivery Config" = "delivery_config",
    "Disable Drinks Asking" = "disable_drinks_asking",
    "Disable Matching Special" = "disable_matching_special",
    "Enable Reorder" = "enable_reorder",
    "Phone Start Conversation After With Name" = "phone_startConversation_afterWithName",
    "Phone Start Conversation After With No Name" = "phone_startConversation_afterWithNoName",
    "Phone Start Conversation Beginning With Name" = "PhoneStartConversationBeginningWithName",
    "Phone Start Conversation Order" = "phone_startConversation_order",
    "Phone Start Conversation Press Zero" = "phone_startConversation_beginningWithName",
    "Welcome Message After Sequence Reference" = "welcome_message_after_sequence_reference",
    "Welcome Message Beginning Sequence Reference" = "welcome_message_beginning_sequence_reference",
    "Address SWNE" = "address_swne",
    "Agent Initiated Transfer Message" = "agent_initiated_transfer_message",
    "Alternate Phones" = "altphones",
    "Customer Initiated Transfer Message" = "customer_initiated_transfer_message",
    "Dashboard Ready Timeout Second" = "dashboard_ready_timeout_sec",
    "Dtmf Forward To Voix" = "dtmf_forward_to_voix",
    "Elastic Search Action" = "elastic_search_action",
    "Enable Forward Repeat Caller" = "enable_forward_repeat_caller",
    "Fillers" = "fillers",
    "Non Rest Hours Message" = "non_rest_hours_message",
    "Non Voix Hours Message" = "non_voix_hours_message",
    "Off Hold Message" = "off_hold_message",
    "On Hold Message" = "on_hold_message",
    "Play Acknowledge Message" = "play_ack_message",
    "Restaurant Parallel Listen Number" = "restaurant_parallel_listen_number",
    "Restaurant Parallel Listen On" = "restaurant_parallel_listen_on",
    "SMS From Number" = "sms_from_number",
    "Third Party Transfer Message" = "third_party_transfer_message",
    "Wait Message" = "wait_message",
    "Welcome Message" = "welcome_message",
    "Whisper Message" = "whisper_message",
    "Agent Availability Delay" = "agent_availability_delay",
    "Non English Digit" = "non_english_digit",
    "Category Sort" = "category_sort",
    "Delivery Address Zone" = "delivery_address_zone",
    "Maximum Cash Order Amount" = "max_cash_order_amt",
    "AI ENABLED" = "ai_enabled",
    "SIP URI" = "sip_uri",
    "Maximum Cash Order Delivery" = "max_cash_order_amt_delivery",
    "Maximum Cash Order Amount Pickup" = "max_cash_order_amt_pickup",
    "Maximum Cash Order Amount Carryout" = "max_cash_order_amt_carryout",
    "Maximum Cash Order Amount Curbside" = "max_cash_order_amt_curbside",
    "KVM URL" = "kvm_url",
    "FOUR WEEK OFFER ID" = "four_week_offer_id",
    "Loyalty Enrollment IVR Enabled" = "loyalty_enrollment_ivr_enabled",
  }
