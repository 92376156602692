import { DecimalPipe } from "@angular/common";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { Select } from "@ngxs/store";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { storeDefaultState, storeState } from "app/core/ngxs/state/store.state";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { generalService } from "app/core/services/general.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable } from "rxjs";
import { ITipModel, TipActions, TipType } from "./model/tip.model";

@Component({
  selector: "app-tip",
  templateUrl: "./tip.component.html",
  styleUrls: ["./tip.component.scss"],
  providers: [DecimalPipe],
})
export class TipComponent implements OnInit {
  tip: number = 0;
  tipValue: number = 0;
  cartData: any;
  maxLimitValu: number = 0;
  selectedTip: string = "AMOUNT";
  showTipError: boolean = false;
  tipErrorText: string = "Tip amount is more than ordeer amount";
  appliedTipAmount: number = 0;
  storeData: any;
  isPaymentSupportInternal: boolean = true;
  isTipButtonClicked: boolean = false;
  internal: string = "internal";
  @Input() selectedPaymentType: string;
  @Select(cartState.getCart) cart: Observable<cartDefaultState>;
  @Select(storeState.getStore) storeDetail: Observable<storeDefaultState>;
  constructor(
    private emitTrans: emitTransactionsService,
    private decimalPipe: DecimalPipe,
    private ngxService: NgxUiLoaderService,
    private general: generalService
  ) {}

  ngOnInit() {
    this.GetCart();
    this.GetStoreDetail();
    this.LoadIframeIfTipIsApplied(true);
    this.maxLimitValu = this.GetCartTotal();
  }

  GetStoreDetail() {
    this.storeDetail.subscribe((res: any) => {
      if (res) {
        this.storeData = res;
        this.isPaymentSupportInternal = this.general.checkInternalPaymentAccept(
          this.storeData
        );
        // console.log(res, this.isPaymentSupportInternal);
      }
    });
  }
  LoadIframeIfTipIsApplied(isLoadFirstTime = false) {
    if (this.CheckIfTipIsAlreadyApplied()) {
      this.tipValue = this.appliedTipAmount;
      this.UpdateSelectedTipButton(this.tipValue);
      this.AddTip(this.tipValue, isLoadFirstTime);
    }
  }
  UpdateSelectedTipButton(tipValue: number) {
    // console.log(tipValue);
    let isDecimalNumber: boolean = this.isDecimal(tipValue);
    if (isDecimalNumber) {
      let revertValue = Math.round(
        (tipValue / this.GetCartTotal()) * 100
      ).toFixed(2);
      this.selectedTip = parseInt(revertValue).toString();
    } else {
      //update textbox
    }
  }
  isDecimal(num: number): boolean {
    return Number(num) === num && num % 1 !== 0;
  }
  CheckIfTipIsAlreadyApplied(): boolean {
    let items = this.cartData.cartLedger.expectedLedger.subLineItems;
    let tipApplied: boolean = false;
    items.forEach((item) => {
      if (item.itemName.toLowerCase() == "tip" && item.price > 0) {
        tipApplied = true;
        this.appliedTipAmount = item.price;
        this.isTipButtonClicked = true;
      }
    });
    // console.log(this.appliedTipAmount);
    return this.isTipButtonClicked;
  }
  GetCart() {
    this.cart.subscribe((cartData) => {
      this.cartData = cartData;
      const tipItem = this.cartData.cartLedger.expectedLedger.subLineItems?.find(item => item.itemName.toLowerCase() == "tip");
      if (!tipItem || tipItem?.price === 0) {
        this.appliedTipAmount = 0;
        this.tip = 0;
        this.tipValue = 0;
        this.selectedTip = 'AMOUNT';
        this.maxLimitValu = this.GetCartTotal();
        this.emitTrans.getIframeUrl("");
      }
    });
  }
  AddTip(tipAmount: number = null, isLoadFirstTime = false) {
    // console.log(tipAmount);
    this.isTipButtonClicked = true;
    let percentTip = this.GetTipPercentValue(this.selectedTip);

    let tipModel: ITipModel = {
      Action: TipActions.ADD_TIP,
      TipType: TipType.DOLLAR_AMOUNT,
      TipValue: tipAmount == null ? percentTip : tipAmount,
    };
    // console.log(tipModel);
    if (!isLoadFirstTime) {
      this.ngxService.start();
      this.emitTrans.emitCurrentTipValue(tipModel);
    }
  }
  GetTipPercentValue(type: string): number {
    let cartPrice = this.GetCartTotal();
    let calculatedTipAmount =
      type == "AMOUNT" ? this.tip : (cartPrice * this.tip) / 100;
    return parseFloat(this.decimalPipe.transform(calculatedTipAmount, "1.2-2"));
  }
  GetCartTotal(): number {
    let items = this.cartData.cartLedger.expectedLedger.subLineItems;
    let amount: number = 0.0;
    items.forEach((item) => {
      if (item.itemName.toLowerCase() == "total product") {
        const price: any = parseFloat(item.price);
        amount = price.toFixed(2);
      }
    });
    // console.log(amount);
    return amount;
  }
  setTipAmount(tip: number | string, type: string) {
    let cartAmount: number = this.GetCartTotal();
    this.showTipError = false;
    this.selectedTip = type;
    if (type == "AMOUNT") {
      if (+tip <= cartAmount) {
        // Convert tip to a number with two decimal places
        this.tip = parseFloat(parseFloat(String(tip)).toFixed(2));
        this.tipValue = this.GetTipPercentValue(type);
        this.tipValue = isNaN(this.tipValue) ? 0 : this.tipValue;
        this.selectedTip = type;
      } else {
        this.showTipError = true;
      }
    } else {
      //if amount is in percentage.
      tip = isNaN(+tip) ? 0 : +tip;
      this.tip = tip;
      tip = this.GetTipPercentValue(type);
      if (tip <= cartAmount) {
        this.tipValue = tip;
      } else {
        this.showTipError = true;
      }
    }
  }
}
