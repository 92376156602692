import {OnInit, OnChanges, NgModule, EventEmitter, Input, Output, Component} from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';
import {FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators} from '@angular/forms';

declare const $: any;
@Component({
  selector: 'vx-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit, OnChanges {
  @Output() hourUpdate: EventEmitter<any> = new EventEmitter();
  @Input('updateHoursData') updateHoursData:any = [];
  @Input('dependHoursData') dependHoursData:any = [];
  hoursForm: FormGroup;
  days: any = [{key: 'monday', label: "Monday"},
                {key: 'tuesday', label: "Tuesday"},
                {key: 'wednesday', label: "Wednesday"},
                {key: 'thursday', label: "Thursday"},
                {key: 'friday', label: "Friday"},
                {key: 'saturday', label: "Saturday"},
                {key: 'sunday', label: "Sunday"}];

  hours: any = ['12:15 am', '12:30 am', '12:45 am',
  '1:00 am', '1:15 am', '1:30 am', '1:45 am',
  '2:00 am', '2:15 am', '2:30 am', '2:45 am',
  '3:00 am', '3:15 am', '3:30 am', '3:45 am',
  '4:00 am', '4:15 am', '4:30 am', '4:45 am',
  '5:00 am', '5:15 am', '5:30 am', '5:45 am',
  '6:00 am', '6:15 am', '6:30 am', '6:45 am',
  '7:00 am', '7:15 am', '7:30 am', '7:45 am',
  '8:00 am', '8:15 am', '8:30 am', '8:45 am',
  '9:00 am', '9:15 am', '9:30 am', '9:45 am',
  '10:00 am', '10:15 am', '10:30 am', '10:45 am',
  '11:00 am', '11:15 am', '11:30 am', '11:45 am',
  '12:00 pm', '12:15 pm', '12:30 pm', '12:45 pm',
  '1:00 pm', '1:15 pm', '1:30 pm', '1:45 pm',
  '2:00 pm', '2:15 pm', '2:30 pm', '2:45 pm',
  '3:00 pm', '3:15 pm', '3:30 pm', '3:45 pm',
  '4:00 pm', '4:15 pm', '4:30 pm', '4:45 pm',
  '5:00 pm', '5:15 pm', '5:30 pm', '5:45 pm',
  '6:00 pm', '6:16 pm', '6:30 pm', '6:45 pm',
  '7:00 pm', '7:15 pm', '7:30 pm', '7:45 pm',
  '8:00 pm', '8:15 pm', '8:30 pm', '8:45 pm',
  '9:00 pm', '9:15 pm', '9:30 pm', '9:45 pm',
  '10:00 pm', '10:15 pm', '10:30 pm', '10:45 pm',
  '11:00 pm', '11:15 pm', '11:30 pm', '11:45 pm'];

  addedHours: any = [];

  progressWidth: any = 25;
  constructor(fb: FormBuilder,) {
    this.hoursForm = fb.group({
      'day' :  [null, [Validators.required]],
      'start': [null, [Validators.required]],
      'end': [null, [Validators.required]]
    });
  }
  ngOnInit() { }
  ngOnChanges() {
    this.resetHoursInArray();
    this.convertIntoHoursObjects();
    this.removeUnwantedHours();
  }

  submitForm() {
    if(this.hoursForm.valid) {
      //console.log(this.hoursForm.value);
      this.addedHours.push(this.hoursForm.value);
      this.hoursForm.reset();
      this.convertIntoHoursObjects();
    }
  }

  convertIntoHoursObjects() {
    let finalArray = {monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [] };
    this.addedHours.forEach(function(val, k) {
      finalArray[val.day.key].push({start: val.start, end: val.end});
    });
    for (var i in finalArray) {
      if(finalArray[i].length <= 0) {
        delete finalArray[i];
      }
    }
    this.hourUpdate.emit(finalArray);
  }

  removeTime(index) {
    this.addedHours.splice(index,1);
    this.convertIntoHoursObjects();
  }

  resetHoursInArray() {
    let that = this;
    if(!_.isEmpty(this.updateHoursData)) {
      for (var i in this.updateHoursData) {
        this.updateHoursData[i].forEach(function(val, k) {
          val.day = {key: i, label: i.replace(/(^|\s)\S/g, l => l.toUpperCase())};
          that.addedHours.push(val);
        });
      }
    }
  }

  removeUnwantedHours() {
    if(!_.isEmpty(this.dependHoursData)) {
      let keys = _.keys(this.dependHoursData);
      this.days = this.days.map(obj => {
        if(keys.indexOf(obj.key) >= 0 ) {
          return obj;
        }
      }).filter(function( element ) {
        return element !== undefined;
      });
    }
  }

}
