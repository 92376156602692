import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ComponentService } from "app/core/services/component.service";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { INotification, INotificationStoreDetail } from "../model/INotification";
import { Client } from "../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";
import { NotificationService } from "app/core/notification/notification.service";
import { IClientListing } from "app/order-receive/clients/model/client-listing.model";
import Swal from "sweetalert2";
import { IStore } from "app/store-management/model/IStore";
import { DatePipe } from "@angular/common";
import * as moment from "moment";

@Component({
  selector: "app-add-notification",
  templateUrl: "./add-notification.component.html",
  styleUrls: ["./add-notification.component.scss"],
})
export class AddNotificationComponent implements OnInit, AfterViewInit {
  public selectedFormType: string = "Add";
  public notification: INotification = null;
  public store: Array<INotificationStoreDetail> = [];
  allStoresSelected: boolean = false;
  addNewNotificationForm: FormGroup;
  selectedClient: Client;
  storeData = {
    count: 0
  };
  selectedStores = [];
  storeListing: IStore[] = [];
  storesData: IStore[] = [];
  constructor(
    private componentService: ComponentService,
    private ngxService: NgxUiLoaderService,
    private notificationService: NotificationService,
    private voixHttp: VoixHttpService,
    private fb: FormBuilder
  ) {
    this.componentService
      .getSelectedClientForStore()
      .subscribe((selectedClientStore: IClientListing) => {
        this.selectedClient = selectedClientStore;
      });
  }

  ngOnInit() {
    this.addNewNotificationForm = this.fb.group({
      title: ["", Validators.required],
      notification: ["", Validators.required],
      priority: [1, Validators.required],
      notify_start_time: [null, Validators.required],
      notify_end_time: [null, Validators.required],
      notify_start_date: [null, Validators.required],
      notify_end_date: [null, Validators.required],
    });
  }

  get addNewNotificationFormControls() {
    return this.addNewNotificationForm.controls;
  }

  ngAfterViewInit() {
    if (this.selectedClient) this.selectedClientForStores(this.selectedClient);
    window.scroll(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  updateDeliveryPickupTime() {}

  Back() {
    window.scroll(0, 0);
    this.componentService.SetSelectedButtonType("AddNew");
    this.componentService.LoadNotificationsAddModifyComponent({
      ComponentName: "NotificationListing",
    });
  }

  ResetForm() {
    this.allStoresSelected = false;
    this.store = this.ResetNotificationObject();
  }

  ResetNotificationObject(): any {
    this.selectedStores = [];
    return this.addNewNotificationForm.reset();
  }

  selectedClientForStores(selectedClient: Client) {
    const url = `v1/getAllStores?clientCode=${selectedClient.name}`;
    this.ngxService.start();
    this.voixHttp.getStoresByClient(url).subscribe({
      next: (res) => {
        this.storeListing = res.data.rows.map(item => ({
          ...item,
          Selected: !!this.notification?.stores.find(i => i?.['storeId'] === item.id || i?.['storeIds'] === item.id)
        }));
        this.storesData = [...this.storeListing];
        this.allStoresSelected = this.storeListing.every(item => item.Selected);
        this.storeData.count = res.totalItems;
        if (this.notification) {
          this.selectedStores = this.storeListing.filter(item => !!this.notification.stores.find(store => store['storeId'] === item.id || store['storeIds'] === item.id));
          const dateStr = new Date().toISOString().split('T').shift();
          this.notification['notify_start_time'] = moment(moment(dateStr + ' ' + this.notification['validFromTime']).toDate()).toISOString();
          this.notification['notify_end_time'] = moment(moment(dateStr + ' ' + this.notification['validTillTime']).toDate()).toISOString();
          this.notification['notify_start_date'] = new Date(this.notification['validFromDate']).toISOString();
          this.notification['notify_end_date'] = new Date(this.notification['validTillDate']).toISOString();
          this.addNewNotificationForm.patchValue(this.notification);
        }
        this.ngxService.stop();
      },
      error: (error) => {
        this.showErrorMessage(error);
      },
    });
  }

  showErrorMessage(error) {
    this.notificationService.openSnackBarActionV2(
      "end",
      "top",
      error?.error?.message ||
        error?.message ||
        error?.error ||
        "Something went wrong please try again",
      "danger-snackbar"
    );
    this.ngxService.stop();
  }

   SelectUnSelectAll(event) {
    const checkboxCheckedStatus = event.target.checked;
    this.storeListing.forEach(x => x.Selected = checkboxCheckedStatus);
    this.allStoresSelected = checkboxCheckedStatus;
    if(checkboxCheckedStatus) {
      this.selectedStores = [...this.storeListing];
    } else {
      this.selectedStores = [];
    }
  }

  OnRowCheckboxClicked(event: any, store: any) {
    var checkboxCheckedStatus = event ? event.target.checked : true;
    store.Selected = checkboxCheckedStatus;
    var selectedFromGrid = this.storeListing.filter(x => x.Selected == checkboxCheckedStatus).length;
    var totalAgents = this.storeListing.length;
    this.allStoresSelected = checkboxCheckedStatus ? selectedFromGrid == totalAgents : false;
    if(checkboxCheckedStatus) {
      this.selectedStores.push(store);
    } else {
      const index = this.selectedStores.findIndex((s) => s.id === store.id);
      if (index !== -1) {
        this.selectedStores.splice(index, 1);
      }
    }
  }

  formatDate(date: string): string {
    if (date) {
      return new DatePipe('en-US').transform(date, 'dd/MM/yyyy h:mm a', 'UTC');
    }
  }

  addNewNotification() {
    const url = "v1/notification";
    delete this.addNewNotificationForm.value.stores;
    const { notify_start_time, notify_end_time, notify_start_date, notify_end_date } = this.addNewNotificationForm.value;
    const data = {
      ...this.addNewNotificationForm.value,
      notify_start_time: moment(notify_start_time).format("HH:mm"),
      notify_end_time: moment(notify_end_time).format("HH:mm"),
      notify_start_date: moment(notify_start_date).format("YYYY-MM-DD"),
      notify_end_date: moment(notify_end_date).format("YYYY-MM-DD"),
      storeIds: this.selectedStores.map((store) => store.external_location_id.toString())
    };
    if (this.selectedFormType === 'Add') {
      this.ngxService.start();
      this.voixHttp.createNewNotification(url, data).subscribe({
        next: (res) => {
          Swal.fire("Notification created successfully.", "", "success");
          this.ngxService.stop();
          this.ResetForm();
          this.Back();
        },
        error: (error) => {
          Swal.fire(
            error?.error?.message ||
              error?.message ||
              error?.error ||
              'Notification not created',
            "There was an error while creating the notification",
            "error"
          );
          this.ngxService.stop();
        },
      });
    } else {
      data.notificationId = this.notification.notificationId;
      this.ngxService.start();
      this.voixHttp.updateNotification(url, data).subscribe({
        next: (res) => {
          Swal.fire("Notification updated successfully.", "", "success");
          this.ngxService.stop();
          this.ResetForm();
          this.Back();
        },
        error: (error) => {
          Swal.fire(
            error?.error?.message ||
              error?.message ||
              error?.error ||
              "Notification is not updated",
            "There was an error while updating the notification",
            "error"
          );
          this.ngxService.stop();
        },
      });
    }
  }

  searchStore(searchValue) {
    if (!searchValue) {
      this.storeListing = [...this.storesData];
    }
    this.storeListing = this.storesData
      ?.filter(store => ['external_location_id', 'primaryPhone']
        .map(key => store?.[key])
        .filter(Boolean)
        .some(value => value.toString().includes(searchValue))
      ) ?? [];
  }
}
