import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'tagReplace'})
export class TagReplacePipe implements PipeTransform {
  transform(text: string, args: any = {}): any {
    var textValue = text;
    Object.keys(args).forEach(function (key) {
      textValue =  textValue.replace(new RegExp("<" + key + ">", 'g'), args[key]);
    });
    return textValue;
  }
}
