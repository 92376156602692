import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";
import { Category } from "../../../../../mediator-commlibs/interfaces/response/menu.interface";
import { CartMenuItemDetails } from "app/ai-order-recieve/interface/cartmenuItem-state.interface";
import { stateInterface } from "app/ai-order-recieve/interface/conv-state.interface";
export interface aiParams {
  convId: string;
  customerPhone: string;
  restaurantphone: string;
  storeId: string;
  clientCode: string;
  identity: string;
}
@Injectable({
  providedIn: "root",
})
export class aiMenuService {
  public originalMenu: BehaviorSubject<Category[]> = new BehaviorSubject(null);
  public categories: BehaviorSubject<Category[]> = new BehaviorSubject(null);
  public aiParams: BehaviorSubject<aiParams> = new BehaviorSubject(null);
  public cartMenuItemDetails: BehaviorSubject<CartMenuItemDetails> =
    new BehaviorSubject(null);
  public convState: BehaviorSubject<stateInterface> = new BehaviorSubject(null);
  public clearMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private http: HttpClient) {}
  getMenu(
    clientCode: string,
    storeId: string,
    correlationid: string,
    identity: string,
    customerphone: string
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    const queryParams = new HttpParams().append("customerphone", customerphone);

    return this.http.get(
      `${environment.agent_backend_api_url}api/internal/menu/${storeId}`,
      { headers: headers, params: queryParams }
    );
  }
}
