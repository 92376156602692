import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-notification-filters',
  templateUrl: './notification-filters.component.html',
  styleUrls: ['./notification-filters.component.scss']
})
export class NotificationFiltersComponent {
  @Input() storeID: string;
  @Input() selectedStatus: string;
  @Output() searchByStoreID = new EventEmitter();
  @Output() selectedStatusForNotification = new EventEmitter();

  constructor(){
    this.onSearch = _.debounce(this.onSearch, 1000);
  }

  onSearch() {
    this.searchByStoreID.emit(this.storeID);
  }

  changeStatus(status) {
    this.selectedStatusForNotification.emit(status !== 'active' ? status : '');
  }
}
