import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterPromo',
})
export class FilterPromo implements PipeTransform {
  transform(value: any, filterString: string, property: string): any {
    // console.log(filterString,value,property)
    if (value?.length === 0 || !filterString) {
      return value;
    }
    let filteredPromos: any = [];
    for (let promo of value) {
      if (promo[property].toLowerCase().includes(filterString.toLowerCase())) {
        filteredPromos.push(promo);
      }
    }
    return filteredPromos;
  }
}