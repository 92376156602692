<div class="main-content no-scroll">
  <div class="container-fluid">
    <div class="row">
      
      <div class="col-md-7">
        <div class="card">
          <div class="card-header" data-background-color="voix-blue">
            <h4 class="title">Change Password</h4>
            <!-- <p class="category">Here is a subtitle for this table</p> -->
          </div>
          <div class="card-content table-responsive">

            <form method="#" class="changePassword" action="#" [formGroup]="changePassword" (ngSubmit)="submitForm(changePassword.value)">
              <div class="row">
                <div class="col-md-8">
                  <mat-form-field class="width-100 col-md-12">
                    <input matInput placeholder="Current Password" [formControl]="changePassword.controls['current_password']">
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <mat-form-field class="width-100 col-md-12">
                    <input matInput placeholder="New Password" [formControl]="changePassword.controls['password']" [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <mat-form-field class="width-100 col-md-12">
                    <input matInput placeholder="Confirm Password" [formControl]="changePassword.controls['confirm_password']" [errorStateMatcher]="matcher" [type]="hide ? 'password' : 'text'">
                  </mat-form-field>
                  <div class="confirm-password">
                    <mat-error *ngIf="changePassword.hasError('notSame')">
                        Passwords do not match
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="row col-md-12">
                <div class="col-md-12">
                  <button type="submit" class="btn btn-primary" [disabled]="!changePassword.valid">Submit</button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>