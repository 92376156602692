<div class="row">
  <div class="col-md-12">
    <mat-form-field style="float: left" class="search">
      <input
        matInput
        placeholder="Search By Store"
        #value
        (keyup)="onSearch($event.target.value)"
      />
    </mat-form-field>
    <app-selected-client
      (selectedClientForStores)="clientSelectedForStores($event)" [client]="selectedClient"
    ></app-selected-client>
    <!-- <button
      style="float: right"
      class="btn btn-danger btn-sm btn-delete-all"
      [disabled]="
        !selectedUsersFromGrid?.length || selectedUsersFromGrid?.length > 10
      "
      (click)="deleteStore()"
    >
      Delete All ({{ selectedUsersFromGrid?.length ?? "" }})
    </button>
    <button
      style="float: right"
      class="btn btn-primary btn-sm btn-hook"
      [disabled]="!selectedUsersFromGrid?.length"
      (click)="webHookStore()"
    >
      Webhook ({{ selectedUsersFromGrid?.length ?? "" }})
    </button> -->
    <div class="card-content table-responsive col-md-12 agent-table">
      <table class="table table-bordered">
        <thead>
          <tr>
            <!-- <th class="table-heading">
              <input
                cFormCheckInput
                [checked]="allStoresSelected"
                (click)="SelectUnSelectAll($event)"
                type="checkbox"
              />
            </th> -->
            <th class="table-heading" scope="col">Store ID</th>
            <th class="table-heading" scope="col">Phone</th>
            <th class="table-heading" scope="col">First Name</th>
            <th class="table-heading" scope="col">Last Name</th>
            <th class="table-heading" scope="col">Address</th>
            <!-- <th class="table-heading" scope="col">City</th>
                        <th class="table-heading" scope="col">State</th> -->
            <th class="table-heading" scope="col">Webhook Date</th>
            <th class="table-heading" scope="col">Created at</th>
            <th class="table-heading" scope="col">Zip Code</th>
            <th class="table-heading" *ngIf="(userPermissions.RESTAURANT_CONFIG_EDIT | permissions) || (userPermissions.RESTAURANT_CONFIG_DELETE | permissions)" scope="col">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="storeListing?.length">
          <tr *ngFor="let store of storeListing">
            <!-- <td>
              <c-form-check>
                <input
                  cFormCheckInput
                  type="checkbox"
                  [(ngModel)]="store.Selected"
                  (change)="OnRowCheckboxClicked($event, store)"
                  [checked]="store.Selected"
                />
              </c-form-check>
            </td> -->
            <td>{{ store?.storeNumber }}</td>
            <td>{{ store?.phone || "-" }}</td>
            <td>{{ store?.firstName || "-" }}</td>
            <td>{{ store?.lastName || "-" }}</td>
            <td>{{ store?.address }}</td>
            <!-- <td>{{ store?.city || '-' }}</td>
                        <td>{{ store?.state || '-' }}</td> -->
            <td>{{ store?.webhookModifiedDate ? formatDate(store?.webhookModifiedDate) : "-" }}</td>
            <td>{{ store?.createdAt }}</td>
            <td>{{ store?.zipCode }}</td>
            <td *ngIf="(userPermissions.RESTAURANT_CONFIG_EDIT | permissions) || (userPermissions.RESTAURANT_CONFIG_DELETE | permissions)">
              <div class="d-flex">
                <button
                class="btn btn-sm btn-warning badge"
                [disabled]="multipleStoresSelected"
                (click)="modifyStore(store)"
                *ngIf="userPermissions.RESTAURANT_CONFIG_EDIT | permissions"
              >
                Modify Store
              </button>
              <button
                class="btn btn-sm badge"
                [disabled]="multipleStoresSelected"
                (click)="webHookStore(store)"
                [ngClass]="store.isWebhookValid ? 'btn-success' : 'btn-danger'"
                *ngIf="userPermissions.RESTAURANT_CONFIG_EDIT | permissions"
              >
                Webhook
              </button>
              </div>
              <div class="d-flex">
                <button
                class="btn btn-sm btn-warning badge"
                [disabled]="multipleStoresSelected"
                (click)="deleteStore(store)"
                *ngIf="userPermissions.RESTAURANT_CONFIG_DELETE | permissions"
              >
              
                Delete
              </button>
              <button
                class="btn btn-sm btn-warning badge"
                (click)="loadNotification(store?.storeNumber)"
              >
                View Notification
              </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-paginator
        #paginator
        *ngIf="storeListing?.length"
        [length]="storeData?.count"
        [pageSize]="params.limit"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="handlePage($event)"
      >
      </mat-paginator>
      <div class="mat-row not-found" *ngIf="!storeListing?.length">
        <span> No data found. </span>
      </div>
    </div>
  </div>
  <div class="col-md-12"></div>
</div>
