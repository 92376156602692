<div class="wrapper wrapper-full-page">
  <div class="full-page login-page" filter-color="black" data-image="../../assets/img/register.jpg">
    <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
    <div class="content">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <form [formGroup]="loginForm" (ngSubmit)="submitForm(loginForm.value)" method="#" action="#">      
                  <div class="card card-login">
                    <div class="text-center">
                      <div class="logo-img">
                        <img src="/assets/img/EzraAilogo.png" style="width: 200px" />
                      </div>
                      <!-- <div class="logo-img text-logo-sub">
                        <img class="text-logo" src="/assets/img/logo-text-voix.png" />
                      </div> -->
                      <h3 class="card-title">Best Phone Ordering Experience</h3>
                    </div>

                    <div class="card-content pt-pb-0 mt-50">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">email</i>
                        </span>
                        <div class="form-group label-floating is-empty mt-0">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Email" [formControl]="loginForm.controls['email']">
                            <mat-error
                              *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')">
                              Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
                              Email is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <span class="material-input"></span>
                        </div>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">lock_outline</i>
                        </span>
                        <div class="form-group label-floating is-empty mt-0">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Password" [formControl]="loginForm.controls['password']"
                              [type]="hide ? 'password' : 'text'" required>
                            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' :
                              'visibility_off'}}</mat-icon>
                            <mat-error
                              *ngIf="loginForm.controls['password'].hasError('email') && !loginForm.controls['password'].hasError('required')">
                              Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                              Password is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <span class="material-input"></span>
                        </div>
                      </div>
                    </div>

                    <div class="footer text-center">
                      <button type="submit" class="btn btn-primary btn-round submit" [disabled]="!loginForm.valid">Sign
                        In</button>
                    </div>
                  </div>
                </form>
              </div>
              
              <div class="col-md-1"></div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg)"></div>
  </div>
</div>