import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/auth/authentication.service';
import * as SIP from 'sip.js'; // Import the entire SIP module
import { AppConstants } from '../app.constants';
import { emitTransactionsService } from '../core/services/emitTransactions.service';

@Component({
  selector: 'audio-browser',
  templateUrl: './audio-browser.html',
  styleUrls: ['./audio-browser.component.scss']
})
export class AudioBrowserComponent implements OnInit, AfterViewInit {
  newCall = false;
  sessionStatus: any = { color: "#444" };
  sipStatus: any = { color: "#444" };
  registerContextStatus: any = { color: "#444" };

  constructor(private router: Router, private auth: AuthenticationService, private emitTransactions: emitTransactionsService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ value: AppConstants.SIP_ALERT, error: false }));
    this.auth.userDetail().subscribe((response: any) => {
      if (response.user && response.user.AgentSip) {
        const configuration = {
          uri: response.user.AgentSip.agent_name + '@voixai.onsip.com',
          authorizationUser: response.user.AgentSip.authorization_user,
          password: response.user.AgentSip.password,
          userAgentString: SIP.C.USER_AGENT + " sipjs.com",
          traceSip: true
        };
        const simple = new SIP.Web.Simple(configuration);
        this.sipObj(simple);
      } else {
        localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ text: "SIP details are invalid for current agent", value: AppConstants.SIP_ALERT, error: true }));
      }
    });
  }

  sipObj(simple) {
    const audioBrowserComponent = this;
    simple.on('ringing', () => {
      simple.answer();
      const parentComponent = audioBrowserComponent;
      simple.session.on('accepted', () => {
        parentComponent.newCall = true;
        console.log("session accepted");
        parentComponent.sessionStatus.text = "Session New Call Accepted";
        parentComponent.sessionStatus.color = "#2A8735";
        localStorage.setItem(AppConstants.SIP_NOTIFY, JSON.stringify({ alertNotify: 'warning', text: 'Customer call accepted.' }));
      });

      simple.session.on('failed', () => {
        console.log("session failed");
        parentComponent.sessionStatus.text = "Session Call Failed";
        parentComponent.sessionStatus.color = "#CE0000";
        localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ text: "SIP session call failed", value: AppConstants.SIP_ALERT, error: true }));
      });

      simple.session.on('progress', () => {
        console.log("session progress");
        parentComponent.sessionStatus.text = "Session Call In Progress";
        parentComponent.sessionStatus.color = "#EA7D24";
      });

      simple.session.on('rejected', () => {
        console.log("session rejected");
        parentComponent.sessionStatus.text = "Session Call Rejected";
        parentComponent.sessionStatus.color = "#CE0000";
        localStorage.setItem(AppConstants.SIP_NOTIFY, JSON.stringify({ alertNotify: 'warning', text: 'Customer call rejected.' }));
      });

      simple.session.on('terminated', () => {
        console.log("session terminated");
        parentComponent.sessionStatus.text = "Previous Call Terminated, Waiting for New Call....";
        parentComponent.sessionStatus.color = "#EA7D24";
      });

      simple.session.on('bye', () => {
        console.log("session bye");
        parentComponent.sessionStatus.text = "Previous Call Bye, Waiting for New Call....";
        parentComponent.sessionStatus.color = "#EA7D24";
        localStorage.setItem(AppConstants.SIP_NOTIFY, JSON.stringify({ alertNotify: 'warning', text: 'Customer end the call.' }));
      });
    });

    simple.ua.registerContext.on('registered', () => {
      console.log("registered", audioBrowserComponent);
      audioBrowserComponent.registerContextStatus.text = "SIP User Registered";
      audioBrowserComponent.registerContextStatus.color = "#2A8735";
    });

    simple.ua.registerContext.on('unregistered', () => {
      console.log("unregistered");
      audioBrowserComponent.registerContextStatus.text = "SIP User Unregistered";
      audioBrowserComponent.registerContextStatus.color = "#EA7D24";
      localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ text: "SIP user failed to register", value: AppConstants.SIP_ALERT, error: true }));
    });

    simple.ua.registerContext.on('failed', () => {
      console.log("sip failed disconnected");
      audioBrowserComponent.registerContextStatus.text = "SIP User failed to connect/register";
      audioBrowserComponent.registerContextStatus.color = "#CE0000";
      localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ text: "SIP User failed to connect/register", value: AppConstants.SIP_ALERT, error: true }));
    });

    simple.on('connected', () => {
      console.log("sip connected");
      audioBrowserComponent.sipStatus.text = "SIP User connected successfully";
      audioBrowserComponent.sipStatus.color = "#2A8735";
    });

    simple.on('message', (message) => {
      console.log("comingmessage");
    });

    simple.on('new', () => {
      console.log("sip connected");
      audioBrowserComponent.sipStatus.text = "SIP New Call Coming";
      audioBrowserComponent.sipStatus.color = "#2A8735";
      localStorage.setItem(AppConstants.SIP_ALERT, JSON.stringify({ alertNotify: 'success', text: 'New call for agent' }));
    });

    simple.on('connecting', () => {
      audioBrowserComponent.sipStatus.text = "SIP new call connecting";
      audioBrowserComponent.sipStatus.color = "#2A8735";
    });

    simple.on('disconnected', () => {
      console.log("sip disconnected");
      audioBrowserComponent.sipStatus.text = "SIP User disconnected";
      audioBrowserComponent.sipStatus.color = "#CE0000";
    });

    simple.on('ended', () => {
      console.log("the call has ended");
      audioBrowserComponent.sipStatus.text = "SIP User call has ended";
      audioBrowserComponent.sipStatus.color = "#EA7D24";
    });
  }
}
