<div [formGroup]="billing_address">
  <mat-form-field
    class="example-full-width"
    appearance="outline"
    *ngIf="paymentAddressRequiredForPayments"
  >
    <mat-label>Billing Address</mat-label>
    <input
      matInput
      #billingAddress
      formControlName="billing_address"
      type="text"
      ngx-google-places-autocomplete
      #placesRef="ngx-places"
      [options]="googlePlacesOptions"
      [matAutocomplete]="googleAddress"
      (input)="inputbehaviour($event.target.value)"
      (keyup)="inputbehaviour($event.target.value)"
      (onAddressChange)="lineItemAddressChange($event)"
    />
    <mat-autocomplete autoActiveFirstOption #googleAddress="matAutocomplete">
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Postal Code</mat-label>
    <input
      matInput
      #postalCode
      maxlength="10"
      type="number"
      placeholder="Ex. 94105"
      required
      formControlName="zip_code"
    />
    <mat-hint align="end">{{ postalCode.value.length }} / 10</mat-hint>
  </mat-form-field>

  <button
    [disabled]="validate()"
    mat-stroked-button
    color="primary"
    class="btn btn-success"
    style="width: 100%; height: 50px; margin-top: 5px"
    (click)="submitAndCallPaymentUrl()"
  >
    Submit <i class="material-icons" *ngIf="validate()">lock</i>
  </button>
</div>
<span style="color: red"
  ><b>NOTE: PLEASE CLICK ON SUBMIT TO PROCESS PAYMENT</b><br
/></span>
<span *ngIf="validateTip()" style="color: red; padding: 4px"
  ><b>Tip is required</b></span
>
<span *ngIf="validateForm()" style="color: red; padding: 4px"
  ><b>Postal Code is required</b></span
>
