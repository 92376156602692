import { FormsModule } from '@angular/forms';
import { RoleListingComponent } from './role-listing/role-listing.component';
import { RouterModule, Routes } from '@angular/router';
import { AddRoleComponent } from './add-role/add-role.component';
import { ViewRolesComponent } from './view-roles/view-roles.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesComponent } from './roles.component';
import { MaterialModule } from '../core/modules/material.module';
import { SharedModule } from 'app/core/shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';


export const routes: Routes = [
  {
    path: '',
    component: ViewRolesComponent
  }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RouterModule,
    FormsModule,
    SharedModule,
    NgxUiLoaderModule
  ],
  declarations: [
    RolesComponent,
    ViewRolesComponent,
    AddRoleComponent,    
    RoleListingComponent
  ],
  entryComponents:[
    AddRoleComponent,
    RoleListingComponent
  ]
})
export class RolesModule { }
