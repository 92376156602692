<div class="app-page-not-found business-page">
  <div class="app-page-not-found__wrapper">
    <div>
      <div class="app-page-not-found__wrapper__404"> {{ ('404' ) }} </div>
    </div>
    <div class="app-page-not-found__wrapper__message">
      {{ (errorMessage ) }}
    </div>
    <div>
      <img width="350" alt="404 image" src="/assets/img/not-found.svg">
    </div>
    <button (click)="goToPreviousPage()" mat-raised-button color="primary"
      class="app-page-not-found__wrapper__button">{{ ('Go to previous page' ) }}</button>
  </div>
</div>