import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { AuthenticationService } from "./core/auth/authentication.service";
import * as _ from "underscore";
import { DeviceDetectorService } from "ngx-device-detector";
import { MessagingService } from "./core/notification/messaging.service";
import { alertNotificationService } from "./core/services/alert-notifcation.service";
import { AppConstants } from "./app.constants";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { filter } from "rxjs";
import { environment } from "environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  routeObect: any = {};
  deviceInfo: any;
  currentUrl: any;
  loaderText: string = "";
  isLoading = true;
  constructor(
    public location: Location,
    private router: Router,
    private auth: AuthenticationService,
    private deviceService: DeviceDetectorService,
    public msg: MessagingService,
    private alertNotification: alertNotificationService,
    private ngxService: NgxUiLoaderService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.deviceDetect();
        var that = this;
        that.currentUrl = event?.["url"];
        that.initialRun();
        this.alertNotification.textLoader$.subscribe(
          (text) => (this.loaderText = text)
        );
        if (this.router.url === "/") {
          this.router.navigate(["dashboard"]);
        } else if (event?.["url"] === "/dashboard") {
          if (this.auth.isUserLogin()) {
            this.ngxService.start();
            this.auth.getLoggedInUser().subscribe({
              next: (response) => {
                if (response?.user && response?.success) {
                  let user: any = response;
                  user.user.permissions =
                    user.user?.roles?.[0]?.role_details?.permissions.map(
                      (permission) => permission?.permission_details?.name
                    );
                  localStorage.setItem(
                    "userPermissions",
                    JSON.stringify(user.user.permissions)
                  );
                  if(('isAdminBuild' in environment)) {
                    const userObject = JSON.parse(localStorage.getItem("agentCurrentUser")) || {};
                    userObject.user = {
                      ...userObject.user,
                      role: user.user?.roles?.[0]?.role_details?.name
                    }
                    localStorage.setItem(
                      "agentCurrentUser",
                      JSON.stringify(userObject)
                    );
                  }
                }
                this.isLoading = false;
                this.ngxService.stop();
              },
              error: () => {
                this.isLoading = false;
                this.ngxService.stop();
              },
            });
          } else {
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });
  }
  ngOnInit() {}

  initialRun() {
    //commented due to firebase related code was not needed.
    // if (this.currentUrl != "/sip-audio-browser") {
    //   if (this.auth.isUserLogin()) {
    //     let userData = this.auth.userDetail();
    //     this.msg.monitorRefresh(userData);
    //     this.msg.receiveMessages();
    //   }
    // }
    // if (this.currentUrl == "/dashboard") {
    //   this.checkAudioVideoRequirement();
    // }
    window.addEventListener("storage", (event) => {
      if (event.key == AppConstants.SIP_ALERT) {
        var alertData = JSON.parse(event.newValue);
        this.alertNotification.set(alertData);
      }
    });
  }

  deviceDetect() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
  }

  checkAudioVideoRequirement() {
    var navigator = window.navigator;
    var that = this;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {})
      .catch(function (err) {
        // that.alertNotification.set({text: "Microphone permission is denied", value: 'audio-video', error: true});
      });
  }
}
