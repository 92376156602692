import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Location } from "@angular/common";
import { AuthenticationService } from "./../../core/auth/authentication.service";
import { VoixHttpService } from "./../../core/voix-http/voix-http.service";
import { NavbarService } from "../../core/navbar/navbar.service";
import { getRestaurantService } from "../../core/auth/restaurant.service";
import { generalService } from "../../core/services/general.service";
import { emitTransactionsService } from "../../core/services/emitTransactions.service";
import { timer, Observable, first } from "rxjs";
import { StoreDetailComponent } from "../store-detail/store-detail.component";
import { Select } from "@ngxs/store";
import { lookup } from "zip2tz";
import * as moment from "moment-timezone";
import { MatDialog } from "@angular/material/dialog";
import * as _ from "underscore";
import { IntergrationService } from "app/core/integration/integration.service";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import { OrderInProgressComponent } from "app/order-receive/order-in-progress/order-in-progress.component";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { menuDefaultState, menuState } from "app/core/ngxs/state/menu.state";
import { Category } from "../../../../mediator-commlibs/interfaces/response/menu.interface";

const counter = timer(0, 2000);

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}
export interface Entry {
  created: Date;
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  @Select(menuState.getMenuDetails) menuState: Observable<menuDefaultState>;
  @Select(menuState.getUpSellingItems) upSellingItemsState: Observable<Category[]>;
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  @Output() changeRestaurant: EventEmitter<any> = new EventEmitter();
  @ViewChild("navWidth") private navWidth: ElementRef;
  @Input("errors") errors;
  @Input("conversationSoFar") conversationSoFar = [];
  private listTitles: any[];
  location: Location;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public currentuser: any;
  public restaurant: any;
  public name: string;
  public storeDetails: any;
  public navSectionWidth: any;
  public clock;
  entries: any;
  cartDetails: any;
  currentTime: Date;
  currentDay: any;
  timezone: any;
  landmark: string | null;
  nick_name: string | null;
  logo_url: string | null;
  isAdminBuild = environment['isAdminBuild'];
  leastPrices = [];
  overAllHierarchy = [];
  isUpSellingEnabled = false;
  isDropdown = false;
  isDriveThruUrl: boolean;
  isShowNotification = false;
  constructor(
    location: Location,
    private element: ElementRef,
    private auth: AuthenticationService,
    public navbarService: NavbarService,
    private integrationService: IntergrationService,
    private voixHttp: VoixHttpService,
    private currentRestaurant: getRestaurantService,
    private generalService: generalService,
    private emitService: emitTransactionsService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.currentTime = new Date();
    this.isUpSellingEnabled = this.generalService.isUpSellingEnabled;
    this.upSellingItemsState.subscribe((upSellingItems) => {
      this.overAllHierarchy = upSellingItems;
      this.updateUpSellingItems();
    });
    this.cartState.subscribe((res: cartDefaultState) => {
      if (res) {
        this.cartDetails = res;
        console.log("this.cartDetails.cartMenuItems ===>>",this.cartDetails.cartMenuItems);
        
        this.updateUpSellingItems();
      }
    });

    this.location = location;
    this.isDriveThruUrl = window.location.pathname.includes('/drive-thru/');
    this.sidebarVisible = false;
    var navComponent = this;
    emitService.notifyNav$.subscribe(function (response) {
      if (response) {
        navComponent.getAgentAvailability();
      }
    });
    emitService.restaurantName$.subscribe(function (response) {
      if (response) {
        navComponent.name = response.name;
        navComponent.landmark = response.landmark;
        navComponent.nick_name = response.nick_name;
        navComponent.logo_url = response.logo_url;
      }
    });
    //V2
    emitService.storeDetails$.subscribe(function (response) {
      if (response) {
        // console.log(response);
        navComponent.storeDetails = response;
        navComponent.findStoreHoursCurrentDay();
      }
    });
    this.navbarService.isShowNotificationArrowIcon$.subscribe(show => this.isShowNotification = show);
  }

  updateUpSellingItems() {
    this.isUpSellingEnabled = this.generalService.isUpSellingEnabled;
    if (this.isUpSellingEnabled && this.cartDetails?.cartMenuItems?.length) {
      const itemsInCart = this.cartDetails.cartMenuItems.map((val) => val.displayName);
      this.menuState.pipe(first()).subscribe((menu: any) => {
        const getAllItems = menu.filter(val => val.items.find(v => !!itemsInCart.includes(v.displayName))).map(val => val?.displayName);
        this.overAllHierarchy = this.overAllHierarchy?.map((item) => ({ ...item, isDeleted: getAllItems.includes(item.parentName) }));
      })
    }
  }

  ngOnInit() {
    this.dayFinder();
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    this.getAgentAvailability();
    // this.getStoreDetails();
    this.navSectionWidth = this.navWidth?.nativeElement.offsetWidth / 2 - 90;
    let timesRun = 0;
    let navComponent = this;
    // let interval = setInterval(function () {
    //   timesRun += 1;
    //   if (timesRun === 5) {
    //     clearInterval(interval);
    //   }
    //   if (navComponent.navbarService.isActiveRouter("dashboard")) {
    //     navComponent.getAgentAvailability();
    //   }
    // }, 25000);

    setInterval(() => {
      if (!this.changeDetector["destroyed"]) {
        this.changeDetector.detectChanges();
      }
    }, 1000);
  }

  addToCartUpselling(item) {
    let upSellingObj = { ...item };
    if(upSellingObj.modifiers && upSellingObj.modifiers.length) {
      upSellingObj.modifiers.map((modifier) => {
        delete modifier.min;
        delete modifier.max;
      })
    }
    upSellingObj.quantity = 1; // this.overAllHierarchy.filter((val) => upSellingObj.displayName === val.displayName && !val.isDeleted).length
    delete upSellingObj.defaultModifiers;
    delete upSellingObj.lastTitleName;
    delete upSellingObj.styleName;
    delete upSellingObj.subTitleName;
    delete upSellingObj.titleName;
    delete upSellingObj.isDisabled;
    delete upSellingObj.displayModifiers;
    delete upSellingObj.parentName;
    upSellingObj.isUpselling = true;
    this.emitService.addItemToCart(upSellingObj);
  }

  dayFinder() {
    var weekdays = new Array(7);
    weekdays[0] = "SUNDAY";
    weekdays[1] = "MONDAY";
    weekdays[2] = "TUESDAY";
    weekdays[3] = "WEDNESDAY";
    weekdays[4] = "THURSDAY";
    weekdays[5] = "FRIDAY";
    weekdays[6] = "SATURDAY";
    this.currentDay = weekdays[this.currentTime.getDay()];
    // this.currentDay = weekdays[this.currentTime.getUTCDate()];
  }
  storeHours: any[] = [];
  allstoreHours: any[] = [];
  time: any;
  deliveryFee: string = "";
  deliveryTaxRate: string = "";
  findStoreHoursCurrentDay() {
    if (this.storeDetails) {
      let zipToTz = lookup(this.isDriveThruUrl ? '94539' :this.storeDetails.store.address.zip);

      let off = moment.tz(zipToTz).format("Z");
      // console.log(off);
      this.time = new Date(moment.tz(zipToTz).format("YYYY-MM-DDTHH:mm:ss"));
      this.time = new Date(this.time);
      if (this.time) {
        // console.log(this.time);
        this.emitService.setTimezone(this.time);
      }
      // console.log(zipToTz);
      setInterval(() => {
        let zipToTz = lookup(this.isDriveThruUrl ? '94539' : this.storeDetails.store.address.zip);

        this.timezone = new Date(
          moment.tz(zipToTz).format("YYYY-MM-DDTHH:mm:ss")
        );
        this.timezone = this.timezone.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        // if()
        // this.timezone = this.timezone.replace("AM", "");
        // this.timezone = this.timezone.replace("PM", "");
        // console.log(this.timezone);
      }, 1000);

      // console.log(this.storeDetails);
      for (let i = 0; i < this.storeDetails.store.storeHours.length; i++) {
        const element = this.storeDetails.store.storeHours[i];
        if (element.dayOfWeek.toUpperCase() == this.currentDay) {
          let time = element.storeOpenTime + " - " + element.storeCloseTime;
          this.storeHours.push({
            dayOfWeek: element.dayOfWeek,
            time,
            holiday: element.holiday ? element.holiday : false,
            holidayClosedFlag: element.holidayClosedFlag
              ? element.holidayClosedFlag
              : false,
            holidayMessage: element.holidayMessage
              ? element.holidayMessage
              : "",
            timezone: this.storeDetails.store.timezone,
          });
        }
        let time = element.storeOpenTime + " - " + element.storeCloseTime;
        this.allstoreHours.push({
          dayOfWeek: element.dayOfWeek,
          time,
          holiday: element.holiday ? element.holiday : false,
          holidayClosedFlag: element.holidayClosedFlag
            ? element.holidayClosedFlag
            : false,
          holidayMessage: element.holidayMessage ? element.holidayMessage : "",
          pickUpCloseTime: element.pickupCloseTime,
          timezone: this.storeDetails.store.timezone,
        });
      }

      this.deliveryFee =
        this.storeDetails.store.storeFees.length > 0
          ? this.storeDetails.store.storeFees[0].amount
          : "";
      this.deliveryTaxRate =
        this.storeDetails.store.storeFees.length > 0
          ? this.storeDetails.store.storeFees[0].taxRate
          : "";
      // console.log(this.storeHours);
    }
  }

  logout(){
    if (!('isAdminBuild' in environment)) {
      this.router.navigate(['/login']);
    } else {
      this.auth.logout();
      this.router.navigate(['/welcome']);
    }
  }

  getElapsedTime(): TimeSpan {
    let totalSeconds = Math.floor(
      (new Date().getTime() - this.currentTime.getTime()) / 1000
    );

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  getCallDuration(min, sec) {
    min = min < 10 ? "0" + min.toString() : min;
    sec = sec < 10 ? "0" + sec.toString() : sec;
    this.emitService.setCallDuration(min + ":" + sec);
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    body.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  toggleChatDrawer() {
    this.navbarService.emitDrawerToggle(!this.navbarService.chatDrawerSub.getValue());
  }
  openStoreDetails() {
    this.dialog.open(StoreDetailComponent, {
      width: "600px",
      height: "500px",
      data: {
        store: this.storeDetails,
        allhours: this.allstoreHours,
        storeHours: this.storeHours,
        deliveryFee: this.deliveryFee,
        deliveryRate: this.deliveryTaxRate,
      },
    });
  }

  openCustomerPopup() {
    this.emitService.openCustomerPopup(true);
  }

  openCartDetail() {
    this.dialog.open(OrderInProgressComponent, {
      width: "800px",
      height: "700px",
      data: this.storeDetails,
    });
  }
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton?.classList.remove("toggled");
    this.sidebarVisible = false;
    body?.classList?.remove("nav-open");
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      // this.sidebarOpen();
    } else {
      // this.sidebarClose();
    }
  }

  getAgentAvailability(reset = true, object: any = {}) {
    this.auth.userDetail().subscribe((response: any) => {
      this.currentuser = response.user;
      this.isCurrentRestaurant(reset, object);
      this.changeDetector.detectChanges();
    });
  }

  isCurrentRestaurant(reset, object) {
    // var that = this;
    // this.currentRestaurant.restaurant().subscribe(
    //   function (data) {
    //     if (_.isEmpty(data)) {
    //       that.setRestaurantDefault(reset, object);
    //     } else {
    //       that.setRestaurantDefault(false, data);
    //     }
    //   },
    //   (error) => {
    //     //console.log("error" , error);
    //   }
    // );
    //
  }

  setRestaurantDefault(reset, object) {
    // if (reset) {
    //   this.restaurant = this.currentuser.AgentRestaurants[0];
    // } else {
    //   this.restaurant = _.where(this.currentuser.AgentRestaurants, {
    //     id: object.id,
    //   })[0];
    // }
    // this.currentRestaurant.setRestaurant(this.restaurant);
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(2);
    }
    titlee = titlee.split("/").pop();

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Agent Dashboard";
  }

  agentStatusFilter(event) {
    this.restaurant = event.value;
    this.currentRestaurant.setRestaurant(this.restaurant);
  }

  updateAvaibility(event) {
    if (this.errors.length <= 0) {
      var status = "OFFLINE";
      if (event.checked) {
        status = "AVAILABLE";
      }
      this.auth.updateAgentStatus(status).subscribe((response: any) => {
        this.getAgentAvailability(false, this.restaurant);
      });
    }
  }

  updateAgent(event) {
    var paramData = { dont_call_me: 0 };
    if (event.checked) {
      paramData = { dont_call_me: 1 };
    }
    this.voixHttp.put("v1/agent", paramData).subscribe((response: any) => {
      this.getAgentAvailability(false, this.restaurant);
    });
  }

  onSipSession() {
    // this.auth.openCcsBrowser();
  }

  toggleNotificationDrawer() {
    this.navbarService.emitNotificationDrawerToggle(!this.navbarService.notificationDrawerSub.getValue());
  }
}
