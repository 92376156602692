import { Component, Input } from "@angular/core";
import { convSoFar } from "app/ai-order-recieve/interface/conv-state.interface";

@Component({
  selector: "app-chat-box",
  templateUrl: "./chat-box.component.html",
  styleUrls: ["./chat-box.component.scss"],
})
export class ChatBoxComponent {
  @Input() conversationSoFar: convSoFar[] = [];
  constructor() {}
}
