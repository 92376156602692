<div class="main-content no-scroll">
  <div class="row container-fluid">
    <div class="row">
      <div class="col-md-12">
        <form method="#" action="#" [formGroup]="lineItemForm" (ngSubmit)="updateForm(lineItemForm.value)">
            <div class="row">    
              <div class="col-md-12">
                <mat-form-field class="example-full-width col-md-12">
                  <mat-select placeholder="Select Category" name="type" [formControl]="lineItemForm.controls['type']" required (selectionChange)="filterMenu()">
                    <mat-option *ngFor="let category of categories" value="{{ category | lowercase }}">
                      {{ category | uppercase }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width col-md-12" *ngIf="lineItemForm.controls['type'] && lineItemForm.controls['type'].value">
                  <mat-select placeholder="Select Menu" name="name" [formControl]="lineItemForm.controls['name']" (selectionChange)="filterVariation()">
                    <mat-option *ngFor="let menu of menuItems" value="{{ menu.name | lowercase }}">
                      {{ menu.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                
                <div *ngIf="lineItemForm.controls['name'] && lineItemForm.controls['name'].value">
                  <mat-form-field class="example-full-width col-md-12" *ngFor="let item of collectionList | keyValues; let i = index">
                    <div *ngIf="item?.value?.allow_multiple">
                      <mat-select placeholder="Select {{item.key}}" [formControl]="lineItemForm.controls[item.key]" multiple (selectionChange)="updateProperty($event, item.key)">
                        <mat-option *ngFor="let collection of item.value.data" value="{{ collection.name | lowercase }}">
                          {{ collection.name }}
                        </mat-option>
                      </mat-select>
                    </div>
                    <div *ngIf="!item?.value?.allow_multiple">
                      <mat-select placeholder="Select {{item.key}}" [formControl]="lineItemForm.controls[item.key]">
                        <mat-option *ngFor="let collection of item.value.data" value="{{ collection.name | lowercase }}">
                          {{ collection.name }}
                        </mat-option>
                      </mat-select>
                    </div>
                    
                  </mat-form-field>
                </div>
                
                <mat-form-field class="example-full-width col-md-12">
                  <mat-select placeholder="Select quantity" name="quantity" [formControl]="lineItemForm.controls['quantity']">
                    <mat-option *ngFor="let quantity of quantityList" value="{{ quantity }}">
                      {{ quantity }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="footer text-center">
              <button type="submit" class="btn btn-primary btn-round" [disabled]="!lineItemForm.valid">Submit</button>
            </div>

        </form>
      </div>
    </div>
  </div>
</div>
