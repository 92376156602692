import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VoixHttpService } from 'app/core/voix-http/voix-http.service';
import { Client } from '../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-selected-client',
  templateUrl: './selected-client.component.html',
  styleUrls: ['./selected-client.component.scss']
})
export class SelectedClientComponent {
  selectedClient: number;
  clients: Client[] = [];
  @Input() client;
  @Output() selectedClientForStores = new EventEmitter();

  constructor(private voixHttp: VoixHttpService){
    this.voixHttp.get("v1/agent-clients", {}, false, ('isAdminBuild' in environment)).subscribe((res: any) => {
      if (res) {
        this.clients = res.data;
        if (this.clients?.length) {
          this.selectedClient = this.client?.id || this.clients[0].id;
          this.selectedClientForStores.emit(this.client || this.clients[0]);
        }
      }
    });
  }

  changeStore(client) {
    this.selectedClientForStores.emit(client);
  }
}
