import { Customer } from "../response/cart.interface";

export interface CartRequest {
  apt_suite?: string;
  storeId: string;
  notes?: string;
  orderDestination: string;
  customer: Customer;
  cartId: string;
  orderReadyDate?: string;
  addressType: AddressType;
  locationName: string;
}

export enum AddressType {
  business = "business",
  hotel = "hotel",
  school = "school",
  apartment = "apartment",
  residential = "residential",
}
