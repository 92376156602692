<div>
  <mat-card *ngIf="storeDetails">
    <span *ngFor="let item of storeDetails.storeHours">
      <div style="padding: 5px; font-size: medium">
        <div>
          <b>{{ item.dayOfWeek }}: {{ item.time }} ({{ item.timezone }})</b>
        </div>

        <div *ngIf="holidayClosedFlag && holiday">
          <b>
            {{ "CLOSED:" + item.holidayMessage }}
          </b>
          <br />
        </div>
        <div *ngIf="storeDetails?.store?.store?.maxOrderAmt">
          <b>
            {{ "MAX ORDER AMOUNT" }}:
            {{ storeDetails?.store?.store?.maxOrderAmt | currency: "USD" }}
          </b>
          <br />
        </div>
        <div *ngIf="storeDetails?.store?.store?.minDeliveryAmt >= 0">
          <b>
            {{ "MIN DELIVERY AMOUNT" }}:
            {{ storeDetails?.store?.store?.minDeliveryAmt | currency: "USD" }}
          </b>
          <br />
        </div>
        <div *ngIf="storeDetails?.store?.store?.minOrderAmt >= 0">
          <b>
            {{ "MIN ORDER AMOUNT" }}:
            {{ storeDetails?.store?.store?.minOrderAmt | currency: "USD" }}
          </b>
        </div>
      </div>
    </span>
    <mat-divider></mat-divider>
    <span *ngFor="let item of storeDetails.allhours">
      <div style="padding: 5px; font-size: medium">
        <b> {{ item.dayOfWeek }}: {{ item.time }}</b> / {{ "PICKUP-CLOSE: "
        }}{{ item.pickUpCloseTime }}
      </div>
    </span>

    <mat-divider></mat-divider>
    <span *ngFor="let item of storeDetails?.store?.store?.orderTypes">
      <div style="padding: 5px; font-size: medium">
        <b> {{ item.displayName }}: {{ item.deferPrepMinutes }}m</b>
        <b
          *ngIf="
            storeDetails?.deliveryFee != '' && item.displayName == 'DELIVERY'
          "
          >{{ "- FEE:" }} {{ storeDetails?.deliveryFee | currency: "USD" }}</b
        >
        <b
          *ngIf="
            storeDetails?.deliveryRate != '' && item.displayName == 'DELIVERY'
          "
        >
          {{ ", TAX:" }} {{ storeDetails?.deliveryRate + "%" }}</b
        >
      </div>
    </span>
  </mat-card>
</div>
