import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import flagsmith from 'flagsmith';

flagsmith.init({
  environmentID: environment.flagsmith_environment_id,
  cacheFlags: false,
  enableAnalytics: false,
});

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  flagsmith = flagsmith;
  isFeatureOn(featureName: string) {
    return this.flagsmith.hasFeature(featureName);
  }

  getFeatureValue(featureName: string): any {
    return this.flagsmith.getValue(featureName);
  }
}