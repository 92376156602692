import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  map,
  startWith,
} from "rxjs";
import { FormControl } from "@angular/forms";
import { promoDefaultState, promoState } from "app/core/ngxs/state/promo.state";
import { Select } from "@ngxs/store";

@Component({
  selector: "vx-promo-autocomplete",
  templateUrl: "./promo-autocomplete.component.html",
  styleUrls: ["./promo-autocomplete.component.scss"],
})
export class PromoAutocompleteComponent implements OnChanges {
  @Input("item") item: any = {};
  @Output() applyPromo: EventEmitter<any> = new EventEmitter();
  @Select(promoState.getPromo) activePromos: Observable<promoDefaultState>;
  promoSubs: Subscription;
  allpromos: any[] = [];
  promoControl = new FormControl("");
  promosOptionsV2 = new BehaviorSubject<any>([]);
  constructor() {
    this.activePromos.subscribe((res: any) => {
      if (res.data) {
        this.allpromos = res.data;
        this.promosOptionsV2.next(this.allpromos);
      }
    });
    this.promoSubs = this.promoControl.valueChanges
      .pipe(
        startWith(""),
        map((value) => this._filter(value || ""))
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.item?.currentValue?.cartPromotions?.length) {
      const getPromo = this.allpromos.find(
        (promo) =>
          promo?.offerId ===
          changes?.item?.currentValue?.cartPromotions?.[0]?.offerId
      );
      this.promoControl.setValue(getPromo?.promoCode || "");
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    const option = this.allpromos.filter(
      (option) =>
        option?.description?.toLowerCase().includes(filterValue) ||
        option?.altDescription?.toLowerCase().includes(filterValue)
    );
    this.promosOptionsV2.next(option);
    return option;
  }

  displayPromo(value?: any) {
    return value
      ? this.allpromos.find((_) => _.promoCode === value).description
      : undefined;
  }

  getValue(value) {
    return value == "0" ? "" : " - $" + value;
  }

  applyPromoEmit(item) {
    item.cartPromotions = [
      {
        promoCode: this.promoControl.value,
        type: "",
      },
    ];
    this.applyPromo.emit({ item, type: "applyItemPromo" });
  }
  
  removeItemPromo(item) {
    if (this.promoControl?.value) {
      item.cartPromotions = [];
      this.promoControl.setValue("");
      this.applyPromo.emit({ item, type: "removeItemLevelPromo" });
    }
  }
}
