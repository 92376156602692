<div class="wrapper wrapper-full-page">
  <div class="full-page register-page" filter-color="black" data-image="../../assets/img/register.jpg">
    <div class="container">
      <div class="row">
        <div class="col-md-10 col-md-offset-1">
          <div class="card card-signup">
            <div class="text-center">
              <div class="logo-img">
                  <img src="/assets/img/voixai.png"/>
                  
              </div>
              <div class="logo-img text-logo-sub">
                <img class="text-logo" src="/assets/img/logo-text-voix.png" />
              </div>
              <h3 class="card-title text-center desktop-register-padding">Grow your business with the Restaurant AI Voix Assistant for receiving food order</h3>
            </div>
            
            <div class="row">
              <div class="col-md-5 col-md-offset-1">
                <div class="card-content">
                  <div class="info info-horizontal">
                    <div class="icon icon-rose">
                      <img src="/assets/img/headphones.svg"/>
                    </div>
                    <div class="description">
                      <h4 class="info-title">Smart Ordering</h4>
                      <p class="description">
                        Take Multiple order at same time and never loose an order with zero call wait time.
                      </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div class="icon icon-primary">
                      <img src="/assets/img/budget.svg"/>
                    </div>
                    <div class="description">
                      <h4 class="info-title">Cost Saving</h4>
                      <p class="description">
                        Save thousands in human cost by automating phone ordering and no missed revenue.
                      </p>
                    </div>
                  </div>
                  <div class="info info-horizontal">
                    <div class="icon icon-info">
                      <img src="/assets/img/graphic-on-whiteboard.svg"/>
                    </div>
                    <div class="description">
                      <h4 class="info-title">Onboard Future</h4>
                      <p class="description">
                        Connect with future of food ordering and get more orders from Amazon Alexa and Google Home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="social text-center">
                  <!-- <button class="btn btn-just-icon btn-round btn-twitter">
                    <i class="fa fa-twitter"></i>
                  </button>
                  <button class="btn btn-just-icon btn-round btn-dribbble">
                    <i class="fa fa-dribbble"></i>
                  </button>
                  <button class="btn btn-just-icon btn-round btn-facebook">
                    <i class="fa fa-facebook"> </i>
                  </button> -->

                  <div class="social-link">
                    <button class="btn btn-facebook">
                      <i class="fa fa-facebook-square"></i> Connect With Facebook
                      <div class="ripple-container"></div>
                    </button>

                    <button class="btn btn-gmail">
                      <i class="fa fa-google"></i> Connect with Google
                      <div class="ripple-container"></div>
                    </button>

                  </div>

                  <h4> <strong> OR </strong> </h4>
                </div>
                <form id="RegisterValidation" [formGroup]="registrationForm" (ngSubmit)="submitForm(registrationForm)" class="form" method="" action="">
                  <div class="card-content pt-pb-0 mt-50">
                    <!-- <div class="input-group">
                      <span class="input-group-addon">
                        <i class="material-icons">face</i>
                      </span>
                      <div class="form-group label-floating is-empty">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Full Name" [formControl]="registrationForm.controls['full_name']">
                          <mat-error *ngIf="registrationForm.controls['full_name'].hasError('required')">
                            Full name is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                        <span class="material-input"></span>
                      </div>
                    </div> -->
                    <div class="input-group">
                      <span class="input-group-addon">
                        <i class="material-icons">email</i>
                      </span>
                      <div class="form-group label-floating is-empty mt-0">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Email" [formControl]="registrationForm.controls['email']">
                          <mat-error *ngIf="registrationForm.controls['email'].hasError('email') && !registrationForm.controls['email'].hasError('required')">
                            Please enter a valid email address
                          </mat-error>
                          <mat-error *ngIf="registrationForm.controls['email'].hasError('required')">
                            Email is <strong>required</strong>
                          </mat-error>
                        </mat-form-field>
                        <span class="material-input"></span>
                      </div>
                    </div>
                    <div class="input-group">
                      <span class="input-group-addon">
                        <i class="material-icons">lock_outline</i>
                      </span>
                      <div class="form-group label-floating is-empty mt-0">
                        <mat-form-field class="example-full-width">
                          <input matInput placeholder="Password" minlength="8" [formControl]="registrationForm.controls['password']" [type]="hide ? 'password' : 'text'"
                          required>
                          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                          <mat-error *ngIf="registrationForm.controls['password'].hasError('required')">
                            Password is <strong>required</strong>
                          </mat-error>
                          <mat-error *ngIf="registrationForm.controls['password'].hasError('minlength')">
                            Password must be at least 8 characters long.
                          </mat-error>
                        </mat-form-field>
                        <span class="material-input"></span>
                      </div>
                    </div>
                    <!-- If you want to add a checkbox to this form, uncomment this code -->
                    <div class="checkbox text-center">
                      <!-- <mat-checkbox [formControl]="registrationForm.controls['terms']" required> -->
                          <span class="checkbox-material"></span> By clicking sign up, connect with facebook or connect with google, you agree to voix
                          <a href="http://voix.ai/terms-of-service.html"  target="_blank">Terms of Service</a> and <a href="http://voix.ai/privacy-policy.html" target="_blank">Privacy Policy.</a> 
                      <!-- </mat-checkbox> -->
                    </div>
                  </div>
                  <div class="footer text-center">
                    <button type="submit" class="btn btn-primary btn-round" [disabled]="!registrationForm.valid">Sign Up</button>
                  </div>

                  <hr>
                  <div class="w-full text-center p-t-55">
                      <span class="txt2">
                          Already have an account?
                      </span>

                      <a routerLink='/login' class="txt2 bo1">
                          Sign In
                      </a>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <footer class="footer">
      <div class="container">
        <nav class="pull-left">
          <ul>
            <li>
              <a href="#">
                Home
              </a>
            </li>
            <li>
              <a href="#">
                Company
              </a>
            </li>
            <li>
              <a href="#">
                Portofolio
              </a>
            </li>
            <li>
              <a href="#">
                Blog
              </a>
            </li>
          </ul>
        </nav>
        <p class="copyright pull-right">
          ©
          <script type="text/javascript" async="" src="http://www.google-analytics.com/ga.js"></script><script>
          document.write(new Date().getFullYear())
        </script>2018
        <a href="http://www.creative-tim.com"> Creative Tim </a>, made with love for a better web
      </p>
    </div>
  </footer> -->
  <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg) "></div></div>
</div>

<script type="text/javascript">
  function setFormValidation(id) {
    $(id).validate({
      errorPlacement: function(error, element) {
        $(element).closest('div').addClass('has-error');
      }
    });
  }

  $(document).ready(function() {
    setFormValidation('#RegisterValidation');
    setFormValidation('#TypeValidation');
    setFormValidation('#LoginValidation');
    setFormValidation('#RangeValidation');
  });
</script>