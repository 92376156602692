export class ActionSavePromo {
  static readonly type = "[PROMO] SAVE PROMO";
  constructor(public payload: any) {}
}

export class ActionSaveSelectPromo {
  static readonly type = "[PROMO] SAVE SELECT PROMO";
  constructor(public payload: any) {}
}

export class getAllPromos {
  static readonly type = "[PROMO] GET PROMO FOR MANAGEMENT";
  constructor(public payload: any) {}
}

export class setPromoOverride {
  static readonly type = "[PROMO] SAVE PROMO FOR OVERRIDE";
  constructor() {}
}

export class setPromoNull {
  static readonly type = "[PROMO] PROMO NULL";
  constructor() {}
}