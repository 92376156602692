import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  Optional,
} from "@angular/core";
import { VoixHttpService } from "./../core/voix-http/voix-http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "./../core/notification/notification.service";
import * as io from "socket.io-client";
// import { Response } from '@angular/http';
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { AppConstants } from "app/app.constants";
import { AuthenticationService } from "app/core/auth/authentication.service";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { FeatureFlagService } from "app/core/services/feature-flag.service";
import { SubmitOrderComponent } from "app/order-receive/submit-order/submit-order.component";
import { TipActions, ITipModel } from "app/order-receive/tip/model/tip.model";
import { generalService } from "app/core/services/general.service";
import { getAllPromos } from "app/core/ngxs/action/promo_override.action";
import { alertNotificationService } from "app/core/services/alert-notifcation.service";
import { BulkPromoReportModal } from "app/modify-coupon/bulk-report-modal/bulk-promo-report-modal.component";
import { CommonLibs as constants } from "../commonLibs";
import { environment } from "environments/environment";
import {
  ActionCreateCart,
  ActionGetCart,
  ActionUpdateCartItem,
} from "app/core/ngxs/action/cart.action";
import { Select, Store } from "@ngxs/store";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActionGetStore } from "app/core/ngxs/action/store.action";
import { storeDefaultState, storeState } from "app/core/ngxs/state/store.state";
import { Observable } from "rxjs";
import { billingAddressPayment } from "app/transaction/billing-address/interface/billing-address.interface";
import {
  Address,
  StoreDetails,
} from "../../../mediator-commlibs/interfaces/response/store.interface";
import {
  AddCartItem,
  CartItemResponse,
} from "../../../mediator-commlibs/interfaces/response/cartItem.interface";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { SubmitOrderPayload } from "app/order-receive/order-receive.component";
import { ActionSubmitOrder } from "app/core/ngxs/action/order.action";
import { OrderDialogComponent } from "app/order-receive/order-dialog/order-dialog.component";
import { IClientListing } from "app/order-receive/clients/model/client-listing.model";
import { CartResponseComponent } from "app/cart-response/cart-response.component";
import { ActionSendPayment } from "app/core/ngxs/action/payment.action";
import {
  paymentDefaultState,
  paymentState,
} from "app/core/ngxs/state/payment.state";
@Component({
  selector: "app-payments",
  templateUrl: "./payments.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit, AfterViewInit {
  @Select(cartState.getCart) cart: Observable<cartDefaultState>;
  @Select(storeState.getStore) storeState: Observable<storeDefaultState>;
  @Select(paymentState.getPayments)
  getPaymentSplit: Observable<paymentDefaultState>;
  selectedPaymentType: string = "CARD";
  paymentTypeV2 = [
    { value: "CARD", viewValue: "CARD" },
    { value: "GIFT", viewValue: "GIFT" },
  ];
  gift_card: FormGroup;
  cartId: string;
  phone: string;
  cartDetail: CartItemResponse;
  transactionSetupID: string = "";
  socket: any;
  messageData: any = [];
  uuid: string;
  storeId: string;
  client_code: string;
  tipAction: string = null;
  isPaymentSupportInternal: boolean = true;
  paymentAddressRequiredForPayments: boolean = false;
  isCartHasItem: boolean = false;
  tipActionEnum = () => {
    return TipActions;
  };
  AI: string = "AI";
  isCallerCustomer: boolean = false;
  orderDestination: string;
  payment_component_url: SafeUrl = undefined;
  isCartEmpty: boolean = true;
  isCartFound: boolean = true;
  restaurantId: number;
  orderData = null;
  preTipError: boolean = false;
  constructor(
    private fb: FormBuilder,
    private generalService: generalService,
    private router: Router,
    private crypto: CryptoService,
    @Optional() public dialogRef: MatDialogRef<SubmitOrderComponent>,
    protected _sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private emitTrans: emitTransactionsService,
    private changeDetector: ChangeDetectorRef,
    private featureFlagService: FeatureFlagService,
    private auth: AuthenticationService,
    private alertNotification: alertNotificationService,
    private notification: NotificationService,
    private ngxsStore: Store,
    private ngxService: NgxUiLoaderService,
    private voixHttp: VoixHttpService,
    public dialog: MatDialog
  ) {
    this.checkRouterUrl();
    // localStorage.setItem("agentCurrentUser", JSON.stringify({}));
    this.getRestaurantDetailsV2();

    this.socket = io(environment.io_url, {
      query: "token=" + this.uuid,
      transports: ["polling"],
    });
    this.messageData["customer"] = [];
    this.paymentAddressRequiredForPayments = true;

    this.socketConnection();
    this.getStoreSocket();
    // this.submitOrderV2(1);
    this.getCartFromState();
    this.GetCurrentTipValueSocket();
    this.getPaymentUrlRequest();
    this.getPaymentUrlCheck();
    this.gift_card = this.fb.group({
      cartId: [this.cartId],
      cardNumber: [null, [Validators.required]],
      pin: [null, [Validators.required]],
      tipAmount: [null],
    });

    if (localStorage.getItem("tA")) {
      this.gift_card.patchValue({
        tipAmount: this.crypto.DecryptWithoutPromise("tA"),
      });
      this.inputbehaviour(this.crypto.DecryptWithoutPromise("tA"));
      // this.billingAddress.patchValue({
      //   ccPhone: this.phone,
      // });
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      var orComponent = this;
      if (!orComponent.socket.connected) {
        orComponent.alertNotification.set({
          text: "Agent Backend socket connection is not connected",
          value: AppConstants.NOT_CONNECTED,
          error: true,
        });
      }
    }, 5000);
  }

  getRestaurantDetailsV2() {
    this.checkRouterUrl();
    this.voixHttp
      .getClientByClientCode("v1/client-detail/" + this.client_code)
      .subscribe((res: IClientListing[]) => {
        // console.log(res);
        if (res.length > 0) {
          this.restaurantId = res[0].id;
        }
      });
  }
  setLocalStorageParent() {
    // window.parent.localStorage.setItem("agentCurrentUser", JSON.stringify({}));
  }

  socketConnection() {
    var that = this;
    // console.log(uuid());
    that.socket.on("connect", function () {
      that.alertNotification.set({
        value: AppConstants.NOT_CONNECTED,
        error: false,
      });

      that.messageData["customer"][that.uuid] = [];
    });

    that.socket.on("message", function (data) {
      if (data.channel) {
        // console.log(data);
        that.uuid = data.id;

        if (data.channel == constants.APPLY_PRETIP) {
          that.ngxService.stop();
          console.log(data.message.data);
          if (data.message.data.error) {
            that.loggedELK(data.message.data.error.message, "APPLY_TIP", true);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            if (
              data.message.data.error.message ===
              "Order ID already exists in Cart."
            ) {
              that.preTipError = true;
            }
          } else {
            // that.ngxService.stop();
            //load iframe

            // that.ngxService.start();

            that.createCartState(data.message.data);
            that.getCartFromState();
            that.UpdateCartCache(data.message.data);

            that.storeState.subscribe((res: any) => {
              if (res) {
                const isPaymentSupportInternal: boolean =
                  that.generalService.checkInternalPaymentAccept(res);
                console.log(isPaymentSupportInternal);
                if (isPaymentSupportInternal) {
                  that.getPaymentUrlSocket(null);
                } else if (that.billingAddressZip) {
                  that.getPaymentUrlSocket(that.billingAddress);
                } else {
                  that.changeDetector.detectChanges();
                  that.ngxService.stop();
                  console.log("Not Internal");
                }
              }
            });
          }
        } else if (data.channel === constants.GET_CART) {
          // console.log(data.message.data);
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.isCartFound = false;
            that.isCartEmpty = false;
          } else {
            if (data.message.data.data.cart.cartMenuItems.length === 0) {
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                "CART IS EMPTY",
                "danger-snackbar"
              );
              that.isCartEmpty = true;
              that.isCartFound = true;
            } else {
              if (data.message.data.data.cart.orderId) {
                that.isCartFound = false;
                that.isCartEmpty = false;
              } else {
                that.isCartEmpty = false;
                that.isCartFound = true;
                that.isCartHasItem = true;
                that.getCartDetailState(data.message.data);
              }
            }
          }
        } else if (data.channel === constants.GET_STORE_DETAILS) {
          // that.ngxService.stop();
          console.log(data.message.data);
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.getStoreState(data.message.data);
            that.storeStateConfiguration();
            that.getCartSocket();
          }
        } else if (data.channel == constants.GET_PAYMENT_URL) {
          console.log(data.message);
          that.changeDetector.detectChanges();
          that.ngxService.stop();

          if (data.message.data) {
            //  console.log("Payment Component URL:", data.message.data);
            that.payment_component_url = data.message.data;
            that.emitTrans.getIframeUrl(data.message.data);
            // console.log(that.agent);
            // window.parent.postMessage(that.agent, "*");
            // window.parent.localStorage.setItem("agentCurrentUser", that.agent);
          } else {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          }
          //   "http://localhost:4200/transaction/worldpay?HostedPaymentStatus=Complete&TransactionSetupID=44D8292E-771C-48A4-B1D2-D1BEFCF7762A&TransactionID=211485291&ExpressResponseCode=0&ExpressResponseMessage=Approved&CVVResponseCode=P&ApprovalNumber=82003A&LastFour=0000&ValidationCode=79122D32B09B4D1E&CardLogo=Visa&ApprovedAmount=12.00&Bin=446203&Entry=Manual&NetTranID=003046319140591&TranDT=2023-02-15%2002:51:54";
        } else if (data.channel === constants.WORLD_PAY_CC_REVERSAL) {
          console.log(data.message);
          that.emitTrans.getIframeUrl("");
          if (data.message.data) {
            that.ngxService.stop();
            that.loggedELK(
              `${that.errorMessageWorldpay}`,
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );

            that.notification.openSnackBarActionV2(
              "end",
              "top",
              `${that.errorMessageWorldpay} Try Again`,
              "danger-snackbar"
            );
          } else if (
            data.message.data.message &&
            data.message.data.message === "Failed"
          ) {
            that.ngxService.stop();
            that.loggedELK(
              "RV Failed Billing Address/Postal Code Mismatch Try Again".concat(
                JSON.stringify(data.message.data)
              ),
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "RV Failed Billing Address/Postal Code Mismatch Try Again",
              "danger-snackbar"
            );
          } else {
            that.ngxService.stop();
            that.loggedELK(
              JSON.stringify(data.message.data),
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          }
        } else if (data.channel == constants.SUBMIT_ORDER) {
          that.ngxService.stop();
          console.log(data.message);
          if (data.message.data.error) {
            that.getCartFromState();
            that.createCart(that.cartDetail);

            that.loggedELK(
              data.message.data.error.message,
              "SUBMIT_ORDER_DASHBOARD_AI",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "FAILED TO PROCESS ORDER PLEASE TRY AGAIN",
              "danger-snackbar"
            );
          } else {
            that.saveOrder(data.message.data);

            // localStorage.removeItem("Rmt");
          }
          // that.pleasewait.close();
        } else if (data.channel == constants.CREATE_CART) {
          console.log(data.message);
          that.ngxService.stop();
          localStorage.removeItem("Rmt");
          if (data.message.data.error) {
            that.loggedELK(
              data.message.data.error.message,
              "CREATE_CART_DASHBOARD",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.createCartState(data.message.data);
            that.getCartFromState();

            that.router
              .navigateByUrl(
                `/payment/${localStorage.getItem("uuid")}/${that.storeId}/${
                  that.client_code
                }/${that.cartDetail.id}/${that.restaurantphone}`
              )
              .then(() => {
                // window.location.reload();
              });
          }
        } else if (data.channel == constants.PAYMENT_CREDIT_CARD) {
          console.log(data.message.data);

          if (data.message.data.error) {
            that.ngxService.stop();
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            // that.dialog.closeAll();
            that.submitOrderSocket(true, that.orderForm);
          } else {
            that.ngxsStore.dispatch(
              new ActionSendPayment(data.message.data.data.data)
            );
            // console.log(data.message.data.data);
            // that.dialog.closeAll();
            that.submitOrderCard(
              data.message.data.data.data.paymentTransactionId,
              data.message.data.data.data.cardName
            );
            // localStorage.removeItem("Rmt");
          }
        }
      }
    });
    that.socket.on("open", function (connect) {
      console.log("open connect", connect);
    });
    that.socket.on("event", function (data) {
      console.log("event connect", data);
    });
    that.socket.on("disconnect", function () {
      console.log("socket disconnect");
      that.alertNotification.set({
        text: "Agent Backend socket connection is disconnected",
        value: AppConstants.NOT_CONNECTED,
        error: true,
      });
    });
  }

  UpdateCartCache(payload: any) {
    // console.log(payload);
    this.ngxsStore.dispatch(new ActionUpdateCartItem(payload));
  }

  submitOrderSocket(isEdit: boolean = false, data: any) {
    // this.getCartDetailSocket();
    console.log(data);
    this.checkRouterUrl();
    // var dialogref = this.dialog.open(SubmitOrderComponent, {
    //   disableClose: false,
    //   data: {
    //     isEdit: isEdit,
    //     order: data,
    //     paymentType: this.paymentTypeV2,
    //     orderDestination: this.cartDetail.orderDestination,
    //     payment_component_url: this.payment_component_url,
    //     clientName: this.client_code,
    //     storeId: this.storeId,
    //   },
    //   width: "800px",
    //   height: "800px",
    // });
    // dialogref.afterClosed().subscribe((result) => {
    //   if (result) {
    //     this.ngxService.start();
    //     if (result.paymentType === "GIFT") {
    //       this.paymentTypeV2 = result.paymentType;
    //       this.orderForm = result.gift_card;

    //       const payload = {
    //         id: this.uuid,
    //         agent_email: this.AI,
    //         channel: constants.PAYMENT_GIFT_CARD,
    //         message: {
    //           ClientCode: this.client_code,
    //           storeId: this.storeId,
    //           paymentBody: this.orderForm,
    //         },
    //       };
    //       // console.log(payload);
    //       this.socket.send(payload);
    //     } else if (result.paymentType === "CARD") {
    //       this.paymentTypeV2 = result.paymentType;
    //       this.orderForm = result.cardForm;
    //       const payload = {
    //         id: this.uuid,
    //         agent_email: this.AI,
    //         channel: constants.PAYMENT_CREDIT_CARD,
    //         message: {
    //           ClientCode: this.client_code,
    //           storeId: this.storeId,
    //           paymentBody: this.orderForm,
    //         },
    //       };
    //       console.log(payload);
    //       this.socket.send(payload);
    //     }
    //   }
    // });
  }

  submitOrderCard(paymentToken, paymentType) {
    // this.pleasewait.close();
    // this.pleasewait = this.dialog.open(LoaderComponent, {
    //   hasBackdrop: true,
    //   disableClose: true,
    // });
    this.checkRouterUrl();
    let orderBody: any;
    let payments: any[] = [];
    this.getPaymentSplit.subscribe((res: any) => {
      console.log(res);
      if (res) {
        console.log(res);
        if (res.length > 0) {
          for (let index = 0; index < res.length; index++) {
            const paymentPayload = res[index];
            payments.push({
              paymentToken: paymentPayload.paymentTransactionId,
              paymentType: paymentPayload.cardName,
              currency: "USD",
            });
          }

          const uniquePayments = payments.filter((obj, index) => {
            return (
              index ===
              payments.findIndex((o) => obj.paymentToken === o.paymentToken)
            );
          });
          payments = uniquePayments;

          orderBody = {
            cartId: this.cartId,
            payments: payments,
          };
        } else {
          orderBody = {
            cartId: this.cartId,
            paymentToken: paymentToken,
            paymentType: paymentType,
          };
        }
      }
    });
    this.storeStateConfiguration();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.SUBMIT_ORDER,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        orderBody: orderBody,
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }

  openCartResponse() {
    this.getCartFromState();
    console.log(this.cartDetail);
    const dialogRef = this.dialog.open(CartResponseComponent, {
      width: "800px",
      height: "700px",
      data: {
        order: {
          cart: this.cartDetail,
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }

  createCartState(payload) {
    this.ngxsStore.dispatch(new ActionCreateCart(payload));
  }

  createCart(cart: CartItemResponse) {
    console.log(cart);
    delete cart.cartMenuItems;
    delete cart.externalOrderId;

    this.checkRouterUrl();
    this.ngxService.start();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.CREATE_CART,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        cartBody: { ...cart, cartId: this.cartId },
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }

  getWindowListener() {
    // console.log("message From Parent");
    window.addEventListener("message", this.receiveMessage.bind(this), false);
  }

  errorMessageWorldpay: string;
  receiveMessage(event) {
    // console.log("Received data from iframe", event);
    if (
      event.data.HostedPaymentStatus != undefined &&
      event.data.HostedPaymentStatus == "Complete"
    ) {
      this.loggedELK(
        JSON.stringify(event.data),
        "WORLD_PAY_RES_AFTER_PAYMENT",
        false
      );
      // console.log("\n World Pay Socket Channel", event.data);
      if (
        event.data.AVSResponseCode === "N" ||
        event.data.CVVResponseCode === "N"
      ) {
        this.errorMessageWorldpay =
          event.data.AVSResponseCode === "N" ? "AVS MISMATCH" : "CVV MISMATCH";
        this.worldPayReversalSocket(event.data);
      } else {
        this.setLocalStorageParent();
        this.dialog.closeAll();
        this.submitOrderWorldPay(event.data);
      }
    }

    if (event.data.type == "INTERNAL") {
      console.log("INTERNAL", event.data);
      this.setLocalStorageParent();
      this.paymentCCInternal(event.data);
    }
  }
  orderForm: any;
  paymentCCInternal(response) {
    this.checkRouterUrl();
    this.ngxService.start();
    this.selectedPaymentType = response.paymentType;
    this.orderForm = response.cardForm;

    let payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.PAYMENT_CREDIT_CARD,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        paymentBody: { ...this.orderForm, cartId: this.cartId },
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }

  submitOrderWorldPay(result) {
    this.ngxService.start();
    this.checkRouterUrl();
    const paymentType = result.CardLogo;
    const orderBody = {
      cartId: this.cartId,
      payment: {
        paymentType: paymentType,
        paymentToken: "",
        currency: "USD",
      },
      payload: JSON.stringify(result),
    };
    this.storeStateConfiguration();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.SUBMIT_ORDER,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        orderBody: orderBody,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  worldPayReversalSocket(params) {
    this.ngxService.start();
    this.checkRouterUrl();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.WORLD_PAY_CC_REVERSAL,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        cartId: this.cartId,
        params: params,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }
  cartTotal: number;
  getCartFromState() {
    console.log("call");
    this.cart.subscribe((res: cartDefaultState) => {
      const cartDetails: CartItemResponse = res as unknown as CartItemResponse;
      if (cartDetails) {
        this.cartDetail = cartDetails;
        this.cartTotal = cartDetails.cartLedger.expectedLedger.lineItemPrice;
      }
    });
  }

  submitOrderV2(payload) {
    console.log(payload);
    this.ngxsStore.dispatch(new ActionSubmitOrder(payload, this.storeAddress));
    this.dialog.open(OrderDialogComponent, {
      disableClose: true,
      width: "700px",
      height: "500px",
      data: {
        order: payload,
        store: this.storeAddress,
        cart: this.cartDetail,
        isCustomer: true,
      },
      backdropClass: "backdropBackground",
    });
  }
  callDuration: string = "";
  saveOrder(payload: any) {
    this.selectedPaymentType = "CARD";
    this.checkRouterUrl();
    this.emitTrans.durationTime$.subscribe((time: any) => {
      if (time) {
        this.callDuration = time;
      }
    });
    const body: SubmitOrderPayload = {
      order_id: payload.data.orderId || "",
      correlationid: this.uuid || "",
      customer_address:
        payload.data.order.cart.customer.deliveryAddress.streetLine4 || "",
      customer_name:
        payload.data.order.cart.customer.firstName +
          " " +
          payload.data.order.cart.customer.lastName || "",
      customer_phone: payload.data?.order?.cart?.customer?.phone || "",
      restaurant_phone: this.restaurantphone || "",
      restaurant_id: 0,
      order_items_count: payload?.data?.order?.cart?.cartMenuItems?.length || 0,
      call_duration: this.callDuration || "",
      agent_email: this.AI,
      external_store_id: this.storeId,
      total_price:
        payload?.data?.order?.cart?.cartLedger?.expectedLedger?.lineItemPrice ||
        "",
      client_id: this.restaurantId || 0,
      client_name: this.client_code,
      order_type: payload?.data?.order?.cart?.orderDestination || "",
      payment_mode: "",
      order_metadata: "",
    };
    body.payment_mode = payload?.data?.order?.payments
      ? JSON.stringify(payload?.data?.order?.payments || [])
      : payload?.data?.order?.payment?.paymentType;
    body.order_metadata = JSON.stringify(payload?.data || {});
    console.log(body);

    this.voixHttp.postSubmitOrderAI("v1/submit-order-ai", body).subscribe(
      (res) => {
        this.loggedELK(JSON.stringify(res), "SAVE_ORDER_DB", false);
        console.log(res);
      },
      (error) => {
        this.loggedELK(error, "SAVE_ORDER_DB_ERROR", true);
        const errorHandle = JSON.stringify(error);
        this.notification.show("danger", "notifications", errorHandle);
      }
    );
    this.submitOrderV2(payload);
  }

  billingAddressZip: number | null = null;
  billingAddress: billingAddressPayment;
  getPaymentUrlRequest() {
    this.emitTrans.getPaymentUrlRequest$.subscribe(
      (res: billingAddressPayment) => {
        if (res) {
          // this.ngxService.start();
          // console.log("Get Payment URL Request");
          this.billingAddress = res;
          this.billingAddressZip = res.zip_code;
          this.getPaymentUrlSocket(res);
        }
      }
    );
  }
  loggedELK(data: string, name: string, isError: boolean) {
    this.checkRouterUrl();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: isError
        ? constants.LOGGED_ERROR_DASHBOARD
        : constants.LOGGED_INFO_DASHBOARD,
      message: {
        data: data, //Data or Error in String
        ClientCode: this.client_code,
        storeId: this.storeId,
      },
      name: name, //Message Name
    };
    this.socket.send(payload);
  }

  getPaymentUrlSocket(billingAddress: billingAddressPayment | null) {
    this.ngxService.start();
    this.checkRouterUrl();
    const billingAddressComp: billingAddressPayment = billingAddress
      ? billingAddress
      : {
          billing_address: "",
          zip_code: undefined,
        };
    if (!billingAddressComp) {
      billingAddressComp["billing_address"] = "";
    }
    let payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.GET_PAYMENT_URL,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        cartId: this.cartId,
        billingAddress: billingAddressComp,
        restaurantPhone: this.restaurantphone,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  checkRouterUrl() {
    console.log(this.router.url);
    const routerSplitted: string[] = this.router.url.split("/");
    // console.log(routerSplitted);
    this.uuid = routerSplitted[2];
    this.storeId = routerSplitted[3];
    this.client_code = routerSplitted[4];
    this.cartId = routerSplitted[5];
    this.restaurantphone = routerSplitted[6];

    // console.log(this.cartId);

    localStorage.setItem("uuid", this.uuid);

    if (this.router.url.includes("/payment")) {
      // console.log(this.router.url);
      this.isCallerCustomer = true;
    }
  }
  storeAddress: Address;
  restaurantphone: string;
  storeStateConfiguration() {
    this.storeState.subscribe((response: storeDefaultState) => {
      const storeDetails: StoreDetails = response as unknown as StoreDetails;
      if (storeDetails) {
        const paymentMethods: string[] = storeDetails.store.paymentMethods;
        this.storeAddress = storeDetails.store.address;
        //TODO: remove the below check to add gift option on order submit popup.
        const isGiftMethodExist = paymentMethods.some((paymentMethod: string) =>
          paymentMethod.includes("gift")
        );
        this.isPaymentSupportInternal =
          this.generalService.checkInternalPaymentAccept(storeDetails);
        if (!isGiftMethodExist) {
          this.paymentTypeV2 = this.paymentTypeV2.filter(
            (method) => method.value != "GIFT"
          );
        }
        this.paymentAddressRequiredForPayments =
          storeDetails.store.paymentAddressRequiredForPayments;
      }
    });
  }

  getPaymentUrlCheck() {
    this.emitTrans.paymentUrl$.subscribe((res: string) => {
      if (res) {
        // this.changeDetector.detectChanges();
        // this.isInternalUrl = res.includes("certtransaction.hostedpayments.com");
        this.payment_component_url =
          this._sanitizer.bypassSecurityTrustResourceUrl(res);
        this.changeDetector.detectChanges();
      } else if (res === "") {
        this.payment_component_url = undefined;
      }
    });
  }

  GetCurrentTipValueSocket() {
    this.emitTrans.getCurrentTipValue$.subscribe((tip: ITipModel) => {
      this.checkRouterUrl();
      // console.log("INITIATE THE TIP CHANNEL", tip);

      const payload = {
        id: this.uuid,
        agent_email: this.AI,
        channel: constants.APPLY_PRETIP,
        message: {
          ClientCode: this.client_code,
          storeId: this.storeId,
          cartId: this.cartId,
          tipBody: { tipAmount: tip.TipValue },
        },
      };
      // console.log(payload);
      this.socket.send(payload);
    });
  }

  getStoreState(payload) {
    this.ngxsStore.dispatch(new ActionGetStore(payload));
  }

  getStoreSocket() {
    // this.ngxService.start();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.GET_STORE_DETAILS,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  getCartDetailState(payload) {
    this.ngxsStore.dispatch(new ActionGetCart(payload));
  }

  getCartSocket() {
    this.checkRouterUrl();
    // this.ngxService.start();
    const payload = {
      id: this.uuid,
      agent_email: this.AI,
      channel: constants.GET_CART,
      message: {
        ClientCode: this.client_code,
        storeId: this.storeId,
        cartId: this.cartId,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  isEditFalse(data) {
    if (data.orderDestination == "DRIVE_UP_PICK_UP") {
      this.paymentTypeV2 = [
        { value: "CARD", viewValue: "CARD" },
        { value: "GIFT", viewValue: "GIFT" },
      ];
    }
  }

  ngOnInit() {
    this.getWindowListener();
    this.GetCurrentTipValue();
  }
  GetCurrentTipValue() {
    this.emitTrans.getCurrentTipValue$.subscribe((tip: ITipModel) => {
      this.tipAction = tip.Action == TipActions.ADD_TIP ? "ADD_TIP" : null;
      this.payment_component_url = undefined;
    });
  }
  get ccNumberGCRequired() {
    return this.gift_card.get("cardNumber").hasError("required");
  }
  get pinGCRequired() {
    return this.gift_card.get("pin").hasError("required");
  }

  submitOrderCash() {
    this.dialogRef.close({ paymentType: this.selectedPaymentType });
  }

  totalAmountC = new Number(0);
  totalAmount: string;
  inputbehaviour(evt) {
    this.totalAmountC =
      Number(evt) +
      Number(this.cartDetail.cartLedger.expectedLedger.lineItemPrice);
    const totalAmountTemp = this.totalAmountC.toString();
    this.totalAmount = parseFloat(totalAmountTemp).toFixed(2);
  }

  submitOrderGift() {
    this.dialogRef.close({
      paymentType: this.selectedPaymentType,
      gift_card: this.gift_card.value,
    });
  }

  ngOnDestroy() {}
}
