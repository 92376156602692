import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../core/modules/material.module";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { emitTransactionsService } from "../core/services/emitTransactions.service";
import { StoreDetailComponent } from "./store-detail/store-detail.component";
import { OrderInProgressComponent } from "../order-receive/order-in-progress/order-in-progress.component";
import { SharedModule } from "app/core/shared/shared.module";
import { ClientNumberComponent } from './client-number/client-number.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrderByPipe } from "./order-by.pipe";
import { UpsellingCount } from "./upselling-count";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, SharedModule,FormsModule,ReactiveFormsModule, MatButtonModule, MatMenuModule],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    StoreDetailComponent,
    ClientNumberComponent,
    OrderByPipe,
    UpsellingCount,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    StoreDetailComponent,
    SidebarComponent,
  ],
  entryComponents: [StoreDetailComponent, OrderInProgressComponent],
  providers: [emitTransactionsService],
})
export class ComponentsModule {}
