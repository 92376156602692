<div class="row">
  <div class="col-lg-12 col-md-12">
    <div class="card">
      <div class="card-header" data-background-color="voix-blue">
        <h4 class="title">Deals</h4>

        <button
          type="button"
          matTooltip="Save Deal"
          matTooltipPosition="below"
          class="btn btn-primary refresh-table"
          (click)="saveDeals()"
          *ngIf="userPermissions.DEAL_EDIT | permissions"
        >
          APPLY CHANGES
        </button>
        <!-- <p class="category">New Order on 15th September, 2016</p> -->
      </div>
      <div class="card-content table-responsive" >
        <table class="table table-hover">
          <tr>
            <th class="check_uncheck_common">
              <mat-checkbox [checked]="allDealsSelected" (change)="SelectUnSelectAll($event)"></mat-checkbox>&nbsp;Select
            </th>
            <th *ngIf="isShowSortOrder">Sort Order</th>
            <th *ngIf="!isShowSortOrder">Priority</th>
            <th>Id</th>
            <th>Promo Code</th>
            <th class="coupon_th">Coupon/Promo</th>
            <th>Display Name</th>
            <th>Phone Deal</th>
            <th>Auto Apply</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
          <!-- <ng-container> -->
            <ng-container *ngIf="dealState | async as deals">
            <tr
              *ngFor="
                let item of allDeals | filterPromo : search : 'promoCode';
                index as i
              "
              style="cursor: pointer"
            >
              <td class="text-center">
                <mat-checkbox
                  [checked]="item.isOverridden"
                  class="example-margin"
                  (click)="addDealForOverride(item, !item.isOverridden, i)"
                ></mat-checkbox>
              </td>
              <td *ngIf="isShowSortOrder" (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span class="offerId-span">{{ item.sortOrder }}</span>
              </td>
              <td *ngIf="!isShowSortOrder">
                <input
                  type="number"
                  class="priority-input"
                  [(ngModel)]="item.priority"
                  placeholder="Enter priority"
                />
              </td>
              <td (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span class="offerId-span">{{ item.offerId }}</span>
              </td>
              <td (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span>{{ item.promoCode || "-N/A-" }}</span>
              </td>
              <td (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span class="offerId-span">{{
                  item.altDescription || "-N/A-"
                }}</span>
              </td>
              <td (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span class="offerId-span">{{
                  item.description || "-N/A-"
                }}</span>
              </td>
              <td (click)="addDealForOverride(item, !item.isOverridden, i)">
                <span class="offerId-span">{{
                  item.showOfferInPapacall === undefined ? 'N/A' : item.showOfferInPapacall ? "Enabled" : "Disabled"
                }}</span>
              </td>
              <td>
                <div class="inputs">
                  <mat-slide-toggle color="primary" [(ngModel)]="item.isActiveItem" (change)="changeAutoApplied($event, item)" ></mat-slide-toggle>
                </div>
              </td>
              <td>
                <div class="d-flex" style="justify-content: center;">
                  <span class="offerId-span" [ngClass]="checkDateValid(item.startDate, item.endDate) ? 'txt-green' : 'txt-red'">{{ item.startDate ? (item.startDate | date: 'dd/MM/yyyy') : '-'}}</span>
                </div>
              </td>
              <td>
                <div class="d-flex" style="justify-content: center;">
                  <span class="offerId-span" [ngClass]="checkDateValid(item.startDate, item.endDate) ? 'txt-green' : 'txt-red'">{{ item.endDate ? (item.endDate | date: 'dd/MM/yyyy') : '-'}}</span>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
