import { IRoleListing } from './../model/role-listing.model';
import { IComponentLoadData } from './../model/component-load-data';
import { RoleListingComponent } from './../role-listing/role-listing.component';
import { Component, OnInit, ViewChild,AfterViewInit, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AddRoleComponent } from '../add-role/add-role.component';
import { UtilityService } from 'app/core/services/Utility.service';
import { ComponentService } from 'app/core/services/component.service';
import { Permissions } from 'app/core/auth/model/permissions.model';
import { PermissionsPipe } from 'app/core/pipes/permissions.pipe';



@Component({
  selector: 'app-view-roles',
  templateUrl: './view-roles.component.html',
  styleUrls: ['./view-roles.component.scss'],
})
export class ViewRolesComponent implements OnInit, AfterViewInit {
  userPermissions = Permissions;
  

  @ViewChild('componentPlaceHolder', { read: ViewContainerRef }) addRoleComponent: ViewContainerRef | undefined;
  initialComponent: string = "RoleListing";
  defaultButtonText: string = "AddNew";
  formTitle: string = "List Of Roles";
  isAddRolePermission = false;

  constructor(private utilityService: UtilityService
    , private componentService: ComponentService, public permissionPipe: PermissionsPipe) { }

  ngOnInit() {
    this.utilityService.SetPageTitle("View Roles");
    this.UpdateSelectedButtonType();
    this.componentService.LoadRoleAddModifyComponentSubscription().subscribe(data => {
      this.isAddRolePermission = this.permissionPipe.transform(Permissions.ROLE_ADD);
      this.LoadComponent(data);
    });
    this.componentService.SetSelectedButtonType("AddNew");
  }

  ngAfterViewInit(): void {
    this.componentService.LoadRoleAddModifyComponent({
      ComponentName: "RoleListing"
    });
  }
  UpdateSelectedButtonType() {
    this.componentService.GetSelectedButtonType().subscribe(type=> this.defaultButtonText = type);
  }
  AddNew() {
    this.componentService.SetSelectedButtonType("Back");
    // this.LoadComponent("AddRole");
    this.componentService.LoadRoleAddModifyComponent({
      ComponentName: "AddRole",
      Role: {Description:null,ID:null,Name:null, IsActive:false}
    });

  }

  LoadComponent(data: IComponentLoadData) {
    if(data == null) return ;
    let componentName: string = data.ComponentName;
    this.addRoleComponent?.clear();
    switch (componentName) {
      case "RoleListing":
        const isViewPermission = this.permissionPipe.transform(Permissions.ROLE_VIEW);
        if (isViewPermission) {
          this.formTitle = "List Of Roles";
          this.addRoleComponent?.createComponent(RoleListingComponent, {
  
          });
        }
        break;
      case "AddRole":
        if (this.isAddRolePermission) {
          this.formTitle = "Add New Role";
          let ref = this.addRoleComponent?.createComponent(AddRoleComponent);
          let component: AddRoleComponent = ref?.instance as AddRoleComponent;
          component.selectedFormType = "Add";
        }
        break;
      case "ModifyRole":
        const isModifyPermission = this.permissionPipe.transform(Permissions.ROLE_EDIT);
        if (isModifyPermission) {
          this.formTitle = "Modify Role";
          let refModify = this.addRoleComponent?.createComponent(AddRoleComponent);
          let componentModify: AddRoleComponent = refModify?.instance as AddRoleComponent;
          componentModify.selectedFormType = "Modify";
          componentModify.role = data.Role as IRoleListing;
        }
        break;
    }
  }
}
