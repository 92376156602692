import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VoixHttpService } from '../core/voix-http/voix-http.service';
import { NotificationService } from '../core/notification/notification.service';
import { generalService } from '../core/services/general.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-response',
  templateUrl: './custom-response.component.html',
  styleUrls: ['./custom-response.component.scss']
})
export class CustomResponseComponent implements OnInit {

  customResponseData: any = [];
  limit = 10;
  tags: any = [];
  displayedColumns = ['id', 'text', 'tags'];
  dataSource: MatTableDataSource<object>;
  customResponse: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  matcher = new MyErrorStateMatcher();
  constructor( private voixHttp: VoixHttpService,
    private notification: NotificationService,
    public dialog: MatDialog, private fb: FormBuilder,
    private generalService: generalService) {
      this.customResponse  = fb.group({
        'text' : [null, Validators.required],
        'tag_id' : [null, Validators.required]
      });
    }

  ngOnInit() {
    this.fetchCustomReponse();
    this.fetchCustomReponseTags(100);
  }
  
  fetchCustomReponse(limit = 10, page = 1) {
    let that = this;
    let params = { limit: limit, page: page, orderby: 'popularity', order: 'DESC'};
    this.generalService.getCustomResponses(params).subscribe( data => {
      if(data['success']) {
        this.customResponseData = data;
        that.dataSource = new MatTableDataSource(data['data']);
        //console.log(data);
      } else {
        this.notification.show('danger','notifications', data['error']);
      }
    }, error => {
      //console.log(typeof error);
        let errorHandle = (typeof error == 'string' ) ? JSON.parse(error) : error;
        //console.log(errorHandle);
        this.notification.show('danger','notifications', errorHandle.error);
      }
    );
  }

  fetchCustomReponseTags(limit = 10, page = 1) {
    let that = this;
    let params = { limit: limit, page: page };
    this.generalService.getCustomResponsesTags(params).subscribe( data => {
      if(data['success']) {
        this.tags = data['data'];
      } else {
        this.notification.show('danger','notifications', data['error']);
      }
    }, error => {
      //console.log(typeof error);
        let errorHandle = (typeof error == 'string' ) ? JSON.parse(error) : error;
        //console.log(errorHandle);
        this.notification.show('danger','notifications', errorHandle.error);
      }
    );
  }

  submitForm(modelValue) {
    this.voixHttp.post('v1/custom-responses', modelValue).subscribe (
      (res:any) => {
        if(res.status) {
          this.notification.show('success','', 'Custom response been added successfully.');
          this.fetchCustomReponse();
          this.customResponse.reset();
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', 'Something went wrong. please try again after some time.');
      }
    );
  }

  edit(row) {
    row.edit = true;
  }
  editTag(row) {
    row.editTag = true;
  }
  onChangeProperty(row, value, propertyName) {
    row[propertyName] = value;
    if(propertyName == 'tag_id') {
      this.update(row);
    }
  }
  update(row) {
    this.voixHttp.put('v1/custom-responses/' + row.id, {text: row.text, tag_id: row.tag_id}).subscribe (
      (res:any) => {
        if(res.status) {
          this.notification.show('success','', 'Custom response has been updated successfully.');
          this.fetchCustomReponse();
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', 'Something went wrong. please try again after some time.');
      }
    );
  }

  delete(row) {
    this.voixHttp.delete('v1/custom-responses/' + row.id).subscribe (
      (res:any) => {
        if(res.status) {
          this.notification.show('success','', 'Custom response has been deleted successfully.');
          this.fetchCustomReponse();
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', 'Something went wrong. please try again after some time.');
      }
    );
  }

  public handlePage(e: any) {
    this.fetchCustomReponse(e.pageSize, (e.pageIndex+1));
  }
}
