declare const require: any;
import { Injectable } from "@angular/core";
import * as _ from 'underscore';
import { VoixHttpService } from "../../core/voix-http/voix-http.service";
import { CommonLibs as constants} from '../../commonLibs';
import * as moment from "moment";
@Injectable({
  providedIn:'root'
})
export class OrderReceiveService {
  constructor(private voixHttp: VoixHttpService) {}

  saveEventScreenShot(restaurantId, imageStrem, conversationId, eventName) {
    // tslint:disable-next-line:max-line-length
    this.voixHttp
      .post("v1/conversation/upload/" + restaurantId, {
        conversation_id: conversationId,
        data_image: imageStrem,
        event_name: eventName,
      })
      .subscribe((res: any) => {
        // console.log(res);
      });
  }

  setLocalstorage(key, object) {
    localStorage.setItem(key, JSON.stringify(object));
  }
  isKeyBelongsToForm(object, verifyObj) {
    var intersectionObj = _.intersection(
      _.allKeys(verifyObj),
      _.allKeys(object)
    );
    return intersectionObj.length > 0;
  }
  lineItemFormObject(fb) {
    // tslint:disable-next-line:max-line-length
    return {
      type: [null],
      name: [null],
      quantity: "1",
      optionalDressing: [null],
      optionalSauce: [null],
      variant: 2,
      specials: null,
    };
  }
  initialLoadEvent(uuid, socket) {
    let buildParam: any = { id: uuid, channel: constants.GET_LAMBDA_EVENT };
    socket.send(buildParam);
  }
  isValueCorrect(arrayOfData, value, qty = false, plunkName = "name") {
    if (qty) {
      return arrayOfData.indexOf(parseFloat(value)) < 0 ? true : false;
    }
    let flag = false;
    let objData = _.pluck(arrayOfData, plunkName);
    objData = _.map(objData, function (element) {
      return !_.isEmpty(element) ? element.toLowerCase() : null;
    });
    if (value && typeof value == "string") {
      if (!_.isEmpty(objData) && objData.indexOf(value.toLowerCase()) < 0) {
        flag = true;
      }
    } else if (value && typeof value == "object") {
      var crapValue = _.difference(value, objData);
      if (crapValue.length > 0) {
        flag = true;
      }
    }
    return flag;
  }
  verifySlots(formData, collection) {
    let orClass = this;
    console.log(formData, collection, Object.keys(formData));
    Object.keys(formData).forEach(function (key, index) {
      if (!_.isEmpty(formData[key])) {
        let keyArray = key.split("Of");
        console.log(keyArray);
        let possibleValues = orClass.getNameOfVariations(
          collection[keyArray[0].toLowerCase()]
        );
        if (
          !_.isEmpty(possibleValues) &&
          possibleValues.indexOf(formData[key]) < 0 &&
          !collection[keyArray[0].toLowerCase()].allow_multiple
        ) {
          formData[key] = null;
        } else if (
          !_.isEmpty(possibleValues) &&
          collection[keyArray[0].toLowerCase()].allow_multiple
        ) {
          var varifiedData = [];
          formData[key].forEach(function (element) {
            if (possibleValues.indexOf(element) >= 0) {
              varifiedData.push(element);
            }
          });
          formData[key] = varifiedData;
        } else if (keyArray[0].toLowerCase() === "time") {
          formData[key] = moment(formData[key], "h:mm A").format("H:mm");
        } else if (keyArray[0].toLowerCase() === "date") {
          let todayTime = moment();
          if (formData[key].toLowerCase() === "today") {
            formData[key] = moment().format("DD-MMM-YYYY");
          } else if (formData[key].toLowerCase() === "tomorrow") {
            formData[key] = moment().add(1, "days").format("DD-MMM-YYYY");
          } else {
            formData[key] = moment(formData[key], "ll").format("DD-MMM-YYYY");
          }
        }
      }
    });
    return formData;
  }
  getNameOfVariations(collectionObj) {
    let objData = [];
    if (collectionObj) {
      objData = _.pluck(collectionObj.data, "name");
      objData = _.map(objData, function (element) {
        return element.toLowerCase();
      });
    }
    return objData;
  }
  getIntents() {
    return [
      {
        key: "PlaceOrder",
        value: "Place Order",
      },
      {
        key: "EditOrder",
        value: "Edit Order",
      },
      {
        key: "MenuSearch",
        value: "Inquiry for Menu",
      },
      {
        key: "ItemInquiryIntent",
        value: "Inquiry for Menu Item",
      },
      {
        key: "FoodCategoryInquiry",
        value: "Inquiry for Food Category",
      },
      {
        key: "NegativeIntent",
        value: "Negative Intent",
      },
      {
        key: "Greetings",
        value: "Greetings",
      },
      {
        key: "InquiryWorkHours",
        value: "Inquiry for Work Hours",
      },
    ];
  }
  saveConversationParams(id, isSpecialInstruction) {
    const that = this;
    const getParam =
      '{ conversationParam(conversation_id: "' +
      id +
      '") { conversation_values } }';
    this.voixHttp
      .get("v1/graphql-gateway/", { params: { query_object: getParam } })
      .subscribe((res: any) => {
        // console.log(res.data.conversationParam);
        const mutationType = res.data.conversationParam
          ? "conversationParamUpdate"
          : "conversationParamCreate";
        // console.log(mutationType);
        const param =
          "mutation { " +
          mutationType +
          '( conversation_id: "' +
          id +
          '", conversation_values:"{\\"is_special_instruction\\": ' +
          isSpecialInstruction +
          '}") { conversation_id} }';
        that.postConversationParams(param);
      });
  }

  postConversationParams(param) {
    this.voixHttp
      .post("v1/graphql-gateway/", { query_object: param }, {})
      .subscribe((res: any) => {
        // console.log(res);
      });
  }

  getEntities(menu) {
    const entityCollectionOne = [];
    const category = [];
    const variation = [];
    const entityCollectionTwo = [];
    const allowMultiple = { one: [], two: [] };
    const categoryEntities = { one: {}, two: {} };
    const availableVariation = [];
    _.each(menu, function (value, index) {
      entityCollectionOne.push(value.entities[0]);
      category.push(value.MenuCategory.name);
      entityCollectionOne.push(value.MenuCategory.entities[0]);
      entityCollectionTwo.push(value.entities[1]);
      entityCollectionTwo.push(value.MenuCategory.entities[1]);

      categoryEntities["one"][value.MenuCategory.name] = value.entities[0];
      categoryEntities["two"][value.MenuCategory.name] = value.entities[1];

      _.each(value.MenuVariationCollections, function (val, key) {
        entityCollectionOne.push(val.MenuVariation.entities[0]);
        entityCollectionTwo.push(val.MenuVariation.entities[1]);
        variation.push(val.MenuVariation.name);
        if (
          !_.findWhere(availableVariation, { name: val.MenuVariation.name })
        ) {
          availableVariation.push({
            name: val.MenuVariation.name,
            allow_multiple: val.MenuVariation.allow_multiple,
          });
        }
        if (
          allowMultiple.one.indexOf(val.MenuVariation.entities[0]) < 0 &&
          val.MenuVariation.allow_multiple
        ) {
          allowMultiple.one.push(val.MenuVariation.entities[0]);
        }
        if (
          allowMultiple.two.indexOf(val.MenuVariation.entities[1]) &&
          val.MenuVariation.allow_multiple
        ) {
          allowMultiple.two.push(val.MenuVariation.entities[1]);
        }
      });
    });
    const entitiesObj = {
      one: _.without(_.uniq(entityCollectionOne), "FoodTypeOne"),
      two: _.without(_.uniq(entityCollectionTwo), "FoodTypeTwo"),
      categoryEntities: categoryEntities,
      allow_multiple: allowMultiple,
      available_variation: _.uniq(availableVariation),
      category: _.uniq(category),
      variation: _.uniq(variation),
    };
    return entitiesObj;
  }

  getHours(variationObject) {
    const hours = [];
    let getMomentHours = moment().format("h");
    for (let hour = parseInt(getMomentHours); hour < 22; hour++) {
      var objFirst = Object.assign({}, variationObject);
      objFirst.name = moment({ hour }).format("h:mm A");
      objFirst.priority = hour;
      hours.push(objFirst);
      var secondObj = Object.assign({}, variationObject);
      secondObj.priority = hour;
      secondObj.name = moment({ hour, minute: 30 }).format("h:mm A");
      hours.push(secondObj);
    }
    return hours;
  }

  getDates(variationObject) {
    const days = [];
    let todayObject = Object.assign({}, variationObject);
    todayObject.name = "Today";
    let tommarowObj = Object.assign({}, variationObject);
    tommarowObj.name = "Tomorrow";
    days.push(todayObject);
    days.push(tommarowObj);
    let todayDates = moment();
    for (let day = 2; day < 7; day++) {
      // tslint:disable-next-line:no-var-keyword
      var objFirst = Object.assign({}, variationObject);
      objFirst.name = moment().add(day, "day").format("ll");
      objFirst.priority = day;
      days.push(objFirst);
    }
    return days;
  }

  getPatchTime(value) {
    if (_.isEmpty(value)) {
      return null;
    } else {
      return moment(value, "H:mm").format("h:mm A");
    }
  }

  getPatchDate(value) {
    if (_.isEmpty(value)) {
      return null;
    } else {
      if (this.isToday(moment(value))) {
        return "Today";
      } else if (this.isTommarow(moment(value))) {
        return "Tommarow";
      } else {
        return moment(value).format("ll");
      }
    }
  }

  isToday(momentDate) {
    let todayDate = moment().format("DD-MMM-YYYY");
    return momentDate.format("DD-MMM-YYYY") == todayDate;
  }

  isTommarow(momentDate) {
    let tommarow = moment().add(1, "days").format("DD-MMM-YYYY");
    return momentDate.format("DD-MMM-YYYY") == tommarow;
  }
}
