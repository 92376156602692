<div class="card">
  <div class="card-header bg-primary text-white">Transcript</div>
  <div
    class="card-body"
    style="min-height: 80vh; max-height: 80vh; overflow-y: scroll"
  >
    <ng-container *ngFor="let conversation of conversationSoFar">
      <div class="row p-2 justify-content-start">
        <div style="float: left"><b>AI</b></div>
        <div class="message received">
          <div class="message-content">{{ conversation.agent }}</div>
        </div>
      </div>
      <div class="row p-2 justify-content-end" *ngIf="conversation.customer">
        <div style="float: right; text-align: right"><b>Customer</b></div>
        <div class="message sent">
          <div class="message-content">{{ conversation.customer }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
