import { Injectable } from "@angular/core";
import { IAgentComponentLoadData } from "app/agent/model/agent-component-load-data";
import { IClientListing } from "app/order-receive/clients/model/client-listing.model";
import { IComponentLoadData } from "app/roles/model/component-load-data";
import { BehaviorSubject } from "rxjs";
import { Client } from "../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";
import { IStoreLoadComponentDetail } from "app/store-management/model/IStore";
import { INotificationLoadComponentDetail } from "app/notification-management/model/INotification";

@Injectable({
  providedIn: "root",
})
export class ComponentService {
  selectedClient: Client = null;
  private dashboardWidgetData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  private buttonType: BehaviorSubject<string> = new BehaviorSubject<string>(
    "Add"
  );
  private selectedClientForStore: BehaviorSubject<IClientListing> =
    new BehaviorSubject<IClientListing>(null);
  private storesAddModifyComponent: BehaviorSubject<any> =
    new BehaviorSubject<string>(null);
  private notificationsAddModifyComponent: BehaviorSubject<any> =
    new BehaviorSubject<string>(null);
  //Roles Components
  private roleAddModifyComponent: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  //End Roles Components

  //Agent Components
  private agentAddModifyComponent: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  //End Agent Components
  constructor() {}

  UpdateDashboardWidgets(orderData: any) {
    this.dashboardWidgetData.next(orderData);
  }
  DashboardWidgetsSubscription() {
    return this.dashboardWidgetData.asObservable();
  }
  GetSelectedButtonType() {
    return this.buttonType.asObservable();
  }
  SetSelectedButtonType(type: string) {
    this.buttonType.next(type);
  }

  setSelectedClientForStore(data) {
    this.selectedClientForStore.next(data);
  }

  getSelectedClientForStore() {
    return this.selectedClientForStore.asObservable();
  }

  LoadRoleAddModifyComponent(data: IComponentLoadData) {
    this.roleAddModifyComponent.next(data);
  }
  LoadRoleAddModifyComponentSubscription() {
    return this.roleAddModifyComponent.asObservable();
  }
  LoadAgentAddModifyComponent(data: IAgentComponentLoadData) {
    this.agentAddModifyComponent.next(data);
  }
  LoadAgentAddModifyComponentSubscription() {
    return this.agentAddModifyComponent.asObservable();
  }

  LoadStoresAddModifyComponent(data: IStoreLoadComponentDetail) {
    return this.storesAddModifyComponent.next(data);
  }

  LoadStoreAddComponentSubscription() {
    return this.storesAddModifyComponent.asObservable();
  }

  LoadNotificationsAddModifyComponent(data: INotificationLoadComponentDetail) {
    return this.notificationsAddModifyComponent.next(data);
  }

  LoadNotificationAddComponentSubscription() {
    return this.notificationsAddModifyComponent.asObservable();
  }
}
