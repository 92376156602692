export const API_URLS= {
    Permissions:{
        Get:'v1/permssions/get'        
    },
    Reset: {
        Patch: 'v1/agent/reset-password'
    },
    Role:{
        AddRole:'v1/role/add',
        Get:'v1/role/get',
        GetRolesForAgent:'v1/role/for-agent',
        GetByID:'v1/role/get',
        GetRolePermissions:function(roleid:number){
            return `v1/role/${roleid}/permissions`;
        },
        UpdateRole:'v1/role/update',
        DeleteRole:function(roleid:number){
            return `v1/role/delete/${roleid}`
        }
    },
    Client: {
        AddClient:'v1/agent-client',
        Get: 'v1/clients',
    },
    Agent:{
        Get:'v1/users/list',
        Add:'v1/users/adduserwithroles',
        AddBulkAgent:'v1/users/bulkAgentCreateWithRoles',
        Delete:function(ids){return `v1/users/deleteuserwithroles/${ids}`;},
        GetUserRoles : function(user_id) {return `v1/users/roles/get/${user_id}`},
        Update:'v1/users/updateuserwithrights'
    },
    Keywords: {
        downloadKeywords: (clientCode: string) => `downloadkeywordsfile/${clientCode}`,
        uploadKeywords: (clientCode: string) => `uploadkeywordsfile/${clientCode}`,
        getKeywords: (clientCode: string) => `getKeywords/${clientCode}`,
        saveKeywords: (clientCode: string) => `saveKeywords/${clientCode}`,
        downloadTestingKeywords: (clientCode: string) => `downloadkeywordsfile/${clientCode}`,
    },
    Faqs: {
        getAll: "v1/faqs",
        getOne: "v1/faq/",
        Add: "v1/faq",
        Update: "v1/faq/",
        Delete: "v1/faq/"
    }
}