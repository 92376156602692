import { Pipe, PipeTransform } from "@angular/core";
import * as _ from 'underscore';
@Pipe({ name: "sortByName" })
export class SortByNamePipe implements PipeTransform {
  transform(obj: any, value: any) {
    console.log(value);
    if (value) {
      var selectedObj = [];
      var nonselectedObj = [];
      _.map(obj, function (element, key) {
        let condition = false;
        if (value.formValue && typeof value.formValue == "string") {
          condition = value["formValue"] == element.name.toLowerCase();
        }
        if (value.formValue && typeof value.formValue == "object") {
          condition = !_.isEmpty(element)
            ? value.formValue.indexOf(element.name.toLowerCase()) >= 0
            : false;
        }
        if (condition) {
          selectedObj.push(element);
        } else {
          nonselectedObj.push(element);
        }
      });
      selectedObj = _.sortBy(selectedObj, function (i) {
        return i.name.toLowerCase();
      });
      selectedObj = _.sortBy(selectedObj, function (i) {
        return i.priority;
      });
      nonselectedObj = _.sortBy(nonselectedObj, function (i) {
        return !_.isEmpty(i) ? i.name.toLowerCase() : i;
      });
      nonselectedObj = _.sortBy(nonselectedObj, function (i) {
        return !_.isEmpty(i) ? i.priority : i;
      });
      return selectedObj.concat(nonselectedObj);
    }
    var obj : any = _.sortBy(obj, function (i) {
      return i.name.toLowerCase();
    });
    return _.sortBy(obj, function (i) {
      return i.priority;
    });
  }
}
