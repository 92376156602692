import { BrowserModule } from "@angular/platform-browser";
import {
  CommonModule,
  PathLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "./core/modules/material.module";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppComponent } from "./app.component";

// import { FileUploadModule } from "ng2-file-upload";

import "hammerjs";
import { AuthenticationService } from "./core/auth/authentication.service";
import { AuthGuard } from "./core/auth/auth.guard";
import { RoleGuard } from "./core/auth/role.guard";

import { VoixHttpService } from "./core/voix-http/voix-http.service";
import { NotificationService } from "./core/notification/notification.service";
import { SharedModule } from "./core/shared/shared.module";
import { AdminComponent } from "./layout/admin/admin.component";
import { AuthComponent } from "./layout/auth/auth.component";

import { LoginComponent } from "./account/login/login.component";
import { RegisterComponent } from "./account/register/register.component";
import { ForgetpasswordComponent } from "./account/forgetpassword/forgetpassword.component";
import { ResetpasswordComponent } from "./account/resetpassword/resetpassword.component";
import { ActivateComponent } from "./account/activate/activate.component";
import { ChangePasswordComponent } from "./account/change-password/change-password.component";

import { OrderReceiveComponent } from "./order-receive/order-receive.component";
import { OrderDialogComponent } from "./order-receive/order-dialog/order-dialog.component";
import { AudioBrowserComponent } from "./audio-browser/audio-browser.component";
import { OrderInProgressComponent } from "./order-receive/order-in-progress/order-in-progress.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { MessagingService } from "./core/notification/messaging.service";
import { AgentResponseService } from "./core/customer/agent-response.service";
import { OrderReceiveService } from "./core/customer/order-receive.service";
import { getRestaurantService } from "./core/auth/restaurant.service";
import { NavbarService } from "./core/navbar/navbar.service";
import { menuService } from "./core/services/menu.service";
import { generalService } from "./core/services/general.service";
import { CustomResponseComponent } from "./custom-response/custom-response.component";
import { environment } from "../environments/environment";
import { OrderReceiveModule } from "./order-receive/order-receive.module";
import { SmsMessengerModule } from "./sms-messenger/sms-messenger.module";
import { PaymentsComponent } from "./payments/payments.component";
import { PaymentSuccessNotifyComponent } from "./payments/success-notify/success-notify.component";
import { ConversationDialogComponent } from "./orders/conversation-dialog/conversation-dialog.component";
import { LineItemDialogComponent } from "./orders/line-item-dialog/line-item-dialog.component";
import { RestaurantSettingsComponent } from "./restaurant-settings/restaurant-settings.component";
import { AddDialogComponent } from "./restaurant-settings/add/add.component";
import { IntergrationService } from "./core/integration/integration.service";
import { LogMessageService } from "./core/services/logger.service";
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
export const firebaseConfig = environment.firebaseConfig;
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { menuState } from "./core/ngxs/state/menu.state";
import { CustomerComponent } from "./order-receive/customer/customer.component";
// import { NgxCustomGooglePlacesAutoCompleteComponentModule } from 'ngx-custom-google-places-autocomplete';
import { customerState } from "./core/ngxs/state/customer.state";
import { cartState } from "./core/ngxs/state/cart.state";
import { storeState } from "./core/ngxs/state/store.state";
import { promoState } from "./core/ngxs/state/promo.state";
import { orderState } from "./core/ngxs/state/order.state";
import { SubmitOrderComponent } from "./order-receive/submit-order/submit-order.component";
import { dealsState } from "./core/ngxs/state/deals.state";
// import { NgxTimerModule } from 'ngx-timer';
import { frequentOrderState } from "./core/ngxs/state/frequentOrder.state";
import { pastOrderState } from "./core/ngxs/state/pastOrder.state";
import { feedbackState } from "./core/ngxs/state/feedback.state";
import { menuSelectionState } from "./core/ngxs/state/menuSelection.state";
import { CryptoService } from "./core/services/crypto.service";
import { CartResponseComponent } from "./cart-response/cart-response.component";
import { paymentState } from "./core/ngxs/state/payment.state";
import { ModifyCouponComponent } from "./modify-coupon/modify-coupon.component";
import { ViewCouponsComponent } from "./modify-coupon/view-coupons/view-coupons.component";
import { savePromoState } from "./core/ngxs/state/promo_override.state";
import { FilterPromo } from "./core/pipes/filter-promo.pipe";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Interceptor } from "./core/auth/http-interceptor";
import { TransactionModule } from "./transaction/transaction.module";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { RolesComponent } from "./roles/roles.component";
import { RolesModule } from "./roles/roles.module";
import { UploadedCsvListComponent } from "./modify-coupon/uploaded-csv-list/uploaded-csv-list.component";
import { NgxCsvParserModule } from "ngx-csv-parser";
import { BulkPromoReportModal } from "./modify-coupon/bulk-report-modal/bulk-promo-report-modal.component";
import { NgxsSelectSnapshotModule } from "@ngxs-labs/select-snapshot";
import { NotFoundPageComponent } from "./components/not-found-page/not-found-page.component";
import { DealsComponent } from "./deals/deals.component";
import { ViewDealComponent } from "./deals/view-deal/view-deal.component";
import { GooglePlaceModule } from "./core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module";
import { GooglePlaceDirective } from "./core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UploadedCsvListModule } from "./restaurant-settings/uploaded-csv-list/uploaded-csv-list.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ChatDrawerComponent } from "./components/chat-drawer/chat-drawer.component";

import { StoreManagementModule } from "./store-management/store-management.module";
import { ToastrModule } from "ngx-toastr";
import { AiOrderRecieveComponent } from "./ai-order-recieve/component/ai-order-recieve/ai-order-recieve.component";
import { ChatBoxComponent } from "./ai-order-recieve/component/chat-box/chat-box.component";
import { CurrentOrderComponent } from "./ai-order-recieve/component/current-order/current-order.component";
import { MenuSelectorComponent } from "./ai-order-recieve/component/menu-selector/menu-selector.component";
import { TruncatePipe } from "./ai-order-recieve/pipes/truncate.pipe";
import { UpdateCartComponent } from "./ai-order-recieve/component/update-cart/update-cart.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import {VoixPipesModule} from "./core/pipes/pipes.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AuthComponent,
    AdminComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    ActivateComponent,
    OrderReceiveComponent,
    DashboardComponent,
    OrderDialogComponent,
    ConversationDialogComponent,
    AudioBrowserComponent,
    LineItemDialogComponent,
    CustomResponseComponent,
    RestaurantSettingsComponent,
    AddDialogComponent,
    ChangePasswordComponent,
    PaymentsComponent,
    PaymentSuccessNotifyComponent,
    CartResponseComponent,
    ModifyCouponComponent,
    ViewCouponsComponent,
    UploadedCsvListComponent,
    BulkPromoReportModal,
    FilterPromo,
    NotFoundPageComponent,
    DealsComponent,
    ViewDealComponent,
    UploadedCsvListComponent,
    ChatDrawerComponent,
    AiOrderRecieveComponent,
    ChatBoxComponent,
    CurrentOrderComponent,
    MenuSelectorComponent,
    TruncatePipe,
    UpdateCartComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTooltipModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    VoixPipesModule,
    // NgxCustomGooglePlacesAutoCompleteComponentModule,
    NgxsModule.forRoot([
      menuState,
      menuSelectionState,
      customerState,
      cartState,
      storeState,
      promoState,
      orderState,
      dealsState,
      frequentOrderState,
      pastOrderState,
      feedbackState,
      paymentState,
      savePromoState,
    ]),

    NgxCsvParserModule,
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      maxAge: 10,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MaterialModule,
    // MaterialExtensionsModule,
    BrowserAnimationsModule,

    SharedModule,
    OrderReceiveModule,
    SmsMessengerModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TransactionModule,
    NgxUiLoaderModule,
    RolesModule,
    StoreManagementModule,
    UploadedCsvListModule,
    ToastrModule.forRoot({
      timeOut: 60000,
      positionClass: "toast-top-right",
    }),
  ],
  entryComponents: [
    OrderDialogComponent,
    OrderInProgressComponent,
    ConversationDialogComponent,
    LineItemDialogComponent,
    AddDialogComponent,
    CustomerComponent,
    SubmitOrderComponent,
    CartResponseComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    LogMessageService,
    AuthenticationService,
    AuthGuard,
    RoleGuard,
    VoixHttpService,
    NotificationService,
    MessagingService,
    AgentResponseService,
    IntergrationService,
    OrderReceiveService,
    NavbarService,
    getRestaurantService,
    generalService,
    CryptoService,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    menuService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
