import { Injectable } from "@angular/core";
// import {
//   ConnectionBackend,
//   XHRBackend,
//   RequestOptions,
//   Request,
//   RequestOptionsArgs,
//   Response,
//   Http,
//   Headers,
// } from "@angular/http";
//import { Observable } from "rxjs/Observable";
import { map, catchError, tap } from "rxjs/operators";
// import "rxjs/add/operator/map";
// import "rxjs/add/observable/throw";
import { RequestOptions } from "http";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
// import { Observable } from "rxjs/Rx";
// import getMenuV2 from "../../../../ezra-commlibs/libs/business/apiManagers/menuManager.js";
// const menuManager = require("../../../../ezra-commlibs/libs/business/apiManagers/menuManager.js");
// const getToken = require("../../../../ezra-commlibs/libs/business/apiManagers/clientManager.js");
@Injectable({
  providedIn:'root'
})
export class IntergrationService {
  token: any;
  clientCode: string;
  integrationToken = localStorage.getItem("client");
  defaultOptions: RequestOptions;
  constructor( private http: HttpClient) {}

  // getTokenAuth(clientCode: string) {
  //   this.token = getToken.getAuthentication(clientCode);
  //   console.log(this.token);
  //   return this.token;
  //   // return new Promise(function (resolve, reject) {
  //   //   setTimeout(() => {
  //   //     resolve();
  //   //   }, 20);
  //   // });
  // }
  getTokenAuth(website_url: any, clientCode: any) {
    return this.http.get(website_url + "/authentication").pipe(
      map((response: Response) => response.json()),
      catchError(this.handleErrorV2)
    );
  }

  get(
    website_url: string,
    url: string,
    ClientCode: string,
    options?: any
  ): Observable<any> {
    // console.log(data);
    this.clientCode = ClientCode;
    if (!this.integrationToken) {
      this.getTokenAuth(website_url, ClientCode).subscribe((res:any) => {
        localStorage.setItem("client", res.data);
      });
    }
    return this.http
      .get(website_url + url, this.addJwtV2Integration(options, ""))
      .pipe(
        map((response: any) => response.json()),
        catchError(this.handleErrorV2)
      );
  }

  post(
    website_url: string,
    url: string,
    ClientCode: string,
    body: any,
    options?: any
  ): Observable<any> {
    // var api_endpoint = backend ? environment.io_url : environment.api_endpoint;
    return this.http
      .post(
        website_url + url,
        JSON.stringify(body),
        this.addJwt(options, "post")
      )
      .pipe(catchError(this.handleError));
  }

  // put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
  //     return this.http.put(environment.api_endpoint+ url, body, this.addJwt(options)).pipe(catchError(this.handleError));
  // }

  // patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
  //   return this.http.patch(environment.api_endpoint + url, body, this.addJwt(options)).pipe(catchError(this.handleError));
  // }

  // delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
  //     return this.http.delete(environment.api_endpoint+ url, this.addJwt(options)).pipe(catchError(this.handleError));
  // }

  // private helper methods
  private addJwtV2Integration(options?: any, method?: string): any {
    // ensure request options and headers are not null
    options = options || {};
    options.headers = options.headers || new Headers();
    options.headers.append("Content-Type", "application/json");
    if (method == "get") {
      options.headers.append(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
    }

    if (this.integrationToken) {
      options.headers.set("Authorization", "Bearer " + this.integrationToken);
    }
    options.headers.set("CLIENTCODE", this.clientCode);
    // add authorization header with jwt token
    // let agentCurrentUser = JSON.parse(localStorage.getItem("agentCurrentUser"));

    return options;
  }

  private handleErrorV2(error: any) {
    //console.log(error, error._body, error.status);
    if (error.status === 401) {
      // 401 unauthorized response so log user out of client
      window.location.href = "/welcome";
    }
    return throwError(error._body);
  }

  private addJwt(
    options?: any,
    method?: string
  ): any {
    // ensure request options and headers are not null
    options = options || {};
    options.headers = options.headers || new Headers();
    options.headers.append("Content-Type", "application/json");
    if (method == "get") {
      options.headers.append(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
    }

    // add authorization header with jwt token
    let agentCurrentUser = JSON.parse(localStorage.getItem("agentCurrentUser"));
    if (agentCurrentUser && agentCurrentUser.token) {
      options.headers.set("Authorization", agentCurrentUser.token);
    }
    return options;
  }

  private handleError(error: any) {
    //console.log(error, error._body, error.status);
    if (error.status === 401) {
      // 401 unauthorized response so log user out of client
      window.location.href = "/welcome";
    }
    return throwError(error._body);
  }
}
