import { GlobalApiResponse } from "app/core/models/apiResponse.interface";

export class ActionGetDeals {
  static readonly type = "[DEALS] GET DEALS";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionGetSpecialDeals {
  static readonly type = "[DEALS] GET SPECIAL DEALS";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionSaveSelectDeal {
  static readonly type = "[DEAL] SAVE SELECT DEAL";
  constructor(public payload: any) {}
}

export class setDealNull {
  static readonly type = "[DEAL] DEAL NULL";
  constructor() {}
}

export class ActionSaveDeal {
  static readonly type = "[DEAL] SAVE DEAL";
  constructor(public payload: any) {}
}

export class getAllDeals {
  static readonly type = "[DEAL] GET DEAL FOR MANAGEMENT";
  constructor(public payload: any) {}
}

export class setDealOverride {
  static readonly type = "[DEAL] SAVE DEAL FOR OVERRIDE";
  constructor() {}
}
