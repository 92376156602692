import { Injectable } from "@angular/core";
import * as _ from "underscore";
import { VoixHttpService } from "../../core/voix-http/voix-http.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs/internal/Observable";
import { NotificationService } from "../notification/notification.service";
import { FeatureFlagService } from "./feature-flag.service";
import {
  CommonLibs as constants,
} from "../../commonLibs";
import { LogMessageService } from "./logger.service";
import { AppConstants } from "app/app.constants";
import { Select } from "@ngxs/store";
import { cartDefaultState, cartState } from "../ngxs/state/cart.state";
import { BehaviorSubject, first, of } from "rxjs";

interface FlagSmithTraits {
  CustomerPhoneNumber: string;
  IPAddress: string;
  UserRole: string;
  UserEmail: string;
  CorrelationId: string;
  StoreId: string;
  ClientCode: string;
  StorePhoneNumber: string;
}

@Injectable({
  providedIn: "root",
})
export class generalService {
  public id: string = "5HjYLTk1AMKswQ3r3tTU";
  public isMuted = false;
  public activecallkey = '';
  public isApplicableForSpecial: boolean = false;
  public addressTypeLocation: boolean = false;
  public isPaymentAttempsAllowed: boolean = false;
  public isIVRenabled: boolean = false;
  public isMutedFeatureRenabled: boolean = false;
  public isSpecialsAutoApplied: boolean = false;
  public isToppingsCountSeparated: boolean = false;
  public isAddressOutOfRange: boolean = false;
  public isLoyaltyIvrFlowEnabled: boolean = false;
  public isUpSellingEnabled: boolean = false;
  public isStoreNotificationEnabled: boolean = false;
  public creditCardAttempts: number = 2;
  public giftCardAttempts: number = 2;
  public currentCreditCardAttempts = 0;
  public currentGiftCardAttempts = 0;
  public cartID = '';
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  public paymentAttemps$ = new BehaviorSubject<any>(null);

  constructor(private voixHttp: VoixHttpService, private http: HttpClient, private notification: NotificationService, private featureFlagService: FeatureFlagService, public logger : LogMessageService) {}
  getCustomResponses(params) {
    return this.voixHttp.get("v1/custom-responses", { params: params });
  }
  getCustomResponsesTags(params) {
    return this.voixHttp.get("v1/custom-responses-tags", { params: params });
  }
  serialize(obj) {
    let str = Object.keys(obj)
      .reduce(function (a, k) {
        a.push(k + "=" + encodeURIComponent(obj[k]));
        return a;
      }, [])
      .join("&");
    return str;
  }

  getCartId(): string {
    let cartId = '';
    this.cartState.pipe(first()).subscribe(cart => {
      console.log(cart);
      cartId = cart?.id || '';
    });
    return cartId || this.cartID;
  }

  removeCartId() {
    localStorage.removeItem(this.id);
  }

  checkInternalPaymentAccept(storeInfo): boolean {
    const isPaymentSupportInternal: boolean =
      storeInfo.store.paymentMethods.some(
        (method: any) => method.toLowerCase() === "internal"
      );

    return isPaymentSupportInternal;
  }

  togglePauseResumeRecording(activecallkey): Observable<{recording_status: boolean; success: boolean}> {
    const params = {
      function: 'pause_recording',
      activecallkey,
      app_token: environment.app_token
    }
    const { call_center_studio_url } = JSON.parse(this.featureFlagService.getFeatureValue(AppConstants.FLAG_SMITH.IS_MUTE_FEATURE_ENABLE) || null) || {};
    return this.http.get(call_center_studio_url, {params}) as Observable<{recording_status: boolean; success: boolean}>;
  }

  pauseResumeRecording(isPause: boolean) {
    const successMsg = isPause ? "Recording IS PAUSED" : "Recording IS RESUMED";
    const errorMsg = isPause ? "Facing issue while PAUSING the recording, please PAUSE it manually" : "Facing issue while RESUMING the recording, please RESUME it manually";
    this.togglePauseResumeRecording(this.activecallkey)
    .subscribe({
      next: (response: any) => {
        const responseLog = JSON.stringify(response);
        const valid = isPause ? response?.recording_status : !response?.recording_status;
        if (valid) { // if Recording Mute/Resume works first time
          this.notification.openSnackBarActionV2(
            "end",
            "top",
            successMsg,
            isPause ? "success-snackbar" : "blue-snackbar"
          );
          this.isMuted = response?.recording_status;
          // 1st attempt
          this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "First Attempt Success","status": successMsg, "ccs_response": responseLog})});
        } else { // that means, first time mute/resume doesn't work so we can call second time to mute it
          this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "First Attempt Failed","status": errorMsg, "ccs_response": responseLog})});
          this.pauseResumeRecall(isPause, successMsg, errorMsg);
        }
      },
      error: (error) => {
        const errorResponse = JSON.stringify(error);
        this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "First Attempt Failed","status": errorMsg, "ccs_response": errorResponse})});
        this.pauseResumeRecall(isPause, successMsg, errorMsg);
      }
    });
  }

  pauseResumeRecall(isPause, successMsg, errorMsg) {
    this.togglePauseResumeRecording(this.activecallkey)
    .subscribe({
      next: (response: any) => {
        const responseLog = JSON.stringify(response);
        const valid = isPause ? response?.recording_status : !response?.recording_status;
        if (valid) {
          this.notification.openSnackBarActionV2(
            "end",
            "top",
            successMsg,
            isPause ? "success-snackbar" : "blue-snackbar"
          );
          this.isMuted = response?.recording_status;
          // 2nd attempt
          this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "Second Attempt Success","status": successMsg, "ccs_response": responseLog})});
        } else {
          this.notification.openSnackBarActionV2(
            "end",
            "top",
            errorMsg,
            "danger-snackbar"
          );
          // Both attempts failed
          this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "Second Attempt Failed","status": errorMsg, "ccs_response": responseLog})});
        }
      },
      error: (error) => {
        const errorResponse = JSON.stringify(error);
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          errorMsg,
          "danger-snackbar"
        );
        this.logger.emitLogMessage({isPause, logMessage: JSON.stringify({"attempt": "Second Attempt Failed","status": errorMsg, "ccs_response": errorResponse})});
      }
    })
  }

  setFlagsmithTraits(traits: FlagSmithTraits): Promise<any> {
    this.featureFlagService.flagsmith.identify("ezra_users");
    return this.featureFlagService.flagsmith.setTraits({
      ...traits
    }).then(() => {
      this.isSpecialsAutoApplied = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_SPECIALS_AUTO_APPLIED_ENABLED);
      this.isApplicableForSpecial = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_SPECIALS_APPLICABLE_ENABLED);
      this.addressTypeLocation = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.ADDRESS_TYPE_LOCATION);
      this.isPaymentAttempsAllowed = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.CREDIT_CARD_PAYMENT_ATTEMPTS);
      this.isToppingsCountSeparated = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_TOPPINGS_COUNT_SEPARATED);
      this.isIVRenabled = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_IVR_FEATURE_ENABLED);
      this.isMutedFeatureRenabled = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_MUTE_FEATURE_ENABLE);
      this.isAddressOutOfRange = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_ADDRESS_OUT_OF_RANGE_ENABLED);
      this.isLoyaltyIvrFlowEnabled = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_LOYALTY_IVR_FLOW_ENABLED);
      this.isUpSellingEnabled = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_UP_SELLING_FEATURE_ENABLED);
      this.isStoreNotificationEnabled = this.featureFlagService.isFeatureOn(AppConstants.FLAG_SMITH.IS_STORE_NOTIFICATION_ENABLED);
      if (this.isPaymentAttempsAllowed) {
        const { total_attempts_card, total_attempts_gift } = JSON.parse(this.featureFlagService.getFeatureValue(AppConstants.FLAG_SMITH.CREDIT_CARD_PAYMENT_ATTEMPTS) || null) || { total_attempts_card: 2, total_attempts_gift: 2 };
        this.creditCardAttempts = +total_attempts_card;
        this.giftCardAttempts = +total_attempts_gift;
      }
      return Promise.resolve();
    }).catch(() => {
      return Promise.reject();
    });
  }

  setPaymentAttemps({
    credit_card_attempts,
    gift_card_attempts
  }) {
    if (this.isPaymentAttempsAllowed) {
      this.currentCreditCardAttempts = Number(credit_card_attempts ?? 0);
      this.currentGiftCardAttempts = Number(gift_card_attempts ?? 0);
      this.paymentAttemps$.next({
        credit_card_attempts,
        gift_card_attempts
      })
    }
  }

}
