import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetDeals } from "../action/deals.action";
import { ActionGetFrequentOrderItem } from "../action/frequentOrder.action";

export const frequentItemDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface frequentItemDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<frequentItemDefaultState>({
  name: "frequentItem",
  defaults: frequentItemDefaultState,
})
@Injectable()
export class frequentOrderState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getFrequentItems(state: frequentItemDefaultState) {
    return state.data;
  }

  @Action(ActionGetFrequentOrderItem)
  createCart(
    { setState, getState, patchState }: StateContext<frequentItemDefaultState>,
    { payload }
  ) {
    const frequentItem = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const ItemState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };
      patchState(ItemState);
      //   this.orderReceiveComp.getStoreInfoV2();
    }
  }
}
