<div class="main-content no-scroll">
    <div class="row container-fluid">

        <div class="row">
            <div class="col-md-12">

                <div class="chatUi">
                    <div *ngFor="let item of responseData; index as i;">
                        <div class="message-wrapper left" *ngIf="item.input_transcript">
                            <div class="circle-wrapper animated bounceIn"><i class="material-icons">call</i></div>
                            <div class="text-wrapper animated fadeIn">
                                {{item.input_transcript}}
                            </div>
                        </div>

                        <div class="message-wrapper right" *ngIf="item.voix_response">
                            <div class="circle-wrapper animated bounceIn"><img src="/assets/img/voixai.png"></div>
                            <div class="text-wrapper animated fadeIn">
                                {{item.voix_response}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="responseData.length <= 0"> No Conversation data found </div>
                </div>

            </div>
        </div>
    
    </div>
</div>
