import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { CartMenuItem } from "../../../../../mediator-commlibs/interfaces/request/cartItem.interface";
import { BehaviorSubject } from "rxjs";
import { aiParams } from "./ai-menu.service";
@Injectable({
  providedIn: "root",
})
export class aiCartService {
  cartRefreshEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cartUpdateEvent: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private storeData = new BehaviorSubject("");
  currentStoreData = this.storeData.asObservable();
  constructor(private http: HttpClient) {}
  addItemToCart(
    correlationid: string,
    clientCode: string,
    identity: string,
    storeId: string,
    cartId: string,
    cartmenuItems: CartMenuItem[]
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    return this.http.post(
      `${environment.agent_backend_api_url}api/internal/cart/${cartId}/${storeId}`,
      cartmenuItems,
      { headers: headers }
    );
  }
  getCart(
    correlationid: string,
    clientCode: string,
    identity: string,
    storeId: string,
    cartId: string
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    return this.http.get(
      `${environment.agent_backend_api_url}api/internal/cart/${cartId}/${storeId}`,
      { headers: headers }
    );
  }
  removeItemFromCart(
    correlationid: string,
    clientCode: string,
    identity: string,
    storeId: string,
    cartId: string,
    productId: string
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    return this.http.delete(
      `${environment.agent_backend_api_url}api/internal/cart/${cartId}/${storeId}/items/${productId}`,
      { headers: headers }
    );
  }
  clearCart(
    correlationid: string,
    clientCode: string,
    identity: string,
    storeId: string,
    cartId: string
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    return this.http.delete(
      `${environment.agent_backend_api_url}api/internal/cart/${cartId}/${storeId}/clear`,
      { headers: headers }
    );
  }

  getItemUsingItemId(cartMenuItem: CartMenuItem[], id: string): string {
    return cartMenuItem.find(
      (item) =>
        item.aiMenu &&
        item.aiMenu.menuQueries &&
        item.aiMenu.menuQueries.some((menuQuery) => menuQuery.id === id)
    )?.productUUID;
  }

  updateCart(cart: any, storeId: number, aiparams: aiParams) {
    const headers = new HttpHeaders()
      .set("correlationid", aiparams.convId)
      .set("identity", aiparams.identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", aiparams.clientCode);
    return this.http.post<any>(
      `${environment.agent_backend_api_url}api/internal/cart/${storeId}`,
      cart,
      { headers: headers }
    );
  }

  addressFromGoogle(data, storeId, aiparams: aiParams) {
    const headers = new HttpHeaders()
      .set("correlationid", aiparams.convId)
      .set("identity", aiparams.identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", aiparams.clientCode);
    return this.http.post<any>(
      `${environment.agent_backend_api_url}api/internal/address/${storeId}`,
      data,
      { headers: headers }
    );
  }

  addressDetailsFromGoogle(data, storeId, aiparams: aiParams) {
    const headers = new HttpHeaders()
      .set("correlationid", aiparams.convId)
      .set("identity", aiparams.identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", aiparams.clientCode);
    return this.http.post<any>(
      `${environment.agent_backend_api_url}api/internal/place/${storeId}`,
      data,
      { headers: headers }
    );
  }
}
