<div>
  <div class="p-2">
    <div>
      <!-- <mat-toolbar style="height: 25px" color="primary">
        <span>Orders:</span>
      </mat-toolbar> -->
    </div>
    <div *ngIf="frequentOrders | async as frequentOrder">
      <mat-accordion *ngIf="frequentOrder.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> <b>Frequent Orders:</b> </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <mat-spinner
              *ngIf="emitTrans.addItemCartLoader$ | async"
              style="position: absolute; top: 50%; left: 45%"
            >
            </mat-spinner>
            <button
              (click)="addItemToCart(frequentOrder, 'all-frequent-orders')"
              style="
                color: #4caf50;
                float: right;
                text-align: right;
                margin-top: -32px;
              "
              matTooltip="ADD ITEMS TO CART"
              mat-flat-button
            >
              {{ "ADD TO CART" }}
            </button>
            <ng-container *ngFor="let item of frequentOrder; index as i">
              <div style="margin-bottom: 10px; margin-top: 10px">
                <div class="d-flex" style="justify-content: space-between">
                  <div>
                    <span
                      style="
                        color: #536c79;
                        font-weight: 600;
                        font-size: medium;
                        margin-bottom: 5px;
                      "
                    >
                      <b>{{ item.cartMenuItem.displayName }}</b>
                    </span>

                    <div style="margin-top: 10px">
                      <span
                        *ngFor="
                          let addon of item.cartMenuItem.modifiers;
                          index as ind
                        "
                        style="
                          max-width: 10px !important;
                          margin-top: 10px !important;
                        "
                      >
                        <span *ngIf="!addon.alterationCategory">
                          {{ addon.displayName }}
                          <span
                            *ngIf="
                              addon.alterationPortion &&
                              addon.alterationPortion != 'Normal'
                            "
                          >
                            - ({{ addon.alterationPortion | uppercase }})
                          </span>
                          <span
                            *ngIf="
                              ind != item.cartMenuItem.modifiers.length - 1
                            "
                            >{{ "," }}</span
                          >
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      (click)="addItemToCart(item.cartMenuItem)"
                      style="
                        color: #4caf50;
                        text-align: right;
                        margin-top: -9px;
                      "
                      matTooltip="ADD ITEM TO CART"
                      mat-flat-button
                    >
                      <mat-icon class="mat-icon" role="img" aria-hidden="true"
                        >add</mat-icon
                      >
                    </button>

                    <div style="text-align: right">
                      <button
                        style="color: rgb(175, 27, 27); pointer-events: none"
                        mat-flat-button
                      >
                        <span
                          *ngFor="
                            let addon of filterItemsOfType(
                              item.cartMenuItem.modifiers
                            );
                            index as ind
                          "
                        >
                          <b
                            *ngIf="
                              !item.cartMenuItem?.priceInfo.price && item?.cartMenuItem?.priceInfo.price <= 0
                            "
                          >
                            {{
                              addon.priceInfo.price > 0
                                ? "  $" +
                                  (addon.priceInfo.price | number : "1.2-2")
                                : ""
                            }}</b
                          >
                        </span>
                      </button>

                      <button
                        style="color: rgb(175, 27, 27); pointer-events: none"
                        *ngIf="item.cartMenuItem?.priceInfo?.price && item?.cartMenuItem?.priceInfo?.price > 0"
                        mat-flat-button
                      >
                        <b>{{
                          item?.cartMenuItem?.priceInfo?.price > 0
                            ? "  $" + (item?.cartMenuItem?.priceInfo?.price | number : "1.2-2")
                            : ""
                        }}</b>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  style="margin-top: 10px"
                  *ngIf="getDefault(item.cartMenuItem.modifiers) > 0"
                >
                  <span
                    style="color: #536c79; font-weight: 500; font-size: medium"
                  >
                    <b> {{ "Your Defaults" }}</b>
                  </span>
                  <ng-container
                    *ngFor="
                      let addons of item.cartMenuItem.modifiers;
                      index as modiIndex
                    "
                  >
                    <div
                      class="d-flex"
                      style="justify-content: space-between; margin-top: 2px"
                    >
                      <span
                        *ngIf="addons.alterationCategory && addons.isDefault"
                      >
                        {{ addons.name }}
                        <span
                          *ngIf="
                            addons.alterationPortion &&
                            addons.alterationPortion != 'Normal'
                          "
                        >
                          - ({{ addons.alterationPortion | uppercase }})
                        </span>
                      </span>
                      <!-- <button
                      style="
                        color: rgb(175, 27, 27);
                        pointer-events: none;
                        margin-top: -8px;
                      "
                      mat-flat-button
                    >
                      <b>
                        {{
                          addons.priceInfo.price > 0
                            ? "  $" +
                              (addons.priceInfo.price
                                | number : "1.2-2")
                            : ""
                        }}
                      </b>
                    </button> -->
                    </div>
                  </ng-container>
                </div>

                <div
                  style="margin-top: 10px"
                  *ngIf="getAddons(item.cartMenuItem.modifiers) > 0"
                >
                  <span
                    *ngIf="getCustomisation(item.cartMenuItem.modifiers) > 0"
                    style="color: #536c79; font-weight: 500; font-size: medium"
                  >
                    <b> {{ "Your Customisations" }}</b>
                  </span>
                  <ng-container
                    *ngFor="
                      let addons of item.cartMenuItem.modifiers;
                      index as modiIndex
                    "
                  >
                    <div
                      class="d-flex"
                      style="justify-content: space-between; margin-top: 2px"
                    >
                      <span>
                        {{ addons.displayName }}
                        <span
                          *ngIf="
                            addons.alterationPortion &&
                            addons.alterationPortion != 'Normal'
                          "
                        >
                          - ({{ addons.alterationPortion | uppercase }})
                        </span>
                      </span>
                      <button
                        style="
                          color: rgb(175, 27, 27);
                          pointer-events: none;
                          margin-top: -8px;
                        "
                        mat-flat-button
                      >
                        <b>
                          {{
                            addons.priceInfo.price > 0
                              ? "  $" +
                                (addons.priceInfo.price | number : "1.2-2")
                              : ""
                          }}
                        </b>
                      </button>
                    </div>
                  </ng-container>
                </div>

                <div
                  style="margin-top: 10px"
                  *ngIf="getCustomisation(item.cartMenuItem.modifiers) > 0"
                >
                  <span
                    style="color: #536c79; font-weight: 500; font-size: medium"
                  >
                    <b> {{ "Your Customisations" }}</b>
                  </span>
                  <ng-container
                    *ngFor="
                      let addons of item.cartMenuItem.modifiers;
                      index as modiIndex
                    "
                  >
                    <div
                      class="d-flex"
                      style="justify-content: space-between; margin-top: 2px"
                    >
                      <span
                        *ngIf="addons.alterationCategory && addons.isCustomised"
                      >
                        {{ addons.name }}
                        <span
                          *ngIf="
                            addons.alterationPortion &&
                            addons.alterationPortion != 'Normal'
                          "
                        >
                          - ({{ addons.alterationPortion | uppercase }})
                        </span>
                      </span>
                      <button
                        style="
                          color: rgb(175, 27, 27);
                          pointer-events: none;
                          margin-top: -8px;
                        "
                        *ngIf="addons.alterationCategory && addons.isCustomised"
                        mat-flat-button
                      >
                        <b>
                          {{
                            addons.priceInfo.price > 0
                              ? "  $" +
                                (addons.priceInfo.price | number : "1.2-2")
                              : ""
                          }}
                        </b>
                      </button>
                    </div>
                  </ng-container>
                </div>

                <div style="padding: 5px">
                  <hr />
                </div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div style="margin-top: 5px" *ngIf="pastOrders | async as pastOrder">
      <mat-accordion *ngIf="pastOrder.length > 0">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> <b>Past Orders:</b> </mat-panel-title>
          </mat-expansion-panel-header>

          <div>
            <mat-spinner
              *ngIf="emitTrans.addItemCartLoader$ | async"
              style="position: absolute; top: 50%; left: 45%"
            >
            </mat-spinner>
            <ng-container *ngFor="let items of pastOrder">
              <div style="padding: 5px; margin-top: 10px">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header class="d-flex">
                      <mat-panel-title>
                        <div class="" style="justify-content: space-between">
                          <b>
                            ORDER CREATED :
                            {{
                              items.cartCreatedTimestamp | date : "dd/MM/yyyy"
                            }}</b
                          >

                          <span
                            *ngIf="
                              items.cartPromotions &&
                              items.cartPromotions.length > 0
                            "
                            style="margin-left: 10px"
                          >
                            <b>
                              {{ "ORDER WITH PROMO" }}
                            </b>
                          </span>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <button
                      (click)="addItemToCart(items.cartMenuItems)"
                      style="
                        color: #4caf50;
                        float: right;
                        text-align: right;
                        margin-top: -9px;
                      "
                      matTooltip="ADD ITEMS TO CART"
                      mat-flat-button
                    >
                      {{ "ADD TO CART" }}
                    </button>
                    <ng-container
                      *ngFor="let item of items.cartMenuItems; index as i"
                    >
                      <div style="margin-bottom: 10px; margin-top: 35px">
                        <div
                          class="d-flex"
                          style="justify-content: space-between"
                        >
                          <div>
                            <span
                              style="
                                color: #536c79;
                                font-weight: 600;
                                font-size: medium;
                                margin-bottom: 5px;
                              "
                            >
                              <b>{{ item.displayName }}</b>
                            </span>

                            <div style="margin-top: 10px">
                              <span
                                *ngFor="
                                  let addon of item.modifiers;
                                  index as ind
                                "
                                style="
                                  max-width: 10px !important;
                                  margin-top: 10px !important;
                                "
                              >
                                <span *ngIf="!addon.alterationCategory">
                                  {{ addon.displayName }}
                                  <span
                                    *ngIf="
                                      addon.alterationPortion &&
                                      addon.alterationPortion != 'Normal'
                                    "
                                  >
                                    - ({{
                                      addon.alterationPortion | uppercase
                                    }})
                                  </span>
                                  <span
                                    *ngIf="ind != item.modifiers.length - 1"
                                    >{{ "," }}</span
                                  >
                                </span>
                              </span>
                            </div>
                          </div>
                          <div>
                            <button
                              (click)="addItemToCart(item)"
                              style="
                                color: #4caf50;
                                text-align: right;
                                margin-top: -9px;
                              "
                              matTooltip="ADD ITEM TO CART"
                              mat-flat-button
                            >
                              <mat-icon
                                class="mat-icon"
                                role="img"
                                aria-hidden="true"
                                >add</mat-icon
                              >
                            </button>

                            <div>
                              <button
                                *ngFor="
                                  let addon of filterItemsOfType(
                                    item.modifiers
                                  );
                                  index as ind
                                "
                                style="
                                  color: rgb(175, 27, 27);
                                  pointer-events: none;
                                "
                                mat-flat-button
                              >
                                <b
                                  *ngIf="
                                    !item.priceInfo.price &&
                                    item.priceInfo.price <= 0
                                  "
                                  >{{
                                    item.priceInfo.price > 0
                                      ? "  $" +
                                        (item.priceInfo.price
                                          | number : "1.2-2")
                                      : addon.priceInfo.price > 0
                                      ? "  $" +
                                        (addon.priceInfo.price
                                          | number : "1.2-2")
                                      : ""
                                  }}</b
                                >
                              </button>
                              <button
                                style="
                                  color: rgb(175, 27, 27);
                                  pointer-events: none;
                                "
                                *ngIf="
                                  item.priceInfo.price &&
                                  item.priceInfo.price > 0
                                "
                                mat-flat-button
                              >
                                <b>{{
                                  item.priceInfo.price > 0
                                    ? "  $" +
                                      (item.priceInfo.price | number : "1.2-2")
                                    : ""
                                }}</b>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          style="margin-top: 10px"
                          *ngIf="getAddons(item.modifiers) > 0"
                        >
                          <span
                            *ngIf="getCustomisation(item.modifiers) > 0"
                            style="
                              color: #536c79;
                              font-weight: 500;
                              font-size: medium;
                            "
                          >
                            <b> {{ "Your Customisations" }}</b>
                          </span>
                          <ng-container
                            *ngFor="
                              let addons of item.modifiers;
                              index as modiIndex
                            "
                          >
                            <div
                              class="d-flex"
                              style="
                                justify-content: space-between;
                                margin-top: 2px;
                              "
                            >
                              <span>
                                {{ addons.displayName }}
                                <span
                                  *ngIf="
                                    addons.alterationPortion &&
                                    addons.alterationPortion != 'Normal'
                                  "
                                >
                                  - ({{ addons.alterationPortion | uppercase }})
                                </span>
                              </span>
                              <button
                                style="
                                  color: rgb(175, 27, 27);
                                  pointer-events: none;
                                  margin-top: -8px;
                                "
                                mat-flat-button
                              >
                                <b>
                                  {{
                                    addons.priceInfo.price > 0
                                      ? "  $" +
                                        (addons.priceInfo.price
                                          | number : "1.2-2")
                                      : ""
                                  }}
                                </b>
                              </button>
                            </div>
                          </ng-container>
                        </div>

                        <div
                          style="margin-top: 10px"
                          *ngIf="getDefault(item.modifiers) > 0"
                        >
                          <span
                            style="
                              color: #536c79;
                              font-weight: 500;
                              font-size: medium;
                            "
                          >
                            <b> {{ "Your Defaults" }}</b>
                          </span>
                          <ng-container
                            *ngFor="
                              let addons of item.modifiers;
                              index as modiIndex
                            "
                          >
                            <div
                              class="d-flex"
                              style="
                                justify-content: space-between;
                                margin-top: 2px;
                              "
                            >
                              <span
                                *ngIf="
                                  addons.alterationCategory && addons.isDefault
                                "
                              >
                                {{ addons.name }}
                                <span
                                  *ngIf="
                                    addons.alterationPortion &&
                                    addons.alterationPortion != 'Normal'
                                  "
                                >
                                  - ({{ addons.alterationPortion | uppercase }})
                                </span>
                              </span>
                              <!-- <button
                                style="
                                  color: rgb(175, 27, 27);
                                  pointer-events: none;
                                  margin-top: -8px;
                                "
                                mat-flat-button
                              >
                                <b>
                                  {{
                                    addons.priceInfo.price > 0
                                      ? "  $" +
                                        (addons.priceInfo.price
                                          | number : "1.2-2")
                                      : ""
                                  }}
                                </b>
                              </button> -->
                            </div>
                          </ng-container>
                        </div>

                        <div
                          style="margin-top: 10px"
                          *ngIf="getCustomisation(item.modifiers) > 0"
                        >
                          <span
                            style="
                              color: #536c79;
                              font-weight: 500;
                              font-size: medium;
                            "
                          >
                            <b> {{ "Your Customisations" }}</b>
                          </span>
                          <ng-container
                            *ngFor="
                              let addons of item.modifiers;
                              index as modiIndex
                            "
                          >
                            <div
                              class="d-flex"
                              style="
                                justify-content: space-between;
                                margin-top: 2px;
                              "
                            >
                              <span
                                *ngIf="
                                  addons.alterationCategory &&
                                  addons.isCustomised
                                "
                              >
                                {{ addons.name }}
                                <span
                                  *ngIf="
                                    addons.alterationPortion &&
                                    addons.alterationPortion != 'Normal'
                                  "
                                >
                                  - ({{ addons.alterationPortion | uppercase }})
                                </span>
                              </span>
                              <button
                                style="
                                  color: rgb(175, 27, 27);
                                  pointer-events: none;
                                  margin-top: -8px;
                                "
                                *ngIf="
                                  addons.alterationCategory &&
                                  addons.isCustomised
                                "
                                mat-flat-button
                              >
                                <b>
                                  {{
                                    addons.priceInfo.price > 0
                                      ? "  $" +
                                        (addons.priceInfo.price
                                          | number : "1.2-2")
                                      : ""
                                  }}
                                </b>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                        <div
                          class="d-flex"
                          style="
                            justify-content: space-between;
                            margin-top: 10px;
                          "
                        >
                          <span
                            style="
                              color: #536c79;
                              font-weight: 400;
                              font-size: medium;
                              margin-top: 7px;
                            "
                          >
                            {{ "Quantity" }}
                          </span>

                          <button
                            class="mr-1"
                            style="
                              color: rgb(175, 27, 27);
                              pointer-events: none;
                              margin-top: 2px;
                            "
                            mat-flat-button
                          >
                            {{ item.quantity }}
                          </button>
                        </div>

                        <div style="padding: 5px">
                          <hr />
                        </div>
                      </div>
                    </ng-container>
                    <div
                      class="d-flex"
                      style="justify-content: space-between; margin-top: 2px"
                    >
                      <span
                        style="
                          color: #536c79;
                          font-weight: 400;
                          font-size: medium;
                        "
                      >
                        {{ "Total" }}
                      </span>

                      <button
                        class="mr-1"
                        style="
                          color: rgb(175, 27, 27);
                          margin-top: -8px;
                          pointer-events: none;
                        "
                        mat-flat-button
                      >
                        {{
                          "$" +
                            (items.cartLedger?.expectedLedger?.lineItemPrice
                              | number : "1.2-2")
                        }}
                      </button>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
