import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  ElementRef,
  Optional,
  NgZone,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import { orderState, orderDefaultState } from "app/core/ngxs/state/order.state";
import { storeDefaultState, storeState } from "app/core/ngxs/state/store.state";
import { NotificationService } from "app/core/notification/notification.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { generalService } from "app/core/services/general.service";

import { Observable, catchError, map, of } from "rxjs";

import Swal from "sweetalert2";
import { FormatNumberPipe } from "../../core/pipes/formatNumber.pipe";
import { CustomerComponent } from "../customer/customer.component";
import { SelectSnapshot } from "@ngxs-labs/select-snapshot";
import { promoDefaultState, promoState } from "app/core/ngxs/state/promo.state";
import { dealsDefaultState, dealsState } from "app/core/ngxs/state/deals.state";
import { PromoItems } from "../../../../mediator-commlibs/interfaces/response/promos.interface";
import { cartPromotions } from "../../../../mediator-commlibs/interfaces/response/cart.interface";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { PriceInfo } from "../../../../mediator-commlibs/interfaces/response/menu.interface";
@Component({
  selector: "app-order-in-progress",
  templateUrl: "./order-in-progress.component.html",
  styleUrls: ["../order-receive.scss"],
  providers: [FormatNumberPipe],
})
export class OrderInProgressComponent {
  @Output() removeOrderItem: EventEmitter<any> = new EventEmitter();
  @Output() editOrderItem: EventEmitter<any> = new EventEmitter();
  @SelectSnapshot(promoState.getAllPromo) promos: [] | null;
  @Select(promoState.getPromo) allPromos: Observable<promoDefaultState>;
  @Select(dealsState.getDeals) deals: Observable<dealsDefaultState>;
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  @Select(orderState.getOrder)
  orderState: Observable<orderDefaultState>;
  @Select(orderState.getOrderLoaded)
  orderLoadedState: Observable<orderDefaultState>;
  @Select(orderState.getOrderError)
  orderErrorState: Observable<orderDefaultState>;
  @Select(storeState.getStore) storeState: Observable<storeDefaultState>;
  storeDetailV2: any;
  responseData: any;
  isShowed: any = [];
  cartMenuItems: any[] = [];
  orderLoaded: boolean = false;
  allPromo: PromoItems[] = [];
  allDeal: PromoItems[] = [];
  cartPromotions: cartPromotions[];
  constructor(
    private emitTrans: emitTransactionsService,
    private notifier: NotificationService,
    private general: generalService,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<OrderInProgressComponent>,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.orderState.subscribe((data: any) => {
    //   if (data) {
    //     console.log(data);
    //     if (data.statusCode == 200) {
    //       this.orderLoaded = true;
    //     } else {
    //       this.orderLoaded = false;
    //     }
    //   }
    // });
    this.cartState.subscribe((res: any) => {
      if (res) {
        this.cartPromotions = [
          ...new Map(
            res.cartPromotions?.map((item) => [item["offerId"], item])
          ).values(),
        ];
        this.emitTrans.setAutoApplyCall(this.cartPromotions?.length === 0);
        this.cartMenuItems = res.cartMenuItems;
        // console.log(this.cartMenuItems);
      }
    });
    this.storeState.pipe().subscribe((res: any) => {
      // console.log(res);
      if (res) {
        this.storeDetailV2 = res;
      }
    });
    this.deals.subscribe((res: { data: PromoItems[] }) => {
      if (res.data) {
        // console.log(res.data);
        this.allDeal = res.data || [];
      }
    });
    this.allPromos.subscribe((res: { data: PromoItems[] }) => {
      if (res.data) {
        this.allPromo = res.data;
      }
    });
    //console.log("dialog Data", data);
  }
  filterItemsOfType(modifier: any[]) {
    const data = modifier.filter((data: any) => data.productSubCategory);
    // console.log(data);
    return data;
  }
  getPapaSize(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    return data;
  }

  getPapaSizeName(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    if (data.length > 0) {
      return data[0].papaSize.name;
    } else {
      return "";
    }
  }

  displayPrice(priceInfo: PriceInfo) {
    if (priceInfo.displayPrice && priceInfo.displayPrice > 0) {
      return "$".concat(priceInfo.displayPrice.toFixed(3));
    }

    if (priceInfo.price && priceInfo.price > 0) {
      return "$".concat(priceInfo.displayPrice.toFixed(2));
    }

    return ""
  }

  getPapaSizePrice(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    if (data.length > 0) {
      return "$" + data[0].papaSize.price;
    } else {
      return "";
    }
  }

  sendPapaSize(item) {
    console.log(item);
    if (item.papaSizing) {
      item.papaSizing = false;
    } else {
      item.papaSizing = true;
    }

    console.log(item);
    this.emitTrans.sendUpdateItem(item);
    this.emitTrans.setPapaSize(true);
  }

  excludeKeys(key) {
    let excludeKeyArray = [
      "view_price",
      "detailed_pricing",
      "name",
      "type",
      "isHalfnHalf",
      "nameAlias",
      "quantity",
      "variant",
      "tags",
    ];
    return key && excludeKeyArray.indexOf(key) < 0 ? true : false;
  }

  onClose(): void {}

  removeItem(productUUID) {
    Swal.fire({
      title: "Are You Sure You Want To Remove this Item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      // console.log(result.value);
      if (result.value) {
        this.emitTrans.sendRemoveItem(productUUID);
        this.dialogRef.close();
      }
    });
  }

  updateQuantity(item, quantity) {
    if (quantity <= 0) {
      this.notifier.openSnackBarV2(
        "end",
        "top",
        "INVALID QUANTITY",
        "danger-snackbar"
      );
    } else {
      item.quantity = parseInt(quantity);

      // if (item.halfSpecialties) {
      //   item.halfSpecialties = item.halfSpecialties;
      // } else {
      //   delete item.halfSpecialties;
      // }
      // console.log(item);
      this.emitTrans.sendUpdateItem(item);
    }
  }

  updateNotes(item, notes) {
    item.notes = notes;
    this.emitTrans.sendUpdateItem(item);
  }

  updateOrderNotes(notes) {
    this.emitTrans.sendUpdateCart({ notes, isOrderNotesUpdate: true });
  }

  removeAllItem() {
    this.emitTrans.setAutoApplyCall(true);
    let cartId = this.general.getCartId();
    Swal.fire({
      title: "Are You Sure You Want To Remove All Items?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      // console.log(result.value);

      if (result.value) {
        this.emitTrans.sendRemoveItem({ clear: true });
        this.dialogRef.close();
        // if (this.cartMenuItems.length == 0) {
        //   this.dialogRef.close();
        // }
      }
    });
  }

  editItem(index) {
    this.editOrderItem.emit(index);
  }

  editItemFromCart(item) {
    this.emitTrans.setAutoApplyCall(true);
    const { productUUID, quantity, notes } = item;
    // console.log(productUUID);
    this.emitTrans.editItemFromCart({
      productUUID,
      quantity,
      isHalfAndHalf: item?.halfSpecialties?.length > 0,
      notes,
    });
    this.emitTrans.isEditItem(true);
    this.dialogRef.close();
  }

  getDefault(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.isCustomised && data.alterationCategory
    );
    return data.length;
  }

  getToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.alterationCategory == "TOPPINGS"
    );
    return data.length;
  }

  getDefaultToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) =>
        data.isDefault &&
        !data.isRemoved &&
        data.alterationCategory == "TOPPINGS"
    );
    return data.length;
  }

  getRemovedDToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.alterationCategory == "TOPPINGS" && data.isRemoved
    );
    // console.log(modifiers);
    return data.length;
  }

  showDetails(index: number) {
    if (this.isShowed.includes(index)) {
      this.isShowed = this.isShowed.filter((data: any) => data != index);
    } else {
      this.isShowed.push(index);
    }
  }

  applyPromo(item) {
    this.emitTrans.sendUpdateItem(item);
  }

  showToolTip(item) {
    let getPromo = null;
    if (item?.offerType === "DEAL") {
      getPromo = this.allDeal.find(
        (promo) =>
          promo.offerId === item.offerId || promo.promoCode === item.promoCode
      );
    } else {
      getPromo = this.allPromo.find(
        (promo) =>
          promo.offerId === item.offerId || promo.promoCode === item.promoCode
      );
    }
    return getPromo?.title || getPromo?.description || "";
  }

  removePromo(item, cartPromotions) {
    const updatePromos = cartPromotions.filter(
      (promo) => !(promo.offerId === item.offerId)
    );
    this.emitTrans.sendUpdateItem({
      type: "removeOrderLevelPromo",
      updatePromos,
      removedOfferId: item.offerId,
    });
  }

  activeModifiers(item) {
    return item.modifiers.filter((modifier) => !modifier?.isRemoved);
  }
}
