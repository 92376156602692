<!-- [popover]="myPopover"  [popoverOnHover]="true"  -->
<div class="main-chat-content" [ngClass]="{'firstChatWindow': indexValue == 0}">
  <div class="chat-header" [ngClass]="chatWindowInfo?.esclate ? 'esclate' : 'no-esclate'" >
    <a class="header-action"  (click)="toggleData()">
      <div class="icon">
        <span class="iconCircle">{{chatWindowInfo?.restaurant_id}}</span>
      </div>
      <div class="details">
        <div class="phoneNumber">{{chatWindowInfo?.customer_phone | formatUsNumber}}</div>
        <div class="restaurant-name">{{chatWindowInfo?.name}}</div>
      </div>
    </a>
    
    <div class="cross">
      <span class="order-ai-mode" [ngClass]="chatWindowInfo?.playback ? 'super-ai' : 'takeover'">{{chatWindowInfo?.playback ? 'Super AI' : 'TakeOver' }}</span>
      <i class="material-icons refresh-icon" (click)="refreshConversation(chatWindowInfo)">refresh</i>
      <i class="material-icons" (click)="close(indexValue)">clear</i>
    </div>
    <a mat-button="" target="_blank" href="{{ '/order-receive/' + chatWindowInfo?.customer_phone + '/'+ chatWindowInfo?.uuid +'/' + chatWindowInfo?.restaurant_phone +'?takeover=true'}}" class="createorder"> <i class="material-icons">add</i> Create Order</a>
  </div>
  <div class="box-s"></div>
  <div class="content" *ngIf="isOpen"  #scrollContent >
      <div *ngFor="let item of chatWindowInfo?.conversationData; index as i;">
          <div *ngIf="item?.name == 'customer'" class="message-wrapper left">
            <div class="text-wrapper animated fadeIn">
              {{item.text}}
            </div>
          </div>

          <div *ngIf="item?.name == 'ai'" class="message-wrapper right">
            <div class="text-wrapper animated fadeIn">
              {{item.text}}
            </div>
          </div>
      </div>
      <div class="no-conversation" *ngIf="chatWindowInfo?.conversationData.length <= 0 && !chatWindowInfo?.loader"> No Conversation data found </div>
      <div class="loader-conversation" *ngIf="chatWindowInfo?.loader"> 
        <mat-spinner [diameter]="45"></mat-spinner>
      </div>
  </div>
  <div class="footer-content" *ngIf="isOpen">
    <div class="send-sms-content">
      <textarea type="tel" placeholder="Type your message" [(ngModel)]="message" #textMessageElement></textarea>
      <button mat-icon-button matSuffix (click)="sendsms(chatWindowInfo)" class="send-button">
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- <popover-content #myPopover 
                  title="Order Details"
                  placement="top"
                  [animation]="false"
                  [closeOnClickOutside]="false" >
    <div class="text-center" *ngIf="!chatWindowInfo?.orderDetails?.orderSoFar?.text && !chatWindowInfo?.orderDetails?.orderInProgressDetail?.text" style="margin-bottom: 20px">
      Order details are not found in current conversation 
    </div>
    <div *ngIf="chatWindowInfo?.orderDetails?.orderSoFar?.text"><strong>Order so Far</strong></div>
    <div *ngIf="chatWindowInfo?.orderDetails?.orderSoFar?.text" class="order-item">
      {{chatWindowInfo?.orderDetails?.orderSoFar?.text || '-N/A-'}}
    </div>
    <hr *ngIf="chatWindowInfo?.orderDetails?.orderSoFar?.text && chatWindowInfo?.orderDetails?.orderInProgressDetail?.text">
    <div *ngIf="chatWindowInfo?.orderDetails?.orderInProgressDetail?.text"><strong>Order In Progress</strong></div>
    <div *ngIf="chatWindowInfo?.orderDetails?.orderInProgressDetail?.text" class="order-item">
      {{chatWindowInfo?.orderDetails?.orderInProgressDetail?.text || '-N/A-'}}
    </div>
</popover-content> -->