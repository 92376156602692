import { Component, Inject } from '@angular/core';

import { Router } from '@angular/router';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { NotificationService } from '../../core/notification/notification.service';
import { AppConstants } from '../../app.constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentKey, ComponentName } from '../../../../mediator-commlibs/interfaces/request/restaurantSetting.interface';
@Component({
  selector: 'app-add-dialog',
  templateUrl: './add.component.html'
})
export class AddDialogComponent {
  addForm: FormGroup;
  responseData: any;
  componentArray: ComponentName[] = AppConstants.COMPONENT_NAMES;
  componentKeyArray: ComponentKey[] = AppConstants.COMPONENT_KEYS;
  isSubmitted: boolean = false;
  constructor( private voixHttp: VoixHttpService,
    private router: Router,
    private notification: NotificationService,
    fb: FormBuilder,
    public dialogRef: MatDialogRef<AddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseData = data;
    this.addForm = fb.group({
      'component_name' : [null, Validators.required],
      'component_key': [null, Validators.required],
      'component_value': [null, Validators.required]
    });
  }
  
  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.addForm.valid) {
      this.isSubmitted = true;
      this.dialogRef.close(true);
    }
  }

  addConfiguration(value) {
    this.voixHttp.post('v1/restaurant-configurations/' + this.responseData.restaurant_id, value).subscribe (
      (res:any) => {
        if(res.status) {
          this.notification.show('success','notifications', 'Restaurant Configuration added successfully.');
          this.dialogRef.close(true);
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', "Component name and key should be unique, Please try again");
      }
    );
  }

}
