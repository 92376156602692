<nav
  *ngIf="!navbarService.isActiveRoutes(['ai'])"
  class="navbar navbar-expand-lg navbar-light bg-transparent navbar-absolute"
>
  <div
    class="d-flex justify-content-between align-items-start no-scroll"
    [ngClass]="{
    'order-receive-page-nav': navbarService.isActiveRoutes(['order-receive','ai','drive-thru']),
    'dd': !navbarService.isActiveRoutes(['order-receive','ai','drive-thru']),
  }"
    #navWidth
  >
    <div class="d-flex">
      <div
        class="navbar-header"
        [ngClass]="
          navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru'])
            ? 'take-over-flag-space'
            : 'col-md-6'
        "
      >
        <div
          class="logo"
          *ngIf="
            navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru'])
          "
        >
          <a href="/dashboard" class="simple-text">
            <div class="logo-img">
              <!-- <img
                *ngIf="!navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])"
                src="/assets/img/ezraaifavicon.png"
              /> -->
              <img
                [src]="
                  isDriveThruUrl
                    ? 'https://clientslogo.s3.amazonaws.com/cjlogo.png'
                    : logo_url
                "
                [ngStyle]="
                  isDriveThruUrl
                    ? { height: '45px', 'object-fit': 'cover' }
                    : {}
                "
              />
            </div>
          </a>
          <div class="notification-icon" *ngIf="isShowNotification">
            <i class="material-icons" (click)="toggleNotificationDrawer()">
              chevron_right
            </i>
          </div>
        </div>

        <!-- <a class="navbar-brand"  style="margin-left: 120px"
          ><b>{{ name ? name : "Dashboard" }}</b></a
        > -->
        <div
          class="d-flex"
          style="margin-left: 180px; cursor: pointer; min-width: 141px"
        >
          <span>
            <div
              class="restaurant_name"
              *ngIf="
                name &&
                storeDetails &&
                navbarService.isActiveRoutes([
                  'order-receive',
                  'ai',
                  'drive-thru'
                ])
              "
            >
              <b *ngIf="nick_name != null && !isDriveThruUrl">{{
                nick_name ? nick_name : "Dashboard"
              }}</b>
              {{
                isDriveThruUrl
                  ? "46637 MISSION BLVD"
                  : (storeDetails?.store?.address?.street | uppercase)
              }}
            </div>
            <!-- <div
              class="restaurant_address"
              *ngIf="
                landmark != null &&
                storeDetails &&
                navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])
              "
            >
              {{ landmark + "," }}
            </div> -->
            <div
              class="restaurant_address"
              *ngIf="
                name &&
                storeDetails &&
                navbarService.isActiveRoutes([
                  'order-receive',
                  'ai',
                  'drive-thru'
                ])
              "
            >
              <b
                ><span *ngIf="landmark != null && !isDriveThruUrl">{{
                  "LANDMARK:" + landmark + ","
                }}</span></b
              >
              <ng-container *ngIf="!isDriveThruUrl">
                {{ storeDetails?.store?.address?.city | uppercase }},
                {{ storeDetails?.store?.address?.state | uppercase }},
                {{ storeDetails?.store?.address?.zip | uppercase }}
              </ng-container>
              <ng-container *ngIf="isDriveThruUrl">{{
                "FREMONT, CA 94539"
              }}</ng-container>
            </div>

            <div
              class="restaurant_address"
              *ngIf="
                name &&
                storeDetails &&
                navbarService.isActiveRoutes([
                  'order-receive',
                  'ai',
                  'drive-thru'
                ])
              "
            >
              {{
                isDriveThruUrl ? "(510) 493-2353" : storeDetails?.store?.phone
              }}
              <a
                style="color: blue"
                *ngIf="!isDriveThruUrl"
                (click)="openStoreDetails()"
                matTooltip="VIEW STORE INFO"
                ><u>Store Info</u></a
              >
            </div>
          </span>
        </div>
      </div>

      <!-- <div
        class="currentStoreTime"
        *ngIf="storeHours && navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])"
      >
        <span *ngFor="let item of storeHours">
          {{ item.dayOfWeek }}: {{ item.time }} ({{ item.timezone }})
        </span>
      </div> -->
      <!-- <div
      class="dropdown sip-session hidden-xs"
      *ngIf="navbarService.isActiveRoutes(['order-receive','ai','drive-thru']) && landmark != null"
    >
      <button style="pointer-events: none; border: none; font-size: large">
        <b> {{ "LANDMARK:" + landmark.toUpperCase() }} </b>
      </button>
    </div> -->

      <div
        class="dropdown sip-session d-none d-lg-block"
        *ngIf="
          navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru']) &&
          storeDetails &&
          name
        "
      >
        <button style="pointer-events: none; border: none; font-size: x-large">
          <b>
            {{ "STORE:"
            }}{{
              isDriveThruUrl
                ? "OPEN"
                : storeDetails?.store?.storeOpenFlag == true
                ? "OPEN"
                : "CLOSE"
            }}
          </b>
        </button>
      </div>

      <div
        class="dropdown sip-session d-none d-lg-block"
        *ngIf="
          navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru']) &&
          storeDetails
        "
      >
        <button style="pointer-events: none; border: none; font-size: x-large">
          <b *ngIf="storeDetails?.store?.maxOrderAmt > 0">
            {{
              "$" +
                storeDetails?.store?.minDeliveryAmt +
                "-" +
                "$" +
                storeDetails?.store?.maxOrderAmt
            }}
          </b>
        </button>
      </div>
      <div
        class="dropdown sip-session d-none d-lg-block"
        *ngIf="
          navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru']) &&
          timezone
        "
      >
        <button style="pointer-events: none; border: none; font-size: xx-large">
          <b> {{ timezone }} </b>
        </button>
      </div>
      <!-- <div
        style="margin-left: 10px"
        *ngIf="navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])"
      >
        <span>
          <mat-card
            class="restaurant_address"
            style="cursor: pointer"
            (click)="openStoreDetails()"
          >
            (ORDER IN PROGRESS)
          </mat-card>
        </span>
      </div> -->
    </div>

    <!-- <div *ngIf="cartState | async as cartData">
      <mat-card>
        <div class="d-flex" style="justify-content: space-between">
          <mat-icon
            class="mat-icon"
            style="float: left; width: 20%"
            role="img"
            aria-hidden="true"
            >location_on</mat-icon
          >
          <div>
            <div>
              <b>
                {{
                  (cartData.customer.firstName | uppercase) +
                    " " +
                    (cartData.customer.lastName | uppercase)
                }}
              </b>
            </div>
            <div
              *ngIf="cartData.orderDestination != 'CARRYOUT'"
              style="margin-right: 2px"
            >
              <span
                *ngIf="
                  cartData?.customer?.deliveryAddress.streetLine2 != '' &&
                  cartData?.customer?.deliveryAddress.streetLine2 != null
                "
              >
                {{ cartData?.customer?.deliveryAddress.streetLine2 + ", " }}
              </span>
              {{ cartData.customer.deliveryAddress.streetLine4 }}
            </div>
            <div>
              <b>{{ cartData.orderDestination }}</b>
            </div>
          </div>
          <button
            mat-flat-button
            (click)="openCustomerPopup()"
            style="color: rgb(71, 214, 71); margin-top: -8px"
          >
            EDIT
          </button>
        </div>
      </mat-card>
    </div> -->

    <!-- <div
      class="restaurant_name"
      *ngIf="
        navbarService.isActiveRoutes(['order-receive','ai','drive-thru']) &&
        cartDetails?.cartMenuItems.length > 0
      "
    >
      <mat-card (click)="openCartDetail()">
        <ng-container *ngFor="let item of cartDetails?.cartMenuItems">
          <div class="d-flex">
            <b>
              {{ item.displayName }}
            </b>
            <b style="margin-left: 2px; color: rgb(175, 27, 27)">
              {{
                cartDetails?.cartLedger.expectedLedger.lineItemPrice
                  | number: "1.2-2"
              }}
            </b>
          </div>
        </ng-container>
      </mat-card>
    </div> -->
    <!-- V2 -->

    <div class="mobile-header-nav">
      <ul class="navbar-nav navbar-right ms-auto align-items-end flex-wrap">
        <li
          class="nav-item dropdown sip-session d-none d-lg-block"
          *ngIf="cartDetails?.cartLedger"
        >
          <button
            style="pointer-events: none; border: none; font-size: x-large"
          >
            <b>
              {{ "CART TOTAL:" }}
              {{ "$"
              }}{{
                cartDetails?.cartLedger.expectedLedger.lineItemPrice
                  | number : "1.2-2"
              }}
            </b>
          </button>
        </li>

        <!-- <li class="dropdown hidden-xs">
          <mat-slide-toggle
            [checked]="restaurant?.online_status === 'AVAILABLE'"
            [disabled]="errors.length > 0"
            (change)="updateAvaibility($event)"
            >Available</mat-slide-toggle
          >
        </li> -->
        <li
          class="nav-item"
          *ngIf="
            !navbarService.isActiveRoutes(['order-receive', 'ai', 'drive-thru'])
          "
        >
          <!-- <mat-form-field class="status-border restaurant-dropdown">
            <mat-select
              panelClass="mat-no-underline restaurant-select"
              (selectionChange)="agentStatusFilter($event)"
              [(value)]="restaurant"
            >
              <mat-option
                *ngFor="let st of currentuser?.AgentRestaurants"
                [value]="st"
              >
                {{ st?.UserRestaurant?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </li>
        <li class="nav-item selling-btn d-flex border-0">
          <button
            class="mb-2 border-0"
            *ngIf="
              isUpSellingEnabled &&
              (overAllHierarchy | upsellingCount) &&
              cartDetails?.cartMenuItems?.length
            "
            [matMenuTriggerFor]="upsell"
          >
            <span class="upselling-tooltiptext"
              >Remember to offer upselling items to enhance the customer's
              order!</span
            >
            <a
              class="nav-link dropdown-toggle"
              [matMenuTriggerFor]="cartMenu"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                class="cursor-pointer"
                src="/assets/img/upselling-icon.png"
                width="22"
                height="22"
              />
              <span class="notification">{{
                overAllHierarchy | upsellingCount
              }}</span>
            </a>
          </button>
          <mat-menu #upsell="matMenu" class="upselling-list-card">
            <form>
              <div class="main-heading">Upselling</div>
              <div class="upselling-table-wrapper">
                <table class="table upselling-container">
                  <thead>
                    <tr>
                      <th>Items</th>
                      <th>Price</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of overAllHierarchy | orderBy">
                      <ng-container *ngIf="!item?.isDeleted">
                        <td>
                          <div class="d-flex">
                            <div class="item-heading">
                              {{ item?.displayName }}
                            </div>
                            <div class="item-heading item-parent-name">
                              ({{ item?.parentName }})
                            </div>
                          </div>
                          <div class="mt-3 item-details">
                            {{ item?.displayName + "," }}
                            {{ item?.subTitleName }}
                            <span *ngIf="item?.defaultModifiers?.length">{{
                              "," + " " + item?.defaultModifiers
                            }}</span>
                          </div>
                        </td>
                        <td>{{ "$" + item?.priceInfo?.price }}</td>
                        <td class="text-center">
                          <button
                            class="btn btn-primary approve-response apply text-nowrap"
                            matTooltip="Add to Cart"
                            [disabled]="item.isDisabled"
                            (click)="addToCartUpselling(item)"
                          >
                            Add to Cart
                          </button>
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </mat-menu>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            navbarService.isActiveRoutes(['order-receive', 'drive-thru']) &&
            !isDriveThruUrl
          "
        >
          <a
            class="nav-link"
            style="cursor: pointer"
            [matMenuTriggerFor]="
              cartDetails?.cartMenuItems?.length > 0 ? cartMenu : null
            "
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons shopping-icon">shopping_cart</i>
            <span class="notification">{{
              cartDetails?.cartMenuItems.length
                ? cartDetails?.cartMenuItems.length
                : 0
            }}</span>
            <p class="d-lg-none d-md-none">Cart</p>
          </a>
          <mat-menu #cartMenu="matMenu" class="cart-matmenu add-to-upsell-item">
            <div style="max-height: 400px; overflow: auto">
              <button
                mat-menu-item
                *ngFor="let item of cartDetails?.cartMenuItems"
                (click)="openCartDetail()"
                style="font-size: medium"
              >
                <span class="me-1">{{ item.displayName }}</span>
                <b
                  >{{ "$"
                  }}{{
                    item.ledger.expectedLedger.lineItemPrice | number : "1.2-2"
                  }}</b
                >
              </button>
            </div>
          </mat-menu>
        </li>
        <li
          class="nav-item chat-icon"
          *ngIf="
            navbarService.isActiveRoutes(['order-receive', 'drive-thru']) &&
            conversationSoFar?.length
          "
        >
          <i class="material-icons" (click)="toggleChatDrawer()"> chat </i>
        </li>
        <li
          class="dropdown sip-session d-none d-lg-block"
          *ngIf="
            navbarService.isActiveRoutes([
              'order-receive',
              'ai',
              'drive-thru'
            ]) && getElapsedTime() as elapsed
          "
        >
          <button
            style="pointer-events: none; border: none; font-size: xx-large"
          >
            <b>
              {{ elapsed.minutes < 10 ? 0 : "" }}{{ elapsed.minutes }} :
              {{ elapsed.seconds < 10 ? 0 : "" }}{{ elapsed.seconds }}
              {{ getCallDuration(elapsed.minutes, elapsed.seconds) }}
            </b>
          </button>
        </li>
        <li class="invisible-profile-bar">
          <button
            class="menu-btn-header"
            *ngIf="
              !navbarService.isActiveRoutes([
                'order-receive',
                'ai',
                'drive-thru'
              ])
            "
            mat-button
            [matMenuTriggerFor]="menu"
          >
            <a class="nav-link text-uppercase me-3">
              <i class="material-icons">person</i>
              <p class="d-lg-none d-md-none">Profile</p>
              {{ currentuser?.email }}
            </a>
          </button>
          <mat-menu #menu="matMenu" class="navbar-matmenu no-scroll">
            <ul class="drop-menu-list no-scroll">
              <li *ngIf="isAdminBuild == undefined ? true : isAdminBuild">
                <a class="dropdown-item" routerLink="/change-password">
                  <i class="material-icons fs-18">settings</i> Change Password
                </a>
              </li>
              <li class="dropdown-item">
                <a class="dropdown-item" (click)="logout()">
                  <i class="material-icons fs-18">power_settings_new</i> Logout
                </a>
              </li>
            </ul>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</nav>
