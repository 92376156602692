import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'specialsPriceString'})
export class SpecialsPriceStringPipe implements PipeTransform {
  transform(pricingArray: any): any {
    let pricingString = "Price:- ";
    let pricingArrayData = []
    pricingArray.forEach(function(value, index) {
      if(value.variant_name) {
        pricingArrayData.push(value.variant_name + ": $" + value.value);
      } else {
        pricingArrayData.push("$"+value.value);
      }
    });
    
    return pricingString + pricingArrayData.join(", ");
  }
}