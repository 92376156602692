<div class="col-12">
  <div class="row">
    <div class="col-10">
      <div class="row d-flex">
        <div class="col-4">
          <app-notification-selected-client (selectedClientForStores)="clientSelectedForStores($event)"
          [client]="selectedClient"></app-notification-selected-client>
        </div>
        <div class="col-8">
          <app-notification-filters (selectedStatusForNotification)="statusSelectedForNotification($event)"
          (searchByStoreID)="searchByStoreID($event)" [storeID]="storeID"
          [selectedStatus]="status"></app-notification-filters>
        </div>
      </div>
    </div>
    <div class="col-2">
      <button style="float: right" class="btn btn-outline-dark btn-danger btn-sm" [disabled]="
            !selectedUsersFromGrid?.length || selectedUsersFromGrid.length < 2
          " (click)="deleteNotification()" *ngIf="userPermissions.NOTIFICATION_DELETE | permissions">
        Delete All ({{ selectedUsersFromGrid?.length ?? "" }})
      </button>
    </div>
  </div>
  <div class="row">
    <div class="card-content table-responsive col-12 agent-table">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="table-heading">
              <input cFormCheckInput [checked]="allStoresSelected" (click)="SelectUnSelectAll($event)" type="checkbox" />
            </th>
            <th class="table-heading" scope="col">ID</th>
            <th class="table-heading" scope="col">Title</th>
            <th class="table-heading" scope="col">Priority</th>
            <th class="table-heading" scope="col">Total Stores</th>
            <th class="table-heading" scope="col">Start Date</th>
            <th class="table-heading" scope="col">End Date</th>
            <th class="table-heading" scope="col">Created At</th>
            <th class="table-heading" scope="col">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="notificationListing?.length">
          <tr *ngFor="let notification of notificationListing">
            <td>
              <c-form-check>
                <input cFormCheckInput type="checkbox" [(ngModel)]="notification.Selected"
                  (change)="OnRowCheckboxClicked($event, notification)" [checked]="notification.Selected" />
              </c-form-check>
            </td>
            <td>{{ notification?.notificationId || "-" }}</td>
            <td>{{ notification?.title || '-' }}</td>
            <td>{{ notification?.priority || '-' }}</td>
            <td>{{ notification?.stores.length || "-" }}</td>
            <td>{{ notification?.validFromDate }}</td>
            <td>{{ notification?.validTillDate }}</td>
            <td>{{ notification?.createdAt | date:'hh:mm a dd MMM YYYY'|| '-' }}</td>
            <td>
              <button class="btn btn-sm btn-warning badge" [disabled]="multipleNotificationsSelected"
                (click)="modifyNotification(notification)" *ngIf="userPermissions.NOTIFICATION_EDIT | permissions">
                Modify
              </button>
              <button class="btn btn-sm btn-danger badge" [disabled]="multipleNotificationsSelected"
                (click)="deleteNotification(notification)" *ngIf="userPermissions.NOTIFICATION_DELETE | permissions">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-paginator #paginator *ngIf="notificationListing?.length" [length]="notificationData?.count"
        [pageSize]="params.limit" [pageSizeOptions]="[5, 10, 25, 100]" (page)="handlePage($event)">
      </mat-paginator>
      <div class="mat-row not-found" *ngIf="!notificationListing?.length">
        <span> No data found. </span>
      </div>
    </div>
  </div>
</div>