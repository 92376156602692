import { Address } from "app/core/directives/ngx-google-places-autocomplete/objects/address";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { NotificationService } from "app/core/notification/notification.service";
import * as moment from "moment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  Observable,
  takeWhile,
  startWith,
  map,
  filter,
  debounceTime,
  tap,
  switchMap,
  of,
  lastValueFrom,
} from "rxjs";
import { orderTypes } from "../../../../../mediator-commlibs/interfaces/response/store.interface";
import { v4 as uuidv4 } from "uuid";
import { aiCartService } from "app/core/services/ai/ai-cart.service";
import { aiStoreService } from "app/core/services/ai/ai-store.service";
import { aiMenuService } from "app/core/services/ai/ai-menu.service";
import { MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-update-cart",
  templateUrl: "./update-cart.component.html",
  styleUrls: ["./update-cart.component.scss"],
})
export class UpdateCartComponent implements OnInit, OnDestroy {
  cartform: FormGroup;
  selectedOrderType: string;
  orderTypes: orderTypes[];
  dt1: any;
  startAt: any;
  min: any;
  max: any;
  deliveryAddress: any = {
    streetLine1: "",
    streetLine2: "",
    streetLine3: "",
    streetLine4: "",
    zip: "",
    state: "",
    city: "",
  };
  isBusiness: FormControl = new FormControl(
    { value: false, disabled: false },
    Validators.required
  );
  googleaddress: any = [];
  usedAddressClicked: Boolean = false;
  requestAddress: any;
  requestPlace: any;
  customerAddress: any;
  isAddressValidate: boolean = false;
  latlong: string = "";
  sessionToken: string = "";
  vehicle_brandsControl = new FormControl("");
  vehicleBrandOptionsV2: Observable<any>;
  vehicle_typeControl = new FormControl("");
  vehicleTypeOptionsV2: Observable<any>;
  vehicle_colorControl = new FormControl("");
  vehicleColorOptionsV2: Observable<any>;
  vehicle_colors: any[] = [];
  vehicle_brands: any[] = [];
  vehicle_type: any[] = [];
  placeSearchInprogress = false;
  isComponentAlive: boolean = true;
  cart: any;
  restaurantDetails: any;
  store: any;
  @Input() activePage: string;
  constructor(
    private fb: FormBuilder,
    public cartService: aiCartService,
    public storeService: aiStoreService,
    public _aiMenuService: aiMenuService,
    private ngxService: NgxUiLoaderService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    @Optional() public dialogRef: MatDialogRef<UpdateCartComponent>
  ) {
    _aiMenuService.convState.subscribe((data) => {
      if (data) {
        this.cart = data.cart;
        this.store = data.storeDetails;
      }
    });
    this.initializeVehicle();
  }

  ngOnInit(): void {
    this.createAndPatchForm();
    this.cartService.currentStoreData
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((data: any) => {
        this.orderTypes = data.orderTypes;
      });
    this.editPreCondition(this.cart);
    this.vehicleBrandOptionsV2 = this.vehicle_brandsControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || "", "vehicle_brands"))
    );
    this.vehicleTypeOptionsV2 = this.vehicle_typeControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || "", "vehicle_type"))
    );
    this.vehicleColorOptionsV2 = this.vehicle_colorControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || "", "vehicle_colors"))
    );
  }
  closeDialog() {
    this.dialogRef.close();
  }
  ngAfterViewInit(): void {
    const latlong: string = this.store.latLong || "";
    const splitted = latlong?.split(":");
    this.latlong = splitted.join(":");
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4")
      .valueChanges.pipe(
        filter((val) => val != undefined && val !== this.customerAddress),
        debounceTime(400),
        tap((value) => (value ? (this.placeSearchInprogress = true) : null)),
        switchMap((value) => {
          if (!this.sessionToken) {
            this.sessionToken = uuidv4();
          }
          if (value) {
            if (!this.sessionToken) {
              this.sessionToken = uuidv4();
            }
            this.isBusiness.disable();
            this.requestAddress = {
              input: value,
              latLong: this.latlong,
              sessiontoken: this.sessionToken,
              isGoogle: true,
            };
            this.cartService
              .addressFromGoogle(
                this.requestAddress,
                this.cart?.storeId,
                this._aiMenuService.aiParams.value
              )
              .subscribe({
                next: (response) => {
                  this.recieveAddress(response?.data || []);
                  this.placeSearchInprogress = false;
                },
                error: () => {
                  this.placeSearchInprogress = false;
                },
              });
          } else {
            this.isBusiness.enable();
          }
          return of(value);
        })
      )
      .subscribe();
    // const deliveryInstructionControl = this.cartform
    //   ?.get("customer")
    //   ?.get("deliveryAddress")
    //   ?.get("deliveryInstruction");
    const streetline4Control = this.cartform
      ?.get("customer")
      ?.get("deliveryAddress")
      ?.get("streetLine4");
    this.cartform
      .get("orderDestination")
      .valueChanges.pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((val) => {
        streetline4Control.setValue("");
        if (val == "DELIVERY") {
          this.isAddressValidate = false;
          this.updateVehicalDeliveryForm(true, val);
        } else if (val == "DRIVE_UP_PICK_UP") {
          // if (deliveryInstructionControl?.value?.includes("Business name:")) {
          //   deliveryInstructionControl.setValue("");
          // }
          this.updateVehicalDeliveryForm(false, val);
        } else {
          // if (deliveryInstructionControl?.value?.includes("Business name:")) {
          //   deliveryInstructionControl.setValue("");
          // }
          this.updateVehicalDeliveryForm(true, val);
        }
      });
    this.delivery_address.patchValue(
      this?.cart?.customer?.deliveryAddress || ""
    );
    this.cdr.detectChanges();
  }

  private _filter(value: string, type: string): string[] {
    const filterValue = value.toLowerCase();
    return this[type].filter((option) =>
      type === "vehicle_colors"
        ? option.label.toLowerCase().includes(filterValue)
        : option.toLowerCase().includes(filterValue)
    );
  }

  updateVehicalDeliveryForm(deliveryAddress = true, type: string) {
    if (deliveryAddress) {
      this.cartform
        .get("customer")
        .get("deliveryAddress")
        .get("streetLine4")
        .setValidators(type === "CARRYOUT" ? [] : [Validators.required]);
      this.cartform
        .get("customer")
        .get("deliveryAddress")
        .get("streetLine4")
        .updateValueAndValidity();
    }
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("make")
      .clearValidators();
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("type")
      .clearValidators();
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("color")
      .clearValidators();
    if (type == "DRIVE_UP_PICK_UP") {
      this.cartform
        .get("customer")
        .get("vehicleInfo")
        .get("make")
        .setValidators([Validators.required]);
      this.cartform
        .get("customer")
        .get("vehicleInfo")
        .get("type")
        .setValidators([Validators.required]);
      this.cartform
        .get("customer")
        .get("vehicleInfo")
        .get("color")
        .setValidators([Validators.required]);
    }
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("make")
      .updateValueAndValidity();
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("type")
      .updateValueAndValidity();
    this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("color")
      .updateValueAndValidity();
  }

  setOrderType(value: string) {
    const acceptingCurrentOrders =
      this.orderTypes.find(
        (orderType) =>
          orderType.displayName.toUpperCase() === value.toUpperCase()
      )?.acceptingCurrentOrders ?? true;
    if (!acceptingCurrentOrders) {
      this.cartform.get("orderReadyDate").setValidators([Validators.required]);
      this.cartform.get("orderReadyDate").updateValueAndValidity();
      this.selectedOrderType = value?.toUpperCase();
    } else {
      this.selectedOrderType = "";
    }
  }

  setBusinness(value: boolean) {
    if (!this.isBusiness.disabled) {
      const deliveryInstructionControl = this.cartform
        ?.get("customer")
        ?.get("deliveryAddress")
        ?.get("deliveryInstruction");
      if (this.isBusiness.value && !value) {
        deliveryInstructionControl.patchValue("");
      } else {
        deliveryInstructionControl.patchValue(
          this.cartform
            ?.get("customer")
            ?.get("deliveryAddress")
            ?.get("deliveryInstruction").value
        );
      }
      this.isBusiness.patchValue(value);
    }
    this.emptyAddress();
  }

  emptyAddress() {
    this.requestAddress = null;
    this.requestPlace = null;
    this.recieveAddress(null);
    this.recievePlace(null);
  }

  recieveAddress(data) {
    if (data && data.length > 0) {
      this.googleaddress = data;
    } else {
      this.googleaddress = [];
      if (this.usedAddressClicked && data && data.length == 0) {
        this.isBusiness.setValue(true);
        this.requestAddress = {
          input: this.cartform
            .get("customer")
            .get("deliveryAddress")
            .get("streetLine4").value,
          isBusiness: this.isBusiness.value,
        };
        this.usedAddressClicked = false;
      }
    }
  }

  recievePlace(data: any) {
    this.ngxService.start();
    if (data) {
      this.lineItemAddressChange(data);
    }
  }

  lineItemAddressChange(address: Address | any) {
    this.setGoogleAddress(address);
    // if (this.isBusiness.value) {
    // } else {
    //   this.setPostGrid(address);
    // }
  }

  getPlace(address: any, index: number) {
    this.ngxService.start();
    if (address) {
      console.log(address, index);
      this.requestPlace = {
        isGoogle: true,
        sessionToken: this.sessionToken,
        placeId: address.place_id,
        // index: index || 0,
      };
      this.cartService
        .addressDetailsFromGoogle(
          this.requestPlace,
          this.cart?.storeId,
          this._aiMenuService.aiParams.value
        )
        .subscribe({
          next: (response) => {
            this.ngxService.stop();
            this.recievePlace(response?.data || []);
            this.sessionToken = "";
          },
          error: () => {},
        });
    }
  }

  setGoogleAddress(address: Address) {
    console.log(address);
    this.deliveryAddress = {
      streetLine2: null,
      streetLine3: null,
      streetLine4: null,
      zip: null,
    };
    this.customerAddress = null;
    this.customerAddress =
      address["address"] || address.formatted_address || "";

    this.deliveryAddress.streetLine2 = this.cartform.value.apt_suite
      ? this.cartform.value.apt_suite
      : "";
    const deliveryInstructionControl = this.cartform
      ?.get("customer")
      ?.get("deliveryAddress")
      ?.get("deliveryInstruction");
    deliveryInstructionControl.setValue("");
    if (address?.types?.includes("establishment")) {
      if (
        !deliveryInstructionControl?.value ||
        (!deliveryInstructionControl?.value?.includes("Instructions:") &&
          deliveryInstructionControl?.value?.includes("Business name:"))
      ) {
        deliveryInstructionControl.setValue(
          `Business name: ${address?.name || ""}, Instructions:`
        );
      } else if (
        deliveryInstructionControl?.value &&
        !deliveryInstructionControl?.value?.includes("Instructions:") &&
        !deliveryInstructionControl?.value?.includes("Business name:")
      ) {
        deliveryInstructionControl.setValue(
          `Business name: ${address.name || ""}, Instructions: ${
            deliveryInstructionControl?.value
          }`
        );
      } else {
        let value = deliveryInstructionControl?.value;
        let i = value?.indexOf("Instructions:") + 14;
        value = value.slice(i, deliveryInstructionControl?.value.length);
        deliveryInstructionControl.setValue(
          `Business name: ${address.name || ""}, Instructions: ${value}`
        );
      }
    }
    this.deliveryAddress.streetLine4 = this.customerAddress;
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .patchValue({ streetLine4: this.deliveryAddress.streetLine4 });
    for (let index = 0; index < address?.address_components?.length; index++) {
      const element = address.address_components[index];
      if (element.types.includes("postal_code")) {
        this.deliveryAddress.zip = element?.long_name || "";
      }
      if (
        element.types.includes("locality") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1")
      ) {
        this.deliveryAddress.city = element?.long_name || "";
      }
      if (element.types.includes("street_number")) {
        this.deliveryAddress.streetLine1 = element.long_name || "";
        this.deliveryAddress.streetLine3 = element.long_name || "";
      }
      if (element.types.includes("route")) {
        this.deliveryAddress.streetLine1 =
          (this.deliveryAddress?.streetLine1 || "") +
          " " +
          (element?.long_name || "");
      }
      if (element.types.includes("administrative_area_level_1")) {
        this.deliveryAddress.state = element?.short_name || "";
      }
    }
    this.isAddressValidate = true;
    this.ngxService.stop();
  }

  get vehicleBrandRequired() {
    const control = this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("make");
    return control.touched && control.hasError("required");
  }

  get vehicleTypeRequired() {
    const control = this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("type");
    return control.touched && control.hasError("required");
  }

  get vehicleColorRequired() {
    const control = this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("color");
    return control.touched && control.hasError("required");
  }

  get addressline4() {
    const control = this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4");
    return control.touched && control.hasError("required");
  }

  setPostGrid(addressFromPostGrid: any) {
    console.log(addressFromPostGrid);
    if (!addressFromPostGrid || Object.keys(addressFromPostGrid).length == 0) {
      this.showErrorMessage({ message: "ADDRESS IS NOT VALID" });
      this.cartform
        .get("customer")
        .get("deliveryAddress")
        .patchValue({ streetLine4: null });
      this.isAddressValidate = false;
      return;
    }
    const address = addressFromPostGrid.address;
    this.deliveryAddress.streetLine2 = null;
    this.deliveryAddress.streetLine4 = null;
    this.deliveryAddress.streetLine1 = null;
    this.deliveryAddress.zip = null;
    this.customerAddress = null;
    this.customerAddress = address?.address || address?.formatted_address || "";
    this.deliveryAddress.streetLine2 = this.cartform.value.apt_suite
      ? this.cartform.value.apt_suite
      : "";
    const postalcode = address?.pc.includes("-")
      ? address?.pc?.split("-")[0]
      : address.pc;
    this.deliveryAddress.zip = postalcode;
    this.deliveryAddress.city = address?.city;
    this.deliveryAddress.state = address?.prov;
    this.deliveryAddress.streetLine1 = address?.address;
    const streetNumber = address?.address?.split(" ")[0] || "";
    this.deliveryAddress.streetLine4 =
      `${this.customerAddress} ${this.deliveryAddress.city} ${this.deliveryAddress.state} ${this.deliveryAddress.zip} ${address?.country}`.toUpperCase();
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .patchValue({ streetLine4: this.deliveryAddress.streetLine4 });
    this.deliveryAddress.streetLine3 = streetNumber;
    this.isAddressValidate = true;
  }

  clearDate() {
    this.dt1 = null;
    this.cartform.patchValue({
      orderReadyDate: null,
    });
    this.cart.orderReadyDate = null;
    this.storeDT();
  }

  editPreCondition(cart: any) {
    this.dt1 = cart.orderReadyDate
      ? this.removeTimeZonePart(cart.orderReadyDate)
      : new Date();
    this.getDateValidation();
    this.cartform.patchValue({
      ...cart,
      orderReadyDate: cart.orderReadyDate
        ? moment(this.dt1).format("YYYY-MM-DDTHH:mm:ss")
        : null,
    });
    this.deliveryAddress = {
      ...cart.customer.deliveryAddress,
    };
    if (cart.customer.vehicleInfo) {
      this.vehicleInfo.patchValue(cart.customer.vehicleInfo);
      this.vehicle_brandsControl.patchValue(cart.customer.vehicleInfo.make);
      this.vehicle_typeControl.patchValue(cart.customer.vehicleInfo.type);
      this.vehicle_colorControl.patchValue(cart.customer.vehicleInfo.color);
    }
    this.vehicle_colors = this.store.vehicles ? this.store.vehicles.colors : [];
    this.vehicle_type = this.store.vehicles ? this.store.vehicles.types : [];
    this.vehicle_brands = this.store.vehicles
      ? this.store.vehicles.vehicleMakes
      : [];
    this.orderTypes = this.store.orderTypes;
  }

  getDateValidation() {
    const storedateTime = new Date(moment(this.dt1).format());
    this.min = storedateTime;
    this.max = new Date(moment(this.dt1).add("20", "days").format());
    this.startAt = storedateTime;
    this.storeService.startAtDT = {
      startAt: this.startAt,
      max: this.max,
      min: this.min,
    };
  }

  removeTimeZonePart(dateString) {
    let finalDate = "";
    if (dateString.split("+").length > 1) {
      let b = dateString.split("+");
      finalDate = b[0];
    } else {
      let b = dateString.split("-");
      if (b.length > 1) {
        b.pop();
        finalDate = b.join("-");
      }
    }
    return finalDate;
  }

  createAndPatchForm() {
    this.cartform = this.fb.group({
      apt_suite: [null],
      cartId: [this.cart.id],
      customer: this.fb.group({
        deliveryAddress: this.fb.group({
          city: [null],
          state: [null],
          streetLine1: [null],
          streetLine2: [null], // this.streetLine2
          streetLine3: [null],
          streetLine4: [null],
          zip: [null],
          deliveryInstruction: [null],
        }),
        id: [""],
        firstName: [
          null,
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
        ],
        lastName: [
          null,
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
        ],
        phone: [
          null, // this.phone
          [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(10),
            Validators.maxLength(12),
          ],
        ],
        vehicleInfo: this.fb.group({
          make: [""],
          type: [""],
          color: [""],
        }),
        email: [
          "",
          [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
        ],
      }),
      orderReadyDate: [null],
      orderDestination: [this.cart?.orderDestination, [Validators.required]],
      notes: [""],
      storeId: [""],
    });
    if (this.cart?.customer?.deliveryAddress?.streetLine2) {
      this.cartform
        .get("apt_suite")
        .setValue(this.cart?.customer?.deliveryAddress?.streetLine2);
    }
  }

  getDateCalender(date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss.000");
  }

  showErrorMessage(error) {
    // this.notification.setErrorMessage(error.error?.message || error?.message || "Something went wrong please try again");
    this.ngxService.stop();
  }

  onChangeDeliveryPickupTime(event) {
    if (event.value) {
      this.dt1 = this.getDateCalender(event.value);
      this.cartform.get("orderReadyDate").setValue(this.dt1);
      this.storeDT();
    }
  }

  storeDT() {
    let DT = this.storeService.startAtDT;
    this.startAt = this.dt1;
    this.min = DT.min;
    this.max = DT.max;
  }

  private get vehicleInfo(): FormGroup {
    return this.cartform.get("customer").get("vehicleInfo") as FormGroup;
  }

  private get delivery_address(): FormGroup {
    return this.cartform.get("customer").get("deliveryAddress") as FormGroup;
  }

  cloneCart() {
    this.cartform.patchValue({ cartId: this.cart?.id });
    this.createCart();
  }

  initializeVehicle() {
    this.vehicle_brandsControl.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((res: any) => {
        this.updateVehicleForm(res, "make");
      });

    this.vehicle_typeControl.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((res: any) => {
        this.updateVehicleForm(res, "type");
      });
    this.vehicle_colorControl.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((res: any) => {
        this.updateVehicleForm(res, "color");
      });
  }

  updateVehicleForm(res, type) {
    if (res) {
      this.cartform
        .get("customer")
        .get("vehicleInfo")
        .get(type)
        .patchValue(res);
    } else {
      if (this.cartform.get("orderDestination").value == "DRIVE_UP_PICK_UP") {
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get(type)
          .patchValue("");
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get(type)
          .setValidators([Validators.required]);
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get(type)
          .updateValueAndValidity();
      }
    }
  }

  createCart() {
    if (this.cartform.valid) {
      this.cartform.patchValue({
        orderReadyDate: this.cartform.value.orderReadyDate
          ? this.getDateCalender(this.cartform.value.orderReadyDate)
          : null,
      });
      if (this.cartform.value.orderDestination.toUpperCase() == "DELIVERY") {
        if (this.isAddressValidate) {
          if (
            (this.deliveryAddress.zip &&
              this.deliveryAddress.state &&
              this.deliveryAddress.city) ||
            this.deliveryAddress.streetLine4
          ) {
            this.delivery_address.patchValue({
              streetLine1: this.deliveryAddress.streetLine1 ?? "",
              streetLine2: this.cartform.value.apt_suite ?? "",
              streetLine3: this.deliveryAddress.streetLine3 ?? "",
              streetLine4: this.deliveryAddress.streetLine4 ?? "",
              zip: this.deliveryAddress.zip,
              state: this.deliveryAddress.state,
              city: this.deliveryAddress.city,
            });
            this.updateOrder();
          } else {
            this.showErrorMessage({ message: "PLEASE VALIDATE ADDRESS" });
          }
        } else {
          this.updateOrder();
        }
        this.vehicleInfo.patchValue({
          make: "",
          type: "",
          color: "",
        });
      } else {
        if (this.cartform.value.orderDestination.toUpperCase() == "CARRYOUT") {
          this.vehicleInfo.patchValue({
            make: "",
            type: "",
            color: "",
          });
        } else {
          this.vehicleInfo.patchValue({
            make: this.vehicle_brandsControl.value,
            type: this.vehicle_typeControl.value,
            color: this.vehicle_colorControl.value,
          });
        }
        this.delivery_address.patchValue({
          streetLine1: "",
          streetLine2: "",
          streetLine3: "",
          streetLine4: "",
          zip: "",
          state: "",
          city: "",
        });
        this.updateOrder();
      }
    }
  }

  async updateOrder() {
    try {
      console.log(this.cartform.value);
      this.ngxService.start();
      this.cartService.cartUpdateEvent.next(this.cartform.value);
      this.dialogRef.close(null);
      // const cart: any = await lastValueFrom(
      //   this.cartService.updateCart(
      //     this.cartform.value,
      //     this.cart?.storeId,
      //     this._aiMenuService.aiParams.value
      //   )
      // );
      // console.log(cart);
      // if (cart?.error?.message) {
      //   this.showErrorMessage(cart);
      //   this.ngxService.stop();
      //   return;
      // }
      // this.closeDialog();
      // this.cartService.cartRefreshEvent.next(true);
      // this.notification.openSnackBarActionV2(
      //   "end",
      //   "top",
      //   "Cart has been updated",
      //   "success-snackbar"
      // );
      // this.ngxService.stop();
    } catch (error) {
      this.showErrorMessage(error);
      this.ngxService.stop();
    }
  }
  get orderDestination() {
    return this.cartform.get("orderDestination").hasError("required");
  }
  get firstNameRequired() {
    return this.cartform.get("customer").get("firstName").hasError("required");
  }
  get firstNamePattern() {
    return this.cartform.get("customer").get("firstName").hasError("pattern");
  }
  get lastNameRequired() {
    return this.cartform.get("customer").get("lastName").hasError("required");
  }
  get lastNamePattern() {
    return this.cartform.get("customer").get("lastName").hasError("pattern");
  }
  get phoneRequired() {
    return this.cartform.get("customer").get("phone").hasError("required");
  }
  get orderReadyDate() {
    return this.cartform.get("orderReadyDate").hasError("required");
  }

  ngOnDestroy() {
    this.isComponentAlive = false;
  }
}
