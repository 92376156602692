<div class="wrapper wrapper-full-page">
  <div class="full-page login-page" filter-color="black" data-image="../../assets/img/register.jpg">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="card card-login">
            <h1>SIP session <b>ON</b> in this window, please don't close</h1>
            <audio id="remoteAudio" class="position-ab"></audio>
            <div class="sip-status-container">
              <div class="sip-status">SIP register Status : <span [ngStyle]="{'color': registerContextStatus.color}">{{registerContextStatus.text || "-N/A-"}}</span></div>
              <div class="sip-status">Call Status : <span [ngStyle]="{'color': sipStatus.color}">{{sipStatus.text || "-N/A-"}}</span></div>
              <div class="sip-status">Call Session Status : <span [ngStyle]="{'color': sessionStatus.color}">{{sessionStatus.text || "-N/A-"}}</span></div>
            </div>  
          </div>
        </div>
      </div>
    </div>
    <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg)"></div>
  </div>
</div>