<div style="margin-top: 10px" *ngIf="cartState | async as cartData">
  <mat-spinner
    *ngIf="emitTrans.removeItemCart$ | async"
    style="position: absolute; top: 50%; left: 45%"
  >
  </mat-spinner>
  <mat-spinner
    *ngIf="emitTrans.updateCart$ | async"
    style="position: absolute; top: 50%; left: 45%"
  >
  </mat-spinner>
  <div *ngIf="cartData?.cartMenuItems.length > 0">
    <div class="d-flex" style="justify-content: space-between">
      <span style="margin-bottom: 10px" data-label="delivery_address"
        ><b>CART DETAILS</b></span
      >
      <button
        data-testid="clear-cart-button"
        [disabled]="orderLoaded"
        style="color: rgb(175, 27, 27)"
        matTooltip="CART EMPTY"
        mat-flat-button
        (click)="removeAllItem()"
      >
        <b>
          {{ "CLEAR ALL" }}
        </b>
      </button>
    </div>
    <div
      class="promo-chip-content"
      data-label="delivery_address"
      *ngIf="cartPromotions && cartPromotions?.length > 0"
    >
      <span
        class="promo-chip"
        [ngClass]="item?.isLoyaltyOffer && 'promo-loyalty-offer'"
        *ngFor="let item of cartPromotions"
        [matTooltip]="showToolTip(item)"
      >
      <mat-icon *ngIf="item?.isLoyaltyOffer" class="loyalty-offer-icon">loyalty</mat-icon>
        <b>
          {{ item.promoCode ?? item.offerId }}
        </b>
        <mat-icon
          *ngIf="!item?.autoApplied"
          class="promo-cancel"
          (click)="removePromo(item, cartPromotions)"
          >cancel</mat-icon
        >
      </span>
    </div>
    <div style="padding: 5px">
      <ng-container *ngFor="let item of cartData?.cartMenuItems; index as i">
        <div
          style="margin-bottom: 10px; margin-top: 10px"
          [attr.data-testid]="'cart-item-' + i"
        >
          <div
            class="d-flex"
            style="justify-content: space-between; position: relative"
          >
            <div style="width: 80%">
              <span
                style="
                  color: #536c79;
                  font-weight: 600;
                  font-size: medium;
                  margin-bottom: 5px;
                "
              >
                <b [attr.data-testid]="'item-display-name-' + i"
                  >{{ item.displayName }}
                  {{
                    item.halfSpecialties.length > 0 && item.halfSpecialties
                      ? "(HALF N HALF)"
                      : ""
                  }}</b
                >
              </span>

              <div style="margin-top: 10px">
                <span
                  *ngFor="let addon of activeModifiers(item); index as ind"
                  style="
                    max-width: 10px !important;
                    margin-top: 10px !important;
                  "
                >
                  <span
                    *ngIf="addon.alterationCategory != 'TOPPINGS'"
                    [attr.data-testid]="'addon-display-name-' + i + '-' + ind"
                  >
                    {{ addon.displayName }}
                    <b
                      *ngIf="
                        addon.alterationPortion &&
                        addon.alterationPortion != 'Normal'
                      "
                      [attr.data-testid]="
                        'addon-alteration-portion-' + i + '-' + ind
                      "
                    >
                      - ({{ addon.alterationPortion | uppercase }})
                    </b>
                    <span *ngIf="ind != activeModifiers(item).length - 1">{{
                      ","
                    }}</span>
                  </span>
                </span>
              </div>
            </div>
            <div style="position: absolute; right: 0">
              <span>
                <button
                  [attr.data-testid]="'edit-cart-item-button-' + i"
                  (click)="editItemFromCart(item)"
                  style="
                    color: rgb(71, 214, 71);
                    margin-top: -9px;
                    margin-left: 5px;
                  "
                  mat-flat-button
                >
                  <mat-icon class="mat-icon" role="img" aria-hidden="true"
                    >edit</mat-icon
                  >
                </button>
              </span>
              <button
                [attr.data-testid]="'remove-cart-item-button-' + i"
                [disabled]="orderLoaded"
                (click)="removeItem(item.productUUID)"
                style="
                  text-align: right;
                  color: rgb(175, 27, 27);
                  margin-top: -9px;
                "
                matTooltip="REMOVE ITEM FROM CART"
                mat-flat-button
              >
                <mat-icon class="mat-icon" role="img" aria-hidden="true"
                  >close</mat-icon
                >
              </button>

              <div style="text-align: right">
                <button
                  style="color: rgb(175, 27, 27); pointer-events: none"
                  mat-flat-button
                >
                  <span
                    *ngFor="
                      let addon of filterItemsOfType(item.modifiers);
                      index as ind
                    "
                  >
                    <b
                      *ngIf="!item.priceInfo.price && !item.priceInfo.price > 0"
                      [attr.data-testid]="'addon-price-' + i + '-' + ind"
                    >
                      {{
                        addon.priceInfo.price > 0
                          ? "  $" + (addon.priceInfo.price | number : "1.2-2")
                          : ""
                      }}</b
                    >
                  </span>
                </button>
                <button
                  *ngIf="item.priceInfo.price > 0"
                  style="color: rgb(175, 27, 27); pointer-events: none"
                  mat-flat-button
                >
                  <b [attr.data-testid]="'item-price-' + i">{{
                    item.priceInfo.price > 0
                      ? "  $" + (item.priceInfo.price | number : "1.2-2")
                      : ""
                  }}</b>
                </button>
              </div>
            </div>
          </div>

          <div
            style="margin-top: 10px"
            *ngIf="getToppings(item.modifiers) > 0"
            [attr.data-testid]="'toppings-section-' + i"
          >
            <span
              *ngIf="getDefaultToppings(item.modifiers) > 0"
              style="color: #536c79; font-weight: 500; font-size: medium"
            >
              <b> {{ "Your Default Toppings" }}</b>
            </span>
            <ng-container
              *ngFor="let addons of item.modifiers; index as modiIndex"
            >
              <div
                class="d-flex"
                style="justify-content: space-between; margin-top: 2px"
                *ngIf="
                  addons.isDefault &&
                  !addons.isRemoved &&
                  addons.alterationCategory == 'TOPPINGS'
                "
                [attr.data-testid]="'default-topping-' + i + '-' + modiIndex"
              >
                <span>
                  {{ addons.displayName }} ({{
                    addons.alterationQuantity | uppercase
                  }})
                  <b
                    *ngIf="
                      addons.alterationPortion &&
                      addons.alterationPortion != 'Normal'
                    "
                  >
                    - ({{ addons.alterationPortion | uppercase }})
                  </b>
                </span>
                <button
                  style="
                    color: rgb(175, 27, 27);
                    pointer-events: none;
                    margin-top: -8px;
                  "
                  mat-flat-button
                >
                  <b
                    [attr.data-testid]="
                      'default-topping-price-' + i + '-' + modiIndex
                    "
                  >
                    {{
                      addons.priceInfo.price > 0
                        ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                        : ""
                    }}
                  </b>
                </button>
              </div>
            </ng-container>
            <span
              *ngIf="getRemovedDToppings(item.modifiers) > 0"
              style="color: #536c79; font-weight: 500; font-size: medium"
            >
              <b> {{ "Your Removed Default Toppings" }}</b>
            </span>
            <ng-container
              *ngFor="let addons of item.modifiers; index as modiIndex"
            >
              <div
                class="d-flex"
                style="justify-content: space-between; margin-top: 2px"
                *ngIf="
                  addons.alterationCategory == 'TOPPINGS' && addons.isRemoved
                "
                [attr.data-testid]="
                  'removed-default-topping-' + i + '-' + modiIndex
                "
              >
                <span>
                  {{ addons.displayName }} ({{
                    addons.alterationQuantity | uppercase
                  }})
                  <b
                    *ngIf="
                      addons.alterationPortion &&
                      addons.alterationPortion != 'Normal'
                    "
                  >
                    - ({{ addons.alterationPortion | uppercase }})
                  </b>
                </span>
                <button
                  style="
                    color: rgb(175, 27, 27);
                    pointer-events: none;
                    margin-top: -8px;
                  "
                  mat-flat-button
                >
                  <b
                    [attr.data-testid]="
                      'removed-default-topping-price-' + i + '-' + modiIndex
                    "
                  >
                    {{
                      addons.priceInfo.price > 0
                        ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                        : ""
                    }}
                  </b>
                </button>
              </div>
            </ng-container>
            <span
              *ngIf="getDefault(item.modifiers) > 0"
              style="color: #536c79; font-weight: 500; font-size: medium"
            >
              <b> {{ "Your Customisations" }}</b>
            </span>
            <ng-container
              *ngFor="let addons of item.modifiers; index as modiIndex"
            >
              <div
                class="d-flex"
                style="justify-content: space-between; margin-top: 2px"
                *ngIf="addons.alterationCategory && addons.isCustomised"
                [attr.data-testid]="
                  'customisation-topping-' + i + '-' + modiIndex
                "
              >
                <span data-testid="Customisations-toppings">
                  {{ addons.displayName }} ({{
                    addons.alterationQuantity | uppercase
                  }})
                  <b
                    *ngIf="
                      addons.alterationPortion &&
                      addons.alterationPortion != 'Normal'
                    "
                  >
                    - ({{ addons.alterationPortion | uppercase }})
                  </b>
                </span>
                <button
                  style="
                    color: rgb(175, 27, 27);
                    pointer-events: none;
                    margin-top: -8px;
                  "
                  mat-flat-button
                >
                  <b
                    [attr.data-testid]="
                      'customisation-topping-price-' + i + '-' + modiIndex
                    "
                  >
                    {{
                      addons.priceInfo.price > 0
                        ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                        : ""
                    }}
                  </b>
                </button>
              </div>
            </ng-container>
          </div>

          <div
            class="d-flex"
            style="justify-content: space-between; margin-top: 10px"
          >
            <span
              style="
                color: #536c79;
                font-weight: 400;
                font-size: medium;
                margin-top: 7px;
              "
            >
              {{ "Quantity" }}
            </span>

            <span>
              <input
                [attr.data-testid]="'cart-item-quantity-input-' + i"
                [disabled]="orderLoaded"
                type="text"
                #quantity
                [value]="item.quantity"
                onkeydown="return ( event.ctrlKey || event.altKey 
              || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
              || (95<event.keyCode && event.keyCode<106)
              || (event.keyCode==8) || (event.keyCode==9) 
              || (event.keyCode>34 && event.keyCode<40) 
              || (event.keyCode==46) )"
              />
              <button
                [attr.data-testid]="'update-cart-item-quantity-button-' + i"
                [disabled]="orderLoaded"
                matTooltip="UPDATE QUANTITY"
                style="color: rgb(175, 27, 27)"
                mat-flat-button
                (click)="updateQuantity(item, quantity.value)"
              >
                <i class="material-icons">done</i>
              </button>
            </span>
          </div>
          <div
            class="d-flex"
            style="justify-content: space-between; margin-top: 10px"
            *ngIf="storeDetailV2?.store?.storeSpecific?.showItemLevelNotes"
          >
            <span
              style="
                color: #536c79;
                font-weight: 400;
                font-size: medium;
                margin-top: 7px;
              "
            >
              {{ "Item Notes" }}
            </span>

            <span>
              <input
                type="text"
                #itemNotes
                [(ngModel)]="item.notes"
                [value]="item.notes"
                [attr.data-testid]="'item-notes-input-' + i"
              />
              <button
                [attr.data-testid]="'update-notes-button-' + i"
                matTooltip="UPDATE NOTES"
                style="color: rgb(175, 27, 27)"
                mat-flat-button
                (click)="updateNotes(item, itemNotes.value)"
              >
                <i class="material-icons">done</i>
              </button>
            </span>
          </div>
          <vx-promo-autocomplete
            [item]="item"
            *ngIf="storeDetailV2?.store?.storeSpecific?.showItemLevelPromotions"
            (applyPromo)="applyPromo($event)"
          ></vx-promo-autocomplete>
          <marquee>
            <b style="color: rgb(175, 27, 27)">
              {{ item?.status?.message | uppercase }}</b
            >
          </marquee>
          <div class="d-flex" style="justify-content: space-between">
            <button
              [attr.data-testid]="'toggle-details-button-' + i"
              mat-stroked-button
              (click)="showDetails(i)"
              style="color: rgb(71, 214, 71)"
            >
              {{ isShowed.includes(i) ? "Hide Details" : "View Details" }}
            </button>
            <button
              [attr.data-testid]="'papa-size-button-' + i"
              *ngIf="
                getPapaSize(item.modifiers).length > 0 &&
                item.halfSpecialties.length == 0
              "
              (click)="sendPapaSize(item)"
              mat-stroked-button
              [ngStyle]="{
                color: item.papaSizing
                  ? ' rgb(175, 27, 27)'
                  : 'rgb(71, 214, 71)'
              }"
            >
              {{
                item.papaSizing
                  ? "Remove PapaSize" +
                    " " +
                    getPapaSizeName(item.modifiers) +
                    "-" +
                    getPapaSizePrice(item.modifiers)
                  : "Make It" +
                    " " +
                    getPapaSizeName(item.modifiers) +
                    "-" +
                    getPapaSizePrice(item.modifiers)
              }}
            </button>
          </div>
          <ng-container
            *ngFor="let lineItem of item.ledger?.expectedLedger?.subLineItems"
          >
            <div
              *ngIf="isShowed.includes(i)"
              class="d-flex"
              style="justify-content: space-between; margin-top: 10px"
            >
              <span style="color: #536c79; font-weight: 400; font-size: medium">
                {{
                  lineItem.subLineItemType == "PRODUCT"
                    ? lineItem.itemName + "(with AddOns)"
                    : lineItem.itemName
                }}
              </span>

              <button
                [attr.data-testid]="'regular-menu-viewprice-' + i"
                class="mr-1"
                style="
                  color: rgb(175, 27, 27);
                  margin-top: -8px;
                  pointer-events: none;
                "
                mat-flat-button
              >
                {{
                  lineItem.regularMenuPrice
                    ? "$" + (lineItem.regularMenuPrice | number : "1.2-2")
                    : "$" + (lineItem.price | number : "1.2-2")
                }}
              </button>
            </div>
          </ng-container>

          <div
            *ngIf="isShowed.includes(i)"
            class="d-flex"
            style="justify-content: space-between; margin-top: 7px"
          >
            <span style="color: #536c79; font-weight: 400; font-size: medium">
              {{ "Total" }}
            </span>

            <button
              [attr.data-testid]="'total-price-viewButton-' + i"
              class="mr-1"
              style="
                color: rgb(175, 27, 27);
                margin-top: -8px;
                pointer-events: none;
              "
              mat-flat-button
            >
              {{
                "$" +
                  (item.ledger?.expectedLedger?.lineItemPrice
                    | number : "1.2-2")
              }}
            </button>
          </div>

          <div style="padding: 5px">
            <hr />
          </div>
        </div>
      </ng-container>
      <div
        class="d-flex"
        style="justify-content: space-between; margin-top: 10px"
        *ngIf="storeDetailV2?.store?.storeSpecific?.showOrderLevelNotes"
      >
        <span
          style="
            color: #536c79;
            font-weight: 400;
            font-size: medium;
            margin-top: 7px;
          "
        >
          {{ "Order Notes" }}
        </span>

        <span>
          <input
            [attr.data-testid]="'order-notes-input-' + orderIndex"
            type="text"
            [(ngModel)]="cartData.notes"
            #orderNotes
            [value]="cartData.notes"
            style="margin-bottom: 6px"
          />
          <button
            [attr.data-testid]="'update-order-notes-button-' + orderIndex"
            matTooltip="UPDATE ORDER NOTES"
            style="color: rgb(175, 27, 27)"
            mat-flat-button
            (click)="updateOrderNotes(orderNotes.value)"
          >
            <i class="material-icons">done</i>
          </button>
        </span>
      </div>
      <ng-container
        *ngFor="
          let lineItem of cartData?.cartLedger?.expectedLedger?.subLineItems
        "
      >
        <div
          class="d-flex"
          style="justify-content: space-between; margin-top: 2px"
          *ngIf="lineItem.price != 0"
        >
          <span style="color: #536c79; font-weight: 400; font-size: medium">
            {{
              lineItem.subLineItemType == "PRODUCT"
                ? lineItem.itemName + "(with AddOns)"
                : lineItem.itemName
            }}
          </span>

          <button
            [attr.data-testid]="'regular-menu-price-' + lineItem"
            class="mr-1"
            style="
              color: rgb(175, 27, 27);
              margin-top: -8px;
              pointer-events: none;
            "
            mat-flat-button
          >
            {{
              lineItem.regularMenuPrice
                ? "$" + (lineItem.regularMenuPrice | number : "1.2-2")
                : "$" + (lineItem.price | number : "1.2-2")
            }}
          </button>
        </div>
      </ng-container>
      <div
        *ngIf="cartData?.cartLedger"
        class="d-flex"
        style="justify-content: space-between; margin-top: 2px"
      >
        <span style="color: #536c79; font-weight: 400; font-size: medium">
          {{ "Total" }}
        </span>

        <button
          data-testid="line-item-total-price"
          class="mr-1"
          style="
            color: rgb(175, 27, 27);
            margin-top: -8px;
            pointer-events: none;
          "
          mat-flat-button
        >
          {{
            "$" +
              (cartData?.cartLedger?.expectedLedger?.lineItemPrice
                | number : "1.2-2")
          }}
        </button>
      </div>
    </div>
  </div>
</div>
