import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavbarService } from 'app/core/navbar/navbar.service';

@Component({
  selector: 'app-chat-drawer',
  templateUrl: './chat-drawer.component.html',
  styleUrls: ['./chat-drawer.component.scss']
})
export class ChatDrawerComponent implements OnInit{
  isOpenDrawer = false;
   @Input() conversationSoFar;
  constructor(public navbarService: NavbarService){

  }
  ngOnInit() {
    this.navbarService.chatDrawer$.subscribe(value => {
      this.isOpenDrawer = value;
    })
    console.log(this.conversationSoFar)
  }

  onClosed() {
    this.navbarService.emitDrawerToggle(!this.navbarService.chatDrawerSub.getValue());
  }
}
