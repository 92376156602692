import {OnInit, NgModule, EventEmitter, Injectable, Output, Component, Compiler, ViewContainerRef, ViewChild, Input, ComponentRef, ComponentFactory, ComponentFactoryResolver, ChangeDetectorRef} from '@angular/core';
import { AppConstants } from '../../../app.constants';
import * as moment from 'moment';

declare const $: any;
@Component({
  selector: 'app-gs-daterange',
  templateUrl: './gs-daterange.component.html',
  styleUrls: ['./gs-daterange.component.css']
})
export class GsDaterangeComponent implements OnInit {
  timezone = AppConstants.TIMEZONE_OFFSET;
  @Output() dateChanges: EventEmitter<any> = new EventEmitter();
  constructor() { }
  @Input('monthshort') shortMonth:string = 'MMMM';
  @Input('direction') direction:string = 'left';
  startDate:any = moment().utcOffset(this.timezone).set({hours:0})
  endDate:any = moment().utcOffset(this.timezone);
  
  ngOnInit() {   
    var start = this.startDate;
    var end = this.endDate;
    this.cb(start, end);
    
  }

  cb(start, end): void {
    if(start && end) {
      this.dateSelector(start, end);
      $('#reportrange span').html(start.format(this.shortMonth + ' D, YYYY') + ' - ' + end.format(this.shortMonth +' D, YYYY'));
    }
  }

  dateSelector(start, end) {
    $('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        opens: this.direction,
        maxDate: moment().utcOffset(this.timezone),
        ranges: {
           'Today': [moment().utcOffset(this.timezone).set({hours:0}), moment().utcOffset(this.timezone)],
           'Yesterday': [moment().utcOffset(this.timezone).subtract(1, 'days'), moment().utcOffset(this.timezone).subtract(1, 'days')],
           'Last 7 Days': [moment().utcOffset(this.timezone).subtract(6, 'days'), moment().utcOffset(this.timezone)],
           'Last 30 Days': [moment().utcOffset(this.timezone).subtract(29, 'days'), moment().utcOffset(this.timezone)],
           'This Month': [moment().utcOffset(this.timezone).startOf('month'), moment().utcOffset(this.timezone).endOf('month')],
           'Last Month': [moment().utcOffset(this.timezone).subtract(1, 'month').startOf('month'), moment().utcOffset(this.timezone).subtract(1, 'month').endOf('month')]
        }
    });
    var that = this;
    $('#reportrange').on('apply.daterangepicker', function(ev, picker) {
      //console.log(picker.startDate, picker.endDate);
      that.cb(picker.startDate, picker.endDate);
      that.dateChanges.emit(picker);
    });
  }
}
