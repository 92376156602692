<h1 mat-dialog-title align="center">PROMO BULK REPORT</h1>
<div mat-dialog-content>
  <div class="main">
    <div class="total-section">
      <div class="circle">{{data?.total || 0}}</div>
      <div class="text">Total</div>
    </div>
    <div class="success-section">
      <div class="circle">{{data?.success || 0}}</div>
      <div class="text">Success</div>
    </div>
    <div class="error-section">
      <div class="circle">{{data?.error || 0}}</div>
      <div class="text">Error</div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Ok</button>
</div>