<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" filter-color="black" data-image="../../assets/img/register.jpg">
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <form method="#" action="#">
                                <div class="card card-login">
                                    <div class="text-center">
                                        <div class="logo-img">
                                            <img src="/assets/img/voixai.png"/>
                                        </div>
                                        <div class="logo-img text-logo-sub">
                                            <img class="text-logo" src="/assets/img/logo-text-voix.png" />
                                        </div>
                                        <h3 class="card-title mt-40 {{statusClass}}">{{activationHeadText}}</h3>
                                    </div>
                                    <div class="card-content">
                                        <!-- <p class="text-center"> You have requested to reset the password for: <strong>"{{userDetails?.email}}"</strong></p>

                                        <h3 class="text-danger">Sorry, this change password link is not valid.</h3> -->

                                    </div>
                                    
                                    <hr>
                                    
                                    <div class="w-full text-center p-t-55">
                                        <span class="txt2">
                                            Back to
                                        </span>

                                        <a routerLink='/login' class="txt2 bo1">
                                            Sign in
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg)"></div>
    </div>
</div>
      
      

