import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'isAdminDomain'
})
export class IsAdminDomainPipe implements PipeTransform {
  constructor() { }
  transform(): boolean {
    const adminUrl = environment?.['admin']?.['url'];
    return window.location.origin.includes(adminUrl);
  }
}

