import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { ActionAddSelectedItem } from "app/core/ngxs/action/menu.action";
import { ActionSelectedModifiers1 } from "app/core/ngxs/action/menuSelection.action";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import {
  customerState,
  customerDefaultState,
} from "app/core/ngxs/state/customer.state";
import { menuState, menuDefaultState } from "app/core/ngxs/state/menu.state";
import { NotificationService } from "app/core/notification/notification.service";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { menuService } from "app/core/services/menu.service";
import { AddOn_Variations } from "../../../../mediator-commlibs/interfaces/response/menu.interface";

import { Observable } from "rxjs";
import * as _ from "underscore";
import { generalService } from "app/core/services/general.service";
import { Modifier } from "../../../../mediator-commlibs/interfaces/request/cartItem.interface";

//addonVariation Match condition
//externalId2, alterationCategory
@Component({
  selector: "app-order-items",
  templateUrl: "./order-items.component.html",
  styleUrls: ["../order-items/order-items.component.scss"],
})
export class OrderItemsComponent implements OnInit {
  @Select(menuState.getSelectedItem)
  selectedMenuItem: Observable<menuDefaultState>;

  @Select(customerState.getCustomer)
  getCustomerDetail: Observable<customerDefaultState>;

  @Select(menuState.getSelectedCollection)
  selectedMenuCollections: Observable<menuDefaultState>;
  @Select(cartState.getLoadedCart)
  cartLoadedState: Observable<cartDefaultState>;

  @Input() restaurant_details: any;
  @Input() menusV2: any;

  _: any = _;
  cartItem: any = {};
  isQuantityValid: boolean = false;
  selectedCategory: any = {};
  items: any = [];
  modifiers: any = [];
  collections: any = [];
  collectionsModifiers: any = [];
  collectionsModifierscollections: any = [];
  collectionsModifierscollectionsmodifiers: any = [];
  collectionsModifierscollectionsmodifierscollections: any = [];
  selectedAddons: any = [];
  isDefaultModifiers: any = [];
  selectedCollections: any = {};
  selectedCollectionsModifierscollections: any = {};
  selectedAvailableQuantity: string = "";
  menuItemsV2: any;
  isQuantityShow: boolean = false;
  selectedToppings: string = "";
  availableQuantities: Modifier[] | any = [];
  WHOLE: string = "WHOLE";
  LEFT_HALF: string = "LEFT_HALF";
  RIGHT_HALF: string = "RIGHT_HALF";
  selectedQuantity: any = [];
  notToppings: any = [];
  alterationCategoryName = "TOPPINGS";
  topping_quantity: number = 0;
  modifiersCollection: any = [];
  selectedModifier: any = {};
  toppings: any = [];
  notAllowMultiple: any = [];
  isQuantityShowed: boolean = false;
  selectedProductQuantity: any;
  selectedItem: any = {};
  quantity: any = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
    {
      name: "4",
      value: 4,
    },
    {
      name: "5",
      value: 5,
    },
    {
      name: "6",
      value: 6,
    },
    {
      name: "7",
      value: 7,
    },
    {
      name: "8",
      value: 8,
    },
    {
      name: "9",
      value: 9,
    },
    {
      name: "10",
      value: 10,
    },
  ];
  quantityHalf: any = [
    {
      name: "HALF",
      value: 0.5,
    },
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
    {
      name: "4",
      value: 4,
    },
    {
      name: "5",
      value: 5,
    },
    {
      name: "6",
      value: 6,
    },
    {
      name: "7",
      value: 7,
    },
    {
      name: "8",
      value: 8,
    },
    {
      name: "9",
      value: 9,
    },
    {
      name: "10",
      value: 10,
    },
  ];
  productQuantity: number = 1;
  collmodifiers: any = [];
  isEdit: boolean = false;
  editModifer: any;
  customerLoyaltyFlag: boolean = false;
  replicaModifiers: any = [];
  @Output() noteEvent = new EventEmitter<object>();
  constructor(
    private menuservice: menuService,
    private ngxsStore: Store,
    private notification: NotificationService,
    private cryptoService: CryptoService,
    private emitTrans: emitTransactionsService,
    private changeDetector: ChangeDetectorRef,
    private _generalService: generalService
  ) {
    this.menuservice.EditItem.subscribe((data: any) => {
      if (data) {
        console.log(data);
        this.isEdit = data.isEdit;

        if (data.product.product_menu && data.product.product_menu.length > 0) {
          this.editModifer = data.product.product_modifiers.filter(
            (item: any) => item.refCode && !item.refCode.startsWith("-")
          );
          this.editModifer = this.editModifer.filter((n) => n);

          this.cryptoService.Encrypting("left_mod", this.editModifer);

          //console.log(this.editModifer);
          // console.log(data.product.product_modifiers);
          this.menusV2 = data.product.product_menu;
          this.productQuantity = data.product.quantity;
          this.menusV2.forEach((element) => {
            if (element.isDefault) {
              this.selectItemV2(element, false);
            }
          });
        }
        if (
          data.product.left_half_menu &&
          data.product.left_half_menu.length > 0
        ) {
          this.allowHalf = true;
          this.editModifer = data.product.left_modifiers.filter(
            (item: any) => item.refCode && !item.refCode.includes("-")
          );
          this.cryptoService.Encrypting("left_mod", this.editModifer);

          this.menusV2 = data.product.left_half_menu;
          this.menusV2.forEach((element) => {
            if (element.isDefault) {
              this.selectItemV2(element, false);
            }
          });
          this.productQuantity = 0.5;
        }
      }
    });
    this.menuservice.halfMenu.subscribe((res: any) => {
      if (res) {
        if (res.left_menu != null) {
          this.removeAllV2(true);
          this.menusV2 = res.left_menu;
          const selectedCategory = this.menusV2.filter(
            (category) => category.isAvailable
          );
          this.productQuantity = 0.5;
          this.allowHalf = true;
          if (selectedCategory.length && selectedCategory.length === 1) {
            this.selectItemV2(selectedCategory[0], false);
          }
          this.menusV2.forEach((element) => {
            if (element.isDefault) {
              this.selectItemV2(element, false);
            }
          });
        }
      }
    });
  }
  ngOnInit() {
    this.getMenus(this.restaurant_details);
    this.sendtoservice();
    this.menuservice.removecart.subscribe(
      (data) => {
        if (data) {
          this.removeAllV2(false);
        }
      },
      (errr) => {},
      () => {
        this.menuservice.removecart.next(false);
        this.removeAllV2(false);
      }
    );
  }

  setModifiers(payload: any) {
    this.ngxsStore.dispatch(new ActionSelectedModifiers1(payload));
  }

  sendtoservice() {
    this.menuservice.allData.next({
      selectedItem: this.selectedItem,
      cartItem: this.cartItem,
      isQuantityValid: this.isQuantityValid,
      selectedCategory: this.selectedCategory,
      items: this.items,
      modifiers: this.modifiers,
      collections: this.collections,
      collectionsModifiers: this.collectionsModifiers,
      collectionsModifierscollections: this.collectionsModifierscollections,
      collectionsModifierscollectionsmodifiers:
        this.collectionsModifierscollectionsmodifiers,
      collectionsModifierscollectionsmodifierscollections:
        this.collectionsModifierscollectionsmodifierscollections,
      selectedAddons: this.selectedAddons,
      isDefaultModifiers: this.isDefaultModifiers,
      selectedCollections: this.selectedCollections,
      selectedAvailableQuantity: this.selectedAvailableQuantity,
      menuItemsV2: this.menuItemsV2,
      isQuantityShow: this.isQuantityShow,
      selectedToppings: this.selectedToppings,
      availableQuantities: this.availableQuantities,
      WHOLE: this.WHOLE,
      LEFT_HALF: this.LEFT_HALF,
      RIGHT_HALF: this.RIGHT_HALF,
      selectedQuantity: this.selectedQuantity,
      selectedStyle: this.selectedStyle,
      notToppings: this.notToppings,
      alterationCategoryName: this.alterationCategoryName,
      topping_quantity: this.topping_quantity,
      toppings: this.toppings,
      isEdit: this.isEdit,
      notAllowMultiple: this.notAllowMultiple,
      isQuantityShowed: this.isQuantityShowed,
      selectedProductQuantity: this.selectedProductQuantity,
      quantity: this.quantity,
      available: this.available,
      productQuantity: this.productQuantity,
    });
  }
  selectToppings(item: any, index: number) {
    this.sendtoservice();
  }

  selectProductQuantity(quantity: any) {
    this.productQuantity = quantity;
    if (quantity == 0.5) {
      this.allowHalf = true;
      this.cryptoService.Encrypting("sessionmodifiers", this.collmodifiers);
      this.cryptoService.Encrypting(
        "sizer",
        this.selectedCollections.displayName
      );
      this.cryptoService.Encrypting("styleItemStyle", this.selectedStyle);
      this.cryptoService.Encrypting("Item", this.selectedItem);
      var data = {
        selectedItem2: this.selectedItem,
        cartItem2: this.cartItem,
        isQuantityValid2: this.isQuantityValid,
        selectedCategory2: {},
        items2: [],
        modifiers2: this.modifiers,
        collections2: this.collections,
        collectionsModifiers2: this.collectionsModifiers,
        collectionsModifierscollections2:
          this.collectionsModifierscollections.filter(
            (data) => data.externalId1 != this.selectedStyle.externalId1
          ),
        collectionsModifierscollectionsmodifiers2: this.collmodifiers,
        collectionsModifierscollectionsmodifierscollections2:
          this.collectionsModifierscollectionsmodifierscollections,
        selectedAddons2: this.selectedAddons,
        isDefaultModifiers2: this.isDefaultModifiers,
        selectedCollections2: this.selectedCollections,
        selectedAvailableQuantity2: this.selectedAvailableQuantity,
        menuItemsV22: [],
        isQuantityShow2: this.isQuantityShow,
        selectedToppings2: this.selectedToppings,
        availableQuantities2: this.availableQuantities,
        WHOLE2: this.WHOLE,
        LEFT_HALF2: this.LEFT_HALF,
        RIGHT_HALF2: this.RIGHT_HALF,
        allowHalf2: true,
        selectedStyle2: this.selectedStyle,
        selectedQuantity2: this.selectedQuantity,
        notToppings2: this.notToppings,
        alterationCategoryName2: this.alterationCategoryName,
        topping_quantity2: this.topping_quantity,
        toppings2: this.toppings,
        notAllowMultiple2: this.notAllowMultiple,
        isQuantityShowed2: this.isQuantityShowed,
        selectedProductQuantity2: this.selectedProductQuantity,
        quantity2: this.quantity,
        availabe: this.available,
        productQuantity2: this.productQuantity,
      };
      this.setModifiers(data);
      this.emitTrans.halfMenuTrigger({
        itemName: this.selectedItem.displayName,
        size: this.selectedCollections.displayName,
        productSubType: this.selectedStyle.productSubType,
        externalId1: this.selectedStyle.externalId1,
        externalId2: this.selectedStyle.externalId2,
        externalId3: this.selectedStyle.externalId3,
        externalId4: this.selectedStyle.externalId4 || "",
        productSubCategory: this.selectedStyle.productSubCategory,
        match: true,
        identifiers: this.availableQuantities.filter(
          (res: any) =>
            res.alterationQuantity && res.alterationCategory != "TOPPINGS"
        ),
        loader: true,
      });
    } else {
      this.allowHalf = false;
    }

    this.sendtoservice();
  }

  getMenus(payload: any) {
    this.sendtoservice();
  }

  addToCartV2(selectedItem: any) {
    this.cartItem = Object.assign({}, selectedItem);

    this.cartItem.modifiers[0].collections = [this.selectedCollections.slice()];
    this.sendtoservice();
  }

  selectSizeV2(item: any, verified: boolean) {
    const selectedMenuItems = this.selectedCategory.items.filter(
      (val) => val.isAvailable
    );
    if (
      selectedMenuItems.length &&
      selectedMenuItems.length === 1 &&
      this.allowHalf
    ) {
      this.selectedItem = selectedMenuItems[0];
      this.modifiers = selectedMenuItems[0].modifiers;
      this.collections = selectedMenuItems[0].modifiers[0].collections;
      this.selectStyleVariationV2(this.collections[0], false);
      return;
    }
    if (!verified) {
      for (let index = 0; index < item.items.length; index++) {
        const element = item.items[index];
        if (element.isDefault || item.items.length === 1) {
          this.selectedItem = element;
          this.modifiers = element.modifiers;
          this.collections = element.modifiers[0].collections;
        }
      }
    } else {
      this.ngxsStore.dispatch(new ActionAddSelectedItem(item));
      this.selectedItem = item;
      this.modifiers = item.modifiers;
      this.collections = item.modifiers[0].collections;
      if (this.allowHalf) {
        this.selectStyleVariationV2(item, false);
        return;
      }
    }
    let isDefault: boolean = false;
    if (this.collections.length == 1) {
      this.selectStyleVariationV2(this.collections[0], false);
      isDefault = true;
    }
    if (!this.isEdit) {
      if (this.allowHalf) {
        this.collections.forEach((element) => {
          if (element.isDefault) {
            this.selectStyleVariationV2(element, false);
          }
        });
      } else {
        if (isDefault == false) {
          this.removeFromStyleVariationV2(verified);
        }
      }
    } else {
      this.collections.forEach((element) => {
        if (element.isDefault) {
          this.selectStyleVariationV2(element, false);
          isDefault = true;
        }
      });
    }
    this.sendtoservice();
  }

  removeAllV2(isHAlf: boolean, removeLeftFormSelectedItems = false) {
    this.noteEvent.emit({
      note: "",
      isLeft: true,
    });
    this.selectedItem = {};
    this.menuservice.secondFormReset.next(true);
    this.selectedCategory = {};
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.items = [];
    this.modifiers = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collections = [];
    this.collectionsModifiers = [];
    this.collmodifiers = [];
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.cartItem = {};
    this.isDefaultModifiers = [];
    this.isEdit = false;
    this.emitTrans.isEditItem(false);
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle = undefined;
    if (isHAlf) {
      this.productQuantity = 0.5;
      this.isProductHalf = false;
    } else {
      this.emitTrans.getMenu$.subscribe((menus: any) => {
        if (menus) {
          this.menusV2 = [];

          setTimeout(() => {
            this.menusV2 = menus;
          }, 0);
        }
      });
      this.allowHalf = false;
      this.productQuantity = 1;
    }
    if(removeLeftFormSelectedItems) {
      this.menuservice.removeLeftFormSelectedItems.next(true);
    }
    this.sendtoservice();
  }
  showdata() {
    const name = {
      items: this.items,
      modifiers: this.modifiers,
      collections: this.collections,
      collectionsModifiers: this.collectionsModifiers,
      collectionsModifierscollections: this.collectionsModifierscollections,
      collectionsModifierscollectionsmodifiers:
        this.collectionsModifierscollectionsmodifiers,
      availableQuantities: this.availableQuantities,
      collectionsModifierscollectionsmodifierscollections:
        this.collectionsModifierscollectionsmodifierscollections,
      cartItem: this.cartItem,
      isDefaultModifiers: this.isDefaultModifiers,
      selectedCategory: this.selectedCategory,
      selectedCollections: this.selectedCollections,
      isQuantityShow: this.isQuantityShow,
      isQuantityShowed: this.isQuantityShowed,
      selectedStyle: this.selectedStyle,
      collmodifiers: this.collmodifiers,
    };
    console.log(name);
  }
  removeFromItemsV2() {
    this.modifiers = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collections = [];
    this.collectionsModifiers = [];
    this.collmodifiers = [];
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.cartItem = {};
    this.selectedItem = {};
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle = undefined;
    this.productQuantity = 1;
    this.isProductHalf = false;
    this.allowHalf = false;
    this.sendtoservice();
  }

  removeFromSizeVariationV2() {
    this.modifiers = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collectionsModifiers = [];
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers = [];
    this.collmodifiers = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.notAllowMultiple = [];
    this.toppings = [];
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle = undefined;
    this.allowHalf = false;
    this.isProductHalf = false;
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.sendtoservice();
  }

  removeFromStyleVariationV2(verified) {
    if (verified) {
      this.selectedCollectionsModifierscollections = {};
    }
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collectionsModifiers = [];
    this.collectionsModifierscollectionsmodifiers = [];
    this.collmodifiers = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.toppings = [];
    this.notAllowMultiple = [];
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle = undefined;
    this.allowHalf = false;
    this.isProductHalf = false;
    this.sendtoservice();
  }

  removeFromAddonsV2() {
    this.collectionsModifierscollectionsmodifiers = [];
    this.collmodifiers = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.isQuantityShow = false;
    this.notAllowMultiple = [];
    this.isQuantityShowed = false;
    this.selectedStyle = undefined;
    this.toppings = [];
    this.sendtoservice();
  }

  removeFromAvailableQuantityV2() {
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.isQuantityShow = false;
    this.sendtoservice();
  }

  removeFromAddonsVariationsV2() {
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.sendtoservice();
  }

  removeAddonsV2() {
    this.cartItem.modifiers[0].collections[0].modifiers[0].collections = [];
    this.sendtoservice();
  }

  selectItemV2(category: any, verified: boolean) {
    this.selectedCategory = category;
    this.items = category.items;
    if (!verified || this.items.length === 1) {
      this.selectSizeV2(this.selectedCategory, false);
    } else {
      this.removeFromSizeVariationV2();
    }

    this.sendtoservice();
  }

  //SIZE Variations
  selectStyleVariationV2(collection: any, verified: boolean) {
    const selectedCollections = this.collections.filter(
      (val) => val.isAvailable
    );
    if (
      selectedCollections.length &&
      selectedCollections.length === 1 &&
      this.allowHalf
    ) {
      this.selectedCollections = selectedCollections[0];
      const selectedCollectionsModifierscollections =
        this.selectedCollections.modifiers[0].collections.filter(
          (val) => val.isAvailable
        );
      if (
        selectedCollectionsModifierscollections.length &&
        selectedCollectionsModifierscollections.length === 1
      ) {
        this.selectedCollectionsModifierscollections =
          selectedCollectionsModifierscollections[0];
        this.collectionsModifierscollections =
          this.selectedCollections.modifiers[0].collections.filter((n) => n);
        this.selectAddOnsV2(
          this.selectedCollectionsModifierscollections,
          false
        );
      }
      return;
    }
    this.availableQuantities = [];
    while (this.availableQuantities.length > 0) {
      this.availableQuantities.pop();
    }
    this.collections = this.collections.filter(
      (data: any, i: any) => data.displayName != collection.displayName
    );
    // console.log("...Size Variation", this.availableQuantities);
    this.collections.unshift(collection);
    this.collectionsModifiers = collection.modifiers;
    this.collectionsModifierscollections =
      collection.modifiers[0].collections.filter((n) => n);
    this.selectedCollections = collection;
    let isDefault: boolean = false;

    this.collectionsModifierscollections.forEach((element) => {
      if (
        (element.isDefault &&
          this.collectionsModifierscollections.length == 1) ||
        (element.isDefault && this.isEdit) ||
        (element.isDefault && this.allowHalf)
      ) {
        this.notAllowMultiple = [];
        this.toppings = [];
        element.isDefault = true;
        this.selectAddOnsV2(element, false);
        isDefault = true;
      }
    });
    if (isDefault == false) {
      this.removeFromAddonsV2();
    }
    this.sendtoservice();
  }
  setAvailablePortions(item: AddOn_Variations) {
    console.log(item.alterationPortion);
  }
  availablePortions = {
    None: "None",
    Lite: "Lite",
    Normal: "Normal",
    Extra: "Extra",
    Side: "Side",
    Double: "Double",
    Triple: "Triple",
  };

  addExtra(
    item: AddOn_Variations,
    max: number,
    alterationQuantity: any,
    isVerified: boolean,
    isAlterationPortionChange: boolean = false
  ) {
    alterationQuantity =
      alterationQuantity == undefined ? "" : alterationQuantity;

    const totalItem = this.availableQuantities.filter((data) => (
      (this._generalService?.isToppingsCountSeparated && item.alterationCategory == "TOPPINGS" ? data.alterationType == item.alterationType : true) &&
      (data.alterationCategory === item.alterationCategory) && (item.availablePortions?.length ? data.externalId2 != item.externalId2 : true)
    )).length;

    if (alterationQuantity == "") {
      if (item.availableQuantities.length > 0) {
        item.alterationQuantity = item.availableQuantities[0];
        alterationQuantity = item.alterationQuantity;
      }
    }
    if (!alterationQuantity) {
      if (
        this.allowHalf &&
        this.isEdit &&
        item.alterationCategory == "TOPPINGS"
      ) {
        item.alterationQuantity = this.LEFT_HALF;
      }
      this.setAvailableQuantity(item, false, max, totalItem);
      // this.availableQuantities.push(item);
    } else {
      if (
        this.allowHalf &&
        this.isEdit &&
        item.alterationCategory == "TOPPINGS"
      ) {
        item.alterationQuantity = alterationQuantity;
      }
      if (this.allowHalf == true) {
        isVerified = true;
      }
      this.setAvailableQuantity(
        item,
        isAlterationPortionChange,
        max,
        totalItem
      );

      // this.availableQuantities.push(item);
    }

    if (
      this.allowHalf &&
      !this.isEdit &&
      this.productQuantity == 0.5 &&
      totalItem < max &&
      isVerified &&
      item.isClickApplicable &&
      alterationQuantity
    ) {
      this.emitTrans.halfMenuTrigger({
        itemName: this.selectedItem.displayName,
        size: this.selectedCollections.displayName,
        productSubType: this.selectedStyle.productSubType,
        externalId1: this.selectedStyle.externalId1,
        externalId2: this.selectedStyle.externalId2,
        externalId3: this.selectedStyle.externalId3,
        externalId4: this.selectedStyle.externalId4 || "",
        productSubCategory: this.selectedStyle.productSubCategory,
        match: false,
        identifiers: this.availableQuantities.filter(
          (res: any) =>
            res.alterationQuantity && res.alterationCategory != "TOPPINGS"
        ),
        loader: true,
      });
    }

    this.sendtoservice();
    this.updateModifier();
  }

  // setAvailableQuantity(
  //   item: AddOn_Variations,
  //   isAlterationPortionChange: boolean = false,
  //   max: number | null,
  //   totalItem: number
  // ) {
  //   const availablePortionsInItems = item.availablePortions || [];
  //   console.log("item.availablePortions ", item.availablePortions);

  //   if (isAlterationPortionChange && availablePortionsInItems.length > 0) {
  //     const quantityExistIndex = this.availableQuantities.findIndex(
  //       (x: Modifier) =>
  //         x.externalId2 == item.externalId2 &&
  //         x.alterationPortion == item.alterationPortion &&
  //         x.alterationCategory === item.alterationCategory
  //     );

  //     if (quantityExistIndex > -1) {
  //       this.availableQuantities.splice(quantityExistIndex, 1);
  //       const currentPortionIndex = availablePortionsInItems.findIndex(
  //         (arrayItem) => arrayItem === item.alterationPortion
  //       );

  //       item.alterationPortion =
  //         availablePortionsInItems.length > 1
  //           ? currentPortionIndex === availablePortionsInItems.length - 1
  //             ? availablePortionsInItems[0]
  //             : availablePortionsInItems[currentPortionIndex + 1]
  //           : availablePortionsInItems[0];
  //       this.availableQuantities.push(item);
  //     } else {
  //       if (!max || totalItem < max) {
  //         const currentPortionIndex = availablePortionsInItems.findIndex(
  //           (arrayItem) => arrayItem === item.alterationPortion
  //         );
  //         console.log("currentPortion INdex ", currentPortionIndex);
  //         item.alterationPortion =
  //           availablePortionsInItems.length > 1
  //             ? currentPortionIndex === availablePortionsInItems.length - 1
  //               ? availablePortionsInItems[0]
  //               : availablePortionsInItems[currentPortionIndex + 1]
  //             : availablePortionsInItems[0];

  //         this.availableQuantities.push(item);
  //       } else {
  //         this.notification.openSnackBarV2(
  //           "end",
  //           "top",
  //           `YOU CAN ONLY SELECT ${max} ADDONS`,
  //           "danger-snackbar"
  //         );
  //       }
  //     }
  //   } else {
  //     if (!max || totalItem < max) {
  //       this.availableQuantities.push(item);
  //     } else {
  //       this.notification.openSnackBarV2(
  //         "end",
  //         "top",
  //         `YOU CAN ONLY SELECT ${max} ADDONS`,
  //         "danger-snackbar"
  //       );
  //     }
  //   }
  // }

  setAvailableQuantity(
    item: AddOn_Variations,
    isAlterationPortionChange: boolean = false,
    max: number | null,
    totalItem: number
  ) {
    const availablePortionsInItems = item.availablePortions || [];
    const totalCountOfSelectedItem: number = this.availableQuantities.filter(
      (data: Modifier) =>
        data.alterationCategory === item.alterationCategory &&
        data.externalId2 === item.externalId2 &&
        data
    ).length;
    if (item.limit && totalCountOfSelectedItem >= item.limit) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `YOU CAN ONLY SELECT ${item.limit} ITEMS`,
        "danger-snackbar"
      );
      return;
    }
    if (!max) {
      max = this.selectedStyle.max;
    }
    // If max is defined and the totalItem is more than max return with error
    if (max && totalItem >= max) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `YOU CAN ONLY SELECT ${max} ADDONS`,
        "danger-snackbar"
      );
      return;
    }
    if (max && totalItem < max && availablePortionsInItems.length < 1) {
      this.availableQuantities.push(item);
      return;
    }

    // console.log("item.availablePortions ", item.availablePortions);

    const quantityExistIndex =
      availablePortionsInItems.length > 0
        ? this.availableQuantities.findIndex(
            (x: Modifier) =>
              x.externalId2 == item.externalId2 &&
              x.alterationPortion == item.alterationPortion &&
              x.alterationCategory === item.alterationCategory
          )
        : -1;

    if (quantityExistIndex > -1) {
      this.availableQuantities.splice(quantityExistIndex, 1);
    }

    // if the item is eligible for change of portions and there are available portions then calculate
    // the new portion to be set
    if (isAlterationPortionChange && availablePortionsInItems.length > 0) {
      const currentPortionIndex = availablePortionsInItems.findIndex(
        (arrayItem) => arrayItem === item.alterationPortion
      );
      console.log("currentPortion INdex ", currentPortionIndex);
      item.alterationPortion =
        availablePortionsInItems.length > 1
          ? currentPortionIndex === availablePortionsInItems.length - 1
            ? availablePortionsInItems[0]
            : availablePortionsInItems[currentPortionIndex + 1]
          : availablePortionsInItems[0];
    }

    this.availableQuantities.push(item);
  }

  remove(category) {
    this.availableQuantities = this.availableQuantities.filter(
      (data: any) => data.alterationCategory != category
    );
    this.sendtoservice();
    this.updateModifier();
  }
  removeExtra(
    item: AddOn_Variations,
    isClick: boolean,
    modiIndex: any = null,
    CollecIndex: any = null
  ) {
    const availableProtionsInItems = item.availablePortions || [];
    const unique =
      availableProtionsInItems.length > 0
        ? this.availableQuantities.findIndex(
            (data: Modifier) =>
              data.externalId2 == item.externalId2 &&
              data.alterationQuantity == item.alterationQuantity &&
              data.alterationPortion == item.alterationPortion &&
              data.alterationCategory === item.alterationCategory
          )
        : this.availableQuantities.findIndex(
            (data: Modifier) =>
              data.externalId2 == item.externalId2 &&
              data.alterationQuantity == item.alterationQuantity &&
              data.alterationCategory === item.alterationCategory
          );

    if (unique > -1) {
      // console.log("..Called Remove Extra", this.availableQuantities);
      this.availableQuantities.splice(unique, 1);

      // console.log(
      //   "..Called Remove Extra after splice",
      //   this.availableQuantities
      // );
    }
    if (
      this.allowHalf &&
      this.productQuantity == 0.5 &&
      item.isClickApplicable &&
      isClick &&
      !this.isEdit &&
      item.alterationQuantity
    ) {
      this.emitTrans.halfMenuTrigger({
        itemName: this.selectedItem.displayName,
        size: this.selectedCollections.displayName,
        productSubType: this.selectedStyle.productSubType,
        externalId1: this.selectedStyle.externalId1,
        externalId2: this.selectedStyle.externalId2,
        externalId3: this.selectedStyle.externalId3,
        externalId4: this.selectedStyle.externalId4 || "",
        productSubCategory: this.selectedStyle.productSubCategory,
        match: false,
        identifiers: this.availableQuantities.filter(
          (res: any) =>
            res.alterationQuantity && res.alterationCategory != "TOPPINGS"
        ),
        loader: true,
      });
    }
    this.sendtoservice();
    this.updateModifier();
  }
  checkavailable(modifier: any[]) {
    const returnResponse = this.availableQuantities;
    return returnResponse
      .slice()
      .filter((data1) =>
        modifier.some(
          (data) => data.alterationCategory == data1.alterationCategory
        )
      ).length == 0
      ? true
      : false;
  }
  available: boolean;
  checkavailable1(modifier: any) {
    if (!modifier) {
      modifier = this.selectedModifier;
    }
    const returnResponse = this.availableQuantities;
    this.available =
      returnResponse.filter(
        (data1) => data1.alterationCategory == modifier.displayName
      ).length >= modifier.min
        ? false
        : true;
    this.sendtoservice();
    return this.available;
  }
  check(item: AddOn_Variations) {
    const returnResponse = this.availableQuantities.slice();
    const selected =
      returnResponse
        .slice()
        .findIndex(
          (data: Modifier) =>
            data.externalId2 == item.externalId2 &&
            item.alterationQuantity &&
            data.alterationCategory === item.alterationCategory
        ) != -1
        ? true
        : false;
    return selected;
  }

  getDuplicateValueCount(item: AddOn_Variations) {
    const returnResponse = this.availableQuantities;
    return returnResponse.filter(
      (data: Modifier) =>
        !data?.isRemoved &&
        data.externalId2 == item.externalId2 &&
        data.alterationCategory === item.alterationCategory
    ).length;
  }

  changequantityname(
    CollecIndex: any,
    availQuat: any,
    item: AddOn_Variations,
    max: number,
    alterationQuantity: any
  ) {
    const modiIndex = this.collmodifiers.findIndex(
      (modifier) => modifier.name === this.selectedModifier.name
    );
    // console.log('before modifiation =>',this.collmodifiers[modiIndex]["collections"][CollecIndex]);
    // const [first, second, third] = availQuat;
    if (!item.alterationPortion || item.alterationPortion === "") {
      this.collmodifiers[modiIndex]["collections"][CollecIndex][
        "alterationPortion"
      ] = this.availablePortions.Normal;
      // this.addExtra(item, max, alterationQuantity, false);
    }
    // console.log(this.allowHalf, this.isEdit);
    // if (!alterationQuantity) {
    //   this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = first;

    //   this.addExtra(item, max, alterationQuantity, false);
    // } else if (alterationQuantity == first) {
    //   console.log("HELLO", this.isEdit);
    //   if (this.getDuplicateValueCount(item) == 0) {
    //     this.addExtra(item, max, alterationQuantity, false);
    //   } else {
    //     this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //       "alterationQuantity"
    //     ] = second;
    //   }
    // } else if (alterationQuantity == "") {
    //   this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = first;
    // } else if (alterationQuantity == second) {
    //   if (this.getDuplicateValueCount(item) == 0) {
    //     this.addExtra(item, max, alterationQuantity, false);
    //   } else {
    //     this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //       "alterationQuantity"
    //     ] = third;
    //   }
    // } else if (alterationQuantity == third) {
    //   this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = "";
    // } else {
    //   this.collmodifiers[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = alterationQuantity;
    // }

    const availableQuantity = item.availableQuantities || [];
    if (availableQuantity.length > 0) {
      const currentAvailableQuantityIndex = availableQuantity.findIndex(
        (arrayItem) => arrayItem === item.alterationQuantity
      );

      this.collmodifiers[modiIndex]["collections"][CollecIndex][
        "alterationQuantity"
      ] =
        availableQuantity.length > 1
          ? currentAvailableQuantityIndex === availableQuantity.length - 1
            ? availableQuantity[0]
            : availableQuantity[currentAvailableQuantityIndex + 1]
          : availableQuantity[0];
      if (this.getDuplicateValueCount(item) == 0) {
        this.addExtra(item, max, alterationQuantity, false);
      }
    }
    // console.log('after modifiation =>',this.collmodifiers[modiIndex]["collections"][CollecIndex]);

    if (item.isDefault) {
      this.availableQuantities = this.availableQuantities.filter(
        (data: Modifier) => data.externalId2 != item.externalId2
      );

      this.addExtra(item, max, alterationQuantity, false);
    }
    this.sendtoservice();
    if (!item.alterationQuantity) {
      this.removeExtra(item, false);
    }
    this.updateModifier();
  }
  allowHalf: boolean;
  isProductHalf: boolean;
  selectedStyle: any;
  //STYLE Variations
  selectAddOnsV2(styleVariations: any, verified: boolean) {
    if (!this.isEdit && this.productQuantity != 0.5) {
      this.productQuantity = 1;
      this.allowHalf = false;
    }
    this.isProductHalf = styleVariations.allowHalf;
    this.selectedStyle = styleVariations;
    this.selectedCollectionsModifierscollections = this.selectedStyle;
    while (this.availableQuantities.length > 0) {
      this.availableQuantities.pop();
    }

    this.selectedProductQuantity = styleVariations.max;

    this.collectionsModifierscollections =
      this.collectionsModifierscollections.filter(
        (data: any, i: any) => data.displayName != styleVariations.displayName
      );
    this.collectionsModifierscollectionsmodifiers = styleVariations.modifiers;

    this.cryptoService.Encrypting(
      "sessionmodifiers",
      styleVariations.modifiers
    );
    this.collmodifiers =
      this.cryptoService.DecryptWithoutPromise("sessionmodifiers");

    this.collmodifiers.forEach((element) => {
      element.collections.sort(function (x, y) {
        let compare = 0;
        if (x.isDefault < y.isDefault) {
          compare = 1;
        } else if (x.isDefault > y.isDefault) {
          compare = -1;
        }
        return compare;
      });
    });
    this.findCollections();

    let looping = styleVariations.modifiers;

    if (this.toppings.length > 0 && verified == true) {
      this.toppings = [];
    }
    if (this.notAllowMultiple.length > 0 && verified == true) {
      this.notAllowMultiple = [];
    }

    this.collectionsModifierscollections.unshift(styleVariations);
    if (this.availableQuantities.length > 0 && verified == true) {
      this.availableQuantities = [];
    }
    if (this.availableQuantities.length > 0 && verified == false) {
      this.availableQuantities = [];
    }
    while (this.isDefaultModifiers.length > 0) {
      this.isDefaultModifiers.pop();
    }
    for (let index = 0; index < looping.length; index++) {
      const element = looping[index];
      if (element.isDefault == true) {
        this.isDefaultModifiers.push(element);
      }
    }

    if (this.isEdit) {
      this.availableQuantities = new Array();
      this.changeDetector.detectChanges();
      // console.log("before availableQuantities", this.availableQuantities);
      const product_modifiers = JSON.stringify(
        this.cryptoService.DecryptWithoutPromise("left_mod")
      );

      const assigningTemp = JSON.parse(product_modifiers);
      // console.log(
      //   "style_Variation before assigning",
      //   this.availableQuantities,
      //   this.editModifer,
      //   assigningTemp
      // );

      this.availableQuantities = [...assigningTemp.slice()];
      console.log(this.availableQuantities);
      for (let index = 0; index < this.collmodifiers.length; index++) {
        const element = this.collmodifiers[index];
        for (let j = 0; j < element.collections.length; j++) {
          const eleme: AddOn_Variations = element.collections[j];
          const alterationQuantityExist = this.availableQuantities.findIndex(
            (x: AddOn_Variations) => x.externalId2 == eleme.externalId2
          );
          if (alterationQuantityExist > -1) {
            this.collmodifiers[index]["collections"][j]["alterationPortion"] =
              this.availableQuantities[alterationQuantityExist][
                "alterationPortion"
              ];
          }
        }
      }
      this.changeDetector.detectChanges();
      // console.log("after availableQuantities", this.availableQuantities);
      // console.log(
      //   "style_Variation after assigning",
      //   this.availableQuantities,
      //   this.availableQuantities.slice(),
      //   this.editModifer,
      //   assigningTemp
      // );
    } else {
      for (let i = 0; i < this.isDefaultModifiers.length; i++) {
        const element = this.isDefaultModifiers[i];

        for (let j = 0; j < element.collections.length; j++) {
          const collection: AddOn_Variations = element.collections[j];
          if (collection.isDefault) {
            if (this.allowHalf && collection.alterationCategory == "TOPPINGS") {
              this.availableQuantities.push({
                ...collection,
              });
            } else {
              for (let i=0; i<(collection.defaultSelectedCount || 1);i++) {
                this.availableQuantities.push({
                  ...collection,
                  alterationQuantity: this.WHOLE,
                });
              }
            }
          }
        }
      }
      for (let index = 0; index < this.collmodifiers.length; index++) {
        const element = this.collmodifiers[index];
        for (let j = 0; j < element.collections.length; j++) {
          const eleme: AddOn_Variations = element.collections[j];
          if (eleme.isDefault) {
            if (
              eleme.alterationQuantity &&
              eleme.alterationCategory == "TOPPINGS" &&
              this.allowHalf
            ) {
              this.collmodifiers[index]["collections"][j][
                "alterationQuantity"
              ] = eleme.alterationQuantity;
            } else {
              this.collmodifiers[index]["collections"][j][
                "alterationQuantity"
              ] = this.WHOLE;
            }
          } else {
            if (eleme.alterationCategory == "TOPPINGS" && this.allowHalf) {
              this.collmodifiers[index]["collections"][j][
                "alterationQuantity"
              ] = this.LEFT_HALF;
            } else {
              this.collmodifiers[index]["collections"][j][
                "alterationQuantity"
              ] = "";
            }
          }
        }
      }
    }

    this.selectedQuantity = [
      ...new Map(
        this.selectedQuantity.map((item: any) => [
          item["alterationCategory"],
          item,
        ])
      ).values(),
    ];
    this.isProductHalf = styleVariations.allowHalf;
    if (this.productQuantity == 0.5) {
      this.isQuantityShowed = false;
    } else {
      this.isQuantityShowed = true;
    }
    console.log(this.availableQuantities);
    this.cryptoService.Encrypting("sessionmodifiers", this.collmodifiers);
    this.sendtoservice();
    this.updateModifier();
  }

  updateModifier() {
    this.collmodifiers = this.collmodifiers.map(
      (element) =>
        (element = { ...element, isRequired: this.checkavailable1(element) })
    );
  }

  getToolTipText(value1, value2) {
    return value2 ? `${value1} \n ${value2}` : value1;
  }

  findCollections(selectedModifierIndex?) {
    this.selectedModifier = this.collmodifiers[selectedModifierIndex || 0];
    if (this.selectedModifier) {
      this.modifiersCollection = this.selectedModifier.collections;
      const { min, max } = this.selectedModifier;
      this.selectedModifier.toolTipText = `${
        this.isNumber(min) ? "MIN " + min : ""
      }${this.isNumber(min) && this.isNumber(max) ? " & " : ""}${
        this.isNumber(max) ? "MAX " + max + " " : ""
      }`;
    }
  }

  isNumber(value) {
    return typeof value === "number";
  }

  selectTab(modifier) {
    const selectedModifierIndex = this.collmodifiers.findIndex(
      (val) => val.name === modifier.name
    );
    this.findCollections(selectedModifierIndex);
  }

  getHeaderTitle(modifier) {
    return (
      modifier.displayName +
      " " +
      (modifier?.collections[0]?.alterationCategory === "TOPPINGS"
        ? "( " + modifier?.collections[0]?.alterationType + " )"
        : "")
    );
  }

  checkActiveTab(modifier, selectedModifier) {
    if (
      !modifier.collections.length &&
      modifier.displayName !== selectedModifier.displayName
    ) {
      return false;
    } else if (
      !modifier.collections.length &&
      modifier.displayName === selectedModifier.displayName
    ) {
      return true;
    }
    const value1 =
      modifier.displayName + modifier?.collections[0]?.alterationType;
    const value2 =
      selectedModifier.displayName +
      selectedModifier?.collections[0]?.alterationType;
    return value1 === value2 ? true : false;
  }
}
