import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { NotificationService } from "../../notification/notification.service";
import { ActionCreateCart } from "../action/cart.action";
import { ActionGetStore } from "../action/store.action";

export const storeDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface storeDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<storeDefaultState>({
  name: "store",
  defaults: storeDefaultState,
})
@Injectable()
export class storeState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getStore(state: storeDefaultState) {
    return state.data;
  }

  @Action(ActionGetStore)
  createCart(
    { setState, getState, patchState }: StateContext<storeDefaultState>,
    { payload }
  ) {
    const store = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const storeState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };
      patchState(storeState);
      //   this.orderReceiveComp.getStoreInfoV2();
    }
  }
}
