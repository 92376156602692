<div style="padding-left: 0">
  <mat-form-field class="search" appearance="fill">
    <input
      matInput
      placeholder="Search By Store"
      [(ngModel)]="storeID"
      #value
      (keyup)="onSearch()"
    />
  </mat-form-field>
</div>
