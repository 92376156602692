import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatUsNumber'})
export class FormatUsNumberPipe implements PipeTransform {
  transform(number:any) {
    var numbers = number.replace(/\D/g, ''),
    char = { 0: '+', 1: ' ', 4: '-', 7: '-' };
    number = '';
    for (var i = 0; i < numbers.length; i++) {
      number += (char[i] || '') + numbers[i];
    }
    return number;
  }
}