import { IPermission } from './../model/peremission.model';
import { IRoleListing } from './../model/role-listing.model';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UtilityService } from 'app/core/services/Utility.service';
import { RolesAndPermissionsService } from '../../core/services/roles-and-permissions.service';
import { AuthenticationService } from '../../core/auth/authentication.service';
import Swal from 'sweetalert2';
import { ComponentService } from '../../core/services/component.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'app/app.constants';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit, AfterViewInit {
  public selectedTab: string = 'Role Details';
  public selectedFormType: string = '';
  public role: IRoleListing = this.ResetRole();
  public permissions: Array<IPermission> = [];
  user = null;
  allPermissionSelected: boolean = false;
  errorMessages = {
    Name: { Valid: true, Message: 'Role name is required' },
    Description: { Valid: true, Message: 'Role description is required' },
    IsActive: { Valid: true, Message: 'Role must be active while adding.' },
    Permissions: { Valid: true, Message: 'Please select atleast one permission' }
  };
  public currentuser: any;
  roles: any;
  constructor(private utilityService: UtilityService
    , private rolesAndPermissionService: RolesAndPermissionsService
    , private authService: AuthenticationService
    , private componentService: ComponentService,
    private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.authService.userDetail().subscribe((response: any) => {
      this.roles = AppConstants.ROLE;
      this.currentuser = response.user;
      this.currentuser.role = response?.user?.roles?.[0]?.role_details?.name;
    });
    this.user = this.authService.getCurrentUser();
    this.utilityService.SetPageTitle("Add Role");
    this.LoadPermission();
  }
  ngAfterViewInit(): void {
  }
  ResetRole(): IRoleListing {
    return { ID: null, Name: null, Description: null, IsActive: true, Permissions: null };
  }
  AddRole() {
    this.role.Permissions = this.permissions.filter(x => x.Selected);
    if (this.ValidateInput()) {
      this.role.UserID = this.user.id;
      this.ngxService.start();
      this.rolesAndPermissionService.AddRole(this.role).subscribe(data => {
        let successMessage: string = data.data as string;
        this.ngxService.stop();
        this.ResetForm();
        Swal.fire(
          successMessage,
          'Role with permissions added successfully.',
          "success"
        );
        this.Back();
      }, error => {
        Swal.fire(
          'Role not saved.',
          error,
          'error'
        );
        this.ngxService.stop();
      });
    }

  }
  ValidateInput(): boolean {
    let result: boolean = true;
    if (this.role.Name == null || this.role.Name.trim() == "") {
      this.errorMessages.Name.Valid = false;
      result = false;
    } else { this.errorMessages.Name.Valid = true; }
    if (this.role.Description == null || this.role.Description.trim() == "") {
      this.errorMessages.Description.Valid = false;
      result = false;
    } else { this.errorMessages.Description.Valid = true; }
    if (this.selectedFormType == 'Add') {
      if (this.role.IsActive == null || this.role.IsActive == false) {
        this.errorMessages.IsActive.Valid = false;
        result = false;
      } else { this.errorMessages.IsActive.Valid = true; }
    }
    if (this.role.Permissions == null || this.role.Permissions.length == 0) {
      this.errorMessages.Permissions.Valid = false;
      result = false;
    } else { this.errorMessages.Permissions.Valid = true; }
    return result;
  }
  LoadPermission() {
    this.rolesAndPermissionService.GetPermissions().subscribe(permission => {
      this.permissions = permission;
      if (this.selectedFormType == 'Modify') {
        this.allPermissionSelected = true;
        this.permissions.forEach(gridPermission => {
          gridPermission.Selected = this.role.Permissions.filter(selectedPermission => selectedPermission.ID == gridPermission.ID).length > 0;
          if (!gridPermission.Selected) {
            this.allPermissionSelected = false;
          }
        });
      }
    });
  }
  ModifyRole() {
    this.role.Permissions = this.permissions.filter(selectedPermission => selectedPermission.Selected);
    if (this.ValidateInput()) {
      this.role.UserID = this.user.id;
      this.role.updatedby = this.user.id;
      this.ngxService.start();
      this.rolesAndPermissionService.UpdateRole(this.role).subscribe(data => {
        let successMessage: string = data.data as string;
        this.ngxService.stop();
        Swal.fire(
          successMessage,
          'Role successfully updated.',
          "success"
        ).then(data => {
          window.scroll(0, 0);
          this.componentService.SetSelectedButtonType("AddNew");
          this.componentService.LoadRoleAddModifyComponent({
            ComponentName: "RoleListing"
          });
        });
      }, () => this.ngxService.stop());
    }
  }
  Back() {
    window.scroll(0, 0);
    this.componentService.SetSelectedButtonType("AddNew");
    this.componentService.LoadRoleAddModifyComponent({
      ComponentName: "RoleListing"
    });
  }
  ResetForm() {
    this.role = this.ResetRole();
    this.allPermissionSelected = false;
    this.permissions.map(x => x.Selected = false);
  }

  SelectAllPermission(isTrue: boolean) {
    this.permissions.forEach(x => x.Selected = isTrue);
  }

  isActiveChange() {
    if (this.selectedFormType == 'Modify' && !this.role?.IsActive && this.role?.userAttached?.length) {
      const attachedUsers = this.role?.userAttached.map(user => user.user_id).toString();
      Swal.fire({
        title:
        `Can't deactivate because following users are attached ${attachedUsers}`,
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then(() => this.role.IsActive = true);
    }
  }

  get isPermissionPolicy() {
    return !(this.currentuser?.role === this.roles.SUPERADMIN && this.roles.SUPERADMIN !== this.role?.Name);
  }
}
