import { Component, OnInit, Output, ViewEncapsulation, ElementRef, ViewChild, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VoixHttpService } from '../../../core/voix-http/voix-http.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})

export class ChatWindowComponent implements OnInit {
  @Input() indexValue = 0;
  @Output() sendSMS = new EventEmitter();
  // tslint:disable-next-line:max-line-length
  @Input() chatWindowInfo: any = {restaurant_id: null, customer_phone: null, conversationData: [], name: null, loader: false, assignedAgent: {agent_email: null}, orderDetails: {}, esclate: false, playback: true};
  @Output() chatWindowClose = new EventEmitter();
  @Output() refreshChatConversation = new EventEmitter();
  @Output() msgData: any = [];
  @ViewChild('scrollContent') private scrollContent: ElementRef;
  message: string;
  isOpen = true;
  constructor(
    private voixHttp: VoixHttpService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.scrollToBottom();
  }

  // conversation window scrollup
  scrollToBottom() {
    try {
      this.scrollContent.nativeElement.scrollTo({ top: this.scrollContent.nativeElement.scrollHeight, behavior: 'smooth'});
    } catch (err) { }
  }

  close(index) {
    this.chatWindowClose.emit(index);
  }

  toggleData() {
    this.isOpen = !this.isOpen;
  }

  refreshConversation(item: any) {
    console.log(item);
    item.conversationData = [];
    item.loader = true;
    this.refreshChatConversation.emit(item);
  }

  sendsms(item: any) {
    item.text = this.message;
    this.sendSMS.emit(item);
    this.message = null;
  }
}
