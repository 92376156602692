import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
// import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VoixHttpService } from '../../../core/voix-http/voix-http.service';
import { NotificationService } from '../../../core/notification/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FeedbackComponent {
  feedbackForm: FormGroup;
  responseData: any;
  conversationId: any;
  reasons: string[] = [];
  constructor(
  public fb: FormBuilder,
  private voixHttp: VoixHttpService,
  private notification: NotificationService,
  public dialogRef: MatDialogRef<FeedbackComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseData = data;
    this.conversationId = this.responseData.conversationId;
    this.feedbackForm = fb.group({
      'feedback' : [[]],
      'comment': [null]
    });
    this.getListFeedbackReasons();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onChange(feedback: string, event: any) {
    if(event.checked) {
      this.feedbackForm.value.feedback.push(feedback);
    } else {
      let index = this.feedbackForm.value.feedback.indexOf(feedback);
      if (index > -1) {
        this.feedbackForm.value.feedback.splice(index, 1);
      }
    }
  }

  getListFeedbackReasons() {
    this.voixHttp.get('v1/feedback-reasons')
    .subscribe( (response: any) => {
      this.reasons = response.data;
    }, error => {
      const errorHandle = JSON.parse(error);
      this.notification.show('danger', 'notifications', errorHandle.error);
    });
  }

  submitFeedback(value) {
    this.voixHttp.post('v1/feedback/' + this.conversationId, value)
      .subscribe(data => {
        this.notification.show('success', 'notifications', 'Feedback has been submit successfully.');
        this.onClose();
      });
  }
}
