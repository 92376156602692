<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" filter-color="black" data-image="../../assets/img/register.jpg">
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <form [formGroup]="forgetPasswordForm" (ngSubmit)="submitForm(forgetPasswordForm.value)" method="#" action="#">
                                <div class="card card-login">
                                    <div class="text-center">
                                        <div class="logo-img">
                                            <img src="/assets/img/voixai.png"/>
                                        </div>
                                        <div class="logo-img text-logo-sub">
                                            <img class="text-logo" src="/assets/img/logo-text-voix.png" />
                                        </div>
                                        <h3 class="card-title">Forgot your Password?</h3>
                                    </div>
                                    <div class="card-content">
                                        <p> Enter your Email address and we'll send you a link to reset your password. </p>
                                        <div class="input-group">
                                            <span class="input-group-addon">
                                                <i class="material-icons">email</i>
                                            </span>
                                            <div class="form-group label-floating is-empty mt-0">
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Enter your valid email address" [formControl]="forgetPasswordForm.controls['email']">
                                                    <mat-error *ngIf="forgetPasswordForm.controls['email'].hasError('email') && !forgetPasswordForm.controls['email'].hasError('required')">
                                                    Please enter a valid email address
                                                    </mat-error>
                                                    <mat-error *ngIf="forgetPasswordForm.controls['email'].hasError('required')">
                                                    Email is <strong>required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                                <span class="material-input"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer text-center">
                                        <button type="submit" class="btn btn-primary btn-round submit" [disabled]="!forgetPasswordForm.valid">Sent reset link</button>
                                    </div>

                                    <hr>
                                    <div class="w-full text-center p-t-55">
                                        <span class="txt2">
                                            Back to
                                        </span>

                                        <a routerLink='/login' class="txt2 bo1">
                                            Sign in
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg)"></div>
    </div>
</div>
      
      

