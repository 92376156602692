import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { ViewStoresComponent } from './view-stores/view-stores.component';
import { StoreListingComponent } from './store-listing/store-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { AddStoreComponent } from './add-store/add-store.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { SelectedClientComponent } from './selected-client/selected-client.component';
import { UploadedStoreListComponent } from './uploaded-store-list/uploaded-store-list.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SharedModule } from 'app/core/shared/shared.module';
import {TagInputModule} from "ngx-chips";
import {MatChipsModule} from "@angular/material/chips";
import {FormatEnumPipe} from "../core/pipes/format-enum.pipe";




@NgModule({
  declarations: [
    ViewStoresComponent,
    StoreListingComponent,
    AddStoreComponent,
    SelectedClientComponent,
    UploadedStoreListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatPaginatorModule,
    SharedModule,
    TagInputModule,
    MatChipsModule
  ]
})
export class StoreManagementModule { }
