import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetDeals } from "../action/deals.action";
import { ActionSelectedModifiers1 } from "../action/menuSelection.action";

export const menuSelectedState = {
  data: null,
  isloaded: false,
};
export interface menuSelectedState {
  isloaded: boolean;
  data: any | null;
}
@State<menuSelectedState>({
  name: "selectedModifiers",
  defaults: menuSelectedState,
})
@Injectable()
export class menuSelectionState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getSelectedModifiers(state: menuSelectedState) {
    return state.data;
  }

  @Action(ActionSelectedModifiers1)
  menuSelect(
    { setState, getState, patchState }: StateContext<menuSelectedState>,
    { payload }
  ) {
    const selectedModifiers = getState();

    // console.log(payload);
    if (payload) {
      const selectedModifiersState = {
        isloaded: true,
        data: payload,
      };
      patchState(selectedModifiersState);
    } else {
      patchState(selectedModifiers);
    }
  }
}
