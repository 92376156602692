import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-store-detail",
  templateUrl: "./store-detail.component.html",
  styleUrls: ["./store-detail.component.scss"],
})
export class StoreDetailComponent implements OnInit {
  constructor(
    @Optional() public dialogRef: MatDialogRef<StoreDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public storeDetails: any
  ) {}

  ngOnInit() {
    console.log(this.storeDetails);
  }
}
