import {OnInit, NgModule, EventEmitter, Injectable, Output, Component, Compiler, ViewContainerRef, ViewChild, Input, ComponentRef, ComponentFactory, ComponentFactoryResolver, ChangeDetectorRef} from '@angular/core';
import * as _ from 'underscore';
import * as moment from 'moment';

declare const $: any;
@Component({
  selector: 'app-vx-wizard',
  templateUrl: './vx-wizard.component.html',
  styleUrls: ['./vx-wizard.component.scss']
})
export class VxWizardComponent implements OnInit {
  @Input('step') tabActiveIndex: any = 1;
  @Input('subText') subText: string = '';
  progressWidth: any = 25;
  constructor() { }
  ngOnInit() {
    this.tabChange(this.tabActiveIndex);
  }
  
  tabChange(index) {
    this.tabActiveIndex = index;
    this.progressWidth = (12.5*2)*index;
  }

}
