<div class="main-page d-flex align-items-end">
  <!-- main content here -->
  <div class="ps-3 pe-3 pb-3 w-100">
    <div class="bg-white p-2 d-flex justify-content-between">
      <div>
        <button mat-raised-button color="primary">Ezra AI</button>
        <button
          mat-raised-button
          (click)="sendEvents('WAIT')"
          class="ms-2"
          color="warn"
        >
          Wait
        </button>
        <button
          mat-raised-button
          (click)="sendEvents('GOTIT')"
          class="ms-2"
          color="warn"
        >
          Got it
        </button>
        <button
          mat-raised-button
          (click)="sendEvents('YES')"
          class="ms-2"
          color="warn"
        >
          Yes
        </button>
        <button
          mat-raised-button
          (click)="sendEvents('NO')"
          class="ms-2"
          color="warn"
        >
          No
        </button>
      </div>
      <div>
        <button
          mat-raised-button
          *ngIf="conversationData?.cart?.cartLedger?.expectedLedger?.lineItemPrice"
          class="me-2"
          [ngStyle]="{
            'background-color': 'green',
            color: 'white'
          }"
        >
          Total : ${{
            conversationData?.cart?.cartLedger?.expectedLedger?.lineItemPrice
              | number : "1.2-2"
          }}
        </button>
        <button
          color="primary"
          class="me-2"
          mat-raised-button
          *ngIf="getElapsedTime() as elapsed"
        >
          <b>
            {{ elapsed.minutes < 10 ? 0 : "" }}{{ elapsed.minutes }} :
            {{ elapsed.seconds < 10 ? 0 : "" }}{{ elapsed.seconds }}
            {{ getCallDuration(elapsed.minutes, elapsed.seconds) }}
          </b>
        </button>
        <button mat-raised-button (click)="logout()" color="primary">
          logout
        </button>
      </div>
    </div>
    <div class="row align-items-end">
      <div class="col-md-6">
        <app-menu-selector></app-menu-selector>
        <div class="d-flex align-items-end justify-content-between">
          <button
            [disabled]="!isFromValid()"
            [ngStyle]="{
              'background-color': isFromValid() ? 'green' : '',
              color: isFromValid() ? 'white' : ''
            }"
            (click)="addItemToCart()"
            class="w-100"
            mat-raised-button
          >
            Add To Cart
          </button>
        </div>
      </div>
      <div class="col-md-3">
        <app-chat-box
          [conversationSoFar]="
            conversationData?.conversationSoFarSendToDashboard?.reverse() || []
          "
        ></app-chat-box>
        <div class="d-flex justify-content-between">
          <button color="accent" mat-raised-button (click)="endSession()">
            End Session
          </button>
          <button color="primary" mat-raised-button (click)="transfer()">
            Agent Transfer
          </button>
        </div>
      </div>
      <div class="col-md-3">
        <app-current-order
          [cart]="conversationData?.cart || null"
          [currentFoodItems]="conversationData?.cartFlowItemsInProgress || []"
          [lastFoodItems]="conversationData?.lastCartFlowItemsInProgress || []"
        ></app-current-order>
        <div class="d-flex justify-content-between">
          <button
            color="accent"
            [disabled]="
              !conversationData?.cart?.id ||
              conversationData?.cart?.cartMenuItems?.length == 0
            "
            (click)="clearCart()"
            mat-raised-button
          >
            Clear cart
          </button>
          <button
            color="primary"
            mat-raised-button
            (click)="submitOrder()"
            [disabled]="!finalizeOrder"
          >
            Submit order
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="fixed-card">
    <div class="d-flex justify-content-between align-items-end">
      <div class="me-3">
        <app-menu-selector></app-menu-selector>
      </div>
      <div class="me-3">
        <app-chat-box
          [conversationSoFar]="
            conversationData?.conversationSoFarSendToDashboard?.reverse() || []
          "
        ></app-chat-box>
        <div class="d-flex justify-content-between">
          <button color="accent" mat-raised-button>End Session</button>
          <button color="primary" mat-raised-button>Store Transfer</button>
        </div>
      </div>
      <div>
        <app-current-order
          [cart]="conversationData?.cart || null"
          [currentFoodItems]="conversationData?.cartFlowItemsInProgress || []"
          [lastFoodItems]="conversationData?.lastCartFlowItemsInProgress || []"
        ></app-current-order>
        <div class="d-flex justify-content-between">
          <button color="accent" mat-raised-button>Clear</button>
          <button color="primary" mat-raised-button>Finalize</button>
        </div>
      </div>
    </div>
  </div> -->
</div>
