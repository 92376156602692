<div class="main-content dashboard no-scroll">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-wrap align-items-center pb-2">
        <mat-form-field appearance="fill" class="common_padding">
          <mat-label>Select Client</mat-label>
          <mat-select [(ngModel)]="selectedClient" name="client_code">
            <mat-option
              *ngFor="let client of clients"
              (click)="getAllStores(selectedClient)"
              (click)="setDealNull()"
              [value]="client.name"
            >
              {{ client.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="common_padding">
          <mat-label>Select Store</mat-label>
          <input
            type="text"
            placeholder="Select Store"
            aria-label="Number"
            matInput
            [formControl]="storeId"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let store of filteredOptions | async"
              (click)="setDealNull()"
              [value]="store"
            >
              {{ store }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
    
        <mat-form-field appearance="fill" class="common_padding">
          <mat-label>Select Order Destination</mat-label>
          <mat-select [(ngModel)]="selectOrderType" name="order_destination">
            <mat-option
              *ngFor="let orderType of orderTypes"
              [value]="orderType.value"
              (click)="setDealNull()"
            >
              {{ orderType.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="common_padding">
          <button
            mat-stroked-button
            color="primary"
            class="margin-top-btn neworder"
            (click)="getDealSocket()"
            [disabled]="!selectedClient || !storeId.value || !selectOrderType"
            *ngIf="userPermissions.DEAL_VIEW "
          >
            VIEW
            <i class="material-icons">lock</i>
          </button>
        </span>
        <span class="common_padding">
          <button
            mat-stroked-button
            color="primary"
            class="margin-top-btn neworder"
            (click)="downloadSampleCSVFile()"
            *ngIf="userPermissions.DEAL_ADD | permissions"
          >
            Download Sample CSV
          </button>
          <button
            mat-stroked-button
            color="primary"
            class="margin-top-btn neworder"
            (click)="openFile(fileImportInput)"
            *ngIf="userPermissions.DEAL_ADD | permissions"
          >
            Upload CSV
            <i class="material-icons">upload</i>
            <input
              #fileImportInput
              class="file-input hidden"
              type="file"
              name="File Upload"
              id="csvFileUpload"
              (change)="fileChangeListener($event)"
              accept=".csv"
              hidden="true"
            />
          </button>
        </span>
      </div>
      <div class="promo-code">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="searchS"
            placeholder="Search Promo Code"
            #value
            (keyup)="sendSearch($event.target.value)"
          />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div>
    <app-view-deal [deals]="deals" [selectedClient]="selectedClient"></app-view-deal>
  </div>
</div>
