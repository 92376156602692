<div class="card">
  <div class="card-header bg-primary text-white">Menu</div>
  <div
    class="card-body text-center"
    style="min-height: 80vh; max-height: 80vh; overflow-y: scroll"
  >
    <ng-container
      *ngIf="_aimenuService.categories | async as categories; else spinner"
    >
      <!-- selected items -->
      <div class="row row-cols-auto g-2">
        <div class="col">
          <button
            *ngIf="categories.length == 1"
            [ngStyle]="{
              'background-color': categories.length == 1 ? 'green' : '',
              color: categories.length == 1 ? 'white' : ''
            }"
            (click)="deselectCategory()"
            class="rounded-2"
            mat-stroked-button
          >
            {{ categories[0]?.displayName }} <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="col">
          <button
            *ngIf="items.length == 1"
            [ngStyle]="{
              'background-color': items.length == 1 ? 'green' : '',
              color: items.length == 1 ? 'white' : ''
            }"
            (click)="deselectItem()"
            class="rounded-2 ms-2"
            mat-stroked-button
          >
            {{ items[0]?.displayName }} <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="col">
          <button
            *ngIf="sizes.length == 1"
            [ngStyle]="{
              'background-color': sizes.length == 1 ? 'green' : '',
              color: sizes.length == 1 ? 'white' : ''
            }"
            (click)="deselectSize()"
            class="rounded-2 ms-2"
            mat-stroked-button
          >
            {{ sizes[0]?.displayName }} <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="col">
          <button
            *ngIf="styles.length == 1"
            [ngStyle]="{
              'background-color': styles.length == 1 ? 'green' : '',
              color: styles.length == 1 ? 'white' : ''
            }"
            (click)="deselectStyle()"
            class="rounded-2 ms-2"
            mat-stroked-button
          >
            {{ styles[0]?.displayName }} <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <!-- item selector -->
      <div *ngIf="categories.length > 1" class="row row-cols-auto g-2">
        <div class="col" *ngFor="let category of categories">
          <button
            [disabled]="!category.isAvailable"
            (click)="selectCategory(category)"
            class="rounded-2"
            color="warn"
            mat-stroked-button
          >
            {{ category?.displayName }}
          </button>
        </div>
      </div>
      <div *ngIf="items.length > 1" class="row row-cols-auto g-2 mt-2">
        <div class="col" *ngFor="let item of items">
          <button
            [disabled]="!item.isAvailable"
            (click)="selectItem(item)"
            color="warn"
            class="rounded-2"
            mat-stroked-button
          >
            {{ item?.displayName }}
          </button>
        </div>
      </div>
      <div *ngIf="sizes.length > 1" class="row row-cols-auto g-2 mt-2">
        <div class="col" *ngFor="let size of sizes">
          <button
            [disabled]="!size.isAvailable"
            (click)="selectSize(size)"
            color="warn"
            class="rounded-2"
            mat-stroked-button
          >
            {{ size?.displayName }}
          </button>
        </div>
      </div>
      <div *ngIf="styles.length > 1" class="row row-cols-auto g-2 mt-2">
        <div class="col" *ngFor="let style of styles">
          <button
            [disabled]="!style.isAvailable"
            (click)="selectStyle(style)"
            color="warn"
            class="rounded-2"
            mat-stroked-button
          >
            {{ style?.displayName }}
          </button>
        </div>
      </div>
      <div class="container">
        <div
          *ngIf="items.length == 1"
          class="row row-cols-auto g-2 mt-2 align-items-center"
        >
          <div class="col">Quantity :</div>
          <div class="col" *ngFor="let quantity of availableQuantity">
            <button
              (click)="selectQuantity(quantity)"
              [ngStyle]="{
                'background-color':
                  quantity === selectedQuantity ? 'green' : '',
                color: quantity === selectedQuantity ? 'white' : ''
              }"
              class="rounded-2"
              mat-stroked-button
            >
              {{ quantity }}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="addons.length > 0" class="row row-cols-auto g-2 mt-2">
        <div class="col" *ngFor="let addon of addons">
          <button
            [disabled]="!addon.isAvailable"
            [ngStyle]="{
              'background-color':
                selectedAddonTab == getAddonName(addon) ? 'green' : '',
              color: selectedAddonTab == getAddonName(addon) ? 'white' : ''
            }"
            color="warn"
            (click)="selectedAddonTab = getAddonName(addon)"
            class="rounded-2"
            mat-stroked-button
          >
            {{ getAddonName(addon) }}
            <mat-icon
              *ngIf="checkAddonIsRequired(addon)"
              style="background: transparent !important"
              class="header-alert"
            >
              <i class="fa fa-3x fa-exclamation-circle blink"></i>
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="container mt-2">
        <div *ngIf="addons.length > 0">
          <mat-divider></mat-divider>
          <div *ngFor="let addon of addons; index as i">
            <div class="w-100" *ngIf="selectedAddonTab === getAddonName(addon)">
              <div class="row row-cols-auto g-2 mt-2 w-100">
                <div
                  class="col"
                  *ngFor="let addonVariation of addon.collections; index as j"
                >
                  <div class="flex">
                    <button
                      (click)="removeAddonVariation(addon, addonVariation)"
                      color="warn"
                      class="rounded-start-2 p-0"
                      mat-stroked-button
                    >
                      <mat-icon>remove</mat-icon>
                    </button>
                    <button
                      [disabled]="!addonVariation.isAvailable"
                      [ngStyle]="{
                        'background-color': getInsertedAddonVariation(
                          addonVariation
                        )
                          ? 'green'
                          : '',
                        color: getInsertedAddonVariation(addonVariation)
                          ? 'white'
                          : ''
                      }"
                      color="warn"
                      class="rounded-0"
                      (click)="changeAlterationType(addonVariation, i, j)"
                      mat-stroked-button
                    >
                      {{ getAddonVariationName(addonVariation) }}
                    </button>
                    <button
                      (click)="addAddonVariation(addon, addonVariation)"
                      color="warn"
                      class="rounded-end-2 p-0"
                      mat-stroked-button
                    >
                      <mat-icon>add</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="addons.length > 1" class="mt-3">
    <mat-tab-group
      dynamicHeight
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab *ngFor="let addon of addons" [matTooltip]="getAddonName(addon)">
        <ng-template mat-tab-label>
          <span>{{ getAddonName(addon) }}</span>
          <button
            disabled
            *ngIf="checkAddonIsRequired(addon)"
            style="background: transparent !important"
            mat-icon-button
            class="header-alert"
            data-testid="modifiers-required-button"
          >
            <i
              class="fa fa-3x fa-exclamation-circle blink"
              style="margin-top: -10px !important; margin-left: 15px !important"
            ></i>
          </button>
        </ng-template>
        <div class="container">
          <div class="row row-cols-auto g-2 mt-2">
            <div class="col" *ngFor="let addonVariation of addon.collections">
              <div class="flex">
                <button
                  color="warn"
                  class="rounded-start-2 p-0"
                  mat-stroked-button
                >
                  <mat-icon>remove</mat-icon>
                </button>
                <button
                  [disabled]="!addonVariation.isAvailable"
                  color="warn"
                  class="rounded-0"
                  mat-stroked-button
                >
                  {{ addonVariation?.displayName }}
                </button>
                <button
                  color="warn"
                  class="rounded-end-2 p-0"
                  mat-stroked-button
                >
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div> -->
    </ng-container>
    <ng-template #spinner>
      <div
        style="
          min-height: 60vh;
          max-height: 60vh;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
