<div class="row">
  <div class="col-md-12">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th class="table-heading" scope="col">{{ "Role ID" | uppercase }}</th>
          <th class="table-heading" scope="col">{{ "Role" | uppercase }}</th>
          <th class="table-heading" scope="col">
            {{ "Description" | uppercase }}
          </th>
          <th class="table-heading" scope="col" *ngIf="(userPermissions.ROLE_EDIT | permissions) || (userPermissions.ROLE_DELETE | permissions)">{{ "Action" | uppercase }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let role of roleListing">
          <td>
            <span>{{ role.ID }}</span>
          </td>
          <td>
            <span>{{ role.Name }}</span>
          </td>
          <td>
            <span>{{ role.Description }}</span>
          </td>
          <td *ngIf="(userPermissions.ROLE_EDIT | permissions) || (userPermissions.ROLE_DELETE | permissions)">
            <button
              class="btn btn-sm btn-warning badge"
              (click)="ModifyRole(role)"
              *ngIf="userPermissions.ROLE_EDIT | permissions"
            >
              Modify Permissions
            </button>
            <button
              class="btn btn-sm btn-warning badge"
              (click)="DeleteRole(role)"
              *ngIf="userPermissions.ROLE_DELETE | permissions"
            >
              Delete
            </button>
          </td>
        </tr>
        <tr
          class="align-center"
          style="padding: 10px"
          *ngIf="roleListing.length == 0"
        >
          <td colspan="4">No data found</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-md-12"></div>
</div>
