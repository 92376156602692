import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { NotificationService } from "../../notification/notification.service";
import { ActionCreateCart } from "../action/cart.action";
import { ActionSendPayment, ResetPayment } from "../action/payment.action";
import { ActionGetStore } from "../action/store.action";

export const paymentDefaultState = {
  message: null,
  data: [],
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface paymentDefaultState {
  message: String;
  isloaded: boolean;
  data: any[];
  isError?: boolean;
  errorMessage?: string;
}
@State<paymentDefaultState>({
  name: "payment",
  defaults: paymentDefaultState,
})
@Injectable()
export class paymentState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getPayments(state: paymentDefaultState) {
    return state.data;
  }

  @Action(ActionSendPayment)
  savePayments(
    { setState, getState, patchState }: StateContext<paymentDefaultState>,
    { payload }
  ) {
    const payments = getState();

    const paymentState = {
      message: "Successfull",
      isloaded: true,
      data: [...payments.data, payload],
    };
    patchState(paymentState);
  }

  @Action(ResetPayment)
  resetPayments(
    { setState, getState, patchState }: StateContext<paymentDefaultState>,
    {}
  ) {
    setState(paymentDefaultState);
  }
}
