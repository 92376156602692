import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreditCardComponent } from "./credit-card/credit-card.component";
import { GiftCardComponent } from "./gift-card/gift-card.component";
import { RouterModule, Routes } from "@angular/router";
import { MaterialModule } from "app/core/modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WorldpayComponent } from "./worldpay/worldpay.component";
import { emitTransactionsService } from "../core/services/emitTransactions.service";

const routes: Routes = [
  {
    path: "credit-card/:customerphone/:uuid/:restaurantphone",
    component: CreditCardComponent,
  },
  {
    path: "gift-card/:customerphone/:uuid/:restaurantphone",
    component: GiftCardComponent,
  },
  {
    path: "worldpay",
    component: WorldpayComponent,
  },
];
@NgModule({
  declarations: [CreditCardComponent, GiftCardComponent, WorldpayComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  ],
  exports: [],
  providers: [emitTransactionsService],
})
export class TransactionModule {}
