import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { aiMenuService } from "app/core/services/ai/ai-menu.service";
import {
  AddOn,
  AddOn_Variations,
  Category,
  Items,
  Size_Variations,
  Style_Variations,
} from "../../../../../mediator-commlibs/interfaces/response/menu.interface";
import { lastValueFrom } from "rxjs";
import { NotificationService } from "app/core/notification/notification.service";

@Component({
  selector: "app-menu-selector",
  templateUrl: "./menu-selector.component.html",
  styleUrls: ["./menu-selector.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuSelectorComponent implements OnInit {
  items: Items[] = [];
  sizes: Size_Variations[] = [];
  styles: Style_Variations[] = [];
  addons: AddOn[] = [];
  selectedAddonVariations: AddOn_Variations[] = [];
  availableQuantity: number[] = Array.from(
    { length: 10 },
    (_, index) => index + 1
  );
  selectedQuantity: number = 1;
  selectedAddonTab: string = "";
  constructor(
    public _aimenuService: aiMenuService,
    private notification: NotificationService
  ) {
    _aimenuService.clearMenu.subscribe((data) => {
      if (data) {
        this._aimenuService.categories.next(
          this._aimenuService.originalMenu.value
        );
        this.items = [];
        this.sizes = [];
        this.styles = [];
        this.addons = [];
        this.selectedAddonVariations = [];
        this.selectedQuantity = 1;
        this.sendDetailstoService();
        _aimenuService.clearMenu.next(false);
      }
    });
  }
  ngOnInit(): void {}
  selectQuantity = (quantity: number) => {
    this.selectedQuantity = quantity
    this.sendDetailstoService();
  };
  selectCategory = (category: Category) => {
    this._aimenuService.categories.next([category]);
    this.items = category.items;
    this.sendDetailstoService();
  };
  sendDetailstoService = () => {
    const objectTosend = {
      category: this._aimenuService.categories.value,
      items: this.items,
      sizes: this.sizes,
      styles: this.styles,
      addons: this.addons,
      selectedAddonVariations: this.selectedAddonVariations,
      selectedQuantity: this.selectedQuantity,
    };
    this._aimenuService.cartMenuItemDetails.next(objectTosend);
  };
  deselectCategory = () => {
    this._aimenuService.categories.next(this._aimenuService.originalMenu.value);
    this.items = [];
    this.sizes = [];
    this.styles = [];
    this.addons = [];
    this.selectedAddonVariations = [];
    this.selectedQuantity = 1;
    this.sendDetailstoService();
  };

  selectItem = (item: Items) => {
    this.items = [item];
    this.sizes = item.modifiers[0].collections;
    if (this.sizes.length == 1) {
      this.styles = this.sizes[0].modifiers[0]?.collections || [];
    }
    if (this.styles.length == 1) {
      this.addons = this.styles[0].modifiers || [];
      this.selectedAddonVariations = this.addons.flatMap((addon: AddOn) =>
        addon.collections.filter(
          (addonVariation: AddOn_Variations) => addonVariation.isDefault
        )
      );
      this.selectedAddonTab = this.getAddonName(this.addons[0]);
    }
    this.sendDetailstoService();
  };
  deselectItem = () => {
    this.items = this._aimenuService?.categories?.value[0]?.items || [];
    this.sizes = [];
    this.styles = [];
    this.addons = [];
    this.selectedAddonVariations = [];
    this.selectedAddonTab = "";
    this.sendDetailstoService();
  };
  selectSize = (size: Size_Variations) => {
    this.sizes = [size];
    this.styles = size.modifiers[0]?.collections || [];
    if (this.styles.length == 1) {
      this.addons = this.styles[0].modifiers || [];
      this.selectedAddonVariations = this.addons.flatMap((addon: AddOn) =>
        addon.collections.filter(
          (addonVariation: AddOn_Variations) => addonVariation.isDefault
        )
      );
      if (this.addons.length > 0) {
        this.selectedAddonTab = this.getAddonName(this.addons[0]);
      }
    }
    this.sendDetailstoService();
  };
  deselectSize = () => {
    this.sizes = this.items[0]?.modifiers[0]?.collections || [];
    this.styles = [];
    this.addons = [];
    this.selectedAddonVariations = [];
    this.selectedAddonTab = "";
    this.sendDetailstoService();
  };
  selectStyle = (style: Style_Variations) => {
    this.styles = [style];
    this.addons = style.modifiers || [];
    this.selectedAddonVariations = this.addons.flatMap((addon: AddOn) =>
      addon.collections.filter(
        (addonVariation: AddOn_Variations) => addonVariation.isDefault
      )
    );
    if (this.addons.length > 0) {
      this.selectedAddonTab = this.getAddonName(this.addons[0]);
    }
    this.sendDetailstoService();
  };
  deselectStyle = () => {
    this.styles = this.sizes[0].modifiers[0].collections || [];
    this.addons = [];
    this.selectedAddonVariations = [];
    this.selectedAddonTab = "";
    this.sendDetailstoService();
  };

  getAddonName(addon: AddOn) {
    const name = addon?.displayName;
    const addonType =
      addon?.collections[0]?.alterationCategory === "TOPPINGS"
        ? `( ${addon?.collections[0]?.alterationType} )`
        : "";
    return `${name} ${addonType}`?.trim();
  }

  checkAddonIsRequired(addon: AddOn) {
    const availableAddons = this.selectedAddonVariations.filter(
      (addonVariation) => addonVariation.alterationCategory == addon.displayName
    ).length;
    return availableAddons < addon.min;
  }
  getInsertedAddonVariation(addonVariation: AddOn_Variations) {
    const isSelected = this.selectedAddonVariations.some(
      (addonData) => addonData.displayName == addonVariation.displayName
    );
    return isSelected;
  }
  getInsertedAddonVariationCount(addonVariation: AddOn_Variations): number {
    const count = this.selectedAddonVariations.filter(
      (addonData) => addonData.displayName == addonVariation.displayName
    ).length;
    return count;
  }
  getAddonVariationName(addonVariation: AddOn_Variations) {
    const addonVariationName = addonVariation.displayName;
    const addonVariationType =
      addonVariation.alterationCategory == "TOPPINGS"
        ? "- (" + addonVariation.alterationType + ")"
        : "";
    const price =
      addonVariation?.priceInfo?.price > 0
        ? " - $" + addonVariation?.priceInfo?.price
        : "";
    const alterationQuantity = addonVariation?.alterationQuantity
      ? "- (" + addonVariation.alterationQuantity + ")"
      : "";
    const insertedAddonVariation =
      this.getInsertedAddonVariationCount(addonVariation);
    return `${addonVariationName} ${addonVariationType} ${alterationQuantity} ${price} - (${insertedAddonVariation})`;
  }
  addAddonVariation(addon: AddOn, addonVariation: AddOn_Variations): void {
    const max = addon.max;
    const addonVariationExisted = this.selectedAddonVariations.filter(
      (addonData) => addonData.alterationCategory == addon.displayName
    ).length;
    if (max == addonVariationExisted) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `YOU CAN ONLY SELECT ${max} ADDONS`,
        "danger-snackbar"
      );
      return;
    }
    this.selectedAddonVariations.push(addonVariation);
    this.sendDetailstoService();
  }

  removeAddonVariation(addon: AddOn, addonVariation: AddOn_Variations): void {
    const foundedIndex = this.selectedAddonVariations.findIndex(
      (addonData) =>
        addonData.alterationCategory == addon.displayName &&
        addonData.displayName == addonVariation.displayName
    );
    if (foundedIndex != -1) {
      this.selectedAddonVariations.splice(foundedIndex, 1);
      this.sendDetailstoService();
    }
  }
  changeAlterationType(
    addonVariation: AddOn_Variations,
    addonIndex: number,
    addonVariationIndex: number
  ) {
    const availableQuantities = addonVariation.availableQuantities;
    const lastAlterationQuantity = addonVariation.alterationQuantity;
    if (availableQuantities.length > 1) {
      if (lastAlterationQuantity) {
        const availableQuantitiesExistedOnIndex = availableQuantities.findIndex(
          (value) => value == lastAlterationQuantity
        );
        if (
          availableQuantitiesExistedOnIndex ==
          availableQuantities.length - 1
        ) {
          addonVariation.alterationQuantity = availableQuantities[0];
        } else {
          addonVariation.alterationQuantity =
            availableQuantities[availableQuantitiesExistedOnIndex + 1];
        }
      } else {
        addonVariation.alterationQuantity = availableQuantities[0];
      }
      this.addons[addonIndex].collections[addonVariationIndex] = addonVariation;
    }
    console.log(this.addons[addonIndex].collections[addonVariationIndex]);
  }

}
