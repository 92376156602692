import { AfterContentInit, Component, OnInit, ɵConsole } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormControl,
  FormBuilder,
  FormGroupDirective,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ConnectableObservable } from "rxjs";
import { emitTransactionsService } from "../../core/services/emitTransactions.service";

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
declare var Heartland: any;
@Component({
  selector: "app-payment-success-notify",
  templateUrl: "./success-notify.html",
  styleUrls: ["./success-notify.component.scss"],
  providers: [],
})
export class PaymentSuccessNotifyComponent implements OnInit, AfterContentInit {
  matcher = new MyErrorStateMatcher();
  hide = true;
  hps: any;
  transactionId: any = null;
  queryStringParameters: any = {};
  isCallerCustomer: boolean = false;
  constructor(
    fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private emitTran: emitTransactionsService
  ) {}

  ngOnInit() {
    this.checkRouterUrl();
    this.GetQueryStringParameters();
    window.parent.addEventListener(
      "message",
      this.receiveMessage.bind(this),
      false
    );
  }
  ngAfterContentInit(): void {}

  checkRouterUrl() {
    if (this.router.url === "/payments") {
      this.isCallerCustomer = true;
    }
  }

  receiveMessage(event) {
    console.log("Received data from PArent", event);
    if (event.data != undefined && event.data) {
      console.log(event.data);
      // localStorage.setItem("agentCurrentUser", event.data);
    }
  }

  GetQueryStringParameters() {
    this.queryStringParameters = {};
    this.route.queryParamMap.subscribe((params) => {
      params.keys.forEach((key) => {
        this.queryStringParameters[key] = params.get(key);
      });
    });
    // console.log("Query String Parameters:", this.queryStringParameters);

    if (this.queryStringParameters.HostedPaymentStatus == "Complete") {
      window.parent.postMessage(this.queryStringParameters, "*");
    }
  }
}
