export interface ITipModel {
  Action: TipActions;
  TipValue: number;
  TipType: TipType;
}
export enum TipActions {
  ADD_TIP,
  REFRESH_TIP,
}
export enum TipType {
  DOLLAR_AMOUNT,
  PERCENT_AMOUNT,
}
