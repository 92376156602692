import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatEnum'
})
export class FormatEnumPipe implements PipeTransform {
  
  transform(value: string): string {
    if (!value) return value;
    return value.split('_').map(word => {
      // Check if the word is 'AI' and handle it separately
      if (word === 'AI') {
        return 'AI';
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    }).join(' ');
  }
  
}
