import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotificationService } from '../../core/notification/notification.service';
import { Router, ActivatedRoute } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'account-activate',
  templateUrl: './activate.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {
  actived_token: any;
  userDetails: any;
  statusClass = 'text-success';
  activationHeadText: any = "Account Activated Successfully";
  constructor(
    private voixHttp: VoixHttpService,
    private route: ActivatedRoute) { 
      this.route.params.subscribe( params => this.actived_token = params['actived_token'] );
    }
  
  ngOnInit() {
    this.voixHttp.get('v1/users/account/' + this.actived_token, {})
    .subscribe (
      (res:any) => {
        this.activationHeadText = "Account Activated Successfully";
        this.userDetails = res.user;
      }, error => {
        this.activationHeadText = "Account Activation link is Invalid";
        this.statusClass = 'text-danger';
      }
    );
  }
}
