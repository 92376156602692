import { GlobalApiResponse } from "app/core/models/apiResponse.interface";

export class ActionCreateCart {
  static readonly type = "[CART] CREATE CART";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionAddItemToCart {
  static readonly type = "[CART] ADD ITEM TO CART";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionGetCart {
  static readonly type = "[CART] GET CART";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionRemoveItem {
  static readonly type = "[CART] REMOVE ITEM FROM CART";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionApplyPromo {
  static readonly type = "[PROMO] APPLY PROMO";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionUpdateCartItem {
  static readonly type = "[CART] UPDATE CART ITEM";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionClearCart {
  static readonly type = "[CART] CLEAR CART";
  constructor(public payload: GlobalApiResponse) {}
}
