<div class="side-open" [ngStyle]="{'min-height': docHeight + 'px'}">
  
  <div class="header">
    <h6 class="contact-title">Contact List</h6>
    <mat-form-field appearance="outline" floatLabel="never" class="search-box">
      <input matInput placeholder="Search Contact">
      <mat-icon matPrefix class="s-16 secondary-text mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">search</mat-icon>
    </mat-form-field>
  </div>
  <div class="side-content">
    <mat-list role="list">
        <!-- [popover]="myPopover" [popoverOnHover]="true" -->
      <mat-list-item  class="listitem" role="listitem" *ngFor="let item of listOfContacts">
        <div class="mat-container-ripple" [ngClass]="item.esclate ? 'esclate' : 'no-esclate'" matRipple (click)="startConversation(item)">
          <div class="itemIcon">
            <span class="iconCircle">{{item.restaurant_id}}</span>
          </div>
          <div class="itemDetails">
            <div class="phoneNumber"> {{item.customer_phone | formatUsNumber}}
              <span class="circle" [ngClass]="(isOnline(item) || item.isOnline) ? 'online' : 'offline'"></span>
            </div>
            <div class="customer-name" *ngIf="item?.customer_name"> {{ item.customer_name | titlecase }} </div>  
            <div class="restaurant-name">{{item?.name}}</div>
            <div *ngIf="item?.assignedAgent?.agent_email" class="assigned-name">{{item?.assignedAgent?.agent_email}}</div>
            <div *ngIf="item?.endConversation" class="assigned-name">End Conversation</div>
            <button *ngIf="item.esclate" mat-button mat-stroked-button color="primary" class="assign-to-me" (click)="assignToMe(item)">Assign me</button>
          </div>
        </div>
      </mat-list-item>
      <mat-list-item class="listitem no-list" role="listitem" *ngIf="listOfContacts.length === 0">
        No current Conversation found
      </mat-list-item>
    </mat-list>
  </div>

  <!-- <popover-content #myPopover 
                  title="Last Order Status - 23123" 
                  placement="left"
                  [animation]="true"
                  [closeOnClickOutside]="true" >
    <div class="customer-info">
      <strong>Rajesh</strong> - <span style="color: green">$49.99</span> <span style="float:right">Order Id: 123</span></div>
    <div class="order-item">
      <div>1 large combination pizza</div>
      <div>2 large combination pizza</div>
      <div>3 large combination pizza</div>
    </div>
    <div class="note-text">Note :-  Add more tomato and garlic</div>
  </popover-content> -->

</div>