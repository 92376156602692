import { Component, EventEmitter, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStoreUploadCSVDetails } from '../model/IStore';

@Component({
  selector: 'app-uploaded-store-list',
  templateUrl: './uploaded-store-list.component.html',
  styleUrls: ['./uploaded-store-list.component.scss']
})
export class UploadedStoreListComponent {
  csvHeadings = [
    "Store ID",
    "Phone",
    "First Name",
    "Last Name",
    "Address",
    "City",
    "State",
    "Zip Code",
    "Secondary Phones"
  ];
  form: FormGroup = this.fb.group({
    csv: this.fb.array([]),
  });
  onSubmit = new EventEmitter();
  constructor(private fb: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: Array<IStoreUploadCSVDetails>,){
    const csvArray = this.form.get('csv') as FormArray;
    data.map((item) => {
      csvArray.push(
        this.fb.group({
          StoreID: [item?.['Store ID'] ?? "", Validators.required],
          Phone: [item.Phone ?? "", [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(11), Validators.maxLength(11)]],
          FirstName: [item?.['First Name'] ?? "",Validators.required],
          LastName: [item?.['Last Name'] ?? "",Validators.required],
          Address: [item.Address ?? "",Validators.required],
          City: [item.City ?? "",Validators.required],
          State: [item.State ?? "",Validators.required],
          ZipCode: [item?.['Zip Code'] ?? "" , [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(5), Validators.maxLength(6)]],
          secondary_phones: [item?.['Secondary Phones'] ? item?.['Secondary Phones'].split(';').map(phone => phone.trim()) : []]
        })
      );
    });
  }
  accordionClick(): void {
    this.form.markAllAsTouched()
  }
  get csv() {
    return this.form.controls["csv"] as FormArray;
  }
}
