import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ViewEncapsulation,
} from "@angular/core";
import * as Chartist from "chartist";
import { Router } from "@angular/router";
import { VoixHttpService } from "../core/voix-http/voix-http.service";
import { NotificationService } from "../core/notification/notification.service";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import * as _ from "underscore";
import { AppConstants } from "../app.constants";
import { AuthenticationService } from "../core/auth/authentication.service";
import { MessagingService } from "../core/notification/messaging.service";
import { getRestaurantService } from "../core/auth/restaurant.service";
import { NavbarService } from "../core/navbar/navbar.service";
import { uuid } from "../../../src/assets/js/customScripts.js";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { CartResponseComponent } from "app/cart-response/cart-response.component";
import { environment } from "environments/environment";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { map, Observable, of, startWith } from "rxjs";
import { Permissions } from "app/core/auth/model/permissions.model";
import { PermissionsPipe } from "app/core/pipes/permissions.pipe";
import { ClientNumberComponent } from "app/components/client-number/client-number.component";

interface Store {
  storeId: string;
  storePhone: string;
}

interface Client {
  id: number;
  logo_url: string;
  name: string;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  timezone = AppConstants.TIMEZONE_OFFSET;
  isProd: boolean = environment.production;
  restaurant: any;
  orderData: any = [];
  orderListData: any = [];
  selected: any = 1;
  limit = 10;
  customerObject: any = {};
  dateParams: any;
  userPermissions = Permissions;
  openOrderData: any;
  _: any;
  filteredOptions: Observable<any>;
  searchForm: FormGroup;
  displayedColumns = [
    "id",
    "order_id",
    "datetime",
    "customerphone",
    "agent_email",
    "client_name",
    "external_store_id",
    "order_type",
    "payment_mode",
    "total",
    "action",
  ];
  agentRole: string = "AGENT";
  dataSource: MatTableDataSource<object>;
  statusValue = new FormControl();
  status = AppConstants.ORDER_STATUS;
  tickerLabel = "Today";
  filterStatus: any = AppConstants.DEFAULT_FILTER_STATUS;
  faxStatusLoader: any = [];
  clients: Client[] = [];
  stores: Store[] = [];
  store = new FormControl();
  selectedClient: number;
  storePhone: string;
  storeId: string;
  isAdminBuild = environment['isAdminBuild'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private router: Router,
    private auth: AuthenticationService,
    public msg: MessagingService,
    fb: FormBuilder,
    private currentRestaurant: getRestaurantService,
    private cdRef: ChangeDetectorRef,
    private navService: NavbarService,
    private crypto: CryptoService,
    private emitTrans: emitTransactionsService,
    public dialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private permissionsPipe: PermissionsPipe
  ) {
    if (('isAdminBuild' in environment) && this.isAdminBuild) {
      return;
    }
    this._ = _;
    const user = this.auth.getCurrentUser();
    const role =
      user.roles?.[0]?.role_details?.name || user?.role || this.crypto.DecryptWithoutPromise("agentSecret");
    if (role) {
      this.agentRole = role;
    } else {
      this.agentRole = "AGENT";
    }
    this.dateParams = {
      fromDate: moment().utcOffset(this.timezone).set({ hours: 0 }).format(),
      toDate: moment().utcOffset(this.timezone).format(),
    };
    this.searchForm = fb.group({
      customer_phone: [null],
    });
    currentRestaurant.restaurantSelected$.subscribe((data) => {
      this.setRestaurant(data);
    });
    currentRestaurant.restaurant().subscribe((data) => {
      this.setRestaurant(data);
    });
    this.voixHttp.get(`v1/agent-clients?dashboard_subdomain=${window.location.origin}`, {}, false, ('isAdminBuild' in environment)).subscribe((res: any) => {
      if (res) {
        console.log(res.data);
        this.clients = res.data;
        if (this.clients?.length) {
          this.selectedClient = this.clients[0].id;
          this.getAllStores(this.selectedClient);
        }
      }
    });
  }
  
  AddClientNumber() {
    this.dialog.open(ClientNumberComponent,{
      data:{
        storePhoneForManualOrder: this.storePhone
      }
    });
  }

  openDialog(order_id): void {
    this.voixHttp.get("v1/order/" + order_id).subscribe(
      (response: any) => {
        console.log(response);
        if (response.data[0].order_metadata) {
          const dialogRef = this.dialog.open(CartResponseComponent, {
            width: "800px",
            height: "700px",
            data: response.data[0].order_metadata,
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log("The dialog was closed");
            // this.animal = result;
          });
        } else {
          this.notification.show(
            "danger",
            "notifications",
            "DETAILS NOT FOUND"
          );
        }
      },
      (error) => {
        let errorHandle = JSON.parse(error);
        this.notification.show("danger", "notifications", errorHandle.error);
      }
    );
  }
  setRestaurant(data) {
    this.restaurant = data;
    if (
      !_.isEmpty(this.restaurant) &&
      this.navService.isActiveRouter("dashboard")
    ) {
      this.fetchOrders();
    }
  }
  startAnimationForLineChart(chart) {
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on("draw", function (data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint,
          },
        });
      } else if (data.type === "point") {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    });

    seq = 0;
  }
  startAnimationForBarChart(chart) {
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on("draw", function (data) {
      if (data.type === "bar") {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: "ease",
          },
        });
      }
    });

    seq2 = 0;
  }

  validateRole() {
    // if (!environment.production) return true;
    if (
      this.agentRole == AppConstants.ROLE.ADMIN ||
      this.agentRole === AppConstants.ROLE.SUPERADMIN ||
      this.agentRole === "DEVADMIN"
    )
      return true;
    return false;
  }

  validateRoleTestOrder() {
    if (!environment.production) {
      if (this.agentRole === "AGENT") {
        return false;
      }
      return true;
    }
    if (
      this.agentRole === AppConstants.ROLE.SUPERADMIN ||
      this.agentRole === "DEVADMIN"
    )
      return true;
    return false;
  }

  ngOnInit() {
    if (('isAdminBuild' in environment) && this.isAdminBuild) {
      return;
    }
    this.auth.userDetail().subscribe((response: any) => {
      // console.log(response);
      let userData = { uid: response.user.email };
      this.msg.getPermission(userData);
    });
    this.filteredOptions = this.store.valueChanges.pipe(
      startWith(null),
      map((val) => this.filter(val))
    );
  }

  filter(val) {
    if (!val) {
      return this.stores.sort((a, b) => +a.storeId - +b.storeId);
    }
    return this.stores
      .filter(
        (store) =>
          store?.storePhone?.includes(val) || store?.storeId?.includes(val)
      )
      .sort((a, b) => +a.storeId - +b.storeId);
  }

  refreshTable() {
    this.fetchOrders();
  }

  fetchOrders(limit = 10, page = 1, customerFilter = {}) {
    let that = this;
    let params = Object.assign({ limit: limit, page: page }, this.dateParams);
    params = Object.assign(params, customerFilter);
    if (this.filterStatus) {
      params = Object.assign(params, { status: this.filterStatus });
    }
    if (!_.isEmpty(this.customerObject)) {
      params = Object.assign(params, this.customerObject);
    }
    //console.log(params.toDate);
    params.fromDate = moment(params.fromDate).utc().format();
    params.toDate = moment(params.toDate).utc().format();
    if (this.selectedClient) {
      params.client_id = this.selectedClient;
    }
    if (this.storeId) {
      params.store_id = this.storeId;
    }
    if (!this.permissionsPipe.transform(this.userPermissions.ORDER_VIEW)) {
      const user = this.auth.getCurrentUser();
      params.agent_email = user?.email;
    }
    this.ngxService.start();
    // console.log(params);
    this.voixHttp
      .get("v1/ordersv2", {
        params: params,
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          this.orderData = response;
          this.orderListData = this.orderData.data;

          // this.orderListData = this.orderListData.map((order) => {
          //   let orderMeta = _.findWhere(order.OrdersMetadata, {
          //     order_key: "fax_details",
          //   });
          //   order.fax_status = "-N/A-";
          //   if (orderMeta) {
          //     let faxDetails =
          //       typeof orderMeta.order_value == "string"
          //         ? JSON.parse(orderMeta.order_value)
          //         : orderMeta.order_value;
          //     order.fax_status = faxDetails.status;
          //   }
          //   return order;
          // });
          that.dataSource = new MatTableDataSource(this.orderListData);
          this.ngxService.stop();
          // that.openOrders();
        },
        (error) => {
          console.log(error);
          let errorHandle = JSON.parse(error);
          this.notification.show("danger", "notifications", errorHandle.error);
          this.ngxService.stop();
        }
      );
  }

  submitFilter(searchForm) {
    if (searchForm.value.customer_phone) {
      let customer_phone = decodeURIComponent(
        searchForm.value.customer_phone.trim()
      );
      this.customerObject = {
        search: encodeURIComponent(customer_phone),
      };
    } else {
      this.customerObject = {};
    }
    this.fetchOrders();
  }

  openOrders(limit = 10, page = 1) {
    let that = this;
    let params = Object.assign(
      { limit: limit, page: page, status: "RECEIVED" },
      this.dateParams
    );
    this.voixHttp
      .get("v1/ordersv2", {
        params: params,
      })
      .subscribe(
        (data) => {
          this.openOrderData = data;
          //console.log(this.openOrderData);
        },
        (error) => {
          console.log(error);
          let errorHandle = JSON.parse(error);
          this.notification.show("danger", "notifications", errorHandle.error);
        }
      );
  }

  public handlePage(e: any) {
    this.fetchOrders(e.pageSize, e.pageIndex + 1);
  }

  redirectOrder(row) {
    // this.router.navigate(["/orders", row.id]);
  }

  dashboardDataLoad(dateObject: any) {
    if (dateObject) {
      this.dateParams = {
        fromDate: dateObject.startDate.format(),
        toDate: dateObject.endDate.format(),
      };
      this.tickerLabel = dateObject.element[0].innerText;
      //console.log(dateObject.element[0].innerText);
      this.fetchOrders();
    }
  }

  public ngDoCheck() {
    this.cdRef.detectChanges();
  }

  public newOrder(client: string) {
    let urlGenerate = "TESTING-" + uuid();
    let phoneNumber = "1" + Math.floor(100000000 + Math.random() * 900000000);
    // console.log(this.restaurant);
    const restaurantPhoneNumber =
      !client && this.storePhone
        ? this.storePhone
        : client === "PJI"
        ? 18722486020
        : 19498788304;
    // let restaurantno =
    //   this.restaurant.UserRestaurant.RestaurantPhones[0].phone_number;
    window.location.href =
      "/order-receive/" +
      phoneNumber +
      "/" +
      urlGenerate +
      "/" +
      restaurantPhoneNumber;
    // console.log(
    //   "/order-receive/" +
    //     phoneNumber +
    //     "/" +
    //     urlGenerate +
    //     "/" +
    //     18722486020 +
    //     19498788304
    // );
  }

  public newDriveThruOrder(client: string) {
    let urlGenerate = "TESTING-" + uuid();
    let phoneNumber = "1" + Math.floor(100000000 + Math.random() * 900000000);
    // console.log(this.restaurant);
    const restaurantPhoneNumber =
      !client && this.storePhone
        ? this.storePhone
        : client === "PJI"
        ? 18722486020
        : 19498788304;
    // let restaurantno =
    //   this.restaurant.UserRestaurant.RestaurantPhones[0].phone_number;
    window.location.href =
      "/drive-thru/" +
      urlGenerate +
      "/" +
      restaurantPhoneNumber;
  }

  getAllStores(selectedClient: number) {
    const getSelectClient = this.clients.find(
      (client) => client?.id === selectedClient
    );
    this.fetchOrders();
    this.ngxService.start();
    this.voixHttp
      .getStoresByClient(
        `v1/agent/getallrestaurants?clientcode=${getSelectClient.name}`
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.stores = res?.data ? res.data : [];
            this.store.setValue(null);
            this.selectedClient = selectedClient;
            this.storePhone = null;
            this.ngxService.stop();
          }
        },
        () => {
          this.ngxService.stop();
        }
      );
  }

  handlePaymentMode(payment_mode) {
    // console.log(payment_mode);
    let result: string = "";
    if (payment_mode.length > 20) {
      let parser = JSON.parse(payment_mode);
      parser.forEach((element) => {
        if (result != "") {
          result = result.concat("," + element.paymentType);
        } else {
          result = result.concat(element.paymentType);
        }
      });
      // console.log(JSON.parse(payment_mode))
    } else {
      result = payment_mode;
    }
    return result;
  }

  public statusFilter(event) {
    //console.log(event);
    this.filterStatus = event.value;
    this.fetchOrders();
  }

  public statusChange(row: any) {
    let params = { status: row.status };
    this.voixHttp
      .put(
        "v1/agent/restaurant/" +
          this.restaurant.restaurant_id +
          "/order/" +
          row.id,
        params
      )
      .subscribe(
        (data) => {
          this.fetchOrders();
        },
        (error) => {
          let errorHandle = JSON.parse(error);
          this.notification.show("danger", "notifications", errorHandle.error);
        }
      );
  }

  public checkFaxStatus(row: any) {
    this.faxStatusLoader[row.id] = true;
    this.voixHttp.get("v1/order/" + row.id + "/fax/status").subscribe(
      (data) => {
        this.faxStatusLoader[row.id] = false;
        this.fetchOrders();
      },
      (error) => {
        let errorHandle = JSON.parse(error);
        this.notification.show("danger", "notifications", errorHandle.error);
        this.faxStatusLoader[row.id] = false;
      }
    );
  }

  selectStore(resStore) {
    this.storePhone = resStore?.storePhone ? resStore.storePhone : null;
    this.storeId = resStore.storeId;
    this.fetchOrders();
  }
  
}
