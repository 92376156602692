import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotificationService } from '../../core/notification/notification.service';
import { Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'forgetpassword',
  templateUrl: './forgetpassword.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
  forgetPasswordForm : FormGroup;
  constructor(fb: FormBuilder,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private router: Router) { 
      this.forgetPasswordForm = fb.group({
        'email': [null, [Validators.required, Validators.email]]
      });
    }
  
  ngOnInit() {
    
  }

  submitForm(value: any) {
    this.voixHttp.post('v1/users/forgot', value).subscribe (
      (res:any) => {
        if(res.status) {
          this.notification.show('success','notifications', 'An email message has been sent containing a link to reset the password.');
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', errorHandle.error);
      }
    );
  }

}
