import {
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ComponentService } from "app/core/services/component.service";
import { UtilityService } from "app/core/services/Utility.service";
import { AddNotificationComponent } from "../add-notification/add-notification.component";
import { NotificationListingComponent } from "../notification-listing/notification-listing.component";
import { HostListener } from "@angular/core";
import { INotificationLoadComponentDetail } from "../model/INotification";
import { PermissionsPipe } from "app/core/pipes/permissions.pipe";
import { Permissions } from 'app/core/auth/model/permissions.model';

@Component({
  selector: "app-view-notifications",
  templateUrl: "./view-notifications.component.html",
  styleUrls: ["./view-notifications.component.scss"],
})
export class ViewNotificationsComponent implements OnDestroy {
  @ViewChild("componentPlaceHolder", { read: ViewContainerRef })
  addRoleComponent: ViewContainerRef | undefined;
  initialComponent: string = "NotificationListing";
  defaultButtonText: string = "AddNew";
  formTitle: string;
  isSmallScreen = false;
  isAddNotificationPermission = false;

  constructor(
    private utilityService: UtilityService,
    private componentService: ComponentService,
    public permissionPipe: PermissionsPipe
  ) {}

  ngOnInit() {
    this.utilityService.SetPageTitle("View Notifications");
    this.UpdateSelectedButtonType();
    this.componentService
      .LoadNotificationAddComponentSubscription()
      .subscribe((data) => {
        this.isAddNotificationPermission = this.permissionPipe.transform(Permissions.NOTIFICATION_ADD);
        this.loadComponent(data);
      });
    this.componentService.SetSelectedButtonType("AddNew");
  }
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    const screenWidth = window.innerWidth;
    if (screenWidth < 1335) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  UpdateSelectedButtonType() {
    this.componentService
      .GetSelectedButtonType()
      .subscribe((type) => (this.defaultButtonText = type));
  }
  ngAfterViewInit(): void {
    this.componentService.LoadNotificationsAddModifyComponent({
      ComponentName: this.initialComponent,
    });
  }

  AddNew() {
    this.componentService.SetSelectedButtonType("Back");
    this.componentService.LoadNotificationsAddModifyComponent({
      ComponentName: "AddNotification",
    });
  }

  loadComponent(data: INotificationLoadComponentDetail) {
    let componentName: string = data?.ComponentName;
    this.addRoleComponent?.clear();
    switch (componentName) {
      case "NotificationListing":
        const isViewPermission = this.permissionPipe.transform(Permissions.NOTIFICATION_VIEW);
        if (isViewPermission) {
          this.formTitle = "List Of Notifications";
          this.addRoleComponent?.createComponent(
            NotificationListingComponent,
            {}
          );
        }
        break;
      case "AddNotification":
        if (this.isAddNotificationPermission) {
          this.formTitle = "Add New Notification";
          let refAdd = this.addRoleComponent?.createComponent(
            AddNotificationComponent
          );
          let componentAdd: AddNotificationComponent =
            refAdd?.instance as AddNotificationComponent;
          componentAdd.selectedFormType = "Add";
        }
        break;
      case "ModifyNotification":
        const isModifyPermission = this.permissionPipe.transform(Permissions.NOTIFICATION_EDIT);
        if (isModifyPermission) {
          this.formTitle = "Modify Notification";
          let refModify = this.addRoleComponent?.createComponent(
            AddNotificationComponent
          );
          let componentModify: AddNotificationComponent =
            refModify?.instance as AddNotificationComponent;
          componentModify.selectedFormType = "Modify";
          componentModify.notification = data.Notification;
          componentModify.store = data.Notification.stores;
        }
        break;
    }
  }

  ngOnDestroy(): void {
    this.componentService.selectedClient = null;
  }
}
