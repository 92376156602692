import { Component, Input, ViewChild } from "@angular/core";
import { ComponentService } from "app/core/services/component.service";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from "sweetalert2";
import { INotification } from "../model/INotification";
import * as _ from "underscore";
import { NotificationService } from "app/core/notification/notification.service";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { Client } from "../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { Permissions } from 'app/core/auth/model/permissions.model';

@Component({
  selector: "app-notification-listing",
  templateUrl: "./notification-listing.component.html",
  styleUrls: ["./notification-listing.component.scss"],
})
export class NotificationListingComponent {
  @Input() selectedUsersFromGrid: Array<INotification> = [];
  @ViewChild("paginator") paginator: MatPaginator;
  allStoresSelected: boolean = false;
  multipleNotificationsSelected: boolean = false;
  selectedClient: Client;
  storeID = '';
  status = 'all'
  customUrl: string;
  params = {
    limit: 10,
    page: 1,
    store: "",
  };
  notificationData = {
    count: 0,
  };
  notificationListing: INotification[] = [];
  userPermissions = Permissions;
  constructor(
    private ngxService: NgxUiLoaderService,
    public componentService: ComponentService,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private route: ActivatedRoute
  ) {
    this.selectedClient = this.componentService.selectedClient;
    this.route.queryParams.subscribe((params)=>{
      if(params && params.storeNumber ){
        this.storeID = params.storeNumber 
      }
    })
  }

  handlePage(e: any) {
    this.params = {
      ...this.params,
      page: e.pageIndex + 1,
      limit: e.pageSize,
    };
    this.getNotifications();
  }

  SelectUnSelectAll(event) {
    const checkboxCheckedStatus = event.target.checked;
    this.notificationListing.forEach(
      (x) => (x.Selected = checkboxCheckedStatus)
    );
    this.allStoresSelected = checkboxCheckedStatus;
    this.multipleNotificationsSelected = this.allStoresSelected;
    this.selectedUsersFromGrid = this.notificationListing.filter(
      (x) => x.Selected == true
    );
  }

  OnRowCheckboxClicked(event: any, store: any) {
    var checkboxCheckedStatus = event.target.checked;
    store.Selected = checkboxCheckedStatus;
    var selectedFromGrid = this.notificationListing.filter(
      (x) => x.Selected == checkboxCheckedStatus
    ).length;
    var totalAgents = this.notificationListing.length;
    this.allStoresSelected = checkboxCheckedStatus
      ? selectedFromGrid == totalAgents
      : false;
    this.multipleNotificationsSelected =
      this.notificationListing.filter((x) => x.Selected == true).length >= 2;
    this.selectedUsersFromGrid = this.notificationListing.filter(
      (x) => x.Selected == true
    );
  }

  modifyNotification(modifyNotification: INotification) {
    this.componentService.LoadNotificationsAddModifyComponent({
      ComponentName: "ModifyNotification",
      Notification: modifyNotification,
    });
  }

  getNotifications() {
    let params = { ...this.params, clientCode: this.selectedClient.name, club: true, show: this.status, storeId: this.storeID };
    this.voixHttp.getNotifications('v1/notification', params).subscribe({
      next: (res) => {
        this.notificationListing = res.data.map((notification) => {
          return notification
        });
        this.notificationData.count = res?.totalItems || res?.count;
        this.ngxService.stop();
      },
      error: (error) => {
        this.showErrorMessage(error);
      },
    });
  }

  searchByStoreID(storeID) {
    this.storeID = storeID;
    this.getNotifications();
  }

  statusSelectedForNotification(status) {
    this.status = status;
    this.getNotifications();
  }

  clientSelectedForStores(selectedClient: Client) {
    this.selectedClientForStores(selectedClient);
  }

  selectedClientForStores(selectedClient: Client) {
    this.selectedClient = selectedClient;
    this.componentService.selectedClient = selectedClient;
    this.componentService.setSelectedClientForStore(selectedClient);
    this.getNotifications();
  }

  deleteNotification(notification?: INotification) {
    let msg = "";
    if (notification) {
      msg = "Are you sure you want to delete the notification";
    } else if (!this.selectedUsersFromGrid?.length) {
      return;
    } else {
      const count = this.selectedUsersFromGrid?.length;
      msg = `Are you sure you want to delete the ${count} notification${
        count >= 2 ? "s" : ""
      }?`;
    }
    Swal.fire({
      title: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      let url = "v1/expire-notifications";
      if (result.value) {
        this.ngxService.start();
        let selectedIDs = {
          notificationIds: notification
            ? [notification.notificationId]
            : this.selectedUsersFromGrid.map((val: any) => val.notificationId),
        };
        this.voixHttp.deleteNotification(url, selectedIDs).subscribe(
          () => {
            if (
              this.notificationListing.length === 1 &&
              this.params.page !== 1
            ) {
              this.params.page = this.params.page - 1;
              this.paginator.pageIndex = this.params.page - 1;
            }
            this.getNotifications();
            this.selectedUsersFromGrid = [];
            this.allStoresSelected = false;
            this.multipleNotificationsSelected = false;
            this.ngxService.stop();
            Swal.fire("Notification deleted successfully.", "", "success");
          },
          () => {
            this.ngxService.stop();
            Swal.fire(
              "Notification Not Deleted",
              "There was an error while deleting the notification.",
              "error"
            );
          }
        );
      }
    });
  }

  showErrorMessage(error) {
    this.notification.openSnackBarActionV2(
      "end",
      "top",
      error?.error?.message ||
        error?.message ||
        error?.error ||
        "Something went wrong please try again",
      "danger-snackbar"
    );
    this.ngxService.stop();
  }
}
