import { GlobalApiResponse } from "app/core/models/apiResponse.interface";

export class ActionGetPromo {
  static readonly type = "[PROMO] GET PROMOS";
  constructor(public payload: GlobalApiResponse) {}
}


export class ActionGetSpecialPromo {
  static readonly type = "[PROMO] GET SPECIAL PROMOS";
  constructor(public payload: GlobalApiResponse) {}
}
