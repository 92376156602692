import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'vx-confirm-dialog',
  templateUrl: './alert-dialog.component.html',
})
export class AlertDialogComponent {
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {}

  public heading:string;
  public message:string;
}