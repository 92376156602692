<div id="container">
  <main>
    <ul id="chat">
      <ng-container *ngFor="let chat of conversationSoFar">
        <li class="you" *ngIf="chat.customer">
          <div class="content">
            <span class="status green"></span>
            <h2>Customer</h2>
            <!-- <h3>10:12AM, Today</h3> -->
          </div>
          <div class="triangle"></div>
          <div class="message">
            {{ chat.customer }}
          </div>
        </li>
        <li class="me" *ngIf="chat.agent">
          <div class="content">
            <!-- <h3>10:12AM, Today</h3> -->
            <h2>AI</h2>
            <span class="status blue"></span>
          </div>
          <div class="triangle"></div>
          <div class="message">
            {{ chat.agent }}
          </div>
        </li>
      </ng-container>
    </ul>
    <!-- <footer>
        <textarea placeholder="Type your message"></textarea>
        <a href="#">Send</a>
      </footer> -->
  </main>
</div>
