import { GlobalApiResponse } from "app/core/models/apiResponse.interface";

export class ActionGetMenu {
  static readonly type = "[MENU] GET MENU";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionGetUpsellingItems {
  static readonly type = "[MENU] GET UP SELLING ITEMS";
  constructor(public payload: GlobalApiResponse) {}
}

export class ActionAddSelectedItem {
  static readonly type = "[MENU] ADD SELECTED ITEM IN STATE";
  constructor(public payload: any) {}
}

export class ActionAddSelectedCollections {
  static readonly type = "[MENU] ADD SELECTED COLLECTIONS IN STATE";
  constructor(public payload: any) {}
}

export class ActionResetSelectedItem {
  static readonly type = "[MENU] RESET SELECTED ITEM IN STATE";
  constructor() {}
}
