import { Component, Input } from "@angular/core";
import { FoodItemEntityStruct } from "app/ai-order-recieve/interface/conv-state.interface";
import { CartItemResponse } from "../../../../../mediator-commlibs/interfaces/response/cartItem.interface";
import { aiCartService } from "app/core/services/ai/ai-cart.service";
import { lastValueFrom } from "rxjs";
import { aiMenuService } from "app/core/services/ai/ai-menu.service";
import { NotificationService } from "app/core/notification/notification.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatDialog } from "@angular/material/dialog";
import { CustomerComponent } from "app/order-receive/customer/customer.component";
import {
  CartMenuItem,
  Modifier,
} from "../../../../../mediator-commlibs/interfaces/request/cartItem.interface";
import { UpdateCartComponent } from "../update-cart/update-cart.component";

@Component({
  selector: "app-current-order",
  templateUrl: "./current-order.component.html",
  styleUrls: ["./current-order.component.scss"],
})
export class CurrentOrderComponent {
  @Input() currentFoodItems: FoodItemEntityStruct[] = [];
  @Input() lastFoodItems: FoodItemEntityStruct[] = [];
  @Input() cart: CartItemResponse | null;
  constructor(
    private _aiCartService: aiCartService,
    private _aiMenuService: aiMenuService,
    private notification: NotificationService,
    private ngxService: NgxUiLoaderService,
    public dialog: MatDialog
  ) {}
  getItemNameWithCategory(item: FoodItemEntityStruct) {
    const itemName = item.ItemName || "";
    const category = item.FoodType || "";
    if (
      category &&
      category &&
      itemName?.toLowerCase()?.includes(category?.toLowerCase())
    ) {
      return itemName;
    }
    return `${itemName} ${category}`;
  }
  getAddons(item: FoodItemEntityStruct) {
    const addons = Array.from(new Map(item?.AddOn)?.keys());
    if (addons.length == 0) return "";
    return `${addons?.join(",")}`;
  }
  async removeItem(item: CartMenuItem) {
    try {
      this.ngxService.start();
      console.log({ item });
      const aiParams = this._aiMenuService.aiParams.value;
      const itemRemoved: any = await lastValueFrom(
        this._aiCartService.removeItemFromCart(
          aiParams.convId,
          aiParams.clientCode,
          aiParams.identity,
          aiParams.storeId,
          this.cart.id,
          item.productUUID
        )
      );
      console.log("item removed =>", itemRemoved);
      if (itemRemoved?.data?.cart) {
        this._aiCartService.cartRefreshEvent.next(true);
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Item removed successfully`,
          "success-snackbar"
        );
      } else {
        this.ngxService.stop();
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Operation Failed`,
          "danger-snackbar"
        );
      }
    } catch (error) {
      this.ngxService.stop();
      console.log(error);
    }
  }
  openCart() {
    this.dialog.open(UpdateCartComponent, {
      width: "800px",
      height: "500px",
    });
  }

  activeModifiers(item) {
    return item.modifiers.filter((modifier) => !modifier?.isRemoved);
  }
  filterItemsOfType(modifier: any[]) {
    const data = modifier.filter((data: any) => data.productSubCategory);
    // console.log(data);
    return data;
  }
  getDefault(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.isCustomised && data.alterationCategory
    );
    return data.length;
  }

  // getToppings(modifiers: any) {
  //   let data = modifiers.filter(
  //     (data: any) => data.alterationCategory == "TOPPINGS"
  //   );
  //   return data.length;
  // }

  getDefaultToppings(modifiers: any) {
    const toppings = modifiers.filter(
      (data: Modifier) =>
        data.alterationCategory == "TOPPINGS" &&
        data.isDefault &&
        !data.isRemoved &&
        !data.isCustomised
    );
    if (toppings.length == 0) return null;
    return toppings;
  }

  getRemovedToppings(modifiers: any) {
    const toppings = modifiers.filter(
      (data: Modifier) =>
        data.alterationCategory == "TOPPINGS" && data.isRemoved
    );
    if (toppings.length == 0) return null;
    return toppings;
  }
  getCustomisedToppings(modifiers: any) {
    const toppings = modifiers.filter(
      (data: Modifier) =>
        data.alterationCategory == "TOPPINGS" &&
        !data.isRemoved &&
        data.isCustomised
    );
    if (toppings.length == 0) return null;
    return toppings;
  }
  getbasicInfo(item: CartMenuItem): string {
    const quantity = item.quantity;
    const itemName = item.displayName;
    const Hnh =
      item.halfSpecialties && item?.halfSpecialties.length > 0
        ? "(HALF N HALF)"
        : "";
    return `${quantity} X ${this.itemSize(item.modifiers)} ${this.getItemCrust(
      item.modifiers
    )} ${itemName?.toLowerCase()} ${Hnh}`.replace(/\s+/g, " ");
  }

  itemSize(modifiers: Modifier[]) {
    if (modifiers.length == 0) return "";
    const size = modifiers?.find(
      (modifier) => !modifier.alterationCategory && !modifier.productCategory
    );
    if (!size) return "";
    if (!size?.aiMenu?.audioAIItem?.name) {
      const audioName = this.filterSize(
        this.audioNameForSize(size.name?.trim())
      );
      return audioName;
    }
    const sizeName = this.filterSize(size?.aiMenu?.audioAIItem?.name?.trim());
    return sizeName;
  }
  filterSize(sizeName: string) {
    switch (sizeName?.trim()?.toUpperCase()) {
      case "PDNA":
        return "";

      case "NO_SIZE":
        return "";

      case "NONE":
        return "";
      case "BOWL":
        return "";
      default:
        return sizeName;
    }
  }
  audioNameForSize(size: string) {
    switch (size?.toUpperCase()?.replace(/\s+/g, "")?.trim()) {
      case "INCH_16":
      case "16INCH":
      case "16IN":
        return "extra large";
      case "INCH_14":
      case "INCH_14":
      case "14INCH":
        return "large";
      case "INCH_12":
      case "12INCH":
      case "12IN":
        return "medium";
      case "INCH_10":
      case "10INCH":
      case "10IN":
        return "small";
      case "INCH_8":
      case "8INCH":
      case "8IN":
        return "extra small";
      case "LITRE_2":
        return "2 liter bottle";
      case "OZ_20":
        return "20 ounce bottle";
      case "OZ_16":
        return "16 ounce bottle";
      case "OZ_12":
        return "12 ounce bottle";
      case "PIECE_5":
      case "PIECE_6":
      case "PIECE_8":
      case "PIECE_9":
      case "PIECE_10":
      case "PIECE_15":
      case "PIECE_16":
      case "PIECE_24":
      case "PIECE_30":
        const noOfPieces = parseInt(size.toUpperCase().split("PIECE_")[1]);
        return `${noOfPieces} pieces`.trim().toLowerCase();
      default:
        return size?.toLowerCase()?.trim();
    }
  }
  getItemCrust(modifiers: Modifier[]): string {
    const crust = modifiers.find(
      (modifier) =>
        modifier.productSubType &&
        modifier?.productCategory?.toLowerCase() == "pizza" &&
        modifier?.productSubType?.toLowerCase() != "papa bowl" &&
        modifier?.productSubType?.toLowerCase() != "papadia" &&
        modifier?.productSubType?.toLowerCase() != "papadias"
    );
    const styleVariation =
      crust?.aiMenu?.audioAIItem?.name?.toLowerCase() ||
      crust?.productSubType?.toLowerCase();

    if (styleVariation) return `${styleVariation.replace("crust", "")} crust`;
    return "";
  }

  getModifierInfo(modifiers: Modifier[]) {
    let modifiersInfo: any = {
      sauces: null,
      bake: null,
      cut: null,
      crustFlavour: null,
      cheese: null,
      defaultToppings: null,
      removedToppings: null,
      customisedToppings: null,
    };
    const sauces = this.getSauce(modifiers);
    modifiersInfo.sauces = sauces;
    const bake = this.getBake(modifiers);
    modifiersInfo.bake = bake;
    const cut = this.getCut(modifiers);
    modifiersInfo.cut = cut;
    const crustFlavour = this.getCrustFlavour(modifiers);
    modifiersInfo.crustFlavour = crustFlavour;
    const cheese = this.getCheese(modifiers);
    modifiersInfo.cheese = cheese;
    const defaultToppings = this.getDefaultToppings(modifiers);
    modifiersInfo.defaultToppings = defaultToppings;
    const removedToppings = this.getRemovedToppings(modifiers);
    modifiersInfo.removedToppings = removedToppings;
    const customisedToppings = this.getCustomisedToppings(modifiers);
    modifiersInfo.customisedToppings = customisedToppings;
    const utensils = this.getUtensils(modifiers);
    modifiersInfo.utensils = utensils;
    return modifiersInfo;
  }
  getSauce(modifiers: Modifier[]): string | null {
    const sauces = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory &&
          (modifier.alterationCategory == "Sauce Type" ||
            modifier.alterationCategory == "Sauce Portion" ||
            modifier.alterationCategory == "Dipping Sauce")
      )
      ?.map((modifier) => modifier.displayName);
    if (sauces.length == 0) return null;
    return sauces.join(",");
  }
  getBake(modifiers: Modifier[]): string | null {
    const bake = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory && modifier.alterationCategory == "Bake"
      )
      ?.map((modifier) => modifier.displayName);
    if (bake.length == 0) return null;
    return bake.join(",");
  }
  getCut(modifiers: Modifier[]): string | null {
    const cut = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory && modifier.alterationCategory == "Cut"
      )
      ?.map((modifier) => modifier.displayName);
    if (cut.length == 0) return null;
    return cut.join(",");
  }
  getCrustFlavour(modifiers: Modifier[]): string | null {
    const crustFlavor = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory &&
          modifier.alterationCategory == "Crust Flavor"
      )
      ?.map((modifier) => modifier.displayName);
    if (crustFlavor.length == 0) return null;
    return crustFlavor.join(",");
  }
  getCheese(modifiers: Modifier[]): string | null {
    const cheese = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory && modifier.alterationCategory == "Cheese"
      )
      ?.map((modifier) => modifier.displayName);
    if (cheese.length == 0) return null;
    return cheese.join(",");
  }
  getUtensils(modifiers: Modifier[]): string | null {
    const utensils = modifiers
      ?.filter(
        (modifier) =>
          modifier.alterationCategory &&
          modifier.alterationCategory == "Utensils"
      )
      ?.map((modifier) => modifier.displayName);
    if (utensils.length == 0) return null;
    return utensils.join(",");
  }
  getToppings(modifiers: Modifier[]): Modifier[] | null {
    const toppings = modifiers?.filter(
      (modifier) =>
        modifier.alterationCategory && modifier.alterationCategory == "TOPPINGS"
    );

    if (toppings.length == 0) return null;
    return toppings;
  }
}
