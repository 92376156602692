import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  ActionAddSelectedCollections,
  ActionAddSelectedItem,
  ActionGetMenu,
  ActionGetUpsellingItems,
  ActionResetSelectedItem,
} from "../action/menu.action";
import { tap } from "rxjs/internal/operators/tap";
import { catchError } from "rxjs/operators";
import { IntergrationService } from "../../integration/integration.service";
import { NotificationService } from "../../notification/notification.service";
import { compress, decompress } from "compress-json";

export const menuDefaultState = {
  message: null,
  data: null,
  menu2: null,
  upSellingItems: null,
  statusCode: null,
  selectedItem: null,
  selectedCollections: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};

export interface menuDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: null;
  menu2: null;
  upSellingItems: null;
  isError?: boolean;
  selectedItem: any;
  selectedCollections: any;
  errorMessage?: string;
}
@State<menuDefaultState>({
  name: "menu",
  defaults: menuDefaultState,
})
@Injectable()
export class menuState {
  constructor(
    private integrationService: IntergrationService,
    private notifier: NotificationService
  ) {}

  @Selector()
  static getSelectedCollection(state: menuDefaultState) {
    return state.selectedCollections;
  }

  @Selector()
  static getSelectedItem(state: menuDefaultState) {
    return state.selectedItem;
  }
  @Selector()
  static getLoader(state: menuDefaultState) {
    return state.isloaded;
  }
  @Selector()
  static getMenuDetails(state: menuDefaultState) {
    return state.data;
  }
  @Selector()
  static getUpSellingItems(state: menuDefaultState) {
    return state.upSellingItems;
  }
  @Selector()
  static getMenu2(state: menuDefaultState) {
    return state.menu2;
  }

  @Action(ActionGetMenu)
  async getMenu(
    { setState, getState, patchState }: StateContext<menuDefaultState>,
    { payload }
  ) {
    const menu = getState();
    // console.log("menu State");

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        menu2: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      if (payload.data.categories) {
      }
      const data = payload.data.categories
        ? payload.data
        : decompress(payload.data);
      const menuState = {
        message: "Successfull",
        isloaded: true,
        data: data.categories,
        menu2: data.categories,
        statusCode: payload.statusCode,
      };
      patchState(menuState);
      // this.notifier.showV2(
      //   "success",
      //   "notifications",
      //   payload.message,
      //   "center"
      // );
    }
  }

  @Action(ActionGetUpsellingItems)
  getUpSellingItems(
    { setState, getState, patchState }: StateContext<menuDefaultState>,
    { payload }
  ) {
    const menu = getState();
    patchState({
      ...menu,
      upSellingItems: payload.data
    });
  }

  @Action(ActionAddSelectedItem)
  addSelectedItemInState(
    { setState, getState, patchState }: StateContext<menuDefaultState>,
    { payload }
  ) {
    const menu = getState();
    // console.log(menu);
    const item = payload;
    patchState({
      ...menu,
      selectedItem: item,
      selectedCollections: item.modifiers[0].collections,
    });
  }

  @Action(ActionAddSelectedCollections)
  addSelectedCollectionInState(
    { setState, getState, patchState }: StateContext<menuDefaultState>,
    { payload }
  ) {
    const menu = getState();

    const collections = payload;
    // console.log(payload);
    patchState({
      ...menu,
      selectedCollections: collections,
    });
  }

  @Action(ActionResetSelectedItem)
  resetSelectedItem({
    setState,
    getState,
    patchState,
  }: StateContext<menuDefaultState>) {
    const menu = getState();

    patchState({
      selectedItem: null,
      selectedCollections: null,
    });
  }
}
