import { Component,  Output, EventEmitter, Inject, ViewEncapsulation } from '@angular/core';
import { UcfirstPipe } from '../../core/pipes/ucfirst.pipe';
import { TagReplacePipe } from '../../core/pipes/tag-replace.pipe';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import * as _ from 'underscore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-pre-responses',
  templateUrl: './pre-responses.component.html',
  styleUrls: ['./pre-responses.scss', '../order-receive.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ UcfirstPipe, TagReplacePipe ]
})
export class PreResponsesComponent {
  @Output() selectedResponse: EventEmitter<any>  = new EventEmitter();
  responseData: any;
  allResponses: any = [];
  allTags: any = [];
  allTagRespones: any = [];
  replaceVariableValues = {'address': '', 'open': '10:00 am', 'close': '10:00 pm', 'deliveryFee': '', 'radius': ''};
  constructor(public dialogRef: MatDialogRef<PreResponsesComponent>, private tagReplace: TagReplacePipe,
    private voixHttp: VoixHttpService,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.responseData = data;
    
    let address = this.responseData.restaurant.address;
    this.replaceVariableValues.address = address;
    this.replaceVariableValues.deliveryFee = this.responseData.restaurant.delivery_fee;
    this.replaceVariableValues.radius = this.responseData.restaurant.delivery_radius;
  
    let preResponses = this;
    this.responseData.responses.forEach(function(value, index) {
      value.text = preResponses.tagReplace.transform(value.text, preResponses.replaceVariableValues);
      preResponses.allResponses.push(value);
      if(value.tag) {
        preResponses.allTags.push(value.tag);
      }
    });
    this.allTags =  _.uniq(this.allTags, 'id');
    this.filterResponse();
  }

  filterResponse() {
    let preResponses = this;
    preResponses.allTagRespones[this.allTags.length] = {};
    preResponses.allTagRespones[this.allTags.length].tag = {name: 'other'};
    preResponses.allTagRespones[this.allTags.length].responses = [];

    this.allTags.forEach(function(value, index) {
      preResponses.allTagRespones[index] =  {};
      preResponses.allTagRespones[index].tag = value;
      preResponses.allTagRespones[index].responses = [];
      preResponses.allResponses.forEach(function(val, i) {
        if(val.tag_id == value.id) {
          preResponses.allTagRespones[index].responses.push(val);
        } else {
          if(_.findWhere(preResponses.allTagRespones[preResponses.allTags.length].responses, {id: val.id}) === undefined && val.tag_id == 0) {
            preResponses.allTagRespones[preResponses.allTags.length].responses.push(val);
          }
        }
      });
    });
  }

  responseSelect(response, responseId) {
    this.selectedResponse.emit(response);
    this.updatePopularity(responseId);
  }

  updatePopularity(responseId) {
    this.voixHttp.post('v1/custom-responses/' + responseId + '/popularity/add', {})
    .subscribe( response => {
      //console.log(response);
    });
  }
}
