import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
  public errorMessage = 'Woops, the page you\'re looking for isn\'t there!';
  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  goToPreviousPage() {
    this.router.navigate(["/welcome"]);
  }

}
