import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/shared/shared.module';
import { CommonModule } from '@angular/common';
import { ChatSmsRoutes } from './sms-messenger.routing';
import { SmsMessengerUIComponent } from './ui/ui.component';
import { ChatWindowComponent } from './ui/chat-window/chat-window.component';
import { SidebarComponent } from './ui/sidebar/sidebar.component';
import { MaterialModule } from '../core/modules/material.module';
import { FormsModule } from '@angular/forms';
// import { PopoverModule } from 'ngx-bootstrap/popover';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    // PopoverModule,
    RouterModule.forChild(ChatSmsRoutes)
  ],
  declarations: [SmsMessengerUIComponent, SidebarComponent, ChatWindowComponent],
  exports: []
})
export class SmsMessengerModule {}
