import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";

import { Observable, of } from "rxjs";
import { debounceTime, map, startWith, switchMap, tap } from "rxjs/operators";
import * as moment from "moment";

import Swal from "sweetalert2";
import { Address } from "app/core/directives/ngx-google-places-autocomplete/objects/address";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import {
  customerState,
  customerDefaultState,
} from "app/core/ngxs/state/customer.state";
import { storeState, storeDefaultState } from "app/core/ngxs/state/store.state";
import { NotificationService } from "app/core/notification/notification.service";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { generalService } from "app/core/services/general.service";
import { orderTypes } from "../../../../mediator-commlibs/interfaces/response/store.interface";
import { Permissions } from "app/core/auth/model/permissions.model";
import { AddressType } from "../../../../mediator-commlibs/interfaces/request/cart.interface";
import { uuid } from "assets/js/customScripts";
import { Location } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";

declare var google: any;
//TODO:  [extends ISuggestionRow ] was removed
interface myCustomResult {
  img: string;
  value: string;
  icon: string;
}
@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit, AfterViewInit {
  cartform: FormGroup;
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  @Select(customerState.getCustomer)
  customerPastOrder: Observable<customerDefaultState>;
  @Select(customerState.getCustomerLoaded)
  customerStateLoaded: Observable<customerDefaultState>;
  @Select(storeState.getStore) storeState: Observable<storeDefaultState>;
  @Output() emitData = new EventEmitter();
  datetimenew: any;
  formValue: any;
  customerDetails: any;
  customerExists: boolean = false;
  addresses: any = [];
  googleaddress: any = [];
  address$: Observable<any>;
  firstNameControl = new FormControl("");
  firstNameOptions: Observable<any>;
  lastNameControl = new FormControl("");
  customerAddressControl = new FormControl("");
  dt1: any;
  dtOutofBound: any;
  dateOrder: any = localStorage.getItem("tdx");
  lastNameOptions: Observable<any>;
  selectedCustomer: any;
  storedateTime: any;
  isEmail: boolean = false;
  socket: any;
  uuid: any;
  loyaltyStatus: string = "";
  loyaltyId: string = "";
  selectedOrderTypeObj: orderTypes;
  acceptingCurrentOrders: boolean = true;
  storeDetailV2: any;
  public startAt: any;
  public min: any;
  public max: any;
  cartExist: boolean = false;
  cartOrder: boolean = false;
  usedAddressClicked: Boolean = false;
  isBusiness: FormControl = new FormControl(
    { value: false, disabled: false },
    Validators.required
  );
  userPermissions = Permissions;
  addressTypes = AddressType;
  addressTypeLocation = false;
  selectedCustomerAddress: string;
  validationFlag: boolean = false;
  addressCheck: string;
  verifyAddress: boolean;
  getPlaceFlag: boolean = false;
  displayedStores: any[] = []; // Items to be displayed initially
  storesToShow = 3;
  isAddressOutOfBound = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private ngxsStore: Store,
    @Optional() public dialogRef: MatDialogRef<CustomerComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public order_receive: any,
    private emitTrans: emitTransactionsService,
    private notification: NotificationService,
    private cryptoService: CryptoService,
    private general: generalService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private ngxService: NgxUiLoaderService
  ) {
    this.addressTypeLocation = general.addressTypeLocation;
    emitTrans.isBusiness$.subscribe((data) => this.isBusiness.patchValue(data));
    this.getPhoneNumber();
    const baseName = this.router.url.split('/').find(portion => !!portion);
    const phoneNumSettings = [
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10),
      Validators.maxLength(12),
    ]
    if(baseName != 'drive-thru'){
      phoneNumSettings.unshift(Validators.required)
    }

    
    this.cartform = this.fb.group({
      apt_suite: [null],
      cartId: [""],
      customer: this.fb.group({
        deliveryAddress: this.fb.group({
          city: [null],
          state: [null],
          streetLine1: [null],
          streetLine2: [this.streetLine2],
          streetLine3: [null],
          streetLine4: [null],
          zip: [null],
          deliveryInstruction: [null],
          addressType: [null],
          locationName: [null],
        }),
        id: [""],

        firstName: [
          null,
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
        ],
        lastName: [
          null,
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
        ],
        phone: [
          this.phone,
          [
            ...phoneNumSettings
          ],
        ],
        vehicleInfo: this.fb.group({
          make: [""],
          type: [""],
          color: [""],
        }),
        email: [
          "",
          [
            Validators.pattern(
              "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
            ),
          ],
        ],
      }),
      orderReadyDate: [null],
      orderReadyDateV2: [null],
      orderDestination: [null, [Validators.required]],
      notes: [""],
      storeId: [this.order_receive.restaurant_details.storeId || ""],
    });
    this.customer_detail.patchValue({
      phone: this.phone,
    });

    this.emitTrans.recieveAddressOutOfBound$.subscribe((payload) => {
      if (!!payload && payload.data && payload.data.length > 0) {
        this.storeList = payload.data.map((item: any, index: number) => {
          index == 0 ? (item.expanded = true) : (item.expanded = false);
          return item;
        });
        this.displayedStores = this.storeList.slice(0, this.storesToShow);
        this.isAddressOutOfBound = true;
      } else {
        this.storeList = [];
        this.displayedStores = [];
      }
      this.ngxService.stopLoader("customerLoader");
    });

    this.emitTrans.recieveAddress$.subscribe((data) => {
      if (data && data.length > 0) {
        this.googleaddress = data;
      } else {
        this.googleaddress = [];
        if (this.usedAddressClicked && data && data.length == 0) {
          this.emitTrans.isBusiness$.next(true);
          this.emitTrans.requestAddress$.next({
            input:
              this.cartform
                .get("customer")
                .get("deliveryAddress")
                .get("streetLine4").value ||
              this.streetLine4 ||
              this.selectedCustomerAddress,
            isBusiness: this.isBusiness.value,
          });
          this.usedAddressClicked = false;
        }
      }
    });
    this.emitTrans.isCartExist$.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        this.cartExist = res;
      }
    });
    this.cartState.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        this.cartExist = true;
        if (res.cartMenuItems.length > 0) {
          this.cartOrder = true;
        } else {
          this.cartOrder = false;
        }
      } else {
        this.cartOrder = false;
      }
    });

    localStorage.setItem("cln", "false");
    this.cartform.patchValue({ cartId: "" });
    // console.log(this.cartform.value);
    this.getCustomerInfo();

    this.vehicle_brandsControl.valueChanges.subscribe((res: any) => {
      if (res) {
        // console.log(res, "Hello");
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .patchValue(res);
      } else {
        if (this.cartform.get("orderDestination").value == "DRIVE_UP_PICK_UP") {
          // console.log("HEY");
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("make")
            .patchValue("");
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("make")
            .setValidators([Validators.required]);
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("make")
            .updateValueAndValidity();
        }
      }
    });

    this.vehicle_typeControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .patchValue(res);
      } else {
        if (this.cartform.get("orderDestination").value == "DRIVE_UP_PICK_UP") {
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("type")
            .patchValue("");
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("type")
            .setValidators([Validators.required]);
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("type")
            .updateValueAndValidity();
        } else {
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("type")
            .clearValidators();
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("type")
            .updateValueAndValidity();
        }
      }
    });
    this.vehicle_colorControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .patchValue(res);
      } else {
        if (this.cartform.get("orderDestination").value == "DRIVE_UP_PICK_UP") {
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("color")
            .patchValue("");
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("color")
            .setValidators([Validators.required]);
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("color")
            .updateValueAndValidity();
        } else {
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("color")
            .clearValidators();
          this.cartform
            .get("customer")
            .get("vehicleInfo")
            .get("color")
            .updateValueAndValidity();
        }
      }
    });
    if (order_receive.isError) {
      this.isAddressValidate = false;
      const isAddressOutOfRange = this.general.isAddressOutOfRange;
      if (order_receive.statusCode === 406 && isAddressOutOfRange) {
        this.getStoreList();
        // .subscribe((list) => {
        //   this.storeList = order_receive.errorMessage.storeList;
        //   this.displayedStores = this.storeList.slice(0, this.storesToShow);
        //   this.isAddressOutOfBound = true;
        // });
      }
    }
    if (order_receive.isEdit) {
      this.isEditPreCondition(order_receive);
    } else {
      this.getDateValidation();
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("addressType")
        .setValue(this.addressTypeLocation ? AddressType.residential : "");
    }
  }
  public customResults: Array<myCustomResult> = [];
  selectedOrderType: string;

  @Input() restaurant_details: any;
  @Input() restaurantName: any;
  customerAddress: any;

  isApiLoaded = false;
  phone: any;
  orderTypes: orderTypes[];
  firstName: string;
  lastName: string;
  vehicle_colors: any[] = [];
  vehicle_brands: any[] = [];
  vehicle_type: any[] = [];
  vehicle_brandsControl = new FormControl("");
  vehicleBrandOptionsV2: Observable<any>;
  vehicle_typeControl = new FormControl("");
  vehicleTypeOptionsV2: Observable<any>;
  vehicle_colorControl = new FormControl("");
  isAddressValidate: boolean = false;
  vehicleColorOptionsV2: Observable<any>;
  storeList: any[] = [];
  setBusinness(value: boolean) {
    if (!this.isBusiness.disabled) {
      const deliveryInstructionControl = this.cartform
        ?.get("customer")
        ?.get("deliveryAddress")
        ?.get("deliveryInstruction");
      if (this.isBusiness.value && !value) {
        deliveryInstructionControl.patchValue("");
      } else {
        deliveryInstructionControl.patchValue(
          this.cartform
            ?.get("customer")
            ?.get("deliveryAddress")
            ?.get("deliveryInstruction").value
        );
      }
      this.emitTrans.isBusiness$.next(value);
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        .setValue("");
    }
  }

  storeDT() {
    let DT = this.cryptoService.DecryptWithoutPromise("startAtDT");
    this.startAt = DT.startAt;
    this.min = DT.min;
    this.max = DT.max;
  }
  clearDateV2() {
    this.cartform.patchValue({
      orderReadyDateV2: null,
    });
    this.order_receive.cart.orderReadyDateV2 = null;
    this.storeDT();
  }

  clearDate() {
    this.cartform.patchValue({
      orderReadyDate: null,
    });
    this.order_receive.cart.orderReadyDate = null;
    this.storeDT();
  }

  toggleEffect(index: number) {
    this.displayedStores.map((item: any, i: number) => {
      if (i == index) item.expanded = true;
      else item.expanded = false;
    });
    this.dtOutofBound = null;
    this.cartform.value.orderReadyDateV2 = null;
  }
  setValidationFlag() {
    this.validationFlag = true;
  }
  setCustomerAddress(usedAddress) {
    // console.log(usedAddress);
    // Save the selected address to the property
    this.selectedCustomerAddress =
      usedAddress.streetLine1 +
      " " +
      usedAddress.city +
      " " +
      usedAddress.state;
    this.emitTrans.isBusiness$.next(false);
    this.usedAddressClicked = true;
    const address = usedAddress.streetLine1;
    this.streetLine2 = null;
    this.streetLine4 = null;
    this.streetLine1 = null;
    this.zip = null;
    this.customerAddress = null;
    this.customerAddress = address || "";
    this.cartform.patchValue({
      apt_suite: usedAddress.streetLine2?.toUpperCase(),
    });
    this.streetLine2 = this.cartform.value.apt_suite
      ? this.cartform.value.apt_suite
      : "";
    const postalcode = usedAddress?.zip.includes("-")
      ? usedAddress?.zip?.split("-")[0]
      : usedAddress?.zip;
    this.zip = postalcode;
    this.city = usedAddress?.city;
    this.state = usedAddress?.state;
    this.streetLine1 = this.customerAddress;
    const streetNumber = this.customerAddress?.split(" ")[0] || "";
    this.streetLine4 =
      `${this.customerAddress} ${this.city} ${this.state}`.toUpperCase();
    // this.cartform
    //   .get("customer")
    //   .get("deliveryAddress")
    //   .patchValue({ streetLine4: this.streetLine4 });
    this.streetLine3 = streetNumber;
    // this.setBusinness(true)
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4")
      .patchValue(
        `${usedAddress.streetLine1} ${usedAddress.city} ${usedAddress.state}`.toUpperCase()
      );

    this.addressCheck = address;
    this.isAddressValidate = true;
    this.onChangeAddress(address);
  }
  getCustomerInfo() {
    this.customerPastOrder.subscribe((res: any) => {
      if (res) {
        this.customerDetails = res.customer;

        if (res.customer) {
          this.customerExists = true;
        }
        if (res.deliveryAddress.length > 0) {
          for (let index = 0; index < res.deliveryAddress.length; index++) {
            const element = res.deliveryAddress[index];
            let address: string =
              element.streetLine1 +
              " " +
              element.streetLine2 +
              " " +
              element.streetLine3 +
              " " +
              element.streetLine4 +
              " " +
              element.city +
              " " +
              element.state +
              " " +
              element.zip;

            this.addresses.push(element);
          }
          // console.log(this.addresses);
          this.address$ = this.addresses;
        }
        if (this.customerDetails && this.customerDetails.length > 0) {
          let customer = this.customerDetails.filter(
            (data: any) => data.loyaltyStatus == "LOYALTY"
          );
          customer = customer[0];
          if (customer) {
            if (customer.email) {
              if (customer.email != "") {
                this.isEmail = true;
              }
            } else {
              this.isEmail = false;
            }

            this.selectedCustomer = customer.id;
            this.loyaltyStatus = customer.loyaltyStatus;
            if (customer.loyaltyId) {
              this.loyaltyId = customer.loyaltyId;
            }
            if (!this.order_receive.isEdit) {
              this.setCustomerDetail(customer.id);
            }
          }
        }
      }
    });
  }

  getDateValidation() {
    this.emitTrans.setTimeZone$.subscribe((res: any) => {
      if (res) {
        this.dt1 = res;
        // console.log(this.dt1);
        this.storedateTime = new Date(
          moment(this.dt1)
            // .add(60 * 60 * 24 * 1000)
            .format()
        );
        this.min = new Date(
          moment(this.dt1)
            // .add(60 * 60 * 24 * 1000)
            .format()
        );
        this.max = new Date(
          moment(this.dt1)
            // .add(60 * 60 * 24 * 1000)
            .add("20", "days")
            .format()
        );
        // console.log(this.storedateTime);
        this.startAt = this.storedateTime;
        this.cryptoService.Encrypting("startAtDT", {
          startAt: this.startAt,
          max: this.max,
          min: this.min,
        });
      }
    });
  }
  removeTimeZonePart(dateString) {
    let finalDate = "";

    if (dateString.split("+").length > 1) {
      let b = dateString.split("+");

      finalDate = b[0];
    } else {
      let b = dateString.split("-");

      if (b.length > 1) {
        b.pop();
        finalDate = b.join("-");
      }
    }

    return finalDate;
  }

  isEditPreCondition(order_receive: any) {
    order_receive.isError
      ? (this.isAddressValidate = false)
      : (this.isAddressValidate = true);
    let orderDate = null;
    if (localStorage.getItem("ord")) {
      orderDate = this.cryptoService.DecryptWithoutPromise("ord");
      order_receive.cart.orderReadyDate = orderDate;
    }

    if (order_receive.cart.customer.email != "") {
      this.isEmail = true;
    } else {
      this.isEmail = false;
    }
    // console.log(this.placesRef.place.formatted_address)
    // console.log(orderDate);
    if (!order_receive.cart.orderReadyDate) {
      this.getDateValidation();
    } else {
      let orderD =
        orderDate != null
          ? orderDate
          : this.removeTimeZonePart(order_receive.cart.orderReadyDate);
      // console.log(orderD);
      this.cartform.patchValue({
        orderReadyDate: moment(new Date(orderD)).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        ),
      });
      this.storeDT();

      // order_receive.cart.orderReadyDate =
      //   order_receive.cart.orderReadyDate.replace("+0000+0000", "");
      // order_receive.cart.orderReadyDate =
      //   order_receive.cart.orderReadyDate.replace("+0000", "");
      //   // order_receive.cart.orderReadyDate =
      //   // order_receive.cart.orderReadyDate.replace("-0700", "");
      order_receive.cart.orderReadyDate =
        orderDate != null
          ? orderDate
          : this.removeTimeZonePart(order_receive.cart.orderReadyDate);
      // console.log(order_receive.cart.orderReadyDate);
      let dt = this.cryptoService.DecryptWithoutPromise("startAtDT");
      this.max = dt.max;
      this.min = dt.min;
      this.cryptoService.Encrypting("startAtDT", {
        startAt: order_receive.cart.orderReadyDate,
        max: this.max,
        min: this.min,
      });
      dt = this.cryptoService.DecryptWithoutPromise("startAtDT");
      this.startAt = dt.startAt;
      // console.log(this.min);
    }
    this.dt1 = this.dateOrder;
    // console.log(order_receive.cart);
    this.cartform.patchValue(order_receive.cart);
    this.customer_detail.patchValue({
      ...order_receive.cart.customer,
      // phone: this.phone,
    });
    this.selectedCustomer = order_receive.cart.customer.id;
    this.delivery_address.patchValue(
      order_receive.cart.customer.deliveryAddress
    );
    const { addressType } = order_receive?.cart?.customer?.deliveryAddress;
    if (
      this.addressTypeLocation &&
      addressType &&
      addressType !== this.addressTypes.residential
    ) {
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        ?.setValidators([Validators.required]);
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        ?.updateValueAndValidity();
    }
    if (order_receive.cart.customer.vehicleInfo) {
      this.vehicleInfo.patchValue(order_receive.cart.customer.vehicleInfo);

      this.vehicle_brandsControl.patchValue(
        order_receive.cart.customer.vehicleInfo.make
      );
      this.vehicle_typeControl.patchValue(
        order_receive.cart.customer.vehicleInfo.type
      );
      this.vehicle_colorControl.patchValue(
        order_receive.cart.customer.vehicleInfo.color
      );
    }

    this.streetLine1 = order_receive.cart.customer.deliveryAddress.streetLine1;
    this.streetLine2 = order_receive.cart.customer.deliveryAddress.streetLine2;
    this.streetLine3 = order_receive.cart.customer.deliveryAddress.streetLine3;
    this.streetLine4 = order_receive.cart.customer.deliveryAddress.streetLine4;
    this.zip = order_receive.cart.customer.deliveryAddress.zip;
    this.state = order_receive.cart.customer.deliveryAddress.state;
    this.city = order_receive.cart.customer.deliveryAddress.city;

    if (order_receive.cart.orderDestination == "DELIVERY" && this.streetLine4) {
      this.selectedCustomerAddress = this.streetLine4;
    }
  }
  storeMessage: string = "";
  ngOnInit() {
    this.emitTrans.recievePlace$.subscribe((data: any) => {
      if (data) {
        this.lineItemAddressChange(data);
      }
    });
    this.storeState.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        this.storeDetailV2 = res;
        this.storeMessage = res.store.storeStatusReasonMessage;
        this.vehicle_colors = res.store.vehicles
          ? res.store.vehicles.colors
          : [];
        this.vehicle_type = res.store.vehicles ? res.store.vehicles.types : [];
        this.vehicle_brands = res.store.vehicles
          ? res.store.vehicles.vehicleMakes
          : [];
        this.orderTypes = res.store.orderTypes;
        // if(res.store.deliveryFlag && res.store.pickupFlag){
        //   this.orderTypes = res.store.orderTypes;
        // }else if(res.store.deliveryFlag && !res.store.pickupFlag){
        //   this.
        // }
      }
    });
    this.vehicleBrandOptionsV2 = this.vehicle_brandsControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.vehicleTypeOptionsV2 = this.vehicle_typeControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterType(value || ""))
    );
    this.vehicleColorOptionsV2 = this.vehicle_colorControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterColor(value || ""))
    );
    // console.log(this.order_receive);
  }
  datetime: any;

  onChangeAddress(value) {
    if (value) {
      this.isBusiness.disable();

      this.emitTrans.requestAddress$.next({
        input: value,
        isBusiness: this.isBusiness.value,
      });
    } else {
      this.isBusiness.enable();
    }
  }

  ngAfterViewInit(): void {
    const deliveryInstructionControl = this.cartform
      ?.get("customer")
      ?.get("deliveryAddress")
      ?.get("deliveryInstruction");
    const streetline4Control = this.cartform
      ?.get("customer")
      ?.get("deliveryAddress")
      ?.get("streetLine4");
    this.cartform.get("orderDestination").valueChanges.subscribe((val) => {
      // console.log(val);

      streetline4Control.setValue("");
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        .clearValidators();
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        ?.updateValueAndValidity();
      if (val == "DELIVERY") {
        this.emitTrans.isBusiness$.next(false);
        this.cartform
          .get("customer")
          ?.get("deliveryAddress")
          ?.get("addressType")
          .setValue(this.addressTypeLocation ? AddressType.residential : "");
        if (!this.order_receive.isEdit) {
          const deliveryInstructionControl = this.cartform
            ?.get("customer")
            ?.get("deliveryAddress")
            ?.get("deliveryInstruction");
          deliveryInstructionControl.patchValue("");
        }
        this.isAddressValidate = false;
        this.cartform
          .get("customer")
          .get("deliveryAddress")
          .get("selectedCustomerAddress")
          ?.setValidators([Validators.required]);
        this.cartform.get("customer").get("deliveryAddress").get("streetLine4");
        this.cartform
          .get("customer")
          .get("deliveryAddress")
          .get("streetLine4")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .updateValueAndValidity();
      } else if (val == "DRIVE_UP_PICK_UP") {
        this.cartform
          .get("customer")
          ?.get("deliveryAddress")
          ?.get("addressType")
          .setValue("");
        this.cartform
          .get("customer")
          ?.get("deliveryAddress")
          ?.get("locationName")
          .setValue("");
        if (deliveryInstructionControl?.value?.includes("Business name:")) {
          deliveryInstructionControl.setValue("");
        }
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .setValidators([Validators.required]);
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .setValidators([Validators.required]);
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .setValidators([Validators.required]);
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .updateValueAndValidity();
      } else {
        this.cartform
          .get("customer")
          ?.get("deliveryAddress")
          ?.get("addressType")
          .setValue("");
        this.cartform
          .get("customer")
          ?.get("deliveryAddress")
          ?.get("locationName")
          .setValue("");
        if (deliveryInstructionControl?.value?.includes("Business name:")) {
          deliveryInstructionControl.setValue("");
        }
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("make")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("type")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("vehicleInfo")
          .get("color")
          .updateValueAndValidity();
        this.cartform
          .get("customer")
          .get("deliveryAddress")
          .get("streetLine4")
          .clearValidators();
        this.cartform
          .get("customer")
          .get("deliveryAddress")
          .get("streetLine4")
          .updateValueAndValidity();
      }
    });
    this.delivery_address.patchValue(
      this.order_receive?.cart?.customer?.deliveryAddress || ""
    );
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4")
      .setValue("");

    this.isAddressOutOfBound = false;
    this.displayedStores = [];
    this.storeList = [];
    if(this.customerDetails?.length > 0) {
      this.cartform.get('customer.email').clearValidators();
    }
    this.cdr.detectChanges();
  }
  onChangeDeliveryPickupTime(event) {
    if (event.value) {
      this.dt1 = this.getDateCalender(event.value);
      let datetime = new Date(
        moment(event.value).format("YYYY-MM-DDTHH:mm:ss.000+0000")
      );
      // console.log(this.dt1);
      this.cryptoService.Encrypting("startAtDT", {
        startAt: this.dt1,
        max: this.max,
        min: this.min,
      });
      this.storeDT();
    }
  }

  onChangeOutOfBoundDeliveryPickupTime(event) {
    if (event.value) {
      this.dtOutofBound = this.getDateCalender(event.value);
      let datetime = new Date(
        moment(event.value).format("YYYY-MM-DDTHH:mm:ss.000+0000")
      );
      // console.log(this.dt1);
      this.cryptoService.Encrypting("startAtDT", {
        startAt: this.dtOutofBound,
        max: this.max,
        min: this.min,
      });
      this.storeDT();
    }
  }

  getDate(date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss.000+0000");
  }
  getDateCalender(date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss.000");
  }
  getPhoneNumber() {
    const baseName = this.router.url.split('/').find(portion => !!portion);
    if(baseName == 'drive-thru'){
      this.phone = ''
      return;
    }
    let CP: any = this.router.url.split("/");
    this.phone = CP[2];
  }

  private get customer_detail(): FormGroup {
    return this.cartform.get("customer") as FormGroup;
  }
  private get vehicleInfo(): FormGroup {
    return this.cartform.get("customer").get("vehicleInfo") as FormGroup;
  }
  private get delivery_address(): FormGroup {
    return this.cartform.get("customer").get("deliveryAddress") as FormGroup;
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.vehicle_brands.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterType(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.vehicle_type.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterColor(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.vehicle_colors.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  setOrderType(value: string) {
    this.selectedOrderType = value.toUpperCase();
    this.selectedOrderTypeObj = this.orderTypes.find(
      (orderType) => orderType.displayName.toUpperCase() === value.toUpperCase()
    );
    this.acceptingCurrentOrders = this.selectedOrderTypeObj
      ? this.selectedOrderTypeObj.acceptingCurrentOrders
      : true;
    if (this.acceptingCurrentOrders === false) {
      this.cartform.get("orderReadyDate").setValidators([Validators.required]);
      this.cartform.get("orderReadyDate").updateValueAndValidity();
    }
  }
  setCustomerDetail(id) {
    let customer = this.customerDetails.filter((data: any) => data.id == id);
    customer = customer[0];

    if (customer.loyaltyStatus == "LOYALTY") {
      this.emitTrans.setLoyaltyStatus(true);
    } else {
      this.emitTrans.setLoyaltyStatus(false);
    }
    if (customer.email) {
      if (customer.email != "") {
        this.isEmail = true;
      }
    } else {
      this.isEmail = false;
    }
    this.loyaltyStatus = customer.loyaltyStatus;
    this.customer_detail.patchValue({
      firstName: customer.firstName ? customer.firstName.toUpperCase() : "",
      lastName: customer.lastName ? customer.lastName.toUpperCase() : "",
      email: customer.email ? customer.email : "",
      id: customer.id ? customer.id : "",
      loyaltyId: customer.loyaltyId ? customer.loyaltyId : "",
    });
  }

  public selectStoreChangeURL(selectedStore, phoneNumber) {
    const [_, __, ___, correlationId]: string[] = this.router.url.split("/");
    const restaurantPhoneNumber = selectedStore.phoneNumber;
    this.location.replaceState(
      "/order-receive/" +
        phoneNumber +
        "/" +
        correlationId +
        "/" +
        restaurantPhoneNumber
    );
  }

  createCart(selectedStore = null) {
    console.log(this.cartform);
    this.emitTrans.createCartLoader(true);
    if (this.cartform.valid) {
      this.cartform.patchValue({
        orderReadyDate: this.cartform.value.orderReadyDate
          ? this.getDateCalender(this.cartform.value.orderReadyDate)
          : null,
      });
      if (this.cartform.value.orderReadyDate) {
        this.cryptoService.Encrypting(
          "ord",
          this.getDateCalender(this.cartform.value.orderReadyDate)
        );
      } else {
        localStorage.removeItem("ord");
      }
      if (this.isAddressOutOfBound) {
        let phoneNumber = this.cartform.value.customer.phone; //"1" + Math.floor(100000000 + Math.random() * 900000000);
        this.selectStoreChangeURL(selectedStore, phoneNumber);
        this.emitData.next({
          ...selectedStore,
          customerPhoneNumber: phoneNumber,
        });
        this.cartform.get("storeId").setValue(selectedStore.storeId);
        this.cartform.get("customer").get("phone").setValue(phoneNumber);
      }

      if (this.cartform.value.orderDestination.toUpperCase() == "DELIVERY") {
        if (this.isAddressValidate) {
          if ((this.zip && this.state && this.city) || this.streetLine4) {
            this.delivery_address.patchValue({
              streetLine1: this.streetLine1 ? this.streetLine1 : "",
              streetLine2: this.cartform.value.apt_suite
                ? this.cartform.value.apt_suite
                : "",
              streetLine3: this.streetLine3 ? this.streetLine3 : "",
              streetLine4: this.streetLine4 ? this.streetLine4 : "",
              zip: this.zip,
              state: this.state,
              city: this.city,
            });
            if (this.streetLine1 === "" || !this.streetLine1) {
              this.validationFlag = false;
              this.getPlaceFlag = true;
              this.googleaddress = null;
              this.errorNotification("PLEASE CHECK ADDRESS IS INCOMPLETE");
            } else {
              const cartToCreate = this.cartform.value;
              cartToCreate.customer["loyaltyStatus"] = this.loyaltyStatus;
              if (this.isAddressOutOfBound)
                this.cartform.get("storeId").setValue(selectedStore.storeId);
              this.dialogRef.close({
                ...this.cartform.value,
                customer: {
                  ...this.cartform.value.customer,
                  loyaltyId: this.loyaltyId,
                },
              });
            }
          } else {
            this.emitTrans.createCartLoader(false);
            this.errorNotification("PLEASE VALIDATE ADDRESS");
          }
        } else {
          this.dialogRef.close({
            ...this.cartform.value,
            customer: {
              ...this.cartform.value.customer,
              loyaltyId: this.loyaltyId,
            },
          });
        }
        this.vehicleInfo.patchValue({
          make: "",
          type: "",
          color: "",
        });
      } else {
        if (this.cartform.value.orderDestination.toUpperCase() == "CARRYOUT") {
          this.vehicleInfo.patchValue({
            make: "",
            type: "",
            color: "",
          });
        } else {
          this.vehicleInfo.patchValue({
            make: this.vehicle_brandsControl.value,
            type: this.vehicle_typeControl.value,
            color: this.vehicle_colorControl.value,
          });
        }

        this.delivery_address.patchValue({
          streetLine1: "",
          streetLine2: "",
          streetLine3: "",
          streetLine4: "",
          zip: "",
          state: "",
          city: "",
        });
        const cartToCreate = this.cartform.value;
        cartToCreate.customer["loyaltyStatus"] = this.loyaltyStatus;
        if (this.loyaltyId) {
          cartToCreate.customer["loyaltyId"] = this.loyaltyId;
        }
        this.emptyAddress();
        this.dialogRef.close(this.cartform.value);
      }
    }
  }

  cloneCart() {
    let cartId = this.general.getCartId();
    localStorage.setItem("cln", "true");
    this.cartform.patchValue({ cartId: cartId });
    this.createCart();
  }

  validateForm() {
    let cond: boolean = false;
    if (this.cartform.invalid) {
      if (this.cartform.get("orderDestination").value == "DELIVERY") {
        if (this.isAddressValidate) {
          if (this.cartform.invalid) {
            cond = true;
          } else {
            cond = false;
          }
        } else {
          cond = true;
        }
      } else {
        cond = true;
      }
    } else {
      if (this.cartform.get("orderDestination").value == "DELIVERY") {
        if (this.isAddressValidate) {
          cond = false;
        } else {
          cond = true;
        }
      } else {
        cond = false;
      }
    }
    // console.log(cond, "validate");
    return cond;
  }

  RemoveAllItems() {
    Swal.fire({
      title:
        "Are You Sure You Want To Remove All Cart Items and Create New Order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      // console.log(this.order_receive.cart.id);
      if (result.value) {
        this.createCart();
      }
    });
  }
  streetLine1: string = "";
  streetLine2: string = "";
  streetLine3: string = "";
  streetLine4: string = "";
  splitedArray: any = [];
  zip: string;
  city: string;
  state: string;

  errorNotification(message: string) {
    this.notification.openSnackBarV2("end", "top", message, "danger-snackbar");
  }
  getPlace(address: any, index: number) {
    if (address) {
      this.getPlaceFlag = true;
      console.log(address, index);
      this.emitTrans.requestPlace$.next({
        isBusiness: this.isBusiness.value,
        place_id: this.isBusiness.value
          ? address.place_id
          : this.cartform
              .get("customer")
              .get("deliveryAddress")
              .get("streetLine4").value ||
            this.streetLine4 ||
            this.selectedCustomerAddress,
        index: index || 0,
      });
    }
  }
  checkSelectedAddressMatch() {
    const conditionOne =
      this.addressCheck.trim() === this.selectedCustomerAddress.trim();
    const conditionTwo =
      this.addressCheck.trim() === this.selectedCustomerAddress.trim();
    this.verifyAddress = conditionOne && conditionTwo ? true : false;
  }
  emptyAddress() {
    this.emitTrans.requestAddress$.next(null);
    this.emitTrans.recieveAddress$.next(null);
    this.emitTrans.requestPlace$.next(null);
    this.emitTrans.recievePlace$.next(null);
  }
  setPostGrid(addressFromPostGrid: any) {
    console.log("addressFromPostGrid", addressFromPostGrid);
    if (!addressFromPostGrid || Object.keys(addressFromPostGrid).length == 0) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        "ADDRESS IS NOT VALID",
        "danger-snackbar"
      );
      this.cartform.get("customer").get("deliveryAddress").setValue("");
      this.isAddressValidate = true;
      return;
    }
    const address = Array.isArray(addressFromPostGrid)
      ? addressFromPostGrid[0]?.address
      : addressFromPostGrid?.address;
    // Save the selected address to the property
    this.selectedCustomerAddress =
      address.address + " " + address.city + " " + address.prov;
    this.streetLine2 = null;
    this.streetLine4 = null;
    this.streetLine1 = null;
    this.zip = null;
    this.customerAddress = null;
    this.customerAddress = address.address || "";
    this.streetLine2 = this.cartform.value.apt_suite
      ? this.cartform.value.apt_suite
      : "";
    const postalcode = address?.pc.includes("-")
      ? address?.pc?.split("-")[0]
      : address.pc;
    this.zip = postalcode;
    this.city = address.city;
    this.state = address.prov;
    this.streetLine1 = address.address;
    const streetNumber = address?.address?.split(" ")[0] || "";
    this.streetLine4 =
      `${this.customerAddress} ${this.city} ${this.state} ${this.zip} ${address?.country}`.toUpperCase();
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .patchValue({ streetLine4: "" });
    this.streetLine3 = streetNumber;
    this.isAddressValidate = true;
  }
  setGoogleAddress(address: Address) {
    console.log(address);
    this.streetLine2 = null;
    this.streetLine4 = null;
    this.streetLine1 = null;
    this.zip = null;
    this.customerAddress = null;
    // Save the selected address to the property
    this.selectedCustomerAddress =
      address["address"] || address.formatted_address || "";
    this.customerAddress =
      address["address"] || address.formatted_address || "";
    // console.log(this.customerAddress);
    this.splitedArray = this.customerAddress?.split(/[ ,]+/);
    // console.log(this.splitedArray);

    this.streetLine2 = this.cartform.value.apt_suite
      ? this.cartform.value.apt_suite
      : "";
    const deliveryInstructionControl = this.cartform
      ?.get("customer")
      ?.get("deliveryAddress")
      ?.get("deliveryInstruction");
    deliveryInstructionControl.setValue("");
    if (address?.types?.includes("establishment")) {
      this.cartform
        ?.get("customer")
        ?.get("deliveryAddress")
        .get("locationName")
        .setValue(address?.name);
      if (
        !deliveryInstructionControl?.value ||
        (!deliveryInstructionControl?.value?.includes("Instructions:") &&
          deliveryInstructionControl?.value?.includes("Business name:"))
      ) {
        deliveryInstructionControl.setValue(
          `Business name: ${address?.name || ""}, Instructions:`
        );
      } else if (
        deliveryInstructionControl?.value &&
        !deliveryInstructionControl?.value?.includes("Instructions:") &&
        !deliveryInstructionControl?.value?.includes("Business name:")
      ) {
        deliveryInstructionControl.setValue(
          `Business name: ${address.name || ""}, Instructions: ${
            deliveryInstructionControl?.value
          }`
        );
      } else {
        let value = deliveryInstructionControl?.value;
        let i = value?.indexOf("Instructions:") + 14;
        value = value.slice(i, deliveryInstructionControl?.value.length);
        deliveryInstructionControl.setValue(
          `Business name: ${address.name || ""}, Instructions: ${value}`
        );
      }
    }
    this.streetLine4 = this.customerAddress;
    this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4")
      .setValue("");
    for (let index = 0; index < address.address_components.length; index++) {
      const element = address.address_components[index];
      if (element.types.includes("postal_code")) {
        this.zip = this.addressTypeLocation
          ? element.short_name
          : element.long_name;
      }
      if (
        element.types.includes("locality") ||
        element.types.includes("sublocality") ||
        element.types.includes("sublocality_level_1")
      ) {
        this.city = this.addressTypeLocation
          ? element.short_name
          : element.long_name;
      }
      if (element.types.includes("street_number")) {
        this.streetLine1 = this.addressTypeLocation
          ? element.short_name
          : element.long_name;
        this.streetLine3 = this.addressTypeLocation
          ? element.short_name
          : element.long_name;
      }
      if (element.types.includes("route")) {
        this.streetLine1 =
          this.streetLine1 +
          " " +
          (this.addressTypeLocation ? element.short_name : element.long_name);
      }
      if (element.types.includes("administrative_area_level_1")) {
        this.state = this.addressTypeLocation
          ? element.short_name
          : element.short_name;
      }
    }
    this.isAddressValidate = !!address.address_components.find((element) =>
      element.types.includes("street_number")
    );
    if (!this.isAddressValidate) {
      this.errorNotification("PLEASE CHECK ADDRESS IS INCOMPLETE");
    }
  }
  lineItemAddressChange(address: Address | any) {
    // console.log(address);
    if (this.isBusiness.value) {
      this.setGoogleAddress(address);
    } else {
      this.setPostGrid(address);
    }
  }

  inputbehaviour(evt) {
    if (evt == "" || evt == undefined) {
      this.cartform
        .get("customer")
        .get("deliveryAddress")
        .patchValue({ streetLine4: null });
    } else {
      this.cartform
        .get("customer")
        .get("deliveryAddress")
        .patchValue({ streetLine4: evt });
    }
    this.isAddressValidate = false;
  }
  get addressline4() {
    return this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("streetLine4")
      .hasError("required");
  }

  get locationNameError() {
    return this.cartform
      .get("customer")
      .get("deliveryAddress")
      .get("locationName")
      .hasError("required");
  }

  get orderDestination() {
    return this.cartform.get("orderDestination").hasError("required");
  }

  get firstNameRequired() {
    return this.cartform.get("customer").get("firstName").hasError("required");
  }
  get firstNamePattern() {
    return this.cartform.get("customer").get("firstName").hasError("pattern");
  }

  get vehicleBrandRequired() {
    return this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("make")
      .hasError("required");
  }

  get vehicleTypeRequired() {
    return this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("type")
      .hasError("required");
  }

  get vehicleColorRequired() {
    return this.cartform
      .get("customer")
      .get("vehicleInfo")
      .get("color")
      .hasError("required");
  }

  get lastNameRequired() {
    return this.cartform.get("customer").get("lastName").hasError("required");
  }
  get lastNamePattern() {
    return this.cartform.get("customer").get("lastName").hasError("pattern");
  }

  get email() {
    return (
      this.cartform.get("customer").get("email").value == null ||
      this.cartform.get("customer").get("email").value == ""
    );
  }

  get phoneRequired() {
    return this.cartform.get("customer").get("phone").hasError("required");
  }
  get orderReadyDate() {
    return this.cartform.get("orderReadyDate").hasError("required");
  }

  setAddressTypeType(address) {
    if (address.value !== this.addressTypes.residential) {
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        .setValidators([Validators.required]);
    } else {
      this.cartform
        .get("customer")
        ?.get("deliveryAddress")
        ?.get("locationName")
        .clearValidators();
    }
    this.cartform
      .get("customer")
      ?.get("deliveryAddress")
      ?.get("locationName")
      ?.updateValueAndValidity();
    this.cartform
      .get("customer")
      ?.get("deliveryAddress")
      ?.get("addressType")
      .setValue(address.value);
  }

  showMoreStores() {
    this.storesToShow += 3;
    this.displayedStores = this.storeList.slice(0, this.storesToShow);
  }

  goBackToOriginalState() {
    this.storeList = [];
    this.isAddressOutOfBound = false;
  }

  selectedAddressFromList(deliveryType, selectedStore) {
    const mapObj = {
      DELIVERY: "deliveryHours",
      CARRYOUT: "carryoutHours",
    };
    const mapObjData = {
      DELIVERY: { name: "deliveryHours", key: "isAcceptingCarryOutOrder" },
      CARRYOUT: { name: "carryoutHours", key: "isAcceptingDeliveryOrder" },
    };
    const getDataMap = mapObjData[deliveryType];
    const storeTiming = selectedStore[getDataMap.name].split("-");
    const acceptingOrder = selectedStore[getDataMap.key];
    if (!acceptingOrder && !this.cartform.value.orderReadyDateV2) {
      this.notification.openSnackBarActionV2(
        "end",
        "top",
        `Store Timing is ${storeTiming[0]?.trim()} to ${storeTiming[1]?.trim()} .`,
        "danger-snackbar"
      );
      return;
    }
    // const isValid = this.compareOrderTypeTiming(this.cartform.value.orderReadyDateV2, storeTiming[0]?.trim(),storeTiming[1]?.trim());

    this.cartform.get("orderDestination").setValue(deliveryType);
    this.isAddressValidate = true;
    this.cartform.value.orderReadyDate = this.cartform.value.orderReadyDateV2;
    this.createCart(selectedStore);
  }

  getStoreList() {
    this.emitTrans.requestAddressOutOfBound$.next({
      payload: true,
    });
  }
}
