import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-worldpay",
  templateUrl: "./worldpay.component.html",
  styleUrls: ["./worldpay.component.scss"],
})
export class WorldpayComponent implements OnInit {
  queryStringParameters: any = {};
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.GetQueryStringParameters();
  }

  GetQueryStringParameters() {
    this.queryStringParameters = {};
    this.activatedRoute.queryParamMap.subscribe((params) => {
      console.log(params);
      params.keys.forEach((key) => {
        this.queryStringParameters[key] = params.get(key);
      });
    });
    // console.log("Query String Parameters:",this.queryStringParameters);
  }
}
