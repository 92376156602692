import { Injectable, Injector, NgZone } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { ActionSubmitOrder } from "../action/order.action";
import { Router } from "@angular/router";
import { NotificationService } from "../../notification/notification.service";
import { generalService } from "../../services/general.service";
export const orderDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface orderDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<orderDefaultState>({
  name: "order",
  defaults: orderDefaultState,
})
@Injectable()
export class orderState {
  constructor(
    private notifier: NotificationService,
    private general: generalService,

    private ngzone: NgZone,
    private router: Router
  ) {}
  @Selector()
  static getOrder(state: orderDefaultState) {
    return state;
  }

  @Selector()
  static getOrderLoaded(state: orderDefaultState) {
    return state.isloaded;
  }

  @Selector()
  static getOrderError(state: orderDefaultState) {
    return state.isError;
  }

  @Action(ActionSubmitOrder)
  createCart(
    { setState, getState, patchState }: StateContext<orderDefaultState>,
    { payload, storeAddress }
  ) {
    const order = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const orderState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };

      patchState(orderState);
      // console.log(payload);
      // this.ngzone.run(() => {
      // this.router.navigate([`/dashboard`]).then(() => {
      //   // Swal.fire(
      //   //   `Your Order Has Been Placed`,
      //   //   `Order Id Is ${payload.data.orderId},  CustomerQuotetime is ${payload.data.customerQuoteTime}m, restaurantOrderNumber is ${payload.data.restaurantOrderNumber},paymentType is ${payload.data.order.paymentType}`,
      //   //   "success"
      //   // ).then((result: any) => {
      //   //   window.location.reload();
      //   // });
      //   Swal.fire(
      //     `Your Order Has Been Placed`,
      //     `<h3 style="text-align: left;">
      //     ORDER ID:  <b>${payload.data.orderId}.</b>
      //     <br>
      //     <br>
      //     CustomerQuote Time:  <b>${payload.data.customerQuoteTime}m.</b>
      //     <br>
      //     <br>
      //     RestaurantOrderNumber: <b>${payload.data.restaurantOrderNumber}.</b>
      //     <br>
      //     <br>
      //     Payment Method:  <b> ${payload.data.order.payment.paymentType}.</b>
      //     <br>
      //     <br>
      //     Store Address: <b>${storeAddress.street} ${storeAddress.city} ${storeAddress.state} ${storeAddress.zip}.</b>
      //     </h3>`,
      //     "success"
      //   ).then((result: any) => {
      //     window.location.reload();
      //   });
      // });

      this.general.removeCartId();
    }
  }
}
