import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  $WebSocket,
  WebSocketSendMode,
} from "angular2-websocket/angular2-websocket";
import { environment } from "environments/environment";
import { lastValueFrom, Subscription } from "rxjs";

import { AuthenticationService } from "app/core/auth/authentication.service";
import { CryptoService } from "app/core/services/crypto.service";
import { getRestaurantService } from "app/core/auth/restaurant.service";
import { stateInterface } from "app/ai-order-recieve/interface/conv-state.interface";
import { aiMenuService } from "app/core/services/ai/ai-menu.service";
import {
  AddOn,
  AddOn_Variations,
  Category,
  Menu,
} from "../../../../../mediator-commlibs/interfaces/response/menu.interface";
import { decompress } from "compress-json";
import { CartMenuItemDetails } from "app/ai-order-recieve/interface/cartmenuItem-state.interface";
import { prepareMenuItemForCart } from "app/ai-order-recieve/helper/cart-item-helper";
import { aiCartService } from "app/core/services/ai/ai-cart.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { NotificationService } from "app/core/notification/notification.service";
import { CustomerComponent } from "app/order-receive/customer/customer.component";
import { MatDialog } from "@angular/material/dialog";
import { aiStoreService } from "app/core/services/ai/ai-store.service";
import { Store } from "@ngxs/store";
import { ActionGetStore } from "app/core/ngxs/action/store.action";
import { GlobalApiResponse } from "app/core/models/apiResponse.interface";
import {
  ActionGetCart,
  ActionUpdateCartItem,
} from "app/core/ngxs/action/cart.action";
import { TimeSpan } from "app/order-receive/order-receive.component";
import { UpdateCartComponent } from "../update-cart/update-cart.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-ai-order-recieve",
  templateUrl: "./ai-order-recieve.component.html",
  styleUrls: ["./ai-order-recieve.component.scss"],
})
export class AiOrderRecieveComponent implements OnInit {
  convId: string;
  customerPhone: string;
  restaurantphone: string;
  ws: any;
  conversationData: stateInterface | null;
  subscriptions: Subscription[];
  agentRole: string = "AGENT";
  userEmail: string | null = null;
  storeId: string | null = null;
  clientName: string | null = null;
  finalizeOrder: boolean = false;
  cartMenuItemDetail: CartMenuItemDetails = null;
  currentTime: Date;
  constructor(
    route: ActivatedRoute,
    private auth: AuthenticationService,
    private _aiMenuService: aiMenuService,
    private _aiCartService: aiCartService,
    private crypto: CryptoService,
    private restaurantService: getRestaurantService,
    private _aiStoreService: aiStoreService,
    private ngxService: NgxUiLoaderService,
    private notification: NotificationService,
    private router: Router,
    public dialog: MatDialog,
    private ngxsStore: Store
  ) {
    this.currentTime = new Date();
    const params = route.snapshot.params;
    this.convId = params.uuid;
    this.customerPhone = params.customerphone;
    this.restaurantphone = params.restaurantphone;
    console.log({
      customerPhone: this.customerPhone,
      restaurantphone: this.restaurantphone,
    });

    // user info
    this.initiateUser();
  }
  async ngOnInit() {
    // restaurant details
    await this.getRestaurantDetails();
    this._aiMenuService.aiParams.next({
      convId: this.convId,
      customerPhone: this.customerPhone,
      restaurantphone: this.restaurantphone,
      storeId: this.storeId,
      clientCode: this.clientName,
      identity: this.userEmail,
    });
    // web socket to hub initiate
    this.initiateSocket();
    await this.getMenu();
    // await this.getStore();
    // await this.getCart();

    this._aiMenuService.cartMenuItemDetails.subscribe((data) => {
      console.log("cartMenuItemDetail =>", data);
      this.cartMenuItemDetail = data;
    });
    this._aiCartService.cartRefreshEvent.subscribe(async (data) => {
      if (data) {
        console.log("_aiCartService.cartRefreshEvent", data);
        const payload = this.buildPayload("REFRESH_CART");
        console.log("payload ", payload);
        await this.ws.send(JSON.stringify(payload), WebSocketSendMode.Promise);
        this.ws.close(false);
        this._aiCartService.cartRefreshEvent.next(false);
      }
    });
    this._aiCartService.cartUpdateEvent.subscribe(async (data) => {
      if (data) {
        console.log("this._aiCartService.cartUpdateEvent", data);
        const payload = this.buildPayload("UPDATE_CART", "empty", data);
        console.log("payload ", payload);
        await this.ws.send(JSON.stringify(payload), WebSocketSendMode.Promise);
        this.ws.close(false);
        this._aiCartService.cartUpdateEvent.next(null);
      }
    });
  }
  async sendEvents(event: string) {
    this.ngxService.start();
    const payload = this.buildPayload(event);
    console.log("payload ", payload);
    await this.ws.send(JSON.stringify(payload), WebSocketSendMode.Promise);
    this.ws.close(false);
  }
  getCallDuration(min, sec) {
    min = min < 10 ? "0" + min.toString() : min;
    sec = sec < 10 ? "0" + sec.toString() : sec;
  }

  getElapsedTime(): TimeSpan {
    let totalSeconds = Math.floor(
      (new Date().getTime() - this.currentTime.getTime()) / 1000
    );

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }
  getMenu = async () => {
    try {
      const response: any = await lastValueFrom(
        this._aiMenuService.getMenu(
          this.clientName,
          this.storeId,
          this.convId,
          this.userEmail,
          this.customerPhone
        )
      );
      console.log(response);
      if (response?.data) {
        const data: Menu = decompress(response?.data);
        this._aiMenuService.originalMenu.next(data?.categories);
        this._aiMenuService.categories.next(data?.categories);
      } else {
        throw new Error("please refresh page");
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  getStore = async () => {
    try {
      if (!this.storeId) throw new Error("store id not found");
      const storeDetails: GlobalApiResponse = (await lastValueFrom(
        this._aiStoreService.getStore(
          this.convId,
          this.clientName,
          this.userEmail,
          this.storeId
        )
      )) as GlobalApiResponse;
      this.ngxsStore.dispatch(new ActionGetStore(storeDetails));
      console.log("storeDetails => ", storeDetails);
    } catch (error) {
      console.log("error from get store => ", error);
    }
  };
  getCart = async () => {
    try {
      if (this.conversationData?.cart?.id) {
        const cartDetails: GlobalApiResponse = (await lastValueFrom(
          this._aiCartService.getCart(
            this.convId,
            this.clientName,
            this.userEmail,
            this.storeId,
            this.conversationData?.cart?.id
          )
        )) as GlobalApiResponse;
        console.log("cartDetails => ", cartDetails);
        this.ngxsStore.dispatch(new ActionGetCart(cartDetails));
        this.ngxsStore.dispatch(new ActionUpdateCartItem(cartDetails));
      }
    } catch (error) {
      console.log("error from get cartDetails => ", error);
    }
  };
  getRestaurantDetails = async () => {
    try {
      if (!this.restaurantphone)
        throw new Error("restaurant phone number not found");
      const restaurantDetails = await lastValueFrom(
        this.restaurantService.getRestaurantDetails(this.restaurantphone)
      );
      this.storeId =
        restaurantDetails?.data?.user_restaurant_details?.restaurants
          ?.external_location_id || null;
      this.clientName =
        restaurantDetails?.data?.user_restaurant_details?.restaurants?.name ||
        null;
      console.log("restaurantDetails => ", restaurantDetails);
    } catch (error) {
      console.log("error from get restaurant => ", error);
    }
  };
  initiateUser = () => {
    const user = this.auth.getCurrentUser();
    this.userEmail = user?.email || "AI";
    const role =
      user.roles?.[0]?.role_details?.name ||
      this.crypto.DecryptWithoutPromise("agentSecret");
    if (role) {
      this.agentRole = role;
    } else {
      this.agentRole = "AGENT";
    }
  };
  initiateSocket = () => {
    this.ws = new $WebSocket(
      environment.ws +
        "?platform=dashboard&token=" +
        this.convId +
        `&uuid=${this.convId}&client=${this.clientName}`
    );
    this.ws.onOpen((event: Event) => {}, { autoApply: true });
    this.ws.onMessage(
      (msg: MessageEvent) => {
        this.ngxService.stop();
        if (msg?.data) {
          const response = JSON.parse(msg.data);
          console.log("response from hub socket => ", response);
          switch (response.channel) {
            case "STATE":
              if (response.data) {
                this.conversationData = this.transFormData(response.data);
                this._aiMenuService.convState.next(this.conversationData);
              } else {
                this.notification.openSnackBarV2(
                  "end",
                  "top",
                  `Operation Failed`,
                  "danger-snackbar"
                );
              }
              break;
            case "FINALIZE_ORDER":
              this.finalizeOrder = true;
              if (response.data) {
                this.conversationData = this.transFormData(response.data);
                this._aiMenuService.convState.next(this.conversationData);
                const dialogRef = this.dialog.open(UpdateCartComponent, {
                  width: "800px",
                  height: "500px",
                });
                dialogRef.afterClosed().subscribe((result) => {
                  console.log(result);
                });
              }
              console.log("finalizing order");
              break;
            case "SUBMIT_ORDER":
              this.finalizeOrder = false;
              if (response.data) {
                this.conversationData = this.transFormData(response.data);
                this._aiMenuService.convState.next(this.conversationData);
              }
              break;
            case "END_CALL":
              if (response.data) {
                this.conversationData = this.transFormData(response.data);
                this._aiMenuService.convState.next(this.conversationData);
              }
              Swal.fire(
                "Call Ended",
                "Call ended successfully",
                "success"
              ).then((_) => {
                this.endSession();
              });
              break;
            case "TRANSFER":
              if (response.data) {
                this.conversationData = this.transFormData(response.data);
                this._aiMenuService.convState.next(this.conversationData);
              }
              Swal.fire(
                "Call Transferred",
                "Now call has been transferred to agent",
                "success"
              ).then((_) => {
                this.endSession();
              });
              break;
            default:
              break;
          }
          console.log(this.conversationData);
        }
      },
      { autoApply: false }
    );

    this.ws.onError((event: Event) => {
      console.log("on error", event);
    });
  };

  transFormData = (convData: stateInterface): stateInterface => {
    // remove empty conv
    convData.conversationSoFarSendToDashboard =
      convData.conversationSoFarSendToDashboard?.filter(
        (convSoFar) => convSoFar.agent || convSoFar.customer
      );

    return convData;
  };
  isFromValid(): boolean {
    if (
      this.cartMenuItemDetail &&
      this.cartMenuItemDetail.category.length == 1 &&
      this.cartMenuItemDetail.items.length == 1 &&
      this.cartMenuItemDetail.sizes.length == 1 &&
      this.cartMenuItemDetail.styles.length == 1 &&
      !this.checkAddonIsRequired(
        this.cartMenuItemDetail.addons,
        this.cartMenuItemDetail.selectedAddonVariations
      )
    ) {
      return true;
    }

    return false;
  }
  checkAddonIsRequired(
    addons: AddOn[],
    selectedAddonVariations: AddOn_Variations[]
  ): boolean {
    const isRequired = addons.some(
      (addon: AddOn) =>
        selectedAddonVariations.filter(
          (addonVariation: AddOn_Variations) =>
            addonVariation.alterationCategory == addon.displayName
        )?.length < addon.min
    );
    return isRequired;
  }
  async addItemToCart() {
    try {
      this.ngxService.start();
      const cartMenuItem = prepareMenuItemForCart(this.cartMenuItemDetail);
      console.log("add to cart item", cartMenuItem);
      const response: any = await lastValueFrom(
        this._aiCartService.addItemToCart(
          this.convId,
          this.clientName,
          this.userEmail,
          this.storeId,
          this.conversationData.cart.id,
          [cartMenuItem]
        )
      );
      this.ngxService.stop();
      this._aiMenuService.clearMenu.next(true);
      console.log("add item to cart respone =>", response);
      if (response?.data?.cart) {
        this._aiCartService.cartRefreshEvent.next(true);
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Item added successfully`,
          "success-snackbar"
        );
      } else {
        this.ngxService.stop();
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Operation Failed`,
          "danger-snackbar"
        );
      }
    } catch (error) {
      this.ngxService.stop();
      console.log(error);
    }
  }
  buildPayload = (event: string, transcript = "empty", data: any = null) => {
    return {
      id: this.convId,
      agent_email: this.userEmail,
      channel: "DASHBOARD_ACTIONS",
      body: {
        id: this.convId,
        source: this.customerPhone,
        channel: "phone",
        storeId: this.storeId,
        transcript: transcript,
        event: event,
        payload: data,
      },
      headers: {
        correlationid: this.convId,
        identity: this.userEmail,
        clientCode: this.clientName?.toUpperCase(),
        restaurantPhoneNumber: this.restaurantphone,
        debug: "true",
      },
    };
  };
  logout() {
    if (!("isAdminBuild" in environment)) {
      this.router.navigate(["/login"]);
    } else {
      this.auth.logout();
      this.router.navigate(["/welcome"]);
    }
  }
  endSession() {
    this.router.navigate(["/dashboard"]);
  }
  async transfer() {
    // this.ngxService.start();
    const payload = this.buildPayload("TRANSFER");
    console.log("payload ", payload);
    await this.ws.send(JSON.stringify(payload), WebSocketSendMode.Promise);
    this.ws.close(false);
    // this._aiCartService.cartRefreshEvent.next(true);
  }
  async submitOrder() {
    this.ngxService.start();
    const payload = this.buildPayload("SUBMIT_ORDER", "yes");
    console.log("payload ", payload);
    await this.ws.send(JSON.stringify(payload), WebSocketSendMode.Promise);
    this.ws.close(false);
    // this._aiCartService.cartRefreshEvent.next(true);
  }
  async clearCart() {
    try {
      this.ngxService.start();
      const response: any = await lastValueFrom(
        this._aiCartService.clearCart(
          this.convId,
          this.clientName,
          this.userEmail,
          this.storeId,
          this.conversationData.cart.id
        )
      );
      this._aiMenuService.clearMenu.next(true);
      console.log("clear cart respone =>", response);
      if (response?.data?.cart) {
        this._aiCartService.cartRefreshEvent.next(true);
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Cart cleared successfully`,
          "success-snackbar"
        );
      } else {
        this.ngxService.stop();
        this.notification.openSnackBarV2(
          "end",
          "top",
          `Operation Failed`,
          "danger-snackbar"
        );
      }
    } catch (error) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `Operation Failed`,
        "danger-snackbar"
      );
      this.ngxService.stop();
      console.log(error);
    }
  }

  openCustomerComponent() {
    var dialogref = this.dialog.open(CustomerComponent, {
      disableClose: true,
      width: "100vh",
      height: "100vh",
      data: {
        isEdit: true,
        isError: false,
        cart: this.conversationData.cart,
        restaurant_details: { storeId: this.storeId },
        restaurantName: this.clientName,
        errorMessage: null,
        statusCode: null,
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });
  }
}
