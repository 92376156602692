
<div class="row">
  <div class="col-md-12  align-center">
    <span>Available Deals</span>
  </div>
  <div class="col-md-12">
    <input type="text" #filterDeals class="form-control" placeholder="Alt+f to focus, Type to filter" (keyup)="filterData($event.target.value)"/>
  </div>
 
  <div class="col-md-12 deal-list">
    <ul class="list-group">
      <li [ngClass]="deal?.isLoyaltyOffer && 'loyalty-offer-row'" *ngFor="let deal of deals" (dblclick)="selectAndApplyDeal(deal)" class="list-group-item" [class.isSelected]="deal.isSelected"
      (click)="deal.isManualDiscount? openPopup(deal) : selectDeal(deal)">  
      <!-- (click)="selectDeal(deal)"> -->
        <span>
          {{deal.altDescription != "" && deal.altDescription != null ?
          deal.altDescription + ' - $' +deal.value : deal.description + ' - $' +deal.value}}
        </span>
        <mat-icon [matTooltip]="'Loyalty Offer'"  *ngIf="deal?.isLoyaltyOffer" class="loyalty-offer-icon">loyalty</mat-icon>
      </li>
    </ul>

  </div>
</div>