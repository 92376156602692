import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

export enum ToastrType{
  Info,
  Error,
  Success
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private router: Router
    , private pageTitle: Title) { }

  NavigateByURL(url: string) {
    this.router.navigateByUrl(url);
  }
  SetPageTitle(pageTitle: string) {
    this.pageTitle.setTitle("Ezra.ai - " + pageTitle);
  }
}
