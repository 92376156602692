import {trigger, state, animate, style, transition} from '@angular/animations';

export function routerTransition() {
  return trigger('openClose', [
    // ...
    state('open', style({
      right: '0px',
      display: "inline-flex",
      opacity: 1
    })),
    state('closed', style({
      right: '-587px',
      display: "none",
      opacity: 0
    })),
    transition('open => closed', [
      animate('1s')
    ]),
    transition('closed => open', [
      animate('0.5s')
    ]),
  ]);
}
