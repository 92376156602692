import { Pipe, PipeTransform } from '@angular/core';
import { Permissions } from '../auth/model/permissions.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';

@Pipe({
  name: 'permissions',
})
export class PermissionsPipe implements PipeTransform {
  constructor(private cookieService: CookieService) { }
  transform(value: Permissions): boolean {
    const permissions = !('isAdminBuild' in environment) ? JSON.parse(localStorage.getItem("userPermissions") || '[]') : JSON.parse(this.cookieService.get('userPermissions') || '[]');
    return permissions?.includes(value);
  }

}