<div class="main-content no-scroll">
  <div class="container-fluid">
    <div class="card">
      <div class="card-content">
        <div class="col-12">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <strong style="width: 100%">{{ formTitle | uppercase }}</strong>
            </div>
            <div class="col-6 right-top-section">
              <button *ngIf="
                defaultButtonText === 'AddNew' &&
                formTitle !== 'Modify Store' &&
                (userPermissions.RESTAURANT_CONFIG_ADD | permissions)
              " class="btn btn-outline-dark btn-sm add-new-store" (click)="AddNew()">
                <i class="fa fa-plus anchor-btn" aria-hidden="true"></i> Add
                New Store
              </button>
              <button *ngIf="
                defaultButtonText == 'AddNew' &&
                formTitle !== 'Modify Store' &&
                (userPermissions.RESTAURANT_CONFIG_ADD | permissions)
              " mat-stroked-button color="primary" class="agent_btn download-sample-cv"
                (click)="downloadSampleCSVFile()">
                Download Sample CSV
              </button>
              <button *ngIf="
                defaultButtonText == 'AddNew' &&
                formTitle !== 'Modify Store' &&
                (userPermissions.RESTAURANT_CONFIG_ADD | permissions)
              " mat-stroked-button color="primary" class="agent_btn" (click)="openFile(fileImportInput)">
                Upload CSV
                <i class="material-icons">upload</i>
                <input #fileImportInput class="file-input" type="file" name="File Upload"
                  (change)="fileChangeListener($event)" id="csvFileUpload" accept=".csv" hidden="true"
                  style="display: none" />
              </button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <ng-container #componentPlaceHolder></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>