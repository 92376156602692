import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../../../mediator-commlibs/interfaces/request/restaurantSetting.interface';

@Component({
  selector: 'app-cash-disabled-dialog',
  template: `
    <h2 mat-dialog-title>Cash Payment Disabled</h2>
    <mat-dialog-content>{{ data.message }}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `
})
export class CashDisabledDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}