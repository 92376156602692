import * as moment from "moment";
import "moment-timezone";
let timeZoneOffset = moment().tz("America/Los_Angeles").format("Z");
export class AppConstants {
  public static STEP_ROUTES = [
    { id: 1, route: "profile" },
    { id: 2, route: "phonenumber" },
    { id: 3, route: "upload" },
    { id: 4, route: "poslink" },
    { id: 5, route: "dashboard" },
  ];
  public static TIMEZONE_OFFSET = timeZoneOffset;
  public static SIP_ALERT = "sip-alert";
  public static NOT_CONNECTED = "not-connected";
  public static WEB_SOCKET = "web-socket";
  public static AGENT_BACKEND_SOCKET_FAILED =
    "agent-backend-socket-connection-failed";
  public static SIP_FAILED = "sip-failed";
  public static ORDER_STATUS = [
    { id: "RECEIVED", value: "Received" },
    { id: "ON-HOLD", value: "On Hold" },
    { id: "COMPLETED", value: "Completed" },
    { id: "UNCONFIRMED", value: "Un - Confirmed" },
  ];
  public static DEFAULT_FILTER_STATUS = ["ON-HOLD", "UNCONFIRMED"];
  public static SUPER_AGENT_ON = "super-agent";
  public static PERMANENT_SUPER_AGENT_ON = "permanent-super-agent-on";
  public static COMPONENT_NAMES = [
    { key: "api_dashboard", value: "API Dashboard" },
    { key: "be", value: "Business Engine" },
    { key: "dashboard", value: "Dashboard" },
    { key: "hub", value: "Hub" },
  ];
  public static COMPONENT_KEYS = [
    { key: "fax_number", value: "Fax Number" },
    { key: "post_order_actions", value: "Post Order Actions" },
    { key: "post_order_actions_automated", value: "Post Order Actions Automated" },
    { key: "delivery_flow_position", value: "Delivery Flow Position" },
    { key: "pos_password", value: "Pos Password" },
    { key: "pos_url", value: "Pos Url" },
    { key: "pos_username", value: "Pos Username" },
    { key: "timezone", value: "Timezone" },
    { key: "delivery_config", value: "Delivery Config" },
    { key: "disable_drinks_asking", value: "Disable Drinks Asking" },
    { key: "disable_matching_special", value: "Disable Matching Special" },
    { key: "enable_reorder", value: "Enable Reorder" },
    { key: "phone_startConversation_afterWithName", value: "Phone Start Conversation After With Name" },
    { key: "phone_startConversation_afterWithNoName", value: "Phone Start Conversation After With No Name" },
    { key: "phone_startConversation_beginningWithName", value: "Phone Start Conversation Beginning With Name" },
    { key: "phone_startConversation_order", value: "Phone Start Conversation Order" },
    { key: "phone_startConversation_pressZero", value: "Phone Start Conversation Press Zero" },
    { key: "welcome_message_after_sequence_reference", value: "Welcome Message After Sequence Reference" },
    { key: "welcome_message_beginning_sequence_reference", value: "Welcome Message Beginning Sequence Reference" },
    { key: "address_swne", value: "Address SWNE" },
    { key: "agent_initiated_transfer_message", value: "Agent Initiated Transfer Message" },
    { key: "altphones", value: "Alternate Phones" },
    { key: "customer_initiated_transfer_message", value: "Customer Initiated Transfer Message" },
    { key: "dashboard_ready_timeout_sec", value: "Dashboard Ready Timeout Second" },
    { key: "dtmf_forward_to_voix", value: "Dtmf Forward To Voix" },
    { key: "elastic_search_action", value: "Elastic Search Action" },
    { key: "enable_forward_repeat_caller", value: "Enable Forward Repeat Caller" },
    { key: "fillers", value: "Fillers" },
    { key: "non_rest_hours_message", value: "Non Rest Hours Message" },
    { key: "non_voix_hours_message", value: "Non Voix Hours Message" },
    { key: "off_hold_message", value: "Off Hold Message" },
    { key: "on_hold_message", value: "On Hold Message" },
    { key: "play_ack_message", value: "Play Acknowledge Message" },
    { key: "restaurant_parallel_listen_number", value: "Restaurant Parallel Listen Number" },
    { key: "restaurant_parallel_listen_on", value: "Restaurant Parallel Listen On" },
    { key: "sms_from_number", value: "SMS From Number" },
    { key: "third_party_transfer_message", value: "Third Party Transfer Message" },
    { key: "wait_message", value: "Wait Message" },
    { key: "welcome_message", value: "Welcome Message" },
    { key: "whisper_message", value: "Whisper Message" },
    { key: "agent_availability_delay", value: "Agent Availability Delay" },
    { key: "non_english_digit", value: "Non English Digit" },
    { key: "category_sort", value: "Category Sort" },
    { key: "delivery_address_zone", value: "Delivery Address Zone" },
    { key: "max_cash_order_amt", value: "Maximum Cash Order Amount" },
    { key: "max_cash_order_amt_delivery", value: "Maximum Cash Order Delivery" },
    { key: "max_cash_order_amt_pickup", value: "Maximum Cash Order Amount Pickup" },
    { key: "max_cash_order_amt_carryout", value: "Maximum Cash Order Amount Carryout" },
    { key: "max_cash_order_amt_curbside", value: "Maximum Cash Order Amount Curbside" },
    { key: "ai_enabled", value: "AI ENABLED"},
    { key: "sip_uri", value: "SIP URI"},
    { key: "kvm_url", value: "KVM URL" },
    { key: "four_week_offer_id", value: "FOUR WEEK OFFER ID" },
    { key: "loyalty_enrollment_ivr_enabled", value: "Loyalty Enrollment IVR Enabled" },
  ];
  public static MODE_OF_OPERATION_LIST = [
    { key: "AGENT_TRAINING", display: "Agent Training" },
    { key: "AI", display: "AI" },
    { key: "STORE_LEARNING", display: "Store Learning" },
    { key: "AGENT_AI_TRAINING", display: "Agent AI Training" },
    { key: "SUPER_AI", display: "Super AI" },
  ];
  public static SIP_NOTIFY = "sip-notify";
  public static ROLE = {
    ADMIN: "ADMIN",
    AGENT: "AGENT",
    SUPERVISOR: "SUPERVISOR",
    ALL: "ALL",
    SUPERADMIN: "SUPERADMIN",
  };
  public static FLAG_SMITH = {
    IS_MUTE_FEATURE_ENABLE: "is_mute_feature_enable",
    IS_SPECIALS_AUTO_APPLIED_ENABLED: "is_specials_auto_applied_enabled",
    IS_SPECIALS_APPLICABLE_ENABLED: "is_specials_applicable_enabled",
    IS_IVR_FEATURE_ENABLED: "is_ivr_feature_enabled",
    ADDRESS_TYPE_LOCATION: "address_type_location",
    CREDIT_CARD_PAYMENT_ATTEMPTS: "credit_card_payment_attempts",
    IS_TOPPINGS_COUNT_SEPARATED: "is_toppings_count_separated",
    IS_ADDRESS_OUT_OF_RANGE_ENABLED: "is_address_out_of_range_enabled",
    IS_LOYALTY_IVR_FLOW_ENABLED: "is_loyalty_ivr_flow_enabled",
    IS_UP_SELLING_FEATURE_ENABLED: "is_up_selling_feature_enabled",
    IS_STORE_NOTIFICATION_ENABLED: "is_store_notification_enabled",
  };
}
