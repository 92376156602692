import { NgModule } from '@angular/core';
import { FormatUsNumberPipe } from './format-number.pipe';
import { TextUsNumberFormatPipe } from './text-us-number-format.pipe';
import { UcfirstPipe } from './ucfirst.pipe';
import { KeyValuesPipe } from './key-values.pipe';
import { SlotsFilterPipe } from './slots-filter.pipe';
import { RemoveSpecialsCharPipe } from './remove-specials.pipe';
import { TagReplacePipe } from './tag-replace.pipe';
import { SortByNamePipe } from './sort-by-name.pipe';
import { ObjectHandlerPipe } from './object-handler.pipe';
import { SpecialsPriceStringPipe } from './specials-price-string.pipe';
import { PermissionsPipe } from './permissions.pipe';
import { NumberToAlphabetConverter } from './number-to-alphabet-converter';
import { IsAdminDomainPipe } from './is-admin-domain.pipe';
import { HighlightPipe } from './highlight.pipe';
import { FormatEnumPipe } from './format-enum.pipe';
@NgModule({
    declarations: [FormatUsNumberPipe, TextUsNumberFormatPipe, UcfirstPipe, KeyValuesPipe, RemoveSpecialsCharPipe, SlotsFilterPipe, TagReplacePipe, ObjectHandlerPipe, SortByNamePipe, SpecialsPriceStringPipe, PermissionsPipe, NumberToAlphabetConverter, IsAdminDomainPipe, HighlightPipe, FormatEnumPipe],
    imports     : [],
  exports: [FormatUsNumberPipe, TextUsNumberFormatPipe, UcfirstPipe, KeyValuesPipe, RemoveSpecialsCharPipe, SlotsFilterPipe, TagReplacePipe, ObjectHandlerPipe, SortByNamePipe, SpecialsPriceStringPipe, PermissionsPipe, NumberToAlphabetConverter, IsAdminDomainPipe, HighlightPipe, FormatEnumPipe],
    providers: [PermissionsPipe, IsAdminDomainPipe]
})

export class VoixPipesModule{ }
