import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keyValues'})
export class KeyValuesPipe implements PipeTransform {
  transform(obj: any, args?: any[]): any[] {
    let dataArr = []
    if(obj) {
      if(typeof obj === 'string') {
        obj = JSON.parse(obj);
      }
      Object.keys(obj).forEach(function(key, index) {
        console.log("keyvalue", key, obj[key], typeof obj[key], (typeof obj[key] != 'object' && obj[key][0] && typeof obj[key][0] != 'object'));
        if(typeof obj[key] != 'object') {
          dataArr.push({key: key, value: obj[key]});
        } else if(typeof obj[key] == 'object' && (obj[key] != undefined && obj[key] != null) && typeof obj[key][0] != 'object') {
          dataArr.push({key: key, value: obj[key]});
        } else {
          dataArr.push({key: key, value: obj[key]});
        }
        // key: the name of the object key
        // index: the ordinal position of the key within the object 
      });
    }
    // return the resulting array
    return dataArr;
  }
}