import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { Permissions } from "app/core/auth/model/permissions.model";
import { ActionSaveDeal, ActionSaveSelectDeal, setDealOverride } from 'app/core/ngxs/action/deals.action';
import { dealsDefaultState, dealsState } from 'app/core/ngxs/state/deals.state';
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import * as moment from 'moment';

import { Observable, Subscription } from "rxjs";
import { PromoItems } from '../../../../mediator-commlibs/interfaces/response/promos.interface';

@Component({
  selector: 'app-view-deal',
  templateUrl: './view-deal.component.html',
  styleUrls: ['./view-deal.component.scss']
})
export class ViewDealComponent implements OnInit {
  dataSource: MatTableDataSource<object>;
  @Select(dealsState.getDeals) dealState: Observable<dealsDefaultState>;
  displayedColumns = ["select", "offerId", "coupon_name", "promoCode"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() deals:any = [];
  @Input() selectedClient:any = '';
  search: string;
  subscription: Subscription;
  userPermissions = Permissions;
  allDealsSelected:boolean = false;
  allDeals: PromoItems[] = [];
  isShowSortOrder = false;
  constructor(
    private emitTrans: emitTransactionsService,
    private ngxsStore: Store,
  ) {
    this.subscription = this.emitTrans.searchDeal.subscribe(
      (data) => (this.search = data)
    );    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.dealState.subscribe((state: dealsDefaultState) => {
      this.allDeals = state.data;
      this.allDealsSelected = state && state.data?.every((x) => x.isOverridden);
      if (this.allDeals?.length) {
        if (this.allDeals[0].hasOwnProperty('sortOrder')) {
          this.isShowSortOrder = true;
        } else {
          this.isShowSortOrder = false;
        }
      } else {
        this.isShowSortOrder = false;
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  saveDeals() {
    this.ngxsStore.dispatch(new setDealOverride());
    this.emitTrans.searchDeal.next("");
  }

  addDealForOverride(deal: any, overridden:boolean, i: any) {
    this.ngxsStore.dispatch(new ActionSaveDeal({deal,overridden,i}));
  }
  
  async SelectUnSelectAll(event: any) {
    this.allDealsSelected = event.checked;
    this.ngxsStore.dispatch(new ActionSaveSelectDeal({allDealsSelected:event.checked}));
  }

  changeAutoApplied(event: any, itemDeal: PromoItems) {
    this.allDeals.forEach((item: PromoItems) => {
      if (item.promoCode == itemDeal.promoCode) {
        item.isActiveItem = event.checked;
      }
    });
  }

  checkDateValid(startDate, endDate) {
    return startDate && endDate && (moment().isAfter(startDate) || moment(startDate).isSame(moment(), 'day')) && (moment().isBefore(endDate) || moment(endDate).isSame(moment(), 'day'));
  }
}
