declare const require: any;
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
  ViewEncapsulation,
  HostListener,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { AppConstants } from "../app.constants";
import { routerTransition } from "../router.animations";
import {
  FormControl,
  FormBuilder,
  FormGroupDirective,
  FormGroup,
  NgForm,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { $WebSocket } from "angular2-websocket/angular2-websocket";
import { VoixHttpService } from "../core/voix-http/voix-http.service";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import * as io from "socket.io-client";
import * as _ from "underscore";

import { AgentResponseService } from "./../core/customer/agent-response.service";
import { OrderReceiveService } from "./../core/customer/order-receive.service";
import { UcfirstPipe } from "../core/pipes/ucfirst.pipe";
import { KeyValuesPipe } from "../core/pipes/key-values.pipe";
import { SortByNamePipe } from "../core/pipes/sort-by-name.pipe";
import { RemoveSpecialsCharPipe } from "../core/pipes/remove-specials.pipe";
import { OrderDialogComponent } from "./order-dialog/order-dialog.component";
import { OrderInProgressComponent } from "./order-in-progress/order-in-progress.component";
import { PreResponsesComponent } from "./pre-responses/pre-responses.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NotificationService } from "./../core/notification/notification.service";
import { AuthenticationService } from "../core/auth/authentication.service";
import { GooglePlaceDirective } from "../core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { Address } from "../core/directives/ngx-google-places-autocomplete/objects/address";
import { generalService } from "../core/services/general.service";
import { alertNotificationService } from "../core/services/alert-notifcation.service";
import { AutoSuggestComponent } from "./auto-suggest/auto-suggest.component";
import { FeedbackComponent } from "../core/shared/feedback/feedback.component";
import html2canvas from "html2canvas";
declare var google: any;
import { emitTransactionsService } from "../core/services/emitTransactions.service";
import { IntergrationService } from "app/core/integration/integration.service";
import { Observable } from "rxjs";
import { menuDefaultState, menuState } from "app/core/ngxs/state/menu.state";
import { Select, Store } from "@ngxs/store";
import {
  ActionAddSelectedCollections,
  ActionAddSelectedItem,
  ActionGetMenu,
  ActionGetUpsellingItems,
  ActionResetSelectedItem,
} from "app/core/ngxs/action/menu.action";
import { CustomerComponent } from "./customer/customer.component";
import { menuService } from "app/core/services/menu.service";
import {
  ActionAddItemToCart,
  ActionApplyPromo,
  ActionClearCart,
  ActionCreateCart,
  ActionGetCart,
  ActionRemoveItem,
  ActionUpdateCartItem,
} from "app/core/ngxs/action/cart.action";
import { ActionGetStore } from "app/core/ngxs/action/store.action";
import { storeDefaultState, storeState } from "app/core/ngxs/state/store.state";
import {
  catchError,
  first,
  last,
  map,
  startWith,
  take,
  takeUntil,
  takeWhile,
  tap,
} from "rxjs/operators";
import { BehaviorSubject, of, Subscribable, Subscription } from "rxjs";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { ActionGetCustomer } from "app/core/ngxs/action/customer.action";
import {
  ActionGetPromo,
  ActionGetSpecialPromo,
} from "app/core/ngxs/action/promo.action";
import { promoDefaultState, promoState } from "app/core/ngxs/state/promo.state";
import { ActionSubmitOrder } from "app/core/ngxs/action/order.action";
import { SubmitOrderComponent } from "./submit-order/submit-order.component";
import { LoaderComponent } from "./loader/loader.component";
import {
  customerDefaultState,
  customerState,
} from "app/core/ngxs/state/customer.state";
import {
  ActionGetDeals,
  ActionGetSpecialDeals,
} from "app/core/ngxs/action/deals.action";
import { dealsDefaultState, dealsState } from "app/core/ngxs/state/deals.state";
import Swal from "sweetalert2";
import { CustomerOrdersComponent } from "./customer-orders/customer-orders.component";
import { ActionGetFrequentOrderItem } from "app/core/ngxs/action/frequentOrder.action";
import { ActionGetPastOrder } from "app/core/ngxs/action/pastOrder.action";
import { ActionGetFeedback } from "app/core/ngxs/action/feedback.action";
import { CryptoService } from "app/core/services/crypto.service";
import {
  pastOrderDefaultState,
  pastOrderState,
} from "app/core/ngxs/state/pastOrder.state";
import { data } from "jquery";
import {
  ActionSendPayment,
  ResetPayment,
} from "app/core/ngxs/action/payment.action";
import {
  paymentDefaultState,
  paymentState,
} from "app/core/ngxs/state/payment.state";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ITipModel } from "./tip/model/tip.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LogData, LogMessageService } from "app/core/services/logger.service";
import {
  AgentClientResponse,
  Payment,
} from "../../../mediator-commlibs/interfaces/response/order.interface";

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface SubmitOrderPayload {
  agent_email: string;
  call_duration: string;
  client_id: number;
  client_name: string;
  correlationid: string;
  customer_address: string;
  customer_name: string;
  customer_phone: string;
  external_store_id: string;
  order_id: string;
  order_items_count: number;
  order_metadata: string;
  order_type: string;
  payment_mode: string;
  restaurant_id: number;
  restaurant_phone: string;
  total_price: string;
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
import {
  CommonLibs as constants,
  get_variation_priority,
  remove_default_loaded,
  set_default_loaded,
} from "../commonLibs";
import { DataShareService } from "app/core/services/data-share.service";
import { ViewDealsComponent } from "./view-deals/view-deals.component";
import { decompress } from "compress-json";
import { billingAddressPayment } from "app/transaction/billing-address/interface/billing-address.interface";
import { v4 as uuidv4 } from "uuid";
import { PromoItems } from "../../../mediator-commlibs/interfaces/response/promos.interface";
import {
  AddCart,
  Cart,
  cartPromotions,
} from "../../../mediator-commlibs/interfaces/response/cart.interface";
import { FeatureFlagService } from "app/core/services/feature-flag.service";
import {
  AddCartItem,
  CartItemResponse,
} from "../../../mediator-commlibs/interfaces/response/cartItem.interface";
import { GlobalApiResponse } from "app/core/models/apiResponse.interface";
import { StoreDetails } from "../../../mediator-commlibs/interfaces/response/store.interface";
import {
  CashOptionDisabledError,
  CashOptionDisabledResponse,
} from "../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";
import { NavbarService } from "app/core/navbar/navbar.service";
import * as moment from "moment";
import { AddOn, Category } from "../../../mediator-commlibs/interfaces/response/menu.interface";
import { DealPopupComponent } from "./view-deals/deal-popup.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
@Component({
  selector: "app-order-receive",
  templateUrl: "./order-receive.html",
  styleUrls: ["./order-receive.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UcfirstPipe,
    KeyValuesPipe,
    RemoveSpecialsCharPipe,
    SortByNamePipe,
  ],
  animations: [routerTransition()],
})
export class OrderReceiveComponent
  implements OnInit, AfterViewChecked, AfterViewInit {
  @Select(menuState.getUpSellingItems) upSellingItemsState: Observable<Category[]>;
  @Select(menuState.getMenuDetails) menuState: Observable<menuDefaultState>;
  @Select(menuState.getSelectedItem)
  selectedMenuItem: Observable<menuDefaultState>;
  @Select(menuState.getSelectedCollection)
  selectedCollectionsState: Observable<menuDefaultState>;
  @Select(menuState.getLoader)
  menuloaded: Observable<menuDefaultState>;
  @Select(storeState.getStore) storeState: Observable<storeDefaultState>;
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  @Select(cartState.getLoadedCart) cartLoaded: Observable<cartDefaultState>;
  @Select(promoState.getPromo) promos: Observable<promoDefaultState>;
  @Select(customerState.getCustomer)
  getCustomer: Observable<customerDefaultState>;
  @Select(dealsState.getDeals) deals: Observable<dealsDefaultState>;
  @Select(customerState.getCustomer)
  customerPastOrder: Observable<customerDefaultState>;
  @Select(pastOrderState.getPastOrder)
  pastOrders: Observable<pastOrderDefaultState>;
  @Select(paymentState.getPayments)
  getPaymentSplit: Observable<paymentDefaultState>;

  @ViewChild("scrollCustomer") private customerContainer: ElementRef;
  @ViewChild(AutoSuggestComponent) autoSuggest: AutoSuggestComponent;
  @ViewChild("scrollAgent") private agentContainer: ElementRef;
  d = new Date();

  private lineItemOne: ElementRef;
  @ViewChild("lineItemOne") set content3(content: ElementRef) {
    this.lineItemOne = content;
  }

  private menuOne: ElementRef;
  @ViewChild("menuOne") set content(content: ElementRef) {
    this.menuOne = content;
  }
  private menuTwo: ElementRef;
  @ViewChild("menuTwo") set content2(content: ElementRef) {
    this.menuTwo = content;
  }
  isPermanentSuperPowerMode: boolean = false;
  restaurant_details = {};
  initSession: any = {};
  isPermanentTakeout: boolean = false;

  getDraftResponse: boolean = false;
  special_instructions: string;
  tip: number;
  dont_call_me: boolean = false;
  isQtyOne = false;
  isQtyTwo = true;
  parentOrderId = null;
  categoriesList: any;
  restaurantId: any;
  docHeight: any;
  callData: any;
  specialsData: any;
  customerMsg: string;
  customResponseArray: any = [];
  response_text = "Response Text";
  lineItemForm: FormGroup;
  lineItemForm2: FormGroup;
  editResponseFlag: boolean = false;
  foodTypeOneForm: FormGroup;
  foodTypeTwoForm: FormGroup;
  extraField: FormGroup;
  foodTypeOne: any;
  foodTypeTwo: any;
  foodTypeOneObject: any;
  foodTypeTwoObject: any;
  customerphone: any;
  takeOverFlag: boolean = false;
  isSpecialInstruction = 0;
  orderData: any = {
    order: [],
    orderInProgress: [{}],
  };
  paymentType: any;
  variationOneIsTrue: boolean = false;
  variationTwoIsTrue: boolean = false;
  lineItemOneIsTrue: boolean = false;
  lineItemTwoIsTrue: boolean = false;
  isCallOnHold: boolean = false;
  categories: any;
  restaurantData: any;
  menuList: any = [];
  variationList: any = [];
  collectionList: any = { itemOne: {}, itemTwo: {} };
  collectionCrustList: any = {};
  collectionToppingList: any = {};
  slotsList: any = [];
  tempStoreCurrentIntent: any = {};
  originalSlots: any;
  lexEventResponse: any = [];
  ws: any; // connect
  socket: any;
  uuid: any;
  routeName: string;
  drive_thru_url: SafeResourceUrl;
  loyalty_enrollment_ivr_enabled: boolean;
  logmessage: string;
  restaurantphone: any;
  orderInProgress: boolean = true;
  matcher = new MyErrorStateMatcher();
  messageData: any = [];
  isCustomerOnline: boolean = false;
  count = 0;
  calculatedDistance: 0;
  startTime: any;
  slots: any;
  sentimateText: any;
  preSelectedResponse: any;
  orderAnythingElse: any = ["Yes", "No"];
  orderDeliveryOrTakeout: any = [
    { key: "Yes", value: "Delivery" },
    { key: "No", value: "Pick-Up" },
  ];
  PaymentTypeList: any = ["cash", "card"];
  orderCal = { total_price: "0.00", price: "0.00", tax: "0.00" };
  foodTypeTwoSlotsCount = 0;
  customerNameFlag = false;
  customerAddressFlag = false;
  customerName: any;
  customerAddress: any;
  customerId: string;
  isDelivery = false;
  dialogOrderRef: any;
  dialogPreResponseRef: any;
  eventData: any = [];
  entitiesData: any = {};
  showForm: boolean = false;
  intentList: any;
  name: string = "PlaceOrder";
  debugToggle: boolean = false;
  qtyData = [0.5, 1, 2, 3, 4, 5];
  modeOfOperation: string;
  addressDistance = null;

  subConversationId = null;
  channel = null;
  orderEditIndex: number = null;
  isSuperPowerModeRequire = true;
  restaurantName: string;
  agentDetails: any = {};
  publishSlotsDisabled = false;
  smsTakeOver = false;
  initLambdaResponse = false;
  coupons = [];
  selectedCoupon = null;
  //V2
  website_url: string;
  storeId: string;

  _: any = _;
  cartItem: any;
  isQuantityValid: boolean;
  selectedCategory: any;
  items: any;
  modifiers: any;
  collections: any;
  collectionsModifiers: any;
  collectionsModifierscollections: any;
  collectionsModifierscollectionsmodifiers: any;
  collectionsModifierscollectionsmodifierscollections: any;
  selectedAddons: any;
  isDefaultModifiers: any;
  selectedCollections: any;
  selectedAvailableQuantity: string;
  menuItemsV2: any;
  isQuantityShow: boolean;
  selectedToppings: string;
  availableQuantities: any;
  WHOLE: string;
  LEFT_HALF: string;
  RIGHT_HALF: string;
  selectedQuantity: any;
  notToppings: any;
  alterationCategoryName: any;
  topping_quantity: number;
  toppings: any;
  notAllowMultiple: any;
  isQuantityShowed: boolean;
  selectedProductQuantity: any;
  quantity: any;
  productQuantity: any;
  cart: any = {};
  selectedItem: any;
  productUUID: any;
  productSelected: boolean = false;
  allpromos: PromoItems[] = [];
  alldeals: PromoItems[] = [];
  cartDetail: Partial<Cart>;
  menuRefresh: boolean = false;
  customerDetails: any;
  customerExists: boolean = false;
  currentTime: any;
  pleasewait: MatDialogRef<LoaderComponent>;

  //replica
  cartItem2: any;
  selectedItem2: any;
  isQuantityValid2: boolean;
  selectedCategory2: any;
  items2: any;
  modifiers2: any;
  collections2: any;
  collectionsModifiers2: any;
  collectionsModifierscollections2: any;
  productSelected2: boolean = false;
  collectionsModifierscollectionsmodifiers2: any;
  collectionsModifierscollectionsmodifierscollections2: any;
  selectedAddons2: any;
  isDefaultModifiers2: any;
  selectedCollections2: any;
  selectedAvailableQuantity2: any;
  menuItemsV22: any;
  isQuantityShow2: boolean = false;
  selectedToppings2: string;
  availableQuantities2: any;
  WHOLE2: string;
  LEFT_HALF2: string;
  RIGHT_HALF2: string;
  selectedQuantity2: any;
  notToppings2: any;
  alterationCategoryName2: any;
  topping_quantity2;
  toppings2: any;
  notAllowMultiple2: any;
  isQuantityShowed2: boolean;
  selectedProductQuantity2: any;
  quantity2: any;
  productQuantity2: any;
  allowHalf: boolean;
  selectedStyle: any;
  selectedStyle2: any;
  liveItems: any[] = [];
  promoRefresh: boolean = false;
  menuTest: any;
  editHalfQuantity: number;
  newsub: BehaviorSubject<any> = new BehaviorSubject(null);
  productUUIDEdit: any;
  halfLoader: boolean = false;
  isHalfAndHalf: boolean = false;
  promoSubs: Subscription;
  dealsSubs: Subscription;
  agent: any;
  selectedDealFromPopup: any = null;
  selectedDealText: string = null;
  payment_component_url: string;
  appliedTip: number = 0;
  notes = {
    leftNotes: "",
    rightNotes: "",
  };
  sessionToken: string = "";
  uuidforGooglePlace: string = uuidv4();
  latlong: string = "";
  applyRemovePromo = null;
  storeMaxOrderAmt: string;
  getCartAmount: string;
  disableSubmitOrderButton: boolean = false;
  getDealsSocketResponse: any;
  getPromosSocketResponse: any;
  setAutoApplyCall: boolean;
  notificationFlow = {
    notificationCIP: true,
    customerPayload: null,
    isCartModalOpened: false,
    isNotificationAPIFailed: false
  }
  isUpsellingAddToCart = false;
  isDriveThruUrl: boolean;
  clientFullName = "";
  constructor(
    private featureFlagService: FeatureFlagService,
    private integrationService: IntergrationService,
    public fb: FormBuilder,
    private voixHttp: VoixHttpService,
    public agentResponseService: AgentResponseService,
    public orService: OrderReceiveService,
    private route: ActivatedRoute,
    private ucFirst: UcfirstPipe,
    private KeyValues: KeyValuesPipe,
    private auth: AuthenticationService,
    private removeSpecialsChar: RemoveSpecialsCharPipe,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private notification: NotificationService,
    private general: generalService,
    private alertNotification: alertNotificationService,
    public emitTran: emitTransactionsService,
    private ngxsStore: Store,
    private menuservice: menuService,
    private router: Router,
    private cryptoService: CryptoService,
    private datashare: DataShareService,
    private ngxService: NgxUiLoaderService,
    private changeDetector: ChangeDetectorRef,
    private logger: LogMessageService,
    private navbarService: NavbarService,
    protected _sanitizer: DomSanitizer,
  ) {
    this.isDriveThruUrl = window.location.pathname.includes('/drive-thru/');
    this.autoApplyCall();
    this.emitTran.getPromos();
    this.emitTran.getDeal();
    this.newsub.subscribe((data) => {
      if (data) {
        // console.log("data is pushed in new sub");
      }
    });
    this.menuState.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        // this.cryptoService.EncryptingMenu(res);
        this.emitTran.cloneMenu(res);
        this.menuTest = res;
      }
    });

    this.emitTran.getMenuTrigger$.subscribe((res: any) => {
      if (res) {
        this.getMenuSocket();
      }
    });
    localStorage.removeItem("1");
    localStorage.removeItem("2");
    localStorage.removeItem("Rmt");
    localStorage.removeItem("tA");

    if (localStorage.getItem("agentCurrentUser")) {
      let agentString = localStorage.getItem("agentCurrentUser");
      this.agent = JSON.parse(agentString);
    }

    this.currentTime = new Date();
    this.navbarService.notificationDrawer$.subscribe(value => {
      if (value === false && !this.isCartExist && !this.notificationFlow?.isCartModalOpened) {
        if (this.notificationFlow?.customerPayload) {
          this.notificationFlow.isCartModalOpened = true;
          this.getCustomerState(this.notificationFlow.customerPayload);
        } else {
          this.notificationFlow.notificationCIP = false;
        }
      }
    });
    this.emitTran.cartData$.subscribe((res: any) => {
      if (res) {
        if (res?.isOrderNotesUpdate) {
          localStorage.setItem("cln", "true");
          let cartId = this.general.getCartId();
          const {
            apt_suite,
            customer,
            orderDestination,
            orderReadyDate,
            storeId,
          } = this.cart;
          this.createCart({
            apt_suite,
            cartId,
            customer,
            orderDestination,
            orderReadyDate,
            storeId,
            notes: res?.notes,
          });
          this.productSelected = false;
          this.productSelected2 = false;
        } else if (res.clear) {
          this.menuservice.removecart.next(true);
          this.clearCart();
        } else {
          this.menuservice.removecart.next(true);
          this.removeItemFromCartSocket(res);
        }
      }
    });

    this.emitTran.cartDetail$.subscribe((res: any) => {
      if (res) {
        if (res?.type === "removeOrderLevelPromo") {
          this.ngxService.start();
          this.cartDetail.cartPromotions = res?.updatePromos;
          this.applyRemovePromo = {
            type: res?.type,
            success: "Promo is removed",
            error: "Promo is not removed",
            removedOfferId: res?.removedOfferId,
          };
          this.emitSubmitPromo();
        } else if (
          res?.type === "applyItemPromo" ||
          res?.type === "removeItemLevelPromo"
        ) {
          this.applyRemovePromo = {
            type: res?.type,
            success:
              res?.type === "applyItemPromo"
                ? "Promo is applied"
                : "Promo is removed",
            error:
              res?.type === "applyItemPromo"
                ? "Promo is not applied"
                : "Promo is not removed",
          };
          this.applyPromo(true, res.item);
        } else {
          // delete res.ledger;
          // console.log(res);
          this.productUUIDEdit = res.productUUID;
          this.cartItems = [];
          this.cartItems.push(res);
          this.updateCartSocket(true);
        }
      }
    });

    this.emitTran.openPopup$.subscribe((res: any) => {
      if (res) {
        this.showCustomerPopup(false, true);
      }
    });

    this.cartState.subscribe((res: any) => {
      if (res) {
        this.cartDetail = res;
        this.liveItems = res.cartMenuItems;
        // console.log(this.cartDetail);
      }
    });

    this.emitTran.editItemCart$.subscribe((res: any) => {
      if (res) {
        const { productUUID, quantity, isHalfAndHalf, notes } = res;
        this.productUUIDEdit = productUUID;
        this.editHalfQuantity = quantity;
        this.getMenuTransform(productUUID);
        if (isHalfAndHalf) {
          this.notes.leftNotes = notes;
          this.notes.rightNotes = notes;
          this.isHalfAndHalf = isHalfAndHalf;
        } else {
          this.notes.leftNotes = notes;
        }
        this.emitTran.updateCartLoader(true);
      }
    });

    this.emitTran.isCartExist$.subscribe((res: any) => {
      if (res) {
        this.isCartExist = res;
      }
    });

    this.emitTran.cartItem$.subscribe((res: any) => {
      if (res) {
        if (Array.isArray(res)) {
          this.cartItems = res;
          // console.log(this.cartItems);
          this.addItemToCartSocket(false);
        } else {
          this.cartItems = [res];
          this.addItemToCartSocket(true);
        }
      }
    });

    this.emitTran.isHalfMenu$.subscribe((res: any) => {
      if (res) {
        this.halfLoader = true;
        this.halfMenuSocket(res);
      }
    });

    this.getPaymentUrlRequest();
    this.promoSubs = this.promoControl.valueChanges
      .pipe(
        startWith(""),
        map((value) => this._filter(value || ""))
      )
      .subscribe();

    this.dealControl.valueChanges
      .pipe(
        startWith(""),
        map((value) => this._filterDeals(value || ""))
      )
      .subscribe();
    this.orService.setLocalstorage("last_event_obj", {});
    localStorage.removeItem(AppConstants.SIP_NOTIFY);
    localStorage.removeItem("transationId");
    this.intentList = this.orService.getIntents();
    const url = this.router.url.trim().split('/').find(portion => !!portion);
    this.routeName = url as string

    this.route.params.subscribe((params) => {
      this.uuid = params["uuid"];
      this.customerphone = params["customerphone"] || '123456789';
      this.restaurantphone = params["restaurantphone"];
      this.socket = io(environment.io_url, {
        query: "token=" + this.uuid,
        transports: ["polling"],
      });
      this.ws = new $WebSocket(
        environment.ws + "?platform=dashboard&token=" + this.uuid,
        null,
        JSON.parse('{"reconnectIfNotNormalClose": true}'),
        "arraybuffer"
      );
    });
    this.route.queryParamMap.subscribe((queryParams) => {
      this.parentOrderId = queryParams.get("parentOrderId");
      if (
        queryParams.get("takeover") &&
        queryParams.get("takeover") == "true"
      ) {
        this.smsTakeOver = true;
      }
    });
    this.preSelectedResponse = this.agentResponseService.preselectedResponse();
    this.foodTypeOne = this.agentResponseService.foodTypeOneMapping();
    this.foodTypeTwo = this.agentResponseService.foodTypeTwoMapping();
    this.messageData["customer"] = [];
    this.messageData["agent"] = [];
    this.generateForm(true);
    this.extraField = fb.group({ tip: [null] });
    this.lineItemForm = fb.group(this.orService.lineItemFormObject(fb));
    this.lineItemForm2 = fb.group(this.orService.lineItemFormObject(fb));
    this.emitTran.addItemSubject$.subscribe((item) => {
      if (item) {
        this.cartItems[0] = item;
        this.isUpsellingAddToCart = true;
        this.addItemToCartSocket(true);
      }
    });
    // this.ws.onMessage((msg: MessageEvent) => {}, { autoApply: true });
    // this.ws.onOpen(
    //   (event: Event) => {
    //     // console.log("on Open", event);
    //     this.alertNotification.set({
    //       value: AppConstants.WEB_SOCKET,
    //       error: false,
    //     });
    //   },
    //   { autoApply: true }
    // );

    // this.ws.onError((event: Event) => {
    //   console.log("on error", event);
    //   this.alertNotification.set({
    //     text: "Hub Websocket connection failed",
    //     value: AppConstants.WEB_SOCKET,
    //     error: true,
    //   });
    // });

    // this.ws.onClose((event: Event) => {
    //   console.log("on close", event);
    // });

    // // set received message stream
    // this.ws.getDataStream().subscribe(
    //   (msg) => {
    //     this.ws.binaryType = "arraybuffer";
    //     this.isCustomerOnline = true;
    //     if (typeof msg.data == "string") {
    //       if (this.customerNameFlag) {
    //         this.customerName = msg.data;
    //         this.customerNameFlag = false;
    //       }
    //       if (this.customerAddressFlag) {
    //         this.customerAddress = msg.data;
    //         this.customerAddressFlag = false;
    //       }

    //       if (this.isJSON(msg.data)) {
    //         var msgObject = JSON.parse(msg.data);
    //         if (msgObject.action.toUpperCase() === "DTMF") {
    //           this.updateSuperMode({ checked: true });
    //         } else if (msgObject.action.toUpperCase() === "TAKEOVER") {
    //           this.setTakeOverFlag();
    //         } else if (!_.isUndefined(msgObject.alertNotify)) {
    //           this.feedBackDialogOR();
    //           this.notification.show(
    //             msgObject.alertNotify,
    //             "notifications",
    //             msgObject.text,
    //             "right"
    //           );
    //         }
    //       } else {
    //         this.messageData["customer"].push({
    //           side: "customer",
    //           message: msg.data,
    //         });
    //         this.customerMsg = msg.data;
    //       }
    //     }
    //   },
    //   (msg) => {
    //     console.log("error", msg);
    //   },
    //   () => {
    //     console.log("complete");
    //     //alert('connect close');
    //   }
    // );
  }
  billingAddressZip: number | null = null;
  billingAddress: billingAddressPayment;
  getPaymentUrlRequest() {
    this.emitTran.getPaymentUrlRequest$.subscribe(
      (res: billingAddressPayment) => {
        if (res) {
          // this.ngxService.start();
          // console.log("Get Payment URL Request");
          this.billingAddress = res;
          this.billingAddressZip = res.zip_code;
          // console.log(res);
          this.ngxService.start();
          this.getPaymentUrlSocket(res);
        }
      }
    );
  }
  errorMessageWorldpay: string;
  receiveMessage(event) {
    // console.log("Received data from iframe", event);
    if (
      event.data.HostedPaymentStatus != undefined &&
      event.data.HostedPaymentStatus == "Complete"
    ) {
      this.loggedELK(
        JSON.stringify(event.data),
        "WORLD_PAY_RES_AFTER_PAYMENT",
        false
      );
      // console.log("\n World Pay Socket Channel", event.data);
      if (
        event.data.AVSResponseCode === "N" ||
        event.data.CVVResponseCode === "N"
      ) {
        const AVSErrorMessage =
          "Address given doesn't match the address on card. The payment is being reversed. Please process the payment again with correct address.";
        const CVVErrorMessage =
          "CVV given doesn't match the CVV on card. The payment is being reversed. Please process the payment again with correct CVV";
        this.errorMessageWorldpay =
          event.data.AVSResponseCode === "N"
            ? AVSErrorMessage
            : CVVErrorMessage;
        this.worldPayReversalSocket(event.data);
      } else {
        this.setLocalStorageParent();
        this.dialog.closeAll();
        this.submitOrderWorldPay(event.data);
      }
    }

    if (event.data.type == "INTERNAL") {
      // console.log(event.data);
      this.setLocalStorageParent();
      this.paymentCCInternal(event.data);
    }
  }

  worldPayReversalSocket(params) {
    this.ngxService.start();
    const cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.WORLD_PAY_CC_REVERSAL,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        params: params,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  setLocalStorageParent() {
    window.parent.localStorage.setItem(
      "agentCurrentUser",
      JSON.stringify(this.agent)
    );
  }

  paymentCCInternal(response) {
    this.ngxService.start();
    this.paymentTypeV2 = response.paymentType;
    this.orderForm = response.cardForm;
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.PAYMENT_CREDIT_CARD,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        paymentBody: this.orderForm,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }
  getWindowListener() {
    console.log("message From Parent");
    window.addEventListener("message", this.receiveMessage.bind(this), false);
  }

  processNotification(data) {
    try {
      if (data.length > 0 && data.length === this.notifications.length) {
        return;
      }
      const notifications = data.sort((a, b) => {
        // First, compare priorities
        if (a.priority < b.priority) return -1;
        if (a.priority > b.priority) return 1;
      
        // If priorities are the same, compare dates
        const dateA: any = new Date(a.createdAt);
        const dateB: any = new Date(b.createdAt);
      
        return dateA - dateB;
      });
      this.navbarService.setNotifications(notifications);
      if (!this.isCartExist && notifications?.length) {
        this.navbarService.emitNotificationDrawerToggle(true);
      } else {
        this.notificationFlow.notificationCIP = false;
        if (!this.notificationFlow.isCartModalOpened && this.notificationFlow.customerPayload) {
          this.getCustomerState(this.notificationFlow.customerPayload);
        }
      }
    } catch (error) {
      this.notificationFlow.notificationCIP = false;
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          error?.error?.message ||
          error?.message ||
          error?.error ||
          "Something went wrong while fetching notifications",
          "danger-snackbar"
        );
    }
  }
// fetch from integration
  getNotifications(storeId, clientName, customParams = {}) {
    const params = {
      limit: 50,
      page: 1,
      store: "",
      clientCode: clientName,
      show: '',
      storeId: storeId,
      restaurantId: this.restaurantId,
      customerId: this.customerId,
      customerPhone:this.customerphone,
      ...customParams
    };

    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.FETCH_NOTIFICATION,
      message: {
        ClientCode: this.restaurantName,
        storeId: storeId,
       params,
      },
    };

    // console.log(payload);
    this.socket.send(payload);
  }
  
  GetCurrentTipValue() {
    this.emitTran.getCurrentTipValue$.subscribe((tip: ITipModel) => {
      // console.log("INITIAT THE TIP CHANNEL", tip);
      this.appliedTip = tip.TipValue;
      let cartId = this.general.getCartId();
      let payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.APPLY_PRETIP,
        message: {
          ClientCode: this.restaurantName,
          storeId: this.restaurant_details["storeId"],
          cartId: cartId,
          tipBody: { tipAmount: tip.TipValue },
        },
      };
      // console.log(payload);
      this.socket.send(payload);
    });
  }

  calling() {
    this.newsub.next("data");
  }
  // ----------------------- on init for socket and preloader -------------------------------
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.docHeight = window.innerHeight - 70;
  }
  @HostListener("window:keydown", ["$event"])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 32) {
      this.publishSlots();
    }
    if ($event.key == "c" && $event.ctrlKey) {
      if (this.smsTakeOver) {
        this.agentResponseService.copyToClipboard(this.response_text);
      }
    }
    // console.log($event);
  }
  makepayment() {
    this.customerName = "";
    if (
      this.tempStoreCurrentIntent &&
      this.tempStoreCurrentIntent.sessionAttributes
    ) {
      var sessionAttributes = this.tempStoreCurrentIntent.sessionAttributes;
      if (!_.isEmpty(sessionAttributes.CustomerName)) {
        this.customerName = sessionAttributes.CustomerName;
      }
    }
    window.open(
      "/payments?name=" +
      this.customerName +
      "&phone=" +
      this.customerphone +
      "&amount=" +
      this.orderCal.total_price,
      "Make Payment",
      "resizable=no,scrollbars=no,status=yes,height=550,width=450,top=100,left=960"
    );
  }

  feedBackDialogOR(): void {
    let feedbackDialogRef = this.dialog.open(FeedbackComponent, {
      width: "500px",
      data: { conversationId: this.uuid },
    });
  }
  orderTypes: any[] = [
    { value: "DELIVERY", viewValue: "DELIVERY" },
    { value: "PICKUP", viewValue: "PICKUP" },
  ];
  ngAfterViewInit() {
    // let ringing:any = new Audio("assets/img/telephone-ring-01a.wav");
    // ringing.type = 'audio/wav';
    // var playPromise = ringing.play();
    var orComponent = this;
    // if (playPromise !== undefined) {
    //   playPromise.then(function () {
    //     console.log('Playing....');
    //   }).catch(function (error) {
    //     console.log('Failed to play....' + error);
    //     //orComponent.alertNotification.set({text: "Agent Browser autoplay-policy should be \"No user gesture is required.\", Please check this link - chrome://flags/#autoplay-policy", value: 'auto-play-policy', error: true});
    //   });
    // }

    setTimeout(() => {
      if (!orComponent.socket.connected) {
        orComponent.alertNotification.set({
          text: "Agent Backend socket connection is not connected",
          value: AppConstants.NOT_CONNECTED,
          error: true,
        });
        window.location.reload();
        orComponent.transferCall(AppConstants.AGENT_BACKEND_SOCKET_FAILED);
      }
    }, 5000);

    window.addEventListener("storage", (event) => {
      if (event.key == AppConstants.SIP_ALERT) {
        var alertData = JSON.parse(event.newValue);
        if (alertData.error) {
          orComponent.transferCall(AppConstants.SIP_FAILED);
        }
      } else if (event.key == AppConstants.SIP_NOTIFY) {
        var notifyData = JSON.parse(event.newValue);
        this.notification.show(
          notifyData.alertNotify,
          "notifications",
          notifyData.text,
          "right"
        );
        localStorage.removeItem(AppConstants.SIP_NOTIFY);
      }
    });
  }
  allMenus: any;
  str: string = "aasdhtrtvb563";
  cartS: any;
  promoControl = new FormControl("");
  dealControl = new FormControl("");
  options: string[] = ["One", "Two", "Three"];
  dealsOptionsV2 = new BehaviorSubject<any>([]);
  promosOptionsV2 = new BehaviorSubject<any>([]);
  totalmin: number = 0;
  totalmin1: number = 0;
  totalmin2: number = 0;
  uniqueLeftForm: any[] = [];
  uniqueRightForm: any[] = [];
  totalUnique: number = 0;
  available: boolean = false;
  isEdit: boolean;
  isCartExist: boolean = false;
  cartExistPayload: GlobalApiResponse;
  storeData: any;
  logunsub: Subscription;
  isPaymentSupportInternal: boolean = true;
  notifications:any = []
  ngOnInit() {
    this.getWindowListener();
    this.emitTran.requestAddress$.subscribe((value) => {
      if (value) {
        this.getAddress(value);
      }
    });
    this.navbarService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });

    this.emitTran.requestAddressOutOfBound$.subscribe((value) => {
      if (value) {
        this.ngxService.startLoader("customerLoader");
        this.getAddressOutOfBound(this.cart);
      }
    });

    this.logunsub = this.logger.logMessage$.subscribe((logData: LogData) => {
      if (logData) {
        this.logmessage = logData?.logMessage; // Assign the value to the logmessage variable
        const payload = {
          id: this.uuid,
          agent_email: this.agent.user.email,
          channel: logData?.isPause
            ? constants.CALL_MUTED
            : constants.CALL_UNMUTED,
          message: {
            logMessage: this.logmessage,
            ClientCode: this.restaurantName,
            storeId: this.restaurant_details["storeId"],
          },
        };
        this.socket.send(payload);
      }
    });

    this.emitTran.requestPlace$.subscribe((value) => {
      if (value) {
        this.getPlaceDetails(value);
      }
    });
    this.promos.subscribe((res: any) => {
      if (res.data) {
        // console.log(res.data);
        this.allpromos = res.data;
        this.promosOptionsV2.next(this.allpromos);
        // console.log(this.promosOptionsV2);
      }
    });
    this.deals.subscribe((res: any) => {
      if (res.data) {
        // console.log(res.data);
        this.alldeals = res.data || [];
        this.dealsOptionsV2.next(this.alldeals);
      }
    });

    // this.dealsOptionsV2 = this.dealControl.valueChanges.pipe(
    //   startWith(""),
    //   map((value) => this._filterDeals(value || ""))
    // );

    this.menuservice.allData.subscribe((data: any) => {
      if (data) {
        this.cartItem = data.cartItem;
        this.selectedItem = data.selectedItem;
        if (data.selectedItem?.notes) {
          this.notes.leftNotes = data.selectedItem?.notes;
        }
        this.isQuantityValid = data.isQuantityValid;
        this.selectedCategory = data.selectedCategory;
        this.items = data.items;
        this.modifiers = data.modifiers;
        this.collections = data.collections;
        this.collectionsModifiers = data.collectionsModifiers;
        this.collectionsModifierscollections =
          data.collectionsModifierscollections;

        this.collectionsModifierscollectionsmodifiers =
          data.collectionsModifierscollectionsmodifiers;
        this.collectionsModifierscollectionsmodifierscollections =
          data.collectionsModifierscollectionsmodifierscollections;
        this.selectedAddons = data.selectedAddons;
        this.isDefaultModifiers = data.isDefaultModifiers;
        this.selectedCollections = data.selectedCollections;
        this.selectedAvailableQuantity = data.selectedAvailableQuantity;
        this.menuItemsV2 = data.menuItemsV2;
        this.isQuantityShow = data.isQuantityShow;
        this.selectedToppings = data.selectedToppings;
        this.availableQuantities = data.availableQuantities;
        this.WHOLE = data.WHOLE;
        this.LEFT_HALF = data.LEFT_HALF;
        this.RIGHT_HALF = data.RIGHT_HALF;
        this.selectedQuantity = data.selectedQuantity;
        this.notToppings = data.notToppings;
        this.selectedStyle = data.selectedStyle;

        if (this.collectionsModifierscollections.length > 0) {
          // console.log(true);

          this.productSelected = true;
        } else {
          // console.log(false);

          this.productSelected = false;
        }
        this.isEdit = data.isEdit;
        this.alterationCategoryName = data.alterationCategoryName;
        this.topping_quantity = data.topping_quantity;
        this.toppings = data.toppings;
        this.notAllowMultiple = data.notAllowMultiple;
        this.isQuantityShowed = data.isQuantityShowed;
        this.selectedProductQuantity = data.selectedProductQuantity;
        this.quantity = data.quantity;
        // this.available = data.available;

        // if (data.available) {
        //   this.productSelected = false;
        // } else {
        //   this.productSelected = true;
        // }
        this.productQuantity = data.productQuantity;
      }
    });

    this.menuservice.allDataSecond.subscribe((data: any) => {
      if (data) {
        this.cartItem2 = data.cartItem2;
        this.selectedItem2 = data.selectedItem2;
        if (data.selectedItem2?.notes) {
          this.notes.rightNotes = data.selectedItem2?.notes;
        }
        this.isQuantityValid2 = data.isQuantityValid2;
        this.selectedCategory2 = data.selectedCategory2;
        this.items2 = data.items2;
        this.modifiers2 = data.modifiers2;
        this.collections2 = data.collections2;
        this.collectionsModifiers2 = data.collectionsModifiers2;
        this.collectionsModifierscollections2 =
          data.collectionsModifierscollections2;

        this.collectionsModifierscollectionsmodifiers2 =
          data.collectionsModifierscollectionsmodifiers2;
        this.collectionsModifierscollectionsmodifierscollections2 =
          data.collectionsModifierscollectionsmodifierscollections2;
        this.selectedAddons2 = data.selectedAddons2;
        this.isDefaultModifiers2 = data.isDefaultModifiers2;
        this.selectedCollections2 = data.selectedCollections2;
        this.selectedAvailableQuantity2 = data.selectedAvailableQuantity2;
        this.menuItemsV22 = data.menuItemsV22;
        this.isQuantityShow2 = data.isQuantityShow2;
        this.selectedToppings2 = data.selectedToppings2;
        this.availableQuantities2 = data.availableQuantities2;
        this.WHOLE2 = data.WHOLE2;
        this.LEFT_HALF2 = data.LEFT_HALF2;
        this.RIGHT_HALF2 = data.RIGHT_HALF2;
        (this.allowHalf = data.allowHalf2),
          (this.selectedQuantity2 = data.selectedQuantity2);
        this.notToppings2 = data.notToppings2;
        this.selectedStyle2 = data.selectedStyle2;
        this.alterationCategoryName2 = data.alterationCategoryName2;

        if (this.collectionsModifierscollections2.length > 0) {
          // console.log(true);

          this.productSelected2 = true;
        } else {
          // console.log(false);

          this.productSelected2 = false;
        }
        this.topping_quantity2 = data.topping_quantity2;
        this.toppings2 = data.toppings2;
        this.notAllowMultiple2 = data.notAllowMultiple2;
        this.isQuantityShowed2 = data.isQuantityShowed2;
        this.selectedProductQuantity2 = data.selectedProductQuantity2;
        this.quantity2 = data.quantity2;
        this.productQuantity2 = data.productQuantity2;
      }
    });

    // if (this.promoRefresh) {
    //   this.allpromos = [];
    //   // this.getPromosSocket(this.cart.orderDestination);
    //   this.promos.subscribe((res: any) => {
    //     if (res.data) {
    //       // console.log(res.data);
    //       this.allpromos = res.data || [];
    //     }
    //   });
    // }
    // this.docHeight = window.innerHeight - 100;
    var that = this;

    // this.dialog.open(FeedbackComponent, {
    //   width: "500px",
    //   data: { conversationId: this.uuid },
    // });

    this.socket.on("connect", function () {
      that.alertNotification.set({
        value: AppConstants.NOT_CONNECTED,
        error: false,
      });
      that.route.params.subscribe((params) => {
        that.uuid = params["uuid"];
        that.customerphone = params["customerphone"] || '123456789';
        that.restaurantphone = params["restaurantphone"];
        that.subscribeChannel(params["uuid"]); //this is for subscribe channel
        // that.getRestaurantDetails(that.restaurantphone);
        that.getRestaurantDetailsV2(that.restaurantphone);

        that.messageData["agent"][that.uuid] = [];
      });
    });

    this.socket.on("error", (error) => {
      // console.log(`Socket Error:${error}`);
    });

    this.auth.userDetail().subscribe((agent: any) => {
      if (agent && agent.success) {
        this.dont_call_me = agent.user.dont_call_me;
        this.agentDetails = {
          agent_id: agent.user.id,
          agent_email: agent.user.email,
        };
      }
    });

    this.socket.on("message", async function (data) {
      if (data.channel) {
        this.uuid = data.id;
        var channelArray = data.channel.split("\\");
        if (
          channelArray[1] === constants.LEX_SLOTS_CHANNEL.toUpperCase() ||
          data.channel === constants.LEX_SLOTS_CHANNEL
        ) {
          //slot receive from redis publish
          // console.log("dsdsd", data);
          that.publishSlotsDisabled = false;
          that.orderInProgessSlot(data.message);
          that.listenSlots();
          that.recordScreen("listen-slots");
          that.publishSlotsDisabled = false;
          that.editResponseFlag = false;
          that.isSuperPowerModeRequire = false;
        }
        // else if (
        //   channelArray[1] === constants.LAMBDA_RESPONSE_CHANNEL.toUpperCase() ||
        //   data.channel === constants.LAMBDA_RESPONSE_CHANNEL
        // ) {
        //   console.log(data);
        //   that.publishSlotsDisabled = false;
        //   that.agentPushOrderMessage(data.message, "lambda");
        //   that.retriveOrderCalculation();
        // }
        // else if (data.channel === constants.FETCH_ORDER_CHANNEL) {
        //   that.agentPushOrderMessage(data.message, "cache");
        //   that.retriveOrderCalculation();
        // }
        else if (data.channel == constants.GET_DRAFT_ORDER) {
          that.getDraftResponse = true;

          // console.log(data.message.data);
          that.getStoreSocket();
          const conversation = (
            data?.message?.data?.data?.conversationSoFar || []
          )?.filter((chat) => chat?.agent || chat?.customer);
          if (data.message.data.data != null) {
            // console.log(data.message.data);
            if (data.message?.data?.data?.cart) {
              that.isCartExist = true;
              that.emitTran.IsCartExist(true);
              that.getMaximumCashOrderAmount(
                that.cart?.orderDestination ||
                data.message.data.data?.cart?.orderDestination
              );
              console.log('data.message.data; --> ', data.message.data)
              that.cartExistPayload = data.message.data;
              that.cart = data.message.data.data.cart;
              that.customerId = data.message.data.data.cart?.customer?.id;
              that.general.cartID = data.message.data.data?.cart?.id;
              that.getMenuSocket();
              if (data.message.data?.data?.cart?.cartMenuItems?.length) {
                that.getUpSellingSocket();
              }
            }
            
            if (conversation?.length) {
              that.navbarService.setConversation(conversation);
              that.navbarService.emitDrawerToggle(
                !that.navbarService.chatDrawerSub.getValue()
              );
            }
          }
          if (!data.message?.data?.data?.cart && conversation?.length) {
            that.navbarService.chatDrawerSub
              .pipe(takeWhile(() => !that.isCartExist))
              .subscribe((isDrawer) => {
                if (!isDrawer) {
                  that.getCustomerSocket();
                }
              });
          } else {
            that.getCustomerSocket();
          }

          // that.getFeedBack();

          // that.setTakeOverFlag(false);
          // that.retriveCustomResponseObject();
          // that.getPastOrderState(data.message.data);
        }
        // else if (data.channel === constants.SENTIMENT_RESPONSE_CHANNEL) {
        //   that.transferAgentResponse(data.message);
        // }
        // else if (data.channel === constants.ORDER_CALCULATION_CHANNEL) {
        //   that.orderCal = data.message;
        //   if (that.dialogOrderRef && that.dialogOrderRef.componentInstance) {
        //     that.dialogOrderRef.componentInstance.data.orderCal = that.orderCal;
        //   }
        // }
        // else if (data.channel === constants.RADIUS_CHANNEL) {
        //   that.calculatedDistance = data.message;
        //   that.validateAddress(data.message);
        // } else if (data.channel === constants.LINE_ITEM_PRICING_CHANNEL) {
        //   that.pricingInquery(data.message);
        // } else if (data.channel === "ERROR_HANDLING") {
        //   that.publishSlotsDisabled = false;
        //   that.notification.show(
        //     "warning",
        //     "notifications",
        //     data.message,
        //     "center"
        //   );
        // }
        //V2
        else if (data.channel == constants.CREATE_CART) {
          that.ngxService.stop();
          localStorage.removeItem("Rmt");
          if (data.message.data.error) {
            that.emitTran.createCartLoader(false);
            const isAddressOutOfRange = that.general.isAddressOutOfRange;
            that.showCustomerPopup(
              true,
              false,
              data.message.data.error.message == "400_BAD_REQUEST" ||
                data.message.data.error.message ==
                "Address is Out of Range.Please Make This Order as Carryout"
                ? true
                : false || data.message.data.error.statusCode == 406
                  ? true
                  : false,
              data.message.data.error.message,
              data.message.data.error.statusCode
            );
            that.loggedELK(
              data.message.data.error.message,
              "CREATE_CART_DASHBOARD",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.statusCode == 406 && !isAddressOutOfRange
                ? "Address is Out of Range.Please Make This Order as Carryout"
                : data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.getMaximumCashOrderAmount(
              that.cart?.orderDestination ||
              data.message.data.data?.cart?.orderDestination
            );
            that.createCartState(data.message.data);
            that.UpdateCartState(data.message.data);
            that.verifyMinimumOrderPriceMet(data.message.data);
            that.emitTran.createCartLoader(false);
            that.isCartExist = true;
            if(that.cart?.customer?.id) {
              const params = {
                customerId: that.cart?.customer?.id,
                customerPhone: that.cart?.customer?.phone
              }
              that.getNotifications(that.cart?.storeId, that.restaurantName, params);
            }
          }
        } else if (data.channel == constants.GET_STORE_DETAILS) {
          that.ngxService.stop();
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.getStoreState(data.message.data);
          }
        } else if (data.channel == constants.GET_UP_SELLING_ITEMS) {
          that.fetchUpsellingItems(data.message.data);
        } else if (data.channel == constants.GET_MENU) {
          // console.log("MENU CALLED");
          that.ngxService.stop();

          that.fetchMenuItemDataV2(data.message.data);
        } else if (data.channel == constants.GET_CUSTOMER) {
          that.ngxService.stop();
          that.notificationFlow.customerPayload = data.message.data;
          if (!that.notificationFlow.notificationCIP || that.isCartExist || that.notificationFlow.isNotificationAPIFailed) {
            that.notificationFlow.isCartModalOpened = true;
            that.getCustomerState(data.message.data);
          }
        } else if (data.channel == constants.ADD_ITEM_TO_CART) {
          that.ngxService.stop();
          if (data.message.data.error) {
            that.menuRefresh = false;
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.emitTran.addItemLoader(false);
            that.cartItems = [];
          } else {
            that.addItemToCartState(data.message.data);
            that.UpdateCartState(data.message.data);
            that.verifyCartAmount(data.message.data);
            that.verifyMinimumOrderPriceMet(data.message.data);
            that.upSellingItemsState.pipe(first()).subscribe((upSellingItems) => !upSellingItems?.length && that.getUpSellingSocket())
            if (that.isUpsellingAddToCart) {
              that.loggedELK(
                data.message.data,
                "ADD_UPSELLING_ITEM_TO_CART",
                false
              );
            }
            that.promoRefresh = true;
          }
          that.isUpsellingAddToCart = false;
        } else if (data.channel == constants.GET_CART) {
          that.getCartDetailState(data.message.data);
          that.UpdateCartState(data.message.data);
          that.verifyMinimumOrderPriceMet(data.message.data);
        } else if (data.channel == constants.REMOVE_ITEM_FROM_CART) {
          that.ngxService.stop();

          that.removeItemFromCartState(data.message.data);
          that.UpdateCartState(data.message.data);
          that.verifyCartAmount(data.message.data);
          that.verifyMinimumOrderPriceMet(data.message.data);
        } else if (data.channel == constants.GET_PROMOS) {
          if (data?.message?.data?.data?.cartId) {
            that.getSpecialPromos(data.message.data);
          } else {
            that.emitTran.getPromos(false);
            that.getPromos(data.message.data);
          }
        } else if (data.channel == constants.SUBMIT_ORDER) {
          console.log(data.message.data);
          that.ngxService.stop();
          if (data.message.data.error) {
            that.loggedELK(
              data.message.data.error.message,
              "SUBMIT_ORDER_DASHBOARD",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.ngxsStore.dispatch(new ResetPayment());
          } else {
            that.saveOrder(data.message.data);
            that.saveOrderToCrm(data.message.data);
            that.submitOrderV2(data.message.data);
            // localStorage.removeItem("Rmt");
          }
          // that.pleasewait.close();
        } else if (data.channel == constants.GET_NEAR_BY_STORES) {
          that.emitTran.recieveAddressOutOfBound$.next(
            data?.message?.data || []
          );
        } else if (data.channel == constants.ADDRESS_FROM_GOOGLE) {
          that.emitTran.recieveAddress$.next(data?.message?.data || []);
        } else if (data.channel == constants.ADDRESS_DETAILS_FROM_GOOGLE) {
          that.emitTran.recievePlace$.next(data?.message?.data || {});
        } else if (data.channel == constants.APPLY_PROMO) {
          if (data.message.data.error) {
            that.selectedDealText = "";
            that.loggedELK(
              data.message.data?.error?.message,
              "APPLY_PROMO",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else if (that.applyRemovePromo != null) {
            const removeapplyRemovePromo =
              that.applyRemovePromo?.type === "removeItemLevelPromo" &&
              !data.message?.data?.data?.cart?.cartMenuItems?.[
                that.applyRemovePromo?.index
              ]?.cartPromotions?.length;
            let { type, index, removedOfferId, offerId, promoCode } =
              that.applyRemovePromo;
            offerId = (offerId || "")?.toLowerCase();
            promoCode = (promoCode || "")?.toLowerCase();
            removedOfferId = (removedOfferId || "")?.toLowerCase();
            if (
              (type === "applyItemPromo" &&
                data.message?.data?.data?.cart?.cartMenuItems?.[index]
                  ?.cartPromotions?.length) ||
              removeapplyRemovePromo ||
              (type === "removeOrderLevelPromo" &&
                !data.message?.data?.data?.cart?.cartPromotions?.find(
                  (promo) => promo?.offerId?.toLowerCase() === removedOfferId
                )) ||
              (type === "applyPromo" &&
                data.message?.data?.data?.cart?.cartPromotions?.find(
                  (promo) =>
                    promo?.offerId?.toLowerCase() === offerId ||
                    promo?.promoCode?.toLowerCase() === promoCode ||
                    promo?.cartPromotionId?.toLowerCase() === promoCode
                )) ||
              that.applyRemovePromo?.type === "autoApplyDeal" ||
              that.applyRemovePromo?.type === "autoApplyPromo"
            ) {
              that.promoControl.patchValue("");
              that.dealControl.patchValue("");
              that.selectedDealText = "";
              that.loggedELK(
                that.applyRemovePromo.success,
                "APPLY_PROMO_DASHBOARD",
                false
              );
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                that.applyRemovePromo.success,
                "success-snackbar",
                that.applyRemovePromo?.type === "autoApplyDeal" || that.applyRemovePromo?.type === "autoApplyPromo" ? {
                  messageClass: "auto-apply-toast"
                } : null
              );
            } else {
              that.loggedELK(
                that.applyRemovePromo.error,
                "APPLY_PROMO_DASHBOARD",
                true
              );
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                that.applyRemovePromo.error,
                "danger-snackbar"
              );
            }
          }
          that.applyRemovePromo = null;
          that.ngxService.stop();
          that.applyPromoState(data.message.data);
          that.verifyMinimumOrderPriceMet(data.message.data);
        } else if (data.channel == constants.IVR_PAYMENTS) {
          if (that.general.isMuted) {
            that.general.pauseResumeRecording(false);
          }
          if (data.message.data.activeCallFalse === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the IVR process due to Activecall not found",
              "danger-snackbar"
            );
          }
        } else if (data.channel == constants.PAYMENT_CREDIT_CARD) {
          that.triggerPaymentAttempts(that);
          that.alertNotification.textLoader$.next("");
          if (that.general.isMuted) {
            that.general.pauseResumeRecording(false);
          }
          if (data.warning === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Warning: Customer has opted to reconnect with the agent.",
              "warning-snackbar"
            );
          } else if (data.isTransactionIdInValid === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the IVR process due to invalid Transaction ID",
              "danger-snackbar"
            );
          } else if (data.isPostalCodeError === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the payment process due to invalid postal code",
              "danger-snackbar"
            );
          } else if (data.isPaymentFailed === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.errorMessage,
              "danger-snackbar"
            );
          } else if (data.isPaymentError === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the IVR process.",
              "danger-snackbar"
            );
          } else if (data.disconnect === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Customer has disconnected the Call.",
              "danger-snackbar"
            );
          } else {
            if (data.message.data.error) {
              that.ngxService.stop();
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                data.message.data.error.message,
                "danger-snackbar"
              );
              that.dialog.closeAll();
              that.submitOrderSocket(true, that.orderForm);
            } else {
              that.ngxsStore.dispatch(
                new ActionSendPayment(data.message.data.data.data)
              );
              console.log(data.message.data.data);
              that.dialog.closeAll();
              that.submitOrderCard(
                data.message.data.data.data.paymentTransactionId,
                data.message.data.data.data.cardName
              );
              // localStorage.removeItem("Rmt");
            }
          }
        } else if (data.channel == constants.PAYMENT_GIFT_CARD) {
          that.triggerPaymentAttempts(that);
          that.alertNotification.textLoader$.next("");
          if (that.general.isMuted) {
            that.general.pauseResumeRecording(false);
          }
          if (data.warning === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Warning: Customer has opted to reconnect with the agent.",
              "warning-snackbar"
            );
          } else if (data.isTransactionIdInValid === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the IVR process due to invalid Transaction ID",
              "danger-snackbar"
            );
          } else if (data.isPaymentFailed === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.errorMessage,
              "danger-snackbar"
            );
          } else if (data.isPaymentError === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Failed to complete the IVR process.",
              "danger-snackbar"
            );
          } else if (data.disconnect === true) {
            that.ngxService.stop();
            that.dialog.closeAll();
            that.submitOrderSocket(false, null);
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "Customer has disconnected the Call.",
              "danger-snackbar"
            );
          } else {
            if (data.message.data.error) {
              that.ngxService.stop();
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                data.message.data.error.message,
                "danger-snackbar"
              );

              that.submitOrderSocket(true, that.orderForm);
            } else if (data.message.data.data.data.remainingAmount > 0) {
              that.ngxService.stop();
              that.submitOrderSocket(false, null);
              // console.log(data.message.data.data);
              that.loggedELK(
                `Your GC Balance is $${data.message.data.data.data.gcBalance}, Remaining Amount is $${data.message.data.data.data.remainingAmount} Please Do Payment VIA CASH or CC`,
                "GIFT_CARD_DASHBOARD",
                false
              );
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                `Your GC Balance is $${data.message.data.data.data.gcBalance}, Remaining Amount is $${data.message.data.data.data.remainingAmount} Please Do Payment VIA CASH or CC`,
                "danger-snackbar"
              );
              if (that.orderForm.tipAmount) {
                that.cryptoService.Encrypting("tA", that.orderForm.tipAmount);
              }
              that.ngxsStore.dispatch(
                new ActionSendPayment(data.message.data.data.data)
              );
              localStorage.setItem(
                "Rmt",
                JSON.stringify(data.message.data.data.data.paymentTransactionId)
              );

              that.submitOrderSocket(true, that.orderForm);
              // that.submitOrderCard(
              //   data.message.data.data.data.paymentTransactionId,
              //   "GIFT"
              // );
            } else {
              that.ngxService.start();
              that.notification.openSnackBarActionV2(
                "end",
                "top",
                `Your GC Balance is $${data.message.data.data.data.gcBalance}`,
                "success-snackbar"
              );
              that.ngxsStore.dispatch(
                new ActionSendPayment(data.message.data.data.data)
              );

              that.submitOrderCard(
                data.message.data.data.data.paymentTransactionId,
                "GIFT"
              );
            }
          }
        } else if (data.channel == constants.GET_DEALS) {
          if (data?.message?.data?.data?.cartId) {
            that.getSpecialDeals(data.message.data);
          } else {
            that.emitTran.getDeal(false);
            that.getDeals(data.message.data);
          }
        } else if (data.channel == constants.UPDATE_ITEM_TO_CART) {
          that.ngxService.stop();
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.getCartDetailSocket();
          } else {
            that.productUUIDEdit = null;
            that.updateItemCartState(data.message.data);
            that.UpdateCartState(data.message.data);
            that.verifyCartAmount(data.message.data);
            that.verifyMinimumOrderPriceMet(data.message.data);
          }
          that.emitTran.updateCartLoader(false);
        } else if (data.channel == constants.CLEAR_CART) {
          that.ngxService.stop();
          that.clearCartState(data.message.data);
          that.UpdateCartState(data.message.data);
        } else if (data.channel == constants.FREQUENT_ORDER) {
          that.getFrequentOrderItemState(data.message.data);
        } else if (data.channel == constants.PAST_ORDER) {
          that.getPastOrderState(data.message.data);
        } else if (data.channel == constants.GET_FEEDBACK) {
          that.getFeedBackState(data.message.data);
        } else if (data.channel == constants.EDIT_CART_BY_PRODUCTUUID) {
          that.ngxService.stop();
          that.emitTran.updateCartLoader(false);
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            const productData = data.message.data.data?.left_half_menu
              ? data.message.data.data
              : decompress(data.message.data.data);
            console.log(productData);
            that.menuservice.EditItem.next({
              product: productData,
              isEdit: true,
              isSingleProductEdit:
                productData?.left_half_menu?.length == 0 &&
                  productData?.right_half_menu?.length == 0
                  ? true
                  : false,
            });
          }

          // that.getFeedBackState(data.message.data);
        } else if (data.channel == constants.GET_HALF_MENU) {
          that.halfLoader = false;
          that.ngxService.stop();
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.isHalfAndHalf = false;
          } else {
            const halfMenu = data.message.data.data?.left_menu
              ? data.message.data.data
              : decompress(data.message.data.data);
            // console.log("halfMenu", halfMenu);
            that.menuservice.halfMenu.next(halfMenu);
            that.isHalfAndHalf = true;
          }
        } else if (data.channel == constants.GET_PAYMENT_URL) {
          that.changeDetector.detectChanges();
          that.ngxService.stop();

          if (data.message.data) {
            //  console.log("Payment Component URL:", data.message.data);
            that.payment_component_url = data.message.data;
            that.emitTran.getIframeUrl(data.message.data);
            // console.log(that.agent);
            // window.parent.postMessage(that.agent, "*");
            // window.parent.localStorage.setItem("agentCurrentUser", that.agent);
          } else {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          }
          // window.parent.addEventListener("message", (event) => {
          //   if (event.data.token) {
          //     console.log(event.data);
          //     window.parent.localStorage.setItem(
          //       "agentCurrentUser",
          //       JSON.stringify(event.data)
          //     );
          //   }
          // });
          //that.payment_component_url = data.message.url;
          // that.payment_component_url =
          //   "http://localhost:4200/transaction/worldpay?HostedPaymentStatus=Complete&TransactionSetupID=44D8292E-771C-48A4-B1D2-D1BEFCF7762A&TransactionID=211485291&ExpressResponseCode=0&ExpressResponseMessage=Approved&CVVResponseCode=P&ApprovalNumber=82003A&LastFour=0000&ValidationCode=79122D32B09B4D1E&CardLogo=Visa&ApprovedAmount=12.00&Bin=446203&Entry=Manual&NetTranID=003046319140591&TranDT=2023-02-15%2002:51:54";
        } else if (data.channel === constants.WORLD_PAY_CC_REVERSAL) {
          that.triggerPaymentAttempts(that);
          that.emitTran.getIframeUrl("");
          if (data.message.data) {
            that.ngxService.stop();
            that.loggedELK(
              `${that.errorMessageWorldpay}`,
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );

            that.notification.openSnackBarActionV2(
              "end",
              "top",
              `${that.errorMessageWorldpay} Try Again`,
              "danger-snackbar"
            );
          } else if (
            data.message.data.message &&
            data.message.data.message === "Failed"
          ) {
            that.ngxService.stop();
            that.loggedELK(
              "RV Failed Billing Address/Postal Code Mismatch Try Again".concat(
                JSON.stringify(data.message.data)
              ),
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              "RV Failed Billing Address/Postal Code Mismatch Try Again",
              "danger-snackbar"
            );
          } else {
            that.ngxService.stop();
            that.loggedELK(
              JSON.stringify(data.message.data),
              "WORLD_PAY_CC_REVERSAL_DASHBOARD_AI",
              true
            );
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          }
        } else if (data.channel == constants.APPLY_PRETIP) {
          that.changeDetector.detectChanges();
          if (data.message.data) {
            // that.ngxService.stop();
            //load iframe

            that.UpdateCartState(data.message.data, true);

            that.storeState.subscribe((res: any) => {
              if (res) {
                that.storeData = res;
                const isPaymentSupportInternal: boolean =
                  that.general.checkInternalPaymentAccept(that.storeData);
                console.log(isPaymentSupportInternal);
                if (isPaymentSupportInternal) {
                  that.getPaymentUrlSocket(null);
                } else if (that.billingAddressZip) {
                  that.ngxService.stop();
                  // that.getPaymentUrlSocket(that.billingAddress);
                } else {
                  that.changeDetector.detectChanges();
                  that.ngxService.stop();
                  console.log("Not Internal");
                }
              }
            });

            // that.ngxService.start();
          } else {
            that.ngxService.stop();
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          }
        } else if (data.channel == constants.PAYMENT_ATTEMPTS) {
          if (data.error) {
            that.ngxService.stop();
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.error,
              "danger-snackbar"
            );
          } else {
            const payload = {
              credit_card_attempts: data.credit_card_attempts,
              gift_card_attempts: data.gift_card_attempts,
            };
            that.general.setPaymentAttemps(payload);
          }
        } else if (data.channel == constants.LOYALTY_ENROLL) {
          that.ngxService.stop();
          if (data?.message?.data?.message) {
            const isSucess = data?.message?.data.success;
            if (!!isSucess) {
              setTimeout(() => {
                window.location.assign("/dashboard");
              }, 3000);
            }

            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.message,
              !!isSucess ? "success-snackbar" : "danger-snackbar"
            );
          }
        } else if (data.channel == constants.FETCH_NOTIFICATION) {
          that.ngxService.stop();
          if (data.message.data.error) {
            that.notificationFlow.isNotificationAPIFailed = true;
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
            that.isHalfAndHalf = false;
            if (that.notificationFlow?.customerPayload && !that.isCartExist && !that.notificationFlow?.isCartModalOpened) {
              that.getCustomerState(that.notificationFlow.customerPayload);
            }
          } else {
          if (data?.message?.data?.message) {
            that.processNotification(data.message.data.data)
          }
        }
        }
      }
    });
    this.socket.on("open", function (connect) {
      console.log("open connect", connect);
    });
    this.socket.on("event", function (data) {
      console.log("event connect", data);
    });
    this.socket.on("disconnect", function () {
      console.log("socket disconnect");
      that.transferCall(AppConstants.AGENT_BACKEND_SOCKET_FAILED);
      that.loggedELK(
        "Agent Backend socket connection is disconnected",
        "SOCKET_DISCONNECT_DASHBOARD",
        true
      );
      that.alertNotification.set({
        text: "Agent Backend socket connection is disconnected",
        value: AppConstants.NOT_CONNECTED,
        error: true,
      });
      window.location.reload();
    });
    this.GetSelectedDealFromPopup();
    this.GetCurrentTipValue();
  }
  GetSelectedDealFromPopup() {
    this.datashare.getSelectedDealFromPopup().subscribe((data) => {
      this.selectedDealFromPopup = data.deal;
      this.selectedDealText =
        this.selectedDealFromPopup.altDescription != "" &&
          this.selectedDealFromPopup.altDescription != null
          ? this.selectedDealFromPopup.altDescription +
          " - $" +
          this.selectedDealFromPopup.value
          : this.selectedDealFromPopup.description +
          " - $" +
          this.selectedDealFromPopup.value;
      this.dealControl.setValue(data.deal.promoCode);
      if (data.action == "apply-deal") {
        //Check if apply deals button is enabled or disabled, it will determine that selected deal should be applied or not.
        let shouldApplyDeal = this.checkDeals();
        if (!shouldApplyDeal) {
          if (data.isAutoApplied) {
            this.applyRemovePromo = {
              type: "autoApplyDeal",
              success:
              `We really appreciate you calling today and making ${this.clientFullName} your pizza of choice! As a small thank you, I have applied a discount to your entire order!
              The deal applied is "${data.deal.description}".`,
              error: "Deal/Promo is not auto applied",
            };
          }
          this.applyPromo(false);
        }
      }
    });
  }
  showMessage(isPromo) {
    return "NO";
  }

  ngOnDestroy(): void {
    this.promoSubs.unsubscribe();
    this.dealsSubs.unsubscribe();
    this.logunsub.unsubscribe();
    this.socket.disconnect();
  }
  client_id: number;

  loggedELK(data: string, name: string, isError: boolean) {
    const payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: isError
        ? constants.LOGGED_ERROR_DASHBOARD
        : constants.LOGGED_INFO_DASHBOARD,
      message: {
        data: data, //Data or Error in String
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
      },
      name: name, //Message Name
    };
    this.socket.send(payload);
  }

  saveOrder(payload: any) {
    // console.log(payload);
    // let callDuration: any = "";
    // this.emitTran.durationTime$.subscribe((time: any) => {
    //   if (time) {
    //     callDuration = time;
    //   }
    // });
    // let agentString = localStorage.getItem("agentCurrentUser");
    // let agent = JSON.parse(agentString);
    // // console.log(agent, this.agent.user.email);
    // let body: SubmitOrderPayload = {
    //   order_id: payload.data.orderId || "",
    //   correlationid: this.uuid || "",
    //   customer_address:
    //     payload.data.order.cart.customer.deliveryAddress.streetLine4 || "",
    //   customer_name:
    //     payload.data.order.cart.customer.firstName +
    //       " " +
    //       payload.data.order.cart.customer.lastName || "",
    //   customer_phone: payload.data?.order?.cart?.customer?.phone || "",
    //   restaurant_phone: this.restaurantphone || "",
    //   restaurant_id: this.restaurantId || 0,
    //   order_items_count: payload?.data?.order?.cart?.cartMenuItems?.length || 0,
    //   call_duration: callDuration || "",
    //   agent_email: this.agent?.user?.email || "",
    //   external_store_id: this.restaurant_details?.["storeId"] || "",
    //   total_price:
    //     payload?.data?.order?.cart?.cartLedger?.expectedLedger?.lineItemPrice ||
    //     "",
    //   client_id: this.client_id || 0,
    //   client_name: this.restaurantName || "",
    //   order_type: payload?.data?.order?.cart?.orderDestination || "",
    //   payment_mode: "",
    //   order_metadata: "",
    // };

    // body.payment_mode = payload?.data?.order?.payments
    //   ? JSON.stringify(payload?.data?.order?.payments || [])
    //   : payload?.data?.order?.payment?.paymentType;

    // body.order_metadata = JSON.stringify(payload?.data || {});
    const body = this.modifyPayload(payload);
    // this.loggedELK(JSON.stringify(body), "ORDER_OBJECT", false); TODO: revert it after demo

    this.voixHttp.post("v1/submit-order", body).subscribe(
      (res: any) => {
        this.loggedELK(JSON.stringify(res), "SAVE_ORDER_DB", false);
      },
      (error) => {
        this.loggedELK(error, "SAVE_ORDER_DB_ERROR", true);
        const errorHandle = JSON.stringify(error);
        this.notification.show("danger", "notifications", errorHandle);
      }
    );
  }

  saveOrderToCrm(payload: any) {
    // console.log(payload);
    if (!!this.storeDetailV2.store.isCustomerServiceProvidedByEzra) {
      const body = this.modifyPayload(payload);
      this.loggedELK(JSON.stringify(body), "ORDER_OBJECT_CRM_DB", false);
      const socket_payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.SAVE_ORDER_DB_CRM,
        message: {
          storeId: this.restaurant_details["storeId"],
          ClientCode: this.restaurantName,
          orderBody: body,
        },
      };

      this.socket.send(socket_payload);
    }
  }
  modifyPayload(payload: any) {
    // console.log(payload);
    let callDuration: any = "";
    this.emitTran.durationTime$.subscribe((time: any) => {
      if (time) {
        callDuration = time;
      }
    });

    let body: SubmitOrderPayload = {
      order_id: payload.data.orderId || "",
      correlationid: this.uuid || "",
      customer_address:
        payload.data.order.cart.customer.deliveryAddress.streetLine4 || "",
      customer_name:
        payload.data.order.cart.customer.firstName +
        " " +
        payload.data.order.cart.customer.lastName || "",
      customer_phone: payload.data?.order?.cart?.customer?.phone || "",
      restaurant_phone: this.restaurantphone || "",
      restaurant_id: this.restaurantId || 0,
      order_items_count: payload?.data?.order?.cart?.cartMenuItems?.length || 0,
      call_duration: callDuration || "",
      agent_email: this.agent?.user?.email || "",
      external_store_id: this.restaurant_details?.["storeId"] || "",
      total_price:
        payload?.data?.order?.cart?.cartLedger?.expectedLedger?.lineItemPrice ||
        "",
      client_id: this.client_id || 0,
      client_name: this.restaurantName || "",
      order_type: payload?.data?.order?.cart?.orderDestination || "",
      payment_mode: "",
      order_metadata: "",
    };

    body.payment_mode = payload?.data?.order?.payments
      ? JSON.stringify(payload?.data?.order?.payments || [])
      : payload?.data?.order?.payment?.paymentType;

    body.order_metadata = JSON.stringify(payload?.data || {});
    return body;
  }
  getElapsedTime(): TimeSpan {
    let totalSeconds = Math.floor(
      (new Date().getTime() - this.currentTime.getTime()) / 1000
    );

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }
  //V2
  cartExist() { }
  av: boolean = true;

  halfMenuSocket(res) {
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_HALF_MENU,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        productSubType: res.productSubType,
        customerPhone: this.customerphone,
        customerId: this.customerId,
        body: {
          itemName: res.itemName,
          size: res.size,
          productSubType: res.productSubType.replace(/\s/g, ""),
          externalId1: res.externalId1,
          externalId2: res.externalId2,
          externalId3: res.externalId3,
          externalId4: res.externalId4 || "",
          productSubCategory: res.productSubCategory,
          match: res.match,
          identifiers: res.identifiers,
        },
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }
  checkavailable() {
    // console.log(this.collectionsModifierscollectionsmodifiers);
    if (this.collectionsModifierscollections == undefined) {
      this.collectionsModifierscollections = [];
    }
    if (this.collectionsModifierscollections.length > 0) {
      if (this.collectionsModifierscollectionsmodifiers.length > 0) {
        for (
          let index = 0;
          index < this.collectionsModifierscollectionsmodifiers.length;
          index++
        ) {
          const element: AddOn =
            this.collectionsModifierscollectionsmodifiers[index];
          if (this.availableQuantities.length > 0) {
            this.av =
              this.availableQuantities.filter(
                (data1) => data1.alterationCategory == element.displayName
              ).length >= element.min
                ? false
                : true;
          } else if (!this.availableQuantities?.length && !element?.min) {
            this.av = false;
          } else {
            this.av = true;
          }

          if (this.av) {
            break;
          }
        }
      } else {
        // console.log(this.selectedStyle);
        if (this.selectedStyle) {
          this.av = false;
        } else {
          this.av = true;
        }
      }
    } else {
      this.av = true;
    }
    // console.log(this.av);
    return this.av;
  }
  av1: boolean = true;
  showData() {
    let form1 = this.checkavailable();
    let form2 = this.checkavailable1();
    //  console.log("form", form1, form2);
    // console.log("productSelect", this.productSelected, this.productSelected2);
    console.log("...alldata", {
      identifiers1: this.availableQuantities,
      identifiers2: this.availableQuantities2,
      collectionsModifierscollections: this.collectionsModifierscollections,
      collectionsModifierscollections2: this.collectionsModifierscollections2,
      collectionsModifierscollectionsmodifiers2:
        this.collectionsModifierscollectionsmodifiers2,
      collectionsModifierscollectionsmodifiers:
        this.collectionsModifierscollectionsmodifiers,
      formValid1: this.av,
      formValid2: this.av1,
    });
    // console.log("\n selectedCollections", this.selectedCollections);
    // console.log("\n selectedCollections2", this.selectedCollections2);
    // console.log("\n cartItem", this.cartItem);
    // console.log("\n cartItems", this.cartItems);
    // console.log("\n collectionCart", this.collectionCart);
    // console.log("\n collectionCart2", this.collectionCart2);
  }
  checkavailable1() {
    // if (this.availableQuantities2) {
    //   this.av1 = false;
    // }
    if (this.collectionsModifierscollections2 == undefined) {
      this.collectionsModifierscollections2 = [];
    }
    if (this.collectionsModifierscollections2.length > 0) {
      if (this.collectionsModifierscollectionsmodifiers2.length > 0) {
        for (
          let index = 0;
          index < this.collectionsModifierscollectionsmodifiers2.length;
          index++
        ) {
          const element = this.collectionsModifierscollectionsmodifiers2[index];
          if (this.availableQuantities2.length > 0) {
            this.av1 =
              this.availableQuantities2.filter(
                (data1) => data1.alterationCategory == element.displayName
              ).length >= element.min
                ? false
                : true;
          } else if (!this.availableQuantities2?.length && !element?.min) {
            this.av1 = false;
          } else {
            this.av1 = true;
          }

          if (this.av1) {
            break;
          }
        }
      } else {
        if (this.selectedStyle2) {
          this.av1 = false;
        } else {
          this.av1 = true;
        }
      }
    } else {
      this.av1 = true;
    }
    // console.log(this.av1);
    return this.av1;
  }
  formsValidation() {
    let form1 = this.checkavailable();
    let form2 = this.checkavailable1();
    // console.log("form NotValid", form1, form2);
    // console.log("productSelect", this.productSelected, this.productSelected2);
    if (this.productSelected) {
      if (this.productSelected2) {
        if ((form1 && form2) || form1 || form2) {
          return true;
        } else {
          return false;
        }
      } else {
        return form1 ? true : false;
      }
    } else {
      if (this.productSelected2) {
        return form2 ? true : false;
      } else {
        return true;
      }
    }
  }
  getRestaurantDetailsV2(restaurantphone) {
    this.ngxService.start();
    var getParam =
      '{user_restaurant_details(phoneNumber: "' +
      restaurantphone +
      '") { restaurant_id, restaurants { address_1, delivery_fee, delivery_radius,external_location_id, client_id, client {name,logo_url}, mode_of_operation,landmark,nick_name, name, configurations(component_name: "dashboard", component_key: "address_swne") { component_value } } }}';

    // console.log(getParam);
    // this.emitTran.setRestaurantName("PJI");
    // this.restaurantName = "PJI";
    // this.restaurant_details["storeId"] = "56";
    // this.getPhoneNumber();
    // this.getStoreSocket();

    // this.getCustomerSocket();
    // this.getMenuSocket();
    // console.log(getParam);
    this.voixHttp
      .get("v1/graphql-gateway/", { params: { query_object: getParam } })
      .subscribe((res: any) => {
        // console.log(res.data);
        if (res.data.user_restaurant_details == null) {
          this.alertNotification.set({
            text: "Restaurant Not Found Please Change Phone Number",
            value: AppConstants.NOT_CONNECTED,
            error: true,
          });
        }
        if (res.data.user_restaurant_details) {
          this.setOrderObjectCache(this.uuid);
          this.restaurantId = res.data.user_restaurant_details.restaurant_id;
         
            this.voixHttp.get(`v1/restaurant-configurations/${this.restaurantId}`, {params: {
              restaurant_id: this.restaurantId,
            }}).subscribe({
              next: (response) => {
                if (this.isDriveThruUrl) {
                  const kvm_url = response.data.find(val => val.component_key === 'kvm_url')?.component_value;
                  this.drive_thru_url = this._sanitizer.bypassSecurityTrustResourceUrl(kvm_url || "https://github.com");
                }
                const loyaltyEnrollmentIVREnabled: string = response.data.find(val => val.component_key === 'loyalty_enrollment_ivr_enabled')?.component_value;
                this.loyalty_enrollment_ivr_enabled = loyaltyEnrollmentIVREnabled === '1' || loyaltyEnrollmentIVREnabled?.toLowerCase() === 'true';
                this.ngxService.stop();
              },
              error: () => this.ngxService.stop()
            });
          if (this.cart?.orderDestination && this.restaurantId) {
            this.getMaximumCashOrderAmount(this.cart?.orderDestination);
          }
          // this.restaurantName =
          //   res.data.user_restaurant_details.restaurants.name;
          // this.emitTran.setRestaurantName(this.restaurantName);

          if (
            res.data.user_restaurant_details.restaurants.client_id &&
            res.data.user_restaurant_details.restaurants.client &&
            res.data.user_restaurant_details.restaurants.client.length > 0
          ) {
            this.checkUserHasClientAccess(
              res.data.user_restaurant_details.restaurants.client_id
            );
            this.client_id =
              res.data.user_restaurant_details.restaurants.client_id;
            // console.log(data);

            this.restaurantName =
              res.data.user_restaurant_details.restaurants.client[0].name || this.isDriveThruUrl && 'PJI';
            const [
              _,
              __,
              CustomerPhoneNumber,
              CorrelationId,
              StorePhoneNumber,
            ]: string[] = window.location.pathname.split("/");
            if (this.restaurantName) {
              this.auth.userDetail().subscribe((response) => {
                const currentUser = response.user;
                const traits = {
                  CustomerPhoneNumber,
                  IPAddress: "",
                  UserRole: currentUser?.roles?.[0]?.role_details?.name,
                  UserEmail: currentUser?.email,
                  CorrelationId,
                  StoreId: res?.data?.user_restaurant_details?.restaurants?.external_location_id,
                  ClientCode: this.restaurantName,
                  StorePhoneNumber,
                };
                this.general.setFlagsmithTraits(traits).then(() => {
                  if (this.general.isPaymentAttempsAllowed) {
                    this.triggerPaymentAttempts(this);
                  }
                  if (this.general.isStoreNotificationEnabled) {
                    this.navbarService.setShowNotificationIcon(true);
                    this.getNotifications(res.data.user_restaurant_details?.restaurants?.external_location_id, this.restaurantName);
                  } else {
                    this.notificationFlow.notificationCIP = false;
                    this.navbarService.setShowNotificationIcon(false);
                  }
                });
              });
            }

            this.emitTran.setRestaurantName({
              name: this.restaurantName,
              logo_url:
                res.data.user_restaurant_details.restaurants.client[0].logo_url,
              landmark: res.data.user_restaurant_details.restaurants.landmark,
              nick_name: res.data.user_restaurant_details.restaurants.nick_name,
            });

            this.restaurant_details["website_url"] = "";
            this.restaurant_details["storeId"] =
              res.data.user_restaurant_details.restaurants.external_location_id;

            // this.emitTran.setStoreDetailsV2(this.restaurant_details)
            this.restaurant_details["address"] =
              res.data.user_restaurant_details.restaurants.address_1;
            this.restaurant_details["delivery_fee"] =
              res.data.user_restaurant_details.restaurants.delivery_fee + "$";
            this.restaurant_details["delivery_radius"] =
              res.data.user_restaurant_details.restaurants.delivery_radius;
            // console.log(this.restaurant_details);
            // if (
            //   res.data.user_restaurant_details.restaurants &&
            //   res.data.user_restaurant_details.restaurants
            //     .configurations
            // ) {
            //   var configurations =
            //     res.data.user_restaurant_details.restaurants
            //       .configurations;
            //   if (configurations[0].component_value) {
            //     var boundValue =
            //       configurations[0].component_value.split(",");
            //     var addressBound = new google.maps.LatLngBounds(
            //       new google.maps.LatLng(boundValue[0], boundValue[1]),
            //       new google.maps.LatLng(boundValue[2], boundValue[3])
            //     );
            //     that.googlePlacesOptions.bounds = addressBound;
            //     // that.placesRef.reset();
            //   }
            // }
            this.modeOfOperation =
              res.data.user_restaurant_details.restaurants.mode_of_operation;
            const ingressChannels =
              res.data.user_restaurant_details.restaurants.ingress_channels;
            // console.log(ingressChannels);
            // if (!_.isEmpty(ingressChannels)) {
            //   const ingressChannelObj = _.findWhere(ingressChannels, {
            //     channel_name: "phone",
            //   });
            //   if (!_.isEmpty(ingressChannelObj)) {
            //     this.modeOfOperation = ingressChannelObj.mode_of_operation;
            //   } else {
            //     this.notification.show(
            //       "danger",
            //       "notifications",
            //       "Ingress channel not found",
            //       "center"
            //     );
            //   }
            // } else {
            //   this.notification.show(
            //     "danger",
            //     "notifications",
            //     "ingress channels not available",
            //     "center"
            //   );
            // }

            this.isPermanentTakeout = this.modeOfOperation === "AGENT_TRAINING";
            if (this.isPermanentTakeout) {
              this.orService.initialLoadEvent(this.uuid, this.socket);
            }
            this.getPhoneNumber();
            this.getDraftOrderSocket();
          } else {
            this.loggedELK(
              `CLIENT NOT FOUND DB ISSUE CONTACT DEV TEAM`,
              "CLIENT_DB_ERROR",
              true
            );
            this.notification.openSnackBarV2(
              "end",
              "top",
              "CLIENT NOT FOUND DB ISSUE CONTACT DEV TEAM",
              "warning-snackbar"
            );
          }

          // if (this.getDraftResponse) {
          //   this.getStoreSocket();
          //   this.getFeedBack();

          //   this.getCustomerSocket();
          //   this.getMenuSocket();

          //   this.setTakeOverFlag(false);
          //   this.retriveCustomResponseObject();
          // }

          //Call Cart Exist API

          //        this.updateSuperMode({checked: true});
        }
      }, () => this.ngxService.stop());
  }

  customerInfoV2() {
    this.customerPastOrder.subscribe((data: any) => {
      if (data.customer.length > 0) {
        this.getFrequentOrderItems();
      }
    });
  }
  getAddress(data: any) {
    if (!this.sessionToken) {
      this.sessionToken = uuidv4();
    }
    const payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.ADDRESS_FROM_GOOGLE,
      message: {
        isBusiness: data.isBusiness,
        latlong: this.latlong,
        ClientCode: this.restaurantName,
        sessiontoken: this.sessionToken,
        storeId: this.restaurant_details["storeId"],
        input: data.input,
      },
    };
    this.socket.send(payload);
  }

  getAddressOutOfBound(data: any) {
    if (!this.sessionToken) {
      this.sessionToken = uuidv4();
    }
    let latlongData = this.latlong.split(",");
    const latlongObj = {
      latitude: latlongData[0],
      longitude: latlongData[1],
    };
    const payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_NEAR_BY_STORES,
      message: {
        latlong: latlongObj,
        ClientCode: this.restaurantName,
        sessiontoken: this.sessionToken,
        storeId: this.restaurant_details["storeId"],
        address: data.customer.deliveryAddress.streetLine4,
      },
    };
    this.socket.send(payload);
  }

  getPlaceDetails(data: any) {
    const payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.ADDRESS_DETAILS_FROM_GOOGLE,
      message: {
        isBusiness: data.isBusiness,
        ClientCode: this.restaurantName,
        sessiontoken: this.sessionToken,
        storeId: this.restaurant_details["storeId"],
        placeId: data.place_id,
        index: data.index,
      },
    };
    this.socket.send(payload);
    this.sessionToken = "";
  }

  private _filter(value: string): PromoItems[] {
    const filterValue = value.toLowerCase();

    const option = this.allpromos.filter(
      (option) =>
        option?.description?.toLowerCase().includes(filterValue) ||
        option?.altDescription?.toLowerCase().includes(filterValue)
    );
    this.promosOptionsV2.next(option);
    return option;
  }

  displayPromo(value?: any) {
    return value
      ? this.allpromos.find((_) => _.promoCode === value).description
      : undefined;
  }
  displayDeals(value?: any) {
    return value
      ? this.alldeals.find((_) => _.promoCode === value).description
      : undefined;
  }
  OpenDealsPopup() {
    var dialogref = this.dialog.open(ViewDealsComponent, {
      disableClose: false,
      width: "800px",
      height: "500px",
      data: {
        deals: this.alldeals,
        selectedDeal: this.selectedDealFromPopup,
        cartPromotions: this.cartDetail.cartPromotions,
      },
    });
  }

  openPopup(deal: any) {
    var dialogref = this.dialog.open(DealPopupComponent, {
      disableClose: false,
      width: "350px",
      height: "300px",
      data: {
        deal
      }
    })

    dialogref.afterClosed().subscribe((result) => {
      this.applyPromo(true, null, undefined, result)
    });

  }

  private _filterDeals(value: string): PromoItems[] {
    const filterValue = value.toLowerCase();
    let option = this.alldeals.filter((option) =>
      option.description.toLowerCase().includes(filterValue)
    );
    this.dealsOptionsV2.next(option);
    return option;
  }
  customer_name: string;
  customer_address: string;
  customer_phone: any;

  showCustomerPopup(
    disableclose: boolean = false,
    verified,
    isError: boolean = false,
    errorMessage = null,
    statusCode = null
  ) {
    if (verified) {
      this.productSelected = false;
      this.productSelected2 = false;
    }
    if (this.isDriveThruUrl) {
      return;
    }
    var dialogref = this.dialog.open(CustomerComponent, {
      disableClose: disableclose,
      width: "800px",
      height: "500px",
      data: {
        isEdit: true,
        isError: isError,
        cart: this.cart,
        restaurant_details: this.restaurant_details,
        restaurantName: this.restaurantName,
        errorMessage,
        statusCode,
      },
    });

    dialogref.componentInstance.emitData.subscribe((store) => {
      this.customerphone = store.customerPhoneNumber;
      this.customer_phone = store.customerPhoneNumber;
      this.restaurantphone = store.phoneNumber;
      this.restaurant_details["storeId"] = store.storeId;
      this.getRestaurantDetailsV2(store.phoneNumber);
      this.getStoreSocket();
    });

    dialogref.afterClosed().subscribe((result) => {
      this.notificationFlow.isCartModalOpened = false;
      if (result) {
        let isClone = localStorage.getItem("cln");
        // console.log(result);
        this.cart = result;

        if (isClone == "false") {
          this.cart.cartId = "";
        }
        // this.cart = {
        //   ...this.cart,
        //   orderReadyDate: this.cryptoService.DecryptWithoutPromise("stats"),
        // };
        // console.log(this.cart);
        this.createCart(this.cart);
        this.productSelected = false;
        this.productSelected2 = false;

        this.customer_name =
          result.customer.firstName + " " + result.customer.lastName;
        this.customer_phone = result.customer.phone;
        // console.log(this.customer_name, this.customer_phone);
      }
    });
  }
  cartId: string;
  cartItems: any[] = [];
  collectionCart: any[] = [];
  collectionCart2: any[] = [];

  modifyCart() {
    this.menuRefresh = true;
    let collection: any;
    let collection2: any;
    this.cartItems = [];

    // this.selectedItem = {
    //   ...this.selectedItem,
    //   productSubCategory: this.selectedCategory.name,
    // };
    // if (this.selectedCategory2) {
    //   this.selectedItem2 = {
    //     ...this.selectedItem2,
    //     productSubCategory: this.selectedCategory2.name,
    //   };
    // }
    const leftNote = this.notes.leftNotes;
    const rightNote = this.notes.rightNotes;
    this.cartItem = {
      notes: leftNote,
      ...this.selectedItem,
      quantity: this.productQuantity,
    };
    this.cartItem2 = {
      notes: rightNote,
      ...this.selectedItem2,
      quantity: this.productQuantity2,
    };
    this.cartItem.modifiers = [];
    this.cartItem2.modifiers = [];
    this.cartItem.modifiers = [...this.availableQuantities];
    this.cartItem2.modifiers = [...this.availableQuantities2];
    // this.selectedCollections.modifiers = [];
    this.cryptoService.Encrypting("eventFilter", this.selectedCollections);
    this.cryptoService.Encrypting("eventFilter2", this.selectedCollections2);

    let selectCollection: any =
      this.cryptoService.DecryptWithoutPromise("eventFilter");
    let selectCollection2: any =
      this.cryptoService.DecryptWithoutPromise("eventFilter2");

    delete selectCollection.modifiers;
    delete selectCollection2.modifiers;
    this.cartItem.modifiers.push(selectCollection);
    this.cartItem2.modifiers.push(selectCollection2);
    // this.collectionCart = this.collectionsModifierscollections[0];
    this.cryptoService.Encrypting(
      "collEvent",
      this.collectionsModifierscollections
    );
    this.cryptoService.Encrypting(
      "collEvent2",
      this.collectionsModifierscollections2
    );

    this.collectionCart = this.cryptoService.DecryptWithoutPromise("collEvent");
    this.collectionCart2 =
      this.cryptoService.DecryptWithoutPromise("collEvent2");

    if (this.collectionCart.length > 0) {
      // console.log(this.collectionCart);
      delete this.collectionCart[0].modifiers;
    }
    if (this.collectionCart2.length > 0) {
      // console.log(this.collectionCart2);
      delete this.collectionCart2[0].modifiers;
    }

    this.cartItem.modifiers = [
      ...this.cartItem.modifiers,
      this.collectionCart[0],
    ];
    this.cartItem2.modifiers = [
      ...this.cartItem2.modifiers,
      this.collectionCart2[0],
    ];

    this.selectedItem2 = this.selectedItem;
    this.selectedCollections2 = this.selectedCollections;

    // console.log(this.cartItem);
    // console.log(this.cartItem2);
    // console.log(this.cartItems);

    if (
      this.availableQuantities.length > 0 ||
      this.collectionsModifierscollections.length > 0
    ) {
      if (
        this.availableQuantities2.length > 0 ||
        this.collectionsModifierscollections2.length > 0
      ) {
        this.cartItems.push(this.cartItem);
        this.cartItems.push(this.cartItem2);

        this.addItemToCartSocket(false);
      } else {
        this.cartItems.push(this.cartItem);
        this.addItemToCartSocket(true);
      }
    } else {
      if (
        this.availableQuantities2.length > 0 ||
        this.collectionsModifierscollections2.length > 0
      ) {
        this.cartItems.push(this.cartItem2);
        this.addItemToCartSocket(true);
      } else {
        this.notification.openSnackBarV2(
          "end",
          "top",
          "PLEASE SELECT ITEMS",
          "warning-snackbar"
        );
      }
    }
  }

  editCartItem() {
    this.menuRefresh = true;
    let collection: any;
    let collection2: any;
    this.cartItems = [];

    this.cartItem = {
      ...this.selectedItem,
      quantity: this.productQuantity,
    };
    this.cartItem2 = {
      ...this.selectedItem2,
      quantity: this.productQuantity2,
    };
    this.cartItem.modifiers = [];
    this.cartItem2.modifiers = [];
    // console.log("\n Available Quantities 1", this.availableQuantities);
    // console.log("\n Available Quantities 2", this.availableQuantities2);
    this.cartItem.modifiers = [...this.availableQuantities];
    this.cartItem2.modifiers = [...this.availableQuantities2];
    // this.selectedCollections.modifiers = [];
    this.cryptoService.Encrypting("eventFilter", this.selectedCollections);
    this.cryptoService.Encrypting("eventFilter2", this.selectedCollections2);

    let selectCollection: any =
      this.cryptoService.DecryptWithoutPromise("eventFilter");
    let selectCollection2: any =
      this.cryptoService.DecryptWithoutPromise("eventFilter2");

    delete selectCollection.modifiers;
    delete selectCollection2.modifiers;
    this.cartItem.modifiers.push(selectCollection);
    this.cartItem2.modifiers.push(selectCollection2);
    // this.collectionCart = this.collectionsModifierscollections[0];
    this.cryptoService.Encrypting(
      "collEvent",
      this.collectionsModifierscollections
    );
    this.cryptoService.Encrypting(
      "collEvent2",
      this.collectionsModifierscollections2
    );

    this.collectionCart = this.cryptoService.DecryptWithoutPromise("collEvent");
    this.collectionCart2 =
      this.cryptoService.DecryptWithoutPromise("collEvent2");

    if (this.collectionCart.length > 0) {
      // console.log(this.collectionCart);
      delete this.collectionCart[0].modifiers;
    }
    if (this.collectionCart2.length > 0) {
      // console.log(this.collectionCart2);
      delete this.collectionCart2[0].modifiers;
    }

    this.cartItem.modifiers = [
      ...this.cartItem.modifiers,
      this.collectionCart[0],
    ];
    this.cartItem2.modifiers = [
      ...this.cartItem2.modifiers,
      this.collectionCart2[0],
    ];

    this.selectedItem2 = this.selectedItem;
    this.selectedCollections2 = this.selectedCollections;

    const cartItem1Modifiers: [] =
      this.cartItem && this.availableQuantities.length > 0
        ? this.cartItem.modifiers
        : [];
    const cartItem2Modifiers: [] =
      this.cartItem2 && this.availableQuantities2.length > 0
        ? this.cartItem2.modifiers
        : [];

    if (cartItem1Modifiers.length > 0) {
      const uniqueModifiers = this.removeDuplicateModifiers(cartItem1Modifiers);
      this.cartItem.modifiers = uniqueModifiers;
    }
    if (cartItem2Modifiers.length > 0) {
      const uniqueModifiers = this.removeDuplicateModifiers(cartItem2Modifiers);
      this.cartItem2.modifiers = uniqueModifiers;
    }
    // console.log(this.cartItem);
    // console.log(this.cartItem2);
    // console.log(this.cartItems);

    if (
      this.availableQuantities.length > 0 ||
      this.collectionsModifierscollections.length > 0
    ) {
      if (
        this.availableQuantities2.length > 0 ||
        this.collectionsModifierscollections2.length > 0
      ) {
        this.cartItems.push({
          ...this.cartItem,
          quantity: this.editHalfQuantity,
        });
        this.cartItems.push(this.cartItem2);

        this.updateCartSocket(false);
      } else {
        this.cartItems.push(this.cartItem);
        // this.cartItems.push(this.cartItem);
        // console.log(this.cartItem);
        this.updateCartSocket(true);
        // this.addItemToCartSocket(true);
      }
    } else {
      if (
        this.availableQuantities2.length > 0 ||
        this.collectionsModifierscollections2.length > 0
      ) {
        this.cartItems.push(this.cartItem2);
        this.addItemToCartSocket(true);
      } else {
        this.notification.openSnackBarV2(
          "end",
          "top",
          "PLEASE SELECT ITEMS",
          "warning-snackbar"
        );
      }
    }
  }

  removeNotes(event: any) {
    if (event.isLeft) {
      this.notes.leftNotes = event.note;
      // console.log(this.productUUIDEdit)
      this.productUUIDEdit = null;
      // console.log(this.productUUIDEdit)
      this.isHalfAndHalf = false;
    } else {
      this.notes.rightNotes = event.note;
    }
  }

  removeDuplicateModifiers(cartModifiers: any) {
    const modifiers: [] = cartModifiers;
    // console.log(modifiers);
    const modifiersWithAlterationQuantity = modifiers.filter(
      (obj: any) => obj.alterationQuantity
    );

    const modifiersWithOutAlterationQuantity = modifiers.filter(
      (obj: any) => !obj.alterationQuantity
    );

    const uniqueModifiersWithSubtypes: any[] = [
      ...new Map(
        modifiersWithOutAlterationQuantity.map((item) => [
          item["productSubType"],
          item,
        ])
      ).values(),
    ];
    // console.log(uniqueModifiersWithSubtypes);
    // const uniqueModifiersWithOutAlterationQuantity =
    //   modifiersWithOutAlterationQuantity.filter(
    //     (obj: any, index, self) =>
    //       index ===
    //       self.findIndex((t: any) => t.externalId1 === obj.externalId1)
    //   );

    const uniqueModifiers = [
      ...modifiersWithAlterationQuantity,
      ...uniqueModifiersWithSubtypes,
    ];
    return uniqueModifiers;
  }
  getValue(value) {
    return value == "0" ? "" : " - $" + value;
  }
  applyPromo(isPromo: boolean, applyRemovePromo = null, submitPromo?: string, manualDiscount?: string) {
    this.emitTran.setAutoApplyCall(false);
    this.ngxService.start();
    if (this.cartDetail && !applyRemovePromo) {
      // let promoCode = this.alldeals.find((data:any)=>this.dealControl.value ==)
      this.cartDetail.cartPromotions = [
        {
          promoCode: submitPromo
            ? submitPromo?.toUpperCase()
            : isPromo
              ? this.promoControl.value?.toUpperCase()
              : this.dealControl.value?.toUpperCase(),
          type: this.cart.orderDestination,
        },
      ];
      if (!this.applyRemovePromo) {
        const promos = isPromo ? this.allpromos : this.alldeals;
        this.applyRemovePromo = {
          type: "applyPromo",
          success: "Promo is applied",
          error: "Promo is not applied",
          offerId: promos?.find(
            (val) =>
              val?.promoCode?.toUpperCase() ===
              this.cartDetail?.cartPromotions?.[0]?.promoCode?.toUpperCase()
          )?.offerId,
          promoCode: this.cartDetail.cartPromotions?.[0]?.promoCode,
        };
      }
    } else if (this.cartDetail && applyRemovePromo) {
      this.applyRemovePromo.index = this.cartDetail?.cartMenuItems?.findIndex(
        (item) => item?.productUUID === applyRemovePromo?.productUUID
      );
      this.cartDetail.cartMenuItems[
        this.applyRemovePromo?.index
      ].cartPromotions = applyRemovePromo.cartPromotions;
      if (applyRemovePromo.cartPromotions?.length) {
        this.cartDetail.cartMenuItems[
          this.applyRemovePromo?.index
        ].cartPromotions[0].type = this.cart.orderDestination;
      }
    }
    this.emitSubmitPromo(manualDiscount);
  }

  emitSubmitPromo(manualDiscount?:any) {
    let cartId = this.general.getCartId();
    if (manualDiscount) {
      let payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.APPLY_PROMO,
        message: {
          ClientCode: this.restaurantName,
          storeId: this.restaurant_details["storeId"],
          cartId: cartId,
          promoBody: {...this.cartDetail,manualDiscount: manualDiscount}
          
        },
      };
      this.socket.send(payload);
      this.emitTran.applypromo(true);
    } else {
      let payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.APPLY_PROMO,
        message: {
          ClientCode: this.restaurantName,
          storeId: this.restaurant_details["storeId"],
          cartId: cartId,
          promoBody: this.cartDetail,

        },
      };
      this.socket.send(payload);
      this.emitTran.applypromo(true);
    }

  }

  UpdateCartState(payload: GlobalApiResponse, pretip = false) {
    this.ngxsStore.dispatch(new ActionUpdateCartItem(payload));
  }

  applyPromoState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionApplyPromo(payload));
    if (payload.statusCode != 200) {
      this.getCartDetailSocket();
    }
  }

  getFrequentOrderItems() {
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.FREQUENT_ORDER,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        customerId: this.customerId,
        customerPhone: this.customerphone,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  getPaymentUrlSocket(billingAddress: billingAddressPayment | null) {
    let billingAddressComp: billingAddressPayment = billingAddress
      ? billingAddress
      : {
        billing_address: "",
        zip_code: undefined,
      };
    if (!billingAddressComp) {
      billingAddressComp["billing_address"] = "";
    }
    const cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_PAYMENT_URL,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        billingAddress: billingAddressComp,
        restaurantPhone: this.restaurantphone,
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }

  getMenuTransform(productUUID) {
    this.ngxService.start();
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.EDIT_CART_BY_PRODUCTUUID,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        productUUID: productUUID,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }
  getpastOrder() {
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.PAST_ORDER,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        customerId: this.customerId,
        customerPhone: this.customerphone,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  getFeedBack() {
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_FEEDBACK,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  getFeedBackState(payload: any) {
    this.ngxsStore.dispatch(new ActionGetFeedback(payload));
  }

  createCartState(payload: GlobalApiResponse) {
    if (!this.isCartExist) {
      this.ngxsStore.dispatch(new ActionCreateCart(payload));
    } else {
      this.cartDetail = payload.data.cart;
    }
    this.customerId = this.cart.customer.id == "" ? "" : this.cart.customer.id;
    this.cart.notes = payload?.data?.cart?.notes;

    if(this.routeName != 'drive-thru'){
    this.getMenuSocket();
    this.emitTran.getPromos(true);
    this.emitTran.getDeal(true);
    this.getPromosSocket(payload.data.cart.orderDestination);
    this.getDealsSocket(payload.data.cart.orderDestination);
    }
    this.promoRefresh = true;
    this.allpromos = [];
    this.alldeals = [];
    this.promoControl.patchValue("");
    this.dealControl.patchValue("");

    if (this.customerId) {
      this.getFrequentOrderItems();
      this.getpastOrder();
    }
    this.ngxsStore.dispatch(new ResetPayment());
    this.isCartExist = false;
  }

  getFrequentOrderItemState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetFrequentOrderItem(payload));
  }

  getPastOrderState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetPastOrder(payload));
  }

  createCart(cart: any) {
    if(this.routeName != 'drive-thru'){
    console.log(cart);
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.CREATE_CART,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartBody: cart,
      },
    };
    console.log("CREATE_CART --->> ", payload);
    this.socket.send(payload);
  }
  }

  getPromosSocket(orderType: string) {
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_PROMOS,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        type: orderType,
        loyaltyObject: this.cartDetail?.customer?.loyaltyStatus?.toLowerCase() === 'yes' ? JSON.stringify({ cartId }) : null,
      },
    };
    if (this.general.isApplicableForSpecial) {
      this.socket.send({ ...payload, message: { ...payload.message, cartId } });
    }
    this.socket.send(payload);
  }

  getDealsSocket(orderType: string) {
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_DEALS,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        type: orderType,
        loyaltyObject: this.cartDetail?.customer?.loyaltyStatus?.toLowerCase() === 'yes' ? JSON.stringify({ cartId }) : null,
      },
    };
    if (this.general.isApplicableForSpecial) {
      this.socket.send({ ...payload, message: { ...payload.message, cartId } });
    }
    this.socket.send(payload);
  }

  checkPromo() {
    let result: boolean = true;
    if (this.liveItems.length > 0) {
      if (this.allpromos.length > 0) {
        result = false;
      }
    } else {
      result = true;
    }

    return result;
  }

  checkPromoTemp() {
    let result: boolean = true;
    if (this.isEdit) {
      result = true; // Disable promo apply button during item editing
    } else {
      result = this.liveItems.length === 0; // Disable promo apply button if liveItems array is empty
    }

    return result;
  }

  checkDeals() {
    let result: boolean = true;
    if (this.isEdit) {
      result = true; // Disable deals button during item editing
    } else {
      result = this.liveItems.length === 0 || this.alldeals.length === 0; // Disable deals button if liveItems array is empty or alldeals array is empty
    }
    return result;
  }

  submitOrderWorldPay(result) {
    this.ngxService.start();
    const cartId = this.general.getCartId();
    const paymentType = result.CardLogo;
    const orderBody = {
      cartId,
      payment: {
        paymentType: paymentType,
        paymentToken: "",
        currency: "USD",
      },
      payload: JSON.stringify(result),
    };
    const payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.SUBMIT_ORDER,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        orderBody: orderBody,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  submitOrderCard(paymentToken, paymentType) {
    // this.pleasewait.close();
    // this.pleasewait = this.dialog.open(LoaderComponent, {
    //   hasBackdrop: true,
    //   disableClose: true,
    // });
    const cartId = this.general.getCartId();
    let orderBody: any;
    let payments: Payment[] = [];
    this.getPaymentSplit.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        if (res.length === 0) {
          orderBody = {
            cartId: cartId,
            paymentToken: paymentToken,
            paymentType: paymentType,
          };
        }
        if (res.length > 0) {
          for (let index = 0; index < res.length; index++) {
            const paymentPayload = res[index];
            payments.push({
              paymentToken: paymentPayload.paymentTransactionId,
              paymentType: paymentPayload.cardName,
              currency: "USD",
            });
          }

          const getCardPaymentsType = [];
          const getOtherPaymentsType = [];
          payments.forEach(payment => (payment.paymentType.includes('GIFT') || payment.paymentType.includes('CASH')) ? getOtherPaymentsType.push(payment) : getCardPaymentsType.push(payment));
          if (getCardPaymentsType?.length > 1) {
            payments = [...getOtherPaymentsType, getCardPaymentsType[getCardPaymentsType.length - 1]];
          }

          orderBody = {
            cartId: cartId,
            payments: payments,
          };
        }
      }
    });
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.SUBMIT_ORDER,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        orderBody: orderBody,
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }
  orderForm: any;
  paymentTypeV2: any;
  submitOrderSocket(isEdit: boolean = false, data: any) {
    let cartId = this.general.getCartId();
    // this.getCartDetailSocket();
    var dialogref = this.dialog.open(SubmitOrderComponent, {
      disableClose: false,
      data: {
        isEdit: isEdit,
        order: data,
        paymentType: this.paymentTypeV2,
        orderDestination: this.cart.orderDestination,
        payment_component_url: this.payment_component_url,
        clientName: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        applyPromo: this.applyPromo.bind(this),
        uuid: this.uuid,
      },
      width: "800px",
      height: "800px",
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result) {
          //@ts-ignore
        this.ngxService.start();
        // this.pleasewait = this.dialog.open(LoaderComponent, {
        //   hasBackdrop: true,
        //   disableClose: true,
        // });
        // console.log(result);
        if (result.paymentProcess) {
          this.alertNotification.textLoader$.next(
            "Please wait while the customer enters the card details."
          );
          const [_, __, ___, correlationID]: string[] =
            window.location.pathname.split("/");
          const cartId = this.general.getCartId();
          this.paymentTypeV2 = result.paymentType;
          let payload = {
            id: this.uuid,
            agent_email: this.agent.user.email,
            channel: constants.IVR_PAYMENTS,
            message: {
              ClientCode: this.restaurantName,
              storeId: this.restaurant_details["storeId"],
              correlationID,
              cartId,
              paymentMethod: result.paymentType === "GIFT" ? "GC" : "CC",
            },
          };
          this.socket.send(payload);
        } else if (result.paymentType === "CASH") {
          this.paymentTypeV2 = result.paymentType;
          this.ngxsStore.dispatch(
            new ActionSendPayment({
              paymentTransactionId: null,
              cardName: "CASH",
            })
          );
          this.submitOrderCard(null, result.paymentType);
        } else if (result.paymentType === "GIFT") {
          this.paymentTypeV2 = result.paymentType;
          this.orderForm = result.gift_card;
          if (this.orderForm.tipAmount) {
          }
          let payload = {
            id: this.uuid,
            agent_email: this.agent.user.email,
            channel: constants.PAYMENT_GIFT_CARD,
            message: {
              ClientCode: this.restaurantName,
              storeId: this.restaurant_details["storeId"],
              paymentBody: this.orderForm,
            },
          };
          // console.log(payload);
          this.socket.send(payload);
        } else if (result.paymentType === "CARD") {
          this.paymentTypeV2 = result.paymentType;
          this.orderForm = result.cardForm;
          let payload = {
            id: this.uuid,
            agent_email: this.agent.user.email,
            channel: constants.PAYMENT_CREDIT_CARD,
            message: {
              ClientCode: this.restaurantName,
              storeId: this.restaurant_details["storeId"],
              paymentBody: this.orderForm,
            },
          };
          // console.log(payload);
          this.socket.send(payload);
        }
      }
    });
  }

  submitOrderV2(payload: GlobalApiResponse) {
    this.storeState.subscribe((res: storeDefaultState) => {
      const storeDetails: StoreDetails = res as unknown as StoreDetails;
      if (storeDetails) {
        this.ngxsStore.dispatch(
          new ActionSubmitOrder(payload, storeDetails.store.address)
        );
        this.dialog.open(OrderDialogComponent, {
          disableClose: true,
          width: "700px",
          height: "500px",
          data: {
            order: payload,
            store: storeDetails.store.address,
            cart: this.cart,
            isLoyaltyEnrollmentIVREnabled: this.loyalty_enrollment_ivr_enabled,
            isCustomer: false,
            onLoyaltyEnrollClick: this.loyaltyEnroll.bind(this)
          },
        });
      }
    });
  }

  loyaltyEnroll() {
    let cartId = this.general.getCartId();
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.LOYALTY_ENROLL,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        mobileNumber: this.customer_phone
      },
    };
    this.socket.send(payload);
  }

  getPromos(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetPromo(payload));
    this.promoRefresh = false;
    this.allpromos = [];
    this.allpromos = payload?.data?.promos || [];
  }

  getSpecialPromos(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetSpecialPromo(payload));
  }

  getDeals(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetDeals(payload));
  }

  getSpecialDeals(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetSpecialDeals(payload));
  }

  addItemToCartSocket(single) {
    // let cartItems: any[] = [];
    // cartItems.push(this.cartItem);
    let cartItem: any;
    if (this.isEdit) {
    }
    if (single) {
      if (this.cartItems.length > 1) {
        this.cartItems.pop();
      }
    }
    let validate: boolean = true;
    for (let index = 0; index < this.cartItems.length; index++) {
      const element = this.cartItems[index];
      if (element.quantity == 0.5 && this.productUUIDEdit) {
        this.cartItems[index].productUUID = this.productUUIDEdit;
      }
      for (let i = 0; i < element.modifiers.length; i++) {
        const modifier = element.modifiers[i];
        if (element.modifiers[i] == undefined) {
          validate = false;
          break;
        }
      }
    }
    if (validate) {
      // if (this.cartItems?.length) {
      //   this.cartItems[0].notes = this.notes.leftNotes;
      // }
      // if (this.cartItems?.length === 2 && !this.isHalfAndHalf) {
      //   this.cartItems[1].notes = this.notes.rightNotes;
      // }
      console.log(this.cartItems);
      this.ngxService.start();
      let cartId = this.general.getCartId();
      let payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.ADD_ITEM_TO_CART,
        message: {
          ClientCode: this.restaurantName,
          storeId: this.restaurant_details["storeId"],
          cartId: cartId,
          cartBody: this.cartItems,
        },
      };
      // console.log(payload);
      this.socket.send(payload);
      this.emitTran.addItemLoader(true);
    } else {
      this.notification.openSnackBarActionV2(
        "end",
        "top",
        "ITEM NOT VALID PLEASE CHECK BOTH FORMS AGAIN",
        "danger-snackbar"
      );
    }
  }

  updateCartSocket(single) {
    let validate: boolean = true;

    if (single) {
      if (this.cartItems.length > 1) {
        this.cartItems.pop();
      }
    }

    for (let index = 0; index < this.cartItems.length; index++) {
      const element = this.cartItems[index];
      if (this.productUUIDEdit) {
        this.cartItems[index].productUUID = this.productUUIDEdit;
      }

      for (let i = 0; i < element.modifiers.length; i++) {
        const modifier = element.modifiers[i];
        if (element.modifiers[i] == undefined) {
          validate = false;
          break;
        }
      }
    }

    if (validate) {
      if (this.cartItems?.length) {
        this.cartItems[0].notes =
          this.cartItems[0].notes || this.notes.leftNotes;
      }
      if (this.cartItems?.length === 2 && !this.isHalfAndHalf) {
        this.cartItems[1].notes =
          this.cartItems[1].notes || this.notes.rightNotes;
      }
      this.ngxService.start();
      let cartId = this.general.getCartId();
      let payload = {
        id: this.uuid,
        agent_email: this.agent.user.email,
        channel: constants.UPDATE_ITEM_TO_CART,
        message: {
          ClientCode: this.restaurantName,
          storeId: this.restaurant_details["storeId"],
          cartId: cartId,
          cartBody: this.cartItems,
        },
      };
      // console.log(payload);
      this.socket.send(payload);
      // this.productUUIDEdit = null;
      this.emitTran.updateCartLoader(true);
    } else {
      this.notification.openSnackBarActionV2(
        "end",
        "top",
        "ITEM NOT VALID PLEASE CHECK BOTH FORMS AGAIN",
        "danger-snackbar"
      );
    }
  }

  updateItemCartState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionUpdateCartItem(payload));
    if (this.isEdit) {
      this.menuservice.removecart.next(true);
    }
    this.emitTran.getPromos(true);
    this.emitTran.getDeal(true);
    this.getPromosSocket(this.cart.orderDestination);
    this.getDealsSocket(this.cart.orderDestination);
    this.promoRefresh = true;
    this.allpromos = [];
    this.alldeals = [];
    this.promoControl.patchValue("");
    this.dealControl.patchValue("");
    this.selectedDealText = "";
    this.notes.leftNotes = "";
    this.notes.rightNotes = "";
    this.isHalfAndHalf = false;
  }

  removeItemFromCartSocket(productUUID) {
    this.ngxService.start();
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.REMOVE_ITEM_FROM_CART,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        productUUID: productUUID,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
    this.emitTran.removeItemLoader(true);
  }

  clearCart() {
    this.ngxService.start();
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.CLEAR_CART,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
    this.emitTran.removeItemLoader(true);
  }

  clearCartState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionClearCart(payload));
    this.cartItems = [];
    this.emitTran.getPromos(true);
    this.emitTran.getDeal(true);
    this.getPromosSocket(this.cart.orderDestination);
    this.getDealsSocket(this.cart.orderDestination);
    this.promoRefresh = true;
    this.allpromos = [];
    this.alldeals = [];
    this.promoControl.patchValue("");
    this.dealControl.patchValue("");
  }
  removeItemFromCartState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionRemoveItem(payload));
    this.emitTran.getPromos(true);
    this.emitTran.getDeal(true);
    this.getPromosSocket(this.cart.orderDestination);
    this.getDealsSocket(this.cart.orderDestination);
    this.promoRefresh = true;
    this.allpromos = [];
    this.alldeals = [];
    this.promoControl.patchValue("");
    this.dealControl.patchValue("");
  }

  addItemToCartState(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionAddItemToCart(payload));
    this.menuRefresh = false;
    this.cartItems = [];
    this.menuservice.removecart.next(true);
    this.emitTran.getPromos(true);
    this.emitTran.getDeal(true);
    this.getPromosSocket(this.cart.orderDestination);
    this.getDealsSocket(this.cart.orderDestination);
    this.promoRefresh = true;
    this.allpromos = [];
    this.alldeals = [];
    this.promoControl.patchValue("");
    this.dealControl.patchValue("");
    this.notes.leftNotes = "";
    this.notes.rightNotes = "";
    this.isHalfAndHalf = false;
  }
  removeAllV2() { }

  getCustomerSocket() {
    if (this.isDriveThruUrl) {
      return;
    }
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_CUSTOMER,
      message: {
        ClientCode: this.restaurantName,
        mobileNumber: this.customer_phone,
        storeId: this.restaurant_details["storeId"],
      },
    };
    console.log("GET_CUSTOMER -->> ", payload);
    this.socket.send(payload);
  }
  getPhoneNumber() {
    let CP: any = window.location.pathname.split("/");
    this.customer_phone = CP[2];
  }

  getCustomerState(payload: GlobalApiResponse) {
    if (payload.data) {
      if (payload.data.customer && payload.data.customer.length > 0) {
        this.customerDetails = payload.data.customer;
        const loyaltyCustomers = this.customerDetails.filter(
          (data: any) => data.loyaltyStatus == "LOYALTY"
        );

        if (loyaltyCustomers.length > 0) {
          const customer = loyaltyCustomers[0];
          if (customer.loyaltyStatus == "LOYALTY") {
            this.emitTran.setLoyaltyStatus(true);
          } else {
            this.emitTran.setLoyaltyStatus(false);
          }
        }
      }
    }

    this.ngxsStore.dispatch(new ActionGetCustomer(payload));
    // console.log(this.isCartExist);
    if (!this.isCartExist) {
      // this.emitTran.IsCartExist();
      if (this.isDriveThruUrl) {
        return;
      }
      var dialogref = this.dialog.open(CustomerComponent, {
        disableClose: true,
        width: "800px",
        height: "500px",
        data: {
          isEdit: false,
          cart: this.cart,
          restaurant_details: this.restaurant_details,
          restaurantName: this.restaurantName,
        },
      });

      dialogref.afterClosed().subscribe((result) => {
        this.notificationFlow.isCartModalOpened = false;
        if (result) {
          let isClone = localStorage.getItem("cln");
          // console.log(result);
          this.cart = result;

          if (isClone == "false") {
            this.cart.cartId = "";
          }
          // this.cart = {
          //   ...this.cart,
          //   orderReadyDate: this.cryptoService.DecryptWithoutPromise("stats"),
          // };
          // console.log(this.cart);
            this.createCart(this.cart);
          

          this.customer_name =
            result.customer.firstName + " " + result.customer.lastName;
          this.customer_phone = result.customer.phone;
          // console.log(this.customer_name, this.customer_phone);
        }
      });
    } else {
      if (this.cartExistPayload) {
        this.createCartState(this.cartExistPayload);
        this.getCartDetailSocket();
      }
      let id = this.cartExistPayload?.data?.cart?.customer?.id;
      if (payload.data && payload.data.data) {
        let customer = payload.data.customer.filter(
          (data: any) => data.id == id
        );
        customer = customer[0];

        if (customer.loyaltyStatus == "LOYALTY") {
          this.emitTran.setLoyaltyStatus(true);
        } else {
          this.emitTran.setLoyaltyStatus(false);
        }
      }
    }
  }
  getStoreSocket() {
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_STORE_DETAILS,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
      },
    };
    this.socket.send(payload);
  }
  getDraftOrderSocket() {
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_DRAFT_ORDER,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        phonenumber: this.customer_phone,
        maxMinutes: 60,
      },
    };
    console.log("GET_DRAFT_ORDER --->> ", payload);
    this.socket.send(payload);
  }

  getMaximumCashOrderAmount(orderType: string) {
    const storeId = this.restaurantId;
    if (!storeId || !orderType) {
      return;
    }
    this.voixHttp
      .post(`v1/restaurant-configurations/${this.restaurantId}/max-amount`, {
        storeId,
        orderType,
      })
      .pipe(
        map(({ max_cash_order_amt }: CashOptionDisabledResponse) => {
          const maxOrderAmt = parseFloat(max_cash_order_amt.trim());
          const maxCashOrderAmt = isNaN(maxOrderAmt)
            ? null
            : maxOrderAmt.toString();
          this.emitTran.setOrderMaxAmount(maxCashOrderAmt);
          return maxCashOrderAmt; // Return the converted string value
        }),
        catchError((error: CashOptionDisabledError) => {
          console.error("Error fetching max_cash_order_amt:", error);
          this.emitTran.setOrderMaxAmount(null); // Reset to an empty string in case of an error
          return of(null);
        })
      )
      .subscribe();
  }

  verifyCartAmount(payload: any) {
    if (this.storeMaxOrderAmt) {
      this.getCartAmount = parseFloat(
        payload.data.cart?.cartLedger?.expectedLedger?.lineItemPrice || 0
      ).toFixed(2);
      const cartAmountAsNumber = parseFloat(this.getCartAmount);
      const maxOrderAmtAsNumber = parseFloat(this.storeMaxOrderAmt);

      const result = cartAmountAsNumber <= maxOrderAmtAsNumber;

      if (!result) {
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          `Order amount is greater than max amount $${maxOrderAmtAsNumber}`,
          "warning-snackbar"
        );
      }
    }
  }

  verifyMinimumOrderPriceMet(payload: any) {
    if (payload?.data?.cart?.minimumOrderPriceMet === false) {
      this.disableSubmitOrderButton = true;
    } else {
      this.disableSubmitOrderButton = false;
    }
    this.emitTran.setSubmitButtonDisabled(this.disableSubmitOrderButton);
  }

  autoApplyCall() {
    this.emitTran.getAutoApplyCall$.subscribe((autoApplyCall) => {
      this.setAutoApplyCall = autoApplyCall;
    });
  }

  async executeAutoApply() {
    if (this.setAutoApplyCall) {
      await this.autoApplyDeals();
      await this.autoApplyPromos();
    }
  }

  async autoApplyDeals() {
    this.cartDetail.cartPromotions = [];
    const isActiveDeal = this.alldeals?.filter(
      (i) => !!i.isActiveItem && i.promoCode
    );
    const indexesDeal = this.cartDetail?.cartPromotions
      ?.filter((i: cartPromotions) => i.offerType === "DEAL")
      ?.map((i: cartPromotions) => i.offerId?.toString());

    if (
      isActiveDeal?.length > 0 &&
      this.general.isSpecialsAutoApplied &&
      this.cartDetail?.cartMenuItems?.length > 0
    ) {
      const autoApplyDeal = isActiveDeal
        .filter((i) => !indexesDeal?.includes(i.offerId?.toLowerCase()))
        .sort((a, b) => Number(b.value) - Number(a.value));

      for (let i = 0; i < autoApplyDeal.length; i++) {
        const element = autoApplyDeal[i];
        const updateDealValue = {
          action: "apply-deal",
          deal: element,
          isAutoApplied: true,
        };
        this.datashare.setSelectedDealFromPopup(updateDealValue);
        setTimeout(async () => {
          const selectDealValue = {
            action: "selected-deal",
            deal: element,
          };
          this.datashare.setSelectedDealFromPopup(selectDealValue);
        }, 50);
      }
    }
  }

  async autoApplyPromos() {
    this.cartDetail.cartPromotions = [];
    if (
      this.general.isSpecialsAutoApplied &&
      this.cartDetail?.cartMenuItems?.length
    ) {
      const isActivePromo = this.allpromos?.filter(
        (i) => !!i.isActiveItem && i.promoCode
      );
      const indexesPromo = this.cartDetail?.cartPromotions
        ?.filter((i: cartPromotions) => i.offerType !== "DEAL")
        ?.map((i: cartPromotions) => i.offerId?.toString());

      if (!(indexesPromo?.length > 0) && isActivePromo?.length > 0) {
        isActivePromo.sort((a, b) => Number(b.value) - Number(a.value));
        // Apply for the first two high-value promos.
        const autoApplyPromo = isActivePromo.slice(0, 2);
        for (let i = 0; i < autoApplyPromo.length; i++) {
          const element = autoApplyPromo[i];
          this.applyRemovePromo = {
            type: "autoApplyPromo",
            success:
              `We really appreciate you calling today and making ${this.clientFullName} your pizza of choice! As a small thank you, I have applied a discount to your entire order!
              The promo applied is "${element.description}".`,
            error: "Deal/Promo is not auto applied",
          };
          await this.applyPromo(true, null, element.promoCode);
        }
      }
    }
  }

  getStoreState(payload: GlobalApiResponse) {
    this.storeMaxOrderAmt = payload.data.store.maxOrderAmt;
    const latlong: string = payload.data.store.latLong || "";
    const splitted = latlong?.split(":");
    this.latlong = splitted.join(",");
    console.log(splitted.join(","));
    this.ngxsStore.dispatch(new ActionGetStore(payload));
    this.getStoreInfoV2();
  }
  storeDetailV2: any;
  getStoreInfoV2() {
    this.storeState.pipe().subscribe((res: any) => {
      // console.log(res);
      if (res) {
        this.storeDetailV2 = res;
        this.emitTran.setStoreDetailsV2(res);
      }
    });
  }

  getUpSellingSocket() {
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_UP_SELLING_ITEMS,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        customerPhone: this.customerphone,
        customerId: this.customerId,
      },
    };
    this.socket.send(payload);
  }

  getMenuSocket() {
    this.ngxService.start();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_MENU,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        customerPhone: this.customerphone,
        customerId: this.customerId,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  fetchMenuItemDataV2(payload: GlobalApiResponse) {
    try {
      this.ngxsStore.dispatch(new ActionGetMenu(payload));

      this.readyToConnectHub();
      if (this.smsTakeOver) {
        this.updateSuperMode({ checked: true });
      }
      this.updateSuperMode({ checked: true });
    } catch (error) {
      let errorHandle = JSON.parse(error);
      this.notification.openSnackBarV2(
        "end",
        "top",
        errorHandle.error,
        "danger-snackbar"
      );
    }
  }

  fetchUpsellingItems(payload: GlobalApiResponse) {
    this.ngxsStore.dispatch(new ActionGetUpsellingItems(payload));
  }

  getRestaurantDetails(restaurantphone) {
    var getParam =
      '{user_restaurant_details(phoneNumber: "' +
      restaurantphone +
      '") { restaurant_id, restaurants { address_1, delivery_fee, delivery_radius, mode_of_operation, name, ingress_channels { channel_name, mode_of_operation }, configurations(component_name: "dashboard", component_key: "address_swne") { component_value } } }}';
    var that = this;
    // console.log(getParam);
    this.voixHttp
      .get("v1/graphql-gateway/", { params: { query_object: getParam } })
      .subscribe((res: any) => {
        // console.log(res.data);
        if (res.data.user_restaurant_details) {
          this.setOrderObjectCache(this.uuid);
          this.restaurantId = res.data.user_restaurant_details.restaurant_id;
          this.restaurantName =
            res.data.user_restaurant_details.restaurants.name || this.isDriveThruUrl && 'PJI';
          this.emitTran.setRestaurantName(this.restaurantName);
          this.restaurant_details["address"] =
            res.data.user_restaurant_details.restaurants.address_1;
          this.restaurant_details["delivery_fee"] =
            res.data.user_restaurant_details.restaurants.delivery_fee + "$";
          this.restaurant_details["delivery_radius"] =
            res.data.user_restaurant_details.restaurants.delivery_radius;
          if (
            res.data.user_restaurant_details.restaurants &&
            res.data.user_restaurant_details.restaurants.configurations
          ) {
            var configurations =
              res.data.user_restaurant_details.restaurants.configurations;
            if (configurations[0].component_value) {
              var boundValue = configurations[0].component_value.split(",");
            }
          }
          this.modeOfOperation =
            res.data.user_restaurant_details.restaurants.mode_of_operation;
          const ingressChannels =
            res.data.user_restaurant_details.restaurants.ingress_channels;
          // console.log(ingressChannels);
          if (!_.isEmpty(ingressChannels)) {
            const ingressChannelObj = _.findWhere(ingressChannels, {
              channel_name: "phone",
            });
            if (!_.isEmpty(ingressChannelObj)) {
              this.modeOfOperation = ingressChannelObj.mode_of_operation;
            } else {
              this.notification.show(
                "danger",
                "notifications",
                "Ingress channel not found",
                "center"
              );
            }
          } else {
            this.notification.show(
              "danger",
              "notifications",
              "ingress channels not available",
              "center"
            );
          }

          this.isPermanentTakeout = this.modeOfOperation === "AGENT_TRAINING";
          if (this.isPermanentTakeout) {
            this.orService.initialLoadEvent(this.uuid, this.socket);
          }
          this.fetchMenuItemData();
          this.setTakeOverFlag(false);
          this.retriveCustomResponseObject();
          //        this.updateSuperMode({checked: true});
        }
      });
  }

  retriveCustomResponseObject() {
    let params = { limit: 100, page: 1, orderby: "popularity", order: "desc" };
    this.general.getCustomResponses(params).subscribe((response) => {
      this.customResponseArray = response["data"];
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  setOrder() {
    this.fetchOrderData();
    let storeOrderData = {
      id: this.uuid,
      channel: constants.ORDER_CHANNEL,
      message: {
        order: this.orderData.order,
        orderInProgress: this.orderData.orderInProgress,
      },
    };
    this.socket.send(storeOrderData);
  }

  setOrderObjectCache(uuid) {
    if (this.setOrderObjectCache) {
      var storeOrderData = {
        id: uuid,
        channel: constants.ORDER_CHANNEL,
        message: {
          order: [],
          orderInProgress: [{}],
        },
      };
      this.socket.send(storeOrderData);
    }
  }
  subscribeChannel(uuid) {
    if (this.uuid || uuid) {
      var subscribeData = {
        id: this.uuid,
        channel: constants.SUBSCRIBE_CHANNEL,
      };
      this.socket.send(subscribeData);
    }
  }

  orderInProgessSlot(message) {
    this.foodTypeOneForm.reset();
    this.foodTypeTwoForm.reset();
    //temporary code for chat window
    var that = this;
    if (this.isJSON(message)) {
      let messageData = JSON.parse(message);
      this.originalSlots = message;
      this.tempStoreCurrentIntent = messageData;
      if (
        this.tempStoreCurrentIntent.currentIntent &&
        this.tempStoreCurrentIntent.currentIntent.name
      ) {
        this.name = this.tempStoreCurrentIntent.currentIntent.name;
      }

      if (this.tempStoreCurrentIntent.sessionAttributes) {
        var sessionAttributes = this.tempStoreCurrentIntent.sessionAttributes;
        if (this.smsTakeOver && !this.initLambdaResponse) {
          this.response_text =
            this.tempStoreCurrentIntent.sessionAttributes.response_content_message;
        }
        if (!_.isEmpty(sessionAttributes.CustomerName)) {
          this.customerName = sessionAttributes.CustomerName;
        }
        if (!_.isEmpty(sessionAttributes.CustomerAddress)) {
          this.customerAddress = sessionAttributes.CustomerAddress;
        }
        if (!_.isEmpty(sessionAttributes.IsDelivery)) {
          this.isDelivery =
            sessionAttributes.IsDelivery.toLowerCase() == "yes" ? true : false;
        }
        if (!_.isEmpty(sessionAttributes.PaymentType)) {
          this.paymentType = sessionAttributes.PaymentType;
        }
        if (!_.isEmpty(sessionAttributes.address_distance)) {
          this.addressDistance = sessionAttributes.address_distance;
        }
        if (!_.isEmpty(sessionAttributes.subConversationId)) {
          this.subConversationId = sessionAttributes.subConversationId;
        }
        if (!_.isEmpty(sessionAttributes.channel)) {
          this.channel = sessionAttributes.channel;
        }
        this.special_instructions = sessionAttributes.special_instructions
          ? sessionAttributes.special_instructions
          : this.special_instructions;

        this.tip = sessionAttributes.tip ? sessionAttributes.tip : this.tip;

        if (sessionAttributes.orderConfirmed) {
        }
      }

      if (
        this.tempStoreCurrentIntent.currentIntent &&
        this.tempStoreCurrentIntent.currentIntent.slots
      ) {
        _.each(this.foodTypeOneObject, function (value, key) {
          var obj = {};
          let keyArray = key.split("Of");

          if (key === "FoodTypeOne") {
            obj[key] = that.tempStoreCurrentIntent.currentIntent.slots[key]
              ? that.ucFirst.transform(
                that.tempStoreCurrentIntent.currentIntent.slots[key]
              )
              : that.tempStoreCurrentIntent.currentIntent.slots[key];
          } else {
            if (
              that.tempStoreCurrentIntent.currentIntent.slots[key] &&
              typeof that.tempStoreCurrentIntent.currentIntent.slots[key] ==
              "string"
            ) {
              if (key == "IsDelivery") {
                obj[key] = that.ucFirst.transform(
                  that.tempStoreCurrentIntent.currentIntent.slots[key]
                );
              } else if (keyArray[0].toLowerCase() === "time") {
                obj[key] = that.orService.getPatchTime(
                  that.tempStoreCurrentIntent.currentIntent.slots[key]
                );
              } else if (keyArray[0].toLowerCase() === "date") {
                obj[key] = that.orService.getPatchDate(
                  that.tempStoreCurrentIntent.currentIntent.slots[key]
                );
              } else {
                obj[key] =
                  that.tempStoreCurrentIntent.currentIntent.slots[
                    key
                  ].toLowerCase();
              }
            } else if (
              that.tempStoreCurrentIntent.currentIntent.slots[key] &&
              typeof that.tempStoreCurrentIntent.currentIntent.slots[key] ==
              "number"
            ) {
              if (key == "countone") {
                obj[key] =
                  that.tempStoreCurrentIntent.currentIntent.slots[
                    key
                  ].toString();
              }
            } else {
              obj[key] =
                that.tempStoreCurrentIntent.currentIntent.slots[key] ==
                  undefined
                  ? that.removeSpecialsChar.transform(
                    that.foodTypeOneObject[key],
                    true
                  )
                  : that.removeSpecialsChar.transform(
                    that.tempStoreCurrentIntent.currentIntent.slots[key],
                    true
                  );
            }
          }
          // console.log("hello", obj);
          that.foodTypeOneForm.patchValue(obj, {
            onlySelf: true,
            emitEvent: true,
          });
        });

        _.each(this.foodTypeTwoObject, function (value, key) {
          var obj = {};
          let keyArray = key.split("Of");
          if (key === "FoodTypeTwo") {
            // tslint:disable-next-line:max-line-length
            obj[key] = that.tempStoreCurrentIntent.currentIntent.slots[key]
              ? that.ucFirst.transform(
                that.tempStoreCurrentIntent.currentIntent.slots[key]
              )
              : that.tempStoreCurrentIntent.currentIntent.slots[key];
          } else {
            if (
              that.tempStoreCurrentIntent.currentIntent.slots[key] &&
              typeof that.tempStoreCurrentIntent.currentIntent.slots[key] ==
              "string"
            ) {
              if (keyArray[0].toLowerCase() === "time") {
                obj[key] = that.orService.getPatchTime(
                  that.tempStoreCurrentIntent.currentIntent.slots[key]
                );
              } else if (keyArray[0].toLowerCase() === "date") {
                obj[key] = that.orService.getPatchDate(
                  that.tempStoreCurrentIntent.currentIntent.slots[key]
                );
              } else {
                obj[key] =
                  that.tempStoreCurrentIntent.currentIntent.slots[
                    key
                  ].toLowerCase();
              }
            } else if (
              that.tempStoreCurrentIntent.currentIntent.slots[key] &&
              typeof that.tempStoreCurrentIntent.currentIntent.slots[key] ==
              "number"
            ) {
              if (key == "counttwo") {
                obj[key] =
                  that.tempStoreCurrentIntent.currentIntent.slots[
                    key
                  ].toString();
              }
            } else {
              obj[key] =
                that.tempStoreCurrentIntent.currentIntent.slots[key] ==
                  undefined
                  ? that.removeSpecialsChar.transform(
                    that.foodTypeTwoObject[key],
                    true
                  )
                  : that.removeSpecialsChar.transform(
                    that.tempStoreCurrentIntent.currentIntent.slots[key],
                    true
                  );
            }
          }
          that.foodTypeTwoForm.patchValue(obj);
        });
      }

      // tslint:disable-next-line:max-line-length
      if (
        !_.isEmpty(this.tempStoreCurrentIntent.currentIntent) &&
        !_.isUndefined(this.tempStoreCurrentIntent.currentIntent) &&
        !_.isUndefined(this.tempStoreCurrentIntent.currentIntent.type) &&
        this.tempStoreCurrentIntent.currentIntent.type == "ConfirmIntent"
      ) {
        let objData = {};
        // tslint:disable-next-line:max-line-length
        if (
          this.tempStoreCurrentIntent.currentIntent.confirmationStatus.toLowerCase() ==
          "confirmed"
        ) {
          objData["OrderAnythingElse"] = "yes";
        }
        if (
          this.tempStoreCurrentIntent.currentIntent.confirmationStatus.toLowerCase() ==
          "denied"
        ) {
          objData["OrderAnythingElse"] = "no";
        }
        this.foodTypeOneForm.patchValue(objData, {
          onlySelf: true,
          emitEvent: true,
        });
      }

      if (this.customerName) {
        this.foodTypeOneForm.patchValue(
          { CustomerName: this.customerName },
          { onlySelf: true, emitEvent: false }
        );
      }
    }
    let last_event_obj: any = localStorage.getItem("last_event_obj");
    if (!_.isUndefined(last_event_obj) && !_.isEmpty(last_event_obj)) {
      last_event_obj =
        typeof last_event_obj == "string"
          ? JSON.parse(last_event_obj)
          : last_event_obj;
      var formOne = this.orService.isKeyBelongsToForm(
        this.foodTypeOneForm.value,
        last_event_obj
      );
      var formTwo = this.orService.isKeyBelongsToForm(
        this.foodTypeTwoForm.value,
        last_event_obj
      );
      if (formOne) {
        this.foodTypeOneForm.patchValue(last_event_obj, {
          onlySelf: true,
          emitEvent: false,
        });
        this.triggerMenuVariationFilter(
          last_event_obj,
          "itemOne",
          "FoodTypeOne",
          "foodTypeOneForm",
          { click: true }
        );
      }
      if (formTwo) {
        this.foodTypeTwoForm.patchValue(last_event_obj, {
          onlySelf: true,
          emitEvent: false,
        });
        this.triggerMenuVariationFilter(
          last_event_obj,
          "itemTwo",
          "FoodTypeTwo",
          "foodTypeTwoForm",
          { click: true }
        );
      }
    }
    this.foodTypeTwoSlotsCount = this.agentResponseService.numOfValuesPreset(
      this.foodTypeTwoForm.value
    );
    if (!this.isPermanentTakeout) {
      this.response_text = "Publish Slots";
    }
  }

  agentPushOrderMessage(message, type) {
    if (type === "lambda") {
      if (this.isJSON(message)) {
        let messageData = JSON.parse(message);
        this.fetchOrderData();
        if (messageData.error) {
          this.notification.show(
            "danger",
            "notifications",
            messageData.error,
            "center"
          );
        } else {
          let originalLexData = JSON.parse(message);
          this.messageData["agent"][this.uuid].push({
            text: messageData.dialogAction.message.content,
            lexResponse: originalLexData,
          });
          this.initLambdaResponse = true;
          this.response_text = messageData.dialogAction.message.content;
          if (originalLexData.disableIsPermantTakeover) {
            this.isPermanentTakeout = this.isPermanentSuperPowerMode;
            this.transferAgentResponse(this.response_text);
            if (this.isPermanentTakeout) {
              this.orService.initialLoadEvent(this.uuid, this.socket);
            }
          } else {
            this.sendAgent({}, "approve_sent");
          }
        }
      }
    } else if (type === "cache") {
      if (this.isJSON(message) && !_.isEmpty(message)) {
        let messageData = message;
        if (messageData.orderInProgress) {
          messageData.orderInProgress =
            typeof messageData.orderInProgress == "string"
              ? messageData.orderInProgress
              : messageData.orderInProgress;
        }
        if (messageData.order) {
          messageData.order =
            typeof messageData.order == "string"
              ? JSON.parse(messageData.order)
              : messageData.order;
        } else {
          messageData.order = [];
        }
        this.orderData = messageData;
      }
    }
  }

  sendAgent(
    response,
    type,
    responseKey = null,
    foodType = null,
    itemCount = "itemOne"
  ) {
    var that = this;
    let agentResponse: any = {
      id: this.uuid,
      channel: constants.AGENT_RESPONSE_CHANNEL,
    };
    if (type == "sent_now") {
      if (!this.takeOverFlag && response.ask === "orderAnyThingElse") {
        this.setTakeOverFlag(false);
      } else if (
        !this.takeOverFlag &&
        response.ask !== "orderAnyThingElse" &&
        (response.takeOverFlag || response.takeOverFlag == undefined)
      ) {
        this.setTakeOverFlag(true);
      }

      if (response.addressType === "customername") {
        this.customerNameFlag = true;
      } else if (response.addressType === "customeraddress") {
        this.customerAddressFlag = true;
      }

      if (
        (response.ask === "inquiryVariation" ||
          response.ask === "inquiryName") &&
        foodType
      ) {
        response.foodType = foodType;
      }

      response = this.agentResponseService.transformAgentSentimate(
        response,
        this.orderData,
        this.categories,
        this.menuList[itemCount],
        this.collectionList[itemCount]
      );
      let getSentimateResponse: any = {
        id: this.uuid,
        channel: constants.SENTIMENT_RESPONSE_CHANNEL,
        message: response,
      };
      that.socket.send(getSentimateResponse);
    }

    if (type == "approve_sent") {
      agentResponse.message = this.getLastAgentResponse();
      this.socket.send(agentResponse);
    }
    this.scrollToBottom();
  }

  transferAgentResponse(response, takeOverFlag = false, repeat = false) {
    var that = this;
    this.ws
      .send(
        JSON.stringify({
          agentText: response,
          action: "speak",
          uuid: this.uuid,
        })
      )
      .subscribe(
        (msg) => {
          console.log("next SEND", msg.data);
        },
        (msg) => {
          console.log("error  SEND", msg);
        },
        () => {
          this.response_text = response;
          if (!repeat) {
            that.messageData["agent"][that.uuid].push({ text: response });
          }
          that.fetchOrderData();
          if (takeOverFlag) {
            this.setTakeOverFlag(false);
          }
          that.autoSuggest.resetAutocomplete();
        }
      );
  }
  playLastAgentResponse() {
    this.setTakeOverFlag(true);
    var response =
      this.messageData["agent"][this.uuid][
      this.messageData["agent"][this.uuid].length - 1
      ];
    this.transferAgentResponse(response.text, true, true);
  }
  fetchOrderData() {
    let agentResponse: any = {
      id: this.uuid,
      channel: constants.FETCH_ORDER_CHANNEL,
      message: {
        restaurantId: this.restaurantId,
        isDelivery: this.isDelivery,
        customerAddress: this.customerAddress,
        restaurantAddress: this.restaurant_details["address"],
        addressDistance: this.addressDistance,
      },
    };
    this.socket.send(agentResponse);
  }
  removeItem(index) {
    if (this.orderData.order) {
      this.orderData.order.splice(index, 1);
      let storeOrderData = {
        id: this.uuid,
        channel: constants.ORDER_CHANNEL,
        message: {
          order: this.orderData.order,
          orderInProgress: [{}],
        },
      };
      this.resetLineItemForms();
      this.socket.send(storeOrderData);
      this.fetchOrderData();
      this.retriveOrderCalculation();
    }
  }
  recordScreen(eventName) {
    var orComponent = this;
    html2canvas(document.body).then(function (canvas) {
      orComponent.orService.saveEventScreenShot(
        orComponent.restaurantId,
        canvas.toDataURL(),
        orComponent.uuid,
        eventName
      );
    });
  }
  publishSlots() {
    this.publishSlotsDisabled = true;
    this.orService.verifySlots(
      this.foodTypeOneForm.value,
      this.collectionList["itemOne"]
    );
    this.orService.verifySlots(
      this.foodTypeTwoForm.value,
      this.collectionList["itemTwo"]
    );

    const lambdaEvent = this.tempStoreCurrentIntent;
    if (_.isEmpty(lambdaEvent.currentIntent)) {
      lambdaEvent.currentIntent = {};
    }
    lambdaEvent.currentIntent.slots = lambdaEvent.currentIntent.slots
      ? lambdaEvent.currentIntent.slots
      : {};
    lambdaEvent.currentIntent.slots = Object.assign(
      lambdaEvent.currentIntent.slots,
      this.foodTypeOneForm.value
    );
    lambdaEvent.currentIntent.slots = Object.assign(
      lambdaEvent.currentIntent.slots,
      this.foodTypeTwoForm.value
    );
    lambdaEvent.currentIntent.name = this.name || "PlaceOrder";
    lambdaEvent.name = lambdaEvent.name || lambdaEvent.currentIntent.name;

    if (
      lambdaEvent.currentIntent &&
      lambdaEvent.currentIntent.slots &&
      lambdaEvent.currentIntent.slots.FoodTypeOne &&
      lambdaEvent.currentIntent.slots.countone
    ) {
      lambdaEvent.currentIntent.slots.countone =
        this.qtyData.indexOf(
          parseFloat(lambdaEvent.currentIntent.slots.countone)
        ) < 0
          ? 1
          : lambdaEvent.currentIntent.slots.countone;
      lambdaEvent.currentIntent.slots.countone = parseFloat(
        lambdaEvent.currentIntent.slots.countone
      );
    } else {
      lambdaEvent.currentIntent.slots.countone = null;
    }

    if (
      lambdaEvent.currentIntent &&
      lambdaEvent.currentIntent.slots &&
      lambdaEvent.currentIntent.slots.FoodTypeTwo &&
      lambdaEvent.currentIntent.slots.counttwo
    ) {
      lambdaEvent.currentIntent.slots.counttwo =
        this.qtyData.indexOf(
          parseFloat(lambdaEvent.currentIntent.slots.counttwo)
        ) < 0
          ? 1
          : lambdaEvent.currentIntent.slots.counttwo;
      lambdaEvent.currentIntent.slots.counttwo = parseFloat(
        lambdaEvent.currentIntent.slots.counttwo
      );
    } else {
      lambdaEvent.currentIntent.slots.counttwo = null;
    }

    if (
      lambdaEvent.currentIntent &&
      lambdaEvent.currentIntent.type === "ConfirmIntent"
    ) {
      if (lambdaEvent.currentIntent.slots.OrderAnythingElse == "yes") {
        lambdaEvent.currentIntent.confirmationStatus = "Confirmed";
      }
      if (lambdaEvent.currentIntent.slots.OrderAnythingElse == "no") {
        lambdaEvent.currentIntent.confirmationStatus = "Denied";
      }
      lambdaEvent.currentIntent.slots.OrderAnythingElse = null;
    }
    if (lambdaEvent.currentIntent && lambdaEvent.currentIntent.type) {
      delete lambdaEvent.currentIntent.type;
    }

    lambdaEvent.currentIntent.confirmationStatus = lambdaEvent.currentIntent
      .confirmationStatus
      ? lambdaEvent.currentIntent.confirmationStatus
      : "None";

    if (!_.isEmpty(lambdaEvent.sessionAttributes)) {
      if (
        !_.isUndefined(this.foodTypeOneForm.value) &&
        !_.isEmpty(this.foodTypeOneForm.value.CustomerName)
      ) {
        lambdaEvent.sessionAttributes.CustomerName =
          this.foodTypeOneForm.value.CustomerName;
      }
      lambdaEvent.sessionAttributes.special_instructions =
        this.special_instructions;
      lambdaEvent.sessionAttributes.tip = this.tip;
      lambdaEvent.sessionAttributes.agent_details = JSON.stringify(
        this.agentDetails
      );
      lambdaEvent.sessionAttributes.parentOrderId = this.parentOrderId;
      lambdaEvent.sessionAttributes.order = JSON.stringify(
        this.orderData.order
      );

      if (
        localStorage.getItem("transationId") &&
        localStorage.getItem("transationId") != ""
      ) {
        lambdaEvent.sessionAttributes.transationId =
          localStorage.getItem("transationId");
      }

      if (this.selectedCoupon) {
        // console.log("selectedCoupon", this.selectedCoupon);
        lambdaEvent.sessionAttributes.coupon = this.selectedCoupon;
      }
    } else {
      this.initSession.special_instructions = this.special_instructions;
      this.initSession.tip = this.tip;
      this.initSession.agent_details = JSON.stringify(this.agentDetails);
      this.initSession.parentOrderId = this.parentOrderId;
    }
    let agentSlotResponse: any = { id: this.uuid };
    console.log("Publishslot", lambdaEvent.sessionAttributes);
    if (this.isPermanentTakeout) {
      agentSlotResponse.channel = constants.GET_LAMBDA_RESPONSE;
      agentSlotResponse.message = {
        lambdaEvent: lambdaEvent,
        RestaurantId: this.restaurantId,
        RestaurantPhone: this.restaurantphone,
        CustomerPhone: this.customerphone,
        initSession: this.initSession,
      };
      console.log("agentSlotResponse", agentSlotResponse);
      this.socket.send(agentSlotResponse);
      this.recordScreen("publish-slot");
    } else {
      if (!this.takeOverFlag) {
        agentSlotResponse.channel = constants.AGENT_SLOTS_CHANNEL;
        agentSlotResponse.message = lambdaEvent;
        this.socket.send(agentSlotResponse);
        var beOriginalEvent =
          !_.isEmpty(this.originalSlots) &&
            typeof this.originalSlots == "string"
            ? JSON.parse(this.originalSlots)
            : {};
        console.log(this.eventData);
        this.socket.send({
          id: this.uuid,
          channel: constants.EVENT_TRACK_CHANNEL,
          message: {
            RestaurantId: this.restaurantId,
            beEvent: beOriginalEvent,
            slotsCorrection: this.eventData,
            subConversationId: this.subConversationId,
            channel: this.channel,
            correctedEvent: lambdaEvent,
          },
        });
        this.recordScreen("publish-slot");
        this.tempStoreCurrentIntent = {};
        this.originalSlots = {};
      }
    }
    this.isSuperPowerModeRequire = true;
    this.initSession = {};
    this.eventData = [];
    this.orService.setLocalstorage("last_event_obj", {});
  }
  getLastAgentResponse() {
    let lastResponse =
      this.messageData["agent"][this.uuid][
      this.messageData["agent"][this.uuid].length - 1
      ];
    return lastResponse != undefined ? lastResponse.lexResponse : "";
  }
  getLastSlots() {
    let lastSlot = this.slotsList[this.slotsList.length - 1];
    let lexSlotObject = {};
    _.each(lastSlot, function (val) {
      lexSlotObject[val.key] = val.value;
    });
    return JSON.stringify(lexSlotObject);
  }

  pushLineItem(editFlag: boolean = false) {
    this.fetchOrderData();
    delete this.lineItemForm2.value.specials;
    delete this.lineItemForm2.value.specials;
    if (!editFlag) {
      if (
        this.agentResponseService.numOfValuesPreset(this.lineItemForm.value) >
        2 &&
        this.lineItemForm.value.name
      ) {
        if (!_.isEmpty(this.lineItemForm.value.quantity)) {
          this.lineItemForm.value.quantity = parseFloat(
            this.lineItemForm.value.quantity
          );
        }
        this.orderData.order.push(this.lineItemForm.value);
      }
      if (
        this.agentResponseService.numOfValuesPreset(this.lineItemForm2.value) >
        2 &&
        this.lineItemForm2.value.name
      ) {
        if (!_.isEmpty(this.lineItemForm2.value.quantity)) {
          this.lineItemForm2.value.quantity = parseFloat(
            this.lineItemForm2.value.quantity
          );
        }
        this.orderData.order.push(this.lineItemForm2.value);
      }
    } else {
      if (!_.isEmpty(this.lineItemForm.value.quantity)) {
        this.lineItemForm.value.quantity = parseFloat(
          this.lineItemForm.value.quantity
        );
      }
      this.orderData.order[this.orderEditIndex] = this.lineItemForm.value;
    }

    let storeOrderData = {
      id: this.uuid,
      channel: constants.ORDER_CHANNEL,
      message: { order: this.orderData.order, orderInProgress: [{}] },
    };
    this.resetLineItemForms();
    this.filterMenuListByCategory("Pizza");
    this.socket.send(storeOrderData);
    this.sendAgent(
      this.agentResponseService.prompts()["orderAnyThingElse"],
      "sent_now",
      null
    );
    this.setTakeOverFlag(false);
  }
  //---------------------------------comman methods--------------------------------

  //conversation window scrollup
  scrollToBottom() {
    try {
      this.customerContainer.nativeElement.scrollTop =
        this.customerContainer.nativeElement.scrollHeight;
      this.agentContainer.nativeElement.scrollTop =
        this.agentContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  setTakeOverFlag(flag = true) {
    this.fetchOrderData();
    let takeOverObject = {
      id: this.uuid,
      channel: constants.AGENT_TAKE_OVER_CHANNEL,
      message: flag,
    };
    this.takeOverFlag = flag;
    this.socket.send(takeOverObject);
  }

  // --------------------------------------------MENU RELATED METHODS-------------------------------------

  //filter menu list based on category
  filterMenuListByCategory(category, item = "itemOne", event = null) {
    var formNumberType = item == "itemOne" ? "one" : "two";
    if (event) {
      this.refreshFormData(formNumberType);
    }
    if (this.restaurantData) {
      this.menuList[item] = _.filter(
        this.restaurantData.MenuItems,
        function (v) {
          return (
            _.isObject(v.MenuCategory) &&
            v.MenuCategory.name.toUpperCase() == category.toUpperCase()
          );
        }
      );
      this.menuList[item] = _.sortBy(this.menuList[item], "name");
    }
  }

  // filter variation list based on menu
  filterVariationListByMenu(
    menu,
    item = "itemOne",
    foodType,
    formName = null,
    event = null
  ) {
    var formNumberType = item == "itemOne" ? "one" : "two";
    //console.log("filterVariationListByMenu event", event);
    if (event) {
      this.refreshFormData(formNumberType, true);
    }
    if (this.restaurantData) {
      this.collectionList[item] = {};
      this.collectionCrustList[item] = [];
      this.collectionToppingList[item] = [];
      var variationObj;
      // console.log('filterVariationListByMenu', menu, typeof menu);
      if (typeof menu == "string") {
        variationObj = _.find(
          this.restaurantData.MenuItems,
          function (menuData) {
            return (
              menuData.name.toLowerCase() === menu.toLocaleLowerCase() &&
              menuData.MenuCategory.name.toLowerCase() ===
              foodType.toLocaleLowerCase()
            );
          }
        );
      } else {
        variationObj = _.find(
          this.restaurantData.MenuItems,
          function (menuData) {
            return (
              menuData.name.toLowerCase() === menu.value.toLocaleLowerCase() &&
              menuData.MenuCategory.name.toLowerCase() ===
              foodType.toLocaleLowerCase()
            );
          }
        );
      }
      // console.log("variation", variationObj);
      if (variationObj) {
        this.variationList = [];
        var allowMultipleData = {};
        var thatParent = this;
        variationObj.MenuVariationCollections.forEach((element) => {
          this.variationList.push(element.MenuVariation);
          this.collectionList[item][element.MenuVariation.name] = this
            .collectionList[item][element.MenuVariation.name]
            ? this.collectionList[item][element.MenuVariation.name]
            : {};
          this.collectionList[item][element.MenuVariation.name].entities =
            element.MenuVariation.entities;
          this.collectionList[item][element.MenuVariation.name].allow_multiple =
            element.MenuVariation.allow_multiple;
          this.collectionList[item][element.MenuVariation.name].orderby =
            get_variation_priority(element.MenuVariation.name);
          this.collectionList[item][element.MenuVariation.name].name =
            element.MenuVariation.name;
          console.log(element.MenuVariation.name);
          this.collectionList[item][element.MenuVariation.name].data = this
            .collectionList[item][element.MenuVariation.name].data
            ? this.collectionList[item][element.MenuVariation.name].data
            : [];

          if (
            !_.isUndefined(element.MenuVariationValueCollection) &&
            !_.isEmpty(element.MenuVariationValueCollection)
          ) {
            element.MenuVariationValueCollection.is_default_collection =
              element.is_default;
            element.MenuVariationValueCollection.priority = element.priority;
          }
          this.collectionList[item][element.MenuVariation.name].data.push(
            element.MenuVariationValueCollection
          );

          if (element.MenuVariation.name == "time") {
            this.collectionList[item][element.MenuVariation.name].data =
              this.orService.getHours(element.MenuVariationValueCollection);
          } else if (element.MenuVariation.name == "date") {
            this.collectionList[item][element.MenuVariation.name].data =
              this.orService.getDates(element.MenuVariationValueCollection);
          }

          let fieldName = null;
          if (formName === "foodTypeOneForm") {
            fieldName = element.MenuVariation.entities[0];
          }
          if (formName === "foodTypeTwoForm") {
            fieldName = element.MenuVariation.entities[1];
          }
          if (formName === "lineItemForm" || formName === "lineItemForm2") {
            fieldName = element.MenuVariation.name;
          }

          if (
            element.is_default &&
            !element.MenuVariation.allow_multiple &&
            fieldName &&
            _.isEmpty(thatParent[formName].value[fieldName])
          ) {
            const variationSelection = {};
            variationSelection[fieldName] =
              element.MenuVariationValueCollection.name.toLocaleLowerCase();
            thatParent[formName].patchValue(variationSelection, {
              emitEvent: false,
              onlySelf: true,
            });
          }

          // multiple button selection login like( toppings)
          if (
            formName &&
            element.MenuVariation.allow_multiple &&
            element.is_default
          ) {
            allowMultipleData[fieldName] = allowMultipleData[fieldName]
              ? allowMultipleData[fieldName]
              : [];
            allowMultipleData[fieldName].push(
              element.MenuVariationValueCollection.name.toLocaleLowerCase()
            );
          }
          console.log(this.collectionList[item][element.MenuVariation.name]);
        });

        var that = this;
        Object.keys(allowMultipleData).forEach(function (key, index) {
          var obj = {};
          obj[key] = allowMultipleData[key];
          if (event) {
            that[formName].patchValue(obj, {
              onlySelf: true,
              emitEvent: false,
            });
          }
        });
        var sortVariationByOrder = _.sortBy(
          this.collectionList[item],
          function (i) {
            return i.orderby;
          }
        );
        if (sortVariationByOrder) {
          this.collectionList[item] = {};
          var orComponent = this;
          sortVariationByOrder.forEach(function (obj) {
            orComponent.collectionList[item][obj.name] = obj;
          });
        }
      }
    }
  }

  //V2 Menus Selection Section

  addToCartV2() {
    // this.cartItem = Object.assign({}, selectedItem);
    // console.log(this.selectedCollections);
    // this.cartItem.modifiers[0].collections = [this.selectedCollections.slice()];
    // this.cartItem.modifiers[0].collections[0].modifiers =
    //   this.selectedCollections.modifiers;
    // console.log(this.availableQuantities);
  }

  fetchMenuItemData() {
    this.voixHttp
      .get("v1/restaurant-menu-categories/" + this.restaurantId.toString())
      .subscribe((res: any) => {
        this.categories = res.data;
      });
    this.voixHttp
      .get("v1/menus/" + this.restaurantId.toString())
      .subscribe((res: any) => {
        this.readyToConnectHub();
        this.restaurantData = res.data;
        this.coupons = res.data && res.data.coupons ? res.data.coupons : [];
        this.entitiesData = this.orService.getEntities(
          this.restaurantData.MenuItems
        );
        this.generateForm();
        this.filterMenuListByCategory("Pizza");
        this.filterMenuListByCategory("Pizza", "itemTwo");
        if (this.smsTakeOver) {
          this.updateSuperMode({ checked: true });
        }
        this.updateSuperMode({ checked: true });
      });
    this.voixHttp
      .get("v1/menus/" + this.restaurantId.toString() + "/specials")
      .subscribe((res: any) => {
        this.specialsData = res.data;
      });
  }

  finishOrder() {
    var finalOrderObj = {
      customerPhone: this.customerphone,
      customerName: this.customerName,
      customerAddress: this.customerAddress,
      isDelivery: this.isDelivery,
      isSpecialInstruction: this.isSpecialInstruction,
      restaurantId: this.restaurantId,
      paymentType: this.paymentType,
      restaurantAddress: this.restaurant_details["address"],
    };
    let finalResponse: any = {
      id: this.uuid,
      channel: constants.BUILD_FINAL_ORDER_CHANNEL,
      message: finalOrderObj,
    };
    this.socket.send(finalResponse);
  }

  retriveOrderCalculation() {
    let buildParam: any = {
      id: this.uuid,
      channel: constants.ORDER_CALCULATION_CHANNEL,
      message: {
        restaurantId: this.restaurantId,
        isDelivery: this.isDelivery,
        customerAddress: this.customerAddress,
        restaurantAddress: this.restaurant_details["address"],
        addressDistance: this.addressDistance,
      },
    };
    this.socket.send(buildParam);
  }

  isJSON(something) {
    if (typeof something != "string") something = JSON.stringify(something);
    try {
      JSON.parse(something);
      return true;
    } catch (e) {
      return false;
    }
  }
  getUppercase(value) {
    return value ? value.toUpperCase() : value;
  }
  getLowercase(value) {
    return value ? value.toLowerCase() : value;
  }
  generateForm(init = false) {
    var foodTypeOneObj = {
      FoodTypeOne: null,
      countone: "1",
      OrderAnythingElse: null,
      IsDelivery: null,
      CustomerName: null,
    };
    var foodTypeTwoObj = {
      FoodTypeTwo: null,
      counttwo: "1",
      NameOfSpecials: null,
      CustomerAddress: null,
      PaymentType: null,
    };
    if (init) {
      this.foodTypeOneForm = this.fb.group(foodTypeOneObj);
      this.foodTypeTwoForm = this.fb.group(foodTypeTwoObj);
    } else {
      var objectOneValue = this.createForm();
      var objectTwoValue = this.createForm("two");
      this.foodTypeOneObject = Object.assign(objectOneValue, foodTypeOneObj);
      this.foodTypeTwoObject = Object.assign(objectTwoValue, foodTypeTwoObj);
      this.showForm = true;
    }
  }
  createForm(type = "one", updateForm = true) {
    var foodTypeForm = {};
    var that = this;
    _.each(this.entitiesData[type], function (value, key) {
      var formType = that.ucFirst.transform(type);
      if (that.entitiesData.allow_multiple[type].indexOf(value) >= 0) {
        if (updateForm) {
          that["foodType" + formType + "Form"].addControl(
            value,
            new FormControl([])
          );
        }
        foodTypeForm[value] = [];
      } else {
        if (updateForm) {
          that["foodType" + formType + "Form"].addControl(
            value,
            new FormControl(null)
          );
        }
        foodTypeForm[value] = null;
      }
    });

    if (updateForm) {
      _.each(this.entitiesData.available_variation, function (value, key) {
        var lineItemFormType =
          type == "one" ? that.lineItemForm : that.lineItemForm2;
        if (value.allow_multiple) {
          lineItemFormType.addControl(value.name, new FormControl([]));
        } else {
          lineItemFormType.addControl(value.name, new FormControl(null));
        }
      });
    }

    return foodTypeForm;
  }

  refreshFormData(type, resetVariation = false) {
    var formType = this.ucFirst.transform(type);
    var foodForm = this.createForm(type, false);
    var categoryNameOf = this.entitiesData.categoryEntities[type];
    if (!resetVariation) {
      this["foodType" + formType + "Form"].patchValue(foodForm, {
        onlySelf: true,
        emitEvent: false,
      });
    } else {
      var foodFormObj = {};
      var collectionOfName = _.values(categoryNameOf);
      Object.keys(foodForm).forEach(function (formControlKey, index) {
        if (collectionOfName.indexOf(formControlKey) >= 0) {
          delete foodForm[formControlKey];
        }
      });
      this["foodType" + formType + "Form"].patchValue(foodForm, {
        onlySelf: true,
        emitEvent: false,
      });
    }

    var lineItemUpdate = !resetVariation ? { name: null } : {};
    _.each(this.entitiesData.available_variation, function (value, key) {
      if (value.allow_multiple) {
        lineItemUpdate[value.name] = [];
      } else {
        lineItemUpdate[value.name] = null;
      }
    });
    if (type == "one") {
      this.lineItemForm.patchValue(lineItemUpdate, {
        onlySelf: true,
        emitEvent: false,
      });
    }
    if (type == "two") {
      this.lineItemForm2.patchValue(lineItemUpdate, {
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  listenSlots() {
    // this.snackBar.open("Please check slots!!! 🍕", "", { duration: 2000 });
    if (!this.isPermanentSuperPowerMode) {
      var audio = new Audio("assets/img/siri_listen.mp3");
      var playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            console.log("Playing....");
          })
          .catch(function (error) {
            console.log("Failed to play...." + error);
          });
      }
    }
  }

  endcall() {
    this.ws
      .send(
        JSON.stringify({ agentText: "", action: "endcall", uuid: this.uuid })
      )
      .subscribe(
        (msg) => {
          console.log("next SEND end call", msg.data);
        },
        (msg) => {
          console.log("error SEND end call", msg);
        },
        () => {
          console.log("send end call complete");
        }
      );
  }
  transferCall(actionText, agentText = "") {
    var that = this;
    this.ws
      .send(
        JSON.stringify({
          agentText: agentText,
          action: actionText,
          uuid: this.uuid,
        })
      )
      .subscribe(
        (msg) => {
          console.log("next SEND transfer call", msg.data);
        },
        (msg) => {
          console.log("error SEND transfer call", msg);
        },
        () => {
          if (actionText == "on-hold") {
            that.setTakeOverFlag(true);
            that.isCallOnHold = true;
          } else if (actionText == "off-hold") {
            that.isCallOnHold = false;
            that.setTakeOverFlag(false);
          } else if (actionText === "transfer-to-me") {
            that.updateSuperMode({ checked: true });
          }
        }
      );
  }
  editResponse() {
    this.editResponseFlag = true;
    this.setTakeOverFlag(true);
  }

  callDataTransiteData() {
    this.voixHttp
      .get(
        "v1/agent/restaurant/" +
        this.restaurantId.toString() +
        "/calls?orderby=id&order=ASC&page=1&limit=50&user_id=" +
        this.uuid,
        {}
      )
      .subscribe(
        (response) => {
          this.callData = response["data"];
          this.openDialog();
        },
        (error) => {
          let errorHandle = JSON.parse(error);
          this.notification.show("danger", "notifications", errorHandle.error);
        }
      );
  }

  openDialog(): void {
    this.dialog.open(OrderDialogComponent, {
      width: "500px",
      data: this.callData,
    });
  }

  preResponsesOpen(): void {
    this.dialogPreResponseRef = this.dialog.open(PreResponsesComponent, {
      width: "1200px",
      data: {
        restaurant: this.restaurant_details,
        responses: this.customResponseArray,
      },
    });
    var that = this;
    this.dialogPreResponseRef.componentInstance.selectedResponse.subscribe(
      (result) => {
        that.transferAgentResponse(result, true);
        that.dialogPreResponseRef.close();
      }
    );
  }

  getCartDetailSocket() {
    let cartId = this.general.getCartId();
    let payload = {
      id: this.uuid,
      agent_email: this.agent.user.email,
      channel: constants.GET_CART,
      message: {
        ClientCode: this.restaurantName,
        storeId: this.restaurant_details["storeId"],
        cartId: cartId,
        cartBody: this.cartItem,
      },
    };
    console.log("GET_CART ---> ", payload);
    this.socket.send(payload);
  }
  getCartDetailState(payload: any) {
    this.ngxsStore.dispatch(new ActionGetCart(payload));
  }
  openOrderDialog(): void {
    // this.getCartDetailSocket();

    this.dialogOrderRef = this.dialog.open(CustomerOrdersComponent, {
      width: "800px",
      height: "600px",
    });
    // var that = this;
    // this.dialogOrderRef.componentInstance.removeOrderItem.subscribe(
    //   (result) => {
    //     this.removeItem(result);
    //   }
    // );
    // this.dialogOrderRef.componentInstance.editOrderItem.subscribe((result) => {
    //   this.editItem(result);
    //   that.dialogOrderRef.close();
    // });
  }
  editItem(index) {
    if (this.orderData && !_.isEmpty(this.orderData.order)) {
      this.orderEditIndex = index;
      this.setTakeOverFlag();
      var orderObject = this.orderData.order[index];
      var that = this;
      _.each(this.lineItemForm.controls, function (value, key) {
        var obj = {};
        if (key == "quantity") {
          obj[key] =
            orderObject[key] == undefined
              ? that.lineItemForm.controls[key].value
              : orderObject[key].toString();
        } else {
          obj[key] =
            orderObject[key] == undefined
              ? that.lineItemForm.controls[key].value
              : orderObject[key];
        }
        that.lineItemForm.patchValue(obj);
      });
    }
  }
  updateTopping($event, form, index) {
    if ($event.source.checked) {
      form.value.toppings.push($event.value);
    } else {
      var index = form.value.toppings.indexOf($event.value);
      form.value.toppings.splice(index, 1);
    }
    this.orderData.orderInProgress[index] = form.value;
    this.setOrder();
  }
  buildResponseObj(
    item,
    foodType,
    menuName,
    buildType = "missing",
    specials = false,
    takeOverFlag = true,
    selectedItem = null,
    specialValueSelected = null
  ) {
    if (specials && menuName) {
      buildType = "inquiry";
    }
    if (item.key !== "toppings") {
      selectedItem = null;
    }
    let response: any = this.agentResponseService.buildSentimateResponse(
      item,
      foodType,
      menuName,
      buildType,
      specials,
      selectedItem,
      specialValueSelected
    );
    response.takeOverFlag = takeOverFlag;
    this.sendAgent(response, "sent_now");
  }
  pricingResponse(form) {
    let buildParam: any = {
      id: this.uuid,
      channel: constants.LINE_ITEM_PRICING_CHANNEL,
      message: { lineItem: form.value, restaurantId: this.restaurantId },
    };
    this.socket.send(buildParam);
  }
  pricingInquery(data) {
    var response = this.agentResponseService.buildPricingResponse(
      data,
      this.entitiesData.available_variation,
      this.collectionList["itemOne"]
    );
    var lastResponsePricing = this.getLastAgentResponse();
    var agentLastResponse =
      lastResponsePricing &&
        lastResponsePricing.dialogAction &&
        lastResponsePricing.dialogAction.message &&
        lastResponsePricing.dialogAction.message.content
        ? lastResponsePricing.dialogAction.message.content
        : "";
    response.tokens["customText"] = agentLastResponse;
    this.sendAgent(response, "sent_now");
    this.setTakeOverFlag(false);
  }
  changeSpecialInstruction($event) {
    if ($event.source.checked) {
      this.isSpecialInstruction = 1;
    } else {
      this.isSpecialInstruction = 0;
    }
    this.eventData.push({ is_special: this.isSpecialInstruction });
    this.orService.saveConversationParams(this.uuid, this.isSpecialInstruction);
  }
  eventTrack(
    formControl,
    title,
    variant = 1,
    isClickOnMenu = false,
    form = null,
    valueChange = false
  ) {
    const obj = {};
    obj[title] = formControl.value;
    if (this.isSuperPowerModeRequire) {
      this.setSuperAgentMode();
      this.orService.setLocalstorage("last_event_obj", obj);
    }

    if (!this.isPermanentTakeout && !this.isPermanentSuperPowerMode) {
      this.eventData.push(obj);
    }

    if (isClickOnMenu) {
      let lambdaEvent = this.tempStoreCurrentIntent;
      if (
        !_.isUndefined(lambdaEvent.sessionAttributes) &&
        !_.isEmpty(lambdaEvent.sessionAttributes)
      ) {
        // tslint:disable-next-line:max-line-length
        set_default_loaded(
          { variant: variant, name: formControl.value },
          lambdaEvent.sessionAttributes,
          isClickOnMenu
        );
      } else {
        set_default_loaded(
          { variant: variant, name: formControl.value },
          this.initSession,
          isClickOnMenu
        );
      }
    }

    if (valueChange) {
      const variationSelectionItem = {};
      variationSelectionItem[title] = formControl.value.toLocaleLowerCase();
      form.patchValue(variationSelectionItem, {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }
  resetLineItemForms() {
    this.lineItemForm.reset();
    this.orderEditIndex = null;
  }
  readyToConnectHub() {
    // this.ws
    //   .send(
    //     JSON.stringify({
    //       agentText: "",
    //       action: "dashboard-ready",
    //       uuid: this.uuid,
    //       customerPhone: this.customerphone,
    //     })
    //   )
    //   .subscribe(
    //     (msg) => {
    //       console.log("next SEND", msg.data);
    //     },
    //     (msg) => {
    //       console.log("error  SEND", msg);
    //     },
    //     () => {
    //       var ss = this.d.getMilliseconds();
    //     }
    //   );
  }
  setScrollForMenu(value, element) {
    setTimeout(() => {
      if (this[element] && this[element].nativeElement.children[value]) {
        this[element].nativeElement.scrollTop =
          this[element].nativeElement.children[value].offsetTop;
      }
    });
  }

  clearFormControl(
    formName,
    controlName,
    formNumberType = null,
    allow_multiple = false,
    menulevel = false
  ) {
    if (
      (controlName == "FoodTypeOne" || controlName == "FoodTypeTwo") &&
      formNumberType
    ) {
      this.refreshFormData(formNumberType);
    } else if (menulevel && formNumberType) {
      let lambdaEvent = this.tempStoreCurrentIntent;
      let variant = formNumberType == "one" ? 1 : 2;
      if (
        !_.isUndefined(lambdaEvent.sessionAttributes) &&
        !_.isEmpty(lambdaEvent.sessionAttributes)
      ) {
        // tslint:disable-next-line:max-line-length
        remove_default_loaded(
          { variant: variant, name: controlName.value },
          lambdaEvent.sessionAttributes
        );
      } else {
        remove_default_loaded(
          { variant: variant, name: controlName.value },
          this.initSession
        );
      }
      this.refreshFormData(formNumberType, true);
    }
    var obj = {};
    if (allow_multiple) {
      obj[controlName] = [];
    } else {
      obj[controlName] = null;
    }
    this[formName].patchValue(obj);
    this.foodTypeTwoSlotsCount = this.agentResponseService.numOfValuesPreset(
      this.foodTypeTwoForm.value
    );
  }

  public handleAddressChange(address: Address) {
    this.foodTypeTwoForm.patchValue(
      { CustomerAddress: address.formatted_address },
      { onlySelf: true, emitEvent: false }
    );
  }
  lineItemAddressChange(address: Address) {
    this.customerAddress = address.formatted_address;
  }
  isAddressRadiusValid() {
    this.agentResponseService.getDistance(
      this.uuid,
      this.socket,
      this.customerAddress,
      this.restaurant_details["address"]
    );
  }
  removeExtra(form, controlName, value) {
    var indexValue = form.value[controlName].indexOf(
      value.trim().toLowerCase()
    );
    if (indexValue >= 0) {
      form.value[controlName].splice(indexValue, 1);
      var obj = {};
      obj[controlName] = form.value[controlName];
      form.patchValue(obj, { onlySelf: true, emitEvent: false });
    }
  }
  getDuplicateValueCount(form, controlName, exactmatch) {
    return _.filter(form.value[controlName], function (value) {
      return value == exactmatch.trim().toLowerCase();
    }).length;
  }
  addExtra(form, controlName, value) {
    form.value[controlName].push(value.trim().toLowerCase());
    var obj = {};
    obj[controlName] = form.value[controlName];
    form.patchValue(obj, { onlySelf: true, emitEvent: false });
  }
  sendSentimate() {
    if (!this.isDelivery) {
      this.sendAgent(
        {
          ask: "greeting",
          responseType: "greetingSentiment",
          foodType: "",
          tags: "pickup",
          random: true,
          tokens: null,
        },
        "sent_now"
      );
    }
  }
  validateAddress(radius) {
    if (radius >= this.restaurant_details["delivery_radius"]) {
      this.sendAgent(
        {
          ask: "addressValid",
          responseType: "addressPrompts",
          foodType: "",
          tags: "noDelivery",
          random: true,
          tokens: {
            address: this.customerAddress,
            actualmiles: radius,
            miles: this.restaurant_details["delivery_radius"],
          },
          addressType: "isdelivery",
        },
        "sent_now"
      );
    }
  }
  setSuperAgentMode(action = AppConstants.SUPER_AGENT_ON) {
    this.isPermanentTakeout = true;
    var orClass = this;
    this.ws
      .send(
        JSON.stringify({ agentText: null, action: action, uuid: this.uuid })
      )
      .subscribe(
        (msg) => {
          console.log("next SEND", msg.data);
        },
        (msg) => {
          console.log("error  SEND", msg);
        },
        () => {
          if (action == AppConstants.SUPER_AGENT_ON) {
            orClass.orService.initialLoadEvent(orClass.uuid, orClass.socket);
          }
        }
      );
  }
  updateSuperMode(event) {
    if (event.checked) {
      this.isPermanentSuperPowerMode = event.checked;
      this.orService.initialLoadEvent(this.uuid, this.socket);
      this.setSuperAgentMode(AppConstants.PERMANENT_SUPER_AGENT_ON);
    }
  }
  enablePublishButton() {
    this.publishSlotsDisabled = false;
  }
  triggerMenuVariationFilter(
    last_event_obj,
    itemType,
    foodType,
    formName,
    event
  ) {
    var keyArray = _.keys(last_event_obj)[0];
    if (keyArray.search(/NameOf/i) >= 0) {
      let typeName = this[formName].value[foodType];
      if (itemType == "itemOne") {
        this.variationOneIsTrue = true;
      } else {
        this.variationTwoIsTrue = true;
      }
      this.filterVariationListByMenu(
        _.values(last_event_obj)[0],
        itemType,
        typeName,
        formName,
        event
      );
    }
  }

  checkUserHasClientAccess(client_id) {
    this.voixHttp
      .get(`v1/agent-clients?dashboard_subdomain=${window.location.origin}`, {}, false, ('isAdminBuild' in environment))
      .subscribe((resp: AgentClientResponse) => {
        if (resp) {
          const getClient = resp?.data?.find((client) => client?.id === client_id);
          if (
            resp?.data &&
            !getClient
          ) {
            alert("You don't have permission to access that client's store");
            window.location.href = "/dashboard";
          } else {
            this.clientFullName = getClient?.['fullname'];
          }
        }
      });
  }

  triggerPaymentAttempts(that) {
    let payload = {
      id: that.uuid,
      agent_email: that.agent.user.email,
      channel: constants.PAYMENT_ATTEMPTS,
      message: {},
    };
    this.socket.send(payload);
  }
}
