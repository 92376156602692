<mat-drawer-container [hasBackdrop]="isNotificationDrawerOpened ? false : true">
  <mat-drawer *ngIf="isNotificationDrawerOpened" #drawer [mode]="'over'" [opened]="isNotificationDrawerOpened" [position]="isNotificationDrawerOpened ? 'start' : 'end'"
    (closedStart)="onClosed()">
    <div class="notification-list" *ngIf="isNotificationDrawerOpened">
      <div class="notification-close" (click)="onClosedNotification()">
        <i class="material-icons">chevron_left</i>
      </div>
      <h3 class="text-center mt-0">Notifications</h3>
      <ng-container *ngIf="notifications?.length">
        <mat-card class="notification-card" *ngFor="let notification of notifications" [ngStyle]="{'background-color': notification?.active ? '#fff' : '#ffc500'}">
          <mat-card-content>
            <div [innerHTML]="notification.notification | safeHtml"></div>
            <!-- <div class="notification-time">{{notification.createdAt}}</div> -->
          </mat-card-content>
        </mat-card>
      </ng-container>
      <div *ngIf="!notifications?.length" class="d-flex justify-content-center align-items-center mt-2 p-2">
        <span>No notification found!</span>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer *ngIf="isOpenDrawer" #drawer [mode]="'over'" [opened]="isOpenDrawer" [position]="'end'" (closedStart)="onClosedChat()">
    <div class="chat-list" *ngIf="conversationSoFar?.length && isOpenDrawer">
      <app-chat-drawer [conversationSoFar]="conversationSoFar"></app-chat-drawer>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="wrapper">
      <div class="alert-notification" [hidden]="!isNotificationOn">
        <span>{{ getErrorList() }}</span>
        <a target="_blank" class="a-text-color"
          href="https://chitchat.voix.ai/default/channels/e4xpe8h4ijgsu8je4ys8stzi3c">, Please contact the support
          team.</a>
        <i (click)="closeNotification()" class="material-icons close-notification">close</i>
      </div>
      <div class="sidebar" data-color="voix" data-image="" *ngIf="!navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(../assets/img/sidebar-4.jpg)"></div>
      </div>
      <div class="main-panel" [ngClass]="
          navbarService.isActiveRoutes(['order-receive','ai','drive-thru']) ? 'full-width' : ''
        ">
        <app-navbar [errors]="mediaErrors" [conversationSoFar]="conversationSoFar"></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="!navbarService.isActiveRoutes(['order-receive','ai','drive-thru'])">
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>