import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Select } from "@ngxs/store";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { generalService } from "app/core/services/general.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.scss"],
})
export class CreditCardComponent implements OnInit {
  @Select(cartState.getCart) cart: Observable<cartDefaultState>;
  cardForm: FormGroup;
  cartId: string;
  totalAmountC: string;
  totalAmount: string;
  cartAmount: any;
  submitButtonOpen: boolean = true;

  constructor(
    private fb: FormBuilder,
    private generalService: generalService
  ) {}

  ngOnInit() {
    window.parent.addEventListener(
      "message",
      this.receiveMessage.bind(this),
      false
    );
    this.cartId = this.generalService.getCartId();
    this.getCartData();
    this.cardFormInit();
  }

  receiveMessage(event) {
    if (event?.data?.cartId && !this.cartId) {
      this.cartId = event?.data?.cartId;
      this.cardForm.get("cartId").setValue(this.cartId);
    }
  }

  getCartData() {
    this.cart.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        this.cartAmount = res;
      }
    });
  }

  cardFormInit() {
    this.cardForm = this.fb.group({
      cartId: [this.cartId],
      cardNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(19),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      expirationMonth: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      expirationYear: [
        null,
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      cvv: [
        null,
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      tipAmount: [null],
      billingAddress: this.fb.group({
        ccAddr1: [null],
        ccAddr2: [null],
        ccCity: [null],
        ccPhone: [null],
        ccPostalCode: [null, [Validators.required]],
        ccState: [
          null,
          [Validators.required, Validators.pattern("^[a-zA-Z ]*$")],
        ],
      }),
    });
  }

  get ccNumberRequired() {
    return this.cardForm.get("cardNumber").hasError("required");
  }

  get cardPostalCodeRequired() {
    return this.cardForm
      .get("billingAddress")
      .get("ccPostalCode")
      .hasError("required");
  }
  get cardStateRequired() {
    return this.cardForm
      .get("billingAddress")
      .get("ccState")
      .hasError("required");
  }

  get ccExpirationMRequired() {
    return this.cardForm.get("expirationMonth").hasError("required");
  }
  get ccExpirationYRequired() {
    return this.cardForm.get("expirationYear").hasError("required");
  }
  get cvvCardRequired() {
    return this.cardForm.get("cvv").hasError("required");
  }

  inputbehaviour(evt) {
    this.totalAmount = parseFloat(
      Number(evt) +
        Number(this.cartAmount.cartLedger.expectedLedger.lineItemPrice) +
        ""
    ).toFixed(2);
  }

  submitOrderCard() {
    const payload = {
      paymentType: "CARD",
      cardForm: this.cardForm.value,
      type: "INTERNAL",
    };
    this.submitButtonOpen = false;
    window.parent.postMessage(payload, "*");
    // this.dialogRef.close({
    //   paymentType: this.selectedPaymentType,
    //   cardForm: this.cardForm.value,
    // });
  }
}
