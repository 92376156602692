import { Component, OnInit, ViewChild, Inject } from '@angular/core';
// import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router } from '@angular/router';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { NotificationService } from '../../core/notification/notification.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-conversation-dialog',
  templateUrl: './conversation-dialog.component.html',
  styleUrls: ['./conversation-dialog.component.css']
})
export class ConversationDialogComponent {
  posForm: FormGroup;
  responseData: any;
  constructor( private voixHttp: VoixHttpService,
    private router: Router,
    private notification: NotificationService,
    public dialogRef: MatDialogRef<ConversationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
     this.responseData = data; 
    }
   
    onClose(): void {
      this.dialogRef.close();
    }

}
