<mat-divider></mat-divider>

<label id="example-radio-group-label">TIP</label>
<div class="row" style="margin-bottom: 25px">
  <div class="col-md-2">
    <button
      [ngClass]="{ selectedTip: selectedTip == '15' }"
      mat-stroked-button
      style="color: rgb(71, 214, 71)"
      (click)="setTipAmount(15, '15')"
      data-testid="tip-15-button"
    >
      % 15
    </button>
  </div>
  <div class="col-md-2">
    <button
      [ngClass]="{ selectedTip: selectedTip == '20' }"
      mat-stroked-button
      style="color: rgb(71, 214, 71)"
      (click)="setTipAmount(20, '20')"
      data-testid="tip-20-button"
    >
      % 20
    </button>
  </div>
  <div class="col-md-2">
    <button
      [ngClass]="{ selectedTip: selectedTip == '25' }"
      mat-stroked-button
      style="color: rgb(71, 214, 71)"
      (click)="setTipAmount(25, '25')"
      data-testid="tip-25-button"
    >
      % 25
    </button>
  </div>
  <div class="col-md-2">
    <input
      type="number"
      [attr.tooltip]="showTipError == true ? tipErrorText : ''"
      [ngClass]="{
        selectedTip: selectedTip == 'AMOUNT',
        haveError: showTipError
      }"
      placeholder="Enter tip amount"
      min="0"
      [max]="maxLimitValu"
      class="txtTip"
      (keyup)="setTipAmount($event.target.value, 'AMOUNT')"
      [(ngModel)]="tipValue"
      data-testid="tip-amount-input"
    />
  </div>
  <div class="col-md-4">
    <button
      [ngClass]="{ btnAddTip: showTipError }"
      mat-stroked-button
      [disabled]="showTipError"
      style="color: rgb(71, 214, 71)"
      (click)="AddTip(tipValue)"
      data-testid="tip-add-button"
    >
      Add ${{ tipValue }} Tip
    </button>
  </div>
</div>
