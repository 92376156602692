<div class="main-content dashboard no-scroll">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center flex-wrap pb-2">
      <div class="d-flex align-items-center dashboard-head">
        <div class="input-group search-btn-margin">
          <form
            method="#"
            action="#"
            [formGroup]="searchForm"
          >
            <mat-form-field class="example-full-width col-md-12 col-sm-12">
              <input
                matInput
                placeholder="Search"
                [formControl]="searchForm.controls['customer_phone']"
                (keyup.enter)="submitFilter(searchForm)"
              />
              <i class="material-icons custom-absolute-position">search</i>
            </mat-form-field>
            <span class="material-input"></span>
          </form>
        </div>
        <div class="d-flex">
          <mat-form-field appearance="fill" style="padding: 4px">
            <mat-label>Select Client</mat-label>
            <mat-select [(ngModel)]="selectedClient" name="client_code">
              <mat-option
                *ngFor="let client of clients"
                (click)="getAllStores(selectedClient)"
                [value]="client.id"
              >
                {{ client.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            appearance="fill"
            style="padding: 4px"
            *ngIf="selectedClient && stores"
          >
            <mat-label>Select Store</mat-label>
            <input
              type="text"
              placeholder="Select Store"
              aria-label="Number"
              matInput
              [formControl]="store"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let resStore of filteredOptions | async"
                [value]="
                  (resStore?.storeId || '') +
                  ' (' +
                  (resStore.storePhone || '') +
                  ')'
                "
                (click)="selectStore(resStore)"
              >
                {{ resStore?.storeId }} ({{ resStore?.storePhone || "N/A" }})
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="create-order" *ngIf="selectedClient && storePhone">
          <button
            class="create-test-order-btn"
            mat-button
            (click)="AddClientNumber()"
            matTooltip="Create Manual Order"
            *ngIf="(!(agentRole !== 'AGENT') && (userPermissions.PLACE_MANUAL_ORDER | permissions)) || (agentRole!='AGENT' && !(userPermissions.ORDER_ADD | permissions) && (userPermissions.PLACE_MANUAL_ORDER | permissions))"
          >
            Manual Order
          </button>
          
          <ng-container *ngIf="agentRole!='AGENT' && (userPermissions.ORDER_ADD | permissions)">
            <button
              class="create-test-order-btn"
              mat-button
              (click)="newOrder('')"
              matTooltip="Create Test Order"
              [ngClass]="{'manual-order-dropdown': (userPermissions.PLACE_MANUAL_ORDER | permissions)}"
            >
              Test Order
            </button>
            <button
              mat-icon-button
              [matMenuTriggerFor]="beforeMenu"
              class="create-manual-order-btn"
              *ngIf="(userPermissions.PLACE_MANUAL_ORDER | permissions)"
            >
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu
              #beforeMenu="matMenu"
              class="manual-order-menuV2"
              xPosition="before"
            >
              <button mat-menu-item
              style="height: 44px;"
              (click)="AddClientNumber()"
              >
                <span>Manual Order</span>
              </button>
              <button mat-menu-item
              style="height: 44px;"
            (click)="newDriveThruOrder('')"
            >
              <span>Drive Thru</span>
            </button>
            </mat-menu>
          </ng-container>
        </div>
      </div>
      <div>
        <app-gs-daterange
        (dateChanges)="dashboardDataLoad($event)"
        [monthshort]="'MMM'"
      ></app-gs-daterange>
      </div>

    </div>
  </div>
  <div class="container-fluid" *ngIf="isAdminBuild == undefined ? true : !isAdminBuild">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header" data-background-color="voix-blue">
            <!-- <i class="material-icons">content_copy</i> -->
            <img src="/assets/img/total-order.svg" class="card-icon" />
          </div>
          <div class="card-content">
            <p class="category">Total Orders</p>
            <h3 class="title">{{ orderData?.count || "0" }}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> {{ tickerLabel }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header" data-background-color="voix-blue">
            <!-- <i class="material-icons">attach_money</i> -->
            <img src="/assets/img/financial-document.svg" class="card-icon" />
          </div>
          <div class="card-content">
            <p class="category">Order Total</p>
            <h3 *ngIf="!orderData?.orderTotal" class="title">
              {{ "0" | currency : "USD" : "symbol-narrow" }}
            </h3>
            <h3 *ngIf="orderData?.orderTotal" class="title">
              {{
                orderData?.orderTotal[0]?.total || "0"
                  | currency : "USD" : "symbol-narrow"
              }}
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> {{ tickerLabel }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header" data-background-color="voix-blue">
            <!-- <i class="material-icons">data_usage</i> -->
            <img src="/assets/img/take-away.svg" class="card-icon" />
          </div>
          <div class="card-content">
            <p class="category">Avg. Order Size</p>
            <h3 class="title" *ngIf="orderData?.orderTotal">
              {{
                (orderData?.orderTotal[0]?.total
                  ? orderData?.orderTotal[0]?.total / orderData?.count
                  : "0"
                ) | currency : "USD" : "symbol-narrow"
              }}
            </h3>
            <h3 class="title" *ngIf="!orderData?.orderTotal">0</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> {{ tickerLabel }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header" data-background-color="orange">
            <!-- <i class="fa fa-cutlery"></i> -->
            <img src="/assets/img/open-order.svg" class="card-icon" />
          </div>
          <div class="card-content">
            <p class="category">Open Orders</p>
            <h3 class="title">{{ openOrderData?.count || "0" }}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> {{ tickerLabel }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="card-header" data-background-color="voix-blue">
              <h4 class="title">Orders</h4>
              <i
                class="material-icons refresh-table"
                matTooltip="Refresh Order"
                matTooltipPosition="below"
                (click)="refreshTable()"
                >autorenew</i
              >
              <!-- <p class="category">New Order on 15th September, 2016</p> -->
            </div>
            <div class="card-content table-responsive">
              <mat-table [dataSource]="dataSource">
                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Order Id' }}">Order Id</mat-header-cell>
                  <mat-cell
                    *matCellDef="let row"
                    class="order-id mat-cell-size"
                    (click)="redirectOrder(row)"
                    matTooltip="{{ row.id }}"
                  >
                    <span class="order-id-span">{{ row.id }}</span>
                    <!-- <i class="material-icons order-view" matTooltip="View Order" matTooltipPosition="right">remove_red_eye</i> -->
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order_id">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Order Source Id' }}">
                    Order Source Id</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.order_id }}">
                    {{ row.order_id }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="datetime">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Date and Time' }}">
                    Date and Time</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.created_at | date : 'M/d/y hh:mm a' : timezone }}">
                    {{
                      row.created_at | date : "M/d/y hh:mm a" : timezone
                    }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="customerphone">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Customer Phone' }}">
                    Customer Phone</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.customer_phone }}">
                    {{ row.customer_phone }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="agent_email">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Agent Email' }}">
                    Agent Email</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.agent_email || '-N/A-' }}">
                    {{ row.agent_email || "-N/A-" }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="client_name">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Order Source' }}">
                    Order Source</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.client_name || '-N/A-' }}">
                    {{ row.client_name || "-N/A-" }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="external_store_id">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Store ID' }}"> Store ID</mat-header-cell>
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.external_store_id || '-N/A-' }}">
                    {{ row.external_store_id || "-N/A-" }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="order_type">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Order Type' }}">
                    Order Type</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.order_type || '-N/A-' }}">
                    {{ row.order_type || "-N/A-" }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="payment_mode">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Payment Mode' }}">
                    Payment Mode</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ handlePaymentMode(row.payment_mode) || '-N/A-' }}">
                    {{
                      handlePaymentMode(row.payment_mode) || "-N/A-"
                    }}</mat-cell
                  >
                </ng-container>

                <!-- <ng-container matColumnDef="items">
                  <mat-header-cell *matHeaderCellDef>
                    Total Items</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)">
                    {{ row.LineItems.length }}</mat-cell
                  >
                </ng-container> -->

                <ng-container matColumnDef="total">
                  <mat-header-cell *matHeaderCellDef class="mat-cell-size" matTooltip="{{ 'Order Total' }}">
                    Order Total</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" (click)="redirectOrder(row)" class="mat-cell-size" matTooltip="{{ row.total_price | currency : 'USD' : 'symbol-narrow'}}">
                    {{
                      row.total_price | currency : "USD" : "symbol-narrow"
                    }}</mat-cell
                  >
                </ng-container>

                <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef class="medium-header"> Action</mat-header-cell>
                  <mat-cell *matCellDef="let row" (click)="openDialog(row.id)" class="medium-header">
                    <button type="button" class="btn btn-primary">
                      VIEW
                    </button></mat-cell
                  >
                </ng-container>
                <!-- 
                <ng-container matColumnDef="fax_status">
                  <mat-header-cell *matHeaderCellDef class="fax_status">
                    Status</mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="fax_status">
                    <span
                      class="{{ row.fax_status }}"
                      *ngIf="row.fax_status !== '-N/A-'"
                    >
                      {{ row.fax_status | uppercase }} - fax
                    </span>
  
                    <span
                      [ngClass]="
                        row.pos_confirmation_id && row.pos_status == 'SUCCESS'
                          ? 'delivered'
                          : 'failed'
                      "
                      *ngIf="row.pos_confirmation_id"
                    >
                      DELIVER{{
                        row.pos_status !== "SUCCESS"
                          ? "Y " + row.pos_status
                          : "ED"
                      }}
                      - {{ row.pos_confirmation_id }}
                    </span>
  
                    <span
                      *ngIf="
                        row.fax_status == '-N/A-' &&
                        _.isEmpty(row.pos_confirmation_id)
                      "
                    >
                      {{ row.fax_status | uppercase }}
                    </span>
  
                    <i
                      class="material-icons recheck-fax-status"
                      [ngClass]="{
                        'icon-refresh-animate': faxStatusLoader[row.id]
                      }"
                      matTooltip="Recheck fax Status"
                      matTooltipPosition="below"
                      (click)="checkFaxStatus(row)"
                      >autorenew</i
                    >
                  </mat-cell>
                </ng-container> -->

                <!-- <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef>
                    <mat-form-field
                      class="full-width status-border status-header"
                    >
                      <mat-select
                        placeholder="Status"
                        [(ngModel)]="filterStatus"
                        panelClass="mat-no-underline"
                        (selectionChange)="statusFilter($event)"
                        multiple
                      >
                        <mat-option [value]=""> All status </mat-option>
                        <mat-option *ngFor="let st of status" [value]="st.id">
                          {{ st.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-form-field class="status-border">
                      <mat-select
                        [(ngModel)]="row.status"
                        (selectionChange)="statusChange(row)"
                        panelClass="mat-no-underline"
                        [disabled]="row.status === 'ON-HOLD'"
                      >
                        <mat-option *ngFor="let st of status" [value]="st.id">
                          {{ st.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-cell>
                </ng-container> -->

                <mat-header-row
                  class="row-resize"
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns" class="row-resize">
                </mat-row>
              </mat-table>
              <mat-paginator
                [length]="orderData?.count"
                [pageSize]="limit"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="handlePage($event)"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
