import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { Select } from "@ngxs/store";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { storeDefaultState, storeState } from "app/core/ngxs/state/store.state";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { generalService } from "app/core/services/general.service";
import { Observable, takeWhile } from "rxjs";
import { SafePipe } from "safe-pipe";
import { TipActions, ITipModel } from "../tip/model/tip.model";
import { AuthenticationService } from "app/core/auth/authentication.service";
import { promoDefaultState, promoState } from "app/core/ngxs/state/promo.state";
import { dealsDefaultState, dealsState } from "app/core/ngxs/state/deals.state";
import { PromoItems } from "../../../../mediator-commlibs/interfaces/response/promos.interface";
import { DataShareService } from "app/core/services/data-share.service";
import { cartPromotions } from "../../../../mediator-commlibs/interfaces/response/cart.interface";
import { CartItemResponse } from "../../../../mediator-commlibs/interfaces/response/cartItem.interface";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { CashDisabledDialogComponent } from "./cash-disabled-dialog.component";
import { NotificationService } from "app/core/notification/notification.service";
import { CashOptionDisabledError, CashOptionDisabledResponse } from "../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";

@Component({
  selector: "app-submit-order",
  templateUrl: "./submit-order.component.html",
  styleUrls: ["./submit-order.component.scss"],
  providers: [DecimalPipe, SafePipe],
})
export class SubmitOrderComponent implements OnInit, OnDestroy {
  @Select(cartState.getCart) cart: Observable<cartDefaultState>;
  @Select(storeState.getStore) storeState: Observable<storeDefaultState>;
  @Select(promoState.getPromo) promos: Observable<promoDefaultState>;
  @Select(dealsState.getDeals) deals: Observable<dealsDefaultState>;
  allApplicableItems:PromoItems[] = [];
  isCashDisabled: boolean = false;
  isSubmitDisabled: boolean = false;
  selectedPaymentType: string = this.isCashDisabled ? 'CARD' : 'CASH';
  selectedPaymentProcess: string = '';
  isIVRenabled: boolean = false;
  paymentTypeV2 = [
    { value: "CASH", viewValue: "CASH" },
    { value: "CARD", viewValue: "CARD" },
    { value: "GIFT", viewValue: "GIFT" },
  ];
  gift_card: FormGroup;
  cartId: string;
  phone: any;
  cartAmount: any;
  appliedDeals: string[] = [];
  transactionSetupID: string = "";
  socket: any;
  uuid: any;
  tipAction: string = null;
  isPaymentSupportInternal: boolean = true;
  paymentAddressRequiredForPayments: boolean = true;
  tipActionEnum = () => {
    return TipActions;
  };
  isCallerCustomer: boolean = false;
  orderDestination: string;
  applyPromo: (isPromo: boolean, level: null, code: string) => {};
  payment_component_url: SafeUrl;
  currentuser: any;
  filteredSpecialOrder:boolean = false;
  isGiftMethodExist: boolean = false;
  maxOrderAmountCashDisabled: string = '';
  cashDisabledMsg: string = '';
  isComponentAlive = true;
  constructor(
    private fb: FormBuilder,
    public generalService: generalService,
    private router: Router,
    private crypto: CryptoService,
    @Optional() public dialogRef: MatDialogRef<SubmitOrderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    protected _sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private emitTrans: emitTransactionsService,
    private changeDetector: ChangeDetectorRef,
    private auth: AuthenticationService,
    private dataShare: DataShareService,
    private voixHttp: VoixHttpService,
    private dialog: MatDialog,
    private notification: NotificationService,
  ) {
    // this.getPhoneNumber();
    this.checkRouterUrl();
    if (!this.isCallerCustomer) {
    }
    this.storeState.subscribe((response: any) => {
      if (response) {
        const paymentMethods: [] = response.store.paymentMethods;
        //TODO: remove the below check to add gift option on order submit popup.
        this.isGiftMethodExist = paymentMethods.some((paymentMethod: any) =>
          paymentMethod.includes("gift")
        );
        this.isPaymentSupportInternal =
          this.generalService.checkInternalPaymentAccept(response);
        if (!this.isGiftMethodExist) {
          this.paymentTypeV2 = this.paymentTypeV2.filter(
            (method: any) => method.value != "GIFT"
          );
        }
        this.paymentAddressRequiredForPayments =
          response.store.paymentAddressRequiredForPayments;
      }
    });

   this.getMaxOrderAmount();

    this.applyPromo = this.data.applyPromo.bind(this);
    this.cartId = this.generalService.getCartId();
    this.emitTrans.paymentUrl$.subscribe((res: any) => {
      if (res) {
        this.changeDetector.detectChanges();
        // this.isInternalUrl = res.includes("certtransaction.hostedpayments.com");
        this.payment_component_url =
          this._sanitizer.bypassSecurityTrustResourceUrl(res);
        this.changeDetector.detectChanges();
      } else if (res === "") {
        this.payment_component_url = undefined;
        // this.creditCardIframe.nativeElement.innerHTML = "";
        if (this.generalService.isMuted) {
          this.generalService.pauseResumeRecording(false)
        }
      }
    });
    this.gift_card = this.fb.group({
      cartId: [this.cartId],
      cardNumber: [null, [Validators.required]],
      pin: [null, [Validators.required]],
      tipAmount: [null],
    });
    if (generalService.isApplicableForSpecial) {
      this.deals.subscribe((res: dealsDefaultState) => {
        this.allApplicableItems = res?.specialDeals;
        this.handlePromos();
      });
    }
    
    this.cart.pipe(takeWhile(() => this.isComponentAlive)).subscribe((res: cartDefaultState) => {
      if (res) {
        this.cartAmount = res;
        this.checkCashDisabled();
        this.checkIsAppiled();
      }
    });
    this.checkSubmitDisabled();
    if (data.isEdit) {
      if (data.orderDestination == "DRIVE_UP_PICK_UP") {
        this.paymentTypeV2 = [
          { value: "CARD", viewValue: "CARD" },
          { value: "GIFT", viewValue: "GIFT" },
        ];
      }
      this.getPhoneNumber();
      if (localStorage.getItem("tA")) {
        this.gift_card.patchValue({
          tipAmount: this.crypto.DecryptWithoutPromise("tA"),
        });
        this.inputbehaviour(this.crypto.DecryptWithoutPromise("tA"));
        // this.billingAddress.patchValue({
        //   ccPhone: this.phone,
        // });
      }
      this.selectedPaymentType = this.creditCardOptionDisabled(data.paymentType) ? 'CASH' : this.giftCardOptionDisabled(data.paymentType) ? 'CASH' : data.paymentType;
      if (data.order != null) {
        let order: any = data.order;
        if (this.selectedPaymentType == "CARD") {
          // this.cardForm.patchValue(order);
        } else if (this.selectedPaymentType == "GIFT") {
          this.gift_card.patchValue(order);
        }
      }
    } else {
      this.isEditFalse(data);
      // this.dummyGC();
    }

    // this.payment_component_url = this.data.payment_component_url;
    this.orderDestination = data.orderDestination;
    this.isIVRenabled = this.generalService.isIVRenabled;
    this.generalService.paymentAttemps$.pipe(takeWhile(() => this.isComponentAlive)).subscribe((attemps) => {
      if (attemps) {
        this.selectedPaymentType = this.creditCardOptionDisabled(data.paymentType) ? 'CASH' : this.giftCardOptionDisabled(data.paymentType) ? 'CASH' : data.paymentType;
      }
    });
  }

  private handlePromos() {
    this.promos.subscribe((promo: promoDefaultState) => {
      if (promo?.specialPromos?.length > 0 || this.allApplicableItems?.length > 0) {
        this.allApplicableItems = [
          ...(this.allApplicableItems ?? []),
          ...(promo?.specialPromos ?? [])
        ];
      }
  
      if (this.allApplicableItems?.length > 0) {
        this.allApplicableItems = this.allApplicableItems
          .filter((obj, index, self) =>
            index === self.findIndex((o) => o.promoCode === obj.promoCode)
          )
          .sort((a: PromoItems, b: PromoItems) => Number(b.value) - Number(a.value));
      }

      if(this.filteredSpecialOrder){
        this.allApplicableItems = this.allApplicableItems
        ?.filter((item: PromoItems) => {
          const applyLevel = item?.applyLevel?.toLowerCase();
          return applyLevel === 'order' || applyLevel === 'multiple';
        });
      }
  
      this.checkIsAppiled();
    });
  }

  checkIsAppiled() {
    this.cart.subscribe((res: cartDefaultState) => {
      const cartDetails: CartItemResponse = res as unknown as CartItemResponse;
      this.appliedDeals= []
      if (cartDetails?.cartPromotions?.length > 0) {
        this.allApplicableItems?.map((deal: PromoItems) => {
          if (cartDetails?.cartPromotions?.map((i: cartPromotions) => i.offerId).includes(deal.offerId)) {
            this.appliedDeals.push(deal.offerId);
          }
        });
      }else{
        this.appliedDeals = []
      }
    });
  }

  checkCashDisabled() {
    this.isCashDisabled = !this.maxOrderAmountCashDisabled ? false : Number(this.cartAmount?.cartLedger?.expectedLedger?.lineItemPrice || 0) > Number(this.maxOrderAmountCashDisabled || 0);
    if(this.isCashDisabled && !this.cashDisabledMsg) {
      this.cashDisabledMsg = `Cash Option has been disabled. $${Number(this.cartAmount?.cartLedger?.expectedLedger?.lineItemPrice || 0).toFixed(2)} exceeds the maximum cash payment limit of $${Number(this.maxOrderAmountCashDisabled || 0).toFixed(2)}.`;
      this.notification.openSnackBarActionV2(
        "end",
        "top",
        this.cashDisabledMsg,
        "danger-snackbar"
      );
    } else {
      this.cashDisabledMsg = '';
    }
  }

checkSubmitDisabled() {
  this.emitTrans.getSubmitButtonDisabled$.subscribe(submitButtonDisabled => {
    if(submitButtonDisabled)
    {
      this.notification.openSnackBarActionV2(
        "end",
        "top",
        `Minimum order amount is not met.`,
        "danger-snackbar"
      );
    }
    this.isSubmitDisabled = submitButtonDisabled;
  })
}

CashOptionDisabled(payType: string): boolean {
  if(this.isCashDisabled && payType === 'CASH'){
    return true;
  }
  return false;
}

creditCardOptionDisabled(payType: string): boolean {
  return payType === 'CARD' && this.generalService.currentCreditCardAttempts >= this.generalService.creditCardAttempts && this.generalService.isPaymentAttempsAllowed;
}

giftCardOptionDisabled(payType: string): boolean {
  return payType === 'GIFT' && this.generalService.currentGiftCardAttempts >= this.generalService.giftCardAttempts && this.generalService.isPaymentAttempsAllowed;
}

PaymentOptionDisabled(payType: string): boolean {
  if (this.isSubmitDisabled && (payType === 'CASH' || payType === 'GIFT' || payType === 'CARD')) {
    return true;
  }
  return false;
}

getMaxOrderAmount(){
  this.emitTrans.getOrderMaxAmount$.subscribe(maxCashOrderAmt => {
    if(maxCashOrderAmt)
    {
      this.maxOrderAmountCashDisabled = maxCashOrderAmt
    }
  })
}

  calculateDiscount(item: any): number {
    // Implement your discount calculation logic here
    const discountPercentage = item.value;

    if (discountPercentage !== undefined) {
      const discountAmount = (this.cartAmount?.cartLedger?.expectedLedger?.subLineItems[0]?.price / 100) * discountPercentage;

      // Convert the discountAmount to a number with two decimal places
      return parseFloat(discountAmount.toFixed(2));
    }

    console.log("Discount Percentage is undefined. Returning default value of 0.");
    return 0; // Default value in case of no discount
  }

  async DealApplied(item: PromoItems) {
    this.emitTrans.setAutoApplyCall(false);
    if (item?.offerType?.toLowerCase() == "deal") {
      setTimeout(async () => {
        const selectDealValue = {
          action: "selected-deal",
          deal: item,
        };
        this.dataShare.setSelectedDealFromPopup(selectDealValue);
      }, 50);
      const updateDealValue = {
        action: "apply-deal",
        deal: item,
      };
      await this.dataShare.setSelectedDealFromPopup(updateDealValue);
    } else {
      this.applyPromo(true, null, item.promoCode);
    }
  }

  checkRouterUrl() {
    if (this.router.url === "/payments") {
      this.isCallerCustomer = true;
    }
  }

  dummyCC() {
    // this.cardForm.patchValue({
    //   cardNumber: "4895321000000000",
    //   expirationMonth: "12",
    //   expirationYear: "2025",
    //   cvv: "382",
    // });
  }

  dummyGC() {
    this.gift_card.patchValue({
      cardNumber: "6006490987999930148",
      pin: "2840",
    });
  }

  isEditFalse(data: any) {
    if (data.orderDestination == "DRIVE_UP_PICK_UP") {
      this.paymentTypeV2 = [
        { value: "CARD", viewValue: "CARD" },
        { value: "GIFT", viewValue: "GIFT" },
      ];
    }

    this.cart.subscribe((res: any) => {
      if (res) {
        if (
          res.orderDestination != "CARRYOUT" ||
          res.orderDestination != "Pick Up"
        ) {
          // this.billingAddress.patchValue({
          //   ccPhone: res.customer.phone,
          //   ccAddr1: res.customer.deliveryAddress.streetLine1,
          //   ccAddr2: res.customer.deliveryAddress.streetLine2,
          //   ccCity: res.customer.deliveryAddress.city,
          //   ccPostalCode: res.customer.deliveryAddress.zip,
          //   ccState: res.customer.deliveryAddress.state,
          // });
        }
      }
    });
  }

  ngOnInit() {
    this.GetCurrentTipValue();
    this.checkIsAppiled()
  }
  GetCurrentTipValue() {
    this.emitTrans.getCurrentTipValue$.subscribe((tip: ITipModel) => {
      this.tipAction = tip.Action == TipActions.ADD_TIP ? "ADD_TIP" : null;
      this.payment_component_url = undefined;
      if (this.selectedPaymentType == "GIFT" && this.tipAction == "ADD_TIP" && !this.isIVRenabled) {
        this.triggerMuteRecording();
      }
    });
  }
  get ccNumberGCRequired() {
    return this.gift_card.get("cardNumber").hasError("required");
  }
  get pinGCRequired() {
    return this.gift_card.get("pin").hasError("required");
  }
  getPhoneNumber() {
    let CP: any = this.router.url.split("/");
    this.phone = CP[2];
  }
  // private get billingAddress(): FormGroup {
  //   return this.cardForm.get("billingAddress") as FormGroup;
  // }

  // submitOrderCard() {
  //   this.dialogRef.close({
  //     paymentType: this.selectedPaymentType,
  //     cardForm: this.cardForm.value,
  //   });
  // }
  totalAmountC: any;
  totalAmount: any;
  inputbehaviour(evt) {
    this.totalAmountC =
      Number(evt) +
      Number(this.cartAmount.cartLedger.expectedLedger.lineItemPrice);
    this.totalAmount = parseFloat(this.totalAmountC).toFixed(2);
  }

  submitOrderCash() {
      this.dialogRef.close({ paymentType: this.selectedPaymentType });
  }  
  
  getPaymentUrl(card) {
    this.selectedPaymentProcess = '';
    if (card == "CASH" && this.generalService.isMuted) {
        this.dialog.open(CashDisabledDialogComponent, {
          width: '300px',
          data: { message: 'Cash payment is currently disabled.' }
        });

      this.generalService.pauseResumeRecording(false);
      
    } else if (card == "GIFT" && this.tipAction == "ADD_TIP" && !this.isIVRenabled) {
      this.triggerMuteRecording();
    }

    if (card == "CARD" && !this.isPaymentSupportInternal) {
      this.emitTrans.getIframeUrl("");
    }

  }

  submitOrderGift() {
    this.dialogRef.close({
      paymentType: this.selectedPaymentType,
      gift_card: this.gift_card.value,
    });
  }

  ngOnDestroy() {
    if (this.generalService.isMuted) {
      this.generalService.pauseResumeRecording(false);
    }
    this.isComponentAlive = false;
    this.cashDisabledMsg = '';
  }

  iframeLoaded(event) {
    const embeddedUrl = this.payment_component_url?.['changingThisBreaksApplicationSecurity'] ?? this.payment_component_url;
    if (event.target.src === embeddedUrl) {
      this.triggerMuteRecording();
    }
  }

  triggerMuteRecording() {
    const payload = {
      cartId: this.generalService.getCartId()
    };
    window.postMessage(payload, "*");
    if (!this.generalService.isMutedFeatureRenabled) {
      return;
    }
    const [_, __, ___, CorrelationId]: string[] = this.router.url.split("/");
    if (CorrelationId) {
      this.generalService.activecallkey = CorrelationId; // get activecallkey from URL
    }
    if (!this.generalService.isMuted) {
      this.generalService.pauseResumeRecording(true);
    }
  }

  emitIVRsocket() {
    this.dialogRef.close({
      paymentType: this.selectedPaymentType,
      paymentProcess: this.selectedPaymentProcess,
    });
  }

  selectPayProcess(processType) {
    if (processType === 'AGENT') {
      this.triggerMuteRecording();
    } else if (processType === 'IVR' && this.generalService.isMuted) {
      this.generalService.pauseResumeRecording(false);
    }
  }
}