import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as io from "socket.io-client";
import { Store } from "@ngxs/store";
import { AppConstants } from "../app.constants";
import { AuthenticationService } from "../core/auth/authentication.service";
import { ActionGetPromo } from "../core/ngxs/action/promo.action";
import { setPromoNull, getAllPromos } from "../core/ngxs/action/promo_override.action";
import { NotificationService } from "../core/notification/notification.service";
import { alertNotificationService } from "../core/services/alert-notifcation.service";
import { emitTransactionsService } from "../core/services/emitTransactions.service";
import { VoixHttpService } from "../core/voix-http/voix-http.service";
import { environment } from "environments/environment";
import { uuid } from "assets/js/customScripts";

import { CommonLibs as constants} from '../commonLibs';
import { MatDialog } from "@angular/material/dialog";
import { UploadedCsvListComponent } from "./uploaded-csv-list/uploaded-csv-list.component";
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { FormControl, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BulkPromoReportModal } from "./bulk-report-modal/bulk-promo-report-modal.component";
import { map, Observable, startWith } from "rxjs";
import { AlertDialogComponent } from "app/core/shared/alert-dialog/alert-dialog.component";
import { Permissions } from "app/core/auth/model/permissions.model";

interface PromoCSV {
  CHECKED: boolean;
  ACTIVE: boolean;
  PRIORITY: number;
  CLIENT_CODE: string;
  STORE_ID: string;
  ORDER_DESTINATION: string;
  PROMO_CODE: string;
}

@Component({
  selector: "app-modify-coupon",
  templateUrl: "./modify-coupon.component.html",
  styleUrls: ["./modify-coupon.component.scss"],
})
export class ModifyCouponComponent implements OnInit {
  selectedClient: string;
  selectOrderType: string;
  socket: any;
  messageData: any = [];
  uuid: any;
  agentDetails: any;
  orderTypes: any[] = [
    { value: "DELIVERY", viewValue: "DELIVERY" },
    { value: "CARRYOUT", viewValue: "CARRYOUT" },
  ];
  clients: any[] = [];
  promos: any[] = [];
  storeId = new FormControl();
  filteredOptions: Observable<any>
  search: string;
  stores: any[] = [];
  searchS :string;
  dialogRef = null;
  isBulkPromos = false;
  userPermissions = Permissions;
  @ViewChild('fileImportInput') fileImportInput: any;

  constructor(
    private route: ActivatedRoute,
    private alertNotification: alertNotificationService,
    private notification: NotificationService,
    private auth: AuthenticationService,
    private voixHttp: VoixHttpService,
    private ngxsStore: Store,
    private emitTrans: emitTransactionsService,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private ngxService: NgxUiLoaderService,
  ) {
    this.uuid = uuid();
    localStorage.setItem("agent-uq", this.uuid);
    this.socket = io(environment.io_url, {
      query: "token=" + this.uuid,
      transports: ["polling"],
    });

    this.messageData["agent"] = [];
    this.voixHttp.get(`v1/agent-clients?dashboard_subdomain=${window.location.origin}`, {}, false, ('isAdminBuild' in environment)).subscribe((res: any) => {
      if (res) {
        console.log(res.data);
        this.clients = res.data;
      }
    });

    this.emitTrans.getPromoDashboard$.subscribe((res: any) => {
      if (res) {
        this.setPromoOverrideSocket(res);
      }
    });
  }

  ngOnInit() {
    this.socketConnection();
    this.ngxsStore.dispatch(new setPromoNull());
    // this.getPromoSocket();
    this.filteredOptions = this.storeId.valueChanges
      .pipe(
        startWith(null),
        map(val => this.filter(val))
      );
  }

  filter(val) {
    if(!val) {
      return this.stores.sort((a, b) => +a - +b);
    }
    return this.stores.filter(store => store.includes(val)).sort((a, b) => +a - +b);
  }

  sendSearch(value) {
    this.emitTrans.searchPromo.next(value);
  }

  getAllStores(selectedClient: string) {
    this.voixHttp
      .getStoresByClient(
        `v1/agent/getallrestaurants?clientcode=${selectedClient}`
      )
      .subscribe((res: any) => {
        if (res) {
          this.stores = res.data ? res?.data?.map(store => store?.storeId) : [];
          this.storeId.setValue(null);
        }
      });
  }
  socketConnection() {
    var that = this;

    // console.log(uuid());
    that.socket.on("connect", function () {
      that.alertNotification.set({
        value: AppConstants.NOT_CONNECTED,
        error: false,
      });

      that.messageData["agent"][that.uuid] = [];
    });

    that.socket.on("message", function (data) {
      if (data.channel) {
        // console.log(data);
        that.uuid = data.id;
        if (data.channel == constants.GET_PROMOS) {
          // console.log(data.message);
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.promos = data.message.data.data.promos;
            that.ngxsStore.dispatch(new getAllPromos([...that.promos.map(promo => ({...promo}))]));
          }
          that.ngxService.stop();
        } else if (data.channel == constants.SAVE_PROMO) {
          if (data.message?.data?.error || data.message?.data?.data?.error) {
            let message = '';
            if (Array.isArray(data.message?.data?.error?.errors)) {
              message = data.message?.data?.error?.errors[0].msg;
            } else if (typeof data.message?.data?.error === 'string') {
              message = data.message?.data?.error;
            } else if (typeof data.message?.data?.data?.error === 'string') {
              message = data.message?.data?.data?.error;
            }
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              message,
              "danger-snackbar"
            );
          } else {
            let hasError = false;
            const resp = data.message?.data?.data?.payload || data.message?.data?.data;
            if (that.isBulkPromos) {
              if (resp?.erroredPromos?.length) {
                that.dialogRef.componentInstance.form.get('csv').clear();
                for (const csvPromo of resp?.erroredPromos) {
                  that.dialogRef.componentInstance.csv.push(that.dialogRef.componentInstance.fb.group({
                    CHECKED: [csvPromo.isOverridden, Validators.required],
                    CLIENT_CODE: [csvPromo.clientCode ?? '', Validators.required],
                    PRIORITY: [csvPromo.priority, Validators.required],
                    STORE_ID: [csvPromo.storeId ?? '', Validators.required],
                    ORDER_DESTINATION: [csvPromo.orderDestination ?? '', Validators.required],
                    PROMO_CODE: [csvPromo.promoCode ?? '', Validators.required],
                    ACTIVE : [csvPromo.isActiveItem ?? ''],
                  }));
                }
                that.notification.openSnackBarActionV2(
                  "end",
                  "top",
                  "Some promos are not saved please check storeId & promo code and retry",
                  "danger-snackbar"
                );
                hasError = true;
              } else {
                if (that.promos?.length) {
                  that.promos = [];
                  that.ngxsStore.dispatch(new getAllPromos([]));
                }
              }
              that.dialog.open(BulkPromoReportModal, {
                width: '500px',
                enterAnimationDuration: '500ms',
                exitAnimationDuration: '300ms',
                data: {
                  total: resp?.requstedPromos?.length,
                  success: resp?.succeededPromos?.length,
                  error: resp?.erroredPromos?.length,
                },
              }).afterClosed().subscribe(() => {
                if (!resp?.erroredPromos?.length) {
                  that.dialogRef.close();
                }
              });
              that.isBulkPromos = false;
            } else {
              that.getPromoSocket();
              that.searchS = "";
            }
            if (!hasError) {
              that.notification.openSnackBarV2(
                "end",
                "top",
                'UPDATED SUCCESSFULLY',
                "success-snackbar"
              );
            }
          }
          that.ngxService.stop();
        }
      }
    });
    that.socket.on("open", function (connect) {
      console.log("open connect", connect);
    });
    that.socket.on("event", function (data) {
      console.log("event connect", data);
    });
    that.socket.on("disconnect", function () {
      console.log("socket disconnect");
      that.alertNotification.set({
        text: "Agent Backend socket connection is disconnected",
        value: AppConstants.NOT_CONNECTED,
        error: true,
      });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      var orComponent = this;
      if (!orComponent.socket.connected) {
        orComponent.alertNotification.set({
          text: "Agent Backend socket connection is not connected",
          value: AppConstants.NOT_CONNECTED,
          error: true,
        });
      }
    }, 5000);
  }

  getPromoSocket(search: string = "") {
    this.ngxService.start();
    // let cartId = this.general.getCartId();
    let agentString = localStorage.getItem("agentCurrentUser");
    let agent = JSON.parse(agentString);
    let agent_uq = localStorage.getItem("agent-uq");
    let payload = {
      id: agent_uq,
      agent_email: agent.user.email,
      channel: constants.GET_PROMOS,
      message: {
        ClientCode: this.selectedClient,
        storeId: this.storeId.value,
        cartId: null,
        type: this.selectOrderType,
        isAdmin: "true",
        search: search,
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  setPromoNull(){
    this.ngxsStore.dispatch(new setPromoNull());
  }

  setPromoOverrideSocket(promos: any) {
    let tempPromos = [];
    if (!this.isBulkPromos) {
      tempPromos = promos.filter((promo, i) => {
       return (
        promo.isOverridden != this.promos[i].isOverridden ||
        promo.isActiveItem != this.promos[i].isActiveItem ||
        promo.priority != this.promos[i].priority
      )})
      .map(promo => ({
        promoCode: promo.promoCode,
        isOverridden: promo.isOverridden,
        isActiveItem: promo.isActiveItem ? promo.isActiveItem : false,
        clientCode: this.selectedClient,
        storeId: this.storeId.value,
        orderDestination: this.selectOrderType,
        priority: (promo.priority || 100).toString(),
      }));
    } else {
      tempPromos = [...promos];
    }
    if (!tempPromos.length) {
      return;
    }
    this.ngxService.start();
    let agentString = localStorage.getItem("agentCurrentUser");
    let agent = JSON.parse(agentString);
    let agent_uq = localStorage.getItem("agent-uq");
    let payload = {
      id: agent_uq,
      agent_email: agent.user.email,
      channel: constants.SAVE_PROMO,
      message: {
        ClientCode: this.selectedClient,
        body: {
          promos: tempPromos,
        },
      },
    };
    // console.log(payload);
    this.socket.send(payload);
  }

  getPromos(payload: any) {
    this.ngxsStore.dispatch(new ActionGetPromo(payload));
  }

  submitFilter(search: string) {
    this.getPromoSocket(search);
  }

  fileChangeListener($event): void {
    const files = $event.srcElement.files;
    this.ngxCsvParser
    .parse(files[0], {
      header: true,
      delimiter: ',',
      encoding: 'utf8'
    })
    .pipe()
    .subscribe({
      next: (result: Array<PromoCSV>) => {
        this.fileImportInput.nativeElement.value = '';
        this.dialogRef = this.dialog.open(UploadedCsvListComponent, {
          width: "1000px",
          height: "800px",
          data: result,
        });
        this.dialogRef.componentInstance.client = this.selectedClient;
        this.dialogRef.componentInstance.onSubmit.subscribe((result) => {
          if(result) {
            this.submit(result.csv);
          }
        });
      },
      error: (error: NgxCSVParserError) => {
        console.log('Error', error);
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          error.message,
          "danger-snackbar"
        );
      }
    });
  }

  downloadSampleCSVFile() {  
    //define the heading for each row of the data  
    let csv = `CLIENT_CODE,${this.selectedClient == 'PJI' ? '' : 'PRIORITY,'}STORE_ID,ORDER_DESTINATION,PROMO_CODE,AUTO_APPLY\n`;
    const emptyRow = ["", "", "", "", ""];
    //merge the data with CSV
    if (this.selectedClient == 'PJI') {
      emptyRow.pop();
    }
    [emptyRow].forEach(function (row) {
      csv += row.join(',');
      csv += "\n";
    });
    //display the created CSV data on the web browser   
    var hiddenElement = document.createElement('a');  
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';  
    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = 'Promos Sample.csv';
    hiddenElement.click();  
  }  

  submit(promos: PromoCSV[]) {
    this.isBulkPromos = true;
    let hasDiffClient = false;
    for (const promo of promos) {
      if (promo.CLIENT_CODE !== this.selectedClient) {
        hasDiffClient = true;
        break;
      }
    }
    if (hasDiffClient) {
      this.openAlertDialog('Error', 'Please enter same client in all promos');
      return;
    }
    this.setPromoOverrideSocket((promos).map((promo: PromoCSV) => ({
      promoCode: promo.PROMO_CODE,
      isOverridden: promo.CHECKED,
      isActiveItem: promo.ACTIVE,
      clientCode: promo.CLIENT_CODE,
      storeId: promo.STORE_ID,
      orderDestination: promo.ORDER_DESTINATION,
      priority: promo.PRIORITY
    })));
  }

  openFile(fileImportInput) {
    if (!this.selectedClient) {
      this.openAlertDialog('Please select client', '');
      return;
    }
    fileImportInput.click();
  }

  openAlertDialog(heading, message) {
    let dialogNewRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '200ms',
      disableClose: false
    });
    dialogNewRef.componentInstance.heading = heading;
    dialogNewRef.componentInstance.message = message;
  }
}
