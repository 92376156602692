<mat-form-field class="w-100" appearance="fill" style="padding: 4px">
    <mat-label>Select Client</mat-label>
    <mat-select [(ngModel)]="selectedClient" name="client_code">
      <mat-option
        *ngFor="let client of clients"
        [value]="client.id"
        (click)="changeStore(client)"
      >
        {{ client.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
