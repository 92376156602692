import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";

import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { NotificationService } from "../../notification/notification.service";
import { emitTransactionsService } from "../../services/emitTransactions.service";
import { ActionCreateCart } from "../action/cart.action";
import { ActionSendPayment } from "../action/payment.action";
import {
  ActionSavePromo,
  ActionSaveSelectPromo,
  getAllPromos,
  setPromoNull,
  setPromoOverride,
} from "../action/promo_override.action";
import { ActionGetStore } from "../action/store.action";

export const savePromoDefaultState = {
  message: null,
  data: [],
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface savePromoDefaultState {
  message: String;
  isloaded: boolean;
  data: any[];
  isError?: boolean;
  errorMessage?: string;
}
@State<savePromoDefaultState>({
  name: "savePromo",
  defaults: savePromoDefaultState,
})
@Injectable()
export class savePromoState {
  constructor(
    private notifier: NotificationService,
    private emitTrans: emitTransactionsService
  ) {}
  @Selector()
  static getPromoOverridden(state: savePromoDefaultState) {
    return state.data;
  }

  @Action(ActionSavePromo)
  selectPromos(
    { setState, getState, patchState }: StateContext<savePromoDefaultState>,
    { payload }
  ) {
    const promos = getState();

    console.log(payload);
    const newPromos = promos.data.map((x) => {
      if (x.promoCode == payload.promo.promoCode) {
        x.isOverridden = payload.overridden;
      }
      return x;
    });
    patchState({ data: null });

    setTimeout(() => {
      patchState({ data: newPromos });
    }, 100);
  }

  @Action(ActionSaveSelectPromo)
  selectUnselectPromos(
    { getState, patchState }: StateContext<savePromoDefaultState>,
    { payload }
  ) {
    const currentState = getState();
    const updatedPromos = currentState.data.map((promo) => ({
      ...promo,
      isOverridden: payload.allPromosSelected,
    }));
  
    patchState({ data: updatedPromos });
  }

  @Action(getAllPromos)
  savePromos(
    { setState, getState, patchState }: StateContext<savePromoDefaultState>,
    { payload }
  ) {
    const promoState = {
      message: "Successfull",
      isloaded: true,
      data: payload,
    };
    setState(promoState);
  }

  @Action(setPromoOverride)
  prom({ getState }: StateContext<savePromoDefaultState>) {
    const promos = getState();
    if (promos.data) {
      this.emitTrans.setPromos(promos.data);
    }
  }

  @Action(setPromoNull)
  setPromoNull(
    { setState, getState, patchState }: StateContext<savePromoDefaultState>,
    { payload }
  ) {


    patchState({ data: null });

  }
}
