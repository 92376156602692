import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LogData {
  isPause: boolean;
  logMessage: string;
}

@Injectable({
  providedIn: 'root'
})
export class LogMessageService {
  private logMessageSubject = new BehaviorSubject<LogData>(null);

  logMessage$ = this.logMessageSubject.asObservable();

  emitLogMessage(logData: LogData) {
    this.logMessageSubject.next(logData);
  }
}