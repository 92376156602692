import { Component, Input, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Select, Store } from "@ngxs/store";
import { Permissions } from "app/core/auth/model/permissions.model";
import { setPromoOverride, ActionSavePromo, ActionSaveSelectPromo } from "app/core/ngxs/action/promo_override.action";
import { savePromoState } from "app/core/ngxs/state/promo_override.state";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import * as moment from "moment";

import { Observable, Subscription, take } from "rxjs";
import {PromoItems} from '../../../../mediator-commlibs/interfaces/response/promos.interface';

@Component({
  selector: "app-view-coupons",
  templateUrl: "./view-coupons.component.html",
  styleUrls: ["./view-coupons.component.scss"],
})
export class ViewCouponsComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<object>;
  @Select(savePromoState.getPromoOverridden) promoState: Observable<PromoItems[]>;
  displayedColumns = ["select", "offerId", "coupon_name", "promoCode"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() selectedClient:any = '';
  promos: any[] = [];
  promoOverriden: any[] = [];
  search: string;
  subscription: Subscription;
  userPermissions = Permissions;
  allPromosSelected:boolean = false;
  allPromos: PromoItems[] = [];
  isShowSortOrder = false;
  constructor(
    private emitTrans: emitTransactionsService,
    private ngxsStore: Store,
  ) {
    this.subscription = this.emitTrans.searchPromo.subscribe(
      (data) => (this.search = data)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this.promoState.subscribe((state: PromoItems[]) => {
      this.allPromos = state;
      this.allPromosSelected = state && state?.every((x) => x.isOverridden);
      if (this.allPromos?.length) {
        if (this.allPromos[0].hasOwnProperty('sortOrder')) {
          this.isShowSortOrder = true;
        } else {
          this.isShowSortOrder = false;
        }
      } else {
        this.isShowSortOrder = false;
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  savePromos() {
    this.ngxsStore.dispatch(new setPromoOverride());
    this.emitTrans.searchPromo.next("");
  }

  addPromoForOverride(promo: any, overridden:boolean, i: any) {
    this.ngxsStore.dispatch(new ActionSavePromo({promo,overridden,i}))
  }

   async SelectUnSelectAll(event: any) {
    this.allPromosSelected = event.checked;
    this.ngxsStore.dispatch(new ActionSaveSelectPromo({allPromosSelected:event.checked}));
  }

  changeAutoApplied(event: any, itemPromo: PromoItems) {
    this.allPromos.forEach((item: PromoItems) => {
      if (item.promoCode == itemPromo.promoCode) {
        item.isActiveItem = event.checked;
      }
    });
  }

  checkDateValid(startDate, endDate) {
    return startDate && endDate && (moment().isAfter(startDate) || moment(startDate).isSame(moment(), 'day')) && (moment().isBefore(endDate) || moment(endDate).isSame(moment(), 'day'));
  }
}
