import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';
// import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
// import * as firebase from 'firebase';
// import { AngularFireDatabase } from 'angularfire2/database';
// import { Subject } from 'rxjs/Subject';
import { VoixHttpService } from './../voix-http/voix-http.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn:'root'
})
export class MessagingService {

  // private messaging = firebase.messaging()

  private messageSource = new Subject()
  currentMessage = this.messageSource.asObservable() // message observable to show in Angular component

  constructor(private afs: AngularFirestore, private voixHttp: VoixHttpService
    ,private afMessaging: AngularFireMessaging) {}
// get permission to send messages
getPermission(user:any) {
  this.afMessaging.requestPermission.subscribe(permission=>{

    this.afMessaging.getToken.subscribe(token=>{
      this.saveToken(user,token);
    },(error:any)=>{
      // this.UtilityService.Toastr(ToastrType.Error,error,"Token Request")
      //console.log("Error while requesting token",error);
    })
  },(error)=>{
    // this.UtilityService.Toastr(ToastrType.Error,error,"Firebase Token Request")
    //console.log("Error while requesting permission from firebase",error);
  });
}

  // Listen for token refresh
monitorRefresh(user:any) {
  this.afMessaging.tokenChanges.subscribe(tokenChanges=>{
    this.afMessaging.getToken.subscribe(refreshToken=>{
      this.saveToken(user,refreshToken);
    });
  })
  // this.messaging.onTokenRefresh(() => {
  //   this.messaging.getToken()
  //   .then(refreshedToken => {
  //     //console.log('Token refreshed.', refreshedToken);
  //     this.saveToken(user, refreshedToken)
  //   })
  //   .catch( err => 
  //     console.log(err, 'Unable to retrieve new token') 
  //   )
  // });
}

// save the permission token in firestore
private saveToken(user:any, token:any): void {
  const currentTokens = user.fcmTokens || { }
  this.voixHttp.post('v1/agent/fcm-token', {currentTokens: currentTokens, token: token})
  .subscribe(data => { });
}

// used to show message when app is open
receiveMessages() {
    this.afMessaging.onMessage(payload => {
    //console.log('Message received. ', payload);
    if(payload.data.customerPhone) {
      //window.location.href = 'customer-details/'+ payload.data.customerPhone + '/'+ payload.data.uuid;
      window.location.href = 'order-receive/'+ payload.data.customerPhone + '/'+ payload.data.uuid + '/' + payload.data.restaurantPhone;
    }
    this.messageSource.next(payload)
  });
}
  // // get permission to send messages
  // getPermission(user) {
  //   this.afMessaging.requestPermission()
  //   .then(() => {
  //     //console.log('Notification permission granted.');
  //     return this.messaging.getToken()
  //   })
  //   .then(token => {
  //     this.saveToken(user, token)
  //   })
  //   .catch((err) => {
  //     //console.log('Unable to get permission to notify.', err);
  //   });
  // }

  //   // Listen for token refresh
  // monitorRefresh(user) {
  //   this.messaging.onTokenRefresh(() => {
  //     this.messaging.getToken()
  //     .then(refreshedToken => {
  //       //console.log('Token refreshed.', refreshedToken);
  //       this.saveToken(user, refreshedToken)
  //     })
  //     .catch( err => 
  //       console.log(err, 'Unable to retrieve new token') 
  //     )
  //   });
  // }

  // // save the permission token in firestore
  // private saveToken(user, token): void {
  //   const currentTokens = user.fcmTokens || { }
  //   this.voixHttp.post('v1/agent/fcm-token', {currentTokens: currentTokens, token: token})
  //   .subscribe(data => { });
  // }

  // // used to show message when app is open
  // receiveMessages() {
  //     this.messaging.onMessage(payload => {
  //     //console.log('Message received. ', payload);
  //     if(payload.data.customerPhone) {
  //       //window.location.href = 'customer-details/'+ payload.data.customerPhone + '/'+ payload.data.uuid;
  //       window.location.href = 'order-receive/'+ payload.data.customerPhone + '/'+ payload.data.uuid + '/' + payload.data.restaurantPhone;
  //     }
  //     this.messageSource.next(payload)
  //   });
  // }

}