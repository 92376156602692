import { Injectable } from '@angular/core';
import { VoixHttpService } from '../voix-http/voix-http.service';
import { API_URLS } from '../constants/api-urls';
import { map, Observable } from 'rxjs';
import { IPermission } from '../../roles/model/peremission.model';
import { IRole } from '../../roles/model/IRole';
import { IRoleListing } from '../../roles/model/role-listing.model';
import { IRolePermission } from '../../roles/model/IRolePermission.model';
import { IAgent } from '../../agent/model/IAgent';
import * as moment from "moment";

export interface AgentListResponse {
  users: Array<IAgent>;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class RolesAndPermissionsService {
  constructor(private httpservice: VoixHttpService) { }
  //Agents
  GetUsers(params: any): Observable<AgentListResponse> {
    return this.httpservice.get(API_URLS.Agent.Get, {params}).pipe(map(response => {
      const users: Array<IAgent> = response.data?.map(user => ({
        ...user,
        Email: user.email,
        Name: user.full_name,
        ID: user.id,
        Status: user.status,
        Role: user.roles?.map(role => role?.role_details?.name)?.join(),
        CreatedAt: moment(user.created_at).format('MM-DD-YYYY')
      }));
      return {
        ...response,
        users
      }
    }, error => error));
  }
  AddUser(agent:IAgent){
    return this.httpservice.post(API_URLS.Agent.Add,agent).pipe(map(data=>data,error=>error));
  }

  AddBulkAgent(agent:IAgent[]){
    return this.httpservice.post(API_URLS.Agent.AddBulkAgent,{agents: agent}).pipe(map(data=>data,error=>error));
  }

  DeleteUser(agents:Array<IAgent>){
    let ids = agents.map(user => user.ID).join(',');
    return this.httpservice.delete(API_URLS.Agent.Delete(ids));
  }
  GetUserRoles(userID:number){    
    return this.httpservice.get(API_URLS.Agent.GetUserRoles(userID));
  }
  UpdateUser(agent:IAgent){
    return this.httpservice.patch(API_URLS.Agent.Update,agent);
  }
  //Ageents End
  //Roles
  DeleteRole(RoleID: number) {
    return this.httpservice.delete(API_URLS.Role.DeleteRole(RoleID));
  }
  UpdateRole(role: IRole) {
    return this.httpservice.patch(API_URLS.Role.UpdateRole, role);
  }
  GetRolePermissions(RoleID: number): Observable<Array<IRolePermission>> {
    return this.httpservice.get(API_URLS.Role.GetRolePermissions(RoleID), {}).pipe(map(data => {
      let rolePermission: Array<IRolePermission> = [];
      data.data.forEach(item => {
        rolePermission.push({
          Active: item.isactive,
          RoleID: item.role_id,
          PermissionID: item.permission_id
        });
      });
      return rolePermission;
    }));
  }
  AddRole(role: IRole) {
    return this.httpservice.post(API_URLS.Role.AddRole, role);
  }
  GetRoles(isAgent = true): Observable<Array<IRoleListing>> {
    return this.httpservice.get(isAgent ? API_URLS.Role.GetRolesForAgent : API_URLS.Role.Get, {}).pipe(map(data => {
      let roleListing: Array<IRoleListing> = [];
      data.data.forEach(role => {
        roleListing.push({
          ID: role.role_id,
          Name: role.name,
          Description: role.description,
          IsActive: role.isactive,
          userAttached: role.userAttached
        });
      });
      return roleListing;
    }));
  }
  GetPermissions(): Observable<Array<IPermission>> {
    return this.httpservice.get(API_URLS.Permissions.Get, {}).pipe(map(data => {
      let permissions: Array<IPermission> = [];
      data.data.forEach(permission => {
        permissions.push({
          ID: permission.permission_id,
          Description: permission.description,
          Permission: permission.name,
          Selected: false,
          Active: permission.isactive
        });
      });
      return permissions;
    }));
  }
  //Roles End
}
