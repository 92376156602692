<div class="main-content no-scroll">
  <div class="row container-fluid">
      <!-- (ngSubmit)="addConfiguration(feedbackForm.value)" -->
    <form method="#" action="#" [formGroup]="feedbackForm" (ngSubmit)="submitFeedback(feedbackForm.value)">
      <div class="col-md-12 reason-pickup">
        <label id="example-radio-group-label">Pick your order feedback</label>
        <mat-checkbox name="test" class="example-radio-button" *ngFor="let reason of reasons" (change)="onChange(reason, $event)">
          {{reason}}
        </mat-checkbox>
        
      </div>
      <div class="col-md-12">
        <mat-form-field class="comment-full-width">
          <textarea matInput placeholder="Comment" [formControl]="feedbackForm.controls['comment']"></textarea>
        </mat-form-field>
      </div>
      <div class="footer text-center">
        <button type="submit" class="btn btn-primary btn-round" [disabled]="!feedbackForm.valid">Submit</button>
      </div>
    </form>
  </div>
</div>
