import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotificationService } from '../../core/notification/notification.service';
import { Router, ActivatedRoute } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'resetpassword',
  templateUrl: './resetpassword.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetPasswordForm : FormGroup;
  reset_token: any;
  userDetails: any;
  matcher = new MyErrorStateMatcher();
  hide = true;
  constructor(fb: FormBuilder,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.resetPasswordForm = fb.group({
        'password': [null, [Validators.required, Validators.minLength(8)]],
        'confirm_password': [null, [Validators.required, Validators.minLength(8)]]
      });
      this.route.params.subscribe( params => this.reset_token = params['reset_token'] );
    }
  
  ngOnInit() {
    this.voixHttp.get('v1/users/reset/' + this.reset_token, {})
    .subscribe (
      (res:any) => {
        this.userDetails = res.user;
      }
    );
  }

  submitForm(form: FormGroup) {
    this.voixHttp.post('v1/users/reset-password/' + this.reset_token, form.value).subscribe (
      (res:any) => {
        if(res.status) {
          form.reset();
          form.markAsPristine();
          this.router.navigateByUrl('/login');
          this.notification.show('success','notifications', 'You have successfully reset the password for your voix account and are signed in to voix.');
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', errorHandle.error);
      }
    );
  }
}
