
<div class="row mt-10">
  <div class="row col-md-10 row-margin-time" *ngFor="let hoursData of addedHours; index as i;">
    <div class="col-md-3"> {{hoursData.day.label}} </div>
    <div class="col-md-4"> <span>{{hoursData.start}} - {{hoursData.end}}</span>  </div>
    <div class="col-md-2"> <button class="btn btn-primary btn-round close-btn" (click)="removeTime(i)"> <i class="material-icons">close</i> </button> </div>
  </div>
</div>

<form method="#" action="#" [formGroup]="hoursForm" (ngSubmit)="hoursForm.valid && submitForm()">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field class="example-full-width row col-md-12">
          <mat-select placeholder="Days" [formControl]="hoursForm.controls['day']">
              <mat-option *ngFor="let day of days" [value]="day">
              {{ day.label }}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    
    <div class="col-md-3">
      <mat-form-field class="example-full-width row col-md-12">
        <mat-select placeholder="Start Time" [formControl]="hoursForm.controls['start']">
            <mat-option *ngFor="let hour of hours" [value]="hour" >
              <span *ngIf="hour == '12:00 pm'"> {{hour}} (Noon) </span>
              <span *ngIf="hour !== '12:00 pm'"> {{hour}} </span>
            </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-3">
        <mat-form-field class="example-full-width row col-md-12">
          <mat-select placeholder="End Time" [formControl]="hoursForm.controls['end']">
              <mat-option *ngFor="let hour of hours" [value]="hour">
                  <span *ngIf="hour == '12:00 pm'"> {{hour}} (Noon) </span>
                  <span *ngIf="hour !== '12:00 pm'"> {{hour}} </span>
              </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
      <button mat-button color="primary"  class="margin-top-btn"> <i class="material-icons">add</i> Add Hours</button>
    </div>
  </div>
</form>