<div class="main-content dashboard no-scroll">
  <div class="container-fluid">
    <mat-form-field appearance="fill" style="padding: 4px">
      <mat-label>Select Client</mat-label>
      <mat-select [(ngModel)]="selectedClient" name="client_code">
        <mat-option
          *ngFor="let client of clients"
          (click)="getAllStores(selectedClient)"
          [value]="client.id"
        >
          {{ client.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> 

    <mat-form-field appearance="fill" style="padding: 4px" *ngIf="selectedClient && stores">
      <mat-label>Select Store</mat-label>
      <input
        type="text"
        placeholder="Select Store"
        aria-label="Number"
        matInput
        [formControl]="store"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let resStore of filteredOptions | async"
          [value]="(resStore?.storeId || '') + ' (' + (resStore.storePhone || '') + ')'"
          (click)="selectStore(resStore)"
        >
          {{ resStore?.storeId }} ({{ resStore?.storePhone || "N/A" }})
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <span style="padding: 5px">
      <button
        mat-stroked-button
        color="primary"
        class="margin-top-btn neworder"
        (click)="handleButtonClick(storeDatabaseId)"
        [disabled]="!selectedClient || !storePhone"
      >
        VIEW Configuration
        <i class="material-icons" [ngClass]="{'lock': !selectedClient || !storePhone, 'unlock': selectedClient && storePhone}">
          {{ selectedClient && storePhone ? 'lock_open' : 'lock' }}
        </i>
      </button>
    </span>    

  <span style="padding: 5px">
    <button
      mat-stroked-button
      color="primary"
      class="margin-top-btn neworder"
      (click)="downloadSampleCSVFile()"
      *ngIf="(userPermissions.RESTAURANT_CONFIG_ADD | permissions)"
    >
      Download Sample CSV
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="margin-top-btn neworder"
      (click)="openFile(fileImportInput)"
      *ngIf="(userPermissions.RESTAURANT_CONFIG_ADD | permissions)"
    >
      Upload CSV
      <i class="material-icons">upload</i>
      <input
        #fileImportInput
        class="file-input"
        type="file"
        name="File Upload"
        id="csvFileUpload"
        (change)="fileChangeListener($event)"
        accept=".csv"
        hidden="true"
        style="display: none"
      />
    </button>
  </span>
   </div>

  <div class="col-md-12">
    <div class="card">
      <div class="card-header" data-background-color="voix-blue">
        <h4 class="title">Restaurant Configuration</h4>
        <i *ngIf="(userPermissions.RESTAURANT_CONFIG_ADD | permissions)" class="material-icons add-config" matTooltip="Add Configuration" matTooltipPosition="below" (click)="addConfiguration()">add</i>
      </div>
      <div class="row col-md-12 margin-top-20 full-width">
          <mat-table [dataSource]="dataSource">
  
            <!-- ID Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="component-name">Component Name</mat-header-cell>
              <mat-cell *matCellDef="let row" class="component-name">
                {{row.component_name}}
              </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef class="component-key">Component Key</mat-header-cell>
              <mat-cell *matCellDef="let row" class="component-key text-truncate">
                {{row.component_key}}
              </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="value">
              <mat-header-cell *matHeaderCellDef class="component-value">Component Value</mat-header-cell>
              <mat-cell *matCellDef="let row" class="component-value"> 
                <mat-form-field class="width-full">
                  <textarea matInput [(ngModel)]="row.component_value" [disabled]="!userPermissions.RESTAURANT_CONFIG_DELETE | permissions"></textarea>
                  <button *ngIf="userPermissions.RESTAURANT_CONFIG_EDIT | permissions" mat-button matSuffix mat-icon-button aria-label="done" (click)="update(row)" class="updateCompentValue">
                    <mat-icon class="apply">done</mat-icon>
                  </button>
                </mat-form-field>
                <a *ngIf="userPermissions.RESTAURANT_CONFIG_DELETE | permissions" mat-button class="pointer-cursor trash" (click)="delete(row)"><mat-icon class="delete">delete_forever</mat-icon></a>
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          
          </mat-table>
          <mat-paginator [length]="restaurantConfigDetails?.count"
            [pageSize]="limit"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="handlePage($event)">
          </mat-paginator>
      </div>
    </div>
  </div>
  
</div>