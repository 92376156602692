<div class="logo" style="padding: 35px 45px">
  <a routerLink="/dashboard" class="simple-text">
    <div class="logo-img">
      <img src="/assets/img/EzraAilogo.png" style="width: 140px" />
    </div>
    <!-- VOIX -->
  </a>
</div>
<div class="sidebar-wrapper">
  <div class="nav-container">
    <ul class="nav">
      <li
        routerLinkActive="active"
        *ngFor="let menuItem of menuItems"
        class="{{ menuItem.class }}"
      >
        <ng-container>
          <a
            [routerLink]="['/', menuItem.path]"
            (click)="reload(menuItem.path)"
            [ngClass]="
              menuItem.title === 'Notification Management' && 'pr-none'
            "
          >
            <i class="material-icons">{{ menuItem.icon }}</i>
            <p>{{ menuItem.title }}</p>
          </a>
        </ng-container>
      </li>
    </ul>
  </div>
  <ul class="nav nav-mobile-menu">
    <li>
      <a routerLink="/login"
        ><i class="material-icons fs-18">power_settings_new</i> Logout</a
      >
    </li>
  </ul>
</div>
