import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetDeals, ActionGetSpecialDeals, ActionSaveDeal, ActionSaveSelectDeal, getAllDeals, setDealOverride } from "../action/deals.action";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";

export const dealsDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  specialDeals: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};

export const saveDealDefaultState = {
  message: null,
  data: [],
  specialDeals: [],
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface dealsDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  specialDeals?: any | null;
  isError?: boolean;
  errorMessage?: string;
}

@State<dealsDefaultState>({
  name: "deal",
  defaults: dealsDefaultState,
})
@State<dealsDefaultState>({
  name: "saveDeal",
  defaults: saveDealDefaultState,
})
@Injectable()export class dealsState {
  constructor(private notifier: NotificationService,  private emitTrans: emitTransactionsService) {}
  @Selector()
  static getDeals(state: dealsDefaultState) {
    return state;
  }

  @Action(ActionSaveDeal)
  selectDeals(
    { setState, getState, patchState }: StateContext<dealsDefaultState>,
    { payload }
  ) {
    const deals = getState();

    const newDeals = deals.data.map((x) => {
      if (x.promoCode == payload.deal.promoCode) {
        x.isOverridden = payload.overridden;
      }
      return x;
    });
    patchState({ data: null });

    setTimeout(() => {
      patchState({ data: newDeals });
    }, 100);
  }

  @Action(setDealOverride)
  prom({ getState }: StateContext<dealsDefaultState>) {
    const deals = getState();
    if (deals.data) {
      this.emitTrans.setDeals(deals.data);
    }
  }

  @Action(ActionSaveSelectDeal)
  selectUnselectDeals(
    { getState, patchState }: StateContext<dealsDefaultState>,
    { payload }
  ) {
    const currentState = getState();
    const updatedDeals = currentState.data.map((deal) => ({
      ...deal,
      isOverridden: payload.allDealsSelected,
    }));
  
    patchState({ data: updatedDeals });
  }

  @Action(getAllDeals)
  saveDeals(
    { setState, getState, patchState }: StateContext<dealsDefaultState>,
    { payload }
  ) {
    const dealState = {
      message: "Successfull",
      isloaded: true,
      data: payload,
    };
    setState(dealState);
  }

  @Action(ActionGetSpecialDeals)
  specialDeals(
    { setState, getState, patchState }: StateContext<dealsDefaultState>,
    { payload }
  ) {
    const deals = getState();
    const dealState = {
      message: "Successfull",
      isloaded: true,
      data: deals.data,
      specialDeals: payload.data.deals
    };
    patchState(dealState);
  }

  @Action(ActionGetDeals)
  createCart(
    { setState, getState, patchState }: StateContext<dealsDefaultState>,
    { payload }
  ) {
    const deals = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const dealsState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.deals,
        statusCode: payload.statusCode,
      };
      patchState(dealsState);
      //   this.orderReceiveComp.getStoreInfoV2();
    }
  }
}
