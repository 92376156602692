import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroupDirective,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { AuthenticationService } from "../../core/auth/authentication.service";
import { NotificationService } from "../../core/notification/notification.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AppConstants } from "../../app.constants";
import * as _ from 'underscore';
import { HttpParams } from "@angular/common/http";
import { IsAdminDomainPipe } from "app/core/pipes/is-admin-domain.pipe";
import { environment } from "environments/environment";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  routeObect: any = {};
  isAdminBuild = environment['isAdminBuild'];
  constructor(
    fb: FormBuilder,
    private auth: AuthenticationService,
    private notification: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private isAdminDomainPipe : IsAdminDomainPipe
  ) {
    this.loginForm = fb.group({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: [null, Validators.required],
    });
  }

  matcher = new MyErrorStateMatcher();
  hide = true;
  submitForm(value: any) {
    // Once we have our data formed, we’ll send the request using the Angular 2 HTTP library.
    this.auth.login(value.email, value.password).subscribe(
      (res: any) => {
        //console.log(res);
        if (res.success) {
          // this.routeObect.route = 'dashboard';
          // window.location.href = '/' + this.routeObect.route;
          // window.open("about:blank", "_self").close();
          // win.close();
          // this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
          if (!('isAdminBuild' in environment)) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            if (!this.returnUrl) {
              this.router.navigateByUrl('/dashboard');
            } else {
              window.location.href = this.returnUrl;
            }
          }
        } else {
          this.notification.show("danger", "notifications", res.error);
        }
      },
      (err) => {
        this.notification.show("danger", "notifications", err.error);
      }
    );
  }

  ngOnInit() {
    if (!('isAdminBuild' in environment)) {
      // reset login status
      this.auth.logout();
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || null;
    if (!this.auth.isUserLogin()) {
      if (this.isAdminDomainPipe.transform()) {
        return;
      }
      const params = new HttpParams()
      .append('returnUrl', window.location.href);
      const adminUrl = environment?.['admin']?.['url'];
      window.location.href = `${adminUrl}/welcome/?${params}`;
    } else {
      if (!this.returnUrl) {
        this.router.navigateByUrl('/dashboard');
      } else {
        window.location.href = this.returnUrl;
      }
    }
  }
}
