export const CommonLibs = {
  APPLY_PRETIP: "APPLY_PRETIP",
  GET_PAYMENT_URL: "GET_PAYMENT_URL",
  CREATE_CART: "CREATE_CART",
  ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
  UPDATE_ITEM_TO_CART: "UPDATE_ITEM_TO_CART",
  REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
  GET_CART: "GET_CART",
  GET_MENU: "GET_MENU",
  GET_UP_SELLING_ITEMS: "GET_UP_SELLING_ITEMS",
  GET_HALF_MENU: "GET_HALF_MENU",
  GET_STORE_DETAILS: "GET_STORE_DETAILS",
  GET_NEAR_BY_STORES: "GET_NEAR_BY_STORES",
  GET_DRAFT_ORDER: "GET_DRAFT_ORDER",
  GET_CUSTOMER: "GET_CUSTOMER",
  GET_PROMOS: "GET_PROMOS",
  SUBMIT_ORDER: "SUBMIT_ORDER",
  SAVE_PROMO: "SAVE_PROMO",
  FREQUENT_ORDER: "FREQUENT_ORDER",
  PAST_ORDER: "PAST_ORDER",
  SAVE_ORDER_DB_CRM: "SAVE_ORDER_DB_CRM",
  APPLY_PROMO: "APPLY_PROMO",
  LOG_CHANNEL: "LOG_CHANNEL",
  CALL_MUTED: "CALL_MUTED",
  CALL_UNMUTED: "CALL_UNMUTED",
  WORLD_PAY_CC_REVERSAL: "WORLD_PAY_CC_REVERSAL",
  PAYMENT_CREDIT_CARD: "PAYMENT_CREDIT_CARD",
  PAYMENT_GIFT_CARD: "PAYMENT_GIFT_CARD",
  PAYMENT_CREDIT_CARD_RESPONSE: "PAYMENT_CREDIT_CARD_RESPONSE",
  PAYMENT_GIFT_CARD_RESPONSE: "PAYMENT_GIFT_CARD_RESPONSE",
  IVR_PAYMENTS: "IVR_PAYMENTS",
  GET_DEALS: "GET_DEALS",
  SAVE_DEAL: "SAVE_DEAL",
  CLEAR_CART: "CLEAR_CART",
  GET_FEEDBACK: "GET_FEEDBACK",
  SUBMIT_FEEDBACK: "SUBMIT_FEEDBACK",
  EDIT_CART_BY_PRODUCTUUID: "EDIT_CART_BY_PRODUCTUUID",
  LEX_SLOTS_CHANNEL: "lex_slots_channel",
  CUSTOM_RESPONSES_CHANNEL: "CUSTOM_RESPONSES_CHANNEL",
  EVENT_TRACK_CHANNEL: "EVENT_TRACK_CHANNEL",
  ORDER_CALCULATION_CHANNEL: "ORDER_CALCULATION_CHANNEL",
  BUILD_FINAL_ORDER_CHANNEL: "BUILD_FINAL_ORDER_CHANNEL",
  SENTIMENT_RESPONSE_CHANNEL: "SENTIMENT_RESPONSE_CHANNEL",
  FETCH_ORDER_CHANNEL: "FETCH_ORDER_CHANNEL",
  ORDER_CHANNEL: "ORDER_CHANNEL",
  SUBSCRIBE_CHANNEL: "SUBSCRIBE_CHANNEL",
  AGENT_TAKE_OVER_CHANNEL: "AGENT_TAKE_OVER_CHANNEL",
  LINE_ITEM_PRICING_CHANNEL: "LINE_ITEM_PRICING_CHANNEL",
  RADIUS_CHANNEL: "RADIUS_CHANNEL",
  GET_LAMBDA_RESPONSE: "GET_LAMBDA_RESPONSE", // constant shared between agent dashboard and agent backend
  GET_LAMBDA_EVENT: "GET_LAMBDA_EVENT", // constant used agent backend
  AGENT_CHANNEL: "agent_channel",
  LAMBDA_CHANNEL: "lambda_channel",
  AGENT_SLOTS_CHANNEL: "agent_slots_channel",
  LAMBDA_RESPONSE_CHANNEL: "lambda_response_channel",
  AGENT_RESPONSE_CHANNEL: "agent_response_channel",
  INCOMMING_TEXT_CHANNELS: ["sms", "chat", "facebook", "whatsapp"],
  ADDRESS_FROM_GOOGLE: "GET_ADDRESS_FROM_GOOGLE",
  ADDRESS_DETAILS_FROM_GOOGLE: "GET_ADDRESS_DETAILS_FROM_GOOGLE",
  LOGGED_INFO_DASHBOARD: "LOGGED_INFO_DASHBOARD",
  LOGGED_ERROR_DASHBOARD: "LOGGED_ERROR_DASHBOARD",
  PAYMENT_ATTEMPTS: "PAYMENT_ATTEMPTS",
  LOYALTY_ENROLL: "LOYALTY_ENROLL",
  FETCH_NOTIFICATION: "FETCH_NOTIFICATION"
};
export function get_variation_priority(variation) {
  if (variation.equals("size")) return 0;
  else if (variation.equals("crust")) return 1;
  else if (variation.equals("base")) return 2;
  else if (variation.equals("protein")) return 3;
  else if (variation.equals("sauce")) return 4;
  else if (variation.equals("spice")) return 5;
  else if (variation.equals("toppings")) return 6;
  else return 7;
}
export function set_default_loaded(itemDetails, session, isLoaded) {
  let isDefaultLoadedKey =
    itemDetails.name.replaceAll(" ", "") +
    itemDetails.variant +
    "_default_loaded";

  //specials are processed one item at a time, this way will resolve item beign overrided
  if (get_boolean_value(session.is_special_order) == true)
    isDefaultLoadedKey = "special" + itemDetails.variant + "_default_loaded";

  session[isDefaultLoadedKey.toLowerCase()] = isLoaded;
}
export function remove_default_loaded(itemDetails, session) {
  let isDefaultLoadedKey =
    itemDetails.name.replaceAll(" ", "") +
    itemDetails.variant +
    "_default_loaded";

  //specials are processed one item at a time, this way will resolve item beign overrided
  if (get_boolean_value(session.is_special_order) == true)
    isDefaultLoadedKey = "special" + itemDetails.variant + "_default_loaded";

  delete session[isDefaultLoadedKey.toLowerCase()];
}
export function START_CONVERSATION(channel = null) {
  let patternText = "startConversation";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}
export function VOIX_AI_RESPONSE(channel = null) {
  let patternText = "voixAIResponse";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}

export function CUSTOMER_REQUEST(channel = null) {
  let patternText = "customerRequest";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}
export function END_CONVERSATION(channel = null) {
  let patternText = "endConversation";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}
export function AGENT_ESCALATE(channel = null) {
  let patternText = "agentEscalate";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}
export function AGENT_ASSIGNED(channel = null) {
  let patternText = "agentAssigned";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}
export function VOIX_AGENT_RESPONSE(channel = null) {
  let patternText = "voixAgentResponse";
  if (channel) {
    return channel + "\\" + patternText;
  } else {
    return patternText;
  }
}

function get_boolean_value(obj) {
  if (obj === null || obj === "" || obj === undefined) return false;

  if (typeof obj === "boolean") return obj;

  if (obj.toUpperCase() === "TRUE") return true;

  return false;
}
