<form [formGroup]="addNewStoreForm">
    <div class="col-12">
        <div class="row">
            <div class="col-6 form-group">
                <label for="store-id">First Name *</label>
                <input class="form-control" formControlName="FirstName" placeholder="Enter First Name"
                    id="store-firstName" required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.FirstName.touched && addNewStoreFormControls.FirstName.errors?.required">
                    First Name is required
                </span>
            </div>
            <div class="col-6 form-group">
                <label for="store-id">Last Name *</label>
                <input class="form-control" formControlName="LastName" placeholder="Enter Last Name" id="store-lastName"
                    required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.LastName.touched && addNewStoreFormControls.LastName.errors?.required">
                    Last Name is required
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <label for="store-id">Store ID *</label>
                <input class="form-control" formControlName="StoreID" placeholder="Enter Store ID" id="store-id"
                    required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.StoreID.touched && addNewStoreFormControls.StoreID.errors?.required">
                    Store ID is required
                </span>
            </div>
            <div class="col-6 form-group">
                <label for="phone">Primary Phone *</label>
                <input class="form-control" placeholder="Enter Store Phone" id="phone" type="text" required
                    formControlName="Phone" maxlength="11" maxlength="11" />
                <div *ngIf="showError">
                    <span class="text-danger"
                        *ngIf="addNewStoreFormControls.Phone.touched && addNewStoreFormControls.Phone.errors?.required">
                        Phone is required
                    </span>
                    <span class="text-danger" *ngIf="addNewStoreForm.get('Phone').hasError('pattern')">Please enter only
                        in digits</span>
                    <span class="text-danger"
                        *ngIf="!addNewStoreForm.get('Phone').hasError('pattern') && addNewStoreForm.controls['Phone'].hasError('minlength')">Please
                        enter 11 digits</span>
                    <span class="text-danger"
                        *ngIf="!addNewStoreForm.get('Phone').hasError('pattern') && addNewStoreForm.controls['Phone'].hasError('maxlength')">Please
                        enter 11 digits</span>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <label>Address *</label>
                <input autocomplete="off" class="form-control" placeholder="Enter Store Address" id="store-email"
                    formControlName="Address" type="text" required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.Address.touched && addNewStoreFormControls.Address.errors?.required">
                    Address is required
                </span>
            </div>
            <div class="col-6 form-group">
                <label for="city">City *</label>
                <input autocomplete="off" name="city" class="form-control" placeholder="Enter Store City"
                    formControlName="City" id="city" type="text" required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.City.touched && addNewStoreFormControls.City.errors?.required">
                    City is required
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <label for="store-state">State *</label>
                <input autocomplete="off" name="store-state" class="form-control" placeholder="Enter Store State"
                    formControlName="State" id="store-state" required />
                <span class="text-danger"
                    *ngIf="addNewStoreFormControls.State.touched && addNewStoreFormControls.State.errors?.required">
                    State is required
                </span>
            </div>
            <div class="col-6 form-group">
                <label for="zip-code">Zip Code *</label>
                <input autocomplete="off" name="zip-code" class="form-control" formControlName="ZipCode"
                    placeholder="Enter Store Zip Code" id="zip-code" required type="text" maxlength="6" />
                <div *ngIf="showError">
                    <span class="text-danger"
                        *ngIf="addNewStoreFormControls.ZipCode.touched && addNewStoreFormControls.ZipCode.errors?.required">
                        Zipcode is required
                    </span>
                    <span class="text-danger" *ngIf="addNewStoreForm.get('ZipCode').hasError('pattern')">Please enter
                        only in digits</span>
                    <span class="text-danger"
                        *ngIf="!addNewStoreForm.get('ZipCode').hasError('pattern') && addNewStoreForm.controls['ZipCode'].hasError('minlength')">Zip
                        code is a minimum of 5 digits and max 6 digits</span>
                    <span class="text-danger"
                        *ngIf="!addNewStoreForm.get('ZipCode').hasError('pattern') && addNewStoreForm.controls['ZipCode'].hasError('maxlength')">Zip
                        code is a minimum of 5 digits and max 6 digits</span>
                </div>
            </div>


        </div>
        <div class="row">
            <div class="col-6">
                <label for="mode_of_operation">Mode of Operation</label>
                <mat-form-field appearance="fill" style="width: 100%">
                    <mat-select name="mode_of_operation" id="mode_of_operation" formControlName="ModeOfOperation">
                        <mat-option *ngFor="let moo of modeOfOperation" [value]="moo">
                            {{ moo | formatEnum }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <mat-label>Secondary Phones <span class="text-muted">(Note: You can double click to edit)</span></mat-label>
        <tag-input formControlName="secondary_phones"
                   [placeholder]="'Add a phone'"
                   [secondaryPlaceholder]="'Enter a phone'"
                   [addOnBlur]="true"
                   [editable]="true"
                   [removable]="true"
                   [separatorKeyCodes]="[13, 188]"> <!-- Enter and comma -->
        </tag-input>
    </div>
    <div class="row">
        <div class="col-md-12 action-btns">
            <div class="d-flex justify-content-end">
                <button class="btn btn-outline-dark btn-sm btn-action-buttons back-add-store-btn" (click)="Back()">
                    <i class="fa fa-arrow-left anchor-btn btn-action-buttons-icon" aria-hidden="true"></i>
                    <span>Back</span>
                </button>
                <button style="margin-left: 5px" (click)="ResetForm()"
                    class="btn btn-outline-dark btn-sm btn-action-buttons d-flex align-items-center">
                    <i class="fa fa-refresh anchor-btn btn-action-buttons-icon" aria-hidden="true"></i>
                    <span>Reset</span>
                </button>
                <button [disabled]="addNewStoreForm.invalid"
                    class="btn btn-outline-dark btn-sm btn-action-buttons d-flex align-items-center"
                    (click)="addNewStore()">
                    <i class="fa fa-plus anchor-btn btn-action-buttons-icon" aria-hidden="true"></i>
                    <span>{{selectedFormType}} Store</span>
                </button>
            </div>
        </div>
    </div>
</form>