import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Store } from '../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface';

export interface Conversation {
  customer: string;
  agent: string;
}

export interface Notification {
  notificationId: number;
  title: string;
  stores: Store;
  notification: string;
  startDate: string;
  endDate: string;
  active: boolean;
  createdAt: string;
}

@Injectable({
  providedIn:'root'
})
export class NavbarService {
  isOrderReceive = false;
  constructor(public location: Location) {}
  public chatDrawerSub = new BehaviorSubject<boolean>(false);
  chatDrawer$ = this.chatDrawerSub.asObservable();
  public notificationDrawerSub = new BehaviorSubject<boolean>(null);
  notificationDrawer$ = this.notificationDrawerSub.asObservable();

  public conversationSoFar = new BehaviorSubject<Conversation[]>([]);
  conversationSoFar$ = this.conversationSoFar.asObservable();

  public notifications = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notifications.asObservable();

  public isShowNotificationArrowIcon = new BehaviorSubject<boolean>(null);
  isShowNotificationArrowIcon$ = this.isShowNotificationArrowIcon.asObservable();

  isActiveRouter(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice( 1 );
    titlee = titlee.split("/")[0];
    if(path == titlee){
      return true;
    } else {
      return false;
    }
  }

  isActiveRoutes(path:string[]) {
    var title = this.location.prepareExternalUrl(this.location.path());
    title = title.slice( 1 );
    title = title.split("/")[0];
    if(path.includes(title)){
      return true;
    } else {
      return false;
    }
  }

  emitDrawerToggle(value: boolean) {
    this.chatDrawerSub.next(value);
  }

  emitNotificationDrawerToggle(value: boolean) {
    this.notificationDrawerSub.next(value);
  }

  setConversation(value: Conversation[]) {
    this.conversationSoFar.next(value);
  }

  setNotifications(notifications: Notification[]) {
    this.notifications.next(notifications);
  }

  setShowNotificationIcon(show: boolean) {
    this.isShowNotificationArrowIcon.next(show);
  }

}