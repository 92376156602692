<div *ngIf="cartData?.cartMenuItems.length > 0">
  <button
    mat-flat-button
    style="float: right; font-weight: 700; cursor: pointer; color: red"
    (click)="close()"
  >
    CLOSE
  </button>
  <div class="d-flex" style="justify-content: space-around">
    <span style="margin-bottom: 10px" data-label="delivery_address"
      ><b>CART DETAILS</b></span
    >
    <span
      style="margin-bottom: 10px; color: red"
      data-label="delivery_address"
      *ngIf="cartData?.cartPromotions && cartData?.cartPromotions?.length > 0"
    >
      <b style="margin-left: 5px" *ngFor="let item of cartData?.cartPromotions">
        {{ item.promoCode ?? item.offerId }}
      </b>
      <!-- <b>{{ "PROMO APPLIED" }}</b> -->
    </span>
  </div>
  <div style="padding: 5px">
    <ng-container *ngFor="let item of cartData?.cartMenuItems; index as i">
      <div style="margin-bottom: 10px; margin-top: 10px">
        <div
          class="d-flex"
          style="justify-content: space-between; position: relative"
        >
          <div style="width: 80%">
            <span
              style="
                color: #536c79;
                font-weight: 600;
                font-size: medium;
                margin-bottom: 5px;
              "
            >
              <b
                >{{ item.displayName }}
                {{
                  item.halfSpecialties.length > 0 && item.halfSpecialties
                    ? "(HALF N HALF)"
                    : ""
                }}
                <span>( Qty {{ item.quantity }} )</span>
                <span *ngIf="item?.papaSizing"> PapaSize </span>
              </b>
            </span>

            <div style="margin-top: 10px">
              <b *ngIf="item?.papaSizing">
                Papa Size : {{ getPapaSizeName(item.modifiers) }}
              </b>
              <br />
              <span
                *ngFor="let addon of activeModifiers(item); index as ind"
                style="max-width: 10px !important; margin-top: 10px !important"
              >
                <span *ngIf="addon.alterationCategory != 'TOPPINGS'">
                  {{ addon.displayName }}
                  <span
                    *ngIf="
                      addon.alterationPortion &&
                      addon.alterationPortion != 'Normal'
                    "
                  >
                    - ({{ addon.alterationPortion | uppercase }})
                  </span>
                  <span *ngIf="ind != activeModifiers(item).length - 1">{{
                    ","
                  }}</span>
                </span>
              </span>
            </div>
          </div>
          <div style="position: absolute; right: 0">
            <div style="text-align: right">
              <button
                style="color: rgb(175, 27, 27); pointer-events: none"
                mat-flat-button
              >
                <span
                  *ngFor="
                    let addon of filterItemsOfType(item.modifiers);
                    index as ind
                  "
                >
                  <b *ngIf="!item.priceInfo.price && !item.priceInfo.price > 0">
                    {{
                      addon.priceInfo.price > 0
                        ? "  $" + (addon.priceInfo.price | number : "1.2-2")
                        : ""
                    }}</b
                  >
                </span>
              </button>
              <button
                *ngIf="item.priceInfo.price > 0"
                style="color: rgb(175, 27, 27); pointer-events: none"
                mat-flat-button
              >
                <b>{{
                  item.priceInfo.price > 0
                    ? "  $" + (item.priceInfo.price | number : "1.2-2")
                    : ""
                }}</b>
              </button>
            </div>
          </div>
        </div>

        <div style="margin-top: 10px" *ngIf="getToppings(item.modifiers) > 0">
          <span
            *ngIf="getDefaultToppings(item.modifiers) > 0"
            style="color: #536c79; font-weight: 500; font-size: medium"
          >
            <b> {{ "Your Default Toppings" }}</b>
          </span>
          <ng-container
            *ngFor="let addons of item.modifiers; index as modiIndex"
          >
            <div
              class="d-flex"
              style="justify-content: space-between; margin-top: 2px"
              *ngIf="
                addons.isDefault &&
                !addons.isRemoved &&
                addons.alterationCategory == 'TOPPINGS'
              "
            >
              <span>
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <span
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </span>
              </span>
              <button
                style="
                  color: rgb(175, 27, 27);
                  pointer-events: none;
                  margin-top: -8px;
                "
                mat-flat-button
              >
                <b>
                  {{
                    addons.priceInfo.price > 0
                      ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                      : ""
                  }}
                </b>
              </button>
            </div>
          </ng-container>
          <span
            *ngIf="getRemovedDToppings(item.modifiers) > 0"
            style="color: #536c79; font-weight: 500; font-size: medium"
          >
            <b> {{ "Your Removed Default Toppings" }}</b>
          </span>
          <ng-container
            *ngFor="let addons of item.modifiers; index as modiIndex"
          >
            <div
              class="d-flex"
              style="justify-content: space-between; margin-top: 2px"
              *ngIf="
                addons.alterationCategory == 'TOPPINGS' && addons.isRemoved
              "
            >
              <span>
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <span
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </span>
              </span>
              <button
                style="
                  color: rgb(175, 27, 27);
                  pointer-events: none;
                  margin-top: -8px;
                "
                mat-flat-button
              >
                <b>
                  {{
                    addons.priceInfo.price > 0
                      ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                      : ""
                  }}
                </b>
              </button>
            </div>
          </ng-container>
          <span
            *ngIf="getDefault(item.modifiers) > 0"
            style="color: #536c79; font-weight: 500; font-size: medium"
          >
            <b> {{ "Your Customisations" }}</b>
          </span>
          <ng-container
            *ngFor="let addons of item.modifiers; index as modiIndex"
          >
            <div
              class="d-flex"
              style="justify-content: space-between; margin-top: 2px"
              *ngIf="addons.alterationCategory && addons.isCustomised"
            >
              <span>
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <span
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </span>
              </span>
              <button
                style="
                  color: rgb(175, 27, 27);
                  pointer-events: none;
                  margin-top: -8px;
                "
                mat-flat-button
              >
                <b>
                  {{
                    addons.priceInfo.price > 0
                      ? "  $" + (addons.priceInfo.price | number : "1.2-2")
                      : ""
                  }}
                </b>
              </button>
            </div>
          </ng-container>
        </div>

        <ng-container
          *ngFor="let lineItem of item.ledger?.expectedLedger?.subLineItems"
        >
          <div
            *ngIf="isShowed.includes(i)"
            class="d-flex"
            style="justify-content: space-between; margin-top: 10px"
          >
            <span style="color: #536c79; font-weight: 400; font-size: medium">
              {{
                lineItem.subLineItemType == "PRODUCT"
                  ? lineItem.itemName + "(with AddOns)"
                  : lineItem.itemName
              }}
            </span>

            <button
              class="mr-1"
              style="
                color: rgb(175, 27, 27);
                margin-top: -8px;
                pointer-events: none;
              "
              mat-flat-button
            >
              {{
                lineItem.regularMenuPrice
                  ? "$" + (lineItem.regularMenuPrice | number : "1.2-2")
                  : "$" + (lineItem.price | number : "1.2-2")
              }}
            </button>
          </div>
        </ng-container>

        <div
          *ngIf="isShowed.includes(i)"
          class="d-flex"
          style="justify-content: space-between; margin-top: 7px"
        >
          <span style="color: #536c79; font-weight: 400; font-size: medium">
            {{ "Total" }}
          </span>

          <button
            class="mr-1"
            style="
              color: rgb(175, 27, 27);
              margin-top: -8px;
              pointer-events: none;
            "
            mat-flat-button
          >
            {{
              "$" +
                (item.ledger?.expectedLedger?.lineItemPrice | number : "1.2-2")
            }}
          </button>
        </div>

        <div style="padding: 5px">
          <hr />
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngFor="
        let lineItem of cartData?.cartLedger?.expectedLedger?.subLineItems
      "
    >
      <div
        class="d-flex"
        style="justify-content: space-between; margin-top: 2px"
        *ngIf="lineItem.price != 0"
      >
        <span style="color: #536c79; font-weight: 400; font-size: medium">
          {{
            lineItem.subLineItemType == "PRODUCT"
              ? lineItem.itemName + "(with AddOns)"
              : lineItem.itemName
          }}
        </span>

        <button
          class="mr-1"
          style="
            color: rgb(175, 27, 27);
            margin-top: -8px;
            pointer-events: none;
          "
          mat-flat-button
        >
          {{
            lineItem.regularMenuPrice
              ? "$" + (lineItem.regularMenuPrice | number : "1.2-2")
              : "$" + (lineItem.price | number : "1.2-2")
          }}
        </button>
      </div>
    </ng-container>
    <div
      *ngIf="cartData?.cartLedger"
      class="d-flex"
      style="justify-content: space-between; margin-top: 2px"
    >
      <span style="color: #536c79; font-weight: 400; font-size: medium">
        {{ "Total" }}
      </span>

      <button
        class="mr-1"
        style="color: rgb(175, 27, 27); margin-top: -8px; pointer-events: none"
        mat-flat-button
      >
        {{
          "$" +
            (cartData?.cartLedger?.expectedLedger?.lineItemPrice
              | number : "1.2-2")
        }}
      </button>
    </div>
  </div>
</div>
