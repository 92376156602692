import { GlobalApiResponse } from "app/core/models/apiResponse.interface";
import { Address } from "../../../../../mediator-commlibs/interfaces/response/store.interface";

export class ActionSubmitOrder {
  static readonly type = "[ORDER] SUBMIT ORDER";
  constructor(
    public payload: GlobalApiResponse,
    public storeAddress: Address
  ) {}
}
