declare const require: any;
import { Injectable } from "@angular/core";
import * as _ from 'underscore';
import { UcfirstPipe } from "../pipes/ucfirst.pipe";
import { ObjectHandlerPipe } from "../pipes/object-handler.pipe";
import { RemoveSpecialsCharPipe } from "../pipes/remove-specials.pipe";
import { CommonLibs as constants } from '../../commonLibs';
@Injectable({
  providedIn: 'root',
})
export class AgentResponseService {
  constructor() {}
  numOfValuesPreset(obj: any) {
    var count = 0;
    for (var prop in obj) {
      if (!_.isEmpty(obj[prop]) && prop != "counttwo") {
        count++;
      }
    }
    return count;
  }

  copyToClipboard(text) {
    document.addEventListener("copy", (e: ClipboardEvent) => {
      e.clipboardData.setData("text/plain", text);
      e.preventDefault();
      document.removeEventListener("copy", null);
    });
    document.execCommand("copy");
  }

  buildSentimateResponse(
    item,
    foodType,
    menuName,
    buildType = "missing",
    specials = false,
    selectedItems,
    specialValueSelected = null
  ) {
    var tokens: any = { name: menuName, type: foodType };
    if (specials) {
      return this.specialsResponse(item, menuName, buildType, tags, tokens);
    }
    var itemKey = item.key ? item.key : "nameOf" + foodType;
    var tags = itemKey;
    var itemValues = [];
    if (item.value && item.value.data) {
      itemValues = item.value.data;
    } else {
      itemValues = item;
    }
    if (specialValueSelected == null) {
      itemValues = _.where(itemValues, { is_special_only: false });
    }
    var servedItems: any = _.pluck(itemValues, "name")
      .slice(0, 5)
      .join(", ")
      .trim();

    if (!_.isEmpty(selectedItems)) {
      tags = "selectedItems|agentdashboard";
      tokens.selectedItems =
        typeof selectedItems == "object"
          ? selectedItems.join(", ")
          : selectedItems;
      tokens.variationName = item.key;
    } else if (buildType == "inquiry") {
      tags = "inquiry|agentdashboard";
      tokens.nameOfVariation = item.key;
      tokens.variationName = item.key;
      tokens.servedItems = servedItems;
    } else if (buildType == "validation") {
      tokens.servedItems = servedItems;
      tags = "validation|agentdashboard";
    }

    var response = {
      ask: buildType + itemKey,
      responseType: "orderPrompts",
      foodType: new UcfirstPipe().transform(foodType),
      tags: tags,
      tokens: tokens,
    };
    return response;
  }
  specialsResponse(item, menuName, buildType, tags, tokens) {
    if (buildType == "ask") {
      tags = "specials|agentdashboard";
      tokens.specials = _.sortBy(tokens.specials, function (i) {
        return i.priority;
      });
      tokens.specials = _.pluck(item, "name").slice(0, 3).join(", ").trim();
    }
    if (buildType == "inquiry" && menuName) {
      tags = "inquiry|agentdashboard";
      var specialsItem = _.map(item, function (element) {
        element.name = element.name.toLowerCase();
        return element;
      });
      var selectedSpecials = _.findWhere(specialsItem, { name: menuName });
      tokens.inquiryText = selectedSpecials.description;
    }
    var response = {
      ask: buildType,
      responseType: "specialsInquiry",
      foodType: null,
      tags: tags,
      tokens: tokens,
    };
    return response;
  }
  buildPricingResponse(data, availableVariations, selectedValue) {
    var tokens = { itemname: "", cost: "0$" };
    if (!_.isEmpty(data.details)) {
      var itemname = " ";
      itemname =
        itemname + new ObjectHandlerPipe().transform(data.details.size) + " ";
      itemname =
        itemname + new ObjectHandlerPipe().transform(data.details.name) + " ";
      itemname =
        itemname + new ObjectHandlerPipe().transform(data.details.type) + " ";
    }
    var variationText;
    var withtext;
    var addwith = false;
    availableVariations.forEach(function (value, key) {
      if (selectedValue[value.name] && selectedValue[value.name].data) {
        var nonDefault = _.filter(
          selectedValue[value.name].data,
          (a) => !a.is_default_collection
        );
        var nonselectedValue = new RemoveSpecialsCharPipe().transform(
          _.pluck(nonDefault, "name"),
          true
        );
        if (value.allow_multiple) {
          data.details[value.name] = _.intersection(
            data.details[value.name],
            nonselectedValue
          );
        } else {
          data.details[value.name] = _.intersection(
            [data.details[value.name]],
            nonselectedValue
          );
        }
      }
      if (
        value.name != "size" &&
        !_.isEmpty(data.details[value.name]) &&
        data.details[value.name] !== null
      ) {
        if (!addwith) {
          withtext = "with ";
          addwith = true;
        } else {
          withtext = "";
        }
        variationText =
          withtext + data.details[value.name] + " " + value.name + ", ";
        itemname = itemname + variationText;
      }
    });
    tokens.itemname = itemname ? itemname.trim().replace(/,\s*$/, "") : "";
    tokens.cost = data.price + "$";
    var response = {
      ask: "pricingMissing",
      responseType: "pricingInquiry",
      foodType: "FOOD",
      tags: "pricing|agentdashboard",
      tokens: tokens,
    };
    return response;
  }
  transformAgentSentimate(
    response: any,
    orderData: any,
    categoryList: any,
    menuItem: any,
    variation: any
  ) {
    //console.log("transform agent sentimate", response, orderData, categoryList, menuItem, variation);
    let lastOrderObject = orderData;
    let menuItemList = _.sortBy(menuItem, function (i) {
      i.name = i.name.toLowerCase();
      return i.priority;
    });
    let menuNames = _.pluck(menuItemList, "name").slice(0, 5).join(", ").trim();
    let categoryNames = categoryList
      ? _.pluck(categoryList, "name").join(", ").trim()
      : "";

    if (response.ask === "invalidname" && response.foodType) {
      response.tokens = { top5Items: menuNames };
    } else if (response.ask === "inquiryName" && response.foodType) {
      response.tokens = { top5: menuNames };
    }
    if (response.ask === "inquiryfood") {
      response.tokens = { foodType: categoryNames };
    }
    //console.log(response);
    return response;
  }

  prompts() {
    return {
      greeting: {
        ask: "missingtype",
        responseType: "greetingSentiment",
        foodType: "",
        tags: "intial",
        random: false,
        tokens: "",
        any: true,
      },
      nameInquiry: {
        ask: "inquiryName",
        display: "Name Inquiry",
        responseType: "orderPrompts",
        foodType: "",
        tags: "top5",
        random: true,
        tokens: "",
        takeOverFlag: false,
      },
      foodInquiry: {
        ask: "inquiryfood",
        display: "Food Type Inquiry",
        responseType: "foodInquiry",
        foodType: "",
        tags: "foodType",
        random: false,
        tokens: "",
        any: false,
        takeOverFlag: false,
      },
      variationInquiry: {
        ask: "inquiryVariation",
        display: "Variation Inquiry",
        responseType: "orderPrompts",
        foodType: "",
        tags: "variation",
        random: true,
        tokens: "",
      },
      thankYou: {
        ask: "thankYou",
        display: "Thank You",
        responseType: "closeCall",
        foodType: "",
        tags: "any",
        random: true,
        tokens: "",
      },
      Pizza: {
        missingcrust: {
          ask: "missingcrust",
          responseType: "orderPrompts",
          foodType: "Pizza",
          tags: "crust",
          random: true,
        },
        missingtoppings: {
          ask: "missingtopping",
          responseType: "orderPrompts",
          foodType: "Pizza",
          tags: "toppings",
          random: true,
        },
        inquiryCrust: {
          ask: "inquiryCrust",
          responseType: "orderPrompts",
          foodType: "Pizza",
          tags: "crusttype",
          random: true,
        },
        inquiryToppings: {
          ask: "inquiryToppings",
          responseType: "orderPrompts",
          foodType: "Pizza",
          tags: "toppingstype",
          random: true,
        },
      },
      orderAnyThingElse: {
        ask: "orderAnythingElse",
        responseType: "orderPrompts",
        foodType: "Pizza",
        tags: "orderAnythingElse",
        random: true,
      },
    };
  }

  preselectedResponse() {
    return [
      [
        {
          key: "Pizza",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Pizza",
              tags: "pizza",
              random: true,
            },
            {
              ask: "missingsize",
              responseType: "orderPrompts",
              foodType: "Pizza",
              tags: "size",
              random: true,
              tokens: "{name:itemDetails.name, type:itemDetails.type}",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Pizza",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name:itemDetails.name}",
            },
            {
              ask: "invalidsize",
              responseType: "orderPrompts",
              foodType: "Pizza",
              tags: "sizevalidation|agentdashboard",
              random: true,
              tokens: "{servedSizes:}",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Pizza",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Salad",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Salad",
              tags: "salad",
              random: true,
            },
            {
              ask: "missingdressing",
              responseType: "orderPrompts",
              foodType: "Salad",
              tags: "dressing",
              random: true,
              tokens: "{otherDressingValues}",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Salad",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name:itemDetails.name}",
            },
            {
              ask: "invaliddressing",
              responseType: "orderPrompts",
              foodType: "Salad",
              tags: "dressingsvalidation|agentdashboard",
              random: true,
              tokens: "{servedSizes:}",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Salad",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Drinks",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Drinks",
              tags: "drinks",
              random: true,
            },
            {
              ask: "missingsize",
              responseType: "orderPrompts",
              foodType: "Drinks",
              tags: "size",
              random: true,
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Drinks",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name}",
            },
            {
              ask: "invalidsize",
              responseType: "orderPrompts",
              foodType: "Drinks",
              tags: "sizevalidation|agentdashboard",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Drinks",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Sides",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Sides",
              tags: "sides",
              random: true,
            },
            {
              ask: "missingsauce",
              responseType: "orderPrompts",
              foodType: "Sides",
              tags: "sauce",
              random: true,
              tokens: "otherSauceValues",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Sides",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name}",
            },
            {
              ask: "invalidsauce",
              responseType: "orderPrompts",
              foodType: "Sides",
              tags: "saucevalidation",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Sides",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Pasta",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Pasta",
              tags: "pasta",
              random: true,
            },
            {
              ask: "missingsauce",
              responseType: "orderPrompts",
              foodType: "Pasta",
              tags: "sauce",
              random: true,
              tokens: "otherSauceValues",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Pasta",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name}",
            },
            {
              ask: "invalidsauce",
              responseType: "orderPrompts",
              foodType: "Pasta",
              tags: "saucevalidation|agentdashboard",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Pasta",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Sandwich",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Sandwich",
              tags: "sandwich",
              random: true,
            },
            {
              ask: "missingsauce",
              responseType: "orderPrompts",
              foodType: "Sandwich",
              tags: "sauce",
              random: true,
              tokens: "otherSauceValues",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Sandwich",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name}",
            },
            {
              ask: "invalidsauce",
              responseType: "orderPrompts",
              foodType: "Sandwich",
              tags: "saucevalidation|agentdashboard",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Sandwich",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
        {
          key: "Wings",
          value: [
            {
              ask: "missingname",
              responseType: "orderPrompts",
              foodType: "Wings",
              tags: "wings",
              random: true,
            },
            {
              ask: "missingsauce",
              responseType: "orderPrompts",
              foodType: "Wings",
              tags: "sauce",
              random: true,
              tokens: "otherSauceValues",
            },
            {
              ask: "invalidname",
              responseType: "orderPrompts",
              foodType: "Wings",
              tags: "namevalidation|agentdashboard",
              random: true,
              tokens: "{name}",
            },
            {
              ask: "invalidsauce",
              responseType: "orderPrompts",
              foodType: "Wings",
              tags: "saucevalidation|agentdashboard",
            },
            {
              ask: "orderAnythingElse",
              responseType: "orderPrompts",
              foodType: "Wings",
              tags: "orderAnythingElse",
              random: true,
            },
          ],
        },
      ],

      [
        {
          key: "Deliverytakeout",
          value: [
            {
              ask: "delivery-OR-takeout",
              display: "DELIVERY OR TAKEOUT",
              responseType: "addressPrompts",
              foodType: "",
              tags: "delivery",
              random: true,
              tokens: null,
              addressType: "isdelivery",
            },
          ],
        },
        {
          key: "Customername",
          value: [
            {
              ask: "customername",
              display: "NAME",
              responseType: "addressPrompts",
              foodType: "",
              tags: "name",
              random: true,
              tokens: null,
              addressType: "customername",
            },
          ],
        },
        {
          key: "Customeraddress",
          value: [
            {
              ask: "customeraddress",
              display: "ADDRESS",
              responseType: "addressPrompts",
              foodType: "",
              tags: "address",
              random: true,
              tokens: null,
              addressType: "customeraddress",
            },
          ],
        },
      ],
    ];
  }

  foodTypeOneMapping() {
    return [
      { key: "Pizza", variation: "SizeOfPizza" },
      { key: "Salad", variation: "DressingOfSalad" },
      { key: "Sandwich", variation: "SauceOfSandwich" },
      { key: "Sides", variation: "SauceOfSides" },
      { key: "Drinks", variation: "SizeOfDrinks" },
      { key: "Pasta", variation: "SauceOfPasta" },
    ];
  }

  foodTypeTwoMapping() {
    return [
      { key: "PizzaTwo", variation: "SizeOfPizzaTwo" },
      { key: "SaladTwo", variation: "DressingOfSaladTwo" },
      { key: "SandwichTwo", variation: "SauceOfSandwichTwo" },
      { key: "SidesTwo", variation: "SauceOfSidesTwo" },
      { key: "DrinksTwo", variation: "SizeOfDrinksTwo" },
      { key: "PastaTwo", variation: "SauceOfPastaTwo" },
    ];
  }

  getDistance(uuid, socket, address1, address2) {
    let buildParam: any = {
      id: uuid,
      channel: constants.RADIUS_CHANNEL,
      message: { address1: address1, address2: address2 },
    };
    socket.send(buildParam);
  }

  getTop5Object(array) {
    return array.slice(0, 5);
  }
}
