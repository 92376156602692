<h1 mat-dialog-title>Restaurant Configuration</h1>
<div class="main-container">
  <div [formGroup]="form">
    <table class="csv-table">
      <thead>
        <tr>
          <th class="heading">
            <div *ngFor="let heading of csvHeadings">
              <span class="heading-title">{{ heading }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container formArrayName="csv">
          <ng-container *ngFor="let csvForm of csv.controls; let i = index">
            <tr>
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header [class.my-invalid]="csvForm.invalid">
                    <mat-panel-title class="name">
                      <div>
                        <span>{{ csvForm.get('restaurant_id')?.value }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('component_name')?.value }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('component_key')?.value }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('component_value')?.value }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div [formGroup]="csvForm" class="accordion-form">
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Restaurant ID</mat-label>
                        <input formControlName="restaurant_id" matInput />
                        <mat-error *ngIf="csvForm.get('restaurant_id')?.invalid">Restaurant ID is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Component Name</mat-label>
                        <input formControlName="component_name" matInput />
                        <mat-error *ngIf="csvForm.get('component_name')?.invalid">Component Name is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Component Key</mat-label>
                        <input formControlName="component_key" matInput />
                        <mat-error *ngIf="csvForm.get('component_key')?.invalid">Component Key is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Component Value</mat-label>
                        <input formControlName="component_value" matInput />
                        <mat-error *ngIf="csvForm.get('component_value')?.invalid">Component Value is required</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button class="submit-btn" mat-button mat-raised-button [disabled]="form.invalid" (click)="submit()">Submit</button>
  </div>
</div>
