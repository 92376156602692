import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/auth/auth.guard";
import { RoleGuard } from "./core/auth/role.guard";
import { LoginComponent } from "./account/login/login.component";
import { AuthComponent } from "./layout/auth/auth.component";
import { AdminComponent } from "./layout/admin/admin.component";
import { OrderReceiveComponent } from "./order-receive/order-receive.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PaymentsComponent } from "./payments/payments.component";
import { CustomResponseComponent } from "./custom-response/custom-response.component";
import { RestaurantSettingsComponent } from "./restaurant-settings/restaurant-settings.component";
import { PaymentSuccessNotifyComponent } from "./payments/success-notify/success-notify.component";
import { AudioBrowserComponent } from "./audio-browser/audio-browser.component";
import { ChangePasswordComponent } from "./account/change-password/change-password.component";
import { ModifyCouponComponent } from "./modify-coupon/modify-coupon.component";
import { RolesComponent } from "./roles/roles.component";
import { AgentComponent } from "./agent/agent.component";
import { NotFoundPageComponent } from "./components/not-found-page/not-found-page.component";
import { DealsComponent } from "./deals/deals.component";
import { ViewStoresComponent } from "./store-management/view-stores/view-stores.component";
import { ViewNotificationsComponent } from "./notification-management/view-notifications/view-notifications.component";
import { AiOrderRecieveComponent } from "./ai-order-recieve/component/ai-order-recieve/ai-order-recieve.component";
const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "coupon",
        component: ModifyCouponComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "keywords",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./keywords/keywords.module").then(
            (module) => module.KeywordsModule
          ),
      },
      {
        path: "deals",
        component: DealsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "custom-responses",
        component: CustomResponseComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: "admin",
        },
      },
      {
        path: "settings",
        component: RestaurantSettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "order-receive/:customerphone/:uuid/:restaurantphone",
        component: OrderReceiveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "ai/order-receive/:customerphone/:uuid/:restaurantphone",
        component: AiOrderRecieveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "drive-thru/:uuid/:restaurantphone",
        component: OrderReceiveComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "orders",
        // loadChildren: "./orders/orders.module#OrdersModule",
        loadChildren: () =>
          import("./orders/orders.module").then((m) => m.OrdersModule),
        canActivate: [AuthGuard],
      },
      {
        path: "chatbot",
        // loadChildren: "./chat-bot/chat-bot.module#ChatBotModule",
        loadChildren: () =>
          import("./chat-bot/chat-bot.module").then((m) => m.ChatBotModule),
        canActivate: [AuthGuard],
      },

      {
        path: "chatsms",
        // loadChildren: "./chat-sms/chat-sms.module#ChatSmsModule",
        loadChildren: () =>
          import("./chat-sms/chat-sms.module").then((m) => m.ChatSmsModule),
        canActivate: [AuthGuard],
      },
      {
        path: "sms-messenger",
        // loadChildren: "./sms-messenger/sms-messenger.module#SmsMessengerModule",
        loadChildren: () =>
          import("./sms-messenger/sms-messenger.module").then(
            (m) => m.SmsMessengerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "roles",
        component: RolesComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../app/roles/roles.module").then((r) => r.RolesModule),
      },
      {
        path: "agents",
        component: AgentComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../app/agent/agent.module").then((a) => a.AgentModule),
      },
      {
        path: "store-management",
        component: ViewStoresComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("../app/store-management/store-management.module").then(
            (a) => a.StoreManagementModule
          ),
      },
      {
        path: "notification-management",
        component: ViewNotificationsComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            "../app/notification-management/notification-management.module"
          ).then((a) => a.NotificationManagementModule),
      },
      {
        path: "faqs",
        canActivate: [AuthGuard],
        loadChildren: () => import("./faqs/faqs.module").then(module => module.FaqsModule)
      },
    ],
  },

  {
    path: "",
    component: AuthComponent,
    children: [
      { path: "welcome", component: LoginComponent },
      { path: "sip-audio-browser", component: AudioBrowserComponent },
      {
        path: "payment/:uuid/:storeId/:client_code/:cartId/:restaurantphone",
        component: PaymentsComponent,
      },
      {
        path: "pay/success",
        component: PaymentSuccessNotifyComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "transaction",
    loadChildren: () =>
      import("./transaction/transaction.module").then(
        (m) => m.TransactionModule
      ),
  },
  { path: "not-found", pathMatch: "full", component: NotFoundPageComponent },
  { path: "**", redirectTo: "welcome", pathMatch: "full" },
  { path: "", redirectTo: "welcome", pathMatch: "full" },
  
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [],
})
export class AppRoutingModule {}
