import { Component, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "app/core/auth/authentication.service";
import { Permissions } from "app/core/auth/model/permissions.model";
import { NotificationService } from "app/core/notification/notification.service";
import { alertNotificationService } from "app/core/services/alert-notifcation.service";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { Observable, map, startWith } from "rxjs";
import { Store } from "@ngxs/store";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { MatDialog } from "@angular/material/dialog";
import { NgxCSVParserError, NgxCsvParser } from "ngx-csv-parser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { uuid } from "assets/js/customScripts";
import * as io from "socket.io-client";
import { environment } from "environments/environment";
import { AppConstants } from "app/app.constants";
import { CommonLibs as constants } from "../commonLibs";
import { BulkPromoReportModal } from "app/modify-coupon/bulk-report-modal/bulk-promo-report-modal.component";

import { UploadedCsvListComponent } from "app/modify-coupon/uploaded-csv-list/uploaded-csv-list.component";
import { AlertDialogComponent } from "app/core/shared/alert-dialog/alert-dialog.component";
import {
  ActionGetDeals,
  getAllDeals,
  setDealNull,
} from "app/core/ngxs/action/deals.action";

interface DealCSV {
  CHECKED: boolean;
  ACTIVE: boolean;
  PRIORITY: number;
  CLIENT_CODE: string;
  STORE_ID: string;
  ORDER_DESTINATION: string;
  PROMO_CODE: string;
}

@Component({
  selector: "app-deals",
  templateUrl: "./deals.component.html",
  styleUrls: ["./deals.component.scss"],
})
export class DealsComponent {
  selectedClient: string;
  selectOrderType: string;
  socket: any;
  messageData: any = [];
  uuid: any;
  agentDetails: any;
  orderTypes: any[] = [
    { value: "DELIVERY", viewValue: "DELIVERY" },
    { value: "CARRYOUT", viewValue: "CARRYOUT" },
  ];
  clients: any[] = [];
  deals: any[] = [];
  storeId = new FormControl();
  filteredOptions: Observable<any>;
  search: string;
  stores: any[] = [];
  searchS: string;
  dialogRef = null;
  isBulkDeals = false;
  userPermissions = Permissions;
  @ViewChild("fileImportInput") fileImportInput: any;

  constructor(
    private route: ActivatedRoute,
    private alertNotification: alertNotificationService,
    private notification: NotificationService,
    private auth: AuthenticationService,
    private voixHttp: VoixHttpService,
    private ngxsStore: Store,
    private emitTrans: emitTransactionsService,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private ngxService: NgxUiLoaderService
  ) {
    this.uuid = uuid();
    localStorage.setItem("agent-uq", this.uuid);
    this.socket = io(environment.io_url, {
      query: "token=" + this.uuid,
      transports: ["polling"],
    });

    this.messageData["agent"] = [];
    this.voixHttp.get(`v1/agent-clients?dashboard_subdomain=${window.location.origin}`, {}, false, ('isAdminBuild' in environment)).subscribe((res: any) => {
      if (res) {
        console.log(res.data);
        this.clients = res.data;
      }
    });

    this.emitTrans.getDealDashboard$.subscribe((res: any) => {
      if (res) {
        this.setDealOverrideSocket(res);
      }
    });
  }

  ngOnInit() {
    this.socketConnection();
    this.ngxsStore.dispatch(new setDealNull());
    // this.getDealSocket();
    this.filteredOptions = this.storeId.valueChanges.pipe(
      startWith(null),
      map((val) => this.filter(val))
    );
  }

  filter(val) {
    if (!val) {
      return this.stores.sort((a, b) => +a - +b);
    }
    return this.stores
      .filter((store) => store.includes(val))
      .sort((a, b) => +a - +b);
  }

  sendSearch(value) {
    this.emitTrans.searchDeal.next(value);
  }

  getAllStores(selectedClient: string) {
    this.voixHttp
      .getStoresByClient(
        `v1/agent/getallrestaurants?clientcode=${selectedClient}`
      )
      .subscribe((res: any) => {
        if (res) {
          this.stores = res.data
            ? res?.data?.map((store) => store?.storeId)
            : [];
          this.storeId.setValue(null);
        }
      });
  }
  socketConnection() {
    var that = this;

    // console.log(uuid());
    that.socket.on("connect", function () {
      that.alertNotification.set({
        value: AppConstants.NOT_CONNECTED,
        error: false,
      });

      that.messageData["agent"][that.uuid] = [];
    });

    that.socket.on("message", function (data) {
      console.log("data", data);

      if (data.channel) {
        // console.log(data);
        that.uuid = data.id;
        if (data.channel == constants.GET_DEALS) {
          console.log(data.message);
          if (data.message.data.error) {
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              data.message.data.error.message,
              "danger-snackbar"
            );
          } else {
            that.deals = data?.message?.data?.data?.deals;
            console.log("that.deals", that.deals);

            that.ngxsStore.dispatch(
              new getAllDeals([...that.deals.map((deal) => ({ ...deal }))])
            );
          }
          that.ngxService.stop();
        } else if (data.channel == constants.SAVE_DEAL) {
          if (data.message?.data?.error || data.message?.data?.data?.error) {
            let message = "";
            if (Array.isArray(data.message?.data?.error?.errors)) {
              message = data.message?.data?.error?.errors[0].msg;
            } else if (typeof data.message?.data?.error === "string") {
              message = data.message?.data?.error;
            } else if (typeof data.message?.data?.data?.error === "string") {
              message = data.message?.data?.data?.error;
            }
            that.notification.openSnackBarActionV2(
              "end",
              "top",
              message,
              "danger-snackbar"
            );
          } else {
            let hasError = false;
            const resp =
              data.message?.data?.data?.payload || data.message?.data?.data;
            if (that.isBulkDeals) {
              if (resp?.erroredDeals?.length) {
                that.dialogRef.componentInstance.form.get("csv").clear();
                for (const csvDeal of resp?.erroredDeals) {
                  that.dialogRef.componentInstance.csv.push(
                    that.dialogRef.componentInstance.fb.group({
                      CHECKED: [csvDeal.isOverridden, Validators.required],
                      CLIENT_CODE: [
                        csvDeal.clientCode ?? "",
                        Validators.required,
                      ],
                      PRIORITY: [csvDeal.priority, Validators.required],
                      STORE_ID: [csvDeal.storeId ?? "", Validators.required],
                      ORDER_DESTINATION: [
                        csvDeal.orderDestination ?? "",
                        Validators.required,
                      ],
                      PROMO_CODE: [
                        csvDeal.promoCode ?? "",
                        Validators.required,
                      ],
                      ACTIVE: [csvDeal.isActiveItem ?? ""],
                    })
                  );
                }
                that.notification.openSnackBarActionV2(
                  "end",
                  "top",
                  "Some deals are not saved please check storeId & deal code and retry",
                  "danger-snackbar"
                );
                hasError = true;
              } else {
                if (that.deals?.length) {
                  that.deals = [];
                  that.ngxsStore.dispatch(new getAllDeals([]));
                }
              }
              that.dialog
                .open(BulkPromoReportModal, {
                  width: "500px",
                  enterAnimationDuration: "500ms",
                  exitAnimationDuration: "300ms",
                  data: {
                    total: resp?.requstedDeals?.length,
                    success: resp?.succeededDeals?.length,
                    error: resp?.erroredDeals?.length,
                  },
                })
                .afterClosed()
                .subscribe(() => {
                  if (!resp?.erroredDeals?.length) {
                    that.dialogRef.close();
                  }
                });
              that.isBulkDeals = false;
            } else {
              that.getDealSocket();
              that.searchS = "";
            }
            if (!hasError) {
              that.notification.openSnackBarV2(
                "end",
                "top",
                "UPDATED SUCCESSFULLY",
                "success-snackbar"
              );
            }
          }
          that.ngxService.stop();
        }
      }
    });
    that.socket.on("open", function (connect) {
      console.log("open connect", connect);
    });
    that.socket.on("event", function (data) {
      console.log("event connect", data);
    });
    that.socket.on("disconnect", function () {
      console.log("socket disconnect");
      that.alertNotification.set({
        text: "Agent Backend socket connection is disconnected",
        value: AppConstants.NOT_CONNECTED,
        error: true,
      });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      var orComponent = this;
      if (!orComponent.socket.connected) {
        orComponent.alertNotification.set({
          text: "Agent Backend socket connection is not connected",
          value: AppConstants.NOT_CONNECTED,
          error: true,
        });
      }
    }, 5000);
  }

  getDealSocket(search: string = "") {
    this.ngxService.start();
    // let cartId = this.general.getCartId();
    let agentString = localStorage.getItem("agentCurrentUser");
    let agent = JSON.parse(agentString);
    let agent_uq = localStorage.getItem("agent-uq");
    let payload = {
      id: agent_uq,
      agent_email: agent.user.email,
      channel: constants.GET_DEALS,
      message: {
        ClientCode: this.selectedClient,
        storeId: this.storeId.value,
        cartId: null,
        type: this.selectOrderType,
        isAdmin: "true",
        search: search,
      },
    };
    console.log(payload);
    this.socket.send(payload);
  }

  setDealNull() {
    this.ngxsStore.dispatch(new setDealNull());
  }

  setDealOverrideSocket(deals: any) {
    let tempDeals = [];
    if (!this.isBulkDeals) {
      tempDeals = deals
        .filter((deal, i) => {
          return (
            deal.isOverridden != this.deals[i].isOverridden ||
            deal.isActiveItem != this.deals[i].isActiveItem ||
            deal.priority != this.deals[i].priority
          );
        })
        .map((deal) => ({
          promoCode: deal.promoCode,
          isOverridden: deal.isOverridden,
          isActiveItem: deal.isActiveItem ? deal.isActiveItem : false,
          clientCode: this.selectedClient,
          storeId: this.storeId.value,
          orderDestination: this.selectOrderType,
          priority: (deal.priority || 100).toString(),
        }));
    } else {
      tempDeals = [...deals];
    }
    if (!tempDeals.length) {
      return;
    }
    this.ngxService.start();
    let agentString = localStorage.getItem("agentCurrentUser");
    let agent = JSON.parse(agentString);
    let agent_uq = localStorage.getItem("agent-uq");
    let payload = {
      id: agent_uq,
      agent_email: agent.user.email,
      channel: constants.SAVE_DEAL,
      message: {
        ClientCode: this.selectedClient,
        body: {
          deals: tempDeals,
        },
      },
    };
    this.socket.send(payload);
  }

  getDeals(payload: any) {
    this.ngxsStore.dispatch(new ActionGetDeals(payload));
  }

  submitFilter(search: string) {
    this.getDealSocket(search);
  }

  fileChangeListener($event): void {
    const files = $event.srcElement.files;
    this.ngxCsvParser
      .parse(files[0], {
        header: true,
        delimiter: ",",
        encoding: "utf8",
      })
      .pipe()
      .subscribe({
        next: (result: Array<DealCSV>) => {
          this.fileImportInput.nativeElement.value = "";
          this.dialogRef = this.dialog.open(UploadedCsvListComponent, {
            width: "1000px",
            height: "800px",
            data: result,
          });
          this.dialogRef.componentInstance.client = this.selectedClient;
          this.dialogRef.componentInstance.onSubmit.subscribe((result) => {
            if (result) {
              this.submit(result.csv);
            }
          });
        },
        error: (error: NgxCSVParserError) => {
          console.log("Error", error);
          this.notification.openSnackBarActionV2(
            "end",
            "top",
            error.message,
            "danger-snackbar"
          );
        },
      });
  }

  downloadSampleCSVFile() {
    //define the heading for each row of the data
    let csv = `CLIENT_CODE,${this.selectedClient == 'PJI' ? '' : 'PRIORITY,'}STORE_ID,ORDER_DESTINATION,PROMO_CODE,AUTO_APPLY\n`;
    const emptyRow = ["", "", "", "", ""];
    //merge the data with CSV
    if (this.selectedClient == 'PJI') {
      emptyRow.pop();
    }
    [emptyRow].forEach(function (row) {
      csv += row.join(",");
      csv += "\n";
    });
    //display the created CSV data on the web browser
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = "Deals Sample.csv";
    hiddenElement.click();
  }

  submit(deals: DealCSV[]) {
    this.isBulkDeals = true;
    let hasDiffClient = false;
    for (const deal of deals) {
      if (deal.CLIENT_CODE !== this.selectedClient) {
        hasDiffClient = true;
        break;
      }
    }
    if (hasDiffClient) {
      this.openAlertDialog("Error", "Please enter same client in all deals");
      return;
    }
    this.setDealOverrideSocket(
      deals.map((deal: DealCSV) => ({
        promoCode: deal.PROMO_CODE,
        isOverridden: deal.CHECKED,
        isActiveItem: deal.ACTIVE,
        clientCode: deal.CLIENT_CODE,
        storeId: deal.STORE_ID,
        orderDestination: deal.ORDER_DESTINATION,
        priority: deal.PRIORITY,
      }))
    );
  }

  openFile(fileImportInput) {
    if (!this.selectedClient) {
      this.openAlertDialog("Please select client", "");
      return;
    }
    fileImportInput.click();
  }

  openAlertDialog(heading, message) {
    let dialogNewRef = this.dialog.open(AlertDialogComponent, {
      width: "400px",
      enterAnimationDuration: "300ms",
      exitAnimationDuration: "200ms",
      disableClose: false,
    });
    dialogNewRef.componentInstance.heading = heading;
    dialogNewRef.componentInstance.message = message;
  }
}
