import { Pipe, PipeTransform } from "@angular/core";
import { Items } from "../../../mediator-commlibs/interfaces/response/menu.interface";
@Pipe({
  name: "orderBy",
})
export class OrderByPipe implements PipeTransform {
  transform(menu: Items[]): Items[] {
    if (!menu || menu.length == 0) return [];
    return [...menu]
      ?.map((item) => {
        if (item["parentName"].toLowerCase() === "sides") {
          item["sort"] = 1;
        } else if (item["parentName"].toLowerCase() === "desserts") {
          item["sort"] = 2;
        } else if (item["parentName"].toLowerCase() === "drinks") {
          item["sort"] = 3;
        } else if (item["parentName"].toLowerCase() === "wings") {
          item["sort"] = 4;
        } else {
          item["sort"] = 100;
        }
        return item;
      })
      .sort((a, b) => a["sort"] - b["sort"]);
  }
}
