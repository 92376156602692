import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'ucfirst'})
export class UcfirstPipe implements PipeTransform {
  transform(input: any): any {
    if(typeof input == 'string') {
      return input.substring(0, 1).toUpperCase() + input.substring(1);
    } else if(typeof input == 'object') {
      return input ? input.join() : '';
    }
  }
}