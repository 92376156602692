<form [formGroup]="addNewNotificationForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 form-group">
            <label for="store-state">Title *</label>
            <input
              autocomplete="off"
              name="notfication-title"
              class="form-control"
              placeholder="Enter Notification Title"
              formControlName="title"
              id="notfication-title"
              required
            />
            <span
              class="text-danger"
              *ngIf="
                addNewNotificationFormControls.title.touched &&
                addNewNotificationFormControls.title.errors?.required
              "
            >
              Notification title is required
            </span>
          </div>
          <div class="col-md-6 form-group priority">
            <mat-form-field class="col-12">
              <mat-label>Select Priority</mat-label>
              <mat-select formControlName="priority" name="notification_priority" required>
                <mat-option
                  *ngFor="let priority of [1,2,3,4,5,6,7,8,9,10]"
                  [value]="priority"
                >
                  {{ priority }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="col-12 form-group">
        <quill-editor
          class="w-100"
          placeholder="Enter notification here ..."
          formControlName="notification"
        ></quill-editor>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'" class="row w-100">
              <input
                formControlName="notify_start_date"
                matInput
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                placeholder="Start Date"
              />
    
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="calendar_today"
                (click)="updateDeliveryPickupTime()"
                class="updateInstruction"
              >
                <mat-icon class="apply">calendar_today</mat-icon>
              </button>
              <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'" class="row w-100">
              <input
                formControlName="notify_start_time"
                matInput
                [owlDateTime]="dt3"
                [owlDateTimeTrigger]="dt3"
                placeholder="Start Time (PST)"
              />
    
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="timer"
                class="updateInstruction"
              >
                <mat-icon class="apply">av_timer</mat-icon>
              </button>
              <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'" class="row w-100">
              <input
                formControlName="notify_end_date"
                matInput
                [owlDateTime]="dt2"
                [owlDateTimeTrigger]="dt2"
                placeholder="End Date"
              />
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="calendar_today"
                (click)="updateDeliveryPickupTime()"
                class="updateInstruction"
              >
                <mat-icon class="apply">calendar_today</mat-icon>
              </button>
              <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'" class="row w-100">
              <input
                formControlName="notify_end_time"
                matInput
                [owlDateTime]="dt4"
                [owlDateTimeTrigger]="dt4"
                placeholder="End Time (PST)"
              />
              <button
                mat-button
                matSuffix
                mat-icon-button
                aria-label="timer"
                (click)="updateDeliveryPickupTime()"
                class="updateInstruction"
              >
                <mat-icon class="apply">av_timer</mat-icon>
              </button>
              <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6">
            <mat-form-field class="right">
              <input
                matInput
                placeholder="Search Store"
                (keyup)="searchStore($event.target.value)"
              />
            </mat-form-field>
          </div>
         
        </div>
      </div>
      
      <div class="card-content table-responsive col-12 notification-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="table-heading">
                <input
                  cFormCheckInput
                  [checked]="allStoresSelected"
                  (click)="SelectUnSelectAll($event)"
                  type="checkbox"
                />
              </th>
              <th class="table-heading" scope="col">Store ID</th>
              <th class="table-heading" scope="col">Phone</th>
              <th class="table-heading" scope="col">Created At</th>
            </tr>
          </thead>
          <tbody *ngIf="storeListing?.length">
            <tr *ngFor="let store of storeListing">
              <td>
                <c-form-check>
                  <input
                    type="checkbox"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="store.Selected"
                    (change)="OnRowCheckboxClicked($event, store)"
                    [checked]="store.Selected"
                  />
                </c-form-check>
              </td>
              <td>{{ store?.external_location_id}}</td>
              <td>{{ store?.primaryPhone || "-" }}</td>
              <td>{{ store?.created_at ? formatDate(store?.created_at) : "-" }}</td>
            </tr>
          </tbody>
        </table>
        <div class="mat-row not-found" *ngIf="!storeListing?.length">
          <span> No data found. </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 action-btns">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-dark btn-sm btn-action-buttons back-add-store-btn"
          (click)="Back()"
        >
          <i
            class="fa fa-arrow-left anchor-btn btn-action-buttons-icon"
            aria-hidden="true"
          ></i>
          <span>Back</span>
        </button>
        <button
          style="margin-left: 5px"
          (click)="ResetForm()"
          class="btn btn-outline-dark btn-sm btn-action-buttons d-flex align-items-center"
        >
          <i
            class="fa fa-refresh anchor-btn btn-action-buttons-icon"
            aria-hidden="true"
          ></i>
          <span>Reset</span>
        </button>
        <button
          [disabled]="
            addNewNotificationForm.invalid ||
            !selectedStores.length
          "
          class="btn btn-outline-dark btn-sm btn-action-buttons d-flex align-items-center"
          (click)="addNewNotification()"
        >
          <i
            class="fa fa-plus anchor-btn btn-action-buttons-icon"
            aria-hidden="true"
          ></i>
          <span>{{ selectedFormType }} Notification</span>
        </button>
      </div>
    </div>
  </div>
</form>
