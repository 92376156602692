<div class="wrapper wrapper-full-page">
  <div
    class="full-page register-page"
    filter-color="black"
    data-image="../../assets/img/register.jpg"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-md-offset-3">
          <div class="card card-signup">
            <div class="text-center">
              <div class="logo-img">
                <img src="/assets/img/EzraAilogo.png" style="width: 200px" />
              </div>
              <!-- <div class="logo-img text-logo-sub">
                <img class="text-logo" src="/assets/img/logo-text-voix.png" />
            </div> -->
            </div>

            <div class="row">
              <div class="col-md-12 done-msg text-center">
                <i class="material-icons"> check_circle_outline </i> Payment
                Successful.
              </div>
              <div class="col-md-12 done-msg text-center">
                Transaction Id : {{this.queryStringParameters.TransactionID}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="full-page-background"
      style="background-image: url(../../assets/img/register.jpg)"
    ></div>
  </div>
</div>
