import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetDeals } from "../action/deals.action";
import { ActionGetFeedback } from "../action/feedback.action";
import { ActionGetFrequentOrderItem } from "../action/frequentOrder.action";
import { ActionGetPastOrder } from "../action/pastOrder.action";

export const feedbackDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface feedbackDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<feedbackDefaultState>({
  name: "feedback",
  defaults: feedbackDefaultState,
})
@Injectable()
export class feedbackState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getFeedback(state: feedbackDefaultState) {
    return state.data;
  }

  @Action(ActionGetFeedback)
  getFeedback(
    { setState, getState, patchState }: StateContext<feedbackDefaultState>,
    { payload }
  ) {
    const feedback = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const feedbackState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };
      patchState(feedbackState);
    }
  }
}
