<div class="main-content dashboard no-scroll">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center pb-2">
      <div class="d-flex flex-wrap align-items-center">
        <mat-form-field appearance="fill" style="padding: 4px">
          <mat-label>Select Client</mat-label>
          <mat-select [(ngModel)]="selectedClient" name="client_code">
            <mat-option *ngFor="let client of clients" (click)="getAllStores(selectedClient)" (click)="setPromoNull()"
              [value]="client.name">
              {{ client.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="padding: 4px">
          <mat-label>Select Store</mat-label>
          <input type="text" placeholder="Select Store" aria-label="Number" matInput [formControl]="storeId"
            [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let store of filteredOptions | async" (click)="setPromoNull()" [value]="store">
              {{ store }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill" style="padding: 4px">
          <mat-label>Select Order Destination</mat-label>
          <mat-select [(ngModel)]="selectOrderType" name="order_destination">
            <mat-option *ngFor="let orderType of orderTypes" [value]="orderType.value" (click)="setPromoNull()">
              {{ orderType.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span style="padding: 5px">
          <button mat-stroked-button color="primary" class="margin-top-btn neworder" (click)="getPromoSocket()"
            [disabled]="!selectedClient || !storeId.value || !selectOrderType"
            *ngIf="userPermissions.PROMO_VIEW | permissions">
            VIEW
            <i class="material-icons">lock</i>
          </button>
        </span>
        <span style="padding: 5px">
          <button mat-stroked-button color="primary" class="margin-top-btn neworder" (click)="downloadSampleCSVFile()"
            *ngIf="userPermissions.PROMO_ADD | permissions">
            Download Sample CSV
          </button>
          <button mat-stroked-button color="primary" class="margin-top-btn neworder" (click)="openFile(fileImportInput)"
            *ngIf="userPermissions.PROMO_ADD | permissions">
            Upload CSV
            <i class="material-icons">upload</i>
            <input #fileImportInput class="file-input" type="file" name="File Upload" id="csvFileUpload"
              (change)="fileChangeListener($event)" accept=".csv" hidden="true" style="display: none" />
          </button>
        </span>

      </div>
      <div class="promo-code">
        <mat-form-field>
          <input matInput [(ngModel)]="searchS" placeholder="Search Promo Code" #value
            (keyup)="sendSearch($event.target.value)" />
        </mat-form-field>
      </div>
    </div>
  </div>

  <div>
    <app-view-coupons [selectedClient]="selectedClient"></app-view-coupons>
  </div>
</div>