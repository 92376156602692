declare const require: any;
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  Router,
  ActivatedRoute,
  Event,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import { VoixHttpService } from "../../core/voix-http/voix-http.service";
import { getRestaurantService } from "../../core/auth/restaurant.service";
import * as _ from 'underscore';
import { NotificationService } from "../../core/notification/notification.service";
import { generalService } from "../../core/services/general.service";
import * as io from "socket.io-client";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../core/auth/authentication.service";
import { AGENT_ASSIGNED, AGENT_ESCALATE, CommonLibs as constants, CUSTOMER_REQUEST, END_CONVERSATION, START_CONVERSATION, VOIX_AGENT_RESPONSE, VOIX_AI_RESPONSE } from '../../commonLibs';
import { uuid } from "./../../../../src/assets/js/customScripts.js";
@Component({
  selector: "app-sms-messenger-ui",
  templateUrl: "./ui.component.html",
  styleUrls: ["./ui.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class SmsMessengerUIComponent implements OnInit {
  chatWindows = [];
  socket: any;
  agentDetails: any = {};
  listOfContacts = [];
  listOfSockets = [];
  docHeight: any;
  // tslint:disable-next-line:max-line-length
  // {uuid: "12121121", restaurant_id: 48, customer_phone: "16508618744", conversationData: [], name: "chicago pizza", esclate: false, assignedAgent: {}, endConversation: false}
  // tslint:disable-next-line:max-line-length
  constructor(
    private voixHttp: VoixHttpService,
    private router: Router,
    private route: ActivatedRoute,
    private generalService: generalService,
    private auth: AuthenticationService,
    private notification: NotificationService
  ) {
    this.socket = this.connection();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url !== "/sms-messenger") {
          this.socket.close();
          this.listOfSockets.forEach((element) => {
            element.close();
          });
        } else {
          this.socket.open();
        }
      }
    });
    this.docHeight = window.innerHeight - 72;
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.docHeight = window.innerHeight - 72;
  }
  ngOnInit() {
    const smsComponent = this;
    this.socket.on("connect", function () {
      console.log("socket connected success fully");
      smsComponent.getActiveStartConversation();
    });
    this.socket.on("message", function (data) {
      let patternString =
        data.pattern !== undefined
          ? data.pattern.replace(/\*/g, "").toUpperCase()
          : undefined;
      console.log(
        patternString,
        START_CONVERSATION().toUpperCase(),
        data
      );
      if (data.channel === "START_CONVERSATION_LIST") {
        let that = smsComponent;
        let conversationList = data.message;
        conversationList.forEach((element) => {
          that.processConversation(element, element.uuid);
        });
      } else if (
        data.pattern !== undefined &&
        patternString === START_CONVERSATION().toUpperCase()
      ) {
        smsComponent.processChatData(data);
      } else if (
        data.pattern !== undefined &&
        patternString === VOIX_AI_RESPONSE().toUpperCase()
      ) {
        smsComponent.processVoixResponse(data);
        console.log(smsComponent.listOfSockets);
      } else if (
        data.pattern !== undefined &&
        patternString === CUSTOMER_REQUEST().toUpperCase()
      ) {
        smsComponent.processCustomerResponse(data);
      } else if (
        data.pattern !== undefined &&
        patternString === END_CONVERSATION().toUpperCase()
      ) {
        smsComponent.endConversation(data);
      } else if (
        data.pattern !== undefined &&
        patternString === AGENT_ESCALATE().toUpperCase()
      ) {
        smsComponent.esclate(data);
      } else if (
        data.pattern !== undefined &&
        patternString === AGENT_ASSIGNED().toUpperCase()
      ) {
        smsComponent.assignToAgent(data);
      } else if (
        data.pattern !== undefined &&
        patternString === VOIX_AGENT_RESPONSE().toUpperCase()
      ) {
        smsComponent.processCustomerResponse(data);
      }
    });

    this.auth.userDetail().subscribe((agent: any) => {
      if (agent && agent.success) {
        this.agentDetails = {
          agent_id: agent.user.id,
          agent_email: agent.user.email,
        };
      }
    });
  }

  connection(obj = {}) {
    const connectionObj = Object.assign({ channel: "sms", hash: uuid() }, obj);
    const queryString = this.generalService.serialize(connectionObj);
    return io(environment.io_url, {
      query: queryString,
      transports: ["polling"],
    });
  }

  assignToAgent(data) {
    const response = JSON.parse(data.message);
    if (
      response &&
      response.assignedAgent !== undefined &&
      !_.isEmpty(response.assignedAgent)
    ) {
      const chatListIndex = this.getlistOfContactIndex(response.customer_phone);
      const chatWindowIndex = this.getChatWindowIndex(response.customer_phone);
      if (chatListIndex >= 0) {
        this.listOfContacts[chatListIndex].assignedAgent =
          response.assignedAgent;
      }
      if (chatWindowIndex >= 0) {
        this.chatWindows[chatWindowIndex].assignedAgent =
          response.assignedAgent;
      }
      this.listOfContacts[chatListIndex].esclate = false;
      this.chatWindows[chatWindowIndex].esclate = false;
      this.chatWindows[chatWindowIndex].playback = false;
    }
  }

  esclate(data) {
    const response = JSON.parse(data.message);
    if (response && response.agentEscalate) {
      const chatListIndex = this.getlistOfContactIndex(response.customerPhone);
      const chatWindowIndex = this.getChatWindowIndex(response.customerPhone);
      if (chatListIndex >= 0) {
        this.listOfContacts[chatListIndex].esclate =
          response.agentEscalate.escalate;
        this.listOfContacts[chatListIndex].uuid = data.id;
      }
      if (chatWindowIndex >= 0) {
        this.chatWindows[chatWindowIndex].esclate =
          response.agentEscalate.escalate;
        this.chatWindows[chatWindowIndex].uuid = data.id;
      }
    }
  }

  endConversation(data) {
    const response = JSON.parse(data.message);
    if (response) {
      const chatListIndex = this.getlistOfContactIndex(response.customerPhone);
      if (chatListIndex >= 0) {
        this.listOfContacts[chatListIndex].endConversation = true;
      }
    }
  }

  processCustomerResponse(data) {
    const response = JSON.parse(data.message);
    if (this.chatWindows.length > 0) {
      const chatWindowIndex = this.getChatWindowIndex(response.customerPhone);
      // tslint:disable-next-line:max-line-length
      const customerText =
        response &&
        response.voixADResponse !== undefined &&
        !_.isEmpty(response.voixADResponse)
          ? response.voixADResponse
          : response.customerRequest;
      if (
        chatWindowIndex >= 0 &&
        response &&
        response.voixADResponse !== undefined &&
        !_.isEmpty(response.voixADResponse)
      ) {
        console.log("processCustomerResponse ---------", response);
        this.chatWindows[chatWindowIndex].conversationData.push({
          text: customerText,
          name: "ai",
        });
      } else if (chatWindowIndex >= 0) {
        this.chatWindows[chatWindowIndex].conversationData.push({
          text: customerText,
          name: "customer",
        });
      }
    }

    const chatListIndex = this.getlistOfContactIndex(
      response.customerPhone.toString()
    );
    if (chatListIndex >= 0) {
      this.listOfContacts[chatListIndex].isOnline = true;
    }
    this.setCountDown(response);
  }

  processVoixResponse(data) {
    const response = JSON.parse(data.message);
    let responseText = null;
    let playBack = true;
    // tslint:disable-next-line:max-line-length
    if (
      response &&
      response.voixAIResponse.dialogAction &&
      response.voixAIResponse.dialogAction.message &&
      !_.isEmpty(response.voixAIResponse.dialogAction.message.content)
    ) {
      responseText = response.voixAIResponse.dialogAction.message.content;
    }

    // tslint:disable-next-line:max-line-length
    if (
      response &&
      response.voixAIResponse.dialogAction &&
      response.voixAIResponse.dialogAction.playback
    ) {
      playBack = response.voixAIResponse.dialogAction.playback;
    }

    if (this.chatWindows.length > 0) {
      const chatWindowIndex = this.getChatWindowIndex(response.customerPhone);
      if (chatWindowIndex >= 0) {
        console.log("processVoixResponse ------------------", response);
        this.chatWindows[chatWindowIndex].conversationData.push({
          text: responseText,
          name: "ai",
        });
        this.chatWindows[chatWindowIndex].playback = playBack;
        this.retriveOrderDetails(
          data.id,
          response.restaurantId,
          response.customerPhone
        );
      }
    }
    const chatListIndex = this.getlistOfContactIndex(
      response.customerPhone.toString()
    );
    if (chatListIndex >= 0 && response.customerName !== null) {
      this.listOfContacts[chatListIndex].customer_name = response.customerName;
    }
  }

  getChatWindowIndex(customerPhoneNumber) {
    return _.findIndex(this.chatWindows, {
      customer_phone: customerPhoneNumber,
    });
  }

  getlistOfContactIndex(customerPhoneNumber) {
    return _.findIndex(this.listOfContacts, {
      customer_phone: customerPhoneNumber,
    });
  }

  processChatData(data) {
    console.log(data);
    // contact list update
    const contactDetails = JSON.parse(data.message);
    this.processConversation(contactDetails, data.id);
  }

  processConversation(contactDetails, uuid) {
    // tslint:disable-next-line:prefer-const
    let contactInfo: any = {
      customer_phone: contactDetails.customerPhone.toString(),
      // tslint:disable-next-line:radix
      restaurant_id: parseInt(contactDetails.restaurantId),
      uuid: null,
      restaurant_phone: contactDetails.restaurantPhone.toString(),
      name: contactDetails.restaurantName,
      esclate: false,
      endConversation: false,
      assignedAgent: { agent_email: null },
      isOnline: true,
      playback: true,
    };

    // tslint:disable-next-line:max-line-length
    const isContactExist = _.findWhere(this.listOfContacts, {
      customer_phone: contactInfo.customer_phone,
      restaurant_id: contactInfo.restaurant_id,
    });
    if (_.isEmpty(isContactExist)) {
      contactInfo.uuid = uuid;
      contactInfo.customer_name = contactDetails.customerName || null;
      this.listOfContacts.push(contactInfo);
    }

    const chatListIndex = this.getlistOfContactIndex(
      contactDetails.customerPhone.toString()
    );
    if (chatListIndex >= 0) {
      this.listOfContacts[chatListIndex].endConversation = false;
      this.listOfContacts[chatListIndex].assignedAgent = { agent_email: null };
      this.listOfContacts[chatListIndex].uuid = uuid;
    }

    if (this.chatWindows.length > 0) {
      const chatWindowIndex = this.getChatWindowIndex(
        contactDetails.customerPhone
      );
      if (chatWindowIndex >= 0) {
        this.chatWindows[chatWindowIndex].orderDetails = {};
        this.chatWindows[chatWindowIndex].playback = true;
        this.chatWindows[chatWindowIndex].uuid = uuid;
      }
    }
    this.setCountDown({ customerPhone: contactDetails.customerPhone });
  }

  openChatWindow(obj) {
    if (this.chatWindows.length < 6) {
      const tabWindow = obj;
      const isTabWindowOpen = _.findWhere(this.chatWindows, {
        customer_phone: obj.customer_phone,
      });
      if (_.isEmpty(isTabWindowOpen)) {
        tabWindow.conversationData = [];
        tabWindow.loader = true;
        this.chatWindows.push(tabWindow);
        this.listOfSockets.push(this.connection({ smsToken: obj.uuid }));
        this.checkSocketReceiver();
      }
      this.getConversation(tabWindow);
      this.retriveOrderDetails(obj.uuid, obj.restaurant_id, obj.customer_phone);
    } else {
      this.notification.show(
        "danger",
        "notifications",
        "Not allow to open more than 3 window",
        "center"
      );
    }
  }

  closeConversation(index) {
    if (index !== -1) {
      this.listOfSockets[index].close();
      this.chatWindows.splice(index, 1);
    }
  }

  getConversation(tabWindowObj) {
    // tslint:disable-next-line:max-line-length
    this.voixHttp
      .get("v1/agent/restaurant/" + tabWindowObj.restaurant_id + "/calls", {
        params: {
          last_hour: true,
          customer_phone: tabWindowObj.customer_phone,
          limit: 1000,
          orderby: "updated_at",
          order: "asc",
        },
      })
      .subscribe((response: any) => {
        const chatWindowIndex = this.getChatWindowIndex(
          tabWindowObj.customer_phone
        );
        this.chatWindows[chatWindowIndex].loader = false;
        this.chatWindows[chatWindowIndex].conversationData = [];
        response.data.forEach((element) => {
          let responseObj = {};
          if (!_.isEmpty(element.voix_response)) {
            responseObj = { text: element.voix_response, name: "ai" };
          }
          if (!_.isEmpty(element.input_transcript)) {
            responseObj = { text: element.input_transcript, name: "customer" };
          }
          if (chatWindowIndex >= 0 && !_.isEmpty(responseObj)) {
            this.chatWindows[chatWindowIndex].conversationData.push(
              responseObj
            );
          }
          this.retriveOrderDetails(
            tabWindowObj.uuid,
            tabWindowObj.restaurant_id,
            tabWindowObj.customer_phone
          );
        });
      });
  }

  assignToMe(item: any) {
    item.assignedAgent = this.agentDetails;
    const objData = { id: item.uuid, channel: "assignToMe", message: item };
    this.socket.send(objData);
  }

  retriveOrderDetails(uuid: any, restaurant_id: any, customer_phone: any) {
    // tslint:disable-next-line:max-line-length
    const objData = {
      id: uuid,
      channel: "orderDetails",
      message: { restaurant_id: restaurant_id, customer_phone: customer_phone },
    };
    this.socket.send(objData);
  }

  sendSMS(item) {
    item.channel = "sms";
    if (item.customer_phone.length > 14 && item.restaurant_phone > 14) {
      item.channel = "messenger";
    }
    console.log(item);
    const objData = {
      id: item.uuid,
      channel: "voixAgentResponse",
      message: item,
    };
    this.socket.send(objData);
  }

  getActiveStartConversation() {
    const objData = { channel: "listOfStartConversation" };
    this.socket.send(objData);
  }

  prepareChatWindowsOrder(data) {
    let response = data.message;
    console.log(response);
    if (this.chatWindows.length > 0) {
      const chatWindowIndex = this.getChatWindowIndex(response.customer_phone);
      if (chatWindowIndex >= 0) {
        this.chatWindows[chatWindowIndex].orderDetails = response;
      }
    }
  }

  checkSocketReceiver() {
    const smsComponent = this;
    this.listOfSockets.forEach((element) => {
      element.on("message", function (data) {
        if (data.channel === "ORDER_DETAILS") {
          smsComponent.prepareChatWindowsOrder(data);
        }
        console.log(data);
      });
    });
  }

  setCountDown(response: any) {
    let that = this;
    setTimeout(function () {
      const chatListIndex = that.getlistOfContactIndex(
        response.customerPhone.toString()
      );
      if (chatListIndex >= 0) {
        that.listOfContacts[chatListIndex].isOnline = false;
      }
    }, 300000);
  }
}
