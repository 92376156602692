import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Select } from "@ngxs/store";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import {
  frequentOrderState,
  frequentItemDefaultState,
} from "app/core/ngxs/state/frequentOrder.state";
import {
  pastOrderState,
  pastOrderDefaultState,
} from "app/core/ngxs/state/pastOrder.state";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-orders",
  templateUrl: "./customer-orders.component.html",
  styleUrls: ["../customer/customer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerOrdersComponent implements OnInit {
  @Select(frequentOrderState.getFrequentItems)
  frequentOrders: Observable<frequentItemDefaultState>;
  @Select(cartState.getCart) cartState: Observable<cartDefaultState>;
  @Select(pastOrderState.getPastOrder)
  pastOrders: Observable<pastOrderDefaultState>;
  isShowed: any = [];

  cartMenuItems: any[] = [];

  constructor(public emitTrans: emitTransactionsService) {
    this.cartState.subscribe((res: any) => {
      if (res) {
        // console.log(res);
        this.cartMenuItems = res.cartMenuItems ? res.cartMenuItems : [];
      }
    });
    // console.log(this.cartMenuItems);
  }

  ngOnInit() {}
  getDefault(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.isDefault && data.alterationCategory
    );
    return data.length;
  }
  getCustomisation(modifiers: any) {
    let data = modifiers.filter((data: any) => data.isCustomised);
    return data.length;
  }
  filterItemsOfType(modifier: any[], item: any) {
    const data = modifier.filter((data: any) => data.productSubCategory);
    // console.log(data);
    return data;
  }
  getAddons(modifier: any[]) {
    const data = modifier.filter((data: any) => data.alterationQuantity);
    // console.log(data);
    return data;
  }
  showDetails(index: number) {
    if (this.isShowed.includes(index)) {
      this.isShowed = this.isShowed.filter((data: any) => data != index);
    } else {
      this.isShowed.push(index);
    }
  }

  addItemToCart(cartItem: any, type?: string) {
    if (type === 'all-frequent-orders') {
      cartItem = cartItem.map(item => item?.cartMenuItem);
    }
    this.emitTrans.addItemtocart(cartItem);
  }
}
