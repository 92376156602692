import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../../../../mediator-commlibs/interfaces/request/restaurantSetting.interface";

@Component({
  selector: "app-message-dialog",
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>{{ data.message }}</mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
  styleUrls: ["./message-dialog.component.scss"]
})
export class MessageDialogComponent {
   constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
