import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent,
} from "@angular/common";
// import "rxjs/add/operator/filter";
import { NavbarComponent } from "../../components/navbar/navbar.component";
import { NavbarService } from "../../core/navbar/navbar.service";
import {
  Router,
  NavigationEnd,
  NavigationStart,
  ActivatedRoute,
} from "@angular/router";
// import { Subscription } from "rxjs/Subscription";
import PerfectScrollbar from "perfect-scrollbar";
import { alertNotificationService } from "../../core/services/alert-notifcation.service";
import * as _ from "underscore";
import { AuthenticationService } from "../../core/auth/authentication.service";
import { Subscription } from "rxjs";
import { filter } from "underscore";
import { map, takeWhile } from 'rxjs/operators';

declare const $: any;
@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  public mediaErrors: any = [];
  public isNotificationOn: boolean = false;
  private _router: Subscription;
  private lastPoppedUrl: string;
  isOpenDrawer = false;
  isNotificationDrawerOpened = true;
  private yScrollStack: number[] = [];
  isOrderRecive = false;
  isComponentAlive = true;
  _: any = _;
  conversationSoFar = [];
  notifications = [];
  @ViewChild(NavbarComponent) navbar: NavbarComponent;
  constructor(
    public location: Location,
    public navbarService: NavbarService,
    private router: Router,
    private route: ActivatedRoute,
    private alertNotification: alertNotificationService,
    private cdRef: ChangeDetectorRef,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.navbarService.conversationSoFar$.subscribe(conversations => {
      this.conversationSoFar = conversations;
    });
    this.navbarService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });
    this.navbarService.chatDrawer$.pipe(takeWhile(() => this.isComponentAlive)).subscribe(value => {
      this.isOpenDrawer = value;
    });

    this.navbarService.notificationDrawer$.pipe(takeWhile(() => this.isComponentAlive)).subscribe(value => {
      this.isNotificationDrawerOpened = value;
    });

    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      this.navbar.sidebarClose();
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });

    //filter((event) => event instanceof NavigationEnd)
    this._router = this.router.events.pipe(map(data => data instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (elemMainPanel) {
          elemMainPanel.scrollTop = 0;
        }
        if (elemSidebar) {
          elemSidebar.scrollTop = 0;
        }
      });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      //console.log(elemSidebar);
      if (elemSidebar != null) {
        ps = new PerfectScrollbar(elemSidebar);
      }
    }

    var adminComponent = this;
    this.alertNotification.get$.subscribe(function (notification) {
      if (!notification.error) {
        adminComponent.mediaErrors = _.reject(
          adminComponent.mediaErrors,
          function (d) {
            return d.value === notification.value;
          }
        );
      } else {
        adminComponent.isNotificationOn = notification.error;
        adminComponent.mediaErrors.push(notification);
      }
      
      adminComponent.isNotificationOn =
        adminComponent.mediaErrors.length <= 0 ? false : true;
      if (adminComponent.isNotificationOn) {
        var parentAdminComponent = adminComponent;
        adminComponent.auth
          .updateAgentStatus("OFFLINE")
          .subscribe((response) => {
            parentAdminComponent.navbar.getAgentAvailability();
          });
      }
    });

    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
    var adminComponent = this;
    setTimeout(function () {
      adminComponent.checkNotification();
    }, 500);
  }

  checkNotification() {
    // var permission = Notification.permission;
    // if (!("Notification" in window)) {
    //   this.alertNotification.set({text:"This browser does not support desktop notification", value: 'notification', error: true});
    // }
    // if(permission == 'denied') {
    //   this.alertNotification.set({text:"This browser desktop notification is denied", value: 'notification', error: true});
    // }
  }

  onClosedChat() {
    this.navbarService.emitDrawerToggle(false);
  }
  
  onClosedNotification() {
    this.navbarService.emitNotificationDrawerToggle(false);
  }

  isMaps(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    } else {
      return true;
    }
  }
  closeNotification() {
    this.isNotificationOn = false;
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  getErrorList() {
    var errorMsgTextArray;
    errorMsgTextArray = _.pluck(this.mediaErrors, "text");
    return _.uniq(errorMsgTextArray).join(", ");
  }
  ngOnDestroy(): void {
    this.isComponentAlive = false;
  }
}
