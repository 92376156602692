import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { ActionAddSelectedItem } from "app/core/ngxs/action/menu.action";
import { cartState, cartDefaultState } from "app/core/ngxs/state/cart.state";
import { menuState, menuDefaultState } from "app/core/ngxs/state/menu.state";
import { menuSelectionState } from "app/core/ngxs/state/menuSelection.state";
import { NotificationService } from "app/core/notification/notification.service";
import { CryptoService } from "app/core/services/crypto.service";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { menuService } from "app/core/services/menu.service";

import { Observable } from "rxjs";

import * as _ from "underscore";
import { AddOn_Variations } from "../../../../mediator-commlibs/interfaces/response/menu.interface";
import { generalService } from "app/core/services/general.service";
import { Modifier } from "../../../../mediator-commlibs/interfaces/request/cartItem.interface";

@Component({
  selector: "app-order-items-replica",
  templateUrl: "./order-items-replica.component.html",
  styleUrls: ["./order-items-replica.component.scss"],
})
export class OrderItemsReplicaComponent implements OnInit {
  @Select(menuState.getSelectedItem)
  selectedMenuItem: Observable<menuDefaultState>;
  @Select(menuState.getSelectedCollection)
  selectedMenuCollections: Observable<menuDefaultState>;
  @Select(menuSelectionState.getSelectedModifiers)
  selectedMenuOptions: Observable<menuSelectionState>;
  @Select(cartState.getLoadedCart)
  cartLoadedState: Observable<cartDefaultState>;
  @Select(menuState.getMenu2)
  menuDetails: Observable<menuDefaultState>;
  menuV2Temp: any;
  @Input() restaurant_details: any;
  @Input() menusV2: any;

  _: any = _;
  cartItem: any = {};
  isQuantityValid: boolean = false;
  selectedCategory: any = {};
  items: any = [];
  modifiers: any = [];
  collections: any = [];
  collectionsModifiers: any = [];
  collectionsModifierscollections: any = [];
  collectionsModifierscollectionsmodifiers2: any = [];
  collectionsModifierscollectionsmodifierscollections: any = [];
  selectedAddons: any = [];
  isDefaultModifiers: any = [];
  selectedCollections: any = {};
  selectedAvailableQuantity: string = "";
  menuItemsV2: any;
  isQuantityShow: boolean = false;
  selectedToppings: string = "";
  availableQuantities: any = [];
  WHOLE: string = "WHOLE";
  LEFT_HALF: string = "LEFT_HALF";
  RIGHT_HALF: string = "RIGHT_HALF";
  selectedQuantity: any = [];
  notToppings: any = [];
  alterationCategoryName = "TOPPINGS";
  topping_quantity: number = 0;
  modifiersCollection: any = [];
  selectedModifier: any = {};
  toppings: any = [];
  notAllowMultiple: any = [];
  isQuantityShowed: boolean = false;
  selectedProductQuantity: any;
  selectedItem: any = {};
  selectedCollectionsModifierscollections: any = {};

  quantity: any = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
    {
      name: "4",
      value: 4,
    },
    {
      name: "5",
      value: 5,
    },
    {
      name: "6",
      value: 6,
    },
    {
      name: "7",
      value: 7,
    },
    {
      name: "8",
      value: 8,
    },
    {
      name: "9",
      value: 9,
    },
    {
      name: "10",
      value: 10,
    },
  ];
  quantityHalf: any = [
    {
      name: "HALF",
      value: 0.5,
    },
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
    {
      name: "4",
      value: 4,
    },
    {
      name: "5",
      value: 5,
    },
    {
      name: "6",
      value: 6,
    },
    {
      name: "7",
      value: 7,
    },
    {
      name: "8",
      value: 8,
    },
    {
      name: "9",
      value: 9,
    },
    {
      name: "10",
      value: 10,
    },
  ];
  productQuantity: number = 1;
  collmodifiers2: any = [];
  selectedHalfSize: string = "";
  halfStyle: any;
  validationItem: any;
  selectedCatHalf: any;
  isEdit: boolean;
  available: boolean;
  editModifer: any;
  isSingleProductEdit: boolean = false;
  @Output() noteEvent = new EventEmitter<object>();

  constructor(
    private menuservice: menuService,
    private ngxsStore: Store,
    private notification: NotificationService,
    private cryptoService: CryptoService,
    private emitTrans: emitTransactionsService,
    private _generalService: generalService
  ) {
    this.menuDetails.subscribe((data: any) => {
      if (data) {
        this.menuV2Temp = data;
      }
    });
    this.menuservice.secondFormReset.subscribe((data) => {
      if (data) this.isSingleProductEdit = false;
    });
    this.menuservice.EditItem.subscribe((data: any) => {
      if (data) {
        // console.log(data);
        this.isSingleProductEdit = data.isSingleProductEdit;
        if (this.isSingleProductEdit) {
          this.removeAllV2(false);
        }
        if (
          data.product.right_half_menu &&
          data.product.right_half_menu.length > 0
        ) {
          this.isEdit = data.isEdit;
          this.editModifer = data.product.right_modifiers.filter(
            (item: any) => item.refCode && !item.refCode.startsWith("-")
          );
          this.menusV2 = data.product.right_half_menu;
          this.menusV2.forEach((element) => {
            if (element.isDefault) {
              this.selectItemV2(element, false);
            }
          });
        }
      }
    });

    this.menuservice.removeLeftFormSelectedItems.subscribe((res: boolean) => {
      if(res) {
        this.removeAllV2(false);
      }
    })
    this.menuservice.halfMenu.subscribe((res: any) => {
      if (res) {
        this.removeAllV2(true);
        // console.log(res.categories);
        this.isQuantityShow = false;
        this.quantityHalf = [];
        this.menusV2 = res.right_menu;
        const selectedCategory = this.menusV2.filter(
          (category) => category.isAvailable
        );
        this.productQuantity = 0.5;
        this.allowHalf = true;
        if (selectedCategory.length && selectedCategory.length === 1) {
          this.selectItemV2(selectedCategory[0], false);
        }
        this.sendtoservice();
      }
    });
    this.selectedMenuOptions.subscribe((data: any) => {
      if (data) {
        this.selectedHalfSize =
          this.cryptoService.DecryptWithoutPromise("sizer");
        this.halfStyle =
          this.cryptoService.DecryptWithoutPromise("styleItemStyle");
        this.collmodifiers2 =
          this.cryptoService.DecryptWithoutPromise("sessionmodifiers");
        this.validationItem = this.cryptoService.DecryptWithoutPromise("Item");
        this.cartItem = data.cartItem2;
        this.selectedItem = data.selectedItem2;
        this.isQuantityValid = data.isQuantityValid2;
        this.selectedCategory = data.selectedCategory2;

        this.items = data.items2;
        this.modifiers = data.modifiers2;
        this.collections = [];
        this.collectionsModifiers = data.collectionsModifiers2;
        this.collectionsModifierscollections = [];
        (this.collmodifiers2 = []),
          (this.collectionsModifierscollectionsmodifierscollections = []);
        this.selectedAddons = data.selectedAddons2;
        this.isDefaultModifiers = data.isDefaultModifiers2;
        this.selectedCollections = data.selectedCollections2;
        this.selectedAvailableQuantity = data.selectedAvailableQuantity2;
        this.menusV2 = data.menuItemsV22;
        this.isQuantityShow = data.isQuantityShow2;
        this.selectedToppings = data.selectedToppings2;
        this.availableQuantities = data.availableQuantities2.filter(() => true);
        this.WHOLE = data.WHOLE2;
        this.selectedStyle2 = data.selectedStyle2;
        this.LEFT_HALF = data.LEFT_HALF2;
        this.RIGHT_HALF = data.RIGHT_HALF2;
        this.allowHalf = data.allowHalf2;
        this.selectedQuantity = data.selectedQuantity2;
        this.notToppings = data.notToppings2;
        this.alterationCategoryName = data.alterationCategoryName2;
        this.topping_quantity = data.topping_quantity2;
        this.toppings = data.toppings2;
        this.notAllowMultiple = data.notAllowMultiple2;
        this.isQuantityShowed = data.isQuantityShowed2;
        this.selectedProductQuantity = data.selectedProductQuantity2;
        this.quantity = data.quantity2;
        this.productQuantity = data.allowHalf2 ? 0.5 : data.productQuantity2;
        this.quantityHalf = data.allowHalf2 ? [] : this.quantity;
      }
      console.log(this.selectedHalfSize);
      // this.cryptoService.Encrypting("selectedCat", this.selectedCategory);
      // console.log(this.selectedHalfSize);
    });
    this.sendtoservice();
  }

  ngOnInit() {
    this.menuservice.removecart.subscribe(
      (data) => {
        if (data) {
          this.removeAllV2(false);
        }
      },
      (errr) => {},
      () => this.menuservice.removecart.next(false)
    );
    this.sendtoservice();
  }

  sendtoservice() {
    this.menuservice.allDataSecond.next({
      cartItem2: this.cartItem,
      selectedItem2: this.selectedItem,
      isQuantityValid2: this.isQuantityValid,
      selectedCategory2: this.selectedCategory,
      items2: this.items,
      modifiers2: this.modifiers,
      collections2: this.collections,
      collectionsModifiers2: this.collectionsModifiers,
      collectionsModifierscollections2: this.collectionsModifierscollections,
      collectionsModifierscollectionsmodifiers2: this.collmodifiers2,
      collectionsModifierscollectionsmodifierscollections2:
        this.collectionsModifierscollectionsmodifierscollections,
      selectedAddons2: this.selectedAddons,
      isDefaultModifiers2: this.isDefaultModifiers,
      selectedCollections2: this.selectedCollections,
      selectedAvailableQuantity2: this.selectedAvailableQuantity,
      menuItemsV22: this.menuItemsV2,
      isQuantityShow2: this.isQuantityShow,
      selectedToppings2: this.selectedToppings,
      availableQuantities2: this.availableQuantities,
      WHOLE2: this.WHOLE,
      LEFT_HALF2: this.LEFT_HALF,
      RIGHT_HALF2: this.RIGHT_HALF,
      allowHalf2: this.allowHalf,
      selectedStyle2: this.selectedStyle2,
      selectedQuantity2: this.selectedQuantity,
      notToppings2: this.notToppings,
      alterationCategoryName2: this.alterationCategoryName,
      topping_quantity2: this.topping_quantity,
      toppings2: this.toppings,
      notAllowMultiple2: this.notAllowMultiple,
      isQuantityShowed2: this.isQuantityShowed,
      selectedProductQuantity2: this.selectedProductQuantity,
      quantity2: this.quantity,
      productQuantity2: this.productQuantity,
    });
  }
  selectToppings(item: any, index: number) {
    this.sendtoservice();
  }
  selectProductQuantity(quantity: any) {
    this.productQuantity = quantity;
    this.sendtoservice();
  }

  addToCartV2(selectedItem: any) {
    this.cartItem = Object.assign({}, selectedItem);
    this.cartItem.modifiers[0].collections = [this.selectedCollections.slice()];
    this.sendtoservice();
  }

  //Menu Item
  selectSizeV2(item: any, verified: boolean) {
    const selectedMenuItems = this.selectedCategory.items.filter(
      (val) => val.isAvailable
    );
    if (
      selectedMenuItems.length &&
      selectedMenuItems.length === 1 &&
      this.allowHalf
    ) {
      this.selectedItem = selectedMenuItems[0];
      this.modifiers = selectedMenuItems[0].modifiers;
      this.collections = selectedMenuItems[0].modifiers[0].collections;
      this.selectStyleVariationV2(this.collections[0], false);
      return;
    }
    if (!verified) {
      for (let index = 0; index < item.items.length; index++) {
        const element = item.items[index];
        if (element.isDefault || item.items.length === 1) {
          this.selectedItem = element;
          this.modifiers = element.modifiers;
          this.collections = element.modifiers[0].collections;
        }
      }
    } else {
      this.ngxsStore.dispatch(new ActionAddSelectedItem(item));
      this.selectedItem = item;
      this.modifiers = item.modifiers;
      this.collections = item.modifiers[0].collections;
      if (this.allowHalf) {
        this.selectStyleVariationV2(item, false);
        return;
      }
    }
    let isDefault: boolean = false;
    if (this.collections[0]?.isDefault && this.collections.length == 1) {
      this.selectStyleVariationV2(this.collections[0], false);
      isDefault = true;
    }
    if (this.productQuantity != 0.5) {
      this.quantityHalf = this.quantity;
    }
    if (!this.isEdit) {
      if (isDefault == false) {
        this.removeFromStyleVariationV2(verified);
      }
    } else {
      this.collections.forEach((element) => {
        if (element.isDefault) {
          this.selectStyleVariationV2(element, false);
          isDefault = true;
        }
      });
    }
    this.sendtoservice();
  }

  removeAllV2(isHalf: boolean) {
    this.noteEvent.emit({
      note: "",
      isLeft: false,
    });
    this.selectedItem = {};
    this.selectedCategory = {};
    this.modifiersCollection = [];
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.selectedModifier = {};
    this.items = [];
    this.modifiers = [];
    this.collections = [];
    this.collmodifiers2 = [];
    this.collectionsModifiers = [];
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers2 = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.cartItem = {};
    this.isEdit = false;
    this.isDefaultModifiers = [];
    this.allowHalf = isHalf;
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle2 = undefined;
    if (!isHalf) {
      this.emitTrans.getMenu$.subscribe((menus: any) => {
        if (menus) {
          this.menusV2 = [];

          setTimeout(() => {
            this.menusV2 = menus;
          }, 0);
        }
      });
    }
    this.sendtoservice();
  }
  showdata() {
    const name = {
      items: this.items,
      modifiers: this.modifiers,
      collections: this.collections,
      collectionsModifiers: this.collectionsModifiers,
      collectionsModifierscollections: this.collectionsModifierscollections,
      collectionsModifierscollectionsmodifiers:
        this.collectionsModifierscollectionsmodifiers2,
      availableQuantities: this.availableQuantities,
      collectionsModifierscollectionsmodifierscollections:
        this.collectionsModifierscollectionsmodifierscollections,
      cartItem: this.cartItem,
      isDefaultModifiers: this.isDefaultModifiers,
      selectedCategory: this.selectedCategory,
      selectedCollections: this.selectedCollections,
      isQuantityShow: this.isQuantityShow,
      isQuantityShowed: this.isQuantityShowed,
      collmodifiers2: this.collmodifiers2,
    };
    console.log(name);
  }
  removeFromItemsV2() {
    this.modifiers = [];
    this.collections = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collmodifiers2 = [];
    this.collectionsModifiers = [];
    this.selectedItem = {};
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers2 = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.cartItem = {};
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle2 = undefined;
    this.sendtoservice();
  }

  removeFromSizeVariationV2() {
    this.modifiers = [];
    this.collectionsModifiers = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collmodifiers2 = [];
    this.collectionsModifierscollections = [];
    this.collectionsModifierscollectionsmodifiers2 = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.notAllowMultiple = [];
    this.toppings = [];
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle2 = undefined;
    this.selectedCollections = {};
    this.selectedCollectionsModifierscollections = {};
    this.sendtoservice();
  }

  removeFromStyleVariationV2(verified) {
    if (verified) {
      this.selectedCollectionsModifierscollections = {};
    }
    this.collectionsModifiers = [];
    this.modifiersCollection = [];
    this.selectedModifier = {};
    this.collmodifiers2 = [];
    this.collectionsModifierscollectionsmodifiers2 = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.toppings = [];
    this.notAllowMultiple = [];
    this.isQuantityShow = false;
    this.isQuantityShowed = false;
    this.selectedStyle2 = undefined;
    this.sendtoservice();
  }

  removeFromAddonsV2() {
    this.collectionsModifierscollectionsmodifiers2 = [];
    this.collmodifiers2 = [];
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.notToppings = [];
    this.isQuantityShow = false;
    this.notAllowMultiple = [];
    this.isQuantityShowed = false;
    this.toppings = [];
    this.selectedStyle2 = undefined;
    this.sendtoservice();
  }

  removeFromAvailableQuantityV2() {
    this.availableQuantities = [];
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.isQuantityShow = false;
    this.sendtoservice();
  }

  removeFromAddonsVariationsV2() {
    this.collectionsModifierscollectionsmodifierscollections = [];
    this.sendtoservice();
  }

  removeAddonsV2() {
    this.cartItem.modifiers[0].collections[0].modifiers[0].collections = [];
    this.sendtoservice();
  }

  selectItemV2(category: any, verified: boolean) {
    this.selectedCategory = category;
    this.items = category.items;
    if (!verified || this.items.length === 1) {
      this.selectSizeV2(this.selectedCategory, false);
    } else {
      this.removeFromSizeVariationV2();
    }

    this.sendtoservice();
  }
  singleProductErrorShow() {
    this.notification.openSnackBarV2(
      "end",
      "top",
      `You Cannot Enable This Form Please Click Left Form Close Button`,
      "danger-snackbar"
    );
  }
  //SIZE Variations
  selectStyleVariationV2(collection: any, verified: boolean) {
    const selectedCollections = this.collections.filter(
      (val) => val.isAvailable
    );
    if (
      selectedCollections.length &&
      selectedCollections.length === 1 &&
      this.allowHalf
    ) {
      this.selectedCollections = selectedCollections[0];
      const selectedCollectionsModifierscollections =
        this.selectedCollections.modifiers[0].collections.filter(
          (val) => val.isAvailable
        );
      if (
        selectedCollectionsModifierscollections.length &&
        selectedCollectionsModifierscollections.length === 1
      ) {
        this.selectedCollectionsModifierscollections =
          selectedCollectionsModifierscollections[0];
        this.collectionsModifierscollections =
          this.selectedCollections.modifiers[0].collections.filter((n) => n);
        this.selectAddOnsV2(
          this.selectedCollectionsModifierscollections,
          false
        );
      }
      return;
    }
    this.availableQuantities = [];
    while (this.availableQuantities.length > 0) {
      this.availableQuantities.pop();
    }
    this.collections = this.collections.filter(
      (data: any, i: any) => data.displayName != collection.displayName
    );
    this.collections.unshift(collection);
    this.collectionsModifiers = collection.modifiers;
    this.collectionsModifierscollections =
      collection.modifiers[0].collections.filter((n) => n);
    this.selectedCollections = collection;
    let isDefault: boolean = false;

    this.collectionsModifierscollections.forEach((element) => {
      if (
        (element.isDefault &&
          this.collectionsModifierscollections.length == 1) ||
        (element.isDefault && this.isEdit)
      ) {
        this.notAllowMultiple = [];
        this.toppings = [];

        this.selectAddOnsV2(element, false);
        isDefault = true;
      }
    });
    if (isDefault == false) {
      this.removeFromAddonsV2();
    }
    this.sendtoservice();
  }
  availablePortions = {
    None: "None",
    Lite: "Lite",
    Normal: "Normal",
    Extra: "Extra",
    Side: "Side",
    Double: "Double",
    Triple: "Triple",
  };

  addExtra(
    item: AddOn_Variations,
    max: number,
    alterationQuantity: any,
    isAlterationPortionChange: boolean = false
  ) {
    alterationQuantity =
      alterationQuantity == undefined ? "" : alterationQuantity;

    const totalItem = this.availableQuantities.filter((data) => (
      (this._generalService?.isToppingsCountSeparated && item.alterationCategory == "TOPPINGS" ? data.alterationType == item.alterationType : true) &&
      (data.alterationCategory === item.alterationCategory) && (item.availablePortions?.length ? data.externalId2 != item.externalId2 : true)
    )).length;

    if (alterationQuantity == "") {
      if (item.availableQuantities.length > 0) {
        item.alterationQuantity = item.availableQuantities[0];
        alterationQuantity = item.alterationQuantity;
      }
    }
    if (!alterationQuantity) {
      if (
        this.allowHalf &&
        this.isEdit &&
        item.alterationCategory == "TOPPINGS"
      ) {
        item.alterationQuantity = this.RIGHT_HALF;
      }
      this.setAvailableQuantity(item, false, max, totalItem);
    } else {
      if (
        this.allowHalf &&
        this.isEdit &&
        item.alterationCategory == "TOPPINGS"
      ) {
        item.alterationQuantity = alterationQuantity;
      }
      this.setAvailableQuantity(
        item,
        isAlterationPortionChange,
        max,
        totalItem
      );
    }

    this.sendtoservice();
    this.updateModifier();
  }

  setAvailableQuantity(
    item: AddOn_Variations,
    isAlterationPortionChange: boolean = false,
    max: number | null,
    totalItem: number
  ) {
    const availablePortionsInItems = item.availablePortions || [];
    const totalCountOfSelectedItem: number = this.availableQuantities.filter(
      (data: Modifier) =>
        data.alterationCategory === item.alterationCategory &&
        data.externalId2 === item.externalId2 &&
        data
    ).length;

    if (item.limit && totalCountOfSelectedItem >= item.limit) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `YOU CAN ONLY SELECT ${item.limit} ITEMS`,
        "danger-snackbar"
      );
      return;
    }
    if (!max) {
      max = this.selectedStyle2.max;
    }
    // If max is defined and the totalItem is more than max return with error
    if (max && totalItem >= max) {
      this.notification.openSnackBarV2(
        "end",
        "top",
        `YOU CAN ONLY SELECT ${max} ADDONS`,
        "danger-snackbar"
      );
      return;
    }
    if (max && totalItem < max && availablePortionsInItems.length < 1) {
      this.availableQuantities.push(item);
      return;
    }

    // console.log("item.availablePortions ", item.availablePortions);

    const quantityExistIndex =
      availablePortionsInItems.length > 0
        ? this.availableQuantities.findIndex(
            (x: Modifier) =>
              x.externalId2 == item.externalId2 &&
              x.alterationPortion == item.alterationPortion &&
              x.alterationCategory === item.alterationCategory
          )
        : -1;

    if (quantityExistIndex > -1) {
      this.availableQuantities.splice(quantityExistIndex, 1);
    }

    // if the item is eligible for change of portions and there are available portions then calculate
    // the new portion to be set
    if (isAlterationPortionChange && availablePortionsInItems.length > 0) {
      const currentPortionIndex = availablePortionsInItems.findIndex(
        (arrayItem) => arrayItem === item.alterationPortion
      );
      console.log("currentPortion INdex ", currentPortionIndex);
      item.alterationPortion =
        availablePortionsInItems.length > 1
          ? currentPortionIndex === availablePortionsInItems.length - 1
            ? availablePortionsInItems[0]
            : availablePortionsInItems[currentPortionIndex + 1]
          : availablePortionsInItems[0];
    }

    this.availableQuantities.push(item);
  }

  remove(category) {
    this.availableQuantities = this.availableQuantities.filter(
      (data: any) => data.alterationCategory != category
    );
    this.sendtoservice();
    this.updateModifier();
  }
  removeExtra(item: AddOn_Variations) {
    const availableProtionsInItems = item.availablePortions || [];
    const unique =
      availableProtionsInItems.length > 0
        ? this.availableQuantities.findIndex(
            (data: Modifier) =>
              data.externalId2 == item.externalId2 &&
              data.alterationQuantity == item.alterationQuantity &&
              data.alterationPortion == item.alterationPortion &&
              data.alterationCategory === item.alterationCategory
          )
        : this.availableQuantities.findIndex(
            (data: Modifier) =>
              data.externalId2 == item.externalId2 &&
              data.alterationQuantity == item.alterationQuantity &&
              data.alterationCategory === item.alterationCategory
          );
    if (unique != -1) this.availableQuantities.splice(unique, 1);
    this.sendtoservice();
    this.updateModifier();
  }
  checkavailable(modifier: any[]) {
    return this.availableQuantities.filter((data1) =>
      modifier.some(
        (data) => data.alterationCategory == data1.alterationCategory
      )
    ).length == 0
      ? true
      : false;
  }
  checkavailable1(modifier: any) {
    if (!modifier) {
      modifier = this.selectedModifier;
    }
    this.available =
      this.availableQuantities.filter(
        (data1) => data1.alterationCategory == modifier.displayName
      ).length >= modifier.min
        ? false
        : true;
    return this.available;
  }

  check(item: AddOn_Variations) {
    const returnResponse = this.availableQuantities.slice();
    const selected =
      returnResponse
        .slice()
        .findIndex(
          (data: Modifier) =>
            data.externalId2 == item.externalId2 &&
            item.alterationQuantity &&
            data.alterationCategory === item.alterationCategory
        ) != -1
        ? true
        : false;
    if (selected) {
    }
    return selected;
  }

  getDuplicateValueCount(item: AddOn_Variations) {
    return this.availableQuantities.filter(
      (data: Modifier) =>
        !data?.isRemoved &&
        data.externalId2 == item.externalId2 &&
        data.alterationCategory === item.alterationCategory
    ).length;
  }

  changequantityname(
    CollecIndex: any,
    availQuat: any,
    item: AddOn_Variations,
    max: number,
    alterationQuantity: string | undefined
  ) {
    const modiIndex = this.collmodifiers2.findIndex(
      (modifier) => modifier.name === this.selectedModifier.name
    );
    console.log(this.collmodifiers2[modiIndex]["collections"][CollecIndex]);
    // console.log(this.collmodifiers2[modiIndex]["collections"][CollecIndex]);
    // const [first, second, third] = availQuat;
    // if (!alterationQuantity) {
    //   this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = first;
    //   this.addExtra(item, max, alterationQuantity);
    // } else if (alterationQuantity == first) {
    //   if (this.getDuplicateValueCount(item) == 0) {
    //     this.addExtra(item, max, alterationQuantity);
    //   } else {
    //     this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //       "alterationQuantity"
    //     ] = second;
    //   }
    // } else if (alterationQuantity == "") {
    //   //   console.log("EM");
    //   this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = first;
    // } else if (alterationQuantity == second) {
    //   if (this.getDuplicateValueCount(item) == 0) {
    //     this.addExtra(item, max, alterationQuantity);
    //   } else {
    //     this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //       "alterationQuantity"
    //     ] = third;
    //   }
    // } else if (alterationQuantity == third) {
    //   console.log("EM");
    //   if (this.getDuplicateValueCount(item) == 0) {
    //     this.addExtra(item, max, alterationQuantity);
    //   } else {
    //     this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //       "alterationQuantity"
    //     ] = "";
    //   }
    // } else {
    //   this.collmodifiers2[modiIndex]["collections"][CollecIndex][
    //     "alterationQuantity"
    //   ] = alterationQuantity;
    // }

    const availableQuantity = item.availableQuantities || [];
    if (availableQuantity.length > 0) {
      const currentAvailableQuantityIndex = availableQuantity.findIndex(
        (arrayItem) => arrayItem === item.alterationQuantity
      );

      this.collmodifiers2[modiIndex]["collections"][CollecIndex][
        "alterationQuantity"
      ] =
        availableQuantity.length > 1
          ? currentAvailableQuantityIndex === availableQuantity.length - 1
            ? availableQuantity[0]
            : availableQuantity[currentAvailableQuantityIndex + 1]
          : availableQuantity[0];
      if (this.getDuplicateValueCount(item) == 0) {
        this.addExtra(item, max, alterationQuantity, false);
      }
    }
    if (item.isDefault) {
      this.availableQuantities = this.availableQuantities.filter(
        (data: Modifier) => data.externalId2 != item.externalId2
      );
      this.addExtra(item, max, alterationQuantity);
    }
    this.sendtoservice();
    if (!item.alterationQuantity) {
      this.availableQuantities = this.availableQuantities.filter(
        (data) => data.externalId2 !== item.externalId2
      );
    }
    this.updateModifier();
  }
  allowHalf: boolean;
  selectedStyle2: any;
  //checkExternal ID
  checkExternalId(item: any) {
    let value: boolean = false;
    if (item.externalId1 == this.halfStyle.externalId1) {
      if (item.productSubType == this.halfStyle.productSubType) {
        value = true;
      }
    } else {
      if (item.productSubType == this.halfStyle.productSubType) {
        value = false;
      } else {
        value = true;
      }
    }
    return value;
  }
  //STYLE Variations
  selectAddOnsV2(styleVariations: any, verified: boolean) {
    if (!this.allowHalf) {
      this.productQuantity = 1;
    } else {
      this.productQuantity = 0.5;
    }

    if (this.isEdit) {
      this.allowHalf = true;
      this.productQuantity = 0.5;
    }

    this.selectedStyle2 = styleVariations;
    this.selectedCollectionsModifierscollections = this.selectedStyle2;
    while (this.availableQuantities.length > 0) {
      this.availableQuantities.pop();
    }

    this.selectedProductQuantity = styleVariations.max;

    this.collectionsModifierscollections =
      this.collectionsModifierscollections.filter(
        (data: any, i: any) => data.displayName != styleVariations.displayName
      );
    this.collectionsModifierscollectionsmodifiers2 = styleVariations.modifiers;
    this.cryptoService.Encrypting(
      "sessionmodifiers2",
      styleVariations.modifiers
    );
    this.collmodifiers2 =
      this.cryptoService.DecryptWithoutPromise("sessionmodifiers2");
    this.collmodifiers2.forEach((element) => {
      element.collections.sort(function (x, y) {
        // true values first
        let compare = 0;
        if (x.isDefault < y.isDefault) {
          compare = 1;
        } else if (x.isDefault > y.isDefault) {
          compare = -1;
        }
        return compare;
      });
    });
    this.findCollections();
    let looping = styleVariations.modifiers;

    if (this.toppings.length > 0 && verified == true) {
      this.toppings = [];
    }
    if (this.notAllowMultiple.length > 0 && verified == true) {
      this.notAllowMultiple = [];
    }
    this.collectionsModifierscollections.unshift(styleVariations);
    //CartItem
    if (this.availableQuantities.length > 0 && verified == true) {
      this.availableQuantities = [];
    }
    if (this.availableQuantities.length > 0 && verified == false) {
      this.availableQuantities = [];
    }
    while (this.isDefaultModifiers.length > 0) {
      this.isDefaultModifiers.pop();
    }
    for (let index = 0; index < looping.length; index++) {
      const element = looping[index];
      if (element.isDefault == true) {
        this.isDefaultModifiers.push(element);
      }
    }
    if (this.isEdit) {
      // console.log(this.editModifer);
      this.availableQuantities = this.editModifer;
      for (let index = 0; index < this.collmodifiers2.length; index++) {
        const element = this.collmodifiers2[index];
        for (let j = 0; j < element.collections.length; j++) {
          const eleme: AddOn_Variations = element.collections[j];
          const alterationQuantityExist = this.availableQuantities.findIndex(
            (x: AddOn_Variations) => x.externalId2 == eleme.externalId2
          );
          if (alterationQuantityExist > -1) {
            this.collmodifiers2[index]["collections"][j]["alterationPortion"] =
              this.availableQuantities[alterationQuantityExist][
                "alterationPortion"
              ];
          }
        }
      }
    } else {
      for (let i = 0; i < this.isDefaultModifiers.length; i++) {
        const element = this.isDefaultModifiers[i];
        for (let j = 0; j < element.collections.length; j++) {
          const collection = element.collections[j];
          if (collection.isDefault) {
            if (this.allowHalf && collection.alterationCategory == "TOPPINGS") {
              this.availableQuantities.push({
                ...collection,
              });
            } else {
              for (let i=0; i<(collection.defaultSelectedCount || 1);i++) {
                this.availableQuantities.push({
                  ...collection,
                  alterationQuantity: this.WHOLE,
                });
              }
            }
          }
        }
      }
      for (let index = 0; index < this.collmodifiers2.length; index++) {
        const element = this.collmodifiers2[index];
        for (let j = 0; j < element.collections.length; j++) {
          const eleme = element.collections[j];
          if (eleme.isDefault) {
            if (
              eleme.alterationQuantity &&
              this.allowHalf &&
              eleme.alterationCategory == "TOPPINGS"
            ) {
              this.collmodifiers2[index]["collections"][j][
                "alterationQuantity"
              ] = eleme.alterationQuantity;
            } else {
              this.collmodifiers2[index]["collections"][j][
                "alterationQuantity"
              ] = this.WHOLE;
            }
          } else {
            if (eleme.alterationCategory == "TOPPINGS" && this.allowHalf) {
              this.collmodifiers2[index]["collections"][j][
                "alterationQuantity"
              ] = this.RIGHT_HALF;
            } else {
              this.collmodifiers2[index]["collections"][j][
                "alterationQuantity"
              ] = "";
            }
          }
        }
      }
    }
    this.selectedQuantity = [
      ...new Map(
        this.selectedQuantity.map((item: any) => [
          item["alterationCategory"],
          item,
        ])
      ).values(),
    ];
    this.isQuantityShowed = true;
    this.cryptoService.Encrypting("sessionmodifiers2", this.collmodifiers2);
    this.sendtoservice();
    this.updateModifier();
  }

  selectAddonVItemV2(topping: any) {
    const Addons = this.collmodifiers2;
    let newItem: any;
    let rootAddon: any;
    let isExist: boolean;
    topping.alterationQuantity = this.selectedAvailableQuantity;
    for (let index = 0; index < this.selectedAddons.length; index++) {
      const element = this.selectedAddons[index];
      isExist =
        element.collections.findIndex(
          (finder: any) => finder.externalId2 == topping.externalId2
        ) != -1
          ? true
          : false;
      if (isExist) {
        //  console.log("Exists");
        newItem = element.collections.filter(
          (finder: any) => finder.externalId2 == topping.externalId2
        );
      }
    }
    if (!isExist) {
      rootAddon = Addons.filter(
        (finder: any) => finder.name == topping.alterationCategory
      );
      rootAddon[0].collections = [topping];
      newItem = rootAddon;
      let addOG = this.selectedAddons.concat(newItem);
      newItem = [];
      this.selectedAddons = addOG;
    } else {
      rootAddon = this.selectedAddons.filter(
        (finder: any) => finder.name != newItem[0].alterationCategory
      );
      this.selectedAddons = rootAddon;
    }
    this.sendtoservice();
    this.updateModifier();
  }

  updateModifier() {
    this.collmodifiers2 = this.collmodifiers2.map(
      (element) =>
        (element = { ...element, isRequired: this.checkavailable1(element) })
    );
  }

  selectAvailableQuantityV2(quantity: string) {
    this.selectedAvailableQuantity = quantity;
    this.sendtoservice();
  }
  selectAddons_VariationV2(addons: any) {
    this.collectionsModifierscollectionsmodifierscollections = addons;
    this.sendtoservice();
  }

  addAddons(addOn: any) {
    this.selectAddons_VariationV2(addOn.collections);
    this.sendtoservice();
  }

  removeAddons(addOn: any) {
    this.selectAddons_VariationV2([]);
    this.sendtoservice();
  }

  getToolTipText(value1, value2) {
    return value2 ? `${value1} \n ${value2}` : value1;
  }

  findCollections(selectedModifierIndex?) {
    this.selectedModifier = this.collmodifiers2[selectedModifierIndex || 0];
    if (this.selectedModifier) {
      this.modifiersCollection = this.selectedModifier.collections;
      const { min, max } = this.selectedModifier;
      this.selectedModifier.toolTipText = `${
        this.isNumber(min) ? "MIN " + min : ""
      }${this.isNumber(min) && this.isNumber(max) ? " & " : ""}${
        this.isNumber(max) ? "MAX " + max + " " : ""
      }`;
    }
  }

  isNumber(value) {
    return typeof value === "number";
  }

  selectTab(modifier) {
    const selectedModifierIndex = this.collmodifiers2.findIndex(
      (val) => val.name === modifier.name
    );
    this.findCollections(selectedModifierIndex);
  }

  getHeaderTitle(modifier) {
    return (
      modifier.displayName +
      " " +
      (modifier?.collections[0]?.alterationCategory === "TOPPINGS"
        ? "( " + modifier?.collections[0]?.alterationType + " )"
        : "")
    );
  }

  checkActiveTab(modifier, selectedModifier) {
    if (
      !modifier.collections.length &&
      modifier.displayName !== selectedModifier.displayName
    ) {
      return false;
    } else if (
      !modifier.collections.length &&
      modifier.displayName === selectedModifier.displayName
    ) {
      return true;
    }
    const value1 =
      modifier.displayName + modifier?.collections[0]?.alterationType;
    const value2 =
      selectedModifier.displayName +
      selectedModifier?.collections[0]?.alterationType;
    return value1 === value2 ? true : false;
  }
}
