import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentService } from 'app/core/services/component.service';
import { VoixHttpService } from 'app/core/voix-http/voix-http.service';
import { IClientListing } from 'app/order-receive/clients/model/client-listing.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import { IStore } from '../model/IStore';
import { ModeOfOperation } from 'app/core/enums/restaurant.enum';


@Component({
  selector: 'app-add-store',
  templateUrl: './add-store.component.html',
  styleUrls: ['./add-store.component.scss']
})
export class AddStoreComponent implements OnInit, AfterViewInit {
  public selectedFormType: string = 'Add';
  public store: IStore = null;
  allStoresSelected: boolean = false;
  selectedClientID: number;
  showError = false;
  addNewStoreForm: FormGroup;
  modeOfOperation: string[] = Object.values(ModeOfOperation);
  constructor(private componentService: ComponentService,
    private ngxService: NgxUiLoaderService,
    private voixHttp: VoixHttpService,
    private fb: FormBuilder
  ) {
    this.componentService.getSelectedClientForStore().subscribe((selectedClientStore: IClientListing) => {
      this.selectedClientID = selectedClientStore.id;
    })
  }
  ngOnInit() {
    this.addNewStoreForm = this.fb.group({
      StoreID: [null, Validators.required],
      FirstName: [null, Validators.required],
      LastName: [null, Validators.required],
      Address: ['', Validators.required],
      State: ['', Validators.required],
      Phone: [null, [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(11), Validators.maxLength(11)]],
      secondary_phones: [[]],
      City: ['', Validators.required],
      ZipCode: ['', [Validators.required, Validators.pattern(/^[0-9]*$/), Validators.minLength(5), Validators.maxLength(6)]],
      ModeOfOperation: [ModeOfOperation.SUPER_AI]
    });
    this.addNewStoreForm.get('Phone').valueChanges.subscribe((val) => {
      this.showError = this.addNewStoreForm.get('Phone').dirty
    });
    this.addNewStoreForm.get('ZipCode').valueChanges.subscribe((val) => {
      this.showError = this.addNewStoreForm.get('ZipCode').dirty
    });

    if (this.store) {
      this.addNewStoreForm.setValue({
        StoreID: this.store.storeNumber,
        FirstName: this.store.firstName,
        LastName: this.store.lastName,
        Address: this.store.address,
        State: this.store.state || '',
        Phone: this.store.phone || '',
        secondary_phones: this.store.secondary_phones ? this.store.secondary_phones.map(phone => ({ display: phone, value: phone })) : [],
        City: this.store.city || '',
        ZipCode: this.store.zipCode,
        ModeOfOperation: this.store.ModeOfOperation
      })
    }
  }

  ngAfterViewInit() {
    window.scroll(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  Back() {
    window.scroll(0, 0);
    this.componentService.SetSelectedButtonType("AddNew");
    this.componentService.LoadStoresAddModifyComponent({
      ComponentName: "StoreListing"
    });
  }
  get addNewStoreFormControls() {
    return this.addNewStoreForm.controls;
  }
  ResetForm() {
    this.allStoresSelected = false;
    this.store = this.ResetStoreObject();
  }

  ResetStoreObject(): any {
    // return { id: null, Address: null, State: null, Phone: null, City: null, ZipCode: null};
    return this.addNewStoreForm.reset();
  }

  addNewStore() {
    let url = '';
    this.addNewStoreForm.value.secondary_phones = this.addNewStoreForm.controls['secondary_phones'].value.map(phone => phone.display);
    if (this.selectedFormType === 'Add') {
      url = 'v1/user/restaurant/create'
      this.ngxService.start();
      this.voixHttp.createNewStore(url, { ...this.addNewStoreForm.value, clientId: this.selectedClientID }).subscribe({
        next: (res) => {
          Swal.fire(
            'Store created successfully.',
            '',
            "success"
          );
          this.ngxService.stop();
          this.ResetStoreObject();
          this.Back();
        },
        error: error => {
          Swal.fire(
            error?.error?.message || error?.message || error?.error || 'Store not created',
            'There was an error while creating the store',
            "error"
          );
          this.ngxService.stop();
        }
      })
    } else {
      url = `v1/user/restaurant/update/${this.store.id}`;
      console.log(this.addNewStoreForm.value)
      this.ngxService.start();
      this.voixHttp.updateStore(url, { ...this.addNewStoreForm.value, restaurantPhoneId: this.store.restaurantPhoneId }).subscribe(
        {
          next: (res) => {
            Swal.fire(
              'Store updated successfully.',
              '',
              "success"
            );
            this.ngxService.stop();
            this.Back();
          },
          error: error => {
            Swal.fire(
              'Store is not updated',
              error?.error?.message || error?.message || error?.error || 'There was an error while updating the store',
              "error"
            );
            this.ngxService.stop();
          }
        }
      )
    }
  }
}
