<div class="row col-md-12 margin-top-20">
  <form method="#" action="#" [formGroup]="addForm" (ngSubmit)="addConfiguration(addForm.value)">
    <div class="col-md-12">
      <mat-form-field class="example-full-width col-md-12">
        <mat-select placeholder="Select Component" name="type" [formControl]="addForm.controls['component_name']" >
          <mat-option *ngFor="let name of componentArray" value="{{ name.key }}">
            {{ name.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>  
    </div>
    <div class="col-md-12">
      <mat-form-field class="example-full-width col-md-12">
        <mat-select placeholder="Select Component Key" name="type" [formControl]="addForm.controls['component_key']" >
          <mat-option *ngFor="let name of componentKeyArray" value="{{ name.key }}">
            {{ name.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>  
    </div>
    <div class="col-md-12">
      <mat-form-field class="example-full-width col-md-12">
        <textarea matInput placeholder="Component Value" value="" [formControl]="addForm.controls['component_value']"></textarea>
      </mat-form-field>
    </div>
<div class="footer text-center">
  <button type="submit" class="btn btn-primary btn-round" [disabled]="!addForm.valid" (click)="onSubmit()">Submit</button>
</div>
<!-- Form submission message -->
  </form>
</div>