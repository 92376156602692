import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'numberToAlphabetConverter',
})
export class NumberToAlphabetConverter implements PipeTransform {
  transform(value: number): any {
    if (value >= 0) {
      value = value + 1;
      return String.fromCharCode(value+64)
    } else {
      return;
    }
  }
}