<div class="row">
  <div class="col-md-12  align-center">
    <span>Manual Discount</span>
  </div>
  <div class="col-md-12">
    <input type="number" #inputValue class="form-control" placeholder="Enter Discount Value" />
    <div>
      <button type="button" class="btn btn-success" (click)="closeDialog(inputValue.value)">Apply</button>
    </div>
  </div>
</div>
