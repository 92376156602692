import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
    providedIn:'root'
})
export class Interceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let contentType = "application/json";
        let urls =["v1/graphql-gateway/"];
        if(request.url.includes(urls[0]) && request.method == "GET"){
            contentType="text/html; charset=utf-8";
        }
        if (!request.url.includes("uploadkeywordsfile") && request.method == "POST") {
            request = request.clone({
                headers: request.headers.set('content-type', contentType)
            });
        }
        let localItem = localStorage.getItem("agentCurrentUser");
        if (localItem != null && localItem != undefined) {
            let agentCurrentUser = JSON.parse(localStorage.getItem("agentCurrentUser") || '');
            if (agentCurrentUser != null && agentCurrentUser.token != null) {
                request = request.clone({
                    headers: request.headers.set("Authorization", agentCurrentUser.token)
                });
            }
        }
        return next.handle(request);
    }
}