<div [formGroup]="cardForm" style="background-color: white">
  <div>
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Card Number*</mat-label>
        <input
          matInput
          placeholder="Card Number"
          formControlName="cardNumber"
          onkeydown="return ( event.ctrlKey || event.altKey 
          || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
          || (95<event.keyCode && event.keyCode<106)
          || (event.keyCode==8) || (event.keyCode==9) 
          || (event.keyCode>34 && event.keyCode<40) 
          || (event.keyCode==46) )"
          class="asterisk-mask-input"
          maxlength="19"
          minlength="13"
        />
        <span *ngIf="ccNumberRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
    </div>
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Expiration Month*</mat-label>
        <input
          matInput
          placeholder="Expiration Month"
          formControlName="expirationMonth"
          onkeydown="return ( event.ctrlKey || event.altKey 
                  || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                  || (95<event.keyCode && event.keyCode<106)
                  || (event.keyCode==8) || (event.keyCode==9) 
                  || (event.keyCode>34 && event.keyCode<40) 
                  || (event.keyCode==46) )"
          maxlength="2"
          minlength="2"
          class="asterisk-mask-input"
        />
        <span *ngIf="ccExpirationMRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Expiration Year*</mat-label>
        <input
          matInput
          placeholder="Expiration Year"
          formControlName="expirationYear"
          onkeydown="return ( event.ctrlKey || event.altKey 
                  || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                  || (95<event.keyCode && event.keyCode<106)
                  || (event.keyCode==8) || (event.keyCode==9) 
                  || (event.keyCode>34 && event.keyCode<40) 
                  || (event.keyCode==46) )"
          maxlength="4"
          minlength="4"
          class="asterisk-mask-input"
        />
        <span *ngIf="ccExpirationYRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
    </div>
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>CVV*</mat-label>
        <input
          matInput
          placeholder="CVV"
          formControlName="cvv"
          onkeydown="return ( event.ctrlKey || event.altKey 
        || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
        || (95<event.keyCode && event.keyCode<106)
        || (event.keyCode==8) || (event.keyCode==9) 
        || (event.keyCode>34 && event.keyCode<40) 
        || (event.keyCode==46) )"
          class="asterisk-mask-input"
          maxlength="4"
          minlength="3"
        />
        <span *ngIf="cvvCardRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
    </div>
  </div>

  <div formGroupName="billingAddress">
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Phone Number</mat-label>
        <input
          matInput
          placeholder="Phone Number"
          formControlName="ccPhone"
          onkeydown="return ( event.ctrlKey || event.altKey 
                  || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                  || (95<event.keyCode && event.keyCode<106)
                  || (event.keyCode==8) || (event.keyCode==9) 
                  || (event.keyCode>34 && event.keyCode<40) 
                  || (event.keyCode==46) )"
          maxlength="12"
          minlength="10"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Postal Code*</mat-label>
        <input
          matInput
          placeholder="Postal Code"
          formControlName="ccPostalCode"
        />
        <span *ngIf="cardPostalCodeRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
    </div>
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Address Line 1</mat-label>
        <input
          matInput
          placeholder="Address Line 1"
          formControlName="ccAddr1"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Address Line 2</mat-label>
        <input
          matInput
          placeholder="Address Line 2"
          formControlName="ccAddr2"
        />
      </mat-form-field>
    </div>
    <div class="d-flex">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" formControlName="ccCity" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>State*</mat-label>
        <input matInput placeholder="State" formControlName="ccState" />
        <span *ngIf="cardStateRequired" style="color: red"
          >This Field is required</span
        >
      </mat-form-field>
    </div>
    <div class="d-flex p-2">
      <button
        [disabled]="cardForm.invalid || !submitButtonOpen"
        mat-stroked-button
        color="primary"
        class="btn btn-success"
        style="width: 100%"
        (click)="submitOrderCard()"
      >
        Submit <i class="material-icons" *ngIf="cardForm.invalid">lock</i>
      </button>
    </div>
  </div>
</div>
