<h1 mat-dialog-title>Create Manual Order</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill" style="padding: 4px ; width: 340px;">
    <mat-label>Enter Customer Number</mat-label>
    <input placeholder="Enter Customer Number" aria-label="Number" matInput 
    maxlength="10"
    [formControl]="client_number" 
    />
    <mat-error *ngIf="!client_number.valid">
      Please enter a valid 10-digit customer number.
    </mat-error>
  </mat-form-field>
</div>
<div class="proceed">
  <button mat-button [ngClass]="!client_number.valid ? 'proceed-btn-disabled' : 'proceed-btn'"
    (click)="placeManualOrderForClient()">Proceed</button>
</div>