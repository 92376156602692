
import { Component, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-popup',
  templateUrl: './deal-popup.component.html',
  styleUrls: ["./view-deals.component.scss"],
})
export class DealPopupComponent {
  @Input() selectedDealValue: string;
  deals: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   public dialogRef: MatDialogRef<DealPopupComponent>,
) { }

  closeDialog(valueToPassBack: string): void {
    this.dialogRef.close(valueToPassBack);
  }
  
}
