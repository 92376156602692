import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
// import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { VoixHttpService } from '../core/voix-http/voix-http.service';
import { NotificationService } from '../core/notification/notification.service';
import { generalService } from '../core/services/general.service';
import { ConfirmationDialogComponent } from '../core/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
// import 'rxjs/add/observable/zip';
import { getRestaurantService } from '../core/auth/restaurant.service';
import { AddDialogComponent } from './add/add.component';
import { AuthenticationService } from '../core/auth/authentication.service';
import { emitTransactionsService } from '../core/services/emitTransactions.service';
import { AppConstants } from '../app.constants';
import * as _ from 'underscore';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, map, startWith } from 'rxjs';
import { AlertDialogComponent } from "app/core/shared/alert-dialog/alert-dialog.component";
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { UploadedCsvListComponent } from "./uploaded-csv-list/uploaded-csv-list.component";
import { CommonLibs as constants} from '../commonLibs';
import { Permissions } from 'app/core/auth/model/permissions.model';
import { BulkUploadService } from '../modify-coupon/bulk-report-modal/bulk-upload-service';
import { RestaurantConfigurationCSV, Client, RestaurantConfigurationPayload, DeletePayload, GetRestaurantConfigurationsParams, CustomerFilter } from '../../../mediator-commlibs/interfaces/request/restaurantSetting.interface'
import { AgentClientsResponse, FilteredOptions, GetStoreByPhoneNumbers, RestaurantConfigurationDetails, RestaurantResponse, StoreResponse } from '../../../mediator-commlibs/interfaces/response/restaurantSetting.interface';
import { environment } from 'environments/environment';
import { MessageDialogComponent } from './uploaded-csv-list/message-dialog.component';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface StoreData {
  data: Store[];
  // Define other properties if present in the response
}

export interface Store {
  storeId: string;
  storePhone: string;
  storeDatabaseId: string;
}

@Component({
  selector: 'app-restaurant-settings',
  templateUrl: './restaurant-settings.component.html',
  styleUrls: ['./restaurant-settings.component.scss']
})
export class RestaurantSettingsComponent implements OnInit {

  customResponseData: any = [];
  customResponse: FormGroup;
  restaurant:any;
  restaurantDetails: any;
  agentDetails: any;
  limit = 10;
  model: any = {};
  ingressChannel: any = {};
  restaurantConfigDetails: any = [];
  modeOfOperationList = AppConstants.MODE_OF_OPERATION_LIST;
  // tslint:disable-next-line:max-line-length
  channelList = [{id: 'phone', name: 'Phone'}, {id: 'sms', name: 'SMS'}, {id: 'facebook', name: 'Facebook'}, {id: 'whatsapp', name: 'WhatsAPP'}, {id: 'chat', name: 'Web Chat'}];
  matcher = new MyErrorStateMatcher();
  listOfChannels = [];
  displayedColumns = ['name', 'key', 'value'];
  dataSource: MatTableDataSource<object>;
  selectedClient: number;
  clients: Client[] = [];
  stores: Store[] = [];
  store = new FormControl();
  name: string;
  storePhone: string;
  storeId: string;
  storeDatabaseId: string;
  filteredOptions: Observable<Store[]>;
  dialogRef = null;
  isBulkConfigurations = false;
  selectOrderType: string;
  configurations: RestaurantConfigurationCSV[] = []; 
  socket: any;
  isButtonClicked = false;
  userPermissions = Permissions;
  @ViewChild('fileImportInput') fileImportInput: ElementRef<HTMLInputElement>;
  constructor( private voixHttp: VoixHttpService,
    private notification: NotificationService,
    public dialog: MatDialog, private fb: FormBuilder,
    private generalService: generalService,
    private currentRestaurant: getRestaurantService, 
    private auth: AuthenticationService,
    private emitTran: emitTransactionsService,
    private ngxService: NgxUiLoaderService,
    private ngxCsvParser: NgxCsvParser,
    private bulkUploadService: BulkUploadService) {
      currentRestaurant.restaurantSelected$.subscribe(data => {
        this.restaurant = data;
        if (this.restaurant?.restaurant_id) {
          this.getCurrentRestaurantDetails();
          this.getALLAgents();
          this.getCurrentRestaurantConfigurationDetails();
          this.getRestaurantChannels();
        }
      });
      currentRestaurant.restaurant().subscribe(
        data => {
        this.restaurant = data;
        if (this.restaurant?.restaurant_id) {
          this.getCurrentRestaurantDetails();
          this.getALLAgents();
          this.getCurrentRestaurantConfigurationDetails();
          this.getRestaurantChannels();
        }
      });

      this.voixHttp.get(`v1/agent-clients?dashboard_subdomain=${window.location.origin}`, {}, false, ('isAdminBuild' in environment)).subscribe((res: AgentClientsResponse) => {
        if (res) {
          this.clients = res.data;
          if (this.clients?.length) {
            this.selectedClient = this.clients[0].id;
            this.getAllStores(this.selectedClient);
          }
        }
      });      
  }

  ngOnInit() {
    this.filteredOptions = this.store.valueChanges.pipe(
      startWith(null),
      map((val) => this.filter(val))
    );
   }

  getCurrentRestaurantConfigurationDetails(limit = 10, page = 1, customerFilter = {}) {
    let that = this;
    let params = {restaurant_id: this.storeDatabaseId, limit: limit, page: page};
    params = Object.assign(params, customerFilter);
    this.voixHttp.get('v1/restaurant-configurations/'+ this.storeDatabaseId,  {params: params})
    .subscribe( (response: any) => {
      this.restaurantConfigDetails = response;
      this.dataSource = new MatTableDataSource(response.data);
    }, error => {
      this.restaurantConfigDetails = []; // Clear the previous data
      this.dataSource = new MatTableDataSource([]); // Clear the data source
      let errorHandle = JSON.parse(error);
      this.notification.show('danger','notifications', errorHandle.error);
    });
  }

  public handlePage(e: any) {
    this.getCurrentRestaurantConfigurationDetails(e.pageSize, (e.pageIndex + 1));
  }

  getRestaurantChannels() {
    this.voixHttp.get('v1/agent/restaurant/' + this.restaurant.restaurant_id + '/channels')
    .subscribe( (response: any) => {
      console.log(response);
      this.listOfChannels = response['data'];
    }, error => {
      let errorHandle = JSON.parse(error);
      this.notification.show('danger','notifications', errorHandle.error);
    });
  }

  submitRestaurantChannels() {
    if(this.ingressChannel.channel_name && this.ingressChannel.mode_of_operation) {
      this.voixHttp.post('v1/agent/restaurant/' + this.restaurant.restaurant_id + '/channels', this.ingressChannel)
      .subscribe( (response: any) => {
        this.notification.show('success', '', 'Ingress channel has been added successfully.');
        this.ingressChannel = {};
        this.getRestaurantChannels();
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', 'Please select valid input data or check duplicate entry');
      });
    } else {
      this.notification.show('danger', 'notifications', 'Please select valid input data');
    }
  }

  getCurrentRestaurantDetails() {
    this.voixHttp.get('v1/agent/restaurant/'+ this.restaurant.restaurant_id)
    .subscribe( data => {
      this.restaurantDetails = data['data'];
      this.model = this.restaurantDetails;
    }, error => {
      let errorHandle = JSON.parse(error);
      this.notification.show('danger', 'notifications', errorHandle.error);
    });
  }

  updateDetails(item) {
    // tslint:disable-next-line:max-line-length
    this.voixHttp.patch('v1/agent/restaurant/' + this.restaurant.restaurant_id + '/channels/' + item.id, {mode_of_operation: item.mode_of_operation})
    .subscribe( data => {
      this.notification.show('success', '', 'Mode of operation has been update successfully.');
    }, error => {
      let errorHandle = JSON.parse(error);
      this.notification.show('danger', 'notifications', errorHandle.error);
    });
  }

  deleteChannel(row) {
    var that = this;
    let dialogNewRef = this.dialog.open(ConfirmationDialogComponent, {disableClose: false});
    dialogNewRef.componentInstance.confirmMessage = 'Are you sure you want to delete \"'+ row.channel_name +'\" channel?';
    dialogNewRef.afterClosed().subscribe(result => {
      if(result) { this.removeChannelItem(row); }
      dialogNewRef = null;
    });
  }

  removeChannelItem(row) {
    this.voixHttp.delete('v1/agent/restaurant/' + this.restaurant.restaurant_id + '/channels/' + row.id)
    .subscribe( data => {
      this.notification.show('success', 'notifications', 'Restaurant Ingress Channel has been Deleted successfully.');
      this.getRestaurantChannels();
    }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', errorHandle.error);
      }
    );
  }

  getALLAgents() {
    this.voixHttp.get('v1/agents/' + this.restaurant.restaurant_id)
    .subscribe( data => {
      this.agentDetails = data['data'];
    }, error => {
      let errorHandle = JSON.parse(error);
      this.notification.show('danger','notifications', errorHandle.error);
    });
  }

  updateAvaibility(event, restaurant_id, id) {
    let status = 'OFFLINE';
    if(event.checked) { status = 'AVAILABLE' };
    this.auth.updateSettingAgentStatus(status, restaurant_id, id).subscribe((response: any) => { 
      this.emitTran.setNav(true);
    });
  }

  updateRestaurantStatus(event, id) {
    let status = 'INACTIVE';
    if(event.checked) { status = 'ACTIVE' };
    this.voixHttp.put('v1/agent-restaurants/' + this.restaurant.restaurant_id + '?role=admin&isRestaurant=true', {status: status, agent_id: id}).subscribe((response: any) => { 
      this.getALLAgents()
      this.notification.show('success','', 'Agent Restaurant status has been updated successfully.');
    }, error => {
      let errorHandle = JSON.parse(error);
      this.notification.show('danger','notifications', 'Something went wrong. please try again after some time.');
    });
  }

  update(row) {
    if (!this.restaurant?.restaurant_id) {
      this.openAlertDialog('Please select store', '');
      return;
    }
    this.voixHttp.put('v1/restaurant-configurations/' + this.restaurant.restaurant_id +  '/' + row.id, {component_value: row.component_value, id: row.id, restaurant_id: this.restaurant.restaurant_id})
    .subscribe( data => {
        this.notification.show('success','notifications', 'Restaurant Configuration has been Updated successfully.');
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', errorHandle.error);
      });
  }

  addConfiguration() {
    if (!this.restaurant?.restaurant_id) {
      this.openAlertDialog('Please select store', '');
      return;
    }
    var that = this;
    let dialogNewRef = this.dialog.open(AddDialogComponent, { width: '500px', data: { restaurant_id: that.restaurant.restaurant_id } });
    dialogNewRef.afterClosed().subscribe( 
      result => {
        if(result) {
          { this.getCurrentRestaurantConfigurationDetails(); }
          this.notification.show('success','notifications', 'Restaurant Configuration has been Added successfully.');
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', errorHandle.error);
      });
  }

  delete(row) {
    if (!this.restaurant?.restaurant_id) {
      this.openAlertDialog('Please select store', '');
      return;
    }
    var that = this;
    let dialogNewRef = this.dialog.open(ConfirmationDialogComponent, {disableClose: false});
    dialogNewRef.componentInstance.confirmMessage = 'Are you sure you want to delete \"'+ row.component_key +'\"?';
    dialogNewRef.afterClosed().subscribe(result => {
      if(result) { this.removeItem(row); }
      dialogNewRef = null;
    });
  }

  removeItem(row) {
    const body: DeletePayload = {
      id: row.id,
      restaurant_id: this.restaurant.restaurant_id
    };
  
    this.voixHttp.deleteRestaurantConfiguration('v1/restaurant-configurations/' + this.restaurant.restaurant_id + '/' + row.id, body)
      .subscribe(
        () => {
          {this.getRestaurantConfigurationDetailsById(this.restaurant.restaurant_id, 10, 1, {})};
  
          this.notification.show('success', 'notifications', 'Restaurant Configuration has been Deleted successfully.');
  
          // Set a timer to clear the notification after 3 seconds
          setTimeout(() => {
            this.notification.dismiss();
          }, 1000);
        },
        error => {
          const errorHandle = JSON.parse(error);
          this.notification.show('danger', 'notifications', errorHandle.error);
        }
      );
  }  
  
  getName(channelId: any) {
    var data = {name: '-N/A-'};
    data =  _.findWhere(this.channelList, {id: channelId});
    return data.name;
  }

  getAllStores(selectedClient: number) {
    const getSelectClient = this.clients.find((client: Client) => client?.id === selectedClient);
    this.ngxService.start();
    this.voixHttp.getStoresByClient(`v1/agent/getallrestaurants?clientcode=${getSelectClient?.name}`).subscribe(
      (res: StoreData) => {
        const { data } = res || {};
        this.stores = data || [];
        this.store.setValue(null);
        this.selectedClient = selectedClient;
        this.ngxService.stop();
      },
      () => {
        this.ngxService.stop();
      }
    );
  }
  
  selectStore(resStore) {
    this.storePhone = resStore?.storePhone ? resStore.storePhone : null;
    this.storeId = resStore.storeId;
    this.restaurant.restaurant_id = resStore.storeId;
    this.storeDatabaseId = resStore.storeDatabaseId;
  }

  filter(val) {
    if (!val) {
      return this.stores.sort((a, b) => +a.storeId - +b.storeId);
    }
    return this.stores
      .filter(
        (store) =>
          store?.storePhone?.includes(val) || store?.storeId?.includes(val)
      )
      .sort((a, b) => +a.storeId - +b.storeId);
  }

  getStoreByPhoneNumbers(phoneNumber: string) {
    this.ngxService.start();
    this.voixHttp.get(`v1/restaurants/phone-numbers?phone_number=${phoneNumber}`)
      .subscribe(
        (res: GetStoreByPhoneNumbers) => {
          const { data } = res || {};
          this.stores = (Array.isArray(data) ? data : data ? [data] : []) as Store[];
          this.store.setValue(null);
          this.ngxService.stop();
        },
        () => {
          this.ngxService.stop();
        }
      );
  }

  getRestaurantConfigurationDetailsById(storeDatabaseId: string, limit = 10, page = 1, customerFilter: CustomerFilter = {}) {
    this.restaurant.restaurant_id = this.storeDatabaseId;
    const params: GetRestaurantConfigurationsParams = {
      restaurant_id: this.storeDatabaseId,
      limit: limit,
      page: page
    };
    Object.assign(params, customerFilter);
    this.voixHttp.get('v1/restaurant-configurations/' + this.restaurant.restaurant_id, { params: params })
      .subscribe((response: RestaurantConfigurationDetails) => {
        this.restaurantConfigDetails = response;
        this.dataSource = new MatTableDataSource(response.data);
      }, error => {
        const errorHandle = JSON.parse(error);
        this.notification.show('danger', 'notifications', errorHandle.error);
      });
  }

  downloadSampleCSVFile() {
    // csv can not be declared as a constant in this function
    let csv = 'restaurant_id,component_name,component_key,component_value\n';
  
    [['', '', '', '']].forEach((row) => {
      csv += row.join(',');
      csv += "\n";
    });
  
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Restaurant Configuration Sample.csv';
    hiddenElement.click();
  }  
  
  openFile(fileImportInput) {
    if (!this.selectedClient) {
      this.openAlertDialog('Please select client', '');
      return;
    }
    fileImportInput.click();
  }

  openAlertDialog(heading, message) {
    const dialogNewRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '200ms',
      disableClose: false
    });
    dialogNewRef.componentInstance.heading = heading;
    dialogNewRef.componentInstance.message = message;
  }
  
  fileChangeListener($event): void {
    const files = $event.srcElement.files;
    this.ngxCsvParser
    .parse(files[0], {
      header: true,
      delimiter: ',',
      encoding: 'utf8'
    })
    .pipe()
    .subscribe({
      next: (result: Array<RestaurantConfigurationCSV>) => {
        this.fileImportInput.nativeElement.value = '';
        this.dialogRef = this.dialog.open(UploadedCsvListComponent, {
          width: "1000px",
          height: "800px",
          data: result,
        });
        this.dialogRef.componentInstance.client = this.selectedClient;
        if (this.dialogRef.componentInstance.onSubmit) {
          this.dialogRef.componentInstance.onSubmit.subscribe((result) => {
            if(result) {
              this.submit(result);
            }
          });
        } else {
          console.error("onSubmit is not defined or not set to a valid observable.");
        }
      },
      error: (error: NgxCSVParserError) => {
        console.log('Error', error);
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          error.message,
          "danger-snackbar"
        );
      }
    });
  }

  submit(configurations: RestaurantConfigurationCSV[]) {
    if (!configurations || !Array.isArray(configurations) || configurations.length === 0) {
      console.error('Invalid configurations data:', configurations);
      return; // Exit the function early if configurations is invalid
    }
  
    this.isBulkConfigurations = true;
  
    const tempConfigurations = this.isBulkConfigurations
      ? [...configurations]
      : configurations.filter((config, i) =>
          config.restaurant_id !== this.configurations[i].restaurant_id ||
          config.component_name !== this.configurations[i].component_name ||
          config.component_key !== this.configurations[i].component_key ||
          config.component_value !== this.configurations[i].component_value
        ).map(config => ({
          restaurant_id: config.restaurant_id,
          component_name: config.component_name,
          component_key: config.component_key,
          component_value: config.component_value,
        }));
  
    if (tempConfigurations.length === 0) {
      return;
    }
  
    // Validate restaurant_id, component_name, component_key, and component_value
    const invalidConfigurations = tempConfigurations.filter(config => {
      return !config.restaurant_id || isNaN(Number(config.restaurant_id)) || !config.component_name || !config.component_key || !config.component_value;
    });
  
    if (invalidConfigurations.length > 0) {
      const invalidMessage = `Invalid entries found: ${invalidConfigurations.map(c => `Restaurant ID: ${c.restaurant_id}, Component Name: ${c.component_name}, Component Key: ${c.component_key}, Component Value: ${c.component_value}`).join('; ')}`;
      this.notification.openSnackBarActionV2('end', 'top', invalidMessage, 'danger-snackbar');
      return; // Exit if there are invalid entries
    }
  
    this.ngxService.start();
    const payload: RestaurantConfigurationPayload = {
      configurations: tempConfigurations
    };
    const duplicates = [];
    const uniqueConfigurations = {
      configurations: payload.configurations.filter((config, index, self) => {
        const key = `${config.restaurant_id}_${config.component_name}_${config.component_key}`;
        const isDuplicate = self.some((c, i) => (
          i !== index &&
          `${c.restaurant_id}_${c.component_name}_${c.component_key}` === key
        ));
        if (isDuplicate) {
          const alreadyAdded = duplicates.some(d => (
            d.restaurant_id === config.restaurant_id &&
            d.component_name === config.component_name &&
            d.component_key === config.component_key
          ));
          if (!alreadyAdded) {
            duplicates.push({
              restaurant_id: config.restaurant_id,
              component_name: config.component_name,
              component_key: config.component_key
            });
          }
        }
        return !isDuplicate;
      })
    };
  
    if (duplicates.length > 0) {
      const duplicateMessage = `Duplicate entries found for the following items: ${duplicates.map(d => `Restaurant ID: ${d.restaurant_id}, Component Name: ${d.component_name}, Component Key: ${d.component_key}`).join('; ')}`;
      this.notification.openSnackBarActionV2('end', 'top', duplicateMessage, 'danger-snackbar');
    }
  
    const promises = uniqueConfigurations.configurations.map(config => {
      return this.voixHttp.post('v1/restaurant-configurations/' + config.restaurant_id + '/bulkUpload', { configurations: [config] })
        .toPromise()
        .catch(error => {
          console.error('Bulk upload error:', error);
          return { error, config };
        });
    });
  
    Promise.all(promises).then(results => {
      const errors = results.filter(result => result && result.error);
      if (errors.length > 0) {
        const errorMessages = errors.map(e => {
          let message = `Restaurant ID: ${e.config.restaurant_id}, Component Name: ${e.config.component_name}, Component Key: ${e.config.component_key}`;
          if (e.error && e.error.error) {
            message += ` - Error: ${e.error.error}`;
          } else if (e.error && e.error.message) {
            message += ` - Error: ${e.error.message}`;
          }
          return message;
        });
  
        const errorMessage = `Errors occurred for the following items: ${errorMessages.join('; ')}`;
        this.notification.openSnackBarActionV2('end', 'top', errorMessage, 'danger-snackbar');
      } else {
        this.notification.openSnackBarActionV2('end', 'top', 'All configurations processed successfully', 'success-snackbar');
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      }
      this.ngxService.stop();
    }).catch(error => {
      console.error('Bulk upload overall error:', error);
      this.notification.openSnackBarActionV2('end', 'top', 'An error occurred while processing the CSV', 'danger-snackbar');
      this.ngxService.stop();
    });
  }
 
  
  validateClient(configurations: RestaurantConfigurationCSV[]): boolean {
    for (const config of configurations) {
      if (!config.restaurant_id) {
        return false;
      }
    }
    return true;
  }
  
  handleButtonClick(storeDatabaseId: string) {
    this.getRestaurantConfigurationDetailsById(storeDatabaseId);
    this.getCurrentRestaurantConfigurationDetails();
  }
  
}