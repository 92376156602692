<!-- <button (click)="showdata()">Test Data</button> -->
<div #done class="order-place-table" *ngIf="menusV2.length > 0">
  <div class="order-item-form slot-form left-form">
    <!-- Select Category -->
    <div data-testid="category-section" class="section item d-flex">
      <div
        class="section item"
        [ngClass]="{ 'text-center': _.isEmpty(menusV2) }"
      >
        <i class="fa fa-spinner fa-spin" *ngIf="_.isEmpty(menusV2)"></i>

        <mat-button-toggle-group
          *ngIf="!_.isEmpty(menusV2)"
          appearance="legacy"
          aria-label="Font Style"
          name="typeOne"
        >
          <ng-container
            *ngIf="selectedCategory && !selectedCategory.displayName"
          >
            <mat-button-toggle
              *ngFor="let category of menusV2"
              (change)="selectItemV2(category, true)"
              mat-stroked-button
              class="button-round"
              [matTooltip]="!category.isAvailable ? 'DISABLED' : ''"
              [disabled]="!category.isAvailable"
              [ngStyle]="{
                'pointer-events': !category.isAvailable ? 'none' : '',
                'background-color':
                  category.specialQualities &&
                  category.specialQualities.length > 0
                    ? 'gold'
                    : ''
              }"
              [attr.data-testid]="'category-button-' + category.name"
            >
              {{ category.name | uppercase }}
            </mat-button-toggle>
          </ng-container>
          <ng-container
            *ngIf="selectedCategory && selectedCategory.displayName"
          >
            <mat-button-toggle mat-stroked-button class="checked button-round" data-testid="selected-category-button">
              {{ selectedCategory.displayName | uppercase }}
              <button
                mat-icon-button
                class="cross-btn"
                color="warn"
                (click)="removeAllV2(false,true)"
                data-testid="remove-all-button"
              >
                <mat-icon aria-label="clear icon">close</mat-icon>
              </button>
            </mat-button-toggle>
            <!-- Select MenuItems -->

            <ng-container *ngIf="selectedItem && selectedItem.displayName">
              <mat-button-toggle
                mat-stroked-button
                class="checked button-round"
                data-testid="selected-item-button"
              >
                {{ selectedItem.displayName | uppercase }}
                <button
                  mat-icon-button
                  class="cross-btn"
                  color="warn"
                  (click)="removeFromItemsV2()"
                  data-testid="remove-item-button"
                >
                  <mat-icon aria-label="clear icon">close</mat-icon>
                </button>
              </mat-button-toggle>
            </ng-container>
            <!-- Select Size Variation -->
            <ng-container
              *ngIf="selectedCollections && selectedCollections.displayName"
            >
              <mat-button-toggle
                mat-stroked-button
                class="checked button-round"
                data-testid="selected-size-variation-button"
              >
                {{
                  selectedCollections.displayName +
                    " " +
                    (selectedCollections.priceInfo.price > 0
                      ? " - " + selectedCollections.priceInfo.price
                      : "") | uppercase
                }}
                <button
                  mat-icon-button
                  class="cross-btn"
                  color="warn"
                  (click)="removeFromSizeVariationV2()"
                  data-testid="remove-size-variation-button"
                >
                  <mat-icon aria-label="clear icon">close</mat-icon>
                </button>
              </mat-button-toggle>
            </ng-container>
            <!-- Select Style Variation -->
            <ng-container
              *ngIf="
                selectedCollectionsModifierscollections &&
                selectedCollectionsModifierscollections.displayName
              "
            >
              <mat-button-toggle
                mat-stroked-button
                class="checked button-round"
                data-testid="selected-style-variation-button"
              >
                {{
                  selectedCollectionsModifierscollections.displayName +
                    " " +
                    (selectedCollectionsModifierscollections.priceInfo.price > 0
                      ? " -  $" +
                        selectedCollectionsModifierscollections.priceInfo.price
                      : "") | uppercase
                }}
                <button
                  mat-icon-button
                  class="cross-btn"
                  color="warn"
                  (click)="removeFromStyleVariationV2(true)"
                  data-testid="remove-style-variation-button"
                >
                  <mat-icon aria-label="clear icon">close</mat-icon>
                </button>
              </mat-button-toggle>
            </ng-container>
          </ng-container>
        </mat-button-toggle-group>
        <i
          class="fa fa-3x fa-exclamation-circle blink"
          *ngIf="selectedCategory?.items?.length == 0"
        ></i>
      </div>
    </div>
    <mat-divider *ngIf="selectedCategory && !selectedCategory.displayName">
    </mat-divider>

    <!-- Select MenuItems -->
    <div
      class="section d-flex"
      *ngIf="selectedItem && !selectedItem.displayName && items?.length > 0"
      data-testid="items-section-container"
    >
      <div class="section item" [ngClass]="{ 'text-center': _.isEmpty(items) }">
        <i class="fa fa-spinner fa-spin" *ngIf="_.isEmpty(items)"></i>

        <mat-button-toggle-group
          *ngIf="!_.isEmpty(items)"
          appearance="legacy"
          name="menu_items"
          data-testid="menu-item-button-toggle-group"
        >
          <ng-container
            *ngFor="let item of selectedCategory?.items; index as itemIndex"
          >
            <mat-button-toggle
              (click)="selectSizeV2(item, true)"
              mat-stroked-button
              [ngClass]="{
                disbutton: !item.isAvailable
              }"
              [ngStyle]="{
                'pointer-events': !item.isAvailable ? 'none' : '',
                'background-color':
                  item.specialQualities && item.specialQualities.length > 0
                    ? 'gold'
                    : ''
              }"
              [checked]="item.isDefault"
              [matTooltip]="
                !item.isAvailable
                  ? 'DISABLED'
                  : getToolTipText(item.description)
              "
              class="button-round multiple-button"
              [attr.data-testid]="'item-button-' + itemIndex"
            >
              {{
                item.displayName +
                  " " +
                  (item.priceInfo.price > 0
                    ? " -  $" + item.priceInfo.price
                    : "") | uppercase
              }}
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
        <i
          class="fa fa-3x fa-exclamation-circle blink"
          *ngIf="items[itemIndex]?.modifiers"
        ></i>
      </div>
    </div>
    <mat-divider
      *ngIf="selectedItem && !selectedItem.displayName && items?.length > 0"
    >
    </mat-divider>

    <!-- Select Size Variation -->
    <div
      class="section d-flex"
      *ngIf="
        selectedCollections &&
        !selectedCollections.displayName &&
        collections.length > 0
      "
      data-testid="size-section-container"
    >
      <div
        class="section item"
        [ngClass]="{ 'text-center': _.isEmpty(collections) }"
      >
        <i class="fa fa-spinner fa-spin" *ngIf="_.isEmpty(collections)"></i>

        <mat-button-toggle-group
          *ngIf="!_.isEmpty(collections)"
          appearance="legacy"
          name="size_variation"
          data-testid="size-variation-button-toggle-group"
        >
          <ng-container
            *ngFor="let item2 of collections; index as collectionIndex"
          >
            <mat-button-toggle
              (change)="selectStyleVariationV2(item2, true)"
              mat-stroked-button
              #size
              [checked]="item2.isDefault"
              [disabled]="!item2.isAvailable"
              [matTooltip]="
                !item2.isAvailable
                  ? 'DISABLED'
                  : getToolTipText(item2.description)
              "
              class="button-round"
              [ngStyle]="{
                'background-color':
                  item2.specialQualities && item2.specialQualities.length > 0
                    ? 'gold'
                    : ''
              }"
              [attr.data-testid]="'item-button-' + collectionIndex"
            >
              {{
                item2.displayName +
                  " " +
                  (item2.priceInfo.price > 0
                    ? " - " + item2.priceInfo.price
                    : "") | uppercase
              }}
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
        <i
          class="fa fa-3x fa-exclamation-circle blink"
          *ngIf="collections[collectionIndex]?.modifiers"
        ></i>
      </div>
    </div>
    <mat-divider
      *ngIf="
        selectedCollections &&
        !selectedCollections.displayName &&
        collections.length > 0
      "
    >
    </mat-divider>

    <!-- Select Style Variation -->
    <div
      class="section d-flex"
      *ngIf="
        selectedCollectionsModifierscollections &&
        !selectedCollectionsModifierscollections.displayName &&
        collectionsModifierscollections.length > 0
      "
      data-testid="style-section-container"
    >
      <div
        class="section item"
        [ngClass]="{
          'text-center': _.isEmpty(collectionsModifierscollections)
        }"
      >
        <i
          class="fa fa-spinner fa-spin"
          *ngIf="_.isEmpty(collectionsModifierscollections)"
        ></i>

        <mat-button-toggle-group
          *ngIf="!_.isEmpty(collectionsModifierscollections)"
          appearance="legacy"
          name="typeOne"
          data-testid="style-button-toggle-group"
        >
          <ng-container
            *ngFor="
              let item of collectionsModifierscollections;
              index as collectionsModifierscollectionsIndex
            "
          >
            <mat-button-toggle
              [ngStyle]="{
                'background-color':
                  item.specialQualities.length > 0 ? 'gold' : ''
              }"
              mat-stroked-button
              [disabled]="!item.isAvailable"
              [matTooltip]="
                !item.isAvailable
                  ? 'DISABLED'
                  : getToolTipText(item.description)
              "
              (change)="selectAddOnsV2(item, true)"
              #default
              class="button-round"
              [attr.data-testid]="'item-button-' + collectionsModifierscollectionsIndex"
            >
              {{
                item.displayName +
                  " " +
                  (item.priceInfo.price > 0
                    ? " -  $" + item.priceInfo.price
                    : "") | uppercase
              }}
              {{ item.papaSize != null ? "(PS)" : "" }}
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
        <i
          class="fa fa-3x fa-exclamation-circle blink"
          *ngIf="
            collectionsModifierscollections[
              collectionsModifierscollectionsIndex
            ]?.modifiers
          "
        ></i>
      </div>
    </div>
    <mat-divider
      *ngIf="
        selectedCollectionsModifierscollections &&
        !selectedCollectionsModifierscollections.displayName &&
        collectionsModifierscollections.length > 0
      "
    >
    </mat-divider>

    <!-- Select Quantity -->
    <div
      class="section d-flex quantity-section"
      *ngIf="isQuantityShowed && productQuantity != '0.5'"
      data-testid="quantity-section"
    >
      <div class="section item">
        <button
          mat-icon-button
          style="color: black; pointer-events: none"
          (click)="removeFromItemsV2()"
          data-testid="quantity-button"
        >
          <b>QTY</b>
        </button>
        <!-- <div style="padding: 8px"></div> -->
      </div>
      <div class="section item" [ngClass]="{ 'text-center': isQuantityShowed }">
        <i class="fa fa-spinner fa-spin" *ngIf="!isQuantityShowed"></i>

        <mat-button-toggle-group
          *ngIf="!isProductHalf || isEdit"
          appearance="legacy"
          name="quantity"
          data-testid="quantity-button-toggle-group"
        >
          <mat-button-toggle
            *ngFor="let item of quantity; index as quantityIndex"
            mat-stroked-button
            [checked]="productQuantity == item.name"
            #quantityCheck
            (change)="selectProductQuantity(item.value)"
            class="button-round multiple-button"
            [attr.data-testid]="'quantity-button-' + quantityIndex"
          >
            {{ item.name }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <mat-button-toggle-group
          *ngIf="isProductHalf && !isEdit"
          appearance="legacy"
          name="quantity"
          data-testid="quantity-button-toggle-group-half"
        >
          <mat-button-toggle
            *ngFor="let item of quantityHalf; index as quantityHalfIndex"
            mat-stroked-button
            [checked]="productQuantity == item.name"
            #quantityCheck
            (change)="selectProductQuantity(item.value)"
            class="button-round multiple-button"
            [attr.data-testid]="'quantity-button-half-' + quantityHalfIndex"
          >
            {{ item.name }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <i
          class="fa fa-3x fa-exclamation-circle blink"
          *ngIf="quantityCheck"
        ></i>
      </div>
    </div>
    <mat-divider *ngIf="isQuantityShowed && productQuantity != '0.5'">
    </mat-divider>
    <!-- Select All Alterations -->
    <div class="all-alteractions-section modifires-tab-group" data-testid="modifiers-section">
      <div class="d-flex">
        <div
          class="tab-heading"
          *ngFor="let modifier of collmodifiers"
          (click)="selectTab(modifier)"
          [matTooltip]="getHeaderTitle(modifier)"
          [ngClass]="{
            'active-tab': checkActiveTab(modifier, selectedModifier)
          }"
          data-testid="tab-heading"
        >
          {{ getHeaderTitle(modifier) }}
          <button
            disabled
            *ngIf="modifier.isRequired"
            style="background: transparent !important"
            mat-icon-button
            class="header-alert"
            data-testid="modifiers-required-button"
          >
            <i
              class="fa fa-3x fa-exclamation-circle blink"
              style="margin-top: -10px !important; margin-left: 15px !important"
            ></i>
          </button>
        </div>
      </div>
      <div
        class="d-flex modifires-content-section"
        *ngIf="modifiersCollection.length > 0"
        data-testid="modifiers-content-section"
      >
        <div class="section item">
          <button
            mat-icon-button
            color="warn"
            (click)="remove(selectedModifier.displayName)"
            data-testid="remove-button1"
          >
            <mat-icon aria-label="clear icon">close</mat-icon>
          </button>
        </div>
        <div
          class="section item"
          [ngClass]="{ 'text-center': _.isEmpty(modifiersCollection) }"
        >
          <div style="align-items: center; align-content: center">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <mat-button-toggle-group
                appearance="legacy"
                multiple
                name="toppings"
                data-testid="available-button-toggle-group"
              >
                <div
                  *ngFor="
                    let item of modifiersCollection;
                    index as toppingIndex
                  "
                  class="variationButton"
                  [ngStyle]="{
                    'background-color': !item.isAvailable ? 'grey' : ''
                  }"
                >
                  <button
                    [id]="item.displayName + ' ' + 'remove'"
                    class="btn btn-danger btn-substract qty-btn"
                    (click)="removeExtra(item, true)"
                    [disabled]="!item.isAvailable"
                    data-testid="remove-button2"
                  >
                    <i class="material-icons">remove</i>
                  </button>
                  <mat-button-toggle
                    disabled
                    color="primary"
                    mat-stroked-button
                    [matTooltip]="
                      getToolTipText(selectedModifier.toolTipText)
                    "
                    [checked]="check(item)"
                    class="button-round multiple-button"
                    [ngStyle]="{
                      'pointer-events': !item.isAvailable ? 'none' : '',
                      'background-color': !item.isAvailable ? 'grey' : ''
                    }"
                    data-testid="available-mat-button-toggle"
                  >
                  <span [disabled]="!item.isAvailable">
                    <img
                      class="cursor-pointer"
                      src="/assets/img/star-img.png"
                      width="20"
                      height="20"
                      *ngIf="item.toppingTier === 'PREMIUM'"
                    />
                    {{ item.displayName }}
                    {{ item.alterationQuantity || "" }}
                    {{
                      item.alterationCategory == "TOPPINGS"
                        ? " - " + "(" + item.alterationType + ")"
                        : ""
                    }}
                    {{
                      item.priceInfo.price > 0
                        ? " - $" + item.priceInfo.price
                        : ""
                    }}
                    {{
                      item.isAvailable
                        ? item.availablePortions &&
                          item.availablePortions.length > 0
                          ? item.alterationPortion
                            ? "(" + item.alterationPortion + ")"
                            : ""
                          : "(" + getDuplicateValueCount(item) + ")"
                        : "NOT AVAILABLE"
                    }}
                  </span>

                    <button
                      class="btn btn-warning btn-adding qty-btn addOverlay"
                      style="
                        border-top-right-radius: 0px !important ;
                        border-bottom-right-radius: 0px !important;
                        border-right: 0px !important;
                      "
                      (click)="
                        changequantityname(
                          toppingIndex,
                          item.availableQuantities,
                          item,
                          selectedModifier.max,
                          item.alterationQuantity
                        )
                      "
                      [disabled]="!item.isAvailable"
                      data-testid="change-quantity-button1"
                    ></button>
                  </mat-button-toggle>
                  <button
                    class="btn btn-warning btn-adding qty-btn"
                    [id]="item.displayName + ' ' + 'add'"
                    (click)="
                      addExtra(
                        item,
                        selectedModifier.max,
                        item.alterationQuantity,
                        true,
                        true
                      )
                    "
                    [disabled]="!item.isAvailable"
                    data-testid="add-button1"
                  >
                    <i class="material-icons">add</i>
                  </button>
                </div>
              </mat-button-toggle-group>
              <button
                disabled
                *ngIf="checkavailable1(selectedModifier)"
                style="background: transparent !important"
                mat-icon-button
              >
                <i
                  class="fa fa-3x fa-exclamation-circle blink"
                  style="
                    margin-top: -10px !important;
                    margin-left: 15px !important;
                  "
                ></i>
              </button>
            </div>
          </div>
          <!-- <mat-divider> </mat-divider> -->
        </div>
      </div>
      <div
        class="no-item-found"
        *ngIf="collmodifiers.length && !modifiersCollection.length"
      >
        No item found
      </div>
    </div>
  </div>
</div>
