import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { emitTransactionsService } from "../../services/emitTransactions.service";
import {
  ActionAddItemToCart,
  ActionApplyPromo,
  ActionClearCart,
  ActionCreateCart,
  ActionGetCart,
  ActionRemoveItem,
  ActionUpdateCartItem,
} from "../action/cart.action";
import { ActionGetCustomer } from "../action/customer.action";

export const cartDefaultState = {
  message: null,
  data: null,
  statusCode: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface cartDefaultState {
  id?: string;
  statusCode?: Number;
  message: String;
  data: any | null;
  isloaded: boolean;
  isError?: boolean;
  errorMessage?: string;
}
@State<cartDefaultState>({
  name: "cart",
  defaults: cartDefaultState,
})
@Injectable()
export class cartState {
  id = "5HjYLTk1AMKswQ3r3tTU";
  constructor(
    private notifier: NotificationService,
    private emitTrans: emitTransactionsService
  ) {}
  @Selector()
  static getCart(state: cartDefaultState) {
    return state.data;
  }

  @Selector()
  static getLoadedCart(state: cartDefaultState) {
    return state;
  }

  @Action(ActionCreateCart)
  createCart(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: false,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      localStorage.setItem(this.id, JSON.stringify(payload.data.cart.id));
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
  }

  @Action(ActionAddItemToCart)
  addItemToCart(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: cart.data,
        isError: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
    this.emitTrans.addItemLoader(false);
  }

  @Action(ActionGetCart)
  getCart(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: cart.data,
        isError: true,
        isloaded: false,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
  }

  @Action(ActionRemoveItem)
  removeItem(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: cart.data,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
      this.emitTrans.removeItemLoader(false);
    }
  }

  @Action(ActionApplyPromo)
  applyPromo(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: {
          ...cart.data,
          cartPromotions: [],
        },
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      // this.notifier.openSnackBarActionV2(
      //   "end",
      //   "top",
      //   payload.error.message,
      //   "danger-snackbar"
      // );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
    this.emitTrans.applypromo(false);

  }

  @Action(ActionUpdateCartItem)
  updateQuantity(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: cart.data,
        isError: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
    this.emitTrans.updateCartLoader(false);
  }

  @Action(ActionClearCart)
  clearCart(
    { setState, getState, patchState }: StateContext<cartDefaultState>,
    { payload }
  ) {
    const cart = getState();

    // console.log(payload);

    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: cart.data,
        isError: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarActionV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const cartState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.cart,
        statusCode: payload.statusCode,
      };
      patchState(cartState);
      // this.notifier.openSnackBarV2(
      //   "end",
      //   "top",
      //   payload.message,
      //   "success-snackbar"
      // );
    }
    this.emitTrans.removeItemLoader(false);
  }
}
