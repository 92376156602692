import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
@Injectable({
  providedIn: "root",
})
export class aiStoreService {
  time: any;
  startAtDT = {
    startAt: null,
    max: null,
    min: null,
  };
  constructor(private http: HttpClient) {}
  getStore(
    correlationid: string,
    clientCode: string,
    identity: string,
    storeId: string
  ) {
    const headers = new HttpHeaders()
      .set("correlationid", correlationid)
      .set("identity", identity)
      .set("x-api-key", environment.agent_dashboard_key)
      .set("clientcode", clientCode);
    return this.http.get(
      `${environment.agent_backend_api_url}api/internal/store/${storeId}`,
      { headers: headers }
    );
  }
}
