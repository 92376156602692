import { Routes } from '@angular/router';
import { SmsMessengerUIComponent } from './ui/ui.component';
export const ChatSmsRoutes: Routes = [{
  path: '',
  redirectTo: 'ui',
  pathMatch: 'full'
},
{
  path: '',
  children: [{
    path: 'ui',
    component: SmsMessengerUIComponent
  }]
}];
