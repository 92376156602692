import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { Permissions } from "./model/permissions.model";
import { NotificationService } from "../notification/notification.service";
import { HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
  providedIn:'root'
})
export class AuthGuard implements CanActivate {
  isAdminBuild = environment['isAdminBuild'];
  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute,
    private notification: NotificationService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!this.authenticationService.isUserLogin()) {
      if (!('isAdminBuild' in environment)) {
        this.router.navigate(["/welcome"], {
          queryParams: { returnUrl: state.url },
        });
      } else {
        const params = new HttpParams()
          .append('returnUrl', window.location.href);
        const adminUrl = environment?.['admin']?.['url'];;
        window.location.href = `${adminUrl}/welcome/?${params}`;
      }

      return false;
    }
      const user = this.authenticationService.getCurrentUser();
      const { permissions } = user || {};
      const isAdminBuildExist = ('isAdminBuild' in environment);
      if (
        (state.url.includes('/dashboard') && !permissions?.includes(Permissions.DASHBOARD_VIEW)) ||
        (state.url.includes('/keywords') && (!permissions?.includes(Permissions.KEYWORD_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/coupon') && (!permissions?.includes(Permissions.PROMO_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/deals') && (!permissions?.includes(Permissions.DEAL_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/roles') && (!permissions?.includes(Permissions.ROLE_VIEW) || (isAdminBuildExist && !this.isAdminBuild))) ||
        (state.url.includes('/agents') && (!permissions?.includes(Permissions.AGENT_VIEW) || (isAdminBuildExist && !this.isAdminBuild))) ||
        (state.url.includes('/order-receive/') && (!permissions?.includes(Permissions.ORDER_ADD) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/chatbot/') && (!permissions?.includes(Permissions.CHATBOT_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/settings') && (!permissions?.includes(Permissions.RESTAURANT_CONFIG_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/store-management') && (!permissions?.includes(Permissions.RESTAURANT_CONFIG_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/notification-management') && (!permissions?.includes(Permissions.NOTIFICATION_VIEW) || (isAdminBuildExist && this.isAdminBuild))) ||
        (state.url.includes('/faqs') && (!permissions?.includes(Permissions.FAQ_VIEW) || (isAdminBuildExist && this.isAdminBuild)))
      ) {
        this.notification.openSnackBarActionV2(
          "end",
          "top",
          `You don't have permission to access that page`,
          "danger-snackbar"
        );
        this.router.navigate(['/not-found']);
        return false;
      }
      return true;
  }
}
