import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VoixPipesModule } from "../pipes/pipes.module";
import { GsDaterangeComponent } from "./gs-daterange/gs-daterange.component";
import { CapitalizePipe } from "./capitalize/capitalize.pipe";
import { VxWizardComponent } from "./vx-wizard/vx-wizard.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { HoursComponent } from "./hours/hours.component";
import { MaterialModule } from "./../../core/modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FeedbackComponent } from "./feedback/feedback.component";
import { OrderInProgressComponent } from "app/order-receive/order-in-progress/order-in-progress.component";
import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { ResetPasswordDialogComponent } from "./reset-password-dialog/reset-password-dialog.component";
import { PromoAutocompleteComponent } from "./promo-autocomplete/promo-autocomplete.component";
import { SafeHtmlPipe } from "./safe-html.pipe";
@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    VoixPipesModule,
    ReactiveFormsModule,
  ],
  declarations: [
    GsDaterangeComponent,
    CapitalizePipe,
    VxWizardComponent,
    HoursComponent,
    OrderInProgressComponent,
    ConfirmationDialogComponent,
    ResetPasswordDialogComponent,
    AlertDialogComponent,
    FeedbackComponent,
    PromoAutocompleteComponent,
    SafeHtmlPipe
  ],
  exports: [
    GsDaterangeComponent,
    CapitalizePipe,
    VxWizardComponent,
    OrderInProgressComponent,
    HoursComponent,
    VoixPipesModule,
    PromoAutocompleteComponent,
    SafeHtmlPipe
  ],
  providers: [],
  entryComponents: [ConfirmationDialogComponent, AlertDialogComponent, FeedbackComponent, ResetPasswordDialogComponent, PromoAutocompleteComponent],
})
export class SharedModule {}
