import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { NotificationService } from '../../core/notification/notification.service';

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registrationForm : FormGroup;
  constructor(fb: FormBuilder,
    private voixHttp: VoixHttpService,
    private notification: NotificationService,
    private router: Router) { 
    this.registrationForm = fb.group({
      'email' : new FormControl('', [Validators.required, Validators.email]),
      'password': [null, [Validators.required, Validators.minLength(8)]]
      // 'terms': [null, Validators.required]
    });
  }

  matcher = new MyErrorStateMatcher();
  hide = true;

  ngOnInit() {
    
  }

  
  submitForm(form: FormGroup) {
    // Once we have our data formed, we’ll send the request using the Angular 2 HTTP library.
    this.voixHttp.post('v1/users', form.value).subscribe (
      (res:any) => {
        if(res.status) {
          form.reset();
          form.markAsPristine();
          this.router.navigateByUrl('/login');
          this.notification.show('success','notifications', 'Please check your e-mail to verfiy the account and continue with account registration process.');
        }
      }, error => {
        let errorHandle = JSON.parse(error);
        this.notification.show('danger','notifications', errorHandle.error);
      }
    );
  }

}
