import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ResolveEnd } from '@angular/router';

import { AuthenticationService } from "./authentication.service";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot):Observable<boolean> | Promise<boolean> | boolean {
    // this will be passed from the route config
    // on the data property
   return new Promise((resolve, reject) => {
      this.auth.userDetail().subscribe((response: any) => {
        const expectedRole = route.data.expectedRole;
        if (environment.role[expectedRole].indexOf(response.user.email) >= 0) {
          resolve(true);
        } else {
          this.router.navigate(['dashboard']);
          resolve(false);
        }
      }, error => {
          this.router.navigate(['dashboard']);
          resolve(false);
      });
    });
  }
}