<h1 mat-dialog-title>Promos of {{client}}</h1>
<div class="main-container">
  <table class="csv-table">
    <thead>
      <tr>
        <th class="heading">
          <div *ngFor="let heading of csvHeadings">
            <span class="heading-title"> {{ heading }} </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <div [formGroup]="form">
          <ng-container formArrayName="csv">
            <ng-container *ngFor="let csvForm of csv.controls; let i = index">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header [class.my-invalid]="csvForm.invalid">
                    <mat-panel-title class="name">
                      <div>
                        <span>{{ csvForm.get('CLIENT_CODE')?.value || '' }}</span>
                      </div>
                      <div *ngIf="client !== 'PJI'">
                        <span>{{ csvForm.get('PRIORITY')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('STORE_ID')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('ORDER_DESTINATION')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('PROMO_CODE')?.value || '' }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div [formGroup]="csvForm" class="accordion-form">
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Client Code</mat-label>
                        <input formControlName="CLIENT_CODE" matInput />
                        <mat-error *ngIf="csvForm.get('CLIENT_CODE')?.invalid">Due Date is required for Tasks</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs" *ngIf="client !== 'PJI'">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Priority</mat-label>
                        <input formControlName="PRIORITY" matInput />
                        <mat-error *ngIf="csvForm.get('PRIORITY')?.invalid">Priority is required for Tasks</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Store Id</mat-label>
                        <input formControlName="STORE_ID" matInput />
                        <mat-error *ngIf="csvForm.get('STORE_ID')?.invalid">Store id is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Order Destination</mat-label>
                        <input formControlName="ORDER_DESTINATION" matInput />
                        <mat-error *ngIf="csvForm.get('ORDER_DESTINATION')?.invalid">Order destination is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Promo Code</mat-label>
                        <input formControlName="PROMO_CODE" matInput />
                        <mat-error *ngIf="csvForm.get('PROMO_CODE')?.invalid">Promo code is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-slide-toggle color="primary" formControlName="CHECKED"></mat-slide-toggle>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </ng-container>
        </div>
      </tr>
    </tbody>
  </table>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button class="submit-btn" mat-button mat-raised-button [disabled]="form.invalid" (click)="onSubmit.emit(form.value)"> Submit </button>
  </div>
</div>