import {Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: "app-bulk-promo-report-modal",
  templateUrl: "./bulk-promo-report-modal.component.html",
  styleUrls: ["./bulk-promo-report-modal.component.scss"],
})
export class BulkPromoReportModal {
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<BulkPromoReportModal>) {}
}