import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn:'root'
})
export class alertNotificationService {
    get$: Observable<any>;
    private get = new Subject<any>();
    public textLoader$ = new BehaviorSubject<string>('');
    constructor() {
      this.get$ = this.get.asObservable();
    }

    set(data) {
      // we can do stuff with data if we want
      this.get.next(data);
    }

    // Service message commands
    emitChange(change: any) {
      this.get.next(change);
    }
}