<div class="wrapper wrapper-full-page">
    <div class="full-page login-page" filter-color="black" data-image="../../assets/img/register.jpg">
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm(resetPasswordForm)" method="#" action="#">
                                <div class="card card-login">
                                    <div class="text-center">
                                        <div class="logo-img">
                                            <img src="/assets/img/voixai.png"/>
                                        </div>
                                        <div class="logo-img text-logo-sub">
                                            <img class="text-logo" src="/assets/img/logo-text-voix.png" />
                                        </div>
                                        <h3 class="card-title">Reset your password</h3>
                                    </div>
                                    <div class="card-content">
                                        <p class="text-center"> You have requested to reset the password for: <strong>"{{userDetails?.email}}"</strong></p>

                                        <h3 class="text-danger" *ngIf="!userDetails">Sorry, this change password link is not valid.</h3>

                                        <div class="input-group" *ngIf="userDetails">
                                            <span class="input-group-addon">
                                                <i class="material-icons">lock_outline</i>
                                            </span>
                                            <div class="form-group label-floating is-empty mt-0">
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="New Password" minlength="8" [formControl]="resetPasswordForm.controls['password']" [type]="hide ? 'password' : 'text'"
                                                    required>
                                                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('required')">
                                                    Password is <strong>required</strong>
                                                    </mat-error>
                                                    <mat-error *ngIf="resetPasswordForm.controls['password'].hasError('minlength')">
                                                    Password must be at least 8 characters long.
                                                    </mat-error>
                                                </mat-form-field>
                                                <span class="material-input"></span>
                                            </div>
                                        </div>

                                        <div class="input-group" *ngIf="userDetails">
                                            <span class="input-group-addon">
                                                <i class="material-icons">lock_outline</i>
                                            </span>
                                            <div class="form-group label-floating is-empty mt-0">
                                                <mat-form-field class="example-full-width">
                                                    <input matInput placeholder="Re-enter New Password" minlength="8" [formControl]="resetPasswordForm.controls['confirm_password']" [type]="hide ? 'password' : 'text'"
                                                    required>
                                                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                    <mat-error *ngIf="resetPasswordForm.controls['confirm_password'].hasError('required')">
                                                    Password is <strong>required</strong>
                                                    </mat-error>
                                                    <mat-error *ngIf="resetPasswordForm.controls['confirm_password'].hasError('minlength')">
                                                    Password must be at least 8 characters long.
                                                    </mat-error>
                                                </mat-form-field>
                                                <span class="material-input"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer text-center" *ngIf="userDetails">
                                        <button type="submit" class="btn btn-primary btn-round submit" [disabled]="!resetPasswordForm.valid">Reset My Password</button>
                                    </div>

                                    <hr>
                                    <div class="w-full text-center p-t-55">
                                        <span class="txt2">
                                            Back to
                                        </span>

                                        <a routerLink='/login' class="txt2 bo1">
                                            Sign in
                                        </a>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-page-background" style="background-image: url(../../assets/img/register.jpg)"></div>
    </div>
</div>
      
      

