import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Select } from "@ngxs/store";
import { cartDefaultState, cartState } from "app/core/ngxs/state/cart.state";
import { Observable } from "rxjs";
import { CartItemResponse } from "../../../mediator-commlibs/interfaces/response/cartItem.interface";

@Component({
  selector: "app-cart-response",
  templateUrl: "./cart-response.component.html",
  styleUrls: ["./cart-response.component.scss"],
})
export class CartResponseComponent implements OnInit {
  @Select(cartState.getCart) cart: Observable<cartDefaultState>;
  isShowed: any = [];
  cartData: CartItemResponse;
  constructor(
    public dialogRef: MatDialogRef<CartResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.cartData = data.order.cart;
  }

  ngOnInit() {}
  close(): void {
    this.dialogRef.close();
  }

  filterItemsOfType(modifier: any[]) {
    const data = modifier.filter((data: any) => data.productSubCategory);
    // console.log(data);
    return data;
  }
  getPapaSize(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    return data;
  }

  getPapaSizeName(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    if (data.length > 0) {
      return data[0].papaSize.name;
    } else {
      return "";
    }
  }

  getPapaSizePrice(modifier: any[]) {
    const data = modifier.filter((data: any) => data.papaSize != null);
    // console.log(data);
    if (data.length > 0) {
      return "$" + data[0].papaSize.price;
    } else {
      return "";
    }
  }

  onClose(): void {}

  getDefault(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.isCustomised && data.alterationCategory
    );
    return data.length;
  }

  getToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.alterationCategory == "TOPPINGS"
    );
    return data.length;
  }

  getDefaultToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) =>
        data.isDefault &&
        !data.isRemoved &&
        data.alterationCategory == "TOPPINGS"
    );
    return data.length;
  }

  getRemovedDToppings(modifiers: any) {
    let data = modifiers.filter(
      (data: any) => data.alterationCategory == "TOPPINGS" && data.isRemoved
    );
    // console.log(modifiers);
    return data.length;
  }

  showDetails(index: number) {
    if (this.isShowed.includes(index)) {
      this.isShowed = this.isShowed.filter((data: any) => data != index);
    } else {
      this.isShowed.push(index);
    }
  }

  activeModifiers(item) {
    return item.modifiers.filter(modifier => !modifier?.isRemoved);
  }
}
