import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  
  transform(value: string, args: string): SafeHtml {
    if (!args) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    const escapedTerm = args.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(escapedTerm, 'gi');
    const replacedValue = value.replace(regex, match => `<span style="background-color: #faf02d"><b>${match}</b></span>`);
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
