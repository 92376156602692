<div class="text-center" [formGroup]="cartform" style="padding: 10px">
  <span class="error-message" *ngIf="!acceptingCurrentOrders">
    <b>
      NOTE : The store is not accepting current {{ selectedOrderType }} order.
      Please place future {{ selectedOrderType }} order.
    </b>
  </span>
  <span
    class="error-message"
    *ngIf="
      !storeDetailV2?.store?.deliverFlag &&
      !storeDetailV2?.store?.dupuFlag &&
      !storeDetailV2?.store?.pickupFlag
    "
  >
    <b>
      {{ storeDetailV2?.store?.storeStatusReasonMessage }}
    </b>
  </span>
  <div class="d-flex">
    <mat-form-field appearance="fill" class="p-2" style="margin-top: 5px" *ngIf="!isAddressOutOfBound">
      <mat-label>Order Destination</mat-label>
      <mat-select
        data-testid="order-dst-field"
        name="orderType"
        formControlName="orderDestination"
      >
        <mat-option
          *ngFor="let orderType of orderTypes"
          [value]="orderType.displayName"
          (click)="setOrderType(orderType.displayName)"
        >
          <b>
            {{ orderType.displayName | uppercase }}
            (Prep Time: {{ orderType.deferPrepMinutes }}m)</b
          >
        </mat-option>
      </mat-select>
      <span *ngIf="orderDestination" style="color: red"
        >This Field is required</span
      >
    </mat-form-field>
    <form
      formGroupName="customer"
      class="w-100"
      *ngIf="
        cartform.get('orderDestination').value == 'DELIVERY' &&
        addressTypeLocation
      "
    >
      <div formGroupName="deliveryAddress" class="w-100">
        <mat-form-field appearance="fill" class="p-2" style="margin-top: 5px">
          <mat-label>Address Types</mat-label>
          <mat-select
            data-testid="address-type-field"
            name="addressType"
            formControlName="addressType"
          >
            <mat-option
              *ngFor="let item of addressTypes | keyvalue"
              [value]="item.value"
              (click)="setAddressTypeType(item)"
            >
              {{ item.value | uppercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="d-flex" *ngIf="customerDetails">
    <mat-form-field
      appearance="fill"
      class="p-2"
      style="margin-top: 5px"
      *ngIf="customerDetails.length > 0"
    >
      <mat-label>Customers</mat-label>
      <mat-select name="customers" [(value)]="selectedCustomer">
        <mat-option
          *ngFor="let customer of customerDetails"
          [value]="customer.id"
          (click)="setCustomerDetail(customer.id)"
        >
          <b *ngIf="customer?.firstName">
            {{ customer.firstName | uppercase }}</b
          >
          <b *ngIf="customer?.lastName"> {{ customer.lastName | uppercase }}</b>
          <b *ngIf="customer?.loyaltyStatus == 'LOYALTY'">
            ({{ customer.loyaltyStatus | uppercase }})</b
          >
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <form formGroupName="customer">
    <div class="d-flex" *ngIf="!isAddressOutOfBound">
      <mat-form-field class="p-2" appearance="outline" color="primary">
        <mat-label>First Name</mat-label>
        <input
          data-testid="first-name-input"
          matInput
          placeholder="First Name"
          formControlName="firstName"
          onkeydown="return /[a-z\d\-_\s]+/i.test(event.key)"
          maxlength="20"
        />
        <span *ngIf="firstNameRequired" style="color: red"
          >This Field is required</span
        >
        <span *ngIf="firstNamePattern" style="color: red"
          >Input is Invalid</span
        >
      </mat-form-field>
      <mat-form-field class="p-2" appearance="outline" color="primary">
        <mat-label>Last Name</mat-label>
        <input
          data-testid="last-name-input"
          matInput
          placeholder="Last Name"
          formControlName="lastName"
          onkeydown="return /[a-z\d\-_\s]+/i.test(event.key)"
          maxlength="20"
        />
        <span *ngIf="lastNameRequired" style="color: red"
          >This Field is required</span
        >
        <span *ngIf="lastNamePattern" style="color: red">Input is Invalid</span>
      </mat-form-field>
    </div>
    <div class="d-flex p-2" *ngIf="!isAddressOutOfBound">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Customer Phone</mat-label>
        <input
          data-testid="customer-phone-input"
          matInput
          placeholder="Phone"
          formControlName="phone"
          onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
          maxlength="10"
          minlength="10"
        />
        <span *ngIf="phoneRequired" style="color: red"
          >This Field is Required</span
        >
      </mat-form-field>
    </div>

    <div
      class="d-flex"
      *ngIf="
        customerDetails &&
        addresses.length > 0 &&
        cartform.get('orderDestination').value == 'DELIVERY'
      "
    >
      <mat-form-field
        appearance="fill"
        class="p-2"
        style="margin-top: 5px"
        *ngIf="customerDetails.length > 0"
      >
        <mat-label>Used Addresses</mat-label>
        <mat-select name="customers">
          <mat-option
            *ngFor="let option of addresses"
            [value]="
              option.streetLine1 + ' ' + option.city + ' ' + option.state
            "
            (click)="setCustomerAddress(option); setValidationFlag(); checkSelectedAddressMatch()"
          >
            <b>
              {{
                option.streetLine1 + " " + option.city + " " + option.state
                  | uppercase
              }}</b
            >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formGroupName="deliveryAddress">
      <div *ngIf="cartform.get('orderDestination').value == 'DELIVERY' && !isAddressOutOfBound">
        <mat-radio-group [formControl]="isBusiness">
          <mat-radio-button
            (click)="emptyAddress()"
            (click)="setBusinness(false)"
            [value]="false"
          >
            {{ "Basic" }}
          </mat-radio-button>
          <mat-radio-button
            style="margin-left: 10px"
            (click)="emptyAddress()"
            (click)="setBusinness(true)"
            [value]="true"
          >
            {{ "Advanced" }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field
        class="example-full-width p-2"
        appearance="fill"
        color="primary"
        *ngIf="cartform.get('orderDestination').value == 'DELIVERY' && !isAddressOutOfBound"
      >
        <mat-label>Search Customer Address</mat-label>
        <input
          type="text"
          placeholder="Customer Address"
          aria-label="Customer Address"
          matInput
          formControlName="streetLine4"
          [matAutocomplete]="googleAddress"
          (keyup)="onChangeAddress($event.target?.value)"
        />
        <button *ngIf="!selectedCustomerAddress" mat-icon-button matSuffix>
          <mat-icon style="color: red">error</mat-icon>
        </button>
        <mat-autocomplete #googleAddress="matAutocomplete">
          <mat-option
            *ngFor="let option of googleaddress; index as i"
            (onSelectionChange)="getPlace(option, i)"
          >
            {{ option.description }}
            <span *ngIf="option?.city && option?.pc"
              >{{ option?.city }} {{ option?.pc }}</span
            >
          </mat-option>
        </mat-autocomplete>
        <span *ngIf="(!selectedCustomerAddress && googleaddress.length === 0)" style="color: red"
          >This Field is required</span
        >
        <span *ngIf="(!googleaddress || googleaddress.length === 0) && getPlaceFlag && !validationFlag"
       style="color: red">
         Please Validate this Address</span
        >
        <span *ngIf="validationFlag && !getPlaceFlag"
       style="color: red">
         Please Validate this Address</span
        >
      </mat-form-field>
      <mat-form-field *ngIf="cartform.get('orderDestination').value == 'DELIVERY'" class="example-full-width p-2" appearance="fill" color="primary">
        <mat-label>Selected Customer Address</mat-label>
        <input
          type="text"
          placeholder="Customer Selected Address"
          aria-label="Customer Selected Address"
          matInput
          value="{{ selectedCustomerAddress }}"
          [disabled]="true"
        />
      </mat-form-field>
    </div>
  </form>

  <div class="d-flex">
    <mat-form-field
      appearance="outline"
      *ngIf="cartform.get('orderDestination').value == 'DELIVERY'"
      data-testid="order-delivery-dst-field"
      class="w-100 p-2"
    >
      <mat-label>Apt/Suite</mat-label>
      <input
        data-testid="apt-suite-field"
        matInput
        placeholder="Apt/Suite"
        formControlName="apt_suite"
      />
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>

    <form
      formGroupName="customer"
      class="w-100"
      *ngIf="
        cartform.get('orderDestination').value == 'DELIVERY' &&
        addressTypeLocation
      "
    >
      <div formGroupName="deliveryAddress" class="w-100">
        <div class="d-flex">
          <mat-form-field
            appearance="outline"
            data-testid="location-name-field"
            class="p-2"
          >
            <mat-label>Location Name</mat-label>
            <input
              data-testid="location-name-input"
              matInput
              placeholder="Location Name"
              formControlName="locationName"
            />
            <span *ngIf="locationNameError" style="color: red">This Field is required </span>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <form formGroupName="customer">
    <div class="d-flex p-2" *ngIf="!isAddressOutOfBound">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Customer Email</mat-label>
        <input
          data-testid="email-input"
          matInput
          placeholder="Email"
          formControlName="email"
          email
          [readonly]="isEmail"
        />
        <span *ngIf="isEmail" style="color: red">NOTE: READONLY</span>
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <div class="d-flex p-2" formGroupName="deliveryAddress">
      <mat-form-field appearance="outline" class="p-2">
        <mat-label>Delivery Instruction</mat-label>
        <input
          data-testid="delivery-instructs-input"
          matInput
          placeholder="Delivery Instruction"
          formControlName="deliveryInstruction"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>

    <div
      class="d-flex"
      style="padding: 10px"
      *ngIf="cartform.get('orderDestination').value == 'DRIVE_UP_PICK_UP'"
    >
      <mat-form-field
        appearance="fill"
        color="primary"
        class="p-2"
        *ngIf="vehicle_brands.length > 0"
      >
        <mat-label>Vehicle Brand</mat-label>
        <input
          type="text"
          placeholder="Select Vehicle Brand"
          aria-label="Vehicle Brand"
          [formControl]="vehicle_brandsControl"
          matInput
          [matAutocomplete]="brand"
          onkeypress="return (event.charCode > 64 && 
event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
        />
        <mat-autocomplete autoActiveFirstOption #brand="matAutocomplete">
          <mat-option
            *ngFor="let option of vehicleBrandOptionsV2 | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <span *ngIf="vehicleBrandRequired" style="color: red"
          >This Field is Required</span
        >
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        color="primary"
        class="p-2"
        *ngIf="vehicle_type.length > 0"
      >
        <mat-label>Vehicle Type</mat-label>
        <input
          type="text"
          placeholder="Select Vehicle Type"
          aria-label="Vehicle Type"
          [formControl]="vehicle_typeControl"
          matInput
          [matAutocomplete]="type"
          onkeypress="return (event.charCode > 64 && 
event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
        />
        <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
          <mat-option
            *ngFor="let option of vehicleTypeOptionsV2 | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <span *ngIf="vehicleTypeRequired" style="color: red"
          >This Field is Required</span
        >
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        color="primary"
        class="p-2"
        *ngIf="vehicle_colors.length > 0"
      >
        <mat-label>Vehicle Color</mat-label>
        <input
          type="text"
          placeholder="Select Color/Enter"
          aria-label="Vehicle Color"
          [formControl]="vehicle_colorControl"
          matInput
          [matAutocomplete]="color"
          onkeypress="return (event.charCode > 64 && 
event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
        />
        <mat-autocomplete autoActiveFirstOption #color="matAutocomplete">
          <mat-option
            *ngFor="let option of vehicleColorOptionsV2 | async"
            [value]="option.value"
          >
            <span class="boxColor" [style.background-color]="option.color">
            </span>
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
        <span *ngIf="vehicleColorRequired" style="color: red"
          >This Field is Required</span
        >
      </mat-form-field>
    </div>
  </form>
  <!-- <div
    class="d-flex p-2"
    style="margin-top: -10px; margin-bottom: 5px; font-size: medium"
  >
    Previous Selected Date:
    {{ storedateTime }}
  </div> -->
  <div class="d-flex p-2" style="margin-top: -10px; margin-bottom: 5px" *ngIf="!isAddressOutOfBound">
    <mat-form-field appearance="outline" class="p-2">
      <input
        data-testid="schedule-date-input"
        matInput
        [owlDateTime]="dt1"
        formControlName="orderReadyDate"
        [owlDateTimeTrigger]="dt1"
        placeholder="Select Order Scheduled Date"
        [min]="min"
        [max]="max"
        readonly
        (dateTimeChange)="onChangeDeliveryPickupTime($event)"
      />
      <span *ngIf="orderReadyDate" style="color: red">
        This Field is required
      </span>
      <button *ngIf="cartform.value.orderReadyDate" mat-icon-button matSuffix>
        <mat-icon (click)="clearDate()">close</mat-icon>
      </button>
      <owl-date-time [startAt]="startAt" #dt1></owl-date-time>
    </mat-form-field>
  </div>
  <span *ngIf="storeList.length" style="color: red" class="blinking-msg">The selected address is outside the delivery range. We are redirecting you to the store that provides delivery. 
    Please select the appropriate option accordingly. </span>
  <div *ngIf="isAddressOutOfBound">
    <div *ngIf="!storeList.length" class="no-store-list-found">
      <span>No stores found</span>
    </div>
    <div *ngIf="storeList.length">
      <mat-accordion multi="true" >
        <mat-expansion-panel *ngFor="let store of displayedStores; let i = index" [expanded]="!!store.expanded" #example>
          <mat-expansion-panel-header (click)="toggleEffect(i);clearDateV2();">
            <mat-panel-title>
              <div class="expansion-title">
                {{ i | numberToAlphabetConverter}}
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <div class="title-section">
                <div class="location-details-first">
                  <span class="location-title">{{store.storeName}}</span>
                  <span class="location-title">PH# {{store?.phoneNumber}}</span>
                </div>
                <div class="location-details-second">
                  <span class="location-title">{{store.street + ', ' + store.city + ' ' + store.state}}</span>
                  <span class="store-closed" *ngIf="store.isStoreClosed">{{store.storeName}} is currently closed &nbsp; <span>Distance: {{store.distance}} Miles.</span></span>
                  <span class="store-open" *ngIf="!store.isStoreClosed">Now Accepting Online Orders &nbsp; <span>Distance: {{store.distance}} Miles.</span></span>
                </div>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="location-info">
            <div class="location-description">
              <div class="accord_iternal_section">
                <div class="d-flex" style="margin-top: -10px; margin-bottom: 5px; display: flex; flex-direction: column;justify-content: center;">
                  <mat-form-field class="no-padding">
                    <input
                      data-testid="schedule-date-input"
                      matInput
                      [owlDateTime]="dtOutofBound"
                      formControlName="orderReadyDateV2"
                      [owlDateTimeTrigger]="dtOutofBound"
                      placeholder="Select Order Scheduled Date"
                      [min]="min"
                      [max]="max"
                      readonly
                      (dateTimeChange)="onChangeOutOfBoundDeliveryPickupTime($event)"
                      style="font-size:14px"
                    />
                    <button *ngIf="cartform.value.orderReadyDateV2" mat-icon-button matSuffix>
                      <mat-icon (click)="clearDateV2()">close</mat-icon>
                    </button>
                    <owl-date-time [startAt]="startAt" #dtOutofBound></owl-date-time>
                  </mat-form-field>
                  <span *ngIf="!store.isAcceptingDeliveryOrder || !store.isAcceptingCarryOutOrder" style="color: red;font-size:14px;margin-top: -12px;">
                    This Field is required
                  </span>
                </div>
                <div class="first-section">
                  <div>Delivery Hours</div>
                  <span>{{store.deliveryHours}}</span>
                  <div>Carryout Hours</div>
                  <span>{{store.carryoutHours}}</span>
                </div>
              </div>
              <div class="second-section">
                <div class="req-section"><span *ngIf="(!store.isAcceptingDeliveryOrder && !!store.isOrderDelivery) && (store.isAcceptingCarryOutOrder != store.isAcceptingDeliveryOrder)" style="color: #ff6d01;font-size:11px;"> Require PAO</span> <button class="order-action-delivery-btn" [disabled]="!store.isOrderDelivery" (click)="selectedAddressFromList('DELIVERY', store)">ORDER DELIVERY </button></div>
                <div class="req-section"><span *ngIf="(!store.isAcceptingCarryOutOrder && !!store.isOrderCarryout) && (store.isAcceptingCarryOutOrder != store.isAcceptingDeliveryOrder)" style="color: #ff6d01;font-size:11px;"> Require PAO</span> <button class="order-action-carryout-btn" [disabled]="!store.isOrderCarryout" (click)="selectedAddressFromList('CARRYOUT', store)">ORDER CARRYOUT</button></div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div *ngIf="displayedStores.length !== storeList.length" class="view-more-stores">
      <span (click)="showMoreStores()">View more stores</span>
    </div>
  </div>
  <div
    class="d-flex p-2"
    *ngIf="storeDetailV2?.store?.storeSpecific?.showOrderLevelNotes"
  >
    <mat-form-field appearance="outline">
      <mat-label>Order Notes</mat-label>
      <input
        data-testid="order-notes-input"
        matInput
        placeholder="Order Notes"
        formControlName="notes"
      />
    </mat-form-field>
  </div>
</div>
<div class="d-flex p-2">
  <span style="width: 100%" *ngIf="!cartExist && !isAddressOutOfBound">
    <button
      data-testid="craete-new-cart-btn"
      [disabled]="validateForm()"
      mat-stroked-button
      color="primary"
      class="btn btn-success"
      style="width: 100%"
      (click)="createCart()"
    >
      CREATE NEW ORDER
      <i *ngIf="validateForm()" class="material-icons">lock</i>
    </button>
  </span>
  <span style="width: 100%" *ngIf="isAddressOutOfBound">
    <button
      data-testid="craete-new-cart-btn"
      [disabled]="validateForm()"
      mat-stroked-button
      class="btn go-back"
      style="width: 100%"
      (click)="goBackToOriginalState()"
    >
      GO BACK
      <i *ngIf="validateForm()" class="material-icons">lock</i>
    </button>
  </span>
  <span style="width: 100%" *ngIf="cartOrder && !isAddressOutOfBound">
    <button
      [disabled]="validateForm()"
      mat-stroked-button
      color="primary"
      class="btn btn-danger"
      style="width: 100%"
      (click)="RemoveAllItems()"
      *ngIf="userPermissions.ORDER_DELETE | permissions"
    >
      REMOVE ALL ITEMS CREATE NEW ORDER
      <i *ngIf="validateForm()" class="material-icons">lock</i>
    </button>
  </span>
  <span
    style="width: 100%; margin-left: 8px"
    *ngIf="(cartState | async) && !isAddressOutOfBound"
  >
    <button
      data-testid="update-cart-btn"
      [disabled]="validateForm()"
      mat-stroked-button
      color="primary"
      class="btn btn-warning"
      style="width: 100%; margin-left: 8px"
      (click)="cloneCart()"
      *ngIf="userPermissions.ORDER_EDIT | permissions"
    >
      UPDATE ORDER
      <i *ngIf="validateForm()" class="material-icons">lock</i>
    </button>
  </span>
</div>

<!-- <pre>{{ cartform.value | json }}</pre> -->

<ngx-ui-loader loaderId="customerLoader"></ngx-ui-loader>
<!-- <pre *ngIf="customerPastOrder | async as pastOrder">{{ pastOrder | json }}</pre>
<pre *ngIf="cartform.value">{{ cartform.value | json }}</pre> -->

<!-- <ngx-custom-google-places-autocomplete
      [placeholder]="'Camping near me...'"
      [autoCompleteOpts]="autoCompleteOpts"
      [customResultTemplate]="sampleTemplate"
      [customResults]="customResults"
      (googlePlaceSelect)="googlePlaceSelect($event)"
      (change)="onChange($event)"
    >
    </ngx-custom-google-places-autocomplete> -->
<!-- <mat-autocomplete #auto="matAutocomplete">
  <mat-option> {{ googlePlacesOptions.types }} </mat-option>
</mat-autocomplete> -->
