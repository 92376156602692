<div class="text-center">
  <label id="example-radio-group-label">REVIEW CART AND CHECKOUT</label>
</div>
<app-order-in-progress></app-order-in-progress>

<mat-divider></mat-divider>

<div *ngIf="generalService.isApplicableForSpecial">
  <div *ngIf="allApplicableItems?.length > 0; else emptySpecial">
    <div class="text-center applicable_item">
      <label id="example-radio-group-label">APPLICABLE PROMOS/DEALS</label>
    </div>
    <div class="promo-table-wrapper">
      <table class="promo-container">
        <thead>
          <th class="th_codeName">Name</th>
          <th>Discount</th>
          <th>Value</th>
          <th>Action</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of allApplicableItems; let i = index">
            <tr>
              <td>
                <div [ngClass]="item?.isLoyaltyOffer && 'loyalty-offer-row'">
                  <span>
                    {{item.altDescription != "" && item.altDescription != null ? item.altDescription : item.description}}
                  </span>
                  <mat-icon [matTooltip]="'Loyalty Offer'" *ngIf="item?.isLoyaltyOffer" class="loyalty-offer-icon">loyalty</mat-icon>
                </div>
              </td>
              <td>${{ calculateDiscount(item) }}</td>
              <td>${{ item?.value ? item?.value : 0 }}</td>
              <td class="text-center">
                <ng-container *ngIf="appliedDeals.includes(item.offerId) else applyButton">
                  <button class="applied"> <i class="material-icons">check</i> Applied</button>
                </ng-container>
                <ng-template #applyButton>
                  <button class="btn btn-primary approve-response apply" matTooltip="Apply" (click)="DealApplied(item)">Apply</button>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <ng-template #emptySpecial>
    <div class="text-center notfoundSpecial">
      <label>No applicable deals/promos found</label>
    </div>
  </ng-template>
</div>

<mat-divider></mat-divider>

<div class="text-center" style="padding: 5px">
  <label id="example-radio-group-label">PAYMENT METHOD</label>
  <p *ngIf="creditCardOptionDisabled('CARD')" class="error-message">The maximum allowable {{generalService.creditCardAttempts}} payment attempts using credit card have been exhausted.</p>
  <p *ngIf="giftCardOptionDisabled('GIFT')" class="error-message">The maximum allowable {{generalService.giftCardAttempts}} payment attempts using gift card have been exhausted.</p>
  <mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="selectedPaymentType"
  [attr.data-testid]="'paymenttype-radio-group-' + selectedPaymentType"
>
  <mat-radio-button
    class="example-radio-button"
    *ngFor="let payType of paymentTypeV2"
    (click)="getPaymentUrl(payType.viewValue)"
    [value]="payType.value"
    [attr.data-testid]="'paymenttype-radio-button-' + payType.value"
    [disabled]="CashOptionDisabled(payType.value) || PaymentOptionDisabled(payType.value) || creditCardOptionDisabled(payType.value) || giftCardOptionDisabled(payType.value)"
  >
    {{ payType.viewValue }}
  </mat-radio-button>
</mat-radio-group>

  <div>
    <div *ngIf="selectedPaymentType == 'CARD'">
      <!-- <app-tip *ngIf="orderDestination != 'CARRYOUT'" [selectedPaymentType]="selectedPaymentType" ></app-tip> -->
      <app-tip [selectedPaymentType]="selectedPaymentType" [attr.data-testid]="'app-tip-' + selectedPaymentType"></app-tip>
      <ng-container *ngIf="!(isIVRenabled && tipAction == 'ADD_TIP'); else paymentProcess"></ng-container>
      <ng-container *ngIf="!isIVRenabled || selectedPaymentProcess === 'AGENT'">
        <app-billing-address
          *ngIf="!isPaymentSupportInternal"
          [paymentAddressRequiredForPayments]="paymentAddressRequiredForPayments"
          [tipAction]="tipAction"
          [attr.data-testid]="'app-billing-address-' + selectedPaymentType"
        ></app-billing-address>
        <div class="d-flex" *ngIf="tipAction == 'ADD_TIP'">
          <!-- <iframe src="http://localhost:4200/transaction/credit-card/1369555649/CON-586c312f-2a30-4e1a-8a0b-85981d0f1bd7/18722486020" style="height: 100%;width: 100%;">
          </iframe> -->

          <iframe
            *ngIf="payment_component_url"
            [src]="payment_component_url"
            style="height: 700px; width: 100%"
            [attr.data-testid]="'payment-iframe-' + selectedPaymentType"
            (load)="iframeLoaded($event)"
          >
          </iframe>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedPaymentProcess === 'IVR'">
        <ng-container *ngTemplateOutlet="submitIVR"></ng-container>
      </ng-container>
    </div>
  </div>

  <div [formGroup]="gift_card" *ngIf="selectedPaymentType == 'GIFT'">
    <app-tip [selectedPaymentType]="selectedPaymentType" [attr.data-testid]="'app-tip-' + selectedPaymentType"></app-tip>
    <ng-container *ngIf="!(isIVRenabled && tipAction == 'ADD_TIP'); else paymentProcess"></ng-container>
    <ng-container *ngIf="!isIVRenabled || selectedPaymentProcess === 'AGENT'">
      <div *ngIf="tipAction == 'ADD_TIP'">
        <mat-form-field appearance="outline" class="p-2">
          <mat-label>Card Number*</mat-label>
          <input
            [attr.data-testid]="'input-cardNumber-' + selectedPaymentType"
            matInput
            placeholder="Card Number"
            formControlName="cardNumber"
            onkeydown="return ( event.ctrlKey || event.altKey 
        || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
        || (95<event.keyCode && event.keyCode<106)
        || (event.keyCode==8) || (event.keyCode==9) 
        || (event.keyCode>34 && event.keyCode<40) 
        || (event.keyCode==46) )"
            maxlength="21"
            minlength="19"
            class="asterisk-mask-input"
          />
          <span *ngIf="ccNumberGCRequired" style="color: red"
            >This Field is required</span
          >
        </mat-form-field>
  
        <div class="d-flex">
          <mat-form-field appearance="outline" class="p-2">
            <mat-label>Pin*</mat-label>
            <input
              [attr.data-testid]="'input-pin-' + selectedPaymentType"
              matInput
              placeholder="Pin"
              formControlName="pin"
              onkeydown="return ( event.ctrlKey || event.altKey 
          || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
          || (95<event.keyCode && event.keyCode<106)
          || (event.keyCode==8) || (event.keyCode==9) 
          || (event.keyCode>34 && event.keyCode<40) 
          || (event.keyCode==46) )"
              class="asterisk-mask-input"
              maxlength="4"
              minlength="4"
            />
            <span *ngIf="pinGCRequired" style="color: red"
              >This Field is required</span
            >
          </mat-form-field>
        </div>
        <div class="d-flex p-2">
          <button
            [attr.data-testid]="'button-submit-order-gift-' + selectedPaymentType"
            [disabled]="gift_card.invalid || PaymentOptionDisabled(selectedPaymentType) || orderSubmitButton"
            mat-stroked-button
            color="primary"
            class="btn btn-success"
            style="width: 100%"
            (click)="submitOrderGift()"
          >
            Submit <i class="material-icons" *ngIf="gift_card.invalid">lock</i>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedPaymentProcess === 'IVR'">
      <ng-container *ngTemplateOutlet="submitIVR"></ng-container>
    </ng-container>
  </div>
  <div class="d-flex p-2" *ngIf="selectedPaymentType == 'CASH'">
    <button
    [attr.data-testid]="'button-submit-order-cash-' + selectedPaymentType"
    mat-stroked-button
    color="primary"
    class="btn btn-success"
    style="width: 100%"
    [disabled]="isCashDisabled || PaymentOptionDisabled(selectedPaymentType)"
    (click)="submitOrderCash()"
  >
    Submit
  </button>  
  </div>
</div>
<ng-template #submitIVR>
  <button
    [attr.data-testid]="'button-' + selectedPaymentProcess"
    mat-stroked-button
    color="primary"
    class="btn btn-success"
    style="width: 100%"
    (click)="emitIVRsocket()"
  >
    Submit with IVR
  </button>  
</ng-template>
<ng-template #paymentProcess>
  <mat-radio-group
    aria-labelledby="payment-process"
    class="example-radio-group"
    [(ngModel)]="selectedPaymentProcess"
    [ngModelOptions]="{standalone: true}"
    [attr.data-testid]="'payment-process-group-' + selectedPaymentProcess"
  >
    <mat-radio-button
      class="example-radio-button"
      *ngFor="let payProcess of ['AGENT', 'IVR']"
      [value]="payProcess"
      (click)="selectPayProcess(payProcess)"
      [attr.data-testid]="'paymentprocess-radio-button-' + payProcess"
    >
      {{ payProcess === 'AGENT' ? 'Agent Assisted' : 'Pay by IVR' }}
    </mat-radio-button>
  </mat-radio-group>
</ng-template>