<div class="d-flex" style="justify-content: space-between; margin-top: 10px">
  <span
    style="color: #536c79; font-weight: 400; font-size: medium; margin-top: 7px"
  >
    {{ "Promo/Coupons" }}
  </span>

  <span>
    <input
      type="text"
      [placeholder]="'Enter Promo/Coupon'"
      aria-label="Promo/Coupons"
      [formControl]="promoControl"
      [matAutocomplete]="promo"
      style="font-weight: bold"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #promo="matAutocomplete"
      [displayWith]="displayPromo.bind(this)"
    >
      <ng-container *ngFor="let option of promosOptionsV2 | async">
        <mat-option
          *ngIf="
            option?.applyLevel.toLowerCase() === 'item' ||
            option?.applyLevel.toLowerCase() === 'multiple'
          "
          [value]="option.promoCode"
          [matTooltip]="
            option.altDescription != '' && option.altDescription != null
              ? option.altDescription + getValue(option.value)
              : option.description + getValue(option.value)
          "
        >
          {{
            option.altDescription != "" && option.altDescription != null
              ? option.altDescription + getValue(option.value)
              : option.description + getValue(option.value)
          }}
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <button
      [attr.data-testid]="'update-notes-button-' + item?.id"
      matTooltip="APPLY PROMO"
      style="color: rgb(175, 27, 27)"
      mat-flat-button
      (click)="applyPromoEmit(item)"
    >
      <i class="material-icons">done</i>
    </button>
    <button
      [attr.data-testid]="'update-notes-button-' + item?.id"
      matTooltip="REMOVE PROMO"
      mat-flat-button
      (click)="removeItemPromo(item)"
      class="remove-promo"
      *ngIf="this.promoControl?.value"
    >
      <i class="material-icons">cancel</i>
    </button>
  </span>
</div>
