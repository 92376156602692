<h3 class="card-title text-center heading-main">Complete Your Account</h3>
<h6 class="sub-heading">{{subText}}</h6>
<div class="wizard-card" data-color="indigo" id="wizard">

  <div class="wizard-navigation">
    <div class="progress-with-circle">
      <div class="progress-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="4" [ngStyle]="{'width.%': progressWidth}"></div>
    </div>
    <ul class="nav nav-pills">
      <li [ngClass]="{'active': tabActiveIndex == 1 || tabActiveIndex == 2 || tabActiveIndex == 3 || tabActiveIndex == 4 }" style="width: 25%;">
        <a href="javascript:void(0)" aria-expanded="true">
          <div class="icon-circle" [ngClass]="{'checked': tabActiveIndex==2 || tabActiveIndex == 3 || tabActiveIndex == 4 }">
            <i class="fa fa-user-o"></i>
          </div>
          <div class="text-custom-width">Restaurant Profile</div>
        </a>
      </li>
      <li [ngClass]="{'active': tabActiveIndex == 2 || tabActiveIndex == 3 || tabActiveIndex == 4 }" style="width: 25%;">
        <a href="javascript:void(0)">
          <div class="icon-circle" [ngClass]="{'checked': tabActiveIndex == 3 || tabActiveIndex == 4 }">
            <i class="fa fa-volume-control-phone"></i>
          </div>
          <div>VOIX Number</div>
        </a>
      </li>
      <li [ngClass]="{'active': tabActiveIndex == 3 || tabActiveIndex == 4 }" style="width: 25%;">
        <a href="javascript:void(0)" aria-expanded="false">
          <div class="icon-circle"  [ngClass]="{'checked': tabActiveIndex == 4 }">
            <i class="fa fa-upload"></i>
          </div>
          <div>Upload Menu</div>
        </a>
      </li>
      
      <li [ngClass]="{'active': tabActiveIndex == 4 }" style="width: 25%;">
        <a href="javascript:void(0)"> <!-- data-toggle="tab" -->
          <div class="icon-circle">
            <i class="fa fa-external-link"></i>
          </div>
          <div>Link POS</div>
        </a>
      </li>
    </ul>
  </div>
</div>  