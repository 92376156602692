import { Component, Input, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GooglePlaceDirective } from "app/core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { Address } from "app/core/directives/ngx-google-places-autocomplete/objects/address";
import { emitTransactionsService } from "app/core/services/emitTransactions.service";
import { ITipModel } from "app/order-receive/tip/model/tip.model";
import { billingAddressPayment } from "./interface/billing-address.interface";
interface billingAddressPaymentFormGroup extends FormGroup {
  value: billingAddressPayment;

  // We need to add these manually again, same fields as IUser
  controls: {
    zip_code: AbstractControl;
  };
}
@Component({
  selector: "app-billing-address",
  templateUrl: "./billing-address.component.html",
  styleUrls: ["./billing-address.component.scss"],
})
export class BillingAddressComponent {
  billing_address: billingAddressPaymentFormGroup;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @Input() tipValue: number | undefined;
  @Input() tipAction: string | undefined | null;
  @Input() paymentAddressRequiredForPayments: boolean;
  googlePlacesOptions: any = {
    strictBounds: true,
    types: [],
  };
  constructor(
    private fb: FormBuilder,
    private emitTrans: emitTransactionsService
  ) {
    this.initiatForm();
    this.getCurrentTipValue();
  }

  getCurrentTipValue() {
    this.emitTrans.getCurrentTipValue$.subscribe((res: ITipModel) => {
      if (res) {
        this.tipValue = res.TipValue;
        //  console.log(this.tipValue);
      }
    });
  }

  initiatForm() {
    this.billing_address = this.fb.group({
      billing_address: [null],
      zip_code: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
    }) as unknown as billingAddressPaymentFormGroup;
    if (!this.paymentAddressRequiredForPayments) {
      this.billing_address.removeControl('billing_address');
    }
  }

  submitAndCallPaymentUrl() {
    this.emitTrans.paymentUrl(this.billing_address.value);
  }

  lineItemAddressChange(address: Address) {
    this.billing_address.patchValue({
      billing_address: address["address"] || address.formatted_address || "",
    });
  }

  inputbehaviour(evt) {
    if (evt == "" || evt == undefined) {
      this.billing_address.patchValue({ billing_address: null });
    } else {
      this.billing_address.patchValue({ billing_address: evt });
    }
  }

  validate() {
    if (!this.validateTip() && !this.validateForm()) {
      return false;
    }
    return true;
  }

  validateTip() {
    if (this.tipAction === "ADD_TIP") {
      return false;
    }
    return true;
  }

  validateForm() {
    if (this.billing_address.valid) {
      return false;
    }
    return true;
  }
}
