import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { startWith } from "rxjs/operators";
import { DataShareService } from "../../core/services/data-share.service";
import { cartPromotions } from "../../../../mediator-commlibs/interfaces/response/cart.interface";
import { DealPopupComponent } from "./deal-popup.component";

@Component({
  selector: "app-view-deals",
  templateUrl: "./view-deals.component.html",
  styleUrls: ["./view-deals.component.scss"],
})
export class ViewDealsComponent implements OnInit, AfterViewInit {
  @ViewChild("filterDeals") txtFilterDeals;
  selectedDeal: any = null;
  deals: any;
  original_deals: any;
  onClickTimer: any;
  isPopupOpen: boolean = false; // Add a flag to control the popup visibility
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private dataShare: DataShareService
  ) { }

  ngOnInit() {
    this.deals = this.data.deals;
    this.original_deals = this.data.deals;
    this.selectedDeal = this.data.selectedDeal;
    this.deals.forEach((x) => (x["isSelected"] = false));
    if (this.data?.cartPromotions?.length) {
      const appliedDeals = this.data?.cartPromotions?.filter((i: cartPromotions) => i.offerType === "DEAL").map((i: cartPromotions) => i.offerId);
      this.deals = this.deals.map(deal => {
        if (appliedDeals?.includes(deal.offerId)) {
          deal.isSelected = true;
        }
        return deal;
      });
    }
  }
  ngAfterViewInit(): void {
    this.shortcutForFilteringDeals();
  }
  shortcutForFilteringDeals() {
    document.addEventListener("keydown", (event) => {
      if (event.altKey && event.key == "f") {
        event.preventDefault();
        this.txtFilterDeals.nativeElement.focus();
      }
    });
  }
  selectDeal(deal) {
    let that = this;
    this.deals.forEach((x) => (x.isSelected = false));
    deal.isSelected = true;
    this.onClickTimer = setTimeout(function () {
      let updateDealValue = {
        action: "selected-deal",
        deal: deal,
      };
      that.dataShare.setSelectedDealFromPopup(updateDealValue);
    }, 50);
  }
  filterData(keyword: string) {
    keyword = (keyword || '').trim().toLocaleLowerCase();
    if (keyword == "" || keyword == null) {
      this.deals = this.original_deals;
    } else {
      this.deals = this.original_deals.filter((x) =>
        x.altDescription != "" && x.altDescription != null
          ? x.altDescription.toLowerCase().includes(keyword)
          : x.description.toLowerCase().includes(keyword)
      );
    }
  }
  selectAndApplyDeal(deal) {
    clearTimeout(this.onClickTimer);
    this.deals.forEach((x) => (x.isSelected = false));
    deal.isSelected = true;
    let updateDealValue = {
      action: "apply-deal",
      deal: deal,
    };
    this.dataShare.setSelectedDealFromPopup(updateDealValue);
    this.dialog.closeAll();
  }

  openPopup(deal: any) {
    var dialogref = this.dialog.open(DealPopupComponent, {
      disableClose: false,
      width: "350px",
      height: "300px",
      data: {
        deal
      }
    })
  }
}
