import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges  } from '@angular/core';
import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { TagReplacePipe } from '../../core/pipes/tag-replace.pipe';
import { ErrorStateMatcher } from '@angular/material/core';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. **/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
export interface SuggestList {
  text: string;
}

@Component({
  selector: 'vx-auto-suggest',
  templateUrl: './auto-suggest.html',
  styleUrls: [],
  providers: [TagReplacePipe]
})
export class AutoSuggestComponent implements OnInit, OnChanges {
  @Input('autoSuggestData') autoSuggestData: any;
  @Input('restaurant') restaurant: any;
  @Output() suggestSelected: EventEmitter<any>  = new EventEmitter();
  suggestedText = new FormControl();
  options: SuggestList[] = [];
  replaceVariableValues = {'address': '', 'open': '10:00 am', 'close': '10:00 pm', 'deliveryFee': '', 'radius': ''};
  filteredOptions: Observable<SuggestList[]>;
  constructor( private tagReplace: TagReplacePipe) {}
  
  ngOnInit() { 
    this.filteredOptions = this.suggestedText.valueChanges
    .pipe(
      startWith<string | SuggestList>(''),
      map(value => {
        if(typeof value === 'string') {
          return value;
        } else {
          return (value) ? value.text : '';
        }
      }),
      map(tokenText => tokenText ? this._filter(tokenText) : this.options.slice())
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    var that = this;
    setTimeout(function() {
      let address = that.restaurant.address;
      that.replaceVariableValues.address = address;
      that.replaceVariableValues.deliveryFee = that.restaurant.delivery_fee;
      that.replaceVariableValues.radius = that.restaurant.delivery_radius;
      //console.log("replaceVariableValues", that.replaceVariableValues);
      that.autoSuggestData.forEach(function(value, index) {
        value.text = that.tagReplace.transform(value.text, that.replaceVariableValues);
        that.options.push(value);
      });
    }, 100);
  }
  
  displayFn(suggestText?: SuggestList): string | undefined {
    return suggestText ? suggestText.text : undefined;
  }

  optionSelected(event) {
    if(event.option.value) {
      this.suggestSelected.emit(event.option.value.text);
    }
  }
  
  onInput(event) {
    if(this.suggestedText.value) {
      this.suggestSelected.emit(this.suggestedText.value);
    }
  }
  private _filter(tokenText: string): SuggestList[] {
    const filterValue = tokenText.toLowerCase();
    return this.filterList(filterValue, this.options); //this.options.filter(option => option.token.toLowerCase().indexOf(filterValue) === 0);
  }

  resetAutocomplete() {
    let asComponent = this;
    setTimeout(function() {
      asComponent.suggestedText.reset();
    }, 500);
  }

  filterList(q, list) {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(" ");
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join("") + ".+",
      "gi"
    );
    return list.filter(item => {
      return searchRegex.test(item.text);
    });
  }

}
