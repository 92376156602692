<div class="main-content no-scroll">
  <div class="container-fluid">
    <div class="col-12"></div>
      <div class="row" *ngIf="responseData">
        <!-- <div class="chatUi">
                    <div *ngFor="let item of responseData; index as i;">
                        <div class="message-wrapper left">
                            <div class="circle-wrapper animated bounceIn"><i class="material-icons">call</i></div>
                            <div class="text-wrapper animated fadeIn">
                                {{item.input_transcript}}
                            </div>
                        </div>

                        <div class="message-wrapper right">
                            <div class="circle-wrapper animated bounceIn"><img src="/assets/img/voixai.png"></div>
                            <div class="text-wrapper animated fadeIn">
                                {{item.voix_response}}
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="col">
          <span> </span>
          <h2 style="text-align: center">
            <img
              style="text-align: center; align-content: center"
              height="100px"
              width="100px"
              src="assets/img/success.png"
              alt=""
            />
            {{ "Your Order Has Been Placed" }}
          </h2>

          <h3 style="text-align: left">
            <span style="padding: 2px">
              <br />
              ORDER ID: <b>{{ responseData.order.data.orderId }}</b>
            </span>
            <span style="padding: 2px" *ngIf="responseData.cart.orderReadyDate">
              <br />
              CustomerQuote Order Date Time:
              <br />
              <b>{{ changeDateFormat(responseData.cart.orderReadyDate) }}.</b>
            </span>
            <span
              style="padding: 2px"
              *ngIf="
                !responseData.cart.orderReadyDate &&
                responseData.order.data.customerQuoteTime > 0
              "
            >
              <br />
              CustomerQuote Time:
              <b>{{ responseData.order.data.customerQuoteTime }}m.</b>
            </span>

            <span style="padding: 2px">
              <br />
              RestaurantOrderNumber:
              <b>{{ responseData.order.data.restaurantOrderNumber }}.</b>
            </span>
            <span
              style="padding: 2px"
              *ngIf="responseData.order.data.order.payment"
            >
              <br />
              Payment Method:
              <b> {{ responseData.order.data.order.payment.paymentType }}.</b>
            </span>
            <span
              style="padding: 2px"
              *ngIf="responseData.order.data.order.payments"
            >
              <br />
              Payment Method:
              <b *ngFor="let item of responseData.order.data.order.payments">
                {{ item.paymentType }}.</b
              >
            </span>
            <span style="padding: 2px">
              <br />
              Payment Amount:
              <b>${{ responseData.order.data.orderTotal }}</b>
            </span>
            <span style="padding: 2px">
              <br />
              Store Address:
              <b
                >{{
                  responseData.store.street + " " + responseData.store.city
                }}
                {{
                  responseData.store.state + " " + responseData.store.zip
                }}.</b
              >
            </span>
            <span
              style="padding: 2px"
              *ngIf="responseData?.cart?.orderDestination == 'DELIVERY'"
            >
              <br />
              Customer Address:
              <b
                >{{
                  responseData.cart.customer.deliveryAddress.streetLine1 +
                    " " +
                    responseData.cart.customer.deliveryAddress.streetLine2
                }}
                {{
                  responseData.cart.customer.deliveryAddress.city +
                    " " +
                    responseData.cart.customer.deliveryAddress.state +
                    " " +
                    responseData.cart.customer.deliveryAddress.zip
                }}.</b
              >
            </span>
          </h3>
        </div>
        <mat-divider></mat-divider>
        <!-- <div>
          <app-order-in-progress></app-order-in-progress>
        </div> -->
        <!-- <div
          style="margin-top: 10px"
          class="reason-pickup"
          *ngIf="feedbackState | async as feedbacks"
        >
          <span style="font-size: 25px; margin-bottom: 10px; text-align: center"
            ><b>Pick your order feedback</b></span
          >
          <mat-checkbox
            style="padding: 2px"
            name="test"
            class="example-radio-button"
            *ngFor="let feedback of feedbacks"
            (click)="selectfeedback(feedback)"
          >
            {{ feedback.title }}
          </mat-checkbox>
        </div> -->
        <div
          class="d-flex"
          [formGroup]="cartNumber"
          *ngIf="!responseData.isCustomer"
        >
          <mat-form-field appearance="outline" class="p-2">
            <mat-label>Customer PhoneNumber</mat-label>
            <input
              matInput
              placeholder="Enter Customer PhoneNumber"
              formControlName="phone"
              onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
              maxlength="10"
              minlength="10"
            />
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
        </div>

        <div *ngIf="loyaltyEnrollStatusBtn">
          <button
            class="btn btn-warning loyalty-enroll-btn"
            (click)="loyaltyEnroll();loyaltyEnrollStatusBtn=!loyaltyEnrollStatusBtn"
            style="width: 100%"
          >
          Transfer to Loyalty Enrollment IVR
          </button>
        </div>

        <div
          class="d-flex"
          style="justify-content: space-between"
          *ngIf="!responseData.isCustomer"
        >
          <button
            class="btn btn-success"
            (click)="submit()"
            style="width: 100%"
          >
            Finish
          </button>
          <button
            [disabled]="cartNumber.invalid"
            class="btn btn-success"
            (click)="placeNewOrder()"
            style="width: 100%"
          >
            CREATE NEW ORDER+
          </button>
        </div>
      </div>
  </div>
</div>
