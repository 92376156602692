<div class="main-container">
  <table class="csv-table">
    <thead>
      <tr>
        <th class="heading">
          <div *ngFor="let heading of csvHeadings">
            <span class="heading-title"> {{ heading }} </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <div [formGroup]="form">
          <ng-container formArrayName="csv">
            <ng-container *ngFor="let csvForm of csv.controls; let i = index">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header [class.my-invalid]="csvForm.invalid" (click)="accordionClick()">
                    <mat-panel-title class="name">
                      <div>
                        <span>{{ csvForm.get('StoreID')?.value|| '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('Phone')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('FirstName')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('LastName')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('Address')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('City')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('State')?.value || '' }}</span>
                      </div>
                      <div>
                        <span>{{ csvForm.get('ZipCode')?.value || '' }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div [formGroup]="csvForm" class="accordion-form">
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>First Name</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Store First Name" id="store-FirstName" formControlName="FirstName" type="text" required />
                        <mat-error *ngIf="csvForm.get('FirstName').touched && csvForm.get('FirstName').errors?.required">First Name is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Last Name</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Store Last Name" id="store-LastName" formControlName="LastName" type="text" required />
                        <mat-error *ngIf="csvForm.get('LastName').touched && csvForm.get('LastName').errors?.required">Last Name is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Store ID</mat-label>
                        <input matInput formControlName="StoreID" placeholder="Enter Store ID" id="store-id" required />
                        <mat-error *ngIf="csvForm.get('StoreID').touched && csvForm.get('StoreID').errors?.required">Store ID is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Phone No</mat-label>
                        <input matInput placeholder="Enter Store Phone" id="phone" type="text" required formControlName="Phone" maxlength="11" maxlength="11" />
                        <mat-error *ngIf="csvForm.get('Phone').touched && csvForm.get('Phone').errors?.required">Phone is required</mat-error>
                        <mat-error *ngIf="csvForm.get('Phone').hasError('pattern')">Please enter only in digits</mat-error>
                        <mat-error *ngIf="!csvForm.get('Phone').hasError('pattern') && csvForm.controls['Phone'].hasError('minlength')">Please enter 11 digits</mat-error>
                        <mat-error *ngIf="!csvForm.get('Phone').hasError('pattern') && csvForm.controls['Phone'].hasError('maxlength')">Please enter 11 digits</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Address</mat-label>
                        <input autocomplete="off" matInput placeholder="Enter Store Address" id="store-Address" formControlName="Address" type="text" required />
                        <mat-error *ngIf="csvForm.get('Address').touched && csvForm.get('Address').errors?.required">Address is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>City</mat-label>
                        <input autocomplete="off" matInput name="city" placeholder="Enter Store City" formControlName="City" id="city" type="text" required />
                        <mat-error *ngIf="csvForm.get('City').touched && csvForm.get('City').errors?.required">City is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>State</mat-label>
                        <input autocomplete="off" matInput name="store-state" placeholder="Enter Store State" formControlName="State" id="store-state" required />
                        <mat-error class="text-danger" *ngIf="csvForm.get('State').touched && csvForm.get('State').errors?.required">State is required</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                      <mat-form-field class="accordion-form-field">
                        <mat-label>Zip Code</mat-label>
                        <input autocomplete="off" matInput name="zip-code" formControlName="ZipCode" placeholder="Enter Store Zip Code" id="zip-code" required type="text" maxlength="6"/>
                        <mat-error class="text-danger"*ngIf="csvForm.get('ZipCode').touched && csvForm.get('ZipCode').errors?.required">Zip code is required</mat-error>
                        <mat-error class="text-danger" *ngIf="csvForm.get('ZipCode').hasError('pattern')">Please enter only in digits</mat-error>
                        <mat-error class="text-danger" *ngIf="!csvForm.get('ZipCode').hasError('pattern') && csvForm.controls['ZipCode'].hasError('minlength')">Zip code is a minimum of 5 digits and max 6 digits</mat-error>
                        <mat-error class="text-danger" *ngIf="!csvForm.get('ZipCode').hasError('pattern') && csvForm.controls['ZipCode'].hasError('maxlength')">Zip code is a minimum of 5 digits and max 6 digits</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="inputs">
                        <mat-label>Secondary Phones</mat-label>
                        <mat-chip-list aria-label="Secondary Phones">
                            <mat-chip *ngFor="let phone of csvForm.get('secondary_phones')?.value" selected="true">
                                {{ phone }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </ng-container>
          </ng-container>
        </div>
      </tr>
    </tbody>
  </table>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close> Cancel </button>
    <button class="submit-btn" mat-button mat-raised-button [disabled]="form.invalid"
      (click)="onSubmit.emit(form.value)"> Submit </button>
  </div>
</div>