import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'textUsNumberFormat'})
export class TextUsNumberFormatPipe implements PipeTransform {
    transform(value:any) {
      if(value) {
        return value.substr(0, 2)+" "+value.substr(2, 3)+"-"+value.substr(5, 3)+"-"+value.substr(8, 4);
      } else {
        return value;
      }
    }
}