import { Component, OnInit, Output, ViewEncapsulation, ElementRef, ViewChild, Input, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VoixHttpService } from '../../../core/voix-http/voix-http.service';
// import { Http } from '@angular/http';
import { getRestaurantService } from '../../../core/auth/restaurant.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-sms-messenger-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})

export class SidebarComponent implements OnInit {
  @Output() eventEmitChatWindow = new EventEmitter();
  @Output() assignToAgent = new EventEmitter();
  @Input() openWindows = [];
  @Input() listOfContacts = [];
  docHeight: any;
  // tslint:disable-next-line:max-line-length
  // listOfContact = [{customer_phone: '12237849821', restaurant_id: 50 }, {customer_phone: '13453235678', restaurant_id: 51 }, {customer_phone: '18749374920', restaurant_id: 50}, {customer_phone: '14657849248', restaurant_id: 46}, {customer_phone: '17648392734', restaurant_id: 51}];
  constructor(
    private voixHttp: VoixHttpService,
    private router: Router,
    private route: ActivatedRoute) {
      this.docHeight = window.innerHeight - 100;
  }
  ngOnInit() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.docHeight = window.innerHeight - 100;
  }

  startConversation(item) {
    this.eventEmitChatWindow.emit(item);
  }

  isOnline(item: any) {
    const isTabWindowOpen = _.findWhere(this.openWindows, {customer_phone: item.customer_phone});
    return !_.isEmpty(isTabWindowOpen);
  }
  assignToMe(item: any) {
    this.assignToAgent.emit(item);
  }

}
