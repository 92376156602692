import { Injectable } from "@angular/core";

//import { Observable } from "rxjs/Observable";
import { map, catchError } from "rxjs/operators";
// import "rxjs/add/operator/map";
// import "rxjs/add/observable/throw";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { DeleteResponse, RestaurantConfigurationResponse, RestaurantResponse } from '../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface';
import { DeletePayload, RestaurantConfigurationPayload } from "../../../../mediator-commlibs/interfaces/request/restaurantSetting.interface";
import { Notification } from "../navbar/navbar.service";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class VoixHttpService {
  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router) {}

  get(url: string, options?: any, backend = false, isAdminUrl = false): Observable<any> {
    var api_endpoint = isAdminUrl ? environment?.['api_endpoint_admin'] : backend ? environment.io_url : environment.api_endpoint;
    return this.http
      .get(api_endpoint + url, this.addJwt(options, ""))
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }
  startConversation(
    body: any = {
      id: "",
      transcript: "",
      channel: "",
      source: 0,
      storeId: "",
    },
    headers: any = {
      correlationid: "",
      identity: "",
      clientCode: "",
      restaurantPhoneNumber: "",
      debug: "",
    }
  ): Observable<any> {
    return this.http
      .post(environment.be_endpoint + "/conversation", body, {
        headers: headers,
      })
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }
  sendToLabelStudio(body: any): Observable<any> {
    return this.http
      .post(environment.be_endpoint + "/feedback/", body)
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getStoresByClient(url: string): Observable<any> {
    return this.http
      .get((!('isAdminBuild' in environment) ? environment['api_endpoint'] : environment?.['api_endpoint_admin']) + url, this.setHeaders())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  getStores(url: string,params): Observable<any> {
    const headers = this.setHeaders();
    const data = {
      headers: headers.headers,
      params
    }    
    return this.http
      .get(environment.api_endpoint + url, data)
      .pipe(
        map((response) => response),
        catchError(this.handleError)
      );
  }
  createNewStore(url:string,payload:any):Observable<any>{
    return this.http.post(environment.api_endpoint + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }
  webHookForStores(url:string,payload:any){
    return this.http.put(environment.io_url + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }
  updateStore(url:string,payload:any):Observable<any>{
    return this.http.put(environment.api_endpoint + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  uploadCsvForStores(url,payload){
    return this.http.post(environment.api_endpoint + url , {data: payload}).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  deleteStore(url:string,options){
    return this.http.delete(environment.api_endpoint + url,options).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  getClientByClientCode(url: string): Observable<any> {
    return this.http
      .get(environment.api_endpoint + url, this.setHeaders())
      .pipe(
        map((response: any) => response),
        catchError(this.handleError)
      );
  }

  deleteRestaurantConfiguration(url: string, body: DeletePayload): Observable<DeleteResponse> {
    return this.http.post<DeleteResponse>(environment.api_endpoint + url, body).pipe(
      map((response: DeleteResponse) => response),
      catchError(this.handleError)
    );
  }

  getRestaurantByPhoneNumber(url: string): Observable<RestaurantResponse> {
    return this.http
      .get<RestaurantResponse>(environment.api_endpoint + url)
      .pipe(
        catchError(this.handleError)
      );
  }

  restaurantConfigurationBulkCreate(url: string, payload: RestaurantConfigurationPayload): Observable<RestaurantConfigurationResponse> {
    return this.http.post<RestaurantConfigurationResponse>(environment.api_endpoint + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  getNotifications(url: string, params): Observable<any> {
    const headers = this.setHeaders();
    const data = {
      headers: headers.headers,
      params
    }    
    return this.http.get(environment.api_endpoint + url, data)
      .pipe(
        map((response) => response),
        catchError(this.handleError)
    );
  }
  
  createNewNotification(url:string,payload:Notification):Observable<any>{
    return this.http.post(environment.api_endpoint + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  updateNotification(url:string,payload:Notification):Observable<any>{
    return this.http.patch(environment.api_endpoint + url, payload).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  deleteNotification(url:string,payload:any):Observable<any>{
    return this.http.delete(environment.api_endpoint + url, { body: payload }).pipe(
      map(response => response),
      catchError(this.handleError)
    );
  }

  post(
    url: string,
    body: any,
    options?: any,
    backend: boolean = false
  ): Observable<any> {
    var api_endpoint = backend ? environment.io_url : environment.api_endpoint;
    return this.http
      .post(api_endpoint + url, JSON.stringify(body))
      .pipe(catchError(this.handleError));
  }

  postSubmitOrderAI(url: string, body: any): Observable<any> {
    var api_endpoint = environment.api_endpoint;
    return this.http
      .post(api_endpoint + url, JSON.stringify(body), this.setHeaders())
      .pipe(catchError(this.handleError));
  }

  put(url: string, body: any, options?: any): Observable<any> {
    return this.http
      .put(environment.api_endpoint + url, body)
      .pipe(catchError(this.handleError));
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    return this.http
      .patch(environment.api_endpoint + url, body)
      .pipe(catchError(this.handleError));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http
      .delete(environment.api_endpoint + url, options)
      .pipe(catchError(this.handleError));
  }

  // private helper methods
  private addJwt(options?: any, method?: string): any {
    // ensure request options and headers are not null
    options = options || {};
    options.headers = options.headers || new Headers();
    options.headers.append("Content-Type", "application/json");
    if (method == "get") {
      options.headers.append(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
    }

    // add authorization header with jwt token
    let agentCurrentUser = JSON.parse(localStorage.getItem("agentCurrentUser"));
    if (agentCurrentUser && agentCurrentUser.token) {
      options.headers.set("Authorization", agentCurrentUser.token);
    }
    return options;
  }

  private setHeaders(): any {
    // ensure request options and headers are not null

    const headers = {
      headers: {
        "x-api-key": environment.api_key,
      },
    };
    return headers;
  }

  private handleError(error: any) {
    console.log(error);
    //console.log(error, error._body, error.status);
    if (error.status === 401) {
      // 401 unauthorized response so log user out of client
      if (('isAdminBuild' in environment)) {
        // remove user from local storage to log user out
        localStorage.removeItem("agentCurrentUser");
        localStorage.removeItem("currentRestaurant");
        this.cookieService.deleteAll('/', environment.ssoSubDomain);
        this.router.navigate(['/welcome']);
        return;
      }
      window.location.href = "/welcome";
    }
    return throwError(error?._body || error?.error);
  }
}
