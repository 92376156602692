import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { environment } from "environments/environment";

import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { IntergrationService } from "../../integration/integration.service";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetCustomer } from "../action/customer.action";

export const customerDefaultState = {
  message: null,
  data: null,
  statusCode: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface customerDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<customerDefaultState>({
  name: "customer",
  defaults: customerDefaultState,
})
@Injectable()
export class customerState {
  constructor(
    private notifier: NotificationService,
    private store: Store,
    private integrationService: IntergrationService
  ) {}
  @Selector()
  static getCustomer(state: customerDefaultState) {
    return state.data;
  }

  @Selector()
  static getCustomerLoaded(state: customerDefaultState) {
    return state.isloaded;
  }
  @Action(ActionGetCustomer)
  getCustomer(
    { setState, getState, patchState }: StateContext<customerDefaultState>,
    { payload }
  ) {
    const customer = getState();
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      if (!environment.production) {
        this.notifier.openSnackBarV2(
          "end",
          "top",
          payload.error.message,
          "warning-snackbar"
        );
      }
    } else {
      const customerState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };
      patchState(customerState);
      //   this.orderReceiveComp.getStoreInfoV2();
    }
  }
}
