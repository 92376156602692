<footer style="display: none">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="http://www.voix.ai" target="_blank"> Home </a>
        </li>
        <!-- <li>
                    <a href="#">
                        Company
                    </a>
                </li>
                <li>
                    <a href="#">
                        Portfolio
                    </a>
                </li>
                <li>
                    <a href="#">
                       Blog
                    </a>
                </li> -->
      </ul>
    </nav>
    <p class="copyright pull-right">
      &copy; {{ test | date: "yyyy" }} <a href="http://www.voix.ai">voix.ai</a>
    </p>
  </div>
</footer>
