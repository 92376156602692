<div class="main-content no-scroll">
  <div class="row container-fluid order-item-form">
    <div *ngFor="let tagResponse of allTagRespones" class="tag-responses">
      <div class="tag-name"><span>{{tagResponse.tag.name}}</span></div>
      <mat-button-toggle-group appearance="legacy">
        <mat-button-toggle (click)="responseSelect(responses.text, responses.id)"  *ngFor="let responses of tagResponse.responses"
          color="primary"
          mat-stroked-button class="button-round"> {{responses.text}} </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
