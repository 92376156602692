<div *ngIf="menuState | async as menusV2 ">
  <div *ngIf="cartLoaded | async as loaded">
    <div *ngIf="loaded?.isloaded != true; else done">
      <!-- <mat-spinner> </mat-spinner> -->
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>
  </div>
  <ng-template #done>
    <div class="container-fluid order-receive-wrpper no-scroll">
      <div class="row">
        <div
          class="bg-white"
          style="margin-top: 10px"
          [ngClass]="{'super-mode': isPermanentTakeout, 'permanent-mode': isPermanentSuperPowerMode}"
          (window:resize)="onResize($event)"
        >
          <!-- <mat-slide-toggle
            class="permanent-super-power-mode"
            [checked]="isPermanentSuperPowerMode"
            (change)="updateSuperMode($event)"
            [disabled]="isPermanentSuperPowerMode"
          ></mat-slide-toggle> -->
          <button
            class="btn btn-primary take-over super-power"
            [disabled]="isPermanentTakeout"
            matTooltip="Handle order by agent using take over flag"
            (click)="setSuperAgentMode()"
          >
            <i class="material-icons"
              >{{isPermanentTakeout ? "flash_on" : "flash_off"}}</i
            >
            Super Power
          </button>
          <button
            class="btn btn-success take-over"
            *ngIf="takeOverFlag"
            matTooltip="Handle order by agent using take over flag"
            (click)="setTakeOverFlag(false)"
          >
            Auto Mode
          </button>
          <div
            class="no-scroll"
            style="height: calc(100vh - 210px); width: 100%;"
          >
          <div class="col-12">
            <div class="row">
              <div class="col-12 takeOverForm" *ngIf="takeOverFlag">
                <app-order-items
                  [restaurant_details]="restaurant_details"
                  [menusV2]="menusV2"
                  (noteEvent)="removeNotes($event)"
                ></app-order-items>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-6 topping-col">
                    <div class="form-slots" *ngIf="!takeOverFlag">
                      <mat-form-field
                        appearance="fill"
                        *ngIf="storeDetailV2?.store?.storeSpecific?.showItemLevelNotes"
                      >
                        <mat-label>Item Notes</mat-label>
                        <input
                          data-testid="left-item-notes-input"
                          matInput
                          placeholder="Item Notes"
                          [(ngModel)]="notes.leftNotes"
                        />
                      </mat-form-field>
                      <app-order-items
                        [restaurant_details]="restaurant_details"
                        [menusV2]="menusV2"
                        (noteEvent)="removeNotes($event)"
                      ></app-order-items>
                    </div>
                  </div>
                  <div class="col-6 topping-col">
                    <div *ngIf="!isEdit || (isEdit && !isSingleProductEdit)">
                      <!-- <div *ngIf="!cartState">
                        <mat-progress-bar mode="query"></mat-progress-bar>
                      </div> -->
                      <div>
                        <mat-form-field
                          appearance="fill"
                          *ngIf="storeDetailV2?.store?.storeSpecific?.showItemLevelNotes && !isHalfAndHalf"
                        >
                          <mat-label>Item Notes</mat-label>
                          <input
                            data-testid="right-item-notes-input"
                            matInput
                            placeholder="Item Notes"
                            [(ngModel)]="notes.rightNotes"
                          />
                        </mat-form-field>
                        <app-order-items-replica
                          [restaurant_details]="restaurant_details"
                          [menusV2]="menusV2"
                          (noteEvent)="removeNotes($event)"
                        ></app-order-items-replica>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>       
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="routeName == 'drive-thru'">
  <iframe [src]="drive_thru_url" style="width: 100%;height: 100vh;"></iframe>
</div>
<!-- <mat-spinner
  *ngIf="emitTran.addItemCartLoader$ | async"
  style="position: absolute; top: 50%; left: 50%"
>
</mat-spinner> -->

<!-- <mat-spinner
  *ngIf="emitTran.removeItemCart$ | async"
  style="position: absolute; top: 50%; left: 50%"
>
</mat-spinner> -->

<!-- <mat-spinner
  *ngIf="emitTran.createCart$ | async"
  style="position: absolute; top: 50%; left: 50%"
> -->
<!-- </mat-spinner>
<mat-spinner
  *ngIf="emitTran.updateCart$ | async"
  style="position: absolute; top: 50%; left: 50%"
> -->
<!-- </mat-spinner> -->

<footer style="position: fixed; bottom: 0px; background-color: white" *ngIf="!isDriveThruUrl">
  <div class="col-md-12 slots-approve">
    <div class="row">
      <div class="row col-md-8">
        <div *ngIf="emitTran.getPromo$ || emitTran.getDeals$  | async  ">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div>
  
        <!-- <div *ngIf="!cartState">
          <mat-progress-bar mode="query"></mat-progress-bar>
        </div> -->
        <div>
          <div class="d-flex">
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              color="primary"
              floatLabel="always"
            >
              <mat-label>{{'Promo/Coupons'}}</mat-label>
              <input
                type="text"
                [placeholder]="'Enter Promo/Coupon'"
                aria-label="Promo/Coupons"
                matInput
                [formControl]="promoControl"
                [matAutocomplete]="promo"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #promo="matAutocomplete"
                [displayWith]="displayPromo.bind(this)"
              >
                <ng-container *ngFor="let option of promosOptionsV2 | async">
                  <div [ngClass]="option?.isLoyaltyOffer && 'promo-coupon-loyalty-offer'" >
                    <mat-option
                      [value]="option.promoCode"
                      [matTooltip]="
                      option.altDescription != '' && option.altDescription != null ?
                      option.altDescription + getValue(option.value) : option.description + getValue(option.value)
                    " (click)="option.isManualDiscount? openPopup(option):getValue(option.value)"
                    >
                      {{option.altDescription != "" && option.altDescription != null
                      ? option.altDescription + getValue(option.value) :
                      option.description + getValue(option.value)}}
                  </mat-option>
                  <mat-icon [matTooltip]="'Loyalty Offer'" *ngIf="option?.isLoyaltyOffer" class="loyalty-offer-icon">loyalty</mat-icon>
                  </div>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <div>
              <button
                [disabled]="checkPromoTemp()"
                class="btn btn-success"
                style="float: right; height: 73%; width: max-content"
                (click)="applyPromo(true)"
              >
                APPLY PROMO<i *ngIf="checkPromoTemp()" class="material-icons"
                  >lock</i
                >
              </button>
            </div>
            <!-- <mat-form-field
              class="example-full-width"
              appearance="fill"
              color="primary"
            >
              <mat-label
                >{{alldeals.length == 0 ? 'NO DEAL APPLICABLE'
                :'Deals'}}</mat-label
              >
              <input
                type="text"
                [placeholder]="alldeals.length == 0 ? 'NO DEAL APPLICABLE'
                :'Enter/Select Deals'"
                aria-label="Deals"
                matInput
                [formControl]="dealControl"
                [matAutocomplete]="deals"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #deals="matAutocomplete"
                [displayWith]="displayDeals.bind(this)"
              >
                <mat-option
                  *ngFor="let option of dealsOptionsV2 | async"
                  [value]="option.promoCode"
                  [matTooltip]="
                  option.altDescription != '' && option.altDescription != null ?
                  option.altDescription + ' - $' + option.value : option.description + ' - $' + option.value
                "
                >
                  {{option.altDescription != "" && option.altDescription != null ?
                  option.altDescription + ' - $' + option.value :
                  option.description + ' - $' + option.value}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              color="primary"
              floatLabel="always"
            >
              <mat-label>{{'Deal/Coupons'}}</mat-label>
              <input
                type="text"
                [value]="selectedDealText"
                readonly
                matInput
                (click)="OpenDealsPopup()"
                [disabled]="alldeals.length == 0 || isEdit "
                [placeholder]="alldeals.length == 0 ? 'NO DEAL APPLICABLE'
            :'Enter/Select Deals'"
                aria-label="Deals"
                matInput
            /></mat-form-field>
            <div>
              <button
                [disabled]="checkDeals() || !dealControl.value"
                class="btn btn-success"
                style="float: right; height: 73%; width: max-content"
                (click)="applyPromo(false)"
              >
                APPLY DEALS
                <i *ngIf="checkDeals()" class="material-icons">lock</i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div *ngIf="cartState | async as cartData">
          <mat-card>
            <div>
              <div class="d-flex" style="justify-content: space-between">
                <mat-icon
                  class="mat-icon"
                  style="float: left"
                  role="img"
                  aria-hidden="true"
                  >person</mat-icon
                >
                <div style="margin-left: 5px; margin-right: 5px">
                  <b>
                    {{ (cartData.customer.firstName | uppercase) + " " +
                    (cartData.customer.lastName | uppercase) }}
                  </b>
                </div>
                <div style="margin-left: 5px">
                  <b>({{ cartData.orderDestination }})</b>
                </div>
  
                <div
                  *ngIf="emitTran.customerLoyalty$ | async"
                  style="margin-left: 5px; margin-top: -3px"
                >
                  <i class="material-icons">star</i>
                </div>
                <button
                  mat-flat-button
                  (click)="showCustomerPopup()"
                  style="color: rgb(71, 214, 71); margin-top: -8px"
                >
                  VIEW
                </button>
              </div>
            </div>
          </mat-card>
        </div>
        <!-- <div
          class="sip-session"
          style="float: right"
          *ngIf="getElapsedTime() as elapsed"
        >
          <button
            style="
              pointer-events: none;
              border: none;
              font-size: xx-large;
              float: right;
            "
          >
            <b>
              {{ elapsed.minutes < 10 ? 0 : "" }}{{ elapsed.minutes }} : {{
              elapsed.seconds < 10 ? 0 : "" }}{{ elapsed.seconds }}
            </b>
          </button>
        </div> -->
        <!-- <vx-auto-suggest
          *ngIf="restaurant_details"
          [autoSuggestData]="customResponseArray"
          (suggestSelected)="transferAgentResponse($event, true)"
          (clearAutocompleteText)="resetValue($event)"
          [restaurant]="restaurant_details"
        ></vx-auto-suggest> -->
        <!-- <button class="btn btn-primary" (click)="retriveCustomResponseObject()" matTooltip="Pre reponse Buttons">Pre Responses</button> -->
      </div>
    </div>
  </div>
  <div>
    <div class="row col-md-12 take-over-section-button confirm-section">
      <div class="col-md-8 order-bottom-address-card pe-2 footer-row-buttons" *ngIf="!smsTakeOver">
        <button
          [disabled]="formsValidation()"
          class="btn btn-success add-to-cart"
          *ngIf="!takeOverFlag && !isEdit"
          (click)="modifyCart()"
        >
          Add to Cart<i *ngIf="formsValidation()" class="material-icons"
            >lock</i
          >
        </button>
        <button
          [disabled]="formsValidation()"
          class="btn btn-success add-to-cart"
          *ngIf="!takeOverFlag && isEdit"
          (click)="editCartItem()"
        >
          Edit Item<i *ngIf="formsValidation()" class="material-icons">lock</i>
        </button>

        <button
          *ngIf="pastOrders | async as pastOrder"
          [disabled]="pastOrder.length == 0"
          class="btn btn-default approve-response"
          matTooltip="Checkout order list"
          (click)="openOrderDialog()"
        >
          <i class="material-icons">view_list</i>PAST ORDERS<i
            class="material-icons"
            *ngIf="pastOrder.length == 0"
            >lock</i
          >
        </button>
        <button
          [disabled]="liveItems.length == 0"
          class="btn btn-primary approve-response"
          matTooltip="SUBMIT ORDER"
          (click)="submitOrderSocket(false,null); executeAutoApply()"
        >
          SUBMIT ORDER<i class="material-icons" *ngIf="liveItems.length == 0"
            >lock</i
          >
        </button>
        <!-- <button (click)="showData()" class="btn btn-danger approve-response">
          DEBUG
        </button> -->
      </div>
      <div class="col-md-4 special-instruction-text d-flex align-items-center">
        <div class="w-100" *ngIf="cartState | async as cartData">
          <mat-card class="d-flex align-items-center" *ngIf="cartData.orderDestination == 'DELIVERY'">
            <div class="d-flex" style="justify-content: space-evenly">
              <mat-icon
                class="mat-icon"
                style="float: left"
                role="img"
                aria-hidden="true"
                >location_pin</mat-icon
              >
              <div
                *ngIf="cartData.orderDestination == 'DELIVERY'"
                style="margin-left: 5px"
              >
                <span
                  *ngIf="
                      cartData?.customer?.deliveryAddress.streetLine2 != '' &&
                      cartData?.customer?.deliveryAddress.streetLine2 != null
                    "
                >
                  <b>
                    {{ cartData?.customer?.deliveryAddress.streetLine2 |
                    uppercase }}
                  </b>
                </span>
                <b
                  >{{ cartData.customer.deliveryAddress.streetLine4 | uppercase
                  }}
                </b>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <button mat-raised-button class="btn btn-primary vertical-publish" [disabled]="publishSlotsDisabled" *ngIf="!takeOverFlag" (click)="publishSlots()" value="Add to Cart"></button> -->
