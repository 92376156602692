import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { emitTransactionsService } from "../../services/emitTransactions.service";
import { ActionGetPromo, ActionGetSpecialPromo } from "../action/promo.action";

export const promoDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  specialPromos: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface promoDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  specialPromos: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<promoDefaultState>({
  name: "promo",
  defaults: promoDefaultState,
})
@Injectable()
export class promoState {
  constructor(
    private notifier: NotificationService,
    private emittrans: emitTransactionsService
  ) {}
  @Selector()
  static getPromo(state: promoDefaultState) {
    return state;
  }
  @Selector()
  static getAllPromo(state: promoDefaultState) {
    return state?.data || [];
  }

  @Action(ActionGetPromo)
  createCart(
    { setState, getState, patchState }: StateContext<promoDefaultState>,
    { payload }
  ) {
    const promos = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: `GET_PROMOS: ${payload.error.message}`,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const promoState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data.promos,
        statusCode: payload.statusCode,
      };
      patchState(promoState);
      //   this.orderReceiveComp.getStoreInfoV2();
    }
  }

  @Action(ActionGetSpecialPromo)
  getSpecialPromos(
    { setState, getState, patchState }: StateContext<promoDefaultState>,
    { payload }
  ) {
    const promos = getState();
    const promoState = {
      message: "Successfull",
      isloaded: true,
      data: promos.data,
      specialPromos: payload?.data?.promos || [],
      statusCode: payload.statusCode,
    };
    patchState(promoState);
  }
}
