import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  private textToConvert: string;
  private readonly password: string = "EZRAAIENCRYPTION";
  private actualData: string;
  halfMenu: string;
  remainingMenu: string;
  constructor() {}
  ClearStorage(): any {
    localStorage.clear();
    sessionStorage.clear();
  }
  Encrypting(SetName: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.actualData = "";
        this.textToConvert = JSON.stringify(data);
        this.actualData = CryptoJS.AES.encrypt(
          this.textToConvert,
          this.password
        ).toString();

        localStorage.setItem(`${SetName}`, this.actualData);
        resolve(true);
      } catch (error) {
        reject(new Error("Encryption Failed"));
      }
    });
  }

  EncryptingMenu(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.actualData = "";
        this.textToConvert = JSON.stringify(data);
        this.actualData = CryptoJS.AES.encrypt(
          this.textToConvert,
          this.password
        ).toString();
        const halfLength = this.actualData.length / 2;
        this.halfMenu = this.actualData.substring(1, halfLength);
        this.remainingMenu = this.actualData.substring(
          halfLength,
          this.actualData.length
        );
        console.log(this.halfMenu, this.remainingMenu);
        localStorage.setItem(`form1h`, this.halfMenu);
        localStorage.setItem(`form2h`, this.remainingMenu);
        resolve(true);
      } catch (error) {
        reject(new Error("Encryption Failed"));
      }
    });
  }

  DecryptWithoutPromiseMenu() {
    this.textToConvert =
      localStorage.getItem(`form1h`) + localStorage.getItem("form2h");
    this.actualData = CryptoJS.AES.decrypt(
      this.textToConvert,
      this.password
    ).toString(CryptoJS.enc.Utf8);
    var Decrypted = JSON.parse(this.actualData);
    return Decrypted;
  }

  DecryptWithoutPromise(GetName: any) {
    this.textToConvert = localStorage.getItem(`${GetName}`);
    this.actualData = CryptoJS.AES.decrypt(
      this.textToConvert,
      this.password
    ).toString(CryptoJS.enc.Utf8);
    var Decrypted = JSON.parse(this.actualData);
    return Decrypted;
  }
}
