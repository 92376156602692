import { Component, Inject, OnInit } from '@angular/core';

import { FormControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { KeyValuesPipe } from '../../core/pipes/key-values.pipe';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { menuService } from '../../core/services/menu.service';
import { UcfirstPipe } from '../../core/pipes/ucfirst.pipe';
import { OrderReceiveService } from '../../core/customer/order-receive.service';
import * as _ from 'underscore';
import { NotificationService } from '../../core/notification/notification.service';

import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-line-item-dialog',
  templateUrl: './line-item-dialog.component.html',
  styleUrls: ['./line-item-dialog.component.scss'],
  providers: [ UcfirstPipe, KeyValuesPipe ]
})
export class LineItemDialogComponent implements OnInit {
  lineItemForm: FormGroup;
  responseData: any;
  lineItemId: number;
  menuItems: any = [];
  quantityList:any = [0.5, 1, 2, 3, 4, 5];
  categories: any;
  collectionList: any = {};
  itemDetails: any;
  public submit$: Observable<any>;
  oldToppingValue: any = [];
  constructor(public dialogRef: MatDialogRef<LineItemDialogComponent>, public fb: FormBuilder, private voixHttp: VoixHttpService, private menuservice: menuService, orderservice: OrderReceiveService,  private Ucfirst: UcfirstPipe,  private KeyValues: KeyValuesPipe, private notification: NotificationService, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.responseData = data;
      //console.log(this.responseData);
      this.lineItemForm  = fb.group({
        'name' : [null, Validators.required],
        'type': [null, Validators.required],
        'quantity': [null, Validators.required],
        'removedToppings': [],
        'extraToppings': []
      });
      let menuEntities = orderservice.getEntities(this.responseData.menu.MenuItems);
      this.categories = menuEntities.category;

      if(menuEntities) {
        let that = this;
        _.each(menuEntities.available_variation, function (value, key) {
          if(value.allow_multiple) {
            that.lineItemForm.addControl(value.name,  new FormControl([]));
          } else {
            that.lineItemForm.addControl(value.name, new FormControl(null));
          }
        });
      }
      //console.log(this.lineItemForm);
      if(!_.isEmpty(this.responseData.items)) {
        this.updateFormValue();
      }
    }
    ngOnInit() { }
    updateFormValue() {
      this.lineItemId = this.responseData.items.id;
      this.itemDetails = (this.responseData.items.details) ? JSON.parse(this.responseData.items.details) : {};
      let that = this;
      this.lineItemForm.patchValue({type: this.itemDetails.type ? this.itemDetails.type.toLowerCase() : ''});
      this.filterMenu();
      this.lineItemForm.patchValue({name: this.itemDetails.name ? this.itemDetails.name.toLowerCase() : ''});
      this.filterVariation();
      Object.keys(this.itemDetails).forEach(function(key, index) {
        let obj = {};
        if(key != 'type' && key != 'name') {
          if(key == "quantity") {
            obj[key] = that.itemDetails[key].toString();
          } else {
            obj[key] = that.itemDetails[key];
          }
          that.lineItemForm.patchValue(obj);
        }
      });
      if(_.has(this.lineItemForm.value, "toppings")) {
        this.oldToppingValue = this.lineItemForm.value.toppings;
      }

    }

    updateProperty(event: MatSelectChange, field) {
      if(_.has(this.lineItemForm.value, field)) {
        let addedToppings = _.difference(this.lineItemForm.value.toppings, this.oldToppingValue);
        let removeToppings = _.difference(this.oldToppingValue, this.lineItemForm.value.toppings);
      
        if(!_.isArray(this.lineItemForm.value.extraToppings)) { this.lineItemForm.patchValue({extraToppings: []});
        }

        if(!_.isArray(this.lineItemForm.value.removedToppings)) { this.lineItemForm.patchValue({removedToppings: []}); }

        addedToppings = this.lineItemForm.value.extraToppings.concat(addedToppings);
        removeToppings = this.lineItemForm.value.removedToppings.concat(removeToppings);
        this.lineItemForm.patchValue({extraToppings: _.uniq(addedToppings)});
        this.lineItemForm.patchValue({removedToppings: _.uniq(removeToppings)});
      }
    }

    filterMenu() {
      this.menuItems = this.menuservice.getFoodTypeMenu(this.responseData.menu.MenuItems, this.lineItemForm.value.type);
    }
    filterVariation() {
      this.collectionList = this.menuservice.getVariationCollections(this.lineItemForm.value.name, this.responseData.menu.MenuItems, this.lineItemForm.value.type);
      //console.log("Collection List", this.collectionList, this.KeyValues.transform(this.collectionList));
    }
    onClose(): void {
      this.dialogRef.close();
    }

    updateForm(value) {
      const lineItemValues = {...this.itemDetails, ...value};
      if(this.lineItemId) {
        this.submit$ = this.voixHttp.put('v1/agent/restaurant/'+ this.responseData.restaurant_id.toString() + '/order/'+ this.responseData.order_id.toString() +'/line-item/'+this.lineItemId, lineItemValues);
      } else {
        this.submit$ = this.voixHttp.post('v1/agent/restaurant/'+ this.responseData.restaurant_id.toString() + '/order/'+ this.responseData.order_id.toString() +'/line-item', lineItemValues);
      }
      this.submit$.subscribe (
        (res:any) => {
          if(res.status) {
            this.notification.show('success','notifications', 'Order line item has been Updated successfully.');
            this.dialogRef.close();
          }
        }, error => {
          let errorHandle = JSON.parse(error);
          this.notification.show('danger','notifications', errorHandle.error);
        }
      );
    }
}
