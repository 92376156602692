import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormArray, ReactiveFormsModule } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BulkUploadService } from "../../modify-coupon/bulk-report-modal/bulk-upload-service";
import { CSVData, ComponentKeyEnum, ComponentNameEnum, RestaurantConfigurationCSV, RestaurantConfigurationRequest } from "../../../../mediator-commlibs/interfaces/request/restaurantSetting.interface";
import { VoixHttpService } from "app/core/voix-http/voix-http.service";
import { MessageDialogComponent } from "./message-dialog.component";
import { RestaurantConfigurationResponse } from "../../../../mediator-commlibs/interfaces/response/restaurantSetting.interface";

@Component({
  selector: "app-uploaded-csv-list",
  templateUrl: "./uploaded-csv-list.component.html",
  styleUrls: ["./uploaded-csv-list.component.scss"],
})
export class UploadedCsvListComponent implements OnInit {
  csvHeadings = [
    "restaurant_id",
    "component_name",
    "component_key",
    "component_value"
  ];
  form = this.fb.group({
    csv: this.fb.array([]),
  });
  public client: string;
  onSubmit = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private bulkUploadService: BulkUploadService,
    private voixHttp: VoixHttpService,
    public dialogRef: MatDialogRef<UploadedCsvListComponent> // Add MatDialogRef
  ) {
    data.map((item) => {
      this.csv.push(
        this.fb.group({
          restaurant_id: [item.restaurant_id, Validators.required],
          component_name: [item.component_name, Validators.required],
          component_key: [item.component_key, Validators.required],
          component_value: [item.component_value],
        })
      );
    });
  }

  ngOnInit() {}

  formatCSVData(data: CSVData[]): RestaurantConfigurationCSV[] {
    // Map the CSV data to the RestaurantConfigurationCSV interface
    return data.map((item) => ({
      restaurant_id: Number(item.restaurant_id),
      component_name: ComponentNameEnum[item.component_name],
      component_key: ComponentKeyEnum[item.component_key],
      component_value: item.component_value,
    }));
  }

  submit() {
    if (this.form.invalid) {
      return;
  }

    const formattedData = this.formatCSVData(this.csv.value);

    if (!Array.isArray(formattedData)) {
      console.error("Formmatted Data is not valid:", formattedData);
      return;
    }

    this.onSubmit.emit(formattedData);
  }
  
  get csv() {
    return this.form.controls["csv"] as FormArray;
  }

  // Utility function for Title Case conversion
  toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}