import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormArray } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-uploaded-csv-list",
  templateUrl: "./uploaded-csv-list.component.html",
  styleUrls: ["./uploaded-csv-list.component.scss"],
})
export class UploadedCsvListComponent implements OnInit {
  csvHeadings = [
    "CLIENT_CODE",
    "PRIORITY",
    "STORE_ID",
    "ORDER_DESTINATION",
    "PROMO_CODE",
    "AUTO_APPLY"
  ];
  form = this.fb.group({
    csv: this.fb.array([]),
  });
  onSubmit = new EventEmitter();
  public client: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}
  ngOnInit() {
    if (this?.client == 'PJI') {
      this.csvHeadings.splice(1, 1);
    }
    this.data.map((item) => {
      const formControls = {
        CHECKED: [true, Validators.required],
        CLIENT_CODE: [item.CLIENT_CODE ?? "", Validators.required],
        PRIORITY: [item.PRIORITY || 100, Validators.required],
        STORE_ID: [item.STORE_ID ?? "", Validators.required],
        ORDER_DESTINATION: [
          item.ORDER_DESTINATION ?? "",
          Validators.required,
        ],
        PROMO_CODE: [item.PROMO_CODE ?? "", Validators.required],
        ACTIVE: [item.AUTO_APPLY.toLowerCase() == "true" ? true : false, Validators.required],
      };
      if (this?.client == 'PJI') {
        delete formControls.PRIORITY;
      }
      this.csv.push(
        this.fb.group(formControls)
      );
    });
  }

  get csv() {
    return this.form.controls["csv"] as FormArray;
  }
}
