import {
  CartMenuItem,
  Modifier,
} from "../../../../mediator-commlibs/interfaces/request/cartItem.interface";
import {
  Items,
  Size_Variations,
  Style_Variations,
  AddOn,
  AddOn_Variations,
} from "../../../../mediator-commlibs/interfaces/response/menu.interface";
import { CartMenuItemDetails } from "../interface/cartmenuItem-state.interface";

export function prepareMenuItemForCart(
  cartMenuItemDetails: CartMenuItemDetails
): CartMenuItem {
  const item = cartMenuItemDetails.items[0];
  const cartMenuItem: CartMenuItem = buildItem(
    item,
    cartMenuItemDetails.selectedQuantity
  );
  const sizeNode = cartMenuItemDetails.sizes[0];
  const sizeModifier: Modifier = builSizeModifier(sizeNode);
  const styleNode: Style_Variations = cartMenuItemDetails.styles[0];
  const styleModifier: Modifier = buildStyleModifier(styleNode);
  cartMenuItem.modifiers.push(sizeModifier, styleModifier);
  cartMenuItem.modifiers.push(
    ...buildSelectedAddOnModifier(cartMenuItemDetails.selectedAddonVariations)
  );
  return cartMenuItem;
}
export function buildItem(item: Items, quantity: number): CartMenuItem {
  const cartItem: CartMenuItem = {
    name: item.name,
    displayName: item.displayName,
    externalId1: item.externalId1,
    externalId2: item.externalId2,
    externalId3: item.externalId3,
    isAvailable: item.isAvailable,
    allowHalf: item.allowHalf,
    allowSide: item.allowSide,
    keywords: "" || "",
    aiMenu: item.aiMenu,
    notes: null,
    quantity: quantity || 1,
    isDefault: item.isDefault,
    priceInfo: item.priceInfo,
    modifiers: [],
    imageInfo: item.imageInfo,
    available: item.isAvailable,
    halfSpecialties: [],
    status: null,
    ledger: null,
    productUUID: "",
  };
  return cartItem;
}
export function builSizeModifier(variation: Size_Variations): Modifier {
  const modifier: Modifier = {
    name: variation.name,
    displayName: variation.displayName,
    externalId1: variation.externalId1,
    externalId2: variation.externalId2,
    externalId3: variation.externalId3,
    isAvailable: variation.isAvailable,
    allowHalf: variation.allowHalf,
    aiMenu: variation.aiMenu || undefined,
    //allowSide: variation.allowSide,
    //alterationType?: ,
    //alterationCategory?: , // needed for AddOn Variation.
    //availableQuantities?:
    isDefault: variation.isDefault,
    priceInfo: variation.priceInfo,
    modifiers: [],
    //isCustomised?: boolean;
    //isRemoved?: boolean;
    //refCode?: , // use externalid2
    //alterationQuantity?: , //WHOLE, LEFT_HALF, RIGHT_HALF
    //productSubType?: , // Style
    //productCategory?: , // category
    //max?: number;
    //min?: number;
    //toppingSubstantiations?: ToppingSubstantiations;
    //imageInfo?: ImageInfo;
    //productMdmSize?: ,
    papaSize: null,
    //productSubCategory?: , // category
    //alterationPortion?: ,
  };
  return modifier;
}
export function buildStyleModifier(variation: Style_Variations): Modifier {
  const modifier: Modifier = {
    name: variation.name,
    displayName: variation.displayName,
    externalId1: variation.externalId1,
    externalId2: variation.externalId2,
    externalId3: variation.externalId3,
    isAvailable: variation.isAvailable,
    allowHalf: variation.allowHalf,
    aiMenu: variation.aiMenu || undefined,
    //allowSide: variation.allowSide,
    //alterationType?: ,
    //alterationCategory?: , // needed for AddOn Variation.
    //availableQuantities?:
    isDefault: variation.isDefault,
    priceInfo: variation.priceInfo,
    modifiers: [],
    //isCustomised?: boolean;
    //isRemoved?: boolean;
    //refCode?: , // use externalid2
    //alterationQuantity?: , //WHOLE, LEFT_HALF, RIGHT_HALF
    productSubType: variation.productSubType, // Style
    productCategory: variation.productCategory, // category
    max: variation.max,
    min: variation.min,
    //toppingSubstantiations: null;
    //imageInfo?: ImageInfo;
    //productMdmSize?: ,
    papaSize: null,
    productSubCategory: variation.productSubCategory, // category
    //alterationPortion?: ,
  };
  return modifier;
}
export function buildSelectedAddOnModifier(
  addonVariation: AddOn_Variations[]
): Modifier[] {
  const addOnModifiers: Modifier[] = [];
  const modifiers: Modifier[] = addonVariation.map((variation) =>
    buildAddOnModifier(variation)
  );

  addOnModifiers.push(...modifiers);
  return addOnModifiers;
}
export function buildAddOnModifier(addOnVariation: AddOn_Variations): Modifier {
  const modifier: Modifier = {
    name: addOnVariation.name,
    displayName: addOnVariation.displayName,
    externalId1: addOnVariation.externalId1,
    externalId2: addOnVariation.externalId2,
    externalId3: addOnVariation.externalId3,
    isAvailable: addOnVariation.isAvailable,
    allowHalf: addOnVariation.allowHalf,
    allowSide: addOnVariation.allowSide,
    alterationType: addOnVariation.alterationType,
    alterationCategory: addOnVariation.alterationCategory, // needed for AddOn Variation.
    availableQuantities: addOnVariation.availableQuantities,
    isDefault: addOnVariation.isDefault,
    priceInfo: addOnVariation.priceInfo,
    modifiers: [],
    aiMenu: addOnVariation.aiMenu || undefined,
    //isCustomised?: boolean;
    //isRemoved?: boolean;
    //refCode?: , // use externalid2
    alterationQuantity: addOnVariation.alterationQuantity || "WHOLE", //WHOLE, LEFT_HALF, RIGHT_HALF
    //productSubType?: , // Style
    //productCategory?: , // category
    //max?: number;
    //min?: number;
    //toppingSubstantiations?: ToppingSubstantiations;
    //imageInfo?: ImageInfo;
    //productMdmSize?: ,
    papaSize: null,
    //productSubCategory?: , // category
    //alterationPortion?: ,
  };
  return modifier;
}
