import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { VoixHttpService } from '../../core/voix-http/voix-http.service';
import { NotificationService } from '../../core/notification/notification.service';
import { generalService } from '../../core/services/general.service';
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { NgxUiLoaderService } from 'ngx-ui-loader';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  changePasswordData: any = [];
  limit = 10;
  tags: any = [];
  displayedColumns = ['id', 'text', 'tags'];
  dataSource: MatTableDataSource<object>;
  changePassword: FormGroup;
  hide = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  matcher = new MyErrorStateMatcher();
  constructor( private voixHttp: VoixHttpService,
    private notification: NotificationService,
    public dialog: MatDialog, private fb: FormBuilder,
    private generalService: generalService, private ngxService: NgxUiLoaderService) {
      this.ngxService.start();
      this.changePassword  = fb.group({
        'current_password' : [null, Validators.required],
        'password' : [null, Validators.required],
        'confirm_password' : [null, Validators.required]
      }, {validator: this.checkPasswords });
    }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.changePassword.reset();
      this.ngxService.stop();
    }, 1000);
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirm_password.value;

    return pass === confirmPass ? null : { notSame: true }     
  }

  submitForm(modelValue) {
    this.ngxService.start();
    this.voixHttp.post('v1/agent/change-password', modelValue).subscribe ({
      next: (res: any) => {
        if(res.success) {
          this.notification.show('success', '', 'Password has been updated successfully. Please logout and login again');
        }
        this.ngxService.stop();
      },
      error: (error) => {
        this.notification.show('danger', 'notifications', error?.error || 'Something went wrong. please try again after some time.');
        this.ngxService.stop();
      }
    });
  }
}
