import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AutoSuggestComponent } from "./auto-suggest/auto-suggest.component";
import { PreResponsesComponent } from "./pre-responses/pre-responses.component";
import { MaterialModule } from "../core/modules/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../core/shared/shared.module";
import { GooglePlaceModule } from "../core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module";
import { OrderItemsComponent } from "./order-items/order-items.component";
import { CustomerComponent } from "./customer/customer.component";
import { CustomerOrdersComponent } from "./customer-orders/customer-orders.component";
import { SubmitOrderComponent } from "./submit-order/submit-order.component";
import { LoaderComponent } from "./loader/loader.component";
import { OrderItemsReplicaComponent } from "./order-items-replica/order-items-replica.component";
import { FormatNumberPipe } from "../core/pipes/formatNumber.pipe";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ViewDealsComponent } from "./view-deals/view-deals.component";
import { TipComponent } from "./tip/tip.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { BillingAddressComponent } from "app/transaction/billing-address/billing-address.component";
import { SafePipeModule } from "safe-pipe";
import { GooglePlaceDirective } from "app/core/directives/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    GooglePlaceModule,
    SafePipeModule,
    // NgxCustomGooglePlacesAutoCompleteComponentModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxUiLoaderModule,
  ],
  declarations: [
    AutoSuggestComponent,
    PreResponsesComponent,
    OrderItemsComponent,
    CustomerComponent,
    CustomerOrdersComponent,
    FormatNumberPipe,
    SubmitOrderComponent,
    LoaderComponent,
    OrderItemsReplicaComponent,
    ViewDealsComponent,
    TipComponent,
    BillingAddressComponent,
  ],
  exports: [
    AutoSuggestComponent,
    PreResponsesComponent,
    OrderItemsComponent,
    CustomerComponent,
    CustomerOrdersComponent,
    SubmitOrderComponent,
    LoaderComponent,
    OrderItemsReplicaComponent,
    BillingAddressComponent,
    TipComponent,
    GooglePlaceDirective,
  ],
  entryComponents: [
    PreResponsesComponent,
    LoaderComponent,
    CustomerOrdersComponent,
    ViewDealsComponent,
  ],
  providers: [],
})
export class OrderReceiveModule {}
