import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../core/auth/authentication.service";
import { Router } from "@angular/router";
import { CryptoService } from "app/core/services/crypto.service";
import { AppConstants } from "app/app.constants";
import { Permissions } from "app/core/auth/model/permissions.model";
import { PermissionsPipe } from "app/core/pipes/permissions.pipe";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  role: string;
  isHideUser: any;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "Dashboard",
    icon: "dashboard",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "coupon",
    title: "Promo/Coupons",
    icon: "library_books",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "deals",
    title: "Deals",
    icon: "library_books",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "roles",
    title: "Roles",
    icon: "manage_accounts",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "agents",
    title: "Agents",
    icon: "groups",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "store-management",
    title: "Store Management",
    icon: "store",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "notification-management",
    title: "Notification Management",
    icon: "notifications",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "keywords",
    title: "Keywords",
    icon: "key",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "faqs",
    title: "FAQs",
    icon: "quiz",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "chatbot",
    title: "chat bot",
    icon: "groups",
    class: "",
    role: null,
    isHideUser: [],
  },
  {
    path: "settings",
    title: "Settings",
    icon: "settings",
    class: "",
    role: null,
    isHideUser: [],
  },
  // { path: 'custom-responses', title: 'Custom Responses',  icon:'tap_and_play', class: '', role: "admin", isHideUser: [] },
  // { path: 'chatbot', title: 'Chat bot',  icon:'chat', class: '', role: 'user', isHideUser: ['agent@papajohns.com'] },
  // { path: 'chatsms', title: 'Chat SMS',  icon:'chat_bubble_outline', class: '', role: "admin",  isHideUser: [] },
  // { path: 'sms-messenger', title: 'SMS Messenger',  icon:'chat_bubble_outline', class: '', role: "admin",  isHideUser: [] },

  // { path: 'menus', title: 'Menu',  icon:'content_paste', class: '' },
  // { path: 'pos-link', title: 'Link POS',  icon:'phonelink', class: '' }

  // { path: 'icons', title: 'Icons',  icon:'bubble_chart', class: '' },
  // { path: 'maps', title: 'Maps',  icon:'location_on', class: '' },
  // { path: 'notifications', title: 'Notifications',  icon:'notifications', class: '' },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  agentRole: string;
  userPermissions = Permissions;
  isAdminBuild = environment['isAdminBuild'];
  constructor(
    public auth: AuthenticationService,
    public router: Router,
    private crypto: CryptoService,
    private permissionPipe: PermissionsPipe
  ) {
    const user = this.auth.getCurrentUser();
    const role =
      user.roles?.[0]?.role_details?.name || user?.role ||
      this.crypto.DecryptWithoutPromise("agentSecret");
    if (role) {
      this.agentRole = role;
    } else {
      this.agentRole = "AGENT";
    }
  }

  ngOnInit() {
    this.auth.userDetail().subscribe(
      () => {
        const isAdminBuildExist = ('isAdminBuild' in environment);
        this.menuItems = ROUTES.filter(
          (route) =>
            (route.path === "dashboard" &&
              this.permissionPipe.transform(Permissions.DASHBOARD_VIEW)) ||
            (route.path === "coupon" &&
              this.permissionPipe.transform(Permissions.PROMO_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "store-management" && this.permissionPipe.transform(Permissions.RESTAURANT_CONFIG_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "deals" &&
              (this.permissionPipe.transform(Permissions.DEAL_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true))) ||
            (route.path === "roles" &&
              (this.permissionPipe.transform(Permissions.ROLE_VIEW) && (isAdminBuildExist ? this.isAdminBuild : true))) ||
            (route.path === "agents" &&
              (this.permissionPipe.transform(Permissions.AGENT_VIEW) && (isAdminBuildExist ? this.isAdminBuild : true))) ||
            (route.path === "settings" &&
              this.permissionPipe.transform(
                Permissions.RESTAURANT_CONFIG_VIEW
              ) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "chatbot" &&
              this.permissionPipe.transform(Permissions.CHATBOT_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "keywords" &&
              this.permissionPipe.transform(Permissions.KEYWORD_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "notification-management" && this.permissionPipe.transform(Permissions.NOTIFICATION_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true)) ||
            (route.path === "faqs" && this.permissionPipe.transform(Permissions.FAQ_VIEW) && (isAdminBuildExist ? !this.isAdminBuild : true))
        );
        // if (this.agentRole && this.agentRole === AppConstants.ROLE.SUPERADMIN) {
        //   this.menuItems = ROUTES;
        // } else if (
        //   this.agentRole &&
        //   this.agentRole === AppConstants.ROLE.ADMIN
        // ) {
        //   this.menuItems = ROUTES.filter(
        //     (menuItem) => menuItem.path != "coupon"
        //   );
        // } else if (
        //   this.agentRole &&
        //   this.agentRole === AppConstants.ROLE.AGENT
        // ) {
        //   this.menuItems = ROUTES.filter(
        //     (menuItem) =>
        //       menuItem.path != "coupon" && menuItem.path != "dashboard"
        //   );
        // } else {
        //   this.menuItems = ROUTES.filter(
        //     (menuItem) =>
        //       menuItem.path != "coupon" && menuItem.path != "dashboard"
        //   );
        // }
      },
      (error) => {
        this.router.navigate(["dashboard"]);
      }
    );
  }

  MobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  reload(path: string) {
    window.location.href = "/" + path;
  }
}
