<div class="card">
  <div
    class="card-header bg-primary text-white d-flex justify-content-between align-items-center"
  >
    <div>Order</div>
    <div *ngIf="cart && cart.id" (click)="openCart()">
      <mat-icon
        matRipple
        style="height: 15px; width: 20px; cursor: pointer"
        matSuffix
        >shopping_cart</mat-icon
      >
    </div>
  </div>
  <div
    class="card-body"
    style="min-height: 80vh; max-height: 80vh; overflow-y: scroll"
  >
    <div class="fs-6">Order In Progress</div>
    <div *ngIf="currentFoodItems.length > 0">
      <ng-container *ngFor="let item of currentFoodItems">
        <div class="fs-7">
          <div>
            {{ item?.ItemCount || 1 }} X {{ item.ItemSize | capitalize }}
            {{
              item.ItemName
                ? (item.ItemName | capitalize)
                : (item.FoodType | capitalize)
            }}
          </div>
          <div>
            {{ getAddons(item) | capitalize }}
          </div>
        </div>
      </ng-container>
    </div>
    <div class="fs-6 mt-5">Currently Ordered</div>
    <div *ngIf="cart?.cartMenuItems?.length > 0">
      <ng-container *ngFor="let item of cart?.cartMenuItems; index as i">
        <div class="d-flex align-items-center justify-content-between mt-3">
          <div>
            {{ getbasicInfo(item) }}
          </div>
          <div>
            <button
              style="margin-top: -10px"
              (click)="removeItem(item)"
              color="warn"
              mat-icon-button
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <div class="fs-7">
          <div *ngIf="getModifierInfo(item.modifiers) as modifiers">
            <div *ngIf="modifiers.cheese">
              <b>Cheese :</b> {{ modifiers.cheese }}
            </div>
            <div *ngIf="modifiers.sauces">
              <b>Sauce :</b> {{ modifiers.sauces }}
            </div>
            <div *ngIf="modifiers.bake"><b>Bake :</b> {{ modifiers.bake }}</div>
            <div *ngIf="modifiers.cut"><b>Cut :</b> {{ modifiers.cut }}</div>
            <div *ngIf="modifiers.crustFlavour">
              <b>Crust flavour :</b> {{ modifiers.crustFlavour }}
            </div>
            <!-- default toppings -->
            <div *ngIf="modifiers.defaultToppings">
              <b>Default toppings :</b>
              <span
                *ngFor="
                  let addons of modifiers.defaultToppings;
                  index as modiIndex
                "
              >
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <b
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </b>
                <span *ngIf="modifiers.defaultToppings.length - 1 != modiIndex"
                  >,</span
                >
              </span>
            </div>
            <!-- customised toppings -->
            <div *ngIf="modifiers.customisedToppings">
              <b>Customised toppings :</b>
              <span
                *ngFor="
                  let addons of modifiers.customisedToppings;
                  index as modiIndex
                "
              >
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <b
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </b>
                <span
                  *ngIf="modifiers.customisedToppings.length - 1 != modiIndex"
                  >,</span
                >
              </span>
            </div>
            <!-- removed toppings -->
            <div *ngIf="modifiers.removedToppings">
              <b>Removed toppings :</b>
              <span
                *ngFor="
                  let addons of modifiers.removedToppings;
                  index as modiIndex
                "
              >
                {{ addons.displayName }} ({{
                  addons.alterationQuantity | uppercase
                }})
                <b
                  *ngIf="
                    addons.alterationPortion &&
                    addons.alterationPortion != 'Normal'
                  "
                >
                  - ({{ addons.alterationPortion | uppercase }})
                </b>
                <span
                  *ngIf="modifiers.removedToppings.length - 1 != modiIndex"
                  >,</span
                >
              </span>
            </div>
          </div>
        </div>
        <hr />
      </ng-container>
    </div>
  </div>
</div>
