<div class="wrapper wrapper-full-page">
  <div
    class="full-page login-page"
    filter-color="black"
    data-image="../../assets/img/register.jpg"
  >
    <div class="content">
      <div class="container">
        <div class="row" style="display: flex; justify-content: center">
          <div class="card card-login" style="width: 80%">
            <div class="text-center" *ngIf="isCartHasItem && !preTipError">
              <label
                class="btn btn-primary"
                id="example-radio-group-label"
                (click)="openCartResponse()"
                >REVIEW CART
                <span *ngIf="cartTotal"><b>${{cartTotal}}</b></span>
              </label>
            </div>

            <!-- <app-order-in-progress></app-order-in-progress> -->

            <!-- <mat-divider></mat-divider> -->

            <div class="text-center" style="padding: 5px">
              <label id="example-radio-group-label">PAYMENT METHOD</label>
              <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="selectedPaymentType"
                [attr.data-testid]="'paymenttype-radio-group-' + selectedPaymentType"
              >
                <mat-radio-button
                  class="example-radio-button"
                  *ngFor="let payType of paymentTypeV2"
                  [value]="payType.value"
                  [attr.data-testid]="'paymenttype-radio-button-' + payType.value"
                >
                  {{ payType.viewValue }}
                </mat-radio-button>
              </mat-radio-group>

              <div>
                <div
                  *ngIf="selectedPaymentType === 'CARD' && isCartHasItem && !preTipError"
                >
                  <!-- <app-tip *ngIf="orderDestination != 'CARRYOUT'" [selectedPaymentType]="selectedPaymentType" ></app-tip> -->
                  <span>{{selectedPaymentType}}</span>

                  <app-tip
                    [selectedPaymentType]="selectedPaymentType"
                    [attr.data-testid]="'app-tip-' + selectedPaymentType"
                  ></app-tip>
                  <app-billing-address
                    *ngIf="!isPaymentSupportInternal"
                    [paymentAddressRequiredForPayments]="paymentAddressRequiredForPayments"
                    [tipAction]="tipAction"
                    [attr.data-testid]="'app-billing-address-' + selectedPaymentType"
                  ></app-billing-address>
                  <div class="d-flex" *ngIf="tipAction == 'ADD_TIP'">
                    <!-- <iframe src="http://localhost:4200/transaction/credit-card/1369555649/CON-586c312f-2a30-4e1a-8a0b-85981d0f1bd7/18722486020" style="height: 100%;width: 100%;">
                    </iframe> -->
                    <iframe
                      *ngIf="payment_component_url"
                      [src]="payment_component_url"
                      style="height: 700px; width: 100%"
                      [attr.data-testid]="'payment-iframe-' + selectedPaymentType"
                    >
                    </iframe>
                  </div>
                </div>
              </div>
            </div>

            <!-- <iframe
              *ngIf="payment_component_url"
              [src]="payment_component_url"
              style="height: 700px; width: 100%"
              [attr.data-testid]="'payment-iframe-' + selectedPaymentType"
            >
            </iframe>
-->

            <span *ngIf="selectedPaymentType == 'GIFT'" style="color: red"
              ><b>COMING SOON...</b><br
            /></span>
            <span *ngIf="isCartEmpty" style="color: red"
              ><b>ERROR: CART IS EMPTY PLEASE CONTACT STORE</b><br
            /></span>
            <span *ngIf="!isCartFound || preTipError" style="color: red"
              ><b>ERROR: CART NOT FOUND PLEASE CONTACT STORE</b><br
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="full-page-background"
      style="background-image: url(../../assets/img/register.jpg)"
    ></div>
  </div>
</div>
