import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  private selectedDeal: Subject<any> = new Subject<any>();
  constructor() {

  }
  public getSelectedDealFromPopup(): Observable<any> {
    return this.selectedDeal.asObservable();
  }
  public setSelectedDealFromPopup(deal){
    this.selectedDeal.next(deal);
  }

}
