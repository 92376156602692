import { Injectable, Injector } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { NotificationService } from "../../notification/notification.service";
import { ActionGetDeals } from "../action/deals.action";
import { ActionGetFrequentOrderItem } from "../action/frequentOrder.action";
import { ActionGetPastOrder } from "../action/pastOrder.action";

export const pastOrderDefaultState = {
  message: null,
  statusCode: null,
  data: null,
  isloaded: false,
  isError: false,
  errorMessage: null,
};
export interface pastOrderDefaultState {
  statusCode?: Number;
  message: String;
  isloaded: boolean;
  data: any | null;
  isError?: boolean;
  errorMessage?: string;
}
@State<pastOrderDefaultState>({
  name: "pastOrder",
  defaults: pastOrderDefaultState,
})
@Injectable()
export class pastOrderState {
  constructor(private notifier: NotificationService) {}
  @Selector()
  static getPastOrder(state: pastOrderDefaultState) {
    return state.data;
  }

  @Action(ActionGetPastOrder)
  getPastOrder(
    { setState, getState, patchState }: StateContext<pastOrderDefaultState>,
    { payload }
  ) {
    const pastOrder = getState();

    // console.log(payload);
    if (payload.statusCode != 200) {
      patchState({
        message: null,
        statusCode: payload.statusCode,
        data: null,
        isError: true,
        isloaded: true,
        errorMessage: payload.error.message,
      });
      this.notifier.openSnackBarV2(
        "end",
        "top",
        payload.error.message,
        "danger-snackbar"
      );
    } else {
      const pastOrderState = {
        message: "Successfull",
        isloaded: true,
        data: payload.data,
        statusCode: payload.statusCode,
      };
      patchState(pastOrderState);
    }
  }
}
