import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ITipModel } from "../../order-receive/tip/model/tip.model";
import { Items } from "../../../../mediator-commlibs/interfaces/response/menu.interface";
@Injectable()
export class emitTransactionsService {
  notifyNav$: Observable<any>;
  private notifyNav = new Subject<any>();
  restaurantName$: Observable<any>;
  private restaurantName = new Subject<any>();
  storeDetails$: Observable<any>;
  private storeDetails = new Subject<any>();
  cartData$: Observable<any>;
  private cartData = new Subject<any>();
  cartDetail$: Observable<any>;
  private cartDetail = new Subject<any>();
  openPopup$: Observable<any>;
  private openPopup = new Subject<any>();
  cartItem$: Observable<any>;
  private cartItem = new Subject<any>();
  addItemCartLoader$: Observable<any>;
  private addItemCartLoader = new Subject<any>();
  removeItemCart$: Observable<any>;
  private removeItemCart = new Subject<any>();
  applyPromo$: Observable<any>;
  private applyPromo = new Subject<any>();
  updateCart$: Observable<any>;
  private updateCart = new Subject<any>();
  createCart$: Observable<any>;
  private createCart = new Subject<any>();
  editItemCart$: Observable<any>;
  private editItemCart = new Subject<any>();
  customerLoyalty$: Observable<any>;
  private customerLoyalty = new BehaviorSubject<boolean>(false);
  setTimeZone$: Observable<any>;
  private setTimeZone = new BehaviorSubject<boolean>(null);
  addToCartDisable$: Observable<any>;
  private addToCartDisable = new BehaviorSubject<boolean>(false);
  isItemEdit$: Observable<any>;
  private isItemEdit = new BehaviorSubject<boolean>(false);
  isCartExist$: Observable<any>;
  private isCartExist = new BehaviorSubject<boolean>(false);
  isHalfMenu$: Observable<any>;
  private isHalfMenu = new BehaviorSubject<boolean>(null);
  orderItems$: Observable<any>;
  private orderItems = new BehaviorSubject<boolean>(null);
  getPromo$: Observable<any>;
  private getPromo = new Subject<any>();
  getDeals$: Observable<any>;
  private getDeals = new Subject<any>();
  durationTime$: Observable<any>;
  private durationTime = new BehaviorSubject<boolean>(null);
  papaSizing$: Observable<any>;
  private papaSizing = new BehaviorSubject<boolean>(false);
  getPromoDashboard$: Observable<any>;
  private getPromoDashboard = new Subject<any>();
  getDealDashboard$: Observable<any>;
  private getDealDashboard = new Subject<any>();
  getMenuTrigger$: Observable<any>;
  private getMenuTrigger = new BehaviorSubject<boolean>(false);
  getMenu$: Observable<any>;
  private getMenuSave = new BehaviorSubject<boolean>(null);
  public searchPromo = new BehaviorSubject<any>("");
  public searchDeal = new BehaviorSubject<any>("");
  getPaymentUrlRequest$: Observable<any>;
  private getPaymentUrl = new BehaviorSubject<boolean>(null);
  paymentUrl$: Observable<any>;
  private paymentUrll = new BehaviorSubject<boolean>(null);
  getOrderSucceededNotification$: Observable<any>;
  private orderSucceeded = new Subject<any>();
  public requestAddressOutOfBound$ = new BehaviorSubject<any>(null);
  public recieveAddressOutOfBound$ = new BehaviorSubject<any>(null);
  public requestAddress$ = new BehaviorSubject<any>(null);
  public recieveAddress$ = new BehaviorSubject<any>(null);
  public requestPlace$ = new BehaviorSubject<any>(null);
  public recievePlace$ = new BehaviorSubject<any>(null);
  public isBusiness$ = new BehaviorSubject<any>(false);
  private addItemSubject = new BehaviorSubject<Items>(null);
  // MAX CASH ORDER AMOUNT
  public OrderMaxAmount: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  getOrderMaxAmount$: Observable<string>;
  public isAutoApplyDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  getAutoApplyCall$: Observable<boolean>;
  // MIN ORDER AMOUNT MET
  public isSubmitButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  getSubmitButtonDisabled$: Observable<boolean>;
  //TIP

  private currentTipValue = new Subject<ITipModel>();
  getCurrentTipValue$: Observable<ITipModel>;
  constructor() {
    this.storeDetails$ = this.storeDetails.asObservable();
    this.notifyNav$ = this.notifyNav.asObservable();
    this.restaurantName$ = this.restaurantName.asObservable();
    this.cartData$ = this.cartData.asObservable();
    this.cartDetail$ = this.cartDetail.asObservable();
    this.openPopup$ = this.openPopup.asObservable();
    this.cartItem$ = this.cartItem.asObservable();
    this.addItemCartLoader$ = this.addItemCartLoader.asObservable();
    this.removeItemCart$ = this.removeItemCart.asObservable();
    this.applyPromo$ = this.applyPromo.asObservable();
    this.updateCart$ = this.updateCart.asObservable();
    this.customerLoyalty$ = this.customerLoyalty.asObservable();
    this.addToCartDisable$ = this.addToCartDisable.asObservable();
    this.setTimeZone$ = this.setTimeZone.asObservable();
    this.createCart$ = this.createCart.asObservable();
    this.editItemCart$ = this.editItemCart.asObservable();
    this.isItemEdit$ = this.isItemEdit.asObservable();
    this.isCartExist$ = this.isCartExist.asObservable();
    this.isHalfMenu$ = this.isHalfMenu.asObservable();
    this.orderItems$ = this.orderItems.asObservable();
    this.getPromo$ = this.getPromo.asObservable();
    this.getDeals$ = this.getDeals.asObservable();
    this.durationTime$ = this.durationTime.asObservable();
    this.papaSizing$ = this.papaSizing.asObservable();
    this.getPromoDashboard$ = this.getPromoDashboard.asObservable();
    this.getDealDashboard$ = this.getDealDashboard.asObservable();
    this.getMenuTrigger$ = this.getMenuTrigger.asObservable();
    this.getMenu$ = this.getMenuSave.asObservable();
    this.getPaymentUrlRequest$ = this.getPaymentUrl.asObservable();
    this.paymentUrl$ = this.paymentUrll.asObservable();
    this.getOrderSucceededNotification$ = this.orderSucceeded.asObservable();
    this.getCurrentTipValue$ = this.currentTipValue.asObservable();
    this.getOrderMaxAmount$ = this.OrderMaxAmount.asObservable();
    this.getAutoApplyCall$ = this.isAutoApplyDisabled.asObservable();
    this.getSubmitButtonDisabled$ = this.isSubmitButtonDisabled.asObservable();
  }
  setNav(data) {
    this.notifyNav.next(data);
  }

  setOrderMaxAmount(data: string) {
    this.OrderMaxAmount.next(data);
  }

  setAutoApplyCall(data: boolean) {
    this.isAutoApplyDisabled.next(data);
  }

  setSubmitButtonDisabled(data: boolean) {
    this.isSubmitButtonDisabled.next(data);
  }

  setRestaurantName(data) {
    this.restaurantName.next(data);
  }

  setPromos(data) {
    this.getPromoDashboard.next(data);
  }

  setDeals(data) {
    this.getDealDashboard.next(data);
  }

  getMenu(data) {
    this.getMenuTrigger.next(data);
  }

  cloneMenu(data) {
    this.getMenuSave.next(data);
  }

  //V2

  setPapaSize(data) {
    this.papaSizing.next(data);
  }
  paymentUrl(data) {
    this.getPaymentUrl.next(data);
  }
  getIframeUrl(data) {
    this.paymentUrll.next(data);
  }
  setStoreDetailsV2(data) {
    this.storeDetails.next(data);
  }

  setCallDuration(time) {
    this.durationTime.next(time);
  }

  getPromos(data: boolean = false) {
    this.getPromo.next(data);
  }

  getDeal(data: boolean = false) {
    this.getDeals.next(data);
  }

  viewOrderItems(data) {
    this.orderItems.next(data);
  }

  halfMenuTrigger(data) {
    this.isHalfMenu.next(data);
  }

  createCartLoader(data: boolean = false) {
    this.createCart.next(data);
  }

  addItemLoader(data: boolean = false) {
    this.addItemCartLoader.next(data);
  }

  removeItemLoader(data: boolean = false) {
    this.removeItemCart.next(data);
  }

  isEditItem(data: boolean = false) {
    this.isItemEdit.next(data);
  }
  IsCartExist(data: boolean = false) {
    this.isCartExist.next(data);
  }

  applypromo(data: boolean = false) {
    this.applyPromo.next(data);
  }

  addToCartDisableAction(data: boolean = false) {
    this.addToCartDisable.next(data);
  }

  sendRemoveItem(data) {
    this.cartData.next(data);
  }

  sendUpdateCart(data) {
    this.cartData.next(data);
  }

  updateCartLoader(data: boolean = false) {
    this.updateCart.next(data);
  }

  editItemFromCart(data) {
    this.editItemCart.next(data);
  }

  setLoyaltyStatus(data: boolean = false) {
    this.customerLoyalty.next(data);
  }

  setTimezone(data) {
    this.setTimeZone.next(data);
  }

  sendUpdateItem(data) {
    this.cartDetail.next(data);
  }

  openCustomerPopup(data) {
    this.openPopup.next(data);
  }

  addItemtocart(data) {
    this.cartItem.next(data);
  }

  // Service message commands
  emitChange(change: any) {
    this.notifyNav.next(change);
  }
  emitOrderSucceededNotification(data: any) {
    this.orderSucceeded.next(data);
  }
  emitCurrentTipValue(tip: ITipModel) {
    this.currentTipValue.next(tip);
  }

  addItemToCart(item) {
    this.addItemSubject.next(item);
  }

  get addItemSubject$() {
    return this.addItemSubject.asObservable();
  }
}
