<div class="text-center fs-2">Finalize Order</div>
<div class="w-100 mt-2" *ngIf="cart && store" [formGroup]="cartform">
  <div class="w-100">
    <div class="error-message" *ngIf="selectedOrderType">
      <b>
        NOTE : The store is not accepting current
        {{ selectedOrderType }} order. Please place future
        {{ selectedOrderType }} order.
      </b>
    </div>
    <div
      class="error-message mt-2"
      *ngIf="!store?.deliverFlag && !store?.dupuFlag && !store?.pickupFlag"
    >
      <b>
        {{ store?.storeStatusReasonMessage }}
      </b>
    </div>
    <mat-form-field appearance="fill" class="w-100 p-2">
      <mat-label>Order Destination</mat-label>
      <mat-select
        data-testid="order-dst-field"
        name="orderType"
        formControlName="orderDestination"
      >
        <mat-option
          *ngFor="let orderType of orderTypes"
          [value]="orderType.displayName"
          (click)="setOrderType(orderType.displayName)"
        >
          <b>
            {{ orderType.displayName | uppercase }}
            (Prep Time: {{ orderType.deferPrepMinutes }}m)</b
          >
        </mat-option>
      </mat-select>
      <span *ngIf="orderDestination" style="color: red"
        >This Field is required</span
      >
    </mat-form-field>
    <div formGroupName="customer">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <mat-form-field
            class="p-2 w-100"
            appearance="outline"
            color="primary"
          >
            <mat-label>First Name</mat-label>
            <input
              data-testid="first-name-input"
              matInput
              placeholder="First Name"
              formControlName="firstName"
              onkeydown="return /[a-z\d\-_\s]+/i.test(event.key)"
              maxlength="20"
            />
            <span *ngIf="firstNameRequired" style="color: red"
              >This Field is required</span
            >
            <span *ngIf="firstNamePattern" style="color: red"
              >Input is Invalid</span
            >
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <mat-form-field
            class="p-2 w-100"
            appearance="outline"
            color="primary"
          >
            <mat-label>Last Name</mat-label>
            <input
              data-testid="last-name-input"
              matInput
              placeholder="Last Name"
              formControlName="lastName"
              onkeydown="return /[a-z\d\-_\s]+/i.test(event.key)"
              maxlength="20"
            />
            <span *ngIf="lastNameRequired" style="color: red"
              >This Field is required</span
            >
            <span *ngIf="lastNamePattern" style="color: red"
              >Input is Invalid</span
            >
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="outline" class="w-100 p-2">
        <mat-label>Customer Phone</mat-label>
        <input
          data-testid="customer-phone-input"
          matInput
          placeholder="Phone"
          formControlName="phone"
          onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
          maxlength="10"
          minlength="10"
        />
        <span *ngIf="phoneRequired" style="color: red"
          >This Field is Required</span
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2 w-100">
        <mat-label>Customer Email</mat-label>
        <input
          data-testid="email-input"
          matInput
          placeholder="Email"
          formControlName="email"
          email
        />
      </mat-form-field>
      <div formGroupName="deliveryAddress">
        <mat-form-field appearance="outline" class="w-100 p-2">
          <mat-label>Delivery Instruction</mat-label>
          <input
            data-testid="delivery-instructs-input"
            matInput
            placeholder="Delivery Instruction"
            formControlName="deliveryInstruction"
          />
        </mat-form-field>
      </div>
      <div formGroupName="deliveryAddress">
        <div *ngIf="cartform.get('orderDestination').value == 'DELIVERY'">
          <mat-form-field
            class="w-100 p-2"
            appearance="outline"
            color="primary"
            *ngIf="
              cartform.get('orderDestination').value == 'DELIVERY' &&
              !isAddressOutOfBound
            "
          >
            <mat-label>Search Customer Address</mat-label>
            <input
              type="text"
              placeholder="Customer Address"
              aria-label="Customer Address"
              matInput
              formControlName="streetLine4"
              [matAutocomplete]="googleAddress"
            />
            <mat-autocomplete #googleAddress="matAutocomplete">
              <mat-option
                *ngFor="let option of googleaddress; index as i"
                (onSelectionChange)="getPlace(option, i)"
              >
                {{ option.description }}
                <span *ngIf="option?.city && option?.pc"
                  >{{ option?.city }} {{ option?.pc }}</span
                >
              </mat-option>
            </mat-autocomplete>

            <span
              *ngIf="
                !isAddressValidate &&
                cartform
                  .get('customer')
                  .get('deliveryAddress')
                  .get('streetLine4').touched
              "
              class="error"
              >Please Validate this Address</span
            >
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- <ng-container
      *ngIf="cartform.get('orderDestination').value == 'DRIVE_UP_PICK_UP'"
    >
      <div class="row">
        <div class="field-container col-lg-4 col-md-6 col-sm-12">
          <label for="vehicle_brands">Vehicle Brand *</label>
          <div>
            <mat-form-field
              appearance="outline"
              color="primary"
              class="p-2"
              *ngIf="vehicle_brands.length > 0"
            >
              <input
                id="vehicle_brands"
                matInput
                type="text"
                [formControl]="vehicle_brandsControl"
                [matAutocomplete]="brand"
                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
              />
              <mat-autocomplete autoActiveFirstOption #brand="matAutocomplete">
                <mat-option
                  *ngFor="let option of vehicleBrandOptionsV2 | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
              <span *ngIf="vehicleBrandRequired" class="error"
                >This Field is required</span
              >
            </mat-form-field>
          </div>
        </div>
        <div class="field-container col-lg-4 col-md-6 col-sm-12">
          <label for="vehicle_type">Vehicle Type *</label>
          <div>
            <input
              id="vehicle_type"
              type="text"
              [formControl]="vehicle_typeControl"
              [matAutocomplete]="type"
              onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
            />
            <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
              <mat-option
                *ngFor="let option of vehicleTypeOptionsV2 | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <span *ngIf="vehicleTypeRequired" class="error"
              >This Field is required</span
            >
          </div>
        </div>
      </div>
      <div class="field-container col-lg-4 col-md-6 col-sm-12">
        <label for="vehicle_color">Vehicle Color *</label>
        <div>
          <input
            id="vehicle_color"
            type="text"
            [formControl]="vehicle_colorControl"
            [matAutocomplete]="color"
            onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
          />
          <mat-autocomplete autoActiveFirstOption #color="matAutocomplete">
            <mat-option
              *ngFor="let option of vehicleColorOptionsV2 | async"
              [value]="option.value"
            >
              <span class="boxColor" [style.background-color]="option.color">
              </span>
              {{ option.label }}
            </mat-option>
          </mat-autocomplete>
          <span *ngIf="vehicleColorRequired" class="error"
            >This Field is required</span
          >
        </div>
      </div>
    </ng-container> -->

    <mat-form-field
      appearance="outline"
      *ngIf="cartform.get('orderDestination').value == 'DELIVERY'"
      data-testid="order-delivery-dst-field"
      class="w-100 p-2"
    >
      <mat-label>Apt/Suite</mat-label>
      <input
        data-testid="apt-suite-field"
        matInput
        placeholder="Apt/Suite"
        formControlName="apt_suite"
      />
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 p-2">
      <mat-label>Order Scheduled Date</mat-label>
      <input
        data-testid="schedule-date-input"
        matInput
        [owlDateTime]="dt1"
        formControlName="orderReadyDate"
        [owlDateTimeTrigger]="dt1"
        [min]="min"
        [max]="max"
        readonly
        (dateTimeChange)="onChangeDeliveryPickupTime($event)"
      />
      <span *ngIf="orderReadyDate" style="color: red">
        This Field is required
      </span>
      <button *ngIf="cartform.value.orderReadyDate" mat-icon-button matSuffix>
        <mat-icon (click)="clearDate()">close</mat-icon>
      </button>
      <owl-date-time [startAt]="startAt" #dt1></owl-date-time>
    </mat-form-field>
    <!-- <div
      class="field-container col-lg-6 col-md-6 col-sm-12"
      *ngIf="store?.storeSpecific?.showOrderLevelNotes"
    >
      <label for="notes">Order Notes</label>
      <input id="notes" formControlName="notes" />
    </div> -->

    <div class="d-flex">
      <button
        mat-raised-button
        class="w-100 me-2"
        [ngStyle]="{
          'background-color': !cartform.invalid ? 'green' : '',
          color: !cartform.invalid ? 'white' : ''
        }"
        [disabled]="cartform.invalid"
        (click)="cloneCart()"
      >
        Update Cart
        <i class="material-icons lock-icon" *ngIf="cartform.invalid">lock</i>
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="false"
        color="primary"
        class="w-100"
      >
        No Changes
      </button>
    </div>
  </div>
</div>
