import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { uuid } from 'assets/js/customScripts';

@Component({
  selector: 'app-client-number',
  templateUrl: './client-number.component.html',
  styleUrls: ['./client-number.component.scss']
})
export class ClientNumberComponent {
  client_number = new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]);
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  public placeManualOrderForClient() {
    let urlGenerate = "PROD-" + uuid();
    window.location.href =
      "/order-receive/" +
      this.client_number.value +
      "/" +
      urlGenerate +
      "/" +
      this.data.storePhoneForManualOrder;
  }
}
