<div class="main-content no-scroll">
  <div class="container-fluid">
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="col-md-12 header">
            <div class="row">
              <div class="col-md-6">
                <strong>{{ formTitle | uppercase }}</strong>
              </div>
              <div class="col-md-6 align-right">
                <div *ngIf="defaultButtonText == 'AddNew' && isAddRolePermission">
                  <button
                    (click)="AddNew()"
                    class="btn btn-outline-dark btn-sm"
                  >
                    <i class="fa fa-plus anchor-btn" aria-hidden="true"></i> Add
                    New Role
                  </button>
                </div>
                <!-- <div *ngIf="defaultButtonText == 'Back' || defaultButtonText == 'Modify'">
                  <button (click)="Back()" class="btn btn-outline-dark btn-sm"><i class="fa fa-arrow-left anchor-btn"
                      aria-hidden="true"></i> Back</button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-md-12 body">
            <ng-container #componentPlaceHolder></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
